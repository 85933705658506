import * as React from 'react';
import CookieConsent from "react-cookie-consent";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import AutoSizer from 'react-virtualized-auto-sizer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
// import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Drawer from '@mui/material/Drawer';
import NotificationsIcon from '@mui/icons-material/Notifications';
// import { mainListItems, secondaryListItems } from './listItems';
import DeleteIcon from '@mui/icons-material/Delete';
import useMediaQuery from '@mui/material/useMediaQuery'; 
import { VariableSizeGrid as Grid } from 'react-window';
// import Chart from './Chart';
// import Deposits from './Deposits';
// import Orders from './Orders';
// import Case from './Case';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
// import FormControl from '@mui/material/FormControl';
// import CaseItems from './CaseItems';
import { Card, CardMedia, Chip, FormControl, Icon } from '@mui/material';
import { useState, useEffect } from 'react';
import { ToggleButtonGroup, ToggleButton, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import OpenBox from './OpenBox.svg';
import './Rankings.css'
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SettingsIcon from '@mui/icons-material/Settings';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import getSymbolFromCurrency from 'currency-symbol-map'
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import { useMemo } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { CaseGridItem } from './CaseGridItem';
import SortIcon from '@mui/icons-material/Sort';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import ReactGA from 'react-ga4';
import { FixedSizeGrid } from 'react-window';
import { AttachMoney, LocalShipping, PriceCheck } from '@mui/icons-material';
import debounce from 'lodash.debounce';

// ReactGA.pageview(window.location.pathname + window.location.search);


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const drawerWidth = 240;


const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const StyledToggleButton = styled(ToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: '#bb171a', 
        marginLeft: '-1px',
    }
});

const CACHE_DURATION = 12 * 60 * 60 * 1000; // Cache duration in milliseconds (12 hours)

function formatNumber(number) {
    // Use the user's locale
    const userLocale = navigator.language || 'en-US';

    // Create Intl.NumberFormat with user's locale
    const formatter = new Intl.NumberFormat(userLocale, {
        //   minimumFractionDigits: 1,
        maximumFractionDigits: number > 1000 ? 0 : number > 100 ? 1 : 2,
    });

    // Format the number
    return formatter.format(number);
    
}



function DashboardContent() {
    //   const data = await fetchData();
    const [pricing, setPricing] = React.useState({});
    const [filteredPricing, setFilteredPricing] = React.useState({});
    const cachedTypes = JSON.parse(localStorage.getItem('cachedTypes'));
    const [types, setTypes] = useState(cachedTypes || [
        "Case"
    ]);
    const [currencies, setCurrencies] = useState([]);
    const [openSettings, setOpenSettings] = useState(true);
    const storedTradeitHideUnavailable = (localStorage.getItem('tradeitHide') ?? "true") == "true";
    const [tradeitHideUnavailable, setTradeitHideUnavailable] = useState(storedTradeitHideUnavailable ?? true);
    const storedSortDirection = (localStorage.getItem('sortDirection') ?? "true") == "true";
    const [sortDirection, setSortDirection] = useState(storedSortDirection ?? true);
    const storedShowUnbox = (localStorage.getItem('showUnbox') ?? "true") == "true";
    const [showUnbox, setShowUnbox] = useState(storedShowUnbox ?? true);
    const storedShowProfit = (localStorage.getItem('showProfit') ?? "true") == "true";
    const [showProfit, setShowProfit] = useState(storedShowProfit ?? true);
    const storedShowInvest = (localStorage.getItem('showInvest') ?? "true") == "true";
    const [showInvest, setShowInvest] = useState(storedShowInvest ?? true);
    const storedShowListings = (localStorage.getItem('showListings') ?? "true") == "true";
    const [showListings, setShowListings] = useState(storedShowListings ?? true);
    var storedSort = localStorage.getItem('sortBy');
    storedSort = storedSort == "invest6m" ? "invest" : storedSort == "invest1m" ? "invest" : storedSort;
    const [sortBy, setSortBy] = useState(storedSort || "unbox");
    const storedCurrency = localStorage.getItem('currency')
    // || new Intl.NumberFormat(navigator.language, {style: 'currency'}).resolvedOptions().currency;
    const [currency, setCurrency] = useState(storedCurrency || 'usd');
    const cachedUSDExchangeRate = localStorage.getItem('usdExchangeRate');
    const [exchangeRate, setExchangeRate] = useState(cachedUSDExchangeRate || 1);


    const cachedPricingSource = localStorage.getItem('pricingSource');
    const [pricingSource, setPricingSource] = useState(cachedPricingSource || 'Steam');
    const [currencySymbol, setCurrencySymbol] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [inputValueOutput, setInputValueOutput] = useState('');
    const [filteredInputOptions, setFilteredInputOptions] = useState([]);
    const [filteredOutputOptions, setFilteredOutputOptions] = useState([]);

    const storedInvestType = localStorage.getItem('investType');
    const [investType, setInvestType] = useState(storedInvestType || "1y");
    // const theme = useTheme();
    const [openAdvanced, setOpenAdvanced] = React.useState(false);
    const [inputItems, setInputItems] = React.useState([]);
    const [outputItems, setOutputItems] = React.useState([]);
    const [itemsAndCollections, setItemsAndCollections] = useState(null);

    const handleInputChange = (event) => {
        const value = event.target.value;
        setInputValue(value);
        debouncedFilterInputOptions(value);
    };

    const handleOutputChange = (event) => {
        const value = event.target.value;
        setInputValueOutput(value);
        debouncedFilterOutputOptions(value);
    };

    const filterOptions = (value, setFilteredOptions) => {
        const searchTerms = value.toLowerCase().split(' ').filter(term => term);
        const options = Object.keys(filteredPricing).filter((key) => {
            const normalizedOption = key.toLowerCase(); 
            return searchTerms.every(term => normalizedOption.includes(term));
        }); 
        setFilteredOptions(options);
    }; 

    const debouncedFilterInputOptions = useMemo(() => debounce((value) => {
        console.log(value);
        filterOptions(value, setFilteredInputOptions);
    }, 300), []);

    const debouncedFilterOutputOptions = useMemo(() => debounce((value) => {
        filterOptions(value, setFilteredOutputOptions);
    }, 300), []);


    const handleInputSelect = (newInputValue) => {
        if (pricing?.[newInputValue] != null) {
            const inputItemValue = pricing?.[newInputValue]?.['steam']?.['last_24h'];
            const tradeitValue = pricing?.[newInputValue]?.['tradeit']?.['depositPrice'];
            var wearStrippedName = newInputValue.replace(" (Factory New)", "").replace(" (Minimal Wear)", "").replace(" (Field-Tested)", "").replace(" (Well-Worn)", "").replace(" (Battle-Scarred)", "").replace("StatTrak™ ", "").replace("Souvenir ", "").replace("★ ", "");
            var itemImage = itemsAndCollections?.[wearStrippedName]?.["CollectionData"]?.[0]?.['ItemImage'];
            setInputItems([...inputItems, { name: newInputValue, value: inputItemValue, tradeitValue: tradeitValue, itemImage: itemImage }]);
        }
    };

    const handleOutputSelect = (newInputValue) => {
        if (pricing?.[newInputValue] != null) {
            const inputItemValue = pricing?.[newInputValue]?.['steam']?.['last_24h'];
            const tradeitValue = pricing?.[newInputValue]?.['tradeit']?.['withdrawPrice'];
            var wearStrippedName = newInputValue.replace(" (Factory New)", "").replace(" (Minimal Wear)", "").replace(" (Field-Tested)", "").replace(" (Well-Worn)", "").replace(" (Battle-Scarred)", "").replace("StatTrak™ ", "").replace("Souvenir ", "").replace("★ ", "");
            var itemImage = itemsAndCollections?.[wearStrippedName]?.["CollectionData"]?.[0]?.['ItemImage'];
            setOutputItems([...outputItems, { name: newInputValue, value: inputItemValue, tradeitValue: tradeitValue, itemImage: itemImage }]);
        }
    };

    const handleDeleteInputItem = (index) => {
        setInputItems(inputItems.filter((_, i) => i !== index));
    };

    const handleDeleteOutputItem = (index) => {
        setOutputItems(outputItems.filter((_, i) => i !== index));
    };

    useEffect(() => {
        let url = '/itemsandcollections.json';

        if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
            url = 'http://localhost:8080/itemsandcollections.json';
        }
        fetch(url)
            .then(response => response.json())
            .then(data => setItemsAndCollections(data))
            .catch(error => console.error('Error:', error));
    }, []);




    const handleDrawerOpen = () => {
        setOpenAdvanced(true);
    };

    const toggleAdvanced = () => {
        setOpenAdvanced(!openAdvanced);
    };

    const [isMobileLayout, setIsMobileLayout] = useState(false);

    useEffect(() => {
        // Initial calculation
        handleResize();

        // Add event listener for resize
        window.addEventListener("resize", handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);



    const handleResize = () => {
        // Calculate aspect ratio 
        const glanceInfoSize = window.innerWidth * 2 / 11

        // Define the threshold value for aspect ratio to differentiate between mobile and desktop
        const glanceInfoThreshold = 215; // Adjust this value based on your needs

        // Set the state based on the aspect ratio
        setIsMobileLayout(glanceInfoSize < glanceInfoThreshold);
    };
    // useEffect(() => {
    //   setItems(initialItems.filter((x) => types.includes(x.CollectionType)).filter((x) => x.Name.toLowerCase().includes(inputValue.toLowerCase())).sort((a,b) => sortBy === "unbox" ? (pricingSource === "Buff" ?  (b['BuffROI'] - a['BuffROI']) : (b['SteamROI'] - a['SteamROI'])): (pricingSource === "Buff" ?  (b['BuffiROI'] - a['BuffiROI']) : (b['SteamiROI'] - a['SteamiROI']))))
    // }, [types, inputValue, sortBy])
    const handleTradeitHide = (sortTest) => {
        setTradeitHideUnavailable(sortTest);
        localStorage.setItem('tradeitHide', sortTest.toString());
        // setSelectedItem(item);
        // setSelectedRarity(rarity)
    };


    const handleShowUnbox = (sortTest) => {
        setShowUnbox(sortTest);
        localStorage.setItem('showUnbox', sortTest.toString());
        // setSelectedItem(item);
        // setSelectedRarity(rarity)
    };

    const handleShowProfit = (sortTest) => {
        setShowProfit(sortTest);
        localStorage.setItem('showProfit', sortTest.toString());
        // setSelectedItem(item);
        // setSelectedRarity(rarity)
    };

    const handleShowInvest = (sortTest) => {
        setShowInvest(sortTest);
        localStorage.setItem('showInvest', sortTest.toString());
        // setSelectedItem(item);
        // setSelectedRarity(rarity)
    };


    const handleShowListings = (sortTest) => {
        setShowListings(sortTest);
        localStorage.setItem('showListings', sortTest.toString());
        // setSelectedItem(item);
        // setSelectedRarity(rarity)
    };


    useEffect(() => {
        const fetchCurrencies = async () => {
            try {
                const cachedCurrencies = localStorage.getItem('currencies');
                const cachedTimestamp = localStorage.getItem('currenciesTimestamp');

                if (
                    cachedCurrencies &&
                    cachedTimestamp &&
                    Date.now() - Number(cachedTimestamp) < CACHE_DURATION
                ) {
                    setCurrencies(JSON.parse(cachedCurrencies));
                } else {
                    let url = '/currencies.json'

                    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
                        url = 'https://csroi.com/currencies.json';
                    }

                    const response = await fetch(url);
                    const data = await response.json();
                    const currencyList = Object.keys(data);
                    setCurrencies(currencyList);

                    localStorage.setItem('currencies', JSON.stringify(currencyList));
                    localStorage.setItem('currenciesTimestamp', Date.now().toString());
                }
            } catch (error) {
                console.error('Failed to fetch currencies:', error);
            }
        };

        const updateExchangeRateToUSD = async () => {
            try {
                const cachedCurrency = localStorage.getItem('currency');
                const cachedUSDExchangeRate = localStorage.getItem('usdExchangeRate');
                const cachedTimestamp = localStorage.getItem('usdExchangeRateTimestamp');
                // const cachedAllCases = localStorage.getItem('allCases');
                // const cachedAllLowCosts = localStorage.getItem('allLowCosts');

                if (
                    cachedCurrency &&
                    currency == cachedCurrency &&
                    cachedUSDExchangeRate &&
                    cachedTimestamp &&
                    Date.now() - Number(cachedTimestamp) < CACHE_DURATION
                ) {
                    setCurrency(cachedCurrency);
                    // setPricingSource('USD');
                    setExchangeRate(cachedUSDExchangeRate);
                    // setAllCases(JSON.parse(cachedAllCases));
                    // setAllLowCosts(JSON.parse(cachedAllLowCosts));
                } else {
                    const response = await fetch(
                        `https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/${currency.toLowerCase()}.json`
                    );
                    const data = await response.json();
                    const usdExchangeRate = data[currency.toLowerCase()]['usd'];
                    // console.log(data);

                    console.log(currency);
                    console.log(usdExchangeRate);

                    setCurrency(currency);
                    // setPricingSource('USD');
                    setExchangeRate(usdExchangeRate);

                    localStorage.setItem('currency', currency);
                    localStorage.setItem('usdExchangeRate', usdExchangeRate);
                    localStorage.setItem('usdExchangeRateTimestamp', Date.now().toString());
                }
            } catch (error) {
                console.error('Failed to update exchange rate to USD:', error);
            }
        };

        fetchCurrencies();
        updateExchangeRateToUSD();
    }, [currency]);

    useEffect(() => {
        setCurrencySymbol(getSymbolFromCurrency(currency));
    }, [currency]);

    useEffect(() => {
        if (!window.location.href.includes('localhost')) {
            ReactGA.send({ hitType: "pageview", page: "/", title: "Rankings" });
            // ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }, []);


    const theme = createTheme({
        palette: {
            mode: 'dark',
        },
        typography: {
            fontFamily: 'Barlow',
        }
    });

    const handlePricingSourceChange = (event, newPricingSource) => {
        localStorage.setItem('pricingSource', newPricingSource.props.value)
        setPricingSource(newPricingSource.props.value);
    };

    useEffect(() => {
        const fetchData = async () => {

            let url = '/pricing.json';

            if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
                url = 'http://localhost:8080/pricing.json';
            }

            const response = await fetch(url);
            const text = await response.text();
            const processedData = JSON.parse(text);

            setPricing(processedData);

            // remove all pricing items where the tradeit's price_estimated is false
            const filteredPricing = Object.keys(processedData).reduce((acc, key) => {
                if (processedData[key].tradeit?.price_estimated == false) {
                    acc[key] = processedData[key];
                }
                return acc;
            }, {});
            setFilteredPricing(filteredPricing);
        };
        fetchData();
    }, []);






    const matchesXS = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const matchesSM = useMediaQuery(theme => theme.breakpoints.between('sm', 'md'));
    const matchesMD = useMediaQuery(theme => theme.breakpoints.between('md', 'lg'));
    const matchesLG = useMediaQuery(theme => theme.breakpoints.up('lg'));

    return (
        <ThemeProvider theme={theme}>
            <CookieConsent
                location="bottom"
                buttonText="I understand"
                cookieName="garequest"
                style={{ background: "#2B373B", zIndex: 99999 }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
            >
                This website uses cookies for anonymous Google Analytics. By continuing to use this website, you consent to the use of cookies.
            </CookieConsent>
            <title>CSROI.com | Find The Best Cases, Capsules, Armory Rewards and More to Invest and Unbox!</title>
            <meta name="description" content="Find the Unboxing Return, Chance to Profit, Investing Return and Market listings alongside in depth historical data to find YOUR favourite CS2 cases, armory rewards, capsules, souvenirs, patches, collections and more!" />
            <meta name="keywords" content="cs2, csgo, roi, case, capsule, souvenir, sticker, armory, stars, invest, pin, graffiti, autographs, listings, profit, unbox, simulator, history, music kit, collection, operation"></meta>
            <Box sx={{ display: 'flex', padding: 0, transition: "width 5s infinite alternate, height 5s infinite alternate", }}>
                <CssBaseline />
                <Drawer
                    sx={{
                        display: isMobileLayout ? 'none' : 'initial',
                        width: drawerWidth,
                        // flexShrink: 0,

                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="permanent"
                    anchor="left"
                // open={openDrawer}
                >
                    <Scrollbars
                        autoHide
                        // autoHideTimeout={1000}
                        // autoHideDuration={200}
                        style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
                        renderView={props => <div {...props} style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }} />}
                        renderThumbVertical={({ style }) => (
                            <div style={{ ...style, backgroundColor: 'lightgray', borderRadius: '4px' }} />
                        )}
                    >
                        <DrawerHeader sx={{ paddingTop: '12px', paddingBottom: '12px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: 'auto', marginBottom: '6px', marginTop: '6px', }}>
                                <img src="/csroi.png" class="p-2" style={{ maxWidth: '280px', width: '100%', height: "auto", objectFit: 'contain', margin: 'auto' }} />

                            </div>


                        </DrawerHeader>
                        <Divider style={{ borderWidth: '2px' }} />
                        <a href='/#/' style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.12)' }}>
                                <p>
                                    Rankings

                                </p>
                            </div>
                        </a>
                        <Divider style={{ borderWidth: '2px' }} />
                        <a href='/#/armory' style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer' }}>
                                <p style={{ lineHeight: '0.9' }}>
                                    Armory
                                    <br />
                                    <span style={{ fontSize: '0.7rem' }}>Find the best way to spend YOUR Armory Stars</span>
                                </p>
                                <Chip label="NEW" variant="outlined" size='small' sx={{ color: 'white', border: '1px white solid', fontSize: '0.8rem', height: '24px', width: '48px', padding: '0' }} />

                            </div>
                        </a>
                        <Divider />
                        <a href='/#/charmcolors' style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer', }}>
                                <p style={{ lineHeight: '0.9' }}>
                                    Charm Colors
                                    <br />
                                    <span style={{ fontSize: '0.7rem' }}>Find the color and pattern of charm to match your skin!</span>
                                </p>
                                <Chip label="NEW" variant="outlined" size='small' sx={{ color: 'white', border: '1px white solid', fontSize: '0.8rem', height: '24px', width: '48px', padding: '0' }} />

                            </div>
                        </a>
                        <Divider />
                        <a href='/#/deals' style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer' }}>
                                <p style={{ lineHeight: '0.9' }}>
                                    Deals
                                    <br />
                                    <span style={{ fontSize: '0.7rem' }}>Resell Skins to get up to 1.5x Steam Wallet Balance!</span>
                                </p>
                            </div>
                        </a>
                        <Divider />
                        <a href='/#/inventory' style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer' }}>
                                <p style={{ lineHeight: '0.9' }}>
                                    Inventory
                                    <br />
                                    <span style={{ fontSize: '0.7rem', }}>Find YOUR Unboxing and Investing ROI!</span>
                                </p>
                                {/* <Chip label="NEW" variant="outlined" size='small' sx={{ color: 'white', border: '1px white solid', fontSize: '0.8rem', height: '24px', width: '48px', padding: '0' }} /> */}
                            </div>
                        </a>
                        <Divider />


                        {/* <p style={{textAlign: 'center', margin: '0'}}> */}
                        <div style={{ flex: '1' }}></div>


                        <Divider />
                        <FormControl sx={{ fontFamily: "'Barlow', 'Roboto', sans-serif", padding: '0 12px 8px 12px', marginTop: '12px', width: '100%' }}>
                            <InputLabel id="pricing-source-label" sx={{ paddingLeft: '14px' }}>Pricing Source</InputLabel>
                            <Select
                                //  renderInput={(params) => <TextField {...params} label="Pricing Source" />}

                                labelId="pricing-source-label"
                                id="pricing-source"
                                value={pricingSource}
                                label="Pricing Source"
                                onChange={handlePricingSourceChange}

                            >
                                <MenuItem value="Steam">Steam</MenuItem>
                                <MenuItem value="Buff">
                                    <div>
                                        Buff.163 / UUPin<Tooltip title="Buff.163 prices are preferred, but UUPin prices are used when Buff.163 prices are unavailable (e.g., containers) or when there is a significant price difference." sx={{ margin: 'auto' }}>

                                            <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '4px', marginBottom: '-4px' }} fontSize='large' />
                                        </Tooltip>
                                    </div>
                                </MenuItem>
                                <MenuItem value="CSFloat">CSFloat</MenuItem>
                                <MenuItem value="Skinport">Skinport</MenuItem>
                                <MenuItem value="Tradeit">
                                    <div>
                                        Tradeit.gg
                                        <Tooltip title="Tradeit.gg pricing reflects the cost to withdraw containers and trade back unboxed items. Estimated prices, marked in grey, are used when specific item prices are unavailable." sx={{ margin: 'auto' }}>

                                            <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '4px', marginBottom: '-4px' }} fontSize='large' />
                                        </Tooltip></div>
                                </MenuItem>
                                {/* Add more pricing source options if needed */}
                            </Select>
                        </FormControl>
                        {
                            pricingSource === "Tradeit" &&
                            <FormGroup sx={{ padding: '0 8px 12px 16px', fontSize: '0.8rem' }}>
                                <FormControlLabel sx={{ fontSize: '0.8rem' }} control={<Checkbox checked={tradeitHideUnavailable} onClick={() => handleTradeitHide(!tradeitHideUnavailable)} />} label="Show Unavailable" />
                            </FormGroup>
                        }
                        <Autocomplete
                            value={currency}
                            onChange={(event, newValue) => setCurrency(newValue)}
                            disableClearable
                            options={currencies}
                            getOptionLabel={(option) => option.toUpperCase()}
                            sx={{ fontFamily: "'Barlow', 'Roboto', sans-serif", padding: '0 12px 12px 12px' }}
                            renderInput={(params) => <TextField {...params} label="Currency" />}
                        />
                        <Divider />
                        <div style={{ margin: '12px', }}>
                            <p style={{

                                textAlign: 'center',
                                backgroundColor: "#121212",
                                border: 'rgba(255, 255, 255, 0.23) 1px solid',
                                color: 'white',
                                padding: '.25rem .75rem',
                                borderRadius: '0.25rem', textAlign: "center", margin: "auto", color: "white",
                            }}>A Project By Brongo: <br />
                                <div style={{ display: 'flex' }}>
                                    <a href="https://twitter.com/BrongoCS" target="_blank" style={{ flex: 1 }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 1200 1227" fill="none">
                                        <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" fill="white"></path>
                                    </svg></a>
                                    <a href="https://www.youtube.com/@BrongoCS" target="_blank" style={{ flex: 1 }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 576 512" fill="none"><path fill="white" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path></svg></a>
                                    <a href="https://steamcommunity.com/tradeoffer/new/?partner=83942777&amp;token=Z_ZUTuBY" style={{ flex: 1 }}><img src="/assets/steamlogo.png" style={{ width: '20px', height: '20px' }} /></a>
                                </div>
                            </p>
                        </div>
                        <Divider />
                        <Accordion sx={{ background: '#121212', padding: '4px 16px 4px 16px', margin: 0 }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                key="Float Drop Chances"
                                sx={{ padding: '0', backgroundColor: '#121212' }}
                            >
                                <Typography>Advanced Settings</Typography>

                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: '0', backgroundColor: '#121212' }}>
                                <FormControlLabel control={<Checkbox onClick={() => handleShowUnbox(!showUnbox)} checked={showUnbox} />} label="Show Unboxing ROI" />
                                <FormControlLabel control={<Checkbox onClick={() => handleShowProfit(!showProfit)} checked={showProfit} />} label="Show Profit Chance" />
                                <FormControlLabel control={<Checkbox onClick={() => handleShowInvest(!showInvest)} checked={showInvest} />} label="Show Investing ROI" />
                                <FormControlLabel control={<Checkbox onClick={() => handleShowListings(!showListings)} checked={showListings} />} label="Show Listings" />
                                {/* <FloatDropChances caseData={caseData} exchangeRate={exchangeRate} currency={currency} isMobile={isMobileLayout}/> */}
                            </AccordionDetails>


                        </Accordion>
                        <Divider />
                        <Divider />

                        <div style={{ margin: '12px', display: 'flex', flexDirection: 'row' }} >
                            <p onClick={() => window.location.href = '/#/faq'} style={{

                                textAlign: 'center',
                                backgroundColor: "#121212",
                                border: 'rgba(255, 255, 255, 0.23) 1px solid',
                                color: 'white',
                                padding: '.25rem .75rem',
                                borderRadius: '0.25rem', textAlign: "center", margin: "auto", color: "white", width: '70px', cursor: "pointer",
                            }}>FAQ
                            </p>
                            <p onClick={() => window.location.href = '/#/changelog'} style={{

                                textAlign: 'center',
                                backgroundColor: "#121212",
                                border: 'rgba(255, 255, 255, 0.23) 1px solid',
                                color: 'white',
                                padding: '.25rem .75rem',
                                borderRadius: '0.25rem', textAlign: "center", margin: "auto", color: "white", width: '120px', cursor: "pointer",
                            }}>Change Log
                            </p>
                        </div>


                        <Divider />
                        <Divider />
                    </Scrollbars>
                </Drawer>
                <Box
                    component="main"
                    sx={{

                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Scrollbars
                        renderThumbVertical={({ style }) => (
                            <div style={{ ...style, backgroundColor: 'lightgray', borderRadius: '4px' }} />
                        )}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: '8px' }}>
                            <div>
                                <TextField
                                    value={inputValue}
                                    onChange={handleInputChange}
                                    label="Search"
                                    type="search"
                                    sx={{ margin: 'auto', width: '220px', marginBottom: isMobileLayout ? 'auto' : 'auto' }}
                                />
                                <div style={{overflowY: 'scroll', maxHeight: '500px'}}>
                                    {filteredInputOptions.map((option, index) => (
                                        <div key={index} onClick={() => handleInputSelect(option)}>
                                            {filteredPricing[option]?.Name ?? option}
                                        </div>
                                    ))}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '8px', justifyContent: 'center' }}>
                                    {inputItems.map((item, index) => (
                                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                            <img src={item.itemImage} alt={`${item.name} image`} style={{ marginRight: '8px', width: '64px', }} />
                                            <span>{`${item.name}: Steam: ${formatNumber(item.value / exchangeRate, true)} / Tradeit: ${formatNumber(item.tradeitValue / exchangeRate, true)} / Tradeit Overpay Percent: ${formatNumber(item.tradeitValue / item.value, false)}`}</span>
                                            <IconButton onClick={() => handleDeleteInputItem(index)}><DeleteIcon /></IconButton>
                                        </div>
                                    ))}
                                </div>
                                <Chip label={`Total: Steam: ${formatNumber(inputItems.reduce((acc, item) => acc + item.value, 0) / exchangeRate, true)} / Tradeit: ${formatNumber(inputItems.reduce((acc, item) => acc + item.tradeitValue, 0) / exchangeRate, true)}`} />
                            </div>
                            <div>
                                <TextField
                                    value={inputValueOutput}
                                    onChange={handleOutputChange}
                                    label="Search"
                                    type="search"
                                    sx={{ margin: 'auto', width: '220px', marginBottom: isMobileLayout ? 'auto' : 'auto' }}
                                />
                                <div style={{overflowY: 'scroll', maxHeight: '500px'}}>
                                    {filteredOutputOptions.map((option, index) => (
                                        <div key={index} onClick={() => handleOutputSelect(option)}>
                                            {filteredPricing[option]?.Name ?? option}
                                        </div>
                                    ))}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '8px', justifyContent: 'center' }}>
                                    {outputItems.map((item, index) => (
                                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                            <img src={item.itemImage} alt={`${item.name} image`} style={{ marginRight: '8px', width: '64px', }} />
                                            <span>{`${item.name}: Steam: ${formatNumber(item.value / exchangeRate, true)} / Tradeit: ${formatNumber(item.tradeitValue / exchangeRate, true)} / Tradeit Overpay Percent: ${formatNumber(item.tradeitValue / item.value, false)}`}</span>
                                            <IconButton onClick={() => handleDeleteOutputItem(index)}><DeleteIcon /></IconButton>
                                        </div>
                                    ))}
                                </div>
                                <Chip label={`Total: Steam: ${formatNumber(outputItems.reduce((acc, item) => acc + item.value, 0) / exchangeRate, true)} / Tradeit: ${formatNumber(outputItems.reduce((acc, item) => acc + item.tradeitValue, 0) / exchangeRate, true)}`} />
                            </div>
                        </div>


                    </Scrollbars>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

function removeSlashSection(imageUrl) {
    return imageUrl.replace(/\/\d+fx\d+f$/, '/');
}

export default function TradingSiteCompare() {
    return <DashboardContent />;
}

