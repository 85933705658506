import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { hydrate, render } from "react-dom";

ReactGA.gtag("consent", "default", {
  ad_storage: "denied",
  ad_user_data: "denied",
  ad_personalization: "denied",
  analytics_storage: "granted",
});

ReactGA.gtag("config", "G-0QVKV4QFFB", {
  // ad_storage: "denied",
  // ad_user_data: "denied",
  // ad_personalization: "denied",
  // analytics_storage: "granted",
  cookie_flags: "SameSite=None;Secure"
  // 'SameSite=None;Secure'
});

ReactGA.initialize('G-0QVKV4QFFB', { debug: true, });




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <App />
    </BrowserRouter>
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
