import * as React from 'react';
import CookieConsent from "react-cookie-consent";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import AutoSizer from 'react-virtualized-auto-sizer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import { skinportLink, buffLink, steamLink, csfloatLink, tradeitLink } from './allLinks.js';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
// import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Drawer from '@mui/material/Drawer';
import NotificationsIcon from '@mui/icons-material/Notifications';
// import { mainListItems, secondaryListItems } from './listItems';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FixedSizeGrid as Grid } from 'react-window';
// import Chart from './Chart';
// import Deposits from './Deposits';
// import Orders from './Orders';
// import Case from './Case';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
// import FormControl from '@mui/material/FormControl';
// import CaseItems from './CaseItems';
import { Card, CardMedia, Chip, FormControl, Icon } from '@mui/material';
import { useState, useEffect } from 'react';
import { ToggleButtonGroup, ToggleButton, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import OpenBox from './OpenBox.svg';
import './Rankings.css'
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SettingsIcon from '@mui/icons-material/Settings';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import getSymbolFromCurrency from 'currency-symbol-map'
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import { useMemo } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { CaseGridItem } from './CaseGridItem';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import SortIcon from '@mui/icons-material/Sort';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import ReactGA from 'react-ga4';
import { FixedSizeGrid } from 'react-window';
import { AttachMoney, LocalShipping, PriceCheck } from '@mui/icons-material';
import { Link } from 'react-router-dom';

// ReactGA.pageview(window.location.pathname + window.location.search);


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const drawerWidth = 240;

const redRarityNums = { 'Contraband': 223, 'Covert': 187, 'Classified': 158, 'Restricted': 104, 'Mil_Spec': 2, 'Industrial': 94, 'Consumer': 176 };
const greenRarityNums = { 'Contraband': 199, 'Covert': 23, 'Classified': 0, 'Restricted': 50, 'Mil_Spec': 79, 'Industrial': 152, 'Consumer': 195 };
const blueRarityNums = { 'Contraband': 0, 'Covert': 26, 'Classified': 197, 'Restricted': 234, 'Mil_Spec': 228, 'Industrial': 217, 'Consumer': 217 };
const dataColors = { "Contraband": "gold", "Covert": "#eb4b4b", "Classified": "#d32ee6", "Restricted": "#8847ff", "Mil_Spec": "#4b69ff", "Industrial": "#5e98d9", "Consumer": "#B0C3D9", "CaseCost": "white", "ROI": "grey", "Profit": "lightgrey", "iROI": "green" };
const rarityOrder = ['Contraband', 'Covert', 'Classified', 'Restricted', 'Mil_Spec', 'Industrial', 'Consumer'];
const otherRarityNames = { 'Extraordinary': 'Covert', 'Exotic': 'Classified', 'Remarkable': 'Restricted', 'High_Grade': 'Mil_Spec', 'Superior': 'Classified', 'Master': 'Covert', 'Exceptional': 'Restricted', 'Distinguished': 'Mil_Spec' }
const realWearNames = { 'FactoryNew': 'Factory New', 'MinimalWear': 'Minimal Wear', 'FieldTested': 'Field-Tested', 'WellWorn': 'Well-Worn', 'BattleScarred': 'Battle-Scarred' };
const smallWearNames = {
    'FactoryNew': "FN",
    'MinimalWear': "MW",
    'FieldTested': "FT",
    'WellWorn': "WW",
    'BattleScarred': "BS",
};


const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const StyledToggleButton = styled(ToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: '#bb171a',
        marginLeft: '-1px',
    }
});

const CACHE_DURATION = 12 * 60 * 60 * 1000; // Cache duration in milliseconds (12 hours)





function DashboardContent() {
    //   const data = await fetchData();
    const [open, setOpen] = React.useState(true);
    const cachedTypes = JSON.parse(localStorage.getItem('cachedTypes'));
    const [types, setTypes] = useState(cachedTypes || [
        "Case"
    ]);
    const [currencies, setCurrencies] = useState([]);
    const [openSettings, setOpenSettings] = useState(true);
    const storedTradeitHideUnavailable = (localStorage.getItem('tradeitHide') ?? "true") == "true";
    const [tradeitHideUnavailable, setTradeitHideUnavailable] = useState(storedTradeitHideUnavailable ?? true);
    const storedSortDirection = (localStorage.getItem('sortDirection') ?? "true") == "true";
    const [sortDirection, setSortDirection] = useState(storedSortDirection ?? true);
    const storedShowUnbox = (localStorage.getItem('showUnbox') ?? "true") == "true";
    const [showUnbox, setShowUnbox] = useState(storedShowUnbox ?? true);
    const storedShowProfit = (localStorage.getItem('showProfit') ?? "true") == "true";
    const [showProfit, setShowProfit] = useState(storedShowProfit ?? true);
    const storedShowInvest = (localStorage.getItem('showInvest') ?? "true") == "true";
    const [showInvest, setShowInvest] = useState(storedShowInvest ?? true);
    const storedShowListings = (localStorage.getItem('showListings') ?? "true") == "true";
    const [showListings, setShowListings] = useState(storedShowListings ?? true);
    var storedSort = localStorage.getItem('searchSortBy');
    storedSort = storedSort == "invest6m" ? "invest" : storedSort == "invest1m" ? "invest" : storedSort;
    const [sortBy, setSortBy] = useState(storedSort || "unbox");
    const storedCurrency = localStorage.getItem('currency')
    // || new Intl.NumberFormat(navigator.language, {style: 'currency'}).resolvedOptions().currency;
    const [currency, setCurrency] = useState(storedCurrency || 'usd');
    const cachedUSDExchangeRate = localStorage.getItem('usdExchangeRate');
    const [exchangeRate, setExchangeRate] = useState(cachedUSDExchangeRate || 1);


    const cachedPricingSource = localStorage.getItem('pricingSource');
    const [pricingSource, setPricingSource] = useState(cachedPricingSource || 'Steam');
    const [currencySymbol, setCurrencySymbol] = useState('');
    const cachedSearch = localStorage.getItem('searchPage');
    const [inputValue, setInputValue] = useState(cachedSearch || '');
    const [selectedValue, setSelectedValue] = useState(null);
    const [initialItems, setInitialItems] = useState([
    ]);
    const [items, setItems] = useState([
    ]);
    // const cachedAllCases = localStorage.getItem('allCases');
    // const [allCases, setAllCases] = useState(cachedAllCases || {});
    // const cachedAllLowCosts = localStorage.getItem('allLowCosts');
    // const [allLowCosts, setAllLowCosts] = useState(cachedAllLowCosts || {});
    const storedInvestType = localStorage.getItem('investType');
    const [investType, setInvestType] = useState(storedInvestType || "1y");
    // const theme = useTheme();
    const [openAdvanced, setOpenAdvanced] = React.useState(false);
    const [itemsAndCollections, setItemsAndCollections] = useState(null);
    const [sortedItemsAndCollections, setSortedItemsAndCollections] = useState(null);
    const [allROIS, setAllROIs] = useState([]);
    const cachedIconSize = localStorage.getItem('invIconSize');
    const [iconSize, setIconSize] = React.useState(cachedIconSize || "large");

    const cachedOnlyUnboxable = localStorage.getItem('onlyUnboxable') ?? "false";
    const [onlyUnboxable, setOnlyUnboxable] = useState(cachedOnlyUnboxable === "true");

    const cachedSelectAutoScroll = localStorage.getItem('selectAutoScroll') ?? "true";
    const [selectAutoScroll, setSelectAutoScroll] = useState(cachedSelectAutoScroll === "true");

    const cachedShowCase = localStorage.getItem('showCase') ?? "true";
    const [showCase, setShowCase] = useState(cachedShowCase === "true");

    const cachedShowUnboxChance = localStorage.getItem('showUnboxChance') ?? "true";
    const [showUnboxChance, setShowUnboxChance] = useState(cachedShowUnboxChance === "true");

    const cachedShowStickersApplied = localStorage.getItem('showStickersApplied') ?? "true";
    const [showStickersApplied, setShowStickersApplied] = useState(cachedShowStickersApplied === "true");

    const cachedShowInvestROI = localStorage.getItem('showInvestROI') ?? "true";
    const [showInvestROI, setShowInvestROI] = useState(cachedShowInvestROI === "true");

    const cachedShowCaseName = localStorage.getItem('showCaseName');
    const [showCaseName, setShowCaseName] = useState(cachedShowCaseName === "true");

    const cachedCombineRepeats = localStorage.getItem('combineRepeats');
    const [combineRepeats, setCombineRepeats] = useState(cachedCombineRepeats === "true");

    const cachedShowTradehold = localStorage.getItem('showTradeHold') ?? "true";
    const [showTradehold, setShowTradehold] = useState(cachedShowTradehold === "true");

    const cachedPriceLinks = localStorage.getItem('priceLinks') ?? "false";
    const [priceLinks, setPriceLinks] = useState(cachedPriceLinks === "true");

    const [options, setOptions] = useState(null);

    const handleDrawerOpen = () => {
        setOpenAdvanced(true);
    };

    const toggleAdvanced = () => {
        setOpenAdvanced(!openAdvanced);
    };

    const handleOptionsClick = (event) => {
        setOptions(!options);
    };

    function formatNumber(number, isCurrency, maximumFractionDigits = 3) {
        // Use the user's locale
        const userLocale = navigator.language || 'en-US';

        // Create Intl.NumberFormat with user's locale
        if (isCurrency) {
            const formatter = new Intl.NumberFormat(userLocale, {
                //   minimumFractionDigits: 1,
                style: "currency",
                currency: currency,
                maximumFractionDigits: number > 1000 ? 0 : number > 100 ? maximumFractionDigits > 1 ? 1 : maximumFractionDigits : number > 0.01 ? maximumFractionDigits > 2 ? 2 : maximumFractionDigits : maximumFractionDigits > 3 ? 3 : maximumFractionDigits,
            });
            return formatter.format(number);
        } else {
            const formatter = new Intl.NumberFormat(userLocale, {
                //   minimumFractionDigits: 1,
                maximumFractionDigits: number > 1000 ? 0 : number > 100 ? maximumFractionDigits > 1 ? 1 : maximumFractionDigits : number > 0.01 ? maximumFractionDigits > 2 ? 2 : maximumFractionDigits : maximumFractionDigits > 3 ? 3 : maximumFractionDigits,

            });
            return formatter.format(number);
        }

        // Format the number

    }

    const fetchROIData = async () => {

        let url = `rois/${pricingSource == "Buff" ? "buff163_rois" : pricingSource == "Skinport" ? "skinport_rois" : pricingSource == "CSFloat" ? "csfloat_rois" : pricingSource == "Tradeit" ? "steam_rois" : "steam_rois"}.json`;

        if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
            url = 'http://localhost:8080/' + url;
        }

        const response = await fetch(url);
        const text = await response.text();
        // const processedText = `[${text.slice(0, -3)}]`;
        const processedData = JSON.parse(text);
        // console.log(processedData);




        // setItems(processedData.filter((x) => types.includes(x.CollectionType)));
        setAllROIs(processedData);


        //   setData(finalData)
    };

    useEffect(() => {
        fetchROIData();

    }, [pricingSource]);

    useEffect(() => {
        let url = '/itemsandcollections.json';
    
        if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
            url = 'http://localhost:8080/itemsandcollections.json';
        }
        fetch(url)
            .then(response => response.json())
            .then(data => {
                // Filter out entries that include the word "Graffiti"
                const filteredData = Object.fromEntries(
                    Object.entries(data).filter(([key, value]) => !key.includes("Graffiti"))
                );
                setItemsAndCollections(filteredData);
            })
            .catch(error => console.error('Error:', error));
    }, []);

    function sortData(sortKey, sortDirection) {
        if (!itemsAndCollections) return;

        const sortedItems = Object.entries(itemsAndCollections).sort(([nameA, itemA], [nameB, itemB]) => {
            const collectionA = itemA?.CollectionData?.[0];
            const collectionB = itemB?.CollectionData?.[0];
            var sortKey = pricingSource + 'Cost';

            let aValue = collectionA?.[sortKey]?.["totalItemValue"] ?? 0;
            let bValue = collectionB?.[sortKey]?.["totalItemValue"] ?? 0;
            // console.log('reee');
            if (sortBy === "invest") {
                aValue = allROIS?.[nameA]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0;
                bValue = allROIS?.[nameB]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0;
            }
            // if (sortBy === "unbox") {
            //     aValue *= collectionA?.CollectionType === "Collection" ? 0.001 : 1;
            //     bValue *= collectionB?.CollectionType === "Collection" ? 0.001 : 1;
            // }

            // if (sortBy === "dropType") {
            //     return aValue.localeCompare(bValue);
            // }

            return bValue - aValue;
        });
        setSortedItemsAndCollections(Object.fromEntries(sortedItems));
    }

    useEffect(() => {
        sortData(sortBy, sortDirection);
    }, [sortBy, sortDirection, itemsAndCollections]);

    const getItems = () => {
        if (!sortedItemsAndCollections) return [];

        const searchTerms = inputValue.toLowerCase().split(' ').filter(term => term); // Split input into search terms

        const filteredItems = Object.entries(sortedItemsAndCollections).filter(([itemName, itemData]) => {
            const caseNames = itemData.CollectionData.map(collection => collection.CollectionId.toLowerCase());
            const lowerItemName = itemName.toLowerCase();

            return searchTerms.every(term =>
                lowerItemName.includes(term) || caseNames.some(caseName => caseName.includes(term))
            );
        });

        return filteredItems.map(([itemName, itemData]) => ({ Name: itemName, ...itemData }));
    };

    useEffect(() => {
        // Usage
        setItems(getItems());
    }, [inputValue, itemsAndCollections, sortBy, sortDirection, sortedItemsAndCollections]);



    useEffect(() => {
        const fetchData = async () => {

            let url = '/pastData/allTrackedCases.json';

            if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
                url = 'http://localhost:8080/pastData/allTrackedCases.json';
            }

            const response = await fetch(url);
            const text = await response.text();
            const processedData = JSON.parse(text);

            setInitialItems(processedData);
        };
        fetchData();
    }, []);


    const [isMobileLayout, setIsMobileLayout] = useState(false);

    useEffect(() => {
        // Initial calculation
        handleResize();

        // Add event listener for resize
        window.addEventListener("resize", handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);



    const handleResize = () => {
        // Calculate aspect ratio 
        const glanceInfoSize = window.innerWidth * 2 / 11

        // Define the threshold value for aspect ratio to differentiate between mobile and desktop
        const glanceInfoThreshold = 215; // Adjust this value based on your needs

        // Set the state based on the aspect ratio
        setIsMobileLayout(glanceInfoSize < glanceInfoThreshold);
    };
    // useEffect(() => {
    //   setItems(initialItems.filter((x) => types.includes(x.CollectionType)).filter((x) => x.Name.toLowerCase().includes(inputValue.toLowerCase())).sort((a,b) => sortBy === "unbox" ? (pricingSource === "Buff" ?  (b['BuffROI'] - a['BuffROI']) : (b['SteamROI'] - a['SteamROI'])): (pricingSource === "Buff" ?  (b['BuffiROI'] - a['BuffiROI']) : (b['SteamiROI'] - a['SteamiROI']))))
    // }, [types, inputValue, sortBy])
    const handleTradeitHide = (sortTest) => {
        setTradeitHideUnavailable(sortTest);
        localStorage.setItem('tradeitHide', sortTest.toString());
        // setSelectedItem(item);
        // setSelectedRarity(rarity)
    };

    const handleSort = (event, sort) => {
        setSortBy(sort.props.value);
        localStorage.setItem('sortBy', sort.props.value);
        // setSelectedItem(item);
        // setSelectedRarity(rarity)
    };

    const saveToLocalStorage = (key, value) => {
        localStorage.setItem(key, value.toString());
    };


    const handleSortDirection = (sortTest) => {
        setSortDirection(sortTest);
        localStorage.setItem('sortDirection', sortTest.toString());
        // setSelectedItem(item);
        // setSelectedRarity(rarity)
    };

    const handleIconSizeChange = (event, newValue) => {
        localStorage.setItem('invIconSize', event.target.value)
        setIconSize(event.target.value);
    };

    const handleShowUnbox = (sortTest) => {
        setShowUnbox(sortTest);
        localStorage.setItem('showUnbox', sortTest.toString());
        // setSelectedItem(item);
        // setSelectedRarity(rarity)
    };

    const handleShowProfit = (sortTest) => {
        setShowProfit(sortTest);
        localStorage.setItem('showProfit', sortTest.toString());
        // setSelectedItem(item);
        // setSelectedRarity(rarity)
    };

    const handleShowInvest = (sortTest) => {
        setShowInvest(sortTest);
        localStorage.setItem('showInvest', sortTest.toString());
        // setSelectedItem(item);
        // setSelectedRarity(rarity)
    };


    const handleShowListings = (sortTest) => {
        setShowListings(sortTest);
        localStorage.setItem('showListings', sortTest.toString());
        // setSelectedItem(item);
        // setSelectedRarity(rarity)
    };

    const handleChange = (type) => {
        const typeValue = type.target.value;
        let newTypes;

        if (!types.includes(typeValue)) {
            newTypes = [...types, typeValue];
            if (typeValue === "Graffiti") {
                newTypes.push("Pins");
            }
            // if (typeValue === "Past Operation") {
            //   newTypes.push("Armory");
            // }
        } else {
            newTypes = types.filter(x => x !== typeValue);
            if (typeValue === "Graffiti") {
                newTypes = newTypes.filter(x => x !== "Pins");
            }
            // if (typeValue === "Past Operation") {
            //   newTypes = newTypes.filter(x => x !== "Armory");
            // }
        }

        localStorage.setItem('cachedTypes', JSON.stringify(newTypes));
        setTypes(newTypes);
    };

    useEffect(() => {
        const fetchCurrencies = async () => {
            try {
                const cachedCurrencies = localStorage.getItem('currencies');
                const cachedTimestamp = localStorage.getItem('currenciesTimestamp');

                if (
                    cachedCurrencies &&
                    cachedTimestamp &&
                    Date.now() - Number(cachedTimestamp) < CACHE_DURATION
                ) {
                    setCurrencies(JSON.parse(cachedCurrencies));
                } else {
                    let url = '/currencies.json'

                    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
                        url = 'https://csroi.com/currencies.json';
                    }

                    const response = await fetch(url);
                    const data = await response.json();
                    const currencyList = Object.keys(data);
                    setCurrencies(currencyList);

                    localStorage.setItem('currencies', JSON.stringify(currencyList));
                    localStorage.setItem('currenciesTimestamp', Date.now().toString());
                }
            } catch (error) {
                console.error('Failed to fetch currencies:', error);
            }
        };

        const updateExchangeRateToUSD = async () => {
            try {
                const cachedCurrency = localStorage.getItem('currency');
                const cachedUSDExchangeRate = localStorage.getItem('usdExchangeRate');
                const cachedTimestamp = localStorage.getItem('usdExchangeRateTimestamp');
                // const cachedAllCases = localStorage.getItem('allCases');
                // const cachedAllLowCosts = localStorage.getItem('allLowCosts');

                if (
                    cachedCurrency &&
                    currency == cachedCurrency &&
                    cachedUSDExchangeRate &&
                    cachedTimestamp &&
                    Date.now() - Number(cachedTimestamp) < CACHE_DURATION
                ) {
                    setCurrency(cachedCurrency);
                    // setPricingSource('USD');
                    setExchangeRate(cachedUSDExchangeRate);
                    // setAllCases(JSON.parse(cachedAllCases));
                    // setAllLowCosts(JSON.parse(cachedAllLowCosts));
                } else {
                    const response = await fetch(
                        `https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/${currency.toLowerCase()}.json`
                    );
                    const data = await response.json();
                    const usdExchangeRate = data[currency.toLowerCase()]['usd'];
                    // console.log(data);

                    console.log(currency);
                    console.log(usdExchangeRate);

                    setCurrency(currency);
                    // setPricingSource('USD');
                    setExchangeRate(usdExchangeRate);

                    localStorage.setItem('currency', currency);
                    localStorage.setItem('usdExchangeRate', usdExchangeRate);
                    localStorage.setItem('usdExchangeRateTimestamp', Date.now().toString());


                }
            } catch (error) {
                console.error('Failed to update exchange rate to USD:', error);
            }
        };

        fetchCurrencies();
        updateExchangeRateToUSD();
    }, [currency]);

    useEffect(() => {
        setCurrencySymbol(getSymbolFromCurrency(currency));
    }, [currency]);

    useEffect(() => {
        if (!window.location.href.includes('localhost')) {
            ReactGA.send({ hitType: "pageview", page: "/", title: "Rankings" });
            // ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }, []);

    // const toggleDrawer = (open) => (event) => {
    //   setDrawerOpen(!drawerOpen);
    // };



    const theme = createTheme({
        palette: {
            mode: 'dark',
        },
        typography: {
            fontFamily: 'Barlow',
        }
    });

    const ColumnCountContext = React.createContext();

    const [drawerOpen, setDrawerOpen] = useState(false);



    const handleCurrencyChange = (event, newCurrency) => {
        setCurrency(newCurrency);
    };

    const handlePricingSourceChange = (event, newPricingSource) => {
        localStorage.setItem('pricingSource', newPricingSource.props.value)
        setPricingSource(newPricingSource.props.value);
    };


    // Debounce utility function
    function debounce(func, wait) {
        let timeout;
        return function(...args) {
            const context = this;
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(context, args), wait);
        };
    }

    // Original handleInputChange function
    const handleInputChange = (event, newInputValue) => {
        localStorage.setItem('searchPage', event.target.value);
        setInputValue(event.target.value);
    };

    // Debounced handleInputChange function
    const debouncedHandleInputChange = debounce(handleInputChange, 300);

    const handleSearchChange = (event, newInputValue, reason) => {

        if (reason == "selectOption") {
            window.open("/#/item/" + newInputValue.UrlName, "_self")

        }

    };

    const handleBlur = () => {
        if (inputValue.trim() !== '') {
            setSelectedValue(inputValue);
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'selectOption') {
            setSelectedValue(inputValue);
        }
    };


    function Cell({ columnIndex, rowIndex, style }) {

        const columnCount = React.useContext(ColumnCountContext);
        const cellRef = React.useRef(null);

        // Calculate the filtered index and then find the corresponding original index
        const filteredIndex = rowIndex * columnCount + columnIndex;
        const asset = items[filteredIndex]; // Get the item directly from items array
        const assetData = asset?.CollectionData[0];
        // console.log(assetData);
        var matchingCase = null;
        var itemCost = null;
        var unboxROI = null;
        var profitChance = null;
        var collectionCost = null;
        var customCollections = null;
        var ROI = null;
        const thisROI = allROIS?.[asset?.Name];
        var itemUrl = null;

        if (assetData !== undefined) {
            matchingCase = initialItems.find(x => x.Name === assetData.CollectionId);
            itemCost = pricingSource == "Steam" ? assetData.SteamCost["totalItemValue"] : pricingSource == "Buff" ? assetData.BuffCost["totalItemValue"] : pricingSource == "Skinport" ? assetData.SkinportCost["totalItemValue"] : pricingSource == "CSFloat" ? assetData.CSFloatCost["totalItemValue"] : assetData.TradeitCost["totalItemValue"];
            collectionCost = pricingSource == "Steam" ? assetData.SteamCollectionPrice : pricingSource == "Buff" ? assetData.BuffCollectionPrice : pricingSource == "Skinport" ? assetData.SkinportCollectionPrice : pricingSource == "CSFloat" ? assetData.CSFloatCollectionPrice : assetData.TradeitCollectionPrice;

            unboxROI = pricingSource == "Steam" ? assetData.SteamROI : pricingSource == "Buff" ? assetData.BuffROI : pricingSource == "Skinport" ? assetData.SkinportROI : pricingSource == "CSFloat" ? assetData.CSFloatROI : assetData.TradeitROI;
            profitChance = pricingSource == "Steam" ? assetData.ProfitSteam : pricingSource == "Buff" ? assetData.ProfitBuff : pricingSource == "Skinport" ? assetData.ProfitSkinport : pricingSource == "CSFloat" ? assetData.ProfitCSFloat : assetData.ProfitTradeit;
            ROI = investType == "1y" ? thisROI?.ROI : investType == "6m" ? thisROI?.["6M_ROI"] : thisROI?.["1M_ROI"];
            customCollections = asset?.CollectionData;
            itemUrl = assetData?.ItemUrl;
        }

        // Adjusted styles
        const adjustedStyle = {
            ...style,
            borderRadius: '10px',
            cursor: 'pointer',
            padding: '4px'
        };

        return assetData !== undefined && (
            <Link to={'/skin/' + itemUrl}>
            <div ref={cellRef} style={adjustedStyle}>
                {itemCost != null && <div style={{ height: '100%', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', padding: '2px', width: "100%", border: asset.Name.includes("StatTrak") ? '1px solid rgba(207, 106, 50, 0.8)' : asset.Name.includes("Souvenir") ? '1px solid rgba(255, 215, 0, 0.65)' : '1px solid rgba(81, 81, 81, 1)', position: 'relative', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', }}>
                    {(showCaseName || (showCase && collectionCost == null && iconSize != "large")) && <a style={{ pointerEvents: "auto" }} href={asset?.collectionUrl} target='_blank' onClick={(e) => { e.stopPropagation() }}><p style={{ display: "flex", alignItems: 'center', paddingBottom: '4px', justifyContent: 'center', height: collectionCost == null ? (iconSize == "medium" ? showCase && showCaseName ? "64px" : showCaseName ? "24px" : "32px" : iconSize == "small" ? (showCase && showCaseName ? "52px" : showCaseName ? "16px" : "28px") : showCase ? "28px" : "26px") : "auto", color: 'white', minHeight: iconSize == "large" ? '2lh' : '2lh', marginBottom: '4px', marginTop: '4px', paddingLeft: '2px', paddingRight: '2px', fontSize: iconSize == "large" ? '0.8rem' : iconSize == "medium" ? '0.75rem' : '0.6rem', lineHeight: '1', width: '100%', textAlign: 'center' }}>{(assetData.CollectionId) ?? (iconSize == "large" ? "" : asset.Name)}</p></a>}

                    {showCase && (collectionCost != null || iconSize == "large") && (collectionCost != null ? <div style={{ width: "100%", background: 'transparent', display: 'flex', marginBottom: '4px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', margin: 'auto', overflowX: Object.keys(customCollections).length <= (iconSize == "large" ? 3 : 2) ? 'hidden' : 'scroll', "scrollbarWidth": "thin", scrollbarColor: "rgba(81, 81, 81, 1) rgb(18, 18, 18)" }}>
                            {/* <div style={{ display: 'flex', cursor: 'pointer', flexDirection: 'row', paddingRight: '1px', paddingLeft: '1px', justifyContent: 'center', margin: 'auto', backgroundColor: Object.keys(customCollections).length > 0 && customCollections?.[filteredIndex] == null ? 'rgba(255, 255, 255, 0.12)' : style.backgroundColor, borderRadius: '4px' }}>
                                <img src={assetData?.collectionImage} style={{ height: iconSize == "small" ? "32px" : "36px", objectFit: 'contain', width: iconSize == "small" ? "32px" : '36px', padding: '1px' }} />


                                <p style={{ color: (asset?.collectionTradeitEstimate && pricingSource == "Tradeit") ? 'rgb(131, 131, 131)' : 'white', marginTop: 'auto', marginBottom: 'auto', marginLeft: '2px', fontSize: iconSize == "large" ? '0.8rem' : iconSize == "medium" ? '0.7rem' : '0.65rem', padding: '1px' }}>{asset.collectionCost == 0.01 ? "DROP" : formatNumber((asset.collectionCost) / exchangeRate, true)}</p>
                            </div> */}
                            {
                                Object.entries(customCollections).map(([extraCollName, extraCollValues]) => (
                                    <Link to={"/item/" + extraCollValues.CollectionUrl}>
                                    <div key={extraCollName} style={{ display: 'flex', cursor: 'pointer', flexDirection: 'row', justifyContent: 'center', margin: 'auto', paddingLeft: '2px', paddingRight: '2px', backgroundColor: customCollections?.[filteredIndex] == extraCollName ? 'rgba(255, 255, 255, 0.12)' : style.backgroundColor, borderRadius: '4px' }}>
                                        <img src={extraCollValues.CollectionImage} style={{ height: iconSize == "small" ? "32px" : "36px", objectFit: 'contain', width: iconSize == "small" ? "32px" : '36px', padding: '1px' }} />
                                        <p style={{ color: 'white', marginTop: 'auto', marginBottom: 'auto', marginLeft: '2px', fontSize: iconSize == "large" ? '0.8rem' : iconSize == "medium" ? '0.7rem' : '0.65rem', padding: '1px' }}>
                                            {(pricingSource == "Steam" ? extraCollValues.SteamCollectionPrice : pricingSource == "Buff" ? extraCollValues.BuffCollectionPrice : pricingSource == "Skinport" ? extraCollValues.SkinportCollectionPrice : pricingSource == "CSFloat" ? extraCollValues.CSFloatCollectionPrice : extraCollValues.TradeitCollectionPrice) > 0.01 ? formatNumber((pricingSource == "Steam" ? extraCollValues.SteamCollectionPrice : pricingSource == "Buff" ? extraCollValues.BuffCollectionPrice : pricingSource == "Skinport" ? extraCollValues.SkinportCollectionPrice : pricingSource == "CSFloat" ? extraCollValues.CSFloatCollectionPrice : extraCollValues.TradeitCollectionPrice) / exchangeRate, true): "DROP"}
                                        </p>
                                    </div>
                                    </Link>
                                ))
                            }
                        </div>



                    </div> :
                        asset?.UnboxROI != 0 ? <div style={{ width: "100%", background: 'transparent', display: 'flex', height: '36px', marginBottom: '4px' }}>
                            <Tooltip title="Return on Investment for opening this container now" sx={{ margin: 'auto' }}>
                                <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'row', textAlign: 'center', fontSize: '0.8rem', lineHeight: 0.9 }}>
                                    {/* Unboxing ROI */}
                                    <img src={OpenBox} className="iconSVGMob" style={{ width: '24px', height: '24px', margin: '2px' }} />
                                    <h3 style={{ lineHeight: 0.5, margin: 0, fontSize: '0.8rem' }}>{formatNumber(asset?.UnboxROI * 100, false)}%</h3>

                                </div>

                            </Tooltip>
                            <Tooltip title="Chance for each unbox to be worth more than the cost of opening" sx={{ margin: 'auto' }}>
                                <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'row', textAlign: 'center', fontSize: '0.8rem', lineHeight: 0.9 }}>
                                    {/* Profit% */}
                                    <PriceCheckIcon className="iconSVG" sx={{ width: '24px', height: '24px', margin: '2px' }} fontSize='large' />
                                    <h3 style={{ lineHeight: 0.5, margin: 0, fontSize: '0.8rem' }}>{formatNumber(asset?.ProfitChance * 100)}%</h3>
                                </div>

                            </Tooltip>

                        </div> :
                            <div style={{ width: "100%", background: 'transparent', display: 'flex', height: showCase && showCaseName ? '34px' : '36px', marginBottom: '4px' }} />





                    )}
                    <Card style={{
                        background: `linear-gradient(145deg, RGB(${otherRarityNames.hasOwnProperty(assetData.Rarity) ? redRarityNums[otherRarityNames[assetData?.Rarity ?? "Consumer"]] : redRarityNums[assetData?.Rarity ?? "Consumer"]}, ${otherRarityNames.hasOwnProperty(assetData.Rarity) ? greenRarityNums[otherRarityNames[assetData?.Rarity ?? "Consumer"]] : greenRarityNums[assetData?.Rarity ?? "Consumer"]}, ${otherRarityNames.hasOwnProperty(assetData.Rarity) ? blueRarityNums[otherRarityNames[assetData?.Rarity ?? "Consumer"]] : blueRarityNums[assetData?.Rarity ?? "Consumer"]}) 0%, black 100%)`,

                        width: "100%", paddingLeft: 4, paddingRight: 4, marginTop: showCase ? '0px' : '1px', marginRight: '1px',
                        position: 'relative', transition: 'box-shadow 0.3s', marginBottom: iconSize == "medium" ? '36px' : "0",
                        boxShadow: (showCase && collectionCost == null) ? 'none' : '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
                    }}>

                        <CardMedia
                            sx={{ height: iconSize == "small" ? 100 : 140, backgroundSize: "contain", }}
                            image={assetData.ItemImage}
                            loading="lazy"
                        />

                        {collectionCost != null && <div style={{ position: 'absolute', top: '-2px', left: '4px', padding: iconSize == "small" ? '1px' : '4px', borderRadius: '4px 0 0 0', zIndex: 2 }}>
                            {<span style={{
                                fontSize: iconSize == "medium" || iconSize == "large" ? "16px" : '14px', color: 'white', display: showUnboxChance ? "block" : "none",
                                textShadow: "1px 0 black, -1px 0 black, 0 1px black, 0 -1px black,0.5px 0.5px black, -0.5px -0.5px black, 0.5px -0.5px black, -0.5px 0.5px black"
                            }}>1 in {formatNumber(1 / (assetData.Chance / assetData.NumRarity))}</span>}
                        </div>}




                    </Card>
                    <div style={{ position: 'absolute', width: '100%', justifyContent: 'center', bottom: '2px', display: iconSize !== "large" && (itemCost ?? 0) != 0 ? 'block' : 'none', textAlign: 'center', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <span style={{ fontSize: iconSize == "medium" ? '22px' : '16px', color: ((asset?.wearEstimate ?? false) && pricingSource == "Tradeit") ? "rgb(131, 131, 131)" : "lightskyblue" }}>{formatNumber((itemCost) / exchangeRate, true)}</span>
                            <div style={{ display: iconSize !== "large" && showInvestROI ? 'flex' : 'none', color: ROI > -0.01 ? "green" : "rgb(187, 23, 26)", fontSize: iconSize == "small" ? '11px' : '13px', lineHeight: '0.5', marginTop: 'auto', marginBottom: 'auto', paddingBottom: '7px', paddingLeft: '2px', flexDirection: 'column', alignItems: 'center' }}>{ROI > -0.01 ? <TrendingUpIcon style={{ width: iconSize == "small" ? "16px" : '19px', height: iconSize == "small" ? "12px" : "14px" }} /> : <TrendingDownIcon style={{ width: iconSize == "small" ? "16px" : '19px', height: iconSize == "small" ? "12px" : "14px" }} />}{(asset?.ROI ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(ROI), false, 0)}%</div>
                        </div>

                    </div>


                    <div style={{ paddingLeft: '8px', display: iconSize == "large" ? "block" : "none", }}>
                        <h5 style={{ lineHeight: 1, marginBottom: '0', marginTop: asset.Name.split(' | ').length < 2 ? "16px" : '12px', width: '170px', fontSize: asset.Name.split(' | ').length > 1 ? '0.82rem' : '0.95rem' }}>
                            {
                                // assetData.Rarity !== "Contraband" ?
                                asset.Name.split(' | ')[0]
                                // :
                                // (
                                //   Object.entries(caseData['KnifeCosts'][asset.Name]).length >
                                //     Object.entries(getWears(caseData['Items'][asset.Name]['FloatMin'] / 100, caseData['Items'][asset.Name]['FloatMax'] / 100))
                                //       .filter(([key, percent]) => percent !== 0).length + 1 ?
                                //     (pastNumber[3] > 0.1 ? '' : 'StatTrak™ ') :
                                //     ''
                                // ) + asset.Name.split(' | ')[0]
                            }
                        </h5>
                        <h4 style={{ lineHeight: 1, marginTop: '2px', marginBottom: '0px' }}>{asset.Name?.split(' | ')?.[1]?.split(" (")?.[0] ?? ""}</h4>
                        <div style={{ position: 'absolute', bottom: isMobileLayout ? '84%' : '22px', right: isMobileLayout ? '4px' : '8px', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2, fontSize: isMobileLayout ? '16px' : '22px', display: (itemCost ?? 0) != 0 ? 'flex' : 'none', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <p style={{ fontSize: '13px', lineHeight: '0', margin: 'auto', marginBottom: '-5px', display: showInvestROI ? "block" : "none", verticalAlign: 'middle', textAlign: 'center', color: (asset?.ROI ?? 0) > -0.01 ? "green" : "rgb(187, 23, 26)" }}>{(asset?.ROI ?? 0) > -0.01 ? <TrendingUpIcon style={{ width: "18px", height: "13px" }} /> : <TrendingDownIcon style={{ width: "18px", height: "13px" }} />}{(asset?.ROI ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(ROI), false, 0)}%</p>
                            <span style={{ color: ((asset?.wearEstimate ?? false) && pricingSource == "Tradeit") ? "rgb(131, 131, 131)" : "lightskyblue" }}>{formatNumber((itemCost) / exchangeRate, true)}</span>
                        </div>

                    </div>
                </div>}

            </div>
            </Link>
        );
    }


    const matchesXS = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const matchesSM = useMediaQuery(theme => theme.breakpoints.between('sm', 'md'));
    const matchesMD = useMediaQuery(theme => theme.breakpoints.between('md', 'lg'));
    const matchesLG = useMediaQuery(theme => theme.breakpoints.up('lg'));

    return (
        <ThemeProvider theme={theme}>
            <CookieConsent
                location="bottom"
                buttonText="I understand"
                cookieName="garequest"
                style={{ background: "#2B373B", zIndex: 99999 }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
            >
                This website uses cookies for anonymous Google Analytics. By continuing to use this website, you consent to the use of cookies.
            </CookieConsent>
            <title>CSROI.com | Find The Best Cases, Capsules, Armory Rewards and More to Invest and Unbox!</title>
            <meta name="description" content="Find the Unboxing Return, Chance to Profit, Investing Return and Market listings alongside in depth historical data to find YOUR favourite CS2 cases, armory rewards, capsules, souvenirs, patches, collections and more!" />
            <meta name="keywords" content="cs2, csgo, roi, case, capsule, souvenir, sticker, armory, stars, invest, pin, graffiti, autographs, listings, profit, unbox, simulator, history, music kit, collection, operation"></meta>
            <Box sx={{ display: 'flex', padding: 0, transition: "width 5s infinite alternate, height 5s infinite alternate", }}>
                <CssBaseline />
                <Drawer
                    sx={{
                        display: isMobileLayout ? 'none' : 'initial',
                        width: drawerWidth,
                        // flexShrink: 0,

                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="permanent"
                    anchor="left"
                // open={openDrawer}
                >
                    <Scrollbars
                        autoHide
                        // autoHideTimeout={1000}
                        // autoHideDuration={200}
                        style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
                        renderView={props => <div {...props} style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }} />}
                        renderThumbVertical={({ style }) => (
                            <div style={{ ...style, backgroundColor: 'lightgray', borderRadius: '4px' }} />
                        )}
                    >
                        <DrawerHeader sx={{ paddingTop: '12px', paddingBottom: '12px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: 'auto', marginBottom: '6px', marginTop: '6px', }}>
                                <img src="/csroi.png" class="p-2" style={{ maxWidth: '280px', width: '100%', height: "auto", objectFit: 'contain', margin: 'auto' }} />

                            </div>


                        </DrawerHeader>
                        <Divider style={{ borderWidth: '2px' }} />
                        {/* <h5 style={{ textAlign: 'center', maxHeight: '180px', padding: '4px' }}>
              Welcome to the all new CSROI.com!<br /><br />
              Featuring a new overhauled React UI, full currency conversion, a new Investing ROI, upgraded simulator, among many more!<br /><br />
              Any feedback is appreciated <a href="https://twitter.com/BrongoCS" target='_blank'>@BrongoCS</a>
            </h5> */}
                        {/* <Divider /> */}
                        {/* <iframe width={drawerWidth} height="180" src="https://www.youtube.com/embed/mMCqwpVC2Zc?si=1DY9BEJKcLe66qy3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;" allowFullScreen ></iframe> */}
                        <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.12)' }}>
                                <p>
                                    Rankings

                                </p>
                            </div>
                        </Link>
                        <Divider style={{ borderWidth: '2px' }} />
                        <Link to='/armory' style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer' }}>
                                <p style={{ lineHeight: '0.9' }}>
                                    Armory
                                    <br />
                                    <span style={{ fontSize: '0.7rem' }}>Find the best way to spend YOUR Armory Stars</span>
                                </p>
                                <Chip label="NEW" variant="outlined" size='small' sx={{ color: 'white', border: '1px white solid', fontSize: '0.8rem', height: '24px', width: '48px', padding: '0' }} />

                            </div>
                        </Link>
                        <Divider />
                        <Link to='/inventory' style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer' }}>
                                <p style={{ lineHeight: '0.9' }}>
                                    Inventory
                                    <br />
                                    <span style={{ fontSize: '0.7rem', }}>Find YOUR Unboxing and Investing ROI!</span>
                                </p>
                                <Chip label="NEW" variant="outlined" size='small' sx={{ color: 'white', border: '1px white solid', fontSize: '0.8rem', height: '24px', width: '48px', padding: '0' }} />
                            </div>
                        </Link>
                        <Divider />
                        <Link to='/deals' style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer' }}>
                                <p style={{ lineHeight: '0.9' }}>
                                    Deals
                                    <br />
                                    <span style={{ fontSize: '0.7rem' }}>Resell Skins to get up to 1.5x Steam Wallet Balance!</span>
                                </p>
                            </div>
                        </Link>
                        <Divider />

                        {/* <p style={{textAlign: 'center', margin: '0'}}> */}
                        <div style={{ flex: '1' }}></div>


                        <Divider />

                        {/* {(!isMobileLayout || openSettings) && <div style={{ display: 'flex', marginTop: '8px', marginBottom: '8px' }}> */}

                        <FormControl sx={{ fontFamily: "'Barlow', 'Roboto', sans-serif", padding: '0 12px 8px 12px', marginTop: '12px', width: '100%' }}>
                            <InputLabel id="pricing-source-label" sx={{ paddingLeft: '14px' }}>Pricing Source</InputLabel>
                            <Select
                                //  renderInput={(params) => <TextField {...params} label="Pricing Source" />}

                                labelId="pricing-source-label"
                                id="pricing-source"
                                value={pricingSource}
                                label="Pricing Source"
                                onChange={handlePricingSourceChange}

                            >
                                <MenuItem value="Steam">Steam</MenuItem>
                                <MenuItem value="Buff">
                                    <div>
                                        Buff.163 / UUPin<Tooltip title="Buff.163 prices are preferred, but UUPin prices are used when Buff.163 prices are unavailable (e.g., containers) or when there is a significant price difference." sx={{ margin: 'auto' }}>

                                            <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '4px', marginBottom: '-4px' }} fontSize='large' />
                                        </Tooltip>
                                    </div>
                                </MenuItem>
                                <MenuItem value="CSFloat">CSFloat</MenuItem>
                                <MenuItem value="Skinport">Skinport</MenuItem>
                                <MenuItem value="Tradeit">
                                    <div>
                                        Tradeit.gg
                                        <Tooltip title="Tradeit.gg pricing reflects the cost to withdraw containers and trade back unboxed items. Estimated prices, marked in grey, are used when specific item prices are unavailable." sx={{ margin: 'auto' }}>

                                            <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '4px', marginBottom: '-4px' }} fontSize='large' />
                                        </Tooltip></div>
                                </MenuItem>
                                {/* Add more pricing source options if needed */}
                            </Select>
                        </FormControl>
                        {
                            pricingSource === "Tradeit" &&
                            <FormGroup sx={{ padding: '0 8px 12px 16px', fontSize: '0.8rem' }}>
                                <FormControlLabel sx={{ fontSize: '0.8rem' }} control={<Checkbox checked={tradeitHideUnavailable} onClick={() => handleTradeitHide(!tradeitHideUnavailable)} />} label="Show Unavailable" />
                            </FormGroup>
                        }
                        <Autocomplete
                            value={currency}
                            onChange={(event, newValue) => setCurrency(newValue)}
                            disableClearable
                            options={currencies}
                            getOptionLabel={(option) => option.toUpperCase()}
                            sx={{ fontFamily: "'Barlow', 'Roboto', sans-serif", padding: '0 12px 12px 12px' }}
                            renderInput={(params) => <TextField {...params} label="Currency" />}
                        />

                        {/* </div>} */}
                        <Divider />
                        {!isMobileLayout && items.length > 0 &&
                            <p style={{
                                margin: '12px',
                                textAlign: 'center',
                                backgroundColor: "#121212",
                                border: 'rgba(255, 255, 255, 0.23) 1px solid',
                                color: 'white',
                                padding: '.25rem .75rem',
                                borderRadius: '0.25rem'
                            }} >
                                Updated<br />
                                {(() => {
                                    const updatedAtUTC = items[0]["UpdatedAt"] * 1000;
                                    const now = new Date();
                                    const timeDifference = now.getTime() - updatedAtUTC;
                                    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
                                    const daysDifference = Math.floor(hoursDifference / 24);

                                    let message;
                                    if (daysDifference > 0) {
                                        message = `${daysDifference} day${daysDifference !== 1 ? 's' : ''} ago`;
                                    } else {
                                        // if (hoursDifference > 0) {
                                        message = `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
                                        // } else {
                                        //   message = "Less than an hour ago";
                                        // }
                                    }

                                    return message;
                                })()}
                            </p>


                        }

                        <Divider />
                        <div style={{ margin: '12px', }}>
                            <p style={{

                                textAlign: 'center',
                                backgroundColor: "#121212",
                                border: 'rgba(255, 255, 255, 0.23) 1px solid',
                                color: 'white',
                                padding: '.25rem .75rem',
                                borderRadius: '0.25rem', textAlign: "center", margin: "auto", color: "white",
                            }}>A Project By Brongo: <br />
                                <div style={{ display: 'flex' }}>
                                    <a href="https://twitter.com/BrongoCS" target="_blank" style={{ flex: 1 }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 1200 1227" fill="none">
                                        <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" fill="white"></path>
                                    </svg></a>
                                    <a href="https://www.youtube.com/@BrongoCS" target="_blank" style={{ flex: 1 }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 576 512" fill="none"><path fill="white" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path></svg></a>
                                    <a href="https://steamcommunity.com/tradeoffer/new/?partner=83942777&amp;token=Z_ZUTuBY" style={{ flex: 1 }}><img src="/assets/steamlogo.png" style={{ width: '20px', height: '20px' }} /></a>
                                </div>
                            </p>
                        </div>
                        <Divider />
                        <Accordion sx={{ background: '#121212', padding: '4px 16px 4px 16px', margin: 0 }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                key="Float Drop Chances"
                                sx={{ padding: '0', backgroundColor: '#121212' }}
                            >
                                <Typography>Advanced Settings</Typography>

                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: '0', backgroundColor: '#121212' }}>
                                <FormControlLabel control={<Checkbox onClick={() => handleShowUnbox(!showUnbox)} checked={showUnbox} />} label="Show Unboxing ROI" />
                                <FormControlLabel control={<Checkbox onClick={() => handleShowProfit(!showProfit)} checked={showProfit} />} label="Show Profit Chance" />
                                <FormControlLabel control={<Checkbox onClick={() => handleShowInvest(!showInvest)} checked={showInvest} />} label="Show Investing ROI" />
                                <FormControlLabel control={<Checkbox onClick={() => handleShowListings(!showListings)} checked={showListings} />} label="Show Listings" />
                                {/* <FloatDropChances caseData={caseData} exchangeRate={exchangeRate} currency={currency} isMobile={isMobileLayout}/> */}
                            </AccordionDetails>


                        </Accordion>
                        <Divider />
                        <Divider />

                        <div style={{ margin: '12px', display: 'flex', flexDirection: 'row' }} >
                            <p onClick={() => window.location.href = '/#/faq'} style={{

                                textAlign: 'center',
                                backgroundColor: "#121212",
                                border: 'rgba(255, 255, 255, 0.23) 1px solid',
                                color: 'white',
                                padding: '.25rem .75rem',
                                borderRadius: '0.25rem', textAlign: "center", margin: "auto", color: "white", width: '70px', cursor: "pointer",
                            }}>FAQ
                            </p>
                            <p onClick={() => window.location.href = '/#/changelog'} style={{

                                textAlign: 'center',
                                backgroundColor: "#121212",
                                border: 'rgba(255, 255, 255, 0.23) 1px solid',
                                color: 'white',
                                padding: '.25rem .75rem',
                                borderRadius: '0.25rem', textAlign: "center", margin: "auto", color: "white", width: '120px', cursor: "pointer",
                            }}>Change Log
                            </p>
                        </div>


                        <Divider />
                        {/* <h5 style={{ textAlign: 'center', maxHeight: '180px', padding: '4px' }}>
              All Key Costs for third-party marketplaces are now automatically adjusted to reflect an easily achievable cost when converting to Steam Market balance.<br/><br/>
              <a href='/#/deals'>Find the best deals to get Steam Market balance here!</a>
            </h5> */}
                        {/* <h5 style={{ textAlign: 'center', maxHeight: '180px', padding: '4px' }}>
              Buff.163 has removed all containers from their site. They will continue to be tracked for historical purposes. All container prices using Buff.163 pricing from the 19th of May onwards are now instead using 80% of the Steam Market cost, as most users can convert Buff.163 balance to Steam Market balance at that rate or better.
            </h5> */}
                        {/* <Divider /> */}
                        {/* <iframe width={drawerWidth} height="180" src="https://www.youtube.com/embed/mMCqwpVC2Zc?si=1DY9BEJKcLe66qy3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;" allowFullScreen ></iframe> */}
                        <Divider />
                    </Scrollbars>
                </Drawer>
                <Box
                    component="main"
                    sx={{

                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    {/* <Toolbar /> */}
                    <Scrollbars
                        // autoHide
                        // autoHideTimeout={1000}
                        // autoHideDuration={200}
                        renderThumbVertical={({ style }) => (
                            <div style={{ ...style, backgroundColor: 'lightgray', borderRadius: '4px' }} />
                        )}
                    >



                        <Container maxWidth="false" sx={{ mb: 4, mt: "4px" }}>
                            <Container style={{ display: 'flex', flexDirection: 'row', padding: '0', margin: '0', maxWidth: 'none', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
                                {/* <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{ mr: 2 }}
                >
                  <MenuIcon />
                </IconButton> */}


                                <div style={{ display: isMobileLayout ? 'flex' : 'none', flexDirection: 'column', justifyContent: 'center', margin: 'auto', marginBottom: '6px', marginTop: '14px', }}>
                                    <img src="/csroi.png" class="p-2" style={{ maxWidth: '280px', width: '100%', height: "auto", objectFit: 'contain', margin: 'auto' }} />

                                </div>
                                <FormControl style={{ width: '150px', marginTop: '8px', marginRight: '4px' }}>
                                    <InputLabel id="demo-simple-select-label">Sort By</InputLabel>
                                    <Select
                                        value={sortBy}
                                        label="Sort By"
                                        onChange={(event) => {
                                            localStorage.setItem("searchSortBy", event.target.value);
                                            setSortBy(event.target.value);
                                        }}
                                    >
                                        <MenuItem value="recent">Recent</MenuItem>
                                        <MenuItem value="value">Cost</MenuItem>
                                        <MenuItem value="rarity">Rarity</MenuItem>
                                        <MenuItem value="invest">Invest ROI</MenuItem>
                                        <MenuItem value="collection">Collection</MenuItem>
                                    </Select>
                                </FormControl>
                                <IconButton aria-label="sortDirection" style={{ WebkitTransform: sortDirection ? "none" : "scaleY(-1)", marginRight: '4px', transform: sortDirection ? "none" : "scaleY(-1)" }} onClick={() => handleSortDirection(!sortDirection)}>
                                    <SortIcon></SortIcon>
                                </IconButton>
                                <FormControl style={{ width: '120px', marginTop: '8px', marginRight: '4px' }}>
                                    <InputLabel id="demo-simple-select-label">Invest Type</InputLabel>
                                    <Select
                                        value={investType}
                                        label="Investing ROI"
                                        onChange={(event) => {
                                            localStorage.setItem('investType', event.target.value);
                                            setInvestType(event.target.value);
                                        }}
                                    >
                                        <MenuItem value="1y">1 Year</MenuItem>
                                        <MenuItem value="6m">6 Months</MenuItem>
                                        <MenuItem value="1m">1 Month</MenuItem>
                                    </Select>
                                </FormControl>



                                <TextField
                                    sx={{ width: '40%', marginBottom: isMobileLayout ? 'auto' : 'auto', marginTop: '8px', }}
                                    onChange={debouncedHandleInputChange}
                                    label="Find anything about any item!"
                                    defaultValue={inputValue}
                                    InputProps={{
                                        // ...params.InputProps,
                                        type: 'search',
                                    }}
                                />


                                <div style={{ display: 'flex', textAlign: 'center', flexDirection: 'row', marginTop: 'auto', marginBottom: 'auto', marginLeft: '4px' }}>
                                    <h3>Icon Size:</h3>

                                    <Checkbox
                                        onClick={handleIconSizeChange}
                                        value="small"
                                        checked={iconSize === 'small'}
                                        disableRipple
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 16 } }}
                                    />
                                    <Checkbox
                                        onClick={handleIconSizeChange}
                                        value="medium"
                                        checked={iconSize === 'medium'}
                                        disableRipple
                                        sx={{ marginLeft: '-10px' }}
                                    />
                                    <Checkbox
                                        onClick={handleIconSizeChange}
                                        value="large"
                                        checked={iconSize === 'large'}
                                        disableRipple
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 32 }, marginLeft: '-12px' }}
                                    />

                                </div>
                                <div>
                                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleOptionsClick} sx={{ fontSize: '1.2rem', marginTop: 'auto', marginBottom: 'auto' }} startIcon={<SettingsIcon sx={{ height: '28px', width: '28px' }} />}>
                                        OPTIONS
                                    </Button>

                                    <div style={{ display: options ? "block" : "none", zIndex: '2', position: 'absolute', backgroundColor: '#121212', padding: '8px',  }}>
                                        <Divider style={{ paddingTop: '4px', paddingBottom: '4px' }} />
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Checkbox checked={showCase} onChange={(e) => {
                                                    setShowCase(e.target.checked);
                                                    saveToLocalStorage('showCase', e.target.checked);
                                                }} />}
                                                label="Show Case"
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Checkbox checked={showCaseName} onChange={(e) => {
                                                    setShowCaseName(e.target.checked);
                                                    saveToLocalStorage('showCaseName', e.target.checked);
                                                }} />}
                                                label="Show Case Name"
                                            />
                                        </FormGroup>
                                        <Divider style={{ paddingTop: '4px', paddingBottom: '4px' }} />

                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Checkbox checked={showUnboxChance} onChange={(e) => {
                                                    setShowUnboxChance(e.target.checked);
                                                    saveToLocalStorage('showUnboxChance', e.target.checked);
                                                }} />}
                                                label="Show Unbox Chance"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Checkbox checked={showInvestROI} onChange={(e) => {
                                                    setShowInvestROI(e.target.checked);
                                                    saveToLocalStorage('showInvestROI', e.target.checked);
                                                }} />}
                                                label="Show Investing ROI"
                                            />
                                        </FormGroup>
                                    </div>

                                </div>

                        
                                <div style={{ marginTop: '8px', marginBottom: '8px', display: isMobileLayout ? 'flex' : 'none', flexDirection: 'row', marginLeft: 'auto', marginRight: 'auto' }}>
                                    <FormControl sx={{ fontFamily: "'Barlow', 'Roboto', sans-serif", margin: '0 4px 0px 0px', minWidth: '180px', flex: '1', display: 'inline-flex' }}>
                                        <InputLabel id="pricing-source-label" >Pricing Source</InputLabel>
                                        <Select
                                            //  renderInput={(params) => <TextField {...params} label="Pricing Source" />}

                                            labelId="pricing-source-label"
                                            id="pricing-source"
                                            value={pricingSource}
                                            label="Pricing Source"
                                            onChange={handlePricingSourceChange}

                                        >
                                            <MenuItem value="Steam">Steam</MenuItem>
                                            <MenuItem value="Buff">
                                                <div>Buff.163 / UUPin
                                                    <Tooltip title="Buff.163 prices are preferred, but UUPin prices are used when Buff.163 prices are unavailable (e.g., containers) or when there is a significant price difference." sx={{ margin: 'auto' }}>

                                                        <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '4px', marginBottom: '-4px' }} fontSize='large' />
                                                    </Tooltip>
                                                </div>
                                            </MenuItem>
                                            <MenuItem value="CSFloat">CSFloat</MenuItem>
                                            <MenuItem value="Skinport">Skinport</MenuItem>
                                            <MenuItem value="Tradeit">
                                                <div>
                                                    Tradeit.gg
                                                    <Tooltip title="Tradeit.gg pricing reflects the cost to withdraw containers and trade back unboxed items. Estimated prices, marked in grey, are used when specific item prices are unavailable." sx={{ margin: 'auto' }}>

                                                        <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '4px', marginBottom: '-4px' }} fontSize='large' />
                                                    </Tooltip></div>
                                            </MenuItem>
                                            {/* Add more pricing source options if needed */}
                                        </Select>
                                    </FormControl>

                                    <Autocomplete
                                        value={currency}
                                        onChange={(event, newValue) => setCurrency(newValue)}
                                        disableClearable
                                        options={currencies}
                                        getOptionLabel={(option) => option.toUpperCase()}
                                        sx={{ fontFamily: "'Barlow', 'Roboto', sans-serif", padding: '0 12px 4px 12px', flex: '1' }}
                                        renderInput={(params) => <TextField {...params} label="Currency" />}
                                    />
                                </div>
                                {
                                    pricingSource === "Tradeit" && isMobileLayout &&
                                    <FormGroup sx={{ fontSize: '0.8rem', marginTop: '-16px' }} >
                                        <FormControlLabel sx={{}} control={<Checkbox checked={tradeitHideUnavailable} onClick={() => handleTradeitHide(!tradeitHideUnavailable)} />} label="Show Unavailable Containers" />
                                    </FormGroup>
                                }
                                {
                                    isMobileLayout &&
                                    <div style={{ marginBottom: '4px', display: 'flex', border: '1px solid rgba(255, 255, 255, 0.23)', borderRadius: '4px', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer' }} onClick={() => window.location.href = '/#/deals'}>
                                        <p style={{ lineHeight: '0.9', marginRight: '4px' }}>
                                            Deals
                                            <br />
                                            <span style={{ fontSize: '0.7rem' }}>Resell Skins to get up to 1.5x Steam Wallet Balance!</span>
                                        </p>
                                        <Chip label="NEW" variant="outlined" size='small' sx={{ color: 'white', border: '1px white solid', fontSize: '0.8rem', height: '24px', width: '48px', padding: '0', marginLeft: '4px' }} />
                                    </div>
                                }
                            </Container>


                            <div style={{ height: '95vh', paddingLeft: '4px', paddingRight: '4px', paddingBottom: '12px' }}>
                                
                                {/* </Grid> */}
                                {/* </Scrollbars> */}
                                <AutoSizer>
                                    {({ height, width }) => {
                                        let minColumnWidth =
                                            width < 420 ? 220 :
                                                iconSize == "large" ? 260 :
                                                    iconSize == "medium" ? 180 :
                                                        140;
                                        let columnCount = Math.floor((width - 20) / minColumnWidth); // Adjusted to account for potential padding/margin
                                        let columnWidth = (width - 20) / columnCount; // Adjusted similarly
                                        let fixedRowHeight =
                                            isMobileLayout ?
                                                ((showCase ? showCaseName ? 300 : 270 : showCaseName ? 250 : 230)) :
                                                iconSize == "large" ?
                                                    (showCase ? showCaseName ? 300 : 270 : showCaseName ? 255 : 230) :
                                                    iconSize == "medium" ?
                                                        (showCase ? showCaseName ? 265 : 235 : showCaseName ? 235 : 195) :
                                                        (showCase ? showCaseName ? 210 : 180 : showCaseName ? 170 : 144);

                                        return (
                                            <ColumnCountContext.Provider value={columnCount}>
                                                <Grid
                                                    columnCount={columnCount}
                                                    columnWidth={columnWidth}
                                                    height={height - 64}
                                                    rowCount={Math.ceil(items.length / columnCount)}
                                                    rowHeight={fixedRowHeight}
                                                    width={width}
                                                    style={{ margin: 'auto' }}
                                                >
                                                    {Cell}
                                                </Grid>
                                            </ColumnCountContext.Provider>
                                        );
                                    }}
                                </AutoSizer>

                            </div>
                            {isMobileLayout && items.length > 0 &&
                                <p style={{
                                    margin: 'auto',
                                    textAlign: 'center',
                                    backgroundColor: "#121212",
                                    border: 'rgba(255, 255, 255, 0.23) 1px solid',
                                    color: 'white',
                                    padding: '.25rem .75rem',
                                    borderRadius: '0.25rem',
                                    marginTop: '20px',
                                }} >
                                    Updated<br />
                                    {(() => {
                                        const updatedAtUTC = items[0]["UpdatedAt"] * 1000;
                                        const now = new Date();
                                        const timeDifference = now.getTime() - updatedAtUTC;
                                        const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
                                        const daysDifference = Math.floor(hoursDifference / 24);

                                        let message;
                                        if (daysDifference > 0) {
                                            message = `${daysDifference} day${daysDifference !== 1 ? 's' : ''} ago`;
                                        } else {
                                            if (hoursDifference > 0) {
                                                message = `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
                                            } else {
                                                message = "Less than an hour ago";
                                            }
                                        }

                                        return message;
                                    })()}
                                </p>
                            }
                            {/* <br/> */}
                            <p style={{ textAlign: 'center' }}>
                                All containers, items and their corresponding images shown on this site are the property of Valve Corporation. CSROI.com does not guarantee that all probability calculations are correct as Valve Corporation controls all unboxing odds, this site serves merely to estimate the odds with community known data. Investing ROI serves only as an estimate based on historical data used on this site and should not be considered investing advice, past performance is not indicative of future performance.<br /><br />©2022 - 2024 CSROI.com<br /><br /><a href="http://www.onlinewebfonts.com">Unboxing Icon Provided by Online Web Fonts</a></p>

                        </Container>
                    </Scrollbars>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

function removeSlashSection(imageUrl) {
    return imageUrl.replace(/\/\d+fx\d+f$/, '/');
}

export default function Search() {
    return <DashboardContent />;
}

