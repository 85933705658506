import React, { useState, useEffect } from 'react';
import { Card, Autocomplete, Menu, Box, Drawer, Button, Chip, CardMedia, Checkbox, FormControlLabel, FormGroup, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Accordion, AccordionSummary, Typography, AccordionDetails, TextField, Divider, IconButton, Icon, Slider } from '@mui/material';
import CookieConsent from "react-cookie-consent";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import OpenBox from './OpenBox.svg';
import { FixedSizeGrid as Grid } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { CaseGridItem } from './CaseGridItem';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import DataObjectIcon from '@mui/icons-material/DataObject';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { skinportLink, buffLink, steamLink, csfloatLink, tradeitLink } from './allLinks.js';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Scrollbars from 'react-custom-scrollbars-2';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import SortIcon from '@mui/icons-material/Sort';
import SettingsIcon from '@mui/icons-material/Settings';
import './Rankings.css'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const InventoryPage = () => {
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

  const scrollToElement = (elementId) => {
    setTimeout(() => {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 1000); // Delay of 1 second (1000 milliseconds)
  };

  const theme = createTheme({
    palette: {
      mode: 'dark',
    },
    typography: {
      fontFamily: 'Barlow',
    }
  });

  const [currencies, setCurrencies] = useState([]);
  const [ratios, setRatios] = useState([]);
  const cachedSteamId = localStorage.getItem('steamId');
  const [id, setId] = useState(cachedSteamId || '');
  const cachedInventoryData = localStorage.getItem('inventoryData');
  const [response, setResponse] = useState(cachedInventoryData || '');
  const cachedBackupInventoryData = localStorage.getItem('backupInventoryData');
  const [backupResponse, setBackupResponse] = useState(cachedBackupInventoryData || '');
  const cachedInventoryTimestamp = localStorage.getItem('inventoryTimestamp');
  const [inventoryTimestamp, setInventoryTimestamp] = useState((cachedInventoryTimestamp != null ? Number(cachedInventoryTimestamp) : null) || null);
  const cachedBackupInventoryTimestamp = localStorage.getItem('backupInventoryTimestamp');
  const [backupInventoryTimestamp, setBackupInventoryTimestamp] = useState((cachedInventoryTimestamp != null ? Number(cachedInventoryTimestamp) : null) || null);
  const storedSortDirection = localStorage.getItem('sortDirectionInv') ?? "true";
  const [sortDirection, setSortDirection] = useState(storedSortDirection === "true");
  const [assets, setAssets] = useState([]);
  const storedSortBy = localStorage.getItem('invSortBy');
  const [sortBy, setSortBy] = useState(storedSortBy || "recent");
  const storedInvestType = localStorage.getItem('investType');
  const [investType, setInvestType] = useState(storedInvestType || "1y");
  const [allItemValue, setAllItemValue] = useState(0);
  const [totalItemNum, setTotalItemNum] = useState(0);
  const [allItem1YValue, setAllItem1YValue] = useState(0);
  const [allItem6MValue, setAllItem6MValue] = useState(0);
  const [allItem1MValue, setAllItem1MValue] = useState(0);
  const [collectionNames, setAllCollectionNames] = useState([]);
  const [options, setOptions] = useState(null);

  // const [allCollectionValue, setAllCollectionValue] = useState(0);
  const [itemsAndCollections, setItemsAndCollections] = useState(null);
  const cachedPricingSource = localStorage.getItem('pricingSource');
  const [pricingSource, setPricingSource] = useState(cachedPricingSource || 'Steam');
  const cachedUSDExchangeRate = localStorage.getItem('usdExchangeRate');
  const [exchangeRate, setExchangeRate] = useState(cachedUSDExchangeRate || 1);
  const [isMobile, setIsMobileLayout] = useState(false);
  const storedCurrency = localStorage.getItem('currency')
  const [currency, setCurrency] = useState(storedCurrency || 'usd');
  // const [collectionsUsed, setCollectionsUsed] = useState([]);
  const cachedIncludeFreeItems = localStorage.getItem('includeFreeItems') ?? "true";
  const [includeFreeItems, setIncludeFreeItems] = useState(cachedIncludeFreeItems === "true");

  const cachedOnlyUnboxable = localStorage.getItem('onlyUnboxable') ?? "false";
  const [onlyUnboxable, setOnlyUnboxable] = useState(cachedOnlyUnboxable === "true");

  const cachedSelectAutoScroll = localStorage.getItem('selectAutoScroll') ?? "true";
  const [selectAutoScroll, setSelectAutoScroll] = useState(cachedSelectAutoScroll === "true");

  const cachedInventoryAutoScroll = localStorage.getItem('inventoryAutoScroll') ?? "true";
  const [selectInventoryScroll, setSelectInventoryScroll] = useState(cachedInventoryAutoScroll === "true");

  const cachedShowCase = localStorage.getItem('showCase') ?? "true";
  const [showCase, setShowCase] = useState(cachedShowCase === "true");

  const cachedShowUnboxChance = localStorage.getItem('showUnboxChance') ?? "true";
  const [showUnboxChance, setShowUnboxChance] = useState(cachedShowUnboxChance === "true");

  const cachedShowStickersApplied = localStorage.getItem('showStickersApplied') ?? "true";
  const [showStickersApplied, setShowStickersApplied] = useState(cachedShowStickersApplied === "true");

  const cachedShowInvestROI = localStorage.getItem('showInvestROI') ?? "true";
  const [showInvestROI, setShowInvestROI] = useState(cachedShowInvestROI === "true");

  const cachedShowCaseName = localStorage.getItem('showCaseName');
  const [showCaseName, setShowCaseName] = useState(cachedShowCaseName === "true");

  const cachedCombineRepeats = localStorage.getItem('combineRepeats');
  const [combineRepeats, setCombineRepeats] = useState(cachedCombineRepeats === "true");

  const cachedShowTradehold = localStorage.getItem('showTradeHold') ?? "true";
  const [showTradehold, setShowTradehold] = useState(cachedShowTradehold === "true");

  const cachedEnableBackup = localStorage.getItem('enableBackup');
  const [enableBackup, setEnableBackup] = useState(cachedEnableBackup === "true");

  const cachedPriceLinks = localStorage.getItem('priceLinks') ?? "false";
  const [priceLinks, setPriceLinks] = useState(cachedPriceLinks === "true");
  // const [selectedAssets, setSelectedAssets] = useState([]);
  // const [pastNumbers, setPastNumbers] = useState([]);
  // const [totalCost, setTotalCost] = useState(0);
  // const [totalReturn, setTotalReturn] = useState(0);
  // const [numProfit, setNumProfit] = useState(0);
  const [allTrackedCases, setAllTrackedCases] = useState([]);
  const [allROIS, setAllROIs] = useState([]);
  const cachedIconSize = localStorage.getItem('invIconSize');
  const [iconSize, setIconSize] = React.useState(cachedIconSize || "large");

  const handleOptionsClick = (event) => {
    setOptions(!options);
  };

  const handleOptionsClose = () => {
    setOptions(false);
  };

  const handleIconSizeChange = (event, newValue) => {
    localStorage.setItem('invIconSize', event.target.value)
    setIconSize(event.target.value);
  };

  const handlePricingSourceChange = (event, newPricingSource) => {
    localStorage.setItem('pricingSource', newPricingSource.props.value)
    setPricingSource(newPricingSource.props.value);
  };

  const saveToLocalStorage = (key, value) => {
    localStorage.setItem(key, value.toString());
  };


  const CACHE_DURATION = 12 * 60 * 60 * 1000; // Cache duration in milliseconds (12 hours)

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const cachedCurrencies = localStorage.getItem('currencies');
        const cachedTimestamp = localStorage.getItem('currenciesTimestamp');

        if (
          cachedCurrencies &&
          cachedTimestamp &&
          Date.now() - Number(cachedTimestamp) < CACHE_DURATION
        ) {
          setCurrencies(JSON.parse(cachedCurrencies));
        } else {
          let url = '/currencies.json'

          if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
            url = 'https://csroi.com/currencies.json';
          }

          const response = await fetch(url);
          const data = await response.json();
          const currencyList = Object.keys(data);
          setCurrencies(currencyList);

          localStorage.setItem('currencies', JSON.stringify(currencyList));
          localStorage.setItem('currenciesTimestamp', Date.now().toString());
        }
      } catch (error) {
        console.error('Failed to fetch currencies:', error);
      }
    };

    const updateExchangeRateToUSD = async () => {
      try {
        const cachedCurrency = localStorage.getItem('currency');
        const cachedUSDExchangeRate = localStorage.getItem('usdExchangeRate');
        const cachedTimestamp = localStorage.getItem('usdExchangeRateTimestamp');


        if (
          cachedCurrency &&
          currency == cachedCurrency &&
          cachedUSDExchangeRate &&
          cachedTimestamp &&
          Date.now() - Number(cachedTimestamp) < CACHE_DURATION
        ) {
          setCurrency(cachedCurrency);
          // setPricingSource('USD');
          setExchangeRate(cachedUSDExchangeRate);

        } else {
          const response = await fetch(
            `https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/${currency.toLowerCase()}.json`
          );
          const data = await response.json();
          const usdExchangeRate = data[currency.toLowerCase()]['usd'];
          // console.log(data);

          console.log(currency);
          console.log(usdExchangeRate);

          setCurrency(currency);
          // setPricingSource('USD');
          setExchangeRate(usdExchangeRate);

          localStorage.setItem('currency', currency);
          localStorage.setItem('usdExchangeRate', usdExchangeRate);
          localStorage.setItem('usdExchangeRateTimestamp', Date.now().toString());


        }
      } catch (error) {
        console.error('Failed to update exchange rate to USD:', error);
      }
    };

    fetchCurrencies();
    updateExchangeRateToUSD();
  }, [currency]);

  const smallWearNames = {
    'FactoryNew': "FN",
    'MinimalWear': "MW",
    'FieldTested': "FT",
    'WellWorn': "WW",
    'BattleScarred': "BS",
  };

  const fetchROIData = async () => {

    let url = `rois/${pricingSource == "Buff" ? "buff163_rois" : pricingSource == "Skinport" ? "skinport_rois" : pricingSource == "CSFloat" ? "csfloat_rois" : pricingSource == "Tradeit" ? "steam_rois" : "steam_rois"}.json`;

    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
      url = 'http://localhost:8080/' + url;
    }

    const response = await fetch(url);
    const text = await response.text();
    // const processedText = `[${text.slice(0, -3)}]`;
    const processedData = JSON.parse(text);
    // console.log(processedData);




    // setItems(processedData.filter((x) => types.includes(x.CollectionType)));
    setAllROIs(processedData);


    //   setData(finalData)
  };

  useEffect(() => {
    fetchROIData();

  }, [pricingSource]);


  useEffect(() => {
    const fetchData = async () => {

      let url = '/pastData/allTrackedCases.json';

      if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
        url = 'http://localhost:8080/pastData/allTrackedCases.json';
      }

      const response = await fetch(url);
      const text = await response.text();
      // const processedText = `[${text.slice(0, -3)}]`;
      const processedData = JSON.parse(text);
      // console.log(processedData);




      // setItems(processedData.filter((x) => types.includes(x.CollectionType)));
      setAllTrackedCases(processedData);


      //   setData(finalData)
    };

    fetchROIData();
    fetchData();

  }, []);




  const ColumnCountContext = React.createContext();

  const redRarityNums = { 'Contraband': 223, 'Covert': 187, 'Classified': 158, 'Restricted': 104, 'Mil_Spec': 2, 'Industrial': 94, 'Consumer': 176 };
  const greenRarityNums = { 'Contraband': 199, 'Covert': 23, 'Classified': 0, 'Restricted': 50, 'Mil_Spec': 79, 'Industrial': 152, 'Consumer': 195 };
  const blueRarityNums = { 'Contraband': 0, 'Covert': 26, 'Classified': 197, 'Restricted': 234, 'Mil_Spec': 228, 'Industrial': 217, 'Consumer': 217 };
  const dataColors = { "Contraband": "gold", "Covert": "#eb4b4b", "Classified": "#d32ee6", "Restricted": "#8847ff", "Mil_Spec": "#4b69ff", "Industrial": "#5e98d9", "Consumer": "#B0C3D9", "CaseCost": "white", "ROI": "grey", "Profit": "lightgrey", "iROI": "green" };
  const rarityOrder = ['Contraband', 'Covert', 'Classified', 'Restricted', 'Mil_Spec', 'Industrial', 'Consumer'];
  const otherRarityNames = { 'Extraordinary': 'Covert', 'Exotic': 'Classified', 'Remarkable': 'Restricted', 'High_Grade': 'Mil_Spec', 'Superior': 'Classified', 'Master': 'Covert', 'Exceptional': 'Restricted', 'Distinguished': 'Mil_Spec' }
  const realWearNames = { 'FactoryNew': 'Factory New', 'MinimalWear': 'Minimal Wear', 'FieldTested': 'Field-Tested', 'WellWorn': 'Well-Worn', 'BattleScarred': 'Battle-Scarred' };

  const handleSortDirection = (sortTest) => {
    setSortDirection(sortTest);
    localStorage.setItem('sortDirectionInv', sortTest.toString());
    // setSelectedItem(item);
    // setSelectedRarity(rarity)
  };

  function formatNumber(number, isCurrency, maximumFractionDigits = 3) {
    // Use the user's locale
    const userLocale = navigator.language || 'en-US';

    // Create Intl.NumberFormat with user's locale
    if (isCurrency) {
      const formatter = new Intl.NumberFormat(userLocale, {
        //   minimumFractionDigits: 1,
        style: "currency",
        currency: currency,
        maximumFractionDigits: number > 1000 ? 0 : number > 100 ? maximumFractionDigits > 1 ? 1 : maximumFractionDigits : number > 0.01 ? maximumFractionDigits > 2 ? 2 : maximumFractionDigits : maximumFractionDigits > 3 ? 3 : maximumFractionDigits,
      });
      return formatter.format(number);
    } else {
      const formatter = new Intl.NumberFormat(userLocale, {
        //   minimumFractionDigits: 1,
        maximumFractionDigits: number > 1000 ? 0 : number > 100 ? maximumFractionDigits > 1 ? 1 : maximumFractionDigits : number > 0.01 ? maximumFractionDigits > 2 ? 2 : maximumFractionDigits : maximumFractionDigits > 3 ? 3 : maximumFractionDigits,

      });
      return formatter.format(number);
    }

    // Format the number

  }

  // Context creation
  const RangeSelectionContext = React.createContext();

  function RenderCollectionsAndStats() {
    const { selection, setCurrentCollection, currentCollection, customCollections } = React.useContext(RangeSelectionContext);
    const [collections, setCollections] = React.useState({});
    const [renderItems, setRenderItems] = React.useState([]);
    const [allTotalCost, setAllTotalCost] = React.useState(0);
    const [allTotalCostUnbox, setAllTotalCostUnbox] = React.useState(0);
    const [allTotalCost1Y, setAllTotalCost1Y] = React.useState(0);
    const [allTotalCost6M, setAllTotalCost6M] = React.useState(0);
    const [allTotalCost1M, setAllTotalCost1M] = React.useState(0);
    const [allValueCost, setAllValueCost] = React.useState(0);
    const [allValueCostUnbox, setAllValueCostUnbox] = React.useState(0);
    const [allNumProfit, setAllNumProfit] = React.useState(0);
    const [allNumTotal, setAllNumTotal] = React.useState(0);
    const [allNumTotalUnbox, setAllNumTotalUnbox] = React.useState(0);

    React.useEffect(() => {
      var tempValueCostUnbox = 0;
      var tempTotalCostUnbox = 0;
      var tempTotalCost = 0;
      var tempTotalCost1Y = 0;
      var tempTotalCost6M = 0;
      var tempTotalCost1M = 0;
      var tempValueCost = 0;
      var tempNumProfit = 0;
      var tempNumTotal = 0;
      var tempNumTotalUnbox = 0;
      if (selection.end == null && selection.start == null) {
        const allSelected = [...selection.selectedIndices].map(
          (index) => {
            if (!(customCollections?.[index] != null)) {
              return assets[index];
            } else {
              var customAsset = assets[index];
              customAsset["collectionName"] = customCollections[index];
              customAsset["collectionCost"] = customAsset.extraCollections[customCollections[index]].collectionCost;
              customAsset["collectionImage"] = customAsset.extraCollections[customCollections[index]].collectionImage;
              customAsset["collectionUrl"] = customAsset.extraCollections[customCollections[index]].collectionUrl;
            }
            return assets[index];
          }
        );
        const newCollections = {};
        //Q: Explain code above
        //A: It creates a new array of all the selected assets from the assets array
        //Q: How do check if the index is inside of the customCollections within this: const allSelected = [...selection.selectedIndices].map(index => assets[index]);
        //A: 

        allSelected.forEach(item => {

          const collectionKey = `${item.collectionName}`;
          if (collectionKey != "undefined") {
            if (!newCollections[collectionKey]) {
              newCollections[collectionKey] = [];
            }
            newCollections[collectionKey].push(item);
          } else {
            tempNumTotal += item.numItems;
            tempValueCost += item.itemCost * item.numItems;
            tempTotalCost1Y += (item.oneYearPrice * item.numItems);
            tempTotalCost6M += (item.sixMonthPrice * item.numItems);
            tempTotalCost1M += (item.oneMonthPrice * item.numItems);
            // tempTotalCost1Y += (item.itemCost * item.numItems) / (1 + ((item?.oneYearROI ?? 0) / 100));
            // tempTotalCost6M += (item.itemCost * item.numItems) / (1 + ((item?.sixMonthROI ?? 0) / 100));
            // tempTotalCost1M += (item.itemCost * item.numItems) / (1 + ((item?.oneMonthROI ?? 0) / 100));
          }
        });
        //Q: Explain code above
        //A: It creates a new object with the collection name as the key and the assets as the value

        setCollections(newCollections);

        // Calculate render items
        const itemsToRender = [];
        Object.entries(newCollections).forEach(([collectionName, assets]) => {
          const asset = assets[0];
          if (collectionName != undefined && asset?.rarityChances != null) {

            // console.log(collectionName);

            const matchingItemIndex = allTrackedCases.findIndex((item) => item.Name == collectionName);
            var item = matchingItemIndex != -1 ? allTrackedCases[matchingItemIndex] : {};
            var valueCost = 0;
            var valueCost1Y = 0;
            var valueCost6M = 0;
            var valueCost1M = 0;
            var totalCost = 0;
            var backupCost = 0;
            var numProfit = 0;
            var numTotal = 0;
            var numTotalUnbox = 0;
            var allRarityValues = {};

            assets.sort((a, b) => b.itemCost - a.itemCost).forEach(asset => {
              valueCost += asset.itemCost * asset.numItems;
              valueCost1Y += asset.oneYearPrice * asset.numItems;
              valueCost6M += asset.sixMonthPrice * asset.numItems;
              valueCost1M += asset.oneMonthPrice * asset.numItems;
              if (asset.collectionCost > 0.01) {
                totalCost += (asset.collectionCost + asset.keyCost) * asset.numItems;
              } else {
                backupCost += (asset.collectionCost + asset.keyCost) * asset.numItems;
              }
              if (asset.itemCost >= asset.collectionCost) {
                numProfit += asset.numItems;
              }
              if (allRarityValues[asset.rarity] == null) {
                allRarityValues[asset.rarity] = { "totalCost": asset.itemCost * asset.numItems, "numItems": asset.numItems };
              } else {
                allRarityValues[asset.rarity]["totalCost"] += asset.itemCost * asset.numItems;
                allRarityValues[asset.rarity]["numItems"] += asset.numItems;
              }
              numTotalUnbox += asset.numItems;

            });

            tempNumTotal += numTotalUnbox;
            tempValueCost += valueCost;
            tempTotalCost1Y += valueCost1Y;
            tempTotalCost6M += valueCost6M;
            tempTotalCost1M += valueCost1M;

            if (totalCost == 0) {
              item["YourCost"] = backupCost;
            } else {
              item["YourCost"] = totalCost;
            }
            tempTotalCostUnbox += totalCost;
            tempValueCostUnbox += valueCost;
            item["YourReturn"] = valueCost;
            item["YourROI"] = valueCost / item["YourCost"];
            item["YourProfit"] = numProfit / numTotalUnbox;
            tempNumProfit += numProfit;
            item["UrlName"] = null;
            item["NumItems"] = numTotalUnbox;
            item["AllRarityValues"] = allRarityValues;
            item["ExpectedRarityValues"] =
              pricingSource == "Steam" ? item["RarityValuesSteam"] :
                pricingSource == "CSFloat" ? item["RarityValuesCSFloat"] :
                  pricingSource == "Tradeit" ? item["RarityValuesTradeit"] :
                    pricingSource == "Buff" ? item["RarityValuesBuff"] : item["RarityValuesSkinport"];
            tempNumTotalUnbox += numTotalUnbox;
            // console.log(item);

            var tempExpectedReturn = 0;

            Object.entries(asset.rarityChances).forEach(([name, percent]) => {
              tempExpectedReturn += ((item.ExpectedRarityValues?.[name] ?? 0) * item?.NumItems * percent);
            });

            item["ExpectedReturn"] = tempExpectedReturn;

            if (asset.itemCost != null && (asset.collectionCost > 0.01 || includeFreeItems)) {
              itemsToRender.push({
                collectionName,
                item,
                assets,
                collectionUrl: asset.collectionUrl,
              });
            }
          }
        });
        setRenderItems(itemsToRender);
        setCurrentCollection(itemsToRender[0]);
        setAllTotalCostUnbox(tempTotalCostUnbox);
        setAllTotalCost(tempTotalCost);
        setAllTotalCost1Y(tempTotalCost1Y);
        setAllTotalCost6M(tempTotalCost6M);
        setAllTotalCost1M(tempTotalCost1M);
        setAllValueCost(tempValueCost);
        setAllValueCostUnbox(tempValueCostUnbox);
        setAllNumProfit(tempNumProfit);
        setAllNumTotal(tempNumTotal);
        setAllNumTotalUnbox(tempNumTotalUnbox);
      }
      // Usage

    }, [selection.selectedIndices,]); // Add selection.end as a dependency

    return ((Object.keys(collections).length > 0) || allNumTotal > 0) && (

      <div style={{ margin: 'auto' }} id='investStats'>
        {/* <h2 style={{margin: 'auto', textAlign: 'center'}}>Investing:</h2> */}
        <Card sx={{ marginTop: '16px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', padding: '12px' }}>
            {allTotalCost != null &&
              <Tooltip title="Number of items selected" sx={{ margin: 'auto', display: "initial" }}>
                <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'start', flexDirection: 'column', textAlign: 'center', marginBottom: '8px', marginTop: '8px', fontSize: '1.2rem', lineHeight: 0.9 }}>
                  Selected Items

                  {<h2 style={{ marginTop: '16px' }}>{formatNumber((allNumTotal))}</h2>}

                </div>

              </Tooltip>
            }
            {allTotalCost != null &&
              <Tooltip title="Total value of the selected items now" sx={{ margin: 'auto', display: "initial" }}>
                <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'start', flexDirection: 'column', textAlign: 'center', marginBottom: '8px', marginTop: '8px', fontSize: '1.2rem', lineHeight: 0.9 }}>
                  Value Now
                  {/* <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <AttachMoneyIcon className="iconSVG" sx={{ width: '48px', height: '48px', margin: '2px', marginRight: '-8px' }} fontSize='large' />
                    <ArrowDownwardIcon className="iconSVG" sx={{ width: '20px', height: '30px', margin: '2px', marginLeft: '-8px' }} fontSize='large' />
                  </div> */}
                  {<h2 style={{ marginTop: '16px' }}>{formatNumber((allValueCost / exchangeRate), true)}</h2>}

                </div>

              </Tooltip>
            }
            {allTotalCost != null &&
              <Tooltip title="Total value of the selected items around a month ago" sx={{ margin: 'auto', display: "initial" }}>
                <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', marginBottom: '8px', marginTop: '8px', fontSize: '1.2rem', lineHeight: 0.9 }}>
                  1 Month Ago
                  <div style={{ display: 'flex', flexDirection: 'column', marginTop: '16px' }}>

                    {/* <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <AttachMoneyIcon className="iconSVG" sx={{ width: '48px', height: '48px', margin: '2px', marginRight: '-8px' }} fontSize='large' />
                    <ArrowUpwardIcon className="iconSVG" sx={{ width: '20px', height: '30px', margin: '2px', marginLeft: '-8px' }} fontSize='large' />
                  </div> */}
                    {<h2 style={{ margin: 0 }}>{formatNumber(allTotalCost1M / exchangeRate, true)}</h2>}
                    <div style={{ display: 'flex', color: ((allValueCost / ((allTotalCost1M ?? allValueCost)) - 1) * 100) > -0.01 ? "green" : "rgb(187, 23, 26)", fontSize: '20px', lineHeight: '0.5', marginTop: '4px', marginBottom: 'auto', paddingBottom: '7px', paddingLeft: '2px', flexDirection: 'column', alignItems: 'center' }}>{((allValueCost / ((allTotalCost1M ?? allValueCost)) - 1) * 100) > -0.01 ? <TrendingUpIcon style={{ width: '24px', height: "18px" }} /> : <TrendingDownIcon style={{ width: '24px', height: "18px" }} />}{(((allValueCost / ((allTotalCost1M ?? allValueCost)) - 1) * 100) ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs((allValueCost / ((allTotalCost1M ?? allValueCost)) - 1) * 100), false, 2)}%</div>

                  </div>
                </div>

              </Tooltip>
            }
            {allTotalCost != null &&
              <Tooltip title="Total value of the selected items around 6 months ago" sx={{ margin: 'auto', display: "initial" }}>
                <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', marginBottom: '8px', marginTop: '8px', fontSize: '1.2rem', lineHeight: 0.9 }}>
                  6 Months Ago
                  <div style={{ display: 'flex', flexDirection: 'column', marginTop: '16px' }}>

                    {/* <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <AttachMoneyIcon className="iconSVG" sx={{ width: '48px', height: '48px', margin: '2px', marginRight: '-8px' }} fontSize='large' />
                    <ArrowUpwardIcon className="iconSVG" sx={{ width: '20px', height: '30px', margin: '2px', marginLeft: '-8px' }} fontSize='large' />
                  </div> */}
                    {<h2 style={{ margin: 0 }}>{formatNumber(allTotalCost6M / exchangeRate, true)}</h2>}
                    <div style={{ display: 'flex', color: ((allValueCost / ((allTotalCost6M ?? allValueCost)) - 1) * 100) > -0.01 ? "green" : "rgb(187, 23, 26)", fontSize: '20px', lineHeight: '0.5', marginTop: '4px', marginBottom: 'auto', paddingBottom: '7px', paddingLeft: '2px', flexDirection: 'column', alignItems: 'center' }}>{((allValueCost / ((allTotalCost6M ?? allValueCost)) - 1) * 100) > -0.01 ? <TrendingUpIcon style={{ width: '24px', height: "18px" }} /> : <TrendingDownIcon style={{ width: '24px', height: "18px" }} />}{(((allValueCost / ((allTotalCost6M ?? allValueCost)) - 1) * 100) ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs((allValueCost / ((allTotalCost6M ?? allValueCost)) - 1) * 100), false, 2)}%</div>

                  </div>
                </div>

              </Tooltip>
            }
            {allTotalCost != null &&
              <Tooltip title="Total value of the selected items around a year ago" sx={{ margin: 'auto', display: "initial" }}>
                <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', marginBottom: '8px', marginTop: '8px', fontSize: '1.2rem', lineHeight: 0.9 }}>
                  1 Year Ago
                  <div style={{ display: 'flex', flexDirection: 'column', marginTop: '16px' }}>

                    {/* <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <AttachMoneyIcon className="iconSVG" sx={{ width: '48px', height: '48px', margin: '2px', marginRight: '-8px' }} fontSize='large' />
                    <ArrowUpwardIcon className="iconSVG" sx={{ width: '20px', height: '30px', margin: '2px', marginLeft: '-8px' }} fontSize='large' />
                  </div> */}
                    {<h2 style={{ margin: 0 }}>{formatNumber(allTotalCost1Y / exchangeRate, true)}</h2>}
                    <div style={{ display: 'flex', color: ((allValueCost / ((allTotalCost1Y ?? allValueCost)) - 1) * 100) > -0.01 ? "green" : "rgb(187, 23, 26)", fontSize: '20px', lineHeight: '0.5', marginTop: '4px', marginBottom: 'auto', paddingBottom: '7px', paddingLeft: '2px', flexDirection: 'column', alignItems: 'center' }}>{((allValueCost / ((allTotalCost1Y ?? allValueCost)) - 1) * 100) > -0.01 ? <TrendingUpIcon style={{ width: '24px', height: "18px" }} /> : <TrendingDownIcon style={{ width: '24px', height: "18px" }} />}{(((allValueCost / ((allTotalCost1Y ?? allValueCost)) - 1) * 100) ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs((allValueCost / ((allTotalCost1Y ?? allValueCost)) - 1) * 100), false, 2)}%</div>

                  </div>
                </div>

              </Tooltip>
            }
            {/* {allNumProfit != null &&
              <Tooltip title="Percent of unboxings that were profitable" sx={{ margin: 'auto', display: "initial" }}>
                <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', marginBottom: '8px', marginTop: '8px', fontSize: '1.2rem', lineHeight: 0.9 }}>
                  Profitable Unboxings
                  <PriceCheckIcon className="iconSVG" sx={{ width: '48px', height: '48px', margin: '2px' }} fontSize='large' />
                  <h2 style={{ lineHeight: 0.5, margin: 0 }}>{formatNumber((allNumProfit / allNumTotal) * 100)}%</h2>
                </div>

              </Tooltip>
            } */}
          </div>
        </Card>

        {/* <Divider style={{marginTop: '8px', marginBottom: '8px'}}/> */}
        {/* <h2 style={{margin: 'auto', textAlign: 'center'}}>Unboxing:</h2> */}

        <div style={{ display: Object.keys(collections).length > 0 ? "block" : "none" }}>
          <Card sx={{ marginTop: '16px' }}>

            <div style={{ display: 'flex', flexDirection: 'row', padding: '12px' }}>
              {allTotalCost != null &&
                <Tooltip title="Total number of unboxes" sx={{ margin: 'auto', display: "initial" }}>
                  <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', marginBottom: '8px', marginTop: '8px', fontSize: '1.2rem', lineHeight: 0.9 }}>
                    Unboxable Items
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <img src={OpenBox} className="iconSVG" style={{ width: '48px', height: '48px' }} />
                    </div>
                    {<h2 style={{ lineHeight: 0.5, margin: 0 }}>{formatNumber((allNumTotalUnbox))}</h2>}

                  </div>

                </Tooltip>
              }
              {allTotalCost != null &&
                <Tooltip title="Total cost for all unboxes" sx={{ margin: 'auto', display: "initial" }}>
                  <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', marginBottom: '8px', marginTop: '8px', fontSize: '1.2rem', lineHeight: 0.9 }}>
                    Unbox Cost
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <AttachMoneyIcon className="iconSVG" sx={{ width: '48px', height: '48px', margin: '2px', marginRight: '-8px' }} fontSize='large' />
                      <ArrowDownwardIcon className="iconSVG" sx={{ width: '20px', height: '30px', margin: '2px', marginLeft: '-8px' }} fontSize='large' />
                    </div>
                    {<h2 style={{ lineHeight: 0.5, margin: 0 }}>{formatNumber((allTotalCostUnbox / exchangeRate), true)}</h2>}

                  </div>

                </Tooltip>
              }
              {allTotalCost != null &&
                <Tooltip title="Total value of all items unboxed" sx={{ margin: 'auto', display: "initial" }}>
                  <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', marginBottom: '8px', marginTop: '8px', fontSize: '1.2rem', lineHeight: 0.9 }}>
                    Unbox Value
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <AttachMoneyIcon className="iconSVG" sx={{ width: '48px', height: '48px', margin: '2px', marginRight: '-8px' }} fontSize='large' />
                      <ArrowUpwardIcon className="iconSVG" sx={{ width: '20px', height: '30px', margin: '2px', marginLeft: '-8px' }} fontSize='large' />
                    </div>
                    {<h2 style={{ lineHeight: 0.5, margin: 0 }}>{formatNumber(allValueCostUnbox / exchangeRate, true)}</h2>}

                  </div>

                </Tooltip>
              }
              {allTotalCost != null &&
                <Tooltip title="Total value gained/lost from unboxing" sx={{ margin: 'auto', display: "initial" }}>
                  <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', marginBottom: '8px', marginTop: '8px', fontSize: '1.2rem', lineHeight: 0.9 }}>
                    Total Profit/Loss
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <AttachMoneyIcon className="iconSVG" sx={{ width: '48px', height: '48px', margin: '2px', marginRight: '-8px' }} fontSize='large' />
                      {/* <ArrowUpwardIcon className="iconSVG" sx={{width: '20px', height: '48px', margin: '2px', marginLeft: '-8px'}} fontSize='large' />  */}
                    </div>
                    {<h2 style={{ lineHeight: 0.5, margin: 0 }}>{(allValueCostUnbox - allTotalCostUnbox) > 0 ? "" : "-"}{formatNumber(Math.abs(allValueCostUnbox - allTotalCostUnbox) / exchangeRate, true)}</h2>}

                  </div>

                </Tooltip>
              }
              {allTotalCost != null &&
                <Tooltip title="Your Return on Investment for your unboxings" sx={{ margin: 'auto', display: "initial" }}>
                  <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', marginBottom: '8px', marginTop: '8px', fontSize: '1.2rem', lineHeight: 0.9 }}>
                    Total ROI
                    {<img src={OpenBox} className="iconSVGMob" style={{ width: '48px', height: '48px', margin: '2px' }} />}
                    {<h2 style={{ lineHeight: 0.5, margin: 0 }}>{formatNumber((allValueCostUnbox / allTotalCostUnbox) * 100)}%</h2>}

                  </div>

                </Tooltip>
              }
              {allNumProfit != null &&
                <Tooltip title="Percent of unboxings that were profitable" sx={{ margin: 'auto', display: "initial" }}>
                  <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', marginBottom: '8px', marginTop: '8px', fontSize: '1.2rem', lineHeight: 0.9 }}>
                    Profitable Unboxings
                    <PriceCheckIcon className="iconSVG" sx={{ width: '48px', height: '48px', margin: '2px' }} fontSize='large' />
                    <h2 style={{ lineHeight: 0.5, margin: 0 }}>{formatNumber((allNumProfit / allNumTotalUnbox) * 100)}%</h2>
                  </div>

                </Tooltip>
              }
            </div>
          </Card>
          <Card sx={{ marginTop: '16px', paddingLeft: '16px', paddingRight: '16px' }}>
            {/* <h2>Your Unboxings</h2> */}


            <div style={{ display: 'flex', flexDirection: 'row', position: 'relative', overflowX: "scroll", height: '100%', paddingBottom: '8px', width: '100%', maxWidth: '82vw', marginLeft: 'auto', marginRight: 'auto' }}>
              {renderItems.map(({ collectionName, item, assets, collectionUrl }, index) => (
                <div style={{ display: 'flex', flexDirection: 'row', borderRadius: '4px', paddingLeft: '8px', paddingRight: '4px', border: currentCollection?.collectionName !== collectionName ? "none" : '2px solid rgba(255, 255, 255, 0.5)' }}>
                  {currentCollection?.collectionName == collectionName &&
                    <Card style={{ backgroundColor: 'rgba(4,4,4,1)', height: '228px', width: '256px', marginTop: '4px', padding: '8px', border: '1px solid rgba(81, 81, 81, 1)', borderRight: 0, maxHeight: '100%', marginBottom: '4px', marginRight: '-8px', paddingRight: '12px', marginTop: '8px', }}>

                      {/* <h4 style={{margin: '2px', textAlign: 'center'}}>Collection Price</h4> */}
                      {currentCollection?.item?.CollectionPriceSteam != 0.01 &&
                        <div>
                          <Button variant="outlined" onClick={() => window.open(steamLink(currentCollection?.collectionName, null, null, null), "_blank")} style={{ color: 'white', padding: '4px', margin: 'auto', width: '100%', height: "32px", backgroundColor: pricingSource == "Steam" }}>
                            <img style={{ height: "20px", marginLeft: '4px' }} src={window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" ? "http://localhost:8080/assets/steamlogo.png" : "/assets/steamlogo.png"} />

                            <p style={{ margin: 'auto' }}>Steam</p>
                            <p style={{ marginRight: '4px' }}>{formatNumber(currentCollection.item.CollectionPriceSteam / exchangeRate, true)}</p>
                          </Button>
                          <Button variant="outlined" onClick={() => window.open(buffLink(currentCollection?.collectionName, null, null, null), "_blank")} style={{ color: 'white', margin: 'auto', width: '100%', height: "32px", padding: '4px' }}>
                            <img style={{ height: "20px", marginLeft: '4px' }} src={window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" ? "http://localhost:8080/assets/uupinlogo.png" : "/assets/uupinlogo.png"} />

                            <p style={{ margin: 'auto' }}>Buff.163/UUPin</p>
                            <p style={{ marginRight: '4px' }}>{formatNumber(currentCollection.item.CollectionPriceBuff / exchangeRate, true)}</p>
                          </Button>
                          <Button variant="outlined" onClick={() => window.open(csfloatLink(currentCollection?.collectionName, null, null, null), "_blank")} style={{ color: 'white', margin: 'auto', width: '100%', height: "32px", padding: '4px' }}>
                            <img style={{ height: "20px", marginLeft: '4px' }} src={window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" ? "http://localhost:8080/assets/csfloatlogo.png" : "/assets/csfloatlogo.png"} />

                            <p style={{ margin: 'auto' }}>CSFloat</p>
                            <p style={{ marginRight: '4px' }}>{formatNumber(currentCollection.item.CollectionPriceCSFloat / exchangeRate, true)}</p>
                          </Button>
                          <Button variant="outlined" onClick={() => window.open(steamLink(currentCollection?.collectionName, null, null, null), "_blank")} style={{ color: 'white', margin: 'auto', width: '100%', height: "32px", padding: '4px' }}>
                            <img style={{ height: "20px", marginLeft: '4px' }} src={window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" ? "http://localhost:8080/assets/skinportlogo.png" : "/assets/skinportlogo.png"} />

                            <p style={{ margin: 'auto' }}>Skinport</p>
                            <p style={{ marginRight: '4px' }}>{formatNumber(currentCollection.item.CollectionPriceSkinport / exchangeRate, true)}</p>
                          </Button>
                          <Button variant="outlined" onClick={() => window.open(tradeitLink(currentCollection?.collectionName, null, null, null), "_blank")} style={{ color: 'white', margin: 'auto', width: '100%', height: "32px", padding: '4px' }}>
                            <img style={{ height: "20px", marginLeft: '4px' }} src={window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" ? "http://localhost:8080/assets/tradeitlogo.svg" : "/assets/tradeitlogo.svg"} />

                            <p style={{ margin: 'auto' }}>Tradeit.gg</p>
                            <p style={{ marginRight: '4px', color: (currentCollection.item.TradeitEstimateCase && pricingSource == "Tradeit") ? "rgb(131, 131, 131)" : "white" }}>{formatNumber(currentCollection.item.CollectionPriceTradeit / exchangeRate, true)}</p>
                          </Button>
                          <Divider style={{ margin: '6px', marginTop: 'auto' }} />
                        </div>
                      }
                      <Button variant="outlined" onClick={() => window.open(`${currentCollection.collectionUrl}`, "_blank")} style={{ color: 'white', width: '100%' }}>COLLECTION INFO</Button>

                      {/* <Button variant="outlined" onClick={() => window.open(`steam://openurl/https://steamcommunity.com/inventory/${id}/730/2?l=english&count=1000`, "_blank")} style={{  color: 'white', margin: 'auto', width: '100%'   }}>CSFloat - {formatNumber(currentCollection.item.CollectionPriceSteam / exchangeRate, true)}</Button> */}

                    </Card>}
                  <div onClick={() => setCurrentCollection({ "collectionName": collectionName, "item": item, "assets": assets, collectionUrl: collectionUrl })} key={index} style={{ minWidth: '400px', maxWidth: '400px', }}>
                    <CaseGridItem
                      item={item}
                      pricingSource={pricingSource}
                      currency={currency}
                      exchangeRate={exchangeRate}
                      sortBy={"unbox"}
                      isMobileLayout={isMobile}
                      showUnbox={true}
                      showProfit={true}
                      showInvest={false}
                      numListings={0}
                      showListings={false}
                      alwaysShadow={true}
                    />
                    {/* {currentCollection?.collectionName == collectionName && <ArrowDownwardIcon className="iconSVG" sx={{ width: '30px', height: '30px', margin: 'auto', display: 'block' }} fontSize='large' />} */}
                  </div>

                  {/*<Table aria-label="simple table" style={{ flex: 2, width: '18vw' }}>
                  <TableHead>
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      key={"head"}
                    >
                      <TableCell component="th" scope="row" align="center" style={{ border: '1px solid rgba(81, 81, 81, 1)' }}>
                        Cost<br />
                        {formatNumber(currentCollection.item.YourCost / exchangeRate, true)}
                      </TableCell>
                      <TableCell align="center" colSpan={2} style={{ border: '1px solid rgba(81, 81, 81, 1)' }}>Received<br />{formatNumber(currentCollection.item.YourReturn / exchangeRate, true)}</TableCell>
                      <TableCell align="center" colSpan={2} style={{ border: '1px solid rgba(81, 81, 81, 1)' }}>Expected<br />{formatNumber(((pricingSource == "Steam" ? currentCollection.item.SteamROI : pricingSource == "Buff" ? currentCollection.item.BuffROI : currentCollection.item.SkinportROI) * currentCollection.item.YourCost) / exchangeRate, true)}</TableCell>


                    </TableRow>

                  </TableHead>
                  <TableBody>
                    {Object.entries(currentCollection.assets[0].rarityChances).map(([name, percent], index, array) => {
                      var convertedRarityName = Object.keys(otherRarityNames).includes(name) ? otherRarityNames[name] : name;
                      const isLastRow = index === array.length - 1; // Check if current row is the last row
                      return <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          ...(isLastRow && { // Apply border to all cells if it's the last row
                            '& td, & th': {
                              borderBottom: '2px solid rgba(81, 81, 81, 1)',
                            },
                          }),
                        }}
                        key={name}
                      >
                        <TableCell component="th" scope="row" style={{ color: dataColors[convertedRarityName], borderRight: '1px solid rgba(81, 81, 81, 1)', borderLeft: '1px solid rgba(81, 81, 81, 1)', ...(isLastRow && { borderBottom: '2px solid rgba(81, 81, 81, 1)' }) }}>
                          {name.replace('_', '-').replace('Contraband', 'Gloves / Knives')}
                        </TableCell>
                        <TableCell align="right" style={{ ...(isLastRow && { borderBottom: '2px solid rgba(81, 81, 81, 1)' }) }}>{currentCollection.item?.AllRarityValues?.[name]?.["numItems"] ?? 0}</TableCell>
                        <TableCell align="right" style={{ borderRight: '1px solid rgba(81, 81, 81, 1)', ...(isLastRow && { borderBottom: '2px solid rgba(81, 81, 81, 1)' }) }}>{formatNumber((currentCollection.item?.AllRarityValues?.[name]?.["totalCost"] ?? 0) / exchangeRate, true)}</TableCell>
                        <TableCell align="right" style={{ ...(isLastRow && { borderBottom: '2px solid rgba(81, 81, 81, 1)' }) }}>{formatNumber(percent * currentCollection.item?.NumItems)}</TableCell>
                        <TableCell align="right" style={{ borderRight: '1px solid rgba(81, 81, 81, 1)', ...(isLastRow && { borderBottom: '2px solid rgba(81, 81, 81, 1)' }) }}>{formatNumber(((currentCollection.item.ExpectedRarityValues?.[name] ?? 0) * currentCollection.item?.NumItems * percent) / exchangeRate, true)}</TableCell>
                      </TableRow>
                    })}
                  </TableBody>
                </Table>} */}
                </div>
              ))}
            </div>

            {/* {currentCollection != null && 
        <Table aria-label="simple table" style={{ flex: 2, width: '100%', marginTop: '4px' }}>
                    
        <TableBody>
            <TableRow
                    sx={{ display: 'flex', width: '100%', flexDirection: 'row',borderBottom: '2px solid #000' }}
                    key={"raritiesSim"}
                >
                  <TableCell  component="th" scope="row" align="center" sx={{fontSize: '13px', lineHeight: 1, padding: '10px', flex: '1', }}/>
            {Object.entries(currentCollection.assets[0].rarityChances).map(([name, percent]) => {
                var convertedRarityName = Object.keys(otherRarityNames).includes(name) ? otherRarityNames[name] : name;
                return <TableCell component="th" scope="row" align="center" sx={{fontSize: '13px', lineHeight: 1, padding: '10px', color: dataColors[convertedRarityName], flex: '2' }}>
                {name.replace('_', '-').replace('Contraband', 'Gloves / Knives')}
                </TableCell>
            })
            }
             </TableRow>
            <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, display: 'flex', width: '100%', flexDirection: 'row',borderBottom: 0 }}
                    key={"numRaritiesSim"}
                >
                  <TableCell component="th" scope="row" align="center" sx={{fontSize: '13px', lineHeight: 1, padding: '10px', flex: '1', borderBottom: "none"}}>
                Received
                </TableCell>
            {Object.entries(currentCollection.assets[0].rarityChances).map(([name, percent]) => {
                var convertedRarityName = Object.keys(otherRarityNames).includes(name) ? otherRarityNames[name] : name;
                return <TableCell sx={{fontSize: '13px', lineHeight: 1, padding: '10px', flex: '2', borderBottom: "none"}} align="center">{currentCollection.item?.AllRarityValues?.[name]?.["numItems"] ?? 0}</TableCell>
                   
            })
            
            }
            </TableRow>
            <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, display: 'flex', width: '100%', flexDirection: 'row',borderBottom: '2px solid #000' }}
                    key={"valuesRaritiesSim"}
                >
                  <TableCell component="th" scope="row" align="center" sx={{fontSize: '13px', lineHeight: 1, padding: '10px', flex: '1'}}>
                
                </TableCell>
            {Object.entries(currentCollection.assets[0].rarityChances).map(([name, percent]) => {
                var convertedRarityName = Object.keys(otherRarityNames).includes(name) ? otherRarityNames[name] : name;
                return <TableCell sx={{fontSize: '13px', lineHeight: 1, padding: '10px', flex: '2'}} align="center">{formatNumber(currentCollection.item?.AllRarityValues?.[name]?.["totalCost"] ?? 0 / exchangeRate, true)}</TableCell>
                
            } )}
            </TableRow>
            <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, display: 'flex', width: '100%', flexDirection: 'row',borderBottom: 0 }}
                    key={"numRaritiesSim"}
                >
                  <TableCell component="th" scope="row" align="center" sx={{fontSize: '13px', lineHeight: 1, padding: '10px', flex: '1', borderBottom: "none"}}>
                Expected
                </TableCell>
            {Object.entries(currentCollection.assets[0].rarityChances).map(([name, percent]) => {
                var convertedRarityName = Object.keys(otherRarityNames).includes(name) ? otherRarityNames[name] : name;
                return <TableCell sx={{fontSize: '13px', lineHeight: 1, padding: '10px', flex: '2', borderBottom: "none"}} align="center">{formatNumber(percent * allNumTotal)}</TableCell>
                   
            })
            
            }
            </TableRow>
            <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, display: 'flex', width: '100%', flexDirection: 'row',borderBottom: '2px solid #000' }}
                    key={"valuesRaritiesSim"}
                >
                  <TableCell component="th" scope="row" align="center" sx={{fontSize: '13px', lineHeight: 1, padding: '10px', flex: '1', borderBottom: "1px solid rgba(81, 81, 81, 1) !important"}}>
                
                </TableCell>
            {Object.entries(currentCollection.item.ExpectedRarityValues).map(([name, percent]) => {
                var convertedRarityName = Object.keys(otherRarityNames).includes(name) ? otherRarityNames[name] : name;
                return <TableCell sx={{fontSize: '13px', lineHeight: 1, padding: '10px', flex: '2', borderBottom: "1px solid rgba(81, 81, 81, 1) !important"}} align="center">{formatNumber((percent * allNumTotal * currentCollection.assets[0].rarityChances[name])/exchangeRate, true)}</TableCell>
                
            } )}
            </TableRow>
        </TableBody>
    </Table>
                } */}

            {currentCollection != null && <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', position: 'relative', overflowX: "auto" }}>

              {currentCollection?.collectionName != null &&
                <Card style={{ backgroundColor: 'rgba(4,4,4,1)', width: '100%', marginLeft: '8px', marginRight: '8px', marginTop: '4px', border: '1px solid rgba(81, 81, 81, 1)', maxHeight: '100%', marginBottom: '12px', marginTop: '12px', }}>
                  <Table aria-label="simple table" style={{ height: '100%', width: '100%', whiteSpace: 'nowrap' }}>

                    <TableBody >
                      <TableRow
                        sx={{ width: '100%', flexDirection: 'row', border: '1px solid rgba(81, 81, 81, 1)', backgroundColor: 'rgba(255, 255, 255, 0.1)', borderLeft: 'none', borderTop: 'none', borderRight: 'none', borderRadius: '4px' }}
                        key={"raritiesSim"}
                      >

                        <TableCell component="th" scope="row" align="center" sx={{ fontSize: '13px', lineHeight: 1, flex: '1', borderRight: "1px solid rgba(81, 81, 81, 1) !important" }} />
                        {Object.entries(currentCollection.assets[0].rarityChances).map(([name, percent]) => {
                          var convertedRarityName = Object.keys(otherRarityNames).includes(name) ? otherRarityNames[name] : name;
                          return <TableCell component="th" scope="row" align="center" sx={{ fontSize: '13px', lineHeight: 1, color: dataColors[convertedRarityName], flex: '2' }}>
                            {name.replace('_', '-').replace('Contraband', 'Gloves / Knives')} - {formatNumber(percent * 100, false, 2)}%
                          </TableCell>
                        })
                        }
                      </TableRow>
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.1)', flexDirection: 'row', border: '1px solid rgba(81, 81, 81, 1)', borderBottom: 0, borderRight: 'none', borderLeft: 'none' }}
                        key={"numRaritiesSim"}
                      >
                        <TableCell component="th" scope="row" align="center" sx={{ fontSize: '13px', lineHeight: 1, flex: '1', borderBottom: "none", borderRight: "1px solid rgba(81, 81, 81, 1) !important" }}>
                          Received
                        </TableCell>
                        {Object.entries(currentCollection.assets[0].rarityChances).map(([name, percent]) => {
                          var convertedRarityName = Object.keys(otherRarityNames).includes(name) ? otherRarityNames[name] : name;
                          return <TableCell sx={{ fontSize: '13px', lineHeight: 1, flex: '2', borderBottom: "none" }} align="center">{currentCollection.item?.AllRarityValues?.[name]?.["numItems"] ?? 0}</TableCell>

                        })

                        }
                      </TableRow>
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.1)', width: '100%', flexDirection: 'row', border: '1px solid rgba(81, 81, 81, 1)', borderLeft: 'none', borderRight: 'none', borderTop: 'none' }}
                        key={"valuesRaritiesSim"}
                      >

                        <TableCell component="th" scope="row" align="center" sx={{ fontSize: '13px', lineHeight: 1, flex: '1', borderRight: "1px solid rgba(81, 81, 81, 1) !important" }}>
                          {formatNumber(currentCollection.item.YourReturn / exchangeRate, true)}
                        </TableCell>
                        {Object.entries(currentCollection.assets[0].rarityChances).map(([name, percent]) => {
                          var convertedRarityName = Object.keys(otherRarityNames).includes(name) ? otherRarityNames[name] : name;
                          return <TableCell sx={{ fontSize: '13px', lineHeight: 1, flex: '2' }} align="center">{formatNumber((currentCollection.item?.AllRarityValues?.[name]?.["totalCost"] ?? 0) / exchangeRate, true)}</TableCell>

                        })}
                      </TableRow>
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, width: '100%', flexDirection: 'row', border: '1px solid rgba(81, 81, 81, 1)', borderBottom: 0, borderRight: 'none', borderLeft: 'none' }}
                        key={"numRaritiesSim"}
                      >
                        <TableCell component="th" scope="row" align="center" sx={{ fontSize: '13px', lineHeight: 1, flex: '1', borderBottom: 'none', borderRight: "1px solid rgba(81, 81, 81, 1) !important" }}>
                          Expected
                        </TableCell>
                        {Object.entries(currentCollection.assets[0].rarityChances).map(([name, percent]) => {
                          var convertedRarityName = Object.keys(otherRarityNames).includes(name) ? otherRarityNames[name] : name;
                          return <TableCell sx={{ fontSize: '13px', lineHeight: 1, flex: '2', borderBottom: "none" }} align="center">{formatNumber(percent * currentCollection.item?.NumItems)}</TableCell>

                        })

                        }
                      </TableRow>
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: '100%', width: '100%', flexDirection: 'row', border: '1px solid rgba(81, 81, 81, 1)', borderTop: 0, borderRight: 'none', borderBottom: 'none', borderLeft: 'none' }}
                        key={"valuesRaritiesSim"}
                      >
                        <TableCell component="th" scope="row" align="center" sx={{ fontSize: '13px', lineHeight: 1, flex: '1', borderRight: "1px solid rgba(81, 81, 81, 1) !important" }}>
                          {formatNumber(currentCollection.item.ExpectedReturn / exchangeRate, true)}
                        </TableCell>
                        {Object.entries(currentCollection.assets[0].rarityChances).map(([name, percent]) => {
                          var convertedRarityName = Object.keys(otherRarityNames).includes(name) ? otherRarityNames[name] : name;
                          return <TableCell sx={{ fontSize: '13px', lineHeight: 1, flex: '2', }} align="center">{formatNumber(((currentCollection.item.ExpectedRarityValues?.[name] ?? 0) * currentCollection.item?.NumItems * percent) / exchangeRate, true)}</TableCell>

                        })}
                      </TableRow>
                    </TableBody>
                  </Table>
                </Card>}
              {currentCollection.assets.map((asset, index) => {
                const wearName = asset?.itemName?.split(' | ')[1]?.split(" (")?.length > 1 ? asset.itemName.split(' | ')[1].split(" (")[1].replaceAll(")", "") : null;
                let minColumnWidth =
                  iconSize == "large" ? 260 :
                    iconSize == "medium" ? 180 :
                      140;
                let fixedRowHeight =
                  isMobile ?
                    ((220)) :
                    iconSize == "large" ?
                      (220) :
                      iconSize == "medium" ?
                        (220) :
                        (169);
                return (
                  <div style={{ margin: '4px' }}>
                    {asset.itemCost != null && ((asset?.collectionCost ?? 0.02) > 0.01 || includeFreeItems) &&
                      <div style={{ width: minColumnWidth, height: fixedRowHeight, position: 'relative' }}>
                        {asset.itemCost != null && ((asset?.collectionCost ?? 0.02) > 0.01 || includeFreeItems) && <div style={{ height: '100%', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', padding: '2px', width: "100%", border: asset.itemName.includes("StatTrak") ? '1px solid rgba(207, 106, 50, 0.8)' : asset.itemName.includes("Souvenir") ? '1px solid rgba(255, 215, 0, 0.65)' : '1px solid rgba(81, 81, 81, 1)', position: 'relative', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', }}>
                          {(iconSize != "large") && <p style={{ display: "flex", alignItems: 'center', paddingBottom: '4px', justifyContent: 'center', height: asset?.collectionCost == null ? (iconSize == "medium" ? showCase && showCaseName ? "64px" : showCaseName ? "24px" : "32px" : iconSize == "small" ? (showCase && showCaseName ? "52px" : showCaseName ? "16px" : "28px") : showCase ? "28px" : "26px") : "auto", color: 'white', minHeight: iconSize == "large" ? '2lh' : '2lh', marginBottom: '4px', marginTop: '4px', paddingLeft: '2px', paddingRight: '2px', fontSize: iconSize == "large" ? '0.8rem' : iconSize == "medium" ? '0.75rem' : '0.6rem', lineHeight: '1', width: '100%', textAlign: 'center' }}>
                            {/* {(iconSize == "large" ? "" : asset.itemName)}
                             */}
                            {asset.itemName.split(' | ')[0]} | {asset.itemName?.split(' | ')?.[1]?.split(" (")?.[0] ?? ""}
                          </p>}

                          <Card style={{
                            background: `linear-gradient(145deg, RGB(${otherRarityNames.hasOwnProperty(asset.rarity) ? redRarityNums[otherRarityNames[asset?.rarity ?? "Consumer"]] : redRarityNums[asset?.rarity ?? "Consumer"]}, ${otherRarityNames.hasOwnProperty(asset.rarity) ? greenRarityNums[otherRarityNames[asset?.rarity ?? "Consumer"]] : greenRarityNums[asset?.rarity ?? "Consumer"]}, ${otherRarityNames.hasOwnProperty(asset.rarity) ? blueRarityNums[otherRarityNames[asset?.rarity ?? "Consumer"]] : blueRarityNums[asset?.rarity ?? "Consumer"]}) 0%, black 100%)`,

                            width: "100%", paddingLeft: 4, paddingRight: 4, marginTop: showCase ? '0px' : '1px', marginRight: '1px',
                            position: 'relative', transition: 'box-shadow 0.3s', marginBottom: iconSize == "medium" ? '36px' : "0",
                            boxShadow: (showCase && asset?.collectionCost == null) ? 'none' : '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
                          }}>

                            <CardMedia
                              sx={{ height: iconSize == "small" ? 100 : 140, backgroundSize: "contain", }}
                              image={"https://community.fastly.steamstatic.com/economy/image/" + asset.icon_url + "/330x192?allow_animated=1"}
                            />

                            {asset?.collectionCost != null && <div style={{ position: 'absolute', top: '-2px', left: '4px', padding: iconSize == "small" ? '1px' : '4px', borderRadius: '4px 0 0 0', zIndex: 2 }}>
                              {<span style={{
                                fontSize: iconSize == "medium" || iconSize == "large" ? "16px" : '14px', color: 'white', display: showUnboxChance ? "block" : "none",
                                textShadow: "1px 0 black, -1px 0 black, 0 1px black, 0 -1px black,0.5px 0.5px black, -0.5px -0.5px black, 0.5px -0.5px black, -0.5px 0.5px black"
                              }}>1 in {formatNumber(asset.itemChance)}</span>}
                            </div>}

                            <div style={{ position: 'absolute', bottom: '2px', left: '4px', padding: '0px', borderRadius: '4px 0 0 0', zIndex: 2, display: iconSize == "large" ? "none" : 'flex' }}>
                              <span style={{
                                fontSize: iconSize == "medium" || iconSize == "large" ? "16px" : '14px', color: asset.itemName.includes("StatTrak") ? 'rgb(207, 106, 50)' : asset.itemName.includes("Souvenir") ? 'rgba(255, 215, 0)' : 'white',
                                textShadow: "1px 0 black, -1px 0 black, 0 1px black, 0 -1px black,0.5px 0.5px black, -0.5px -0.5px black, 0.5px -0.5px black, -0.5px 0.5px black"
                              }}>{smallWearNames[asset.wearName]}</span>

                            </div>
                            <div style={{ position: 'absolute', bottom: '4px', right: '4px', padding: '0px', zIndex: 2, display: showStickersApplied ? 'flex' : 'none', justifyContent: 'center' }}>
                              {
                                asset.stickerList.map((sticker, index) => (

                                  <img src={sticker.image} alt={sticker.name} width={iconSize == "small" ? "21" : iconSize == "medium" ? "26" : "32"} />

                                ))
                              }
                            </div>
                            {
                              asset?.charm != null && (
                                <div style={{ position: 'absolute', backgroundColor: 'rgba(0,0,0, 0.5)', borderRadius: '6px', bottom: (iconSize == "large" ? '2px' : iconSize == "medium" ? '26px' : '20px'), left: '2px', padding: '0px', zIndex: 2, display: showStickersApplied ? 'flex' : 'none', justifyContent: 'center' }}>


                                  <img src={asset?.charm.image} alt={asset?.charm.name} width={iconSize == "small" ? "32" : iconSize == "medium" ? "37" : "43"} />


                                </div>
                              )
                            }





                            <div style={{ position: 'absolute', top: iconSize == "large" ? '3px' : iconSize == "medium" ? '2px' : '2px', right: '4px', padding: iconSize == "small" ? '1px' : '4px', borderRadius: '4px 0 0 0', zIndex: 2, display: 'flex', flexDirection: 'column', lineHeight: '0.9' }}>
                              {asset.numItems > 1 && <span style={{
                                textAlign: 'center',
                                fontSize: iconSize == "medium" || iconSize == "large" ? "16px" : '14px',
                                textShadow: "1px 0 black, -1px 0 black, 0 1px black, 0 -1px black,0.5px 0.5px black, -0.5px -0.5px black, 0.5px -0.5px black, -0.5px 0.5px black"
                              }}>x{asset.numItems}</span>}
                              {asset.tradeHoldDays > 0 && showTradehold && (() => {
                                const days = Math.floor(asset.tradeHoldDays);
                                const hours = Math.floor((asset.tradeHoldDays - days) * 24);
                                return <span style={{
                                  textAlign: 'center',
                                  fontSize: iconSize == "medium" || iconSize == "large" ? "16px" : '14px',
                                  textShadow: "1px 0 black, -1px 0 black, 0 1px black, 0 -1px black,0.5px 0.5px black, -0.5px -0.5px black, 0.5px -0.5px black, -0.5px 0.5px black",
                                  color: 'white'
                                }}>{days > 0 ? days.toString() + "D" : ""} {hours > 0 ? hours.toString() + "H" : ""}</span>;
                              })()}
                            </div>


                          </Card>
                          <div style={{ position: 'absolute', width: '100%', justifyContent: 'center', bottom: '2px', display: iconSize !== "large" && (asset?.itemCost ?? 0) != 0 ? 'block' : 'none', textAlign: 'center', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                              <a onClick={(event) => event.stopPropagation()} href={priceLinks ? ("https://steamcommunity.com/profiles/" + id + "/inventory/" + "#730_" + asset.contextid + "_" + asset.assetid) : (pricingSource == "Steam" ? steamLink(asset.itemName, null, null, null) : pricingSource == "Buff" ? buffLink(asset.itemName, null, null, null) : pricingSource == "CSFloat" ? csfloatLink(asset.itemName, null, null, null) : pricingSource == "Skinport" ? skinportLink(asset.itemName, null, null, null, null, null) : tradeitLink(asset.itemName, null, null, null))} target='_blank'><span style={{ fontSize: iconSize == "medium" ? '22px' : '16px', color: ((asset?.wearEstimate ?? false) && pricingSource == "Tradeit") ? "rgb(131, 131, 131)" : "lightskyblue" }}>{formatNumber((asset.itemCost) / exchangeRate, true)}</span></a>
                              <div style={{ display: iconSize !== "large" && showInvestROI ? 'flex' : 'none', color: asset.ROI > -0.01 ? "green" : "rgb(187, 23, 26)", fontSize: iconSize == "small" ? '11px' : '13px', lineHeight: '0.5', marginTop: 'auto', marginBottom: 'auto', paddingBottom: '7px', paddingLeft: '2px', flexDirection: 'column', alignItems: 'center' }}>{asset.ROI > -0.01 ? <TrendingUpIcon style={{ width: iconSize == "small" ? "16px" : '19px', height: iconSize == "small" ? "12px" : "14px" }} /> : <TrendingDownIcon style={{ width: iconSize == "small" ? "16px" : '19px', height: iconSize == "small" ? "12px" : "14px" }} />}{(asset?.ROI ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(asset.ROI), false, 0)}%</div>
                            </div>
                            <p style={{ fontSize: iconSize == "medium" ? '13px' : '10px', lineHeight: '0.7', margin: 'auto', display: asset.numItems > 1 ? "block" : "none", verticalAlign: 'middle', color: ((asset?.wearEstimate ?? false) && pricingSource == "Tradeit") ? "rgb(131, 131, 131)" : "lightskyblue" }}> ({formatNumber((asset.itemCost * asset.numItems) / exchangeRate, true)} total)</p>


                          </div>


                          <div style={{ paddingLeft: '8px', display: iconSize == "large" ? "block" : "none", }}>
                            <a style={{ pointerEvents: ((asset.isCollection ? asset?.collectionUrl : null) == null ? "none" : "auto") }} href={asset.isCollection ? asset?.collectionUrl : null} target='_blank' onClick={(e) => { e.stopPropagation() }}>
                              <h5 style={{ lineHeight: 1, marginBottom: '0', marginTop: asset.itemName.split(' | ').length < 2 ? "16px" : '12px', width: '170px', fontSize: asset.itemName.split(' | ').length > 1 ? '0.82rem' : '0.95rem' }}>
                                {
                                  // asset.rarity !== "Contraband" ?
                                  asset.itemName.split(' | ')[0]
                                  // :
                                  // (
                                  //   Object.entries(caseData['KnifeCosts'][asset.itemName]).length >
                                  //     Object.entries(getWears(caseData['Items'][asset.itemName]['FloatMin'] / 100, caseData['Items'][asset.itemName]['FloatMax'] / 100))
                                  //       .filter(([key, percent]) => percent !== 0).length + 1 ?
                                  //     (pastNumber[3] > 0.1 ? '' : 'StatTrak™ ') :
                                  //     ''
                                  // ) + asset.itemName.split(' | ')[0]
                                }
                              </h5>
                              <h4 style={{ lineHeight: 1, marginTop: '2px', marginBottom: '0px' }}>{asset.itemName?.split(' | ')?.[1]?.split(" (")?.[0] ?? ""}</h4>
                              <h5 style={{ lineHeight: 0.2, marginTop: '8px', fontSize: '0.75rem' }}>{(wearName != null ? (Object.values(realWearNames).includes(wearName) ? (("(" + (asset.itemName.includes("StatTrak") ? "ST " : "") + wearName + " - ") + formatNumber((asset.wearChances?.[wearName?.replaceAll("-", "")?.replaceAll(" ", "")] ?? 0) * (asset.itemName.includes("StatTrak") ? 0.1 : 0.9)) + "%)") : "(" + wearName + ")") : <br />)}</h5>
                            </a>
                            <div style={{ position: 'absolute', bottom: isMobile ? '84%' : asset.numItems > 1 ? '14.5px' : '22px', right: isMobile ? '4px' : '8px', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2, fontSize: isMobile ? '16px' : '22px', display: (asset?.itemCost ?? 0) != 0 ? 'flex' : 'none', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                              <p style={{ fontSize: '13px', lineHeight: '0', margin: 'auto', marginBottom: '-5px', display: showInvestROI ? "block" : "none", verticalAlign: 'middle', textAlign: 'center', color: (asset?.ROI ?? 0) > -0.01 ? "green" : "rgb(187, 23, 26)" }}>{(asset?.ROI ?? 0) > -0.01 ? <TrendingUpIcon style={{ width: "18px", height: "13px" }} /> : <TrendingDownIcon style={{ width: "18px", height: "13px" }} />}{(asset?.ROI ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(asset.ROI), false, 0)}%</p>
                              <a href={priceLinks ? ("https://steamcommunity.com/profiles/" + id + "/inventory/" + "#730_" + asset.contextid + "_" + asset.assetid) : (pricingSource == "Steam" ? steamLink(asset.itemName, null, null, null) : pricingSource == "Buff" ? buffLink(asset.itemName, null, null, null) : pricingSource == "CSFloat" ? csfloatLink(asset.itemName, null, null, null) : pricingSource == "Skinport" ? skinportLink(asset.itemName, null, null, null, null, null) : tradeitLink(asset.itemName, null, null, null))} onClick={(event) => event.stopPropagation()} target='_blank'><span style={{ color: ((asset?.wearEstimate ?? false) && pricingSource == "Tradeit") ? "rgb(131, 131, 131)" : "lightskyblue" }}>{formatNumber((asset.itemCost) / exchangeRate, true)}</span></a>
                              <p style={{ fontSize: '11px', lineHeight: '0.7', margin: 'auto', display: asset.numItems > 1 ? "block" : "none", verticalAlign: 'middle', textAlign: 'center' }}> ({formatNumber((asset.itemCost * asset.numItems) / exchangeRate, true)} total)</p>
                            </div>

                          </div>
                        </div>}
                      </div>}
                  </div>
                );
              })}
            </div>}
          </Card>
        </div>
      </div>
    );
  }

  const SearchBar = () => {
    const { searchTerm, setSearchTerm } = React.useContext(RangeSelectionContext);

    const handleChange = (event) => {
      setSearchTerm(event.target.value);
    };

    return (
      <TextField
        sx={{ width: '220px', marginBottom: isMobile ? 'auto' : 'auto', marginTop: '8px', }}
        onChange={handleChange}
        value={searchTerm}
        label="Search"
        InputProps={{
          // ...params.InputProps,
          type: 'search',
        }}
      />
    );
  };

  const ClearSelectionButton = () => {
    const { clearRange } = React.useContext(RangeSelectionContext);

    return (

      <Button variant="outlined" onClick={() => clearRange()} style={{ color: 'white', marginTop: 'auto', marginBottom: 'auto', marginRight: '24px', padding: '8px' }}>CLEAR SELECTION</Button>

    );
  };
  // Extend RangeSelectionContext to include hover handling
  function RangeSelectionProvider({ children }) {
    const [selection, setSelection] = React.useState({
      start: null,
      end: null,
      hoverIndex: null,
      selectedIndices: new Set(),
    });

    const [searchTerm, setSearchTermRaw] = React.useState('');

    const setSearchTerm = (value) => {
      setSearchTermRaw(value);
      setSelection((currentSelection) => {
        return { ...currentSelection, start: null, end: null, hoverIndex: null };
      });
    }

    const filteredAssets = React.useMemo(() => {
      const searchTerms = searchTerm.toLowerCase().split(' ').filter(term => term);

      const mapping = assets.reduce((acc, asset, index) => {
        const itemName = asset.itemName.toLowerCase();
        const collectionName = (asset.collectionName?.toLowerCase() ?? "");

        const matches = searchTerms.every(term =>
          itemName.includes(term) || collectionName.includes(term)
        );

        if (matches) {
          acc.push({ filteredIndex: acc.length, originalIndex: index });
        }

        return acc;
      }, []);

      return mapping;
    }, [searchTerm, assets]);

    // const filteredAssets = React.useMemo(() => {
    //   const mapping = [];

    //   assets.forEach((asset, index) => {
    //     if (asset.itemName.toLowerCase().includes(searchTerm.toLowerCase()) || (asset?.collectionName?.toLowerCase() ?? "").includes(searchTerm.toLowerCase())) {
    //       mapping.push({ filteredIndex: mapping.length, originalIndex: index });
    //     }
    //   });


    //   return mapping;
    // }, [searchTerm, assets]);

    const [currentCollection, setCurrentCollection] = useState(null);

    const [customCollections, setCustomCollections] = useState({});

    const clearRange = () => {
      setSelection((currentSelection) => {

        return { ...currentSelection, selectedIndices: new Set(), };

      })
    }

    const updateRange = (index, ctrlPressed) => {
      setSelection((currentSelection) => {
        // If Control is pressed, toggle the selection state of the clicked item
        if (ctrlPressed) {
          const newSelectedIndices = new Set(currentSelection.selectedIndices);
          if (newSelectedIndices.has(filteredAssets[index].originalIndex)) {
            newSelectedIndices.delete(filteredAssets[index].originalIndex);
          } else {
            newSelectedIndices.add(filteredAssets[index].originalIndex);
          }
          return { ...currentSelection, selectedIndices: newSelectedIndices };
        }

        // Merge with existing selectedIndices
        const newSelectedIndices = new Set(currentSelection.selectedIndices);

        // If start is null, it's the first click
        if (currentSelection.start === null) {
          // newSelectedIndices.add(filteredAssets[index].originalIndex);
          return { ...currentSelection, start: index, selectedIndices: newSelectedIndices };
        } else {
          // Finalize range on second click
          const startIndex = Math.min(currentSelection.start, index);
          const endIndex = Math.max(currentSelection.start, index);
          for (let i = startIndex; i <= endIndex; i++) {
            if (newSelectedIndices.has(filteredAssets[i].originalIndex)) {
              newSelectedIndices.delete(filteredAssets[i].originalIndex);
            } else {
              newSelectedIndices.add(filteredAssets[i].originalIndex);
            }
            // newSelectedIndices.add(filteredAssets[i].originalIndex);
          }
          if (selectAutoScroll) {
            scrollToElement('investStats');
          }


          return { start: null, end: null, hoverIndex: null, selectedIndices: newSelectedIndices };
        }
      });
    };

    const updateHoverIndex = (index) => {
      setSelection((currentSelection) => {
        if (currentSelection.start !== null) {
          // Initialize newSelectedIndices with the current selection
          // const newSelectedIndices = new Set(currentSelection.selectedIndices);

          // Calculate temporary range for hover
          // const startIndex = Math.min(currentSelection.start, index);
          // const endIndex = Math.max(currentSelection.start, index);
          // for (let i = startIndex; i <= endIndex; i++) {
          //   newSelectedIndices.add(i);
          // }
          return { ...currentSelection, hoverIndex: index };
        }
        return currentSelection;
      });
    };

    return (
      <RangeSelectionContext.Provider value={{ selection, updateRange, updateHoverIndex, setCurrentCollection, currentCollection, searchTerm, setSearchTerm, filteredAssets, clearRange, setCustomCollections, customCollections }}>
        {children}
      </RangeSelectionContext.Provider>
    );
  }

  function RenderGrid({ height, width, isMobile, showCase, Cell }) {
    const { selection, updateRange, updateHoverIndex, setCurrentCollection, currentCollection, searchTerm, setSearchTerm, filteredAssets } = React.useContext(RangeSelectionContext);
    let minColumnWidth =
      width < 420 ? 220 :
        iconSize == "large" ? 260 :
          iconSize == "medium" ? 180 :
            140;
    let columnCount = Math.floor((width - 20) / minColumnWidth); // Adjusted to account for potential padding/margin
    let columnWidth = (width - 20) / columnCount; // Adjusted similarly
    let fixedRowHeight =
      isMobile ?
        ((showCase ? showCaseName ? 300 : 270 : showCaseName ? 250 : 230)) :
        iconSize == "large" ?
          (showCase ? showCaseName ? 300 : 270 : showCaseName ? 255 : 230) :
          iconSize == "medium" ?
            (showCase ? showCaseName ? 265 : 235 : showCaseName ? 235 : 195) :
            (showCase ? showCaseName ? 210 : 180 : showCaseName ? 170 : 144);

    return (
      <ColumnCountContext.Provider value={columnCount}>
        <Grid
          columnCount={columnCount}
          columnWidth={columnWidth}
          height={height - 64}
          rowCount={Math.ceil(filteredAssets.length / columnCount)}
          rowHeight={fixedRowHeight}
          width={width}
          style={{ margin: 'auto' }}
        >
          {Cell}
        </Grid>
      </ColumnCountContext.Provider>
    );
  }


  function Cell({ columnIndex, rowIndex, style }) {
    const { selection, updateRange, updateHoverIndex, setCurrentCollection, currentCollection, searchTerm, setSearchTerm, filteredAssets, setCustomCollections, customCollections } = React.useContext(RangeSelectionContext);
    const columnCount = React.useContext(ColumnCountContext);
    const cellRef = React.useRef(null);
    // Calculate the filtered index and then find the corresponding original index
    const filteredIndex = rowIndex * columnCount + columnIndex;
    const index = filteredAssets[filteredIndex]?.originalIndex;


    // console.log("index" + index);
    // console.log("filteredIndex" + filteredIndex);
    // console.log("hoverIndex" + selection.hoverIndex);
    // console.log("start" + selection.start);
    const isHover =
      (selection.start != null && selection.hoverIndex != null) && (
        (selection.hoverIndex >= filteredIndex && filteredIndex >= selection.start)
        || (selection.hoverIndex <= filteredIndex && filteredIndex <= selection.start)
      )
    // (selection.start != null && selection.hoverIndex != null) && 
    // (
    //   (selection.hoverIndex >= index && index >= selection.start) 
    //   || (selection.hoverIndex <= index && index <= selection.start)
    // );

    const isSelected =
      (selection.selectedIndices.has(index) || isHover) ?
        (
          (selection.selectedIndices.has(index) && !isHover)
          || (!selection.selectedIndices.has(index) && isHover)
        )
        : (selection.selectedIndices.has(index) || isHover);

    const asset = assets[index];
    // console.log(asset);

    const wearName = ((asset?.itemName?.split(' | ')[1]?.split(" (")?.length ?? 0) > 1 ? asset.itemName.split(' | ')[1].split(" (")[1].replaceAll(")", "") : null);


    const handleClick = (event) => {
      const ctrlPressed = event.ctrlKey;
      updateRange(filteredIndex, ctrlPressed);
    };

    const handleRemove = (event) => {
      // const ctrlPressed = event.ctrlKey;
      event.stopPropagation(); // Prevent click event from bubbling up

      updateRange(filteredIndex, true);
    };

    const handleCustomCollection = (event, customCollectionName, collectionUrl) => {
      event.stopPropagation(); // Prevent click event from bubbling up

      // Check if the customCollectionName is already in customCollections
      if (Object.values(customCollections).includes(customCollectionName)) {
        if (event.ctrlKey || event.metaKey || event.button === 1) {
          window.open(`${collectionUrl}`, '_blank');
        } else if (event.shiftKey) {
          window.open(`${collectionUrl}`, '_blank');
        } else {
          window.open(`${collectionUrl}`, '_blank');
        }
      } else {
        // Construct the new entry to be added to customCollections
        const newEntry = {
          [index]: customCollectionName
        };

        // Merge the new entry with the existing customCollections
        const updatedCustomCollections = {
          ...customCollections,
          ...newEntry
        };

        // Update customCollections with the merged object
        setCustomCollections(updatedCustomCollections);
      }
    };

    const removeCustomCollection = (e, collection, collectionUrl) => {
      e.stopPropagation(); // Prevent click event from bubbling up

      // Clone the customCollections object to avoid direct state mutation
      const updatedCustomCollections = { ...customCollections };

      // Check if the entry exists at filteredIndex and delete it
      if (updatedCustomCollections.hasOwnProperty(filteredIndex)) {
        delete updatedCustomCollections[filteredIndex];
      } else {
        if (e.ctrlKey || e.metaKey || e.button === 1) {
          window.open(`${collectionUrl}`, '_blank');
        } else if (e.shiftKey) {
          window.open(`${collectionUrl}`, '_blank');
        } else {
          window.open(`${collectionUrl}`, '_blank');
        }
      }

      // Update customCollections with the modified object
      setCustomCollections(updatedCustomCollections);
    };


    const handleMouseEnter = () => {
      updateHoverIndex(filteredIndex);
    };




    // Adjust style based on selection
    const adjustedStyle = {
      ...style,

      borderTopLeftRadius: '10px', cursor: 'pointer', borderTopRightRadius: '10px', padding: iconSize == "large" ? '6px' : iconSize == "medium" ? '4px' : '2px',
    };

    return asset ? (
      <div ref={cellRef} style={adjustedStyle} onClick={handleClick} onMouseEnter={handleMouseEnter}>
        {/* {isSelected && <IconButton aria-label="delete" style={{ position: 'absolute', top: "6px", right: "6px", width: '48px', height: '48px', zIndex: 2 }} onClick={handleRemove}>
          <CloseIcon />
        </IconButton>} */}
        {asset.itemCost != null && ((asset?.collectionCost ?? 0.02) > 0.01 || includeFreeItems) && <div style={{ height: '100%', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', padding: '2px', width: "100%", border: asset.itemName.includes("StatTrak") ? '1px solid rgba(207, 106, 50, 0.8)' : asset.itemName.includes("Souvenir") ? '1px solid rgba(255, 215, 0, 0.65)' : '1px solid rgba(81, 81, 81, 1)', position: 'relative', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', backgroundColor: isSelected ? 'rgba(255, 255, 255, 0.15)' : style.backgroundColor, }}>
          {(showCaseName || (showCase && asset?.collectionCost == null && iconSize != "large")) && <a style={{ pointerEvents: "auto" }} href={asset?.collectionUrl} target='_blank' onClick={(e) => { e.stopPropagation() }}><p style={{ display: "flex", alignItems: 'center', paddingBottom: '4px', justifyContent: 'center', height: asset?.collectionCost == null ? (iconSize == "medium" ? showCase && showCaseName ? "64px" : showCaseName ? "24px" : "32px" : iconSize == "small" ? (showCase && showCaseName ? "52px" : showCaseName ? "16px" : "28px") : showCase ? "28px" : "26px") : "auto", color: 'white', minHeight: iconSize == "large" ? '2lh' : '2lh', marginBottom: '4px', marginTop: '4px', paddingLeft: '2px', paddingRight: '2px', fontSize: iconSize == "large" ? '0.8rem' : iconSize == "medium" ? '0.75rem' : '0.6rem', lineHeight: '1', width: '100%', textAlign: 'center' }}>{(customCollections?.[index] == null ? asset.collectionName : customCollections?.[index]) ?? (iconSize == "large" ? "" : asset.itemName)}</p></a>}

          {showCase && (asset?.collectionCost != null || iconSize == "large") && (asset?.collectionCost != null ? <div style={{ width: "100%", background: 'transparent', display: 'flex', marginBottom: '4px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', margin: 'auto', overflowX: Object.keys(asset.extraCollections).length <= (iconSize == "large" ? 2 : 1) ? 'hidden' : 'scroll', "scrollbarWidth": "thin", scrollbarColor: "rgba(81, 81, 81, 1) rgb(18, 18, 18)" }}>
              <div onClick={(e) => removeCustomCollection(e, asset.collectionName, asset?.collectionUrl)} style={{ display: 'flex', cursor: 'pointer', flexDirection: 'row', paddingRight: '1px', paddingLeft: '1px', justifyContent: 'center', margin: 'auto', backgroundColor: Object.keys(asset.extraCollections).length > 0 && customCollections?.[index] == null ? 'rgba(255, 255, 255, 0.12)' : style.backgroundColor, borderRadius: '4px' }}>
                <img src={asset?.collectionImage} style={{ height: iconSize == "small" ? "32px" : "36px", objectFit: 'contain', width: iconSize == "small" ? "32px" : '36px', padding: '1px' }} />


                <p style={{ color: (asset?.collectionTradeitEstimate && pricingSource == "Tradeit") ? 'rgb(131, 131, 131)' : 'white', marginTop: 'auto', marginBottom: 'auto', marginLeft: '2px', fontSize: iconSize == "large" ? '0.8rem' : iconSize == "medium" ? '0.7rem' : '0.65rem', padding: '1px' }}>{asset.collectionCost == 0.01 ? "DROP" : formatNumber((asset.collectionCost) / exchangeRate, true)}</p>
              </div>
              {
                Object.entries(asset.extraCollections).map(([extraCollName, extraCollValues]) => (
                  <div onClick={(e) => handleCustomCollection(e, extraCollName, extraCollValues?.collectionUrl)} key={extraCollName} style={{ display: 'flex', cursor: 'pointer', flexDirection: 'row', justifyContent: 'center', margin: 'auto', paddingLeft: '2px', paddingRight: '2px', backgroundColor: customCollections?.[index] == extraCollName ? 'rgba(255, 255, 255, 0.12)' : style.backgroundColor, borderRadius: '4px' }}>
                    <img src={extraCollValues.collectionImage} style={{ height: iconSize == "small" ? "32px" : "36px", objectFit: 'contain', width: iconSize == "small" ? "32px" : '36px', padding: '1px' }} />
                    <p style={{ color: 'white', marginTop: 'auto', marginBottom: 'auto', marginLeft: '2px', fontSize: iconSize == "large" ? '0.8rem' : iconSize == "medium" ? '0.7rem' : '0.65rem', padding: '1px' }}>
                      {formatNumber((extraCollValues.collectionCost) / exchangeRate, true)}
                    </p>
                  </div>
                ))
              }
            </div>



          </div> :
            asset?.UnboxROI != 0 ? <div style={{ width: "100%", background: 'transparent', display: 'flex', height: '36px', marginBottom: '4px' }}>
              <Tooltip title="Return on Investment for opening this container now" sx={{ margin: 'auto' }}>
                <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'row', textAlign: 'center', fontSize: '0.8rem', lineHeight: 0.9 }}>
                  {/* Unboxing ROI */}
                  <img src={OpenBox} className="iconSVGMob" style={{ width: '24px', height: '24px', margin: '2px' }} />
                  <h3 style={{ lineHeight: 0.5, margin: 0, fontSize: '0.8rem' }}>{formatNumber(asset?.UnboxROI * 100, false)}%</h3>

                </div>

              </Tooltip>
              <Tooltip title="Chance for each unbox to be worth more than the cost of opening" sx={{ margin: 'auto' }}>
                <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'row', textAlign: 'center', fontSize: '0.8rem', lineHeight: 0.9 }}>
                  {/* Profit% */}
                  <PriceCheckIcon className="iconSVG" sx={{ width: '24px', height: '24px', margin: '2px' }} fontSize='large' />
                  <h3 style={{ lineHeight: 0.5, margin: 0, fontSize: '0.8rem' }}>{formatNumber(asset?.ProfitChance * 100)}%</h3>
                </div>

              </Tooltip>

            </div> :
              <div style={{ width: "100%", background: 'transparent', display: 'flex', height: showCase && showCaseName ? '34px' : '36px', marginBottom: '4px' }} />





          )}
          <Card style={{
            background: `linear-gradient(145deg, RGB(${otherRarityNames.hasOwnProperty(asset.rarity) ? redRarityNums[otherRarityNames[asset?.rarity ?? "Consumer"]] : redRarityNums[asset?.rarity ?? "Consumer"]}, ${otherRarityNames.hasOwnProperty(asset.rarity) ? greenRarityNums[otherRarityNames[asset?.rarity ?? "Consumer"]] : greenRarityNums[asset?.rarity ?? "Consumer"]}, ${otherRarityNames.hasOwnProperty(asset.rarity) ? blueRarityNums[otherRarityNames[asset?.rarity ?? "Consumer"]] : blueRarityNums[asset?.rarity ?? "Consumer"]}) 0%, black 100%)`,

            width: "100%", paddingLeft: 4, paddingRight: 4, marginTop: showCase ? '0px' : '1px', marginRight: '1px',
            position: 'relative', transition: 'box-shadow 0.3s', marginBottom: iconSize == "medium" ? '36px' : "0",
            boxShadow: (showCase && asset?.collectionCost == null) ? 'none' : '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
          }}>

            <CardMedia
              sx={{ height: iconSize == "small" ? 100 : 140, backgroundSize: "contain", }}
              image={"https://community.fastly.steamstatic.com/economy/image/" + asset.icon_url + "/330x192?allow_animated=1"}
            />

            {asset?.collectionCost != null && <div style={{ position: 'absolute', top: '-2px', left: '4px', padding: iconSize == "small" ? '1px' : '4px', borderRadius: '4px 0 0 0', zIndex: 2 }}>
              {<span style={{
                fontSize: iconSize == "medium" || iconSize == "large" ? "16px" : '14px', color: 'white', display: showUnboxChance ? "block" : "none",
                textShadow: "1px 0 black, -1px 0 black, 0 1px black, 0 -1px black,0.5px 0.5px black, -0.5px -0.5px black, 0.5px -0.5px black, -0.5px 0.5px black"
              }}>1 in {formatNumber(asset.itemChance)}</span>}
            </div>}

            <div style={{ position: 'absolute', bottom: '2px', left: '4px', padding: '0px', borderRadius: '4px 0 0 0', zIndex: 2, display: iconSize == "large" ? "none" : 'flex' }}>
              <span style={{
                fontSize: iconSize == "medium" || iconSize == "large" ? "16px" : '14px', color: asset.itemName.includes("StatTrak") ? 'rgb(207, 106, 50)' : asset.itemName.includes("Souvenir") ? 'rgba(255, 215, 0)' : 'white',
                textShadow: "1px 0 black, -1px 0 black, 0 1px black, 0 -1px black,0.5px 0.5px black, -0.5px -0.5px black, 0.5px -0.5px black, -0.5px 0.5px black"
              }}>{smallWearNames[asset.wearName]}</span>

            </div>
            <div style={{ position: 'absolute', bottom: '4px', right: '4px', padding: '0px', zIndex: 2, display: showStickersApplied ? 'flex' : 'none', justifyContent: 'center' }}>
              {
                asset.stickerList.map((sticker, index) => (

                  <img src={sticker.image} alt={sticker.name} width={iconSize == "small" ? "21" : iconSize == "medium" ? "26" : "32"} />

                ))
              }
            </div>
            {
              asset?.charm != null && (
                <div style={{ position: 'absolute', backgroundColor: 'rgba(0,0,0, 0.5)', borderRadius: '6px', bottom: (iconSize == "large" ? '2px' : iconSize == "medium" ? '26px' : '20px'), left: '2px', padding: '0px', zIndex: 2, display: showStickersApplied ? 'flex' : 'none', justifyContent: 'center' }}>


                  <img src={asset?.charm.image} alt={asset?.charm.name} width={iconSize == "small" ? "32" : iconSize == "medium" ? "37" : "43"} />


                </div>
              )
            }



            <div style={{ position: 'absolute', top: iconSize == "large" ? '3px' : iconSize == "medium" ? '2px' : '2px', right: '4px', padding: iconSize == "small" ? '1px' : '4px', borderRadius: '4px 0 0 0', zIndex: 2, display: 'flex', flexDirection: 'column', lineHeight: '0.9' }}>
              {asset.numItems > 1 && <span style={{
                textAlign: 'center',
                fontSize: iconSize == "medium" || iconSize == "large" ? "16px" : '14px',
                textShadow: "1px 0 black, -1px 0 black, 0 1px black, 0 -1px black,0.5px 0.5px black, -0.5px -0.5px black, 0.5px -0.5px black, -0.5px 0.5px black"
              }}>x{asset.numItems}</span>}
              {asset.tradeHoldDays > 0 && showTradehold && (() => {
                const days = Math.floor(asset.tradeHoldDays);
                const hours = Math.floor((asset.tradeHoldDays - days) * 24);
                return <span style={{
                  textAlign: 'center',
                  fontSize: iconSize == "medium" || iconSize == "large" ? "16px" : '14px',
                  textShadow: "1px 0 black, -1px 0 black, 0 1px black, 0 -1px black,0.5px 0.5px black, -0.5px -0.5px black, 0.5px -0.5px black, -0.5px 0.5px black",
                  color: 'white'
                }}>{days > 0 ? days.toString() + "D" : ""} {hours > 0 ? hours.toString() + "H" : ""}</span>;
              })()}
            </div>


          </Card>
          <div style={{ position: 'absolute', width: '100%', justifyContent: 'center', bottom: '2px', display: iconSize !== "large" && (asset?.itemCost ?? 0) != 0 ? 'block' : 'none', textAlign: 'center', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2 }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <a onClick={(event) => event.stopPropagation()} href={priceLinks ? ("https://steamcommunity.com/profiles/" + id + "/inventory/" + "#730_" + asset.contextid + "_" + asset.assetid) : (pricingSource == "Steam" ? steamLink(asset.itemName, null, null, null) : pricingSource == "Buff" ? buffLink(asset.itemName, null, null, null) : pricingSource == "CSFloat" ? csfloatLink(asset.itemName, null, null, null) : pricingSource == "Skinport" ? skinportLink(asset.itemName, null, null, null, null, null) : tradeitLink(asset.itemName, null, null, null))} target='_blank'><span style={{ fontSize: iconSize == "medium" ? '22px' : '16px', color: ((asset?.wearEstimate ?? false) && pricingSource == "Tradeit") ? "rgb(131, 131, 131)" : "lightskyblue" }}>{formatNumber((asset.itemCost) / exchangeRate, true)}</span></a>
              <div style={{ display: iconSize !== "large" && showInvestROI ? 'flex' : 'none', color: asset.ROI > -0.01 ? "green" : "rgb(187, 23, 26)", fontSize: iconSize == "small" ? '11px' : '13px', lineHeight: '0.5', marginTop: 'auto', marginBottom: 'auto', paddingBottom: '7px', paddingLeft: '2px', flexDirection: 'column', alignItems: 'center' }}>{asset.ROI > -0.01 ? <TrendingUpIcon style={{ width: iconSize == "small" ? "16px" : '19px', height: iconSize == "small" ? "12px" : "14px" }} /> : <TrendingDownIcon style={{ width: iconSize == "small" ? "16px" : '19px', height: iconSize == "small" ? "12px" : "14px" }} />}{(asset?.ROI ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(asset.ROI), false, 0)}%</div>
            </div>
            <p style={{ fontSize: iconSize == "medium" ? '13px' : '10px', lineHeight: '0.7', margin: 'auto', display: asset.numItems > 1 ? "block" : "none", verticalAlign: 'middle', color: ((asset?.wearEstimate ?? false) && pricingSource == "Tradeit") ? "rgb(131, 131, 131)" : "lightskyblue" }}> ({formatNumber((asset.itemCost * asset.numItems) / exchangeRate, true)} total)</p>


          </div>


          <div style={{ paddingLeft: '8px', display: iconSize == "large" ? "block" : "none", }}>
            <a style={{ pointerEvents: ((asset.isCollection ? asset?.collectionUrl : null) == null ? "none" : "auto") }} href={asset.isCollection ? asset?.collectionUrl : null} target='_blank' onClick={(e) => { e.stopPropagation() }}>
              <h5 style={{ lineHeight: 1, marginBottom: '0', marginTop: asset.itemName.split(' | ').length < 2 ? "16px" : '12px', width: '170px', fontSize: asset.itemName.split(' | ').length > 1 ? '0.82rem' : '0.95rem' }}>
                {
                  // asset.rarity !== "Contraband" ?
                  asset.itemName.split(' | ')[0]
                  // :
                  // (
                  //   Object.entries(caseData['KnifeCosts'][asset.itemName]).length >
                  //     Object.entries(getWears(caseData['Items'][asset.itemName]['FloatMin'] / 100, caseData['Items'][asset.itemName]['FloatMax'] / 100))
                  //       .filter(([key, percent]) => percent !== 0).length + 1 ?
                  //     (pastNumber[3] > 0.1 ? '' : 'StatTrak™ ') :
                  //     ''
                  // ) + asset.itemName.split(' | ')[0]
                }
              </h5>
              <h4 style={{ lineHeight: 1, marginTop: '2px', marginBottom: '0px' }}>{asset.itemName?.split(' | ')?.[1]?.split(" (")?.[0] ?? ""}</h4>
              <h5 style={{ lineHeight: 0.2, marginTop: '8px', fontSize: '0.75rem' }}>{(wearName != null ? (Object.values(realWearNames).includes(wearName) ? (("(" + (asset.itemName.includes("StatTrak") ? "ST " : "") + wearName + " - ") + formatNumber((asset.wearChances?.[wearName?.replaceAll("-", "")?.replaceAll(" ", "")] ?? 0) * (asset.itemName.includes("StatTrak") ? 0.1 : 0.9)) + "%)") : "(" + wearName + ")") : <br />)}</h5>
            </a>
            <div style={{ position: 'absolute', bottom: isMobile ? '84%' : asset.numItems > 1 ? '14.5px' : '22px', right: isMobile ? '4px' : '8px', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2, fontSize: isMobile ? '16px' : '22px', display: (asset?.itemCost ?? 0) != 0 ? 'flex' : 'none', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <p style={{ fontSize: '13px', lineHeight: '0', margin: 'auto', marginBottom: '-5px', display: showInvestROI ? "block" : "none", verticalAlign: 'middle', textAlign: 'center', color: (asset?.ROI ?? 0) > -0.01 ? "green" : "rgb(187, 23, 26)" }}>{(asset?.ROI ?? 0) > -0.01 ? <TrendingUpIcon style={{ width: "18px", height: "13px" }} /> : <TrendingDownIcon style={{ width: "18px", height: "13px" }} />}{(asset?.ROI ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(asset.ROI), false, 0)}%</p>
              <a href={priceLinks ? ("https://steamcommunity.com/profiles/" + id + "/inventory/" + "#730_" + asset.contextid + "_" + asset.assetid) : (pricingSource == "Steam" ? steamLink(asset.itemName, null, null, null) : pricingSource == "Buff" ? buffLink(asset.itemName, null, null, null) : pricingSource == "CSFloat" ? csfloatLink(asset.itemName, null, null, null) : pricingSource == "Skinport" ? skinportLink(asset.itemName, null, null, null, null, null) : tradeitLink(asset.itemName, null, null, null))} onClick={(event) => event.stopPropagation()} target='_blank'><span style={{ color: ((asset?.wearEstimate ?? false) && pricingSource == "Tradeit") ? "rgb(131, 131, 131)" : "lightskyblue" }}>{formatNumber((asset.itemCost) / exchangeRate, true)}</span></a>
              <p style={{ fontSize: '11px', lineHeight: '0.7', margin: 'auto', display: asset.numItems > 1 ? "block" : "none", verticalAlign: 'middle', textAlign: 'center' }}> ({formatNumber((asset.itemCost * asset.numItems) / exchangeRate, true)} total)</p>
            </div>

          </div>
        </div>}

      </div>
    ) : null;

  }

  useEffect(() => {
    // Initial calculation
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Assuming `response` holds the data you want to process with `handleResponseChange`
    // and it's already in the desired format or state.
    if (response) {
      handleResponseChange(response, backupResponse);
    }
  }, [combineRepeats, includeFreeItems, itemsAndCollections, sortBy, pricingSource, investType, sortDirection, onlyUnboxable, allROIS, enableBackup]); // This will trigger the effect when `combineRepeats` changes.

  useEffect(() => {
    // Assuming `response` holds the data you want to process with `handleResponseChange`
    // and it's already in the desired format or state.



    if (backupResponse) {
      handleResponseChange(response, backupResponse);
    }
  }, [backupResponse]); // This will trigger the effect when `combineRepeats` changes.

  useEffect(() => {
    // Assuming `response` holds the data you want to process with `handleResponseChange`
    // and it's already in the desired format or state.
    // if (response) {
    handleResponseChange(response, backupResponse);
    // }
  }, [response]); // This will trigger the effect when `combineRepeats` changes.



  const handleResize = () => {
    // Calculate aspect ratio 
    const glanceInfoSize = window.innerWidth * 2 / 11

    // Define the threshold value for aspect ratio to differentiate between mobile and desktop
    const glanceInfoThreshold = 215; // Adjust this value based on your needs

    // Set the state based on the aspect ratio
    setIsMobileLayout(glanceInfoSize < glanceInfoThreshold);
  };

  useEffect(() => {
    localStorage.setItem('steamId', id);
  }, [id]);




  useEffect(() => {
    let url = '/itemsandcollections.json';

    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
      url = 'http://localhost:8080/itemsandcollections.json';
    }
    fetch(url)
      .then(response => response.json())
      .then(data => setItemsAndCollections(data))
      .catch(error => console.error('Error:', error));

  }, []);


  function splitStickerNames(names) {
    const result = [];
    let currentName = '';
    let inParentheses = 0;

    for (let i = 0; i < names.length; i++) {
      const char = names[i];

      if (char === ',' && inParentheses === 0) {
        // Avoid splitting if we're dealing with the last part "Run CT, Run"
        if (!currentName.endsWith('Run CT') && !currentName.endsWith('Run T') && !currentName.includes("Don't Worry, I'm Pro") && !currentName.includes("Rock, Paper, Scissors (Foil)" && !currentName.includes("Hi, My Game Is"))) {
          result.push(currentName.trim());
          currentName = '';
        } else {
          currentName += char;
        }
      } else {
        currentName += char;
        if (char === '(') {
          inParentheses++;
        } else if (char === ')') {
          inParentheses--;
        }
      }
    }

    if (currentName) {
      result.push(currentName.trim());
    }

    return result;
  }


  const handleResponseChange = (value, backupResponse) => {

    if (itemsAndCollections != null) {
      try {
        const parsed = JSON.parse(value);
        let backupParsed = null;

        if (backupResponse) {
          backupParsed = JSON.parse(backupResponse);
        }

        // console.log(parsed);

        if (parsed.assets && parsed.descriptions) {
          // Merge assets if backupResponse is provided
          if (backupParsed && backupParsed.assets && backupParsed.descriptions && enableBackup) {
            const assetMap = new Map();
            backupParsed.assets.forEach(asset => assetMap.set(`${asset.assetid}_${asset.classid}_${asset.instanceid}`, asset));
            parsed.assets.forEach(asset => {
              if (!assetMap.has(`${asset.assetid}_${asset.classid}_${asset.instanceid}`)) {
                assetMap.set(`${asset.assetid}_${asset.classid}_${asset.instanceid}`, asset);
              }
            });
            parsed.assets = Array.from(assetMap.values());

            // Merge descriptions if backupResponse is provided
            const descriptionMap = new Map();
            backupParsed.descriptions.forEach(description => descriptionMap.set(`${description.classid}_${description.instanceid}`, description));
            parsed.descriptions.forEach(description => {
              if (!descriptionMap.has(`${description.classid}_${description.instanceid}`)) {
                descriptionMap.set(`${description.classid}_${description.instanceid}`, description);
              }
            });
            parsed.descriptions = Array.from(descriptionMap.values());
          }

          const collectionDataMap = new Map(); // New map to store collection data
          var tempAllItemValue = 0;
          var tempAllItem1YValue = 0;
          var tempAllItem6MValue = 0;
          var tempAllItem1MValue = 0;
          // var tempAllCollectionValue = 0;
          // Loop through each asset and find the corresponding description
          var lastCollectionId = "";
          const assetsWithMarketName = parsed.assets.map(asset => {
            var tempAllItem1YPrice = 0;
            var tempAllItem6MPrice = 0;
            var tempAllItem1MPrice = 0;
            const correspondingDescription = parsed.descriptions.find(description =>
              description.classid === asset.classid && description.instanceid === asset.instanceid
            );

            // Extract trade hold information
            let tradeHoldDays = null;

            if (correspondingDescription?.owner_descriptions) {
              const tradeHoldDescription = correspondingDescription.owner_descriptions.find(desc =>
                desc.value.includes("Tradable/Marketable After")
              );
              if (tradeHoldDescription) {
                // Update the regex to capture the full date and time string
                const match = tradeHoldDescription.value.match(/After (.+) \((\d{1,2}:\d{2}:\d{2})\) GMT/);
                if (match) {
                  // Combine the date and time parts
                  const tradeHoldDate = new Date(`${match[1]} ${match[2]} GMT`);

                  // Get the current date in GMT
                  const currentDate = new Date();
                  const currentDateGMT = new Date(currentDate.toUTCString());

                  // Calculate the difference in milliseconds
                  const timeDiff = tradeHoldDate.getTime() - currentDateGMT.getTime();

                  // Convert the time difference from milliseconds to days
                  tradeHoldDays = timeDiff / (1000 * 60 * 60 * 24);
                }
              }
            }

            var stickerDescription = null;
            var stickerData = [];
            var charmDescription = null;
            var charmData = null;
            if (correspondingDescription?.descriptions != null) {
              stickerDescription = correspondingDescription.descriptions.find(description =>
                description.value.includes("sticker_info") && description.value.includes("Sticker")
              );

              if (stickerDescription) {
                // Extract all image sources (src) from <img> tags
                const stickerInfo = Array.from(
                  stickerDescription.value.matchAll(/<img[^>]+src="([^">]+)"/g),
                  match => match[1] // Extract the src URL directly
                );

                // Extract sticker names after "Sticker:" or "Patch:"
                const stickerNamesMatch = stickerDescription.value.match(/Sticker: (.+?)<\/center>/);
                if (stickerNamesMatch) {
                  const stickerNames = stickerNamesMatch[1]
                    .split(/,(?![^()]*\))/) // Split names by commas not within parentheses
                    .map(name => name.replace(/\">$/, "").trim()); // Trim trailing `\">` and whitespace

                  // Map names and images together, ensuring alignment
                  stickerData = stickerNames.map((name, index) => ({
                    name: name,
                    image: stickerInfo[index] || null // Handle mismatched lengths safely
                  }));
                }
              }
            }



            // Charm extraction
            charmDescription = correspondingDescription.descriptions.find(description =>
              description.value.includes("keychain_info") && description.value.includes("Charm")
            );
            if (charmDescription) {
              const charmInfo = charmDescription.value.match(/<img[^>]+src="([^">]+)"[^>]*>/);
              const charmNameMatch = charmDescription.value.match(/Charm: ([^<]+)/);
              if (charmNameMatch && charmInfo) {
                charmData = {
                  name: charmNameMatch[1].replace('">', ""),
                  image: charmInfo[1]
                };
              }
            }


            var itemName = correspondingDescription.market_hash_name;
            if (correspondingDescription.name.includes("Music Kit")) {
              var strippedName = correspondingDescription.name.replaceAll("★ ", "").replaceAll("Souvenir ", "");
            } else {
              var strippedName = correspondingDescription.name.replaceAll("★ ", "").replaceAll("StatTrak™ ", "").replaceAll("Souvenir ", "");
            }
            // console.log(strippedName);
            // console.log(itemsAndCollections[strippedName]);
            var collectionDataList = itemsAndCollections[strippedName]?.CollectionData; // Get collection data list
            if (collectionDataList == null) {
              var collectionDataList = itemsAndCollections[strippedName + " | ★ (Vanilla)"]?.CollectionData; // Get collection data list
            }

            // if (collectionDataList) {

            //   collectionDataList.forEach(collectionData => {
            //     // Calculate collection cost
            //     // var collectionCost = collectionData?.[pricingSource == "Buff" ? "BuffCollectionPrice" : pricingSource == "Steam" ? "SteamCollectionPrice" : "SkinportCollectionPrice"];

            //     // Calculate item cost
            //     // var itemCost = collectionData?.[pricingSource == "Buff" ? "BuffCost" : pricingSource == "Steam" ? "SteamCost" : "SkinportCost"];

            //     var icon_url = correspondingDescription.icon_url;
            //     var realWearName = correspondingDescription.market_hash_name.replaceAll(strippedName, "");
            //     var wearName = correspondingDescription.market_hash_name.replaceAll(correspondingDescription.name, "").replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "").replaceAll("-", "");
            //     var allWears = collectionData?.[pricingSource == "Buff" ? "BuffCost" : pricingSource == "Steam" ? "SteamCost" : "SkinportCost"];
            //     var itemCost = (((allWears?.[(itemName.includes("StatTrak") ? "StatTrak™ " : "") + (correspondingDescription.market_hash_name == correspondingDescription.name ? "totalItemValue" : wearName)])));
            //     var collectionCost = collectionData?.[pricingSource == "Buff" ? "BuffCollectionPrice" : pricingSource == "Steam" ? "SteamCollectionPrice" : "SkinportCollectionPrice"];
            //     var numRarity = (collectionData?.["NumRarity"] ?? 1);
            //     var itemChance = (1 / ((collectionData?.["Chance"] ?? 0) / numRarity));

            //     // Add collection data to the map if it doesn't exist yet
            //     var collectionId = collectionData?.["CollectionId"];
            //     if ((!collectionId.includes("Souvenir") && !itemName.includes("Souvenir")) || (collectionId.includes("Souvenir") && itemName.includes("Souvenir"))) {
            //       if (!collectionDataMap.has(collectionId)) {
            //         collectionDataMap.set(collectionId, new Map());
            //       }
            //       const itemData = collectionDataMap.get(collectionId).get(itemName) || {};
            //       const { SteamCollectionPrice, SteamCost, SkinportCost, SkinportCollectionPrice, BuffCost, BuffCollectionPrice, WearChances, ...restCollectionData } = collectionData;
            //       if (!collectionDataMap.get(collectionId).has(itemName)) {
            //         const count = 1;
            //         collectionDataMap.get(collectionId).set(itemName, { ...restCollectionData, collectionCost, itemCost, itemCost, collectionCost, itemChance, count, icon_url });
            //       } else {
            //         collectionDataMap.get(collectionId).get(itemName).count++;
            //       }

            //     }
            //   });
            //   // Sort the items in each collection by itemCost
            //   for (let [collectionId, items] of collectionDataMap) {
            //     const sortedItems = new Map([...items.entries()].sort((a, b) => b[1].itemCost - a[1].itemCost));
            //     collectionDataMap.set(collectionId, sortedItems);
            //   }
            // }



            var collectionData = collectionDataList?.[0];

            if (itemName.includes("Souvenir")) {
              collectionData = collectionDataList?.find(collectionData => collectionData?.["CollectionId"].includes("Souvenir"));
            }
            // else if (lastCollectionId != "" && collectionDataList?.find(collectionData => collectionData?.["CollectionId"] == lastCollectionId) != null) {
            //   collectionData = collectionDataList?.find(collectionData => collectionData?.["CollectionId"] == lastCollectionId);
            // }

            var rarity = collectionData?.["Rarity"];
            var realWearName = correspondingDescription.market_hash_name.replaceAll(strippedName, "");
            var wearName = correspondingDescription.market_hash_name.replaceAll(correspondingDescription.name, "").replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "").replaceAll("-", "");
            var allWears = collectionData?.[pricingSource == "Buff" ? "BuffCost" : pricingSource == "Steam" ? "SteamCost" : pricingSource == "CSFloat" ? "CSFloatCost" : pricingSource == "Tradeit" ? "TradeitCost" : "SkinportCost"];
            var itemCost = (((allWears?.[(itemName.includes("StatTrak") ? "StatTrak™ " : "") + (correspondingDescription.market_hash_name == correspondingDescription.name ? "totalItemValue" : wearName)])) ?? (allROIS?.[itemName]?.["current"] ?? 0));


            var collectionCost = collectionData?.[pricingSource == "Buff" ? "BuffCollectionPrice" : pricingSource == "Steam" ? "SteamCollectionPrice" : pricingSource == "CSFloat" ? "CSFloatCollectionPrice" : pricingSource == "Tradeit" ? "TradeitCollectionPrice" : "SkinportCollectionPrice"];
            if ((!itemName.includes("Graffiti") || (collectionCost ?? 0 > 0.01))) {
              tempAllItemValue += itemCost ?? 0;
              tempAllItem1YPrice = (itemCost ?? 0) / (1 + ((allROIS?.[itemName]?.["1Y_Price"] != null ? (allROIS?.[itemName]?.["ROI"] ?? 0) : 0) / 100));
              tempAllItem6MPrice = (itemCost ?? 0) / (1 + ((allROIS?.[itemName]?.["6M_Price"] != null ? (allROIS?.[itemName]?.["6M_ROI"] ?? 0) : 0) / 100));
              tempAllItem1MPrice = (itemCost ?? 0) / (1 + ((allROIS?.[itemName]?.["1M_Price"] != null ? (allROIS?.[itemName]?.["1M_ROI"] ?? 0) : 0) / 100));
              tempAllItem1YValue += tempAllItem1YPrice;
              tempAllItem6MValue += tempAllItem6MPrice;
              tempAllItem1MValue += tempAllItem1MPrice;
            }
            var rarityChance = collectionData?.["Chance"];
            var numRarity = (collectionData?.["NumRarity"] ?? 1);
            var itemChance = collectionData?.["Chance"] != null ? (1 / ((collectionData?.["Chance"] ?? 0) / numRarity)) : 1;
            // tempAllCollectionValue += ((collectionCost ?? 0) / (1 / (itemChance ?? 1)));
            var extraCollections = {};
            if ((collectionDataList?.length ?? 0) > 1) {
              collectionDataList.forEach((tempCollectionData, index) => {
                if (tempCollectionData?.["CollectionId"] != collectionData?.["CollectionId"] && ((itemName.includes("Souvenir")) == (tempCollectionData?.["CollectionId"].includes("Souvenir")))) {
                  extraCollections[tempCollectionData?.["CollectionId"]] = {
                    collectionCost: tempCollectionData?.[pricingSource == "Buff" ? "BuffCollectionPrice" : pricingSource == "Steam" ? "SteamCollectionPrice" : pricingSource == "CSFloat" ? "CSFloatCollectionPrice" : pricingSource == "Tradeit" ? "TradeitCollectionPrice" : "SkinportCollectionPrice"],
                    tradeitEstimateCost: tempCollectionData?.["TradeitEstimateCase"] ?? true,
                    collectionImage: tempCollectionData?.["CollectionImage"],
                    collectionUrl: "#/item/" + tempCollectionData?.["CollectionUrl"],
                  };
                  // extraCollectionCosts.push(collectionData?.[pricingSource == "Buff" ? "BuffCollectionPrice" : pricingSource == "Steam" ? "SteamCollectionPrice" : "SkinportCollectionPrice"]);
                }
              });
            }
            lastCollectionId = collectionData?.["CollectionId"];
            var currROI = allROIS?.[itemName]?.[investType == "1m" ? "1M_ROI" : investType == "6m" ? "6M_ROI" : "ROI"] ?? 0;
            var currCase = null;
            var internalColor = correspondingDescription?.tags?.find(tag => tag.category === "Rarity")?.color;
            var collectionUrl = collectionData?.CollectionUrl;
            var isCollection = false;
            if (rarity == null) {
              if (allTrackedCases && Array.isArray(allTrackedCases) && allTrackedCases.some(caseItem => caseItem.Name === itemName)) {

                currCase = allTrackedCases.find(caseItem => caseItem.Name === itemName)
                if (currCase != null) {
                  collectionUrl = "#/item/" + currCase?.["UrlName"];
                  isCollection = true;
                }
                var compareValue = currCase?.["SteamROI"] ?? 0;
                if (compareValue > 1) {
                  rarity = "Contraband";
                } else if (compareValue > 0.8) {
                  rarity = "Covert";
                } else if (compareValue > 0.6) {
                  rarity = "Classified";
                } else if (compareValue > 0.4) {
                  rarity = "Restricted";
                } else {
                  rarity = "Mil_Spec";
                }
              } else if (internalColor != null || itemName.includes("★") || itemName.includes("M4A4 | Howl") || itemName.includes("Howling Dawn")) {
                if (itemName.includes("★") || itemName.includes("M4A4 | Howl") || itemName.includes("Howling Dawn")) {
                  rarity = "Contraband";
                } else if (internalColor == "eb4b4b") {
                  rarity = "Covert";
                } else if (internalColor == "d32ce6") {
                  rarity = "Classified";
                } else if (internalColor == "8847ff") {
                  rarity = "Restricted";
                } else if (internalColor == "4b69ff") {
                  rarity = "Mil_Spec";
                } else if (internalColor == "5e98d9") {
                  rarity = "Industrial";
                } else if (internalColor == "b0c3d9") {
                  rarity = "Consumer";
                }
              }
            } else {
              currCase = allTrackedCases.find(caseItem => caseItem.Name === collectionData?.["CollectionId"])
              if (currCase != null) {
                collectionUrl = "#/item/" + currCase?.["UrlName"];
              }
            }

            return {
              ...asset,
              market_hash_name: correspondingDescription ? correspondingDescription.market_hash_name : '',
              icon_url: correspondingDescription ? correspondingDescription.icon_url : '',
              name: correspondingDescription ? correspondingDescription.name : '',
              itemName: itemName,
              strippedName: strippedName,
              rarity: rarity,
              realWearName: realWearName,
              wearName: wearName,
              wearChances: collectionData?.["WearChances"],
              wearEstimate: collectionData?.TradeitEstimates?.[wearName] ?? collectionData?.TradeitEstimates ?? false,
              allWears: allWears,
              itemCost: itemCost,
              collectionCost: collectionCost,
              collectionTradeitEstimate: currCase?.["TradeitEstimateCase"] ?? collectionData?.IsCaseEstimateTradeit ?? true,
              keyCost: collectionData?.[pricingSource == "Buff" ? "BuffKeyPrice" : pricingSource == "Steam" ? "SteamKeyPrice" : pricingSource == "CSFloat" ? "CSFloatKeyPrice" : pricingSource == "Tradeit" ? "TradeitKeyPrice" : "SkinportKeyPrice"],
              rarityChance: rarityChance,
              rarityChances: collectionData?.RarityChances,
              numRarity: numRarity,
              itemChance: itemChance,
              collectionImage: collectionData?.["CollectionImage"],
              collectionName: collectionData?.["CollectionId"],
              oneMonthROI: allROIS?.[itemName]?.["1M_Price"] != null ? (allROIS?.[itemName]?.["1M_ROI"] ?? 0) : 0,
              sixMonthROI: allROIS?.[itemName]?.["6M_Price"] != null ? (allROIS?.[itemName]?.["6M_ROI"] ?? 0) : 0,
              oneYearROI: allROIS?.[itemName]?.["1Y_Price"] != null ? (allROIS?.[itemName]?.["ROI"] ?? 0) : 0,
              oneMonthPrice: tempAllItem1MPrice,
              sixMonthPrice: tempAllItem6MPrice,
              oneYearPrice: tempAllItem1YPrice,
              ROI: currROI,
              UnboxROI: currCase?.[pricingSource == "Buff" ? "BuffROI" : pricingSource == "Steam" ? "SteamROI" : pricingSource == "CSFloat" ? "CSFloatROI" : pricingSource == "Tradeit" ? "TradeitROI" : "SkinportROI"] ?? 0,
              ProfitChance: currCase?.[[pricingSource == "Buff" ? "ProfitBuff" : pricingSource == "Steam" ? "ProfitSteam" : pricingSource == "CSFloat" ? "ProfitCSFloat" : pricingSource == "Tradeit" ? "ProfitTradeit" : "ProfitSkinport"]] ?? 0,
              extraCollections: extraCollections,
              stickerList: stickerData,
              charm: charmData,
              collectionUrl: collectionUrl,
              isCollection: isCollection,
              tradeHoldDays: tradeHoldDays,
              numItems: 1,
            };
          });

          // console.log(assetsWithMarketName);

          const filteredAssets = assetsWithMarketName.filter(asset => (((asset?.collectionCost ?? 0) > (includeFreeItems ? 0.001 : 0.01) || !onlyUnboxable) && (!asset?.itemName.includes("Graffiti") || (asset?.collectionCost ?? 0 > 0.01)) && ((asset?.itemCost != 0 && (asset?.collectionCost ?? 0.02) > 0.01) || includeFreeItems)));

          if (combineRepeats) {
            // First, we will use the result from the previous map operation
            const combinedAssets = filteredAssets.reduce((acc, asset) => {
              // Check if the asset with the same market_hash_name already exists in the accumulator
              if (acc[asset.market_hash_name]) {
                // If it exists, increment the numItems count
                acc[asset.market_hash_name].numItems += 1;
              } else {
                // If it doesn't exist, add the asset to the accumulator and initialize numItems to 1
                acc[asset.market_hash_name] = { ...asset, numItems: 1 };
              }
              return acc;
            }, {});

            // Convert the combinedAssets object back into an array
            const combinedAssetsArray = Object.values(combinedAssets);
            if (sortBy === "value" || sortBy === "rarity" || sortBy === "collection") {
              combinedAssetsArray.sort((a, b) => {
                if ((a.itemCost * a.numItems) > (b.itemCost * b.numItems)) return -1;
                if ((a.itemCost * a.numItems) < (b.itemCost * b.numItems)) return 1;
                return 0;
              });
            } else if (sortBy === "invest") {
              combinedAssetsArray.sort((a, b) => {
                if (a.ROI > b.ROI) return -1;
                if (a.ROI < b.ROI) return 1;
                return 0;
              });
            }
            //Sort by rarity order in rarityOrder
            if (sortBy === "rarity") {
              combinedAssetsArray.sort((a, b) => {
                if (rarityOrder.indexOf(otherRarityNames?.[a.rarity] ?? a.rarity) > rarityOrder.indexOf(otherRarityNames?.[b.rarity] ?? b.rarity)) return 1;
                if (rarityOrder.indexOf(otherRarityNames?.[a.rarity] ?? a.rarity) < rarityOrder.indexOf(otherRarityNames?.[b.rarity] ?? b.rarity)) return -1;
                return 0;
              });
            }
            // Sort by collection
            if (sortBy === "collection") {
              combinedAssetsArray.sort((a, b) => {
                return a.collectionName.localeCompare(b.collectionName);
              });
            }
            if (!sortDirection) {
              combinedAssetsArray.reverse();
            }
            setAssets(combinedAssetsArray);
          } else {
            if (sortBy === "value" || sortBy === "rarity" || sortBy === "collection") {
              filteredAssets.sort((a, b) => {
                if (a.itemCost > b.itemCost) return -1;
                if (a.itemCost < b.itemCost) return 1;
                return 0;
              });
            } else if (sortBy === "invest") {
              filteredAssets.sort((a, b) => {
                if (a.ROI > b.ROI) return -1;
                if (a.ROI < b.ROI) return 1;
                return 0;
              });
            }
            //Sort by rarity order in rarityOrder
            if (sortBy === "rarity") {
              filteredAssets.sort((a, b) => {
                if (rarityOrder.indexOf(otherRarityNames?.[a.rarity] ?? a.rarity) > rarityOrder.indexOf(otherRarityNames?.[b.rarity] ?? b.rarity)) return 1;
                if (rarityOrder.indexOf(otherRarityNames?.[a.rarity] ?? a.rarity) < rarityOrder.indexOf(otherRarityNames?.[b.rarity] ?? b.rarity)) return -1;
                return 0;
              });
            }
            // Sort by collection
            if (sortBy === "collection") {
              filteredAssets.sort((a, b) => {
                return (a?.collectionName ?? "zzz").localeCompare(b?.collectionName ?? "zzz");
              });
            }
            if (!sortDirection) {
              filteredAssets.reverse();
            }
            setAssets(filteredAssets);
          }



          setTotalItemNum(assetsWithMarketName.length);
          setAllItemValue(tempAllItemValue);
          setAllItem1YValue(tempAllItem1YValue);
          setAllItem6MValue(tempAllItem6MValue);
          setAllItem1MValue(tempAllItem1MValue);
          if (selectInventoryScroll) {
            scrollToElement("inventoryCard");
          }
          
          // setAllCollectionValue(tempAllCollectionValue);
          // setCollectionsUsed(collectionDataMap); // Set the collection data map

          // console.log(collectionDataMap); // Log the collection data map
        }
      } catch (error) {
        console.error('Error parsing JSON:', error);
        setAssets([]); // Clear the assets if there is an error
        // setCollectionsUsed(new Map()); // Clear the collections used if there is an error
      }
    }
  };



  //Need to use currencies here:
  return (
    <ThemeProvider theme={theme}>
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="garequest"
        style={{ background: "#2B373B", zIndex: 99999 }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies for anonymous Google Analytics. By continuing to use this website, you consent to the use of cookies.
      </CookieConsent>
      <Helmet>
        {/* Standard Meta Tags */}
        <title>CSROI Inventory | Find YOUR Unboxing and Investing ROI!</title>
        <meta
          name="description"
          content="Inspect any inventory for your Investing ROI and Unboxing ROI, with in depth unboxing stats."
        />
        <meta
          name="keywords"
          content="cs2, csgo, inventory, skinport, csfloat, buff, uupin, tradeit, roi, case, capsule, souvenir, sticker, armory, stars, invest, pin, graffiti, autographs, listings, profit, unbox, simulator, history, music kit, collection, operation"
        />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="CSROI Inventory | Find YOUR Unboxing and Investing ROI!" />
        <meta
          property="og:description"
          content="Analyze your CS2 inventory to uncover your Unboxing and Investing ROI. Get in-depth stats, market insights, and see how you can maximize your profit!"
        />
        <meta property="og:image" content="https://csroi.com/logo512.png" />
        <meta property="og:url" content="https://csroi.com/#/inventory" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="CSROI Inventory | Find YOUR Unboxing and Investing ROI!" />
        <meta
          name="twitter:description"
          content="Inspect your CS2 inventory for comprehensive Unboxing and Investing ROI data. Discover market trends and maximize your profit with our detailed analysis tool!"
        />
        <meta name="twitter:image" content="https://csroi.com/logo512.png" />

        {/* Canonical URL */}
        <link rel="canonical" href="https://csroi.com/#/inventory" />

        {/* Schema Markup (JSON-LD) */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "CSROI Inventory",
            "description": "Inspect your CS2 inventory to analyze Unboxing and Investing ROI with detailed stats, market insights, and historical data. Optimize your profit strategy with CSROI.",
            "url": "https://csroi.com/#/inventory",
            "image": "https://csroi.com/logo512.png",
            "publisher": {
              "@type": "Organization",
              "name": "CSROI",
              "logo": {
                "@type": "ImageObject",
                "url": "https://csroi.com/logo512.png"
              }
            }
          })}
        </script>
      </Helmet>

      <Box sx={{ display: 'flex', padding: 0, transition: "width 5s infinite alternate, height 5s infinite alternate", }}>
        <Drawer
          sx={{
            display: isMobile ? 'none' : 'initial',
            width: "240px",
            // flexShrink: 0,

            '& .MuiDrawer-paper': {
              width: "240px",
              boxSizing: 'border-box',
            },
          }}
          variant="permanent"
          anchor="left"
        // open={openDrawer}
        >
          <Scrollbars
            autoHide
            // autoHideTimeout={1000}
            // autoHideDuration={200}
            renderView={props => <div {...props} style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }} />}
            renderThumbVertical={({ style }) => (
              <div style={{ ...style, backgroundColor: 'lightgray', borderRadius: '4px' }} />
            )}
          >
            <DrawerHeader sx={{ paddingTop: '12px', paddingBottom: '12px' }}>
              <Link to='/' style={{ textDecoration: 'none', color: 'inherit', width: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: 'auto', marginBottom: '6px', marginTop: '6px', cursor: 'pointer' }}>
                  <img src="/csroi.png" class="p-2" style={{ maxWidth: '280px', width: '100%', height: "auto", objectFit: 'contain', margin: 'auto' }} />

                </div>
              </Link>


            </DrawerHeader>
            <Divider />
            {/* <h5 style={{ textAlign: 'center', maxHeight: '180px', padding: '4px' }}>
              Welcome to the all new CSROI.com!<br /><br />
              Featuring a new overhauled React UI, full currency conversion, a new Investing ROI, upgraded simulator, among many more!<br /><br />
              Any feedback is appreciated <a href="https://twitter.com/BrongoCS" target='_blank'>@BrongoCS</a>
            </h5> */}
            {/* <Divider /> */}
            {/* <iframe width={drawerWidth} height="180" src="https://www.youtube.com/embed/mMCqwpVC2Zc?si=1DY9BEJKcLe66qy3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;" allowFullScreen ></iframe> */}
            {/* <Divider /> */}
            <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer' }}>
                <p>
                  Rankings

                </p>
              </div>
            </Link>
            <Divider />
            <Link to='/armory' style={{ textDecoration: 'none', color: 'inherit' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer' }}>
                <p style={{ lineHeight: '0.9' }}>
                  Armory
                  <br />
                  <span style={{ fontSize: '0.7rem' }}>Find the best way to spend YOUR Armory Stars</span>
                </p>
                {/* <Chip label="NEW" variant="outlined" size='small' sx={{ color: 'white', border: '1px white solid', fontSize: '0.8rem', height: '24px', width: '48px', padding: '0' }} /> */}

              </div>
            </Link>
            <Divider />
            <Link to='/deals' style={{ textDecoration: 'none', color: 'inherit' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer' }}>
                <p style={{ lineHeight: '0.9' }}>
                  Deals
                  <br />
                  <span style={{ fontSize: '0.7rem' }}>Resell Skins to get up to 1.5x Steam Wallet Balance!</span>
                </p>
                <Chip label="NEW" variant="outlined" size='small' sx={{ color: 'white', border: '1px white solid', fontSize: '0.8rem', height: '24px', width: '48px', padding: '0' }} />
              </div>
            </Link>
            <Divider/>
            <Link to='/inventory' style={{ textDecoration: 'none', color: 'inherit' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.12)' }}>
                <p style={{ lineHeight: '0.9' }}>
                  Inventory
                  <br />
                  <span style={{ fontSize: '0.7rem', }}>Find YOUR Unboxing and Investing ROI!</span>
                </p>
                {/* <Chip label="NEW" variant="outlined" size='small' sx={{ color: 'white', border: '1px white solid', fontSize: '0.8rem', height: '24px', width: '48px', padding: '0' }} /> */}
              </div>
            </Link>
            <Divider  style={{ borderWidth: '2px' }}  />
            <Link to='/charmcolors' style={{ textDecoration: 'none', color: 'inherit' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer', }}>
                <p style={{ lineHeight: '0.9' }}>
                  Charm Colors
                  <br />
                  <span style={{ fontSize: '0.7rem' }}>Find the color and pattern of charm to match your skin!</span>
                </p>
                {/* <Chip label="NEW" variant="outlined" size='small' sx={{ color: 'white', border: '1px white solid', fontSize: '0.8rem', height: '24px', width: '48px', padding: '0' }} /> */}

              </div>
            </Link>

            <Divider />

            <div style={{ flex: '1' }}></div>
            <Divider />
            <FormControl sx={{ fontFamily: "'Barlow', 'Roboto', sans-serif", padding: '0 12px 8px 12px', marginTop: '12px', width: '100%' }}>
              <InputLabel id="pricing-source-label" sx={{ paddingLeft: '14px' }}>Pricing Source</InputLabel>
              <Select
                //  renderInput={(params) => <TextField {...params} label="Pricing Source" />}

                labelId="pricing-source-label"
                id="pricing-source"
                value={pricingSource}
                label="Pricing Source"
                onChange={handlePricingSourceChange}

              >
                <MenuItem value="Steam">Steam</MenuItem>
                <MenuItem value="Buff">
                  <div>
                    Buff.163 / UUPin<Tooltip title="Buff.163 prices are preferred, but UUPin prices are used when Buff.163 prices are unavailable (e.g., containers) or when there is a significant price difference." sx={{ margin: 'auto' }}>

                      <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '4px', marginBottom: '-4px' }} fontSize='large' />
                    </Tooltip>
                  </div>
                </MenuItem>
                <MenuItem value="CSFloat">CSFloat</MenuItem>
                <MenuItem value="Skinport">Skinport</MenuItem>
                <MenuItem value="Tradeit">
                  <div>
                    Tradeit.gg
                    <Tooltip title="Tradeit.gg pricing reflects the cost to withdraw containers and trade back unboxed items. Estimated prices, marked in grey, are used when specific item prices are unavailable." sx={{ margin: 'auto' }}>

                      <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '4px', marginBottom: '-4px' }} fontSize='large' />
                    </Tooltip></div>
                </MenuItem>
                {/* Add more pricing source options if needed */}
              </Select>
            </FormControl>
            <Divider />
            <Autocomplete
              value={currency}
              onChange={(event, newValue) => setCurrency(newValue)}
              disableClearable
              options={currencies}
              getOptionLabel={(option) => option.toUpperCase()}
              sx={{ fontFamily: "'Barlow', 'Roboto', sans-serif", padding: '12px 12px 12px 12px' }}
              renderInput={(params) => <TextField {...params} label="Currency" />}
            />
            {/* <p style={{textAlign: 'center', margin: '0'}}> */}

            {/* {(!isMobile || openSettings) && <div style={{ display: 'flex', marginTop: '8px', marginBottom: '8px' }}> */}

            {/* </div>} */}
            {/* <Divider />
            {!isMobile && items?.length > 0 &&
              <p style={{
                margin: '12px',
                textAlign: 'center',
                backgroundColor: "#121212",
                border: 'rgba(255, 255, 255, 0.23) 1px solid',
                color: 'white',
                padding: '.25rem .75rem',
                borderRadius: '0.25rem'
              }} >
                Updated<br />
                {(() => {
                  const updatedAtUTC = items[0]["UpdatedAt"] * 1000;
                  const now = new Date();
                  const timeDifference = now.getTime() - updatedAtUTC;
                  const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
                  const daysDifference = Math.floor(hoursDifference / 24);

                  let message;
                  if (daysDifference > 0) {
                    message = `${daysDifference} day${daysDifference !== 1 ? 's' : ''} ago`;
                  } else {
                    // if (hoursDifference > 0) {
                    message = `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
                    // } else {
                    //   message = "Less than an hour ago";
                    // }
                  }

                  return message;
                })()}
              </p>


            } */}

            <Divider />
            <div style={{ margin: '12px', }}>
              <p style={{

                textAlign: 'center',
                backgroundColor: "#121212",
                border: 'rgba(255, 255, 255, 0.23) 1px solid',
                color: 'white',
                padding: '.25rem .75rem',
                borderRadius: '0.25rem', textAlign: "center", margin: "auto", color: "white",
              }}>A Project By Brongo: <br />
                <div style={{ display: 'flex' }}>
                  <a href="https://twitter.com/BrongoCS" target="_blank" style={{ flex: 1 }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 1200 1227" fill="none">
                    <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" fill="white"></path>
                  </svg></a>
                  <a href="https://www.youtube.com/@BrongoCS" target="_blank" style={{ flex: 1 }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 576 512" fill="none"><path fill="white" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path></svg></a>
                  <a href="https://steamcommunity.com/tradeoffer/new/?partner=83942777&amp;token=Z_ZUTuBY" style={{ flex: 1 }}><img src="/assets/steamlogo.png" style={{ width: '20px', height: '20px' }} /></a>
                </div>
              </p>
            </div>
            <Divider />

            <div style={{ margin: '12px', display: 'flex', flexDirection: 'row' }} >
              <Link to="/faq" style={{ textDecoration: 'none', marginLeft: 'auto', marginRight: 'auto' }}>
                <p style={{

                  textAlign: 'center',
                  backgroundColor: "#121212",
                  border: 'rgba(255, 255, 255, 0.23) 1px solid',
                  color: 'white',
                  padding: '.25rem .75rem',
                  borderRadius: '0.25rem', textAlign: "center", margin: "auto", color: "white", width: '70px', cursor: "pointer",

                }}>FAQ
                </p>
              </Link>
              <Link to="/changelog" style={{ textDecoration: 'none', marginLeft: 'auto', marginRight: 'auto' }}>
                <p style={{

                  textAlign: 'center',
                  backgroundColor: "#121212",
                  border: 'rgba(255, 255, 255, 0.23) 1px solid',
                  color: 'white',
                  padding: '.25rem .75rem',
                  borderRadius: '0.25rem', textAlign: "center", margin: "auto", color: "white", width: '120px', cursor: "pointer",
                }}>Change Log
                </p>
              </Link>
            </div>

            <Divider />
            {/* <h5 style={{ textAlign: 'center', maxHeight: '180px', padding: '4px' }}>
              All Key Costs for third-party marketplaces are now automatically adjusted to reflect an easily achievable cost when converting to Steam Market balance. This improves ROI calculations.
            </h5> */}
            {/* <h5 style={{ textAlign: 'center', maxHeight: '180px', padding: '4px' }}>
              Buff.163 has removed all containers from their site. They will continue to be tracked for historical purposes. All container prices using Buff.163 pricing from the 19th of May onwards are now instead using 80% of the Steam Market cost, as most users can convert Buff.163 balance to Steam Market balance at that rate or better.
            </h5> */}
            {/* <Divider /> */}
            {/* <iframe width={drawerWidth} height="180" src="https://www.youtube.com/embed/mMCqwpVC2Zc?si=1DY9BEJKcLe66qy3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;" allowFullScreen ></iframe> */}
            <Divider />
          </Scrollbars>
        </Drawer>
        <RangeSelectionProvider>
          <div style={{ height: '87vh', width: '100%', }} id="invInfo">
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', margin: '8px', paddingBottom: '8px' }} >
              {/* <a href='/'><img src="https://csroi.com/csroi.png" class="p-2" style={{ cursor: "pointer", maxWidth: '280px', height: "64px", objectFit: 'contain' }} /></a> */}

              {/* <label> */}
              {/* Your Steam ID: */}
              {/* <input type="text" value={id} onChange={} /> */}
              <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                <TextField
                  label="Your Steam ID64"
                  variant='outlined'
                  sx={{ width: enableBackup ? '160px' : '220px' }}
                  value={id}
                  onChange={(e) => setId(e.target.value)}
                  onClick={(e) => {
                    if (e?.target?.value != null) {
                      e.target.select()
                    }
                  }}
                />
                <a style={{ fontSize: '12px' }} href='https://steamid.io/' target='_blank'>https://steamid.io/</a>
              </div>
              {/* </label> */}
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {/* <a href={``} target="_blank" rel="noopener noreferrer"> */}
                {/* <input type="button" value="Open Raw Inventory Link in Browser" /> */}
                <Button variant="contained" onClick={() => window.open(`https://steamcommunity.com/inventory/${id}/730/2?l=english&count=1000`, '_blank')} style={{ backgroundColor: '#bb171a', color: 'white', margin: '6px 8px', fontSize: '0.7rem' }}>OPEN INVENTORY DATA IN BROWSER</Button>
                {/* </a> */}
                <Button variant="contained" onClick={() => window.open(`steam://openurl/https://steamcommunity.com/inventory/${id}/730/2?l=english&count=1000`)} style={{ backgroundColor: '#bb171a', color: 'white', margin: '6px 8px', fontSize: '0.7rem' }}>OPEN INVENTORY DATA IN STEAM</Button>


              </div>
              {/* <label style={{display: 'flex', flexDirection: 'row'}}> */}
              {/* <p  style={{marginTop: 'auto', marginBottom: 'auto', marginRight: '4px'}}>Paste raw inventory data here: </p> */}
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <TextField
                  label="Raw Inventory Data"
                  variant='outlined'
                  value={response}
                  sx={{ width: enableBackup ? '160px' : '220px' }}
                  onChange={(e) => {
                    localStorage.setItem('backupInventoryTimestamp', Date.now().toString());
                    setBackupInventoryTimestamp(Date.now());
                    localStorage.setItem('backupInventoryData', '');
                    setBackupResponse('')
                    localStorage.setItem('inventoryTimestamp', Date.now().toString());
                    setInventoryTimestamp(Date.now());
                    localStorage.setItem('inventoryData', e.target.value);
                    setResponse(e.target.value)
                  }}
                  onClick={(e) => {
                    if (e?.target?.value != null) {
                      e.target.select()
                    }
                  }}
                />
                {response && inventoryTimestamp &&
                  <p style={{
                    marginTop: '4px',
                    marginBottom: '0',
                    textAlign: 'center',
                    backgroundColor: "#121212",
                    border: 'rgba(255, 255, 255, 0.23) 1px solid',
                    color: 'white',
                    fontSize: '0.9rem',
                    // padding: '.25rem .75rem',
                    borderRadius: '0.25rem'
                  }} >
                    {/* Updated<br /> */}
                    {(() => {
                      // const updatedAtUTC = items[0]["UpdatedAt"] * 1000;
                      // const now = new Date();
                      const timeDifference = Date.now() - inventoryTimestamp;
                      const minsDifference = Math.floor(timeDifference / (1000 * 60));
                      const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
                      const daysDifference = Math.floor(hoursDifference / 24);

                      let message;
                      if (daysDifference > 0) {
                        message = `${daysDifference} day${daysDifference !== 1 ? 's' : ''} old`;
                      } else if (hoursDifference > 0) {
                        // if (hoursDifference > 0) {
                        message = `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} old`;
                        // } else {
                        //   message = "Less than an hour ago";
                        // }
                      } else {
                        // if (hoursDifference > 0) {
                        message = `${minsDifference} min${minsDifference !== 1 ? 's' : ''} old`;
                        // } else {
                        //   message = "Less than an hour ago";
                        // }
                      }

                      return message;
                    })()}
                  </p>


                }
                {response && inventoryTimestamp &&
                  <p style={{
                    marginTop: '4px',
                    marginBottom: '0',
                    textAlign: 'center',
                    backgroundColor: "#121212",
                    border: 'rgba(255, 255, 255, 0.23) 1px solid',
                    color: 'white',
                    fontSize: '0.9rem',
                    // padding: '.25rem .75rem',
                    borderRadius: '0.25rem'
                  }}>
                    <Tooltip title={
                      <Typography variant="body" component="div" style={{ textAlign: 'center' }}>
                        Valve's raw data API has a minor issue where some items are misordered if it is NOT your account, this is likely a bug due to hiding items for 10 days after acquisition. If you are logged into the account, the items should be ordered correctly. If not, you can use the same method as steam does, splitting the query into two seperate ones, by clicking this checkbox and providing the second link's data.
                      </Typography>
                    }>
                      <div style={{
                        margin: '2px',
                        textAlign: 'center',
                        backgroundColor: "#121212",
                        color: 'white',
                        fontSize: '0.8rem !important',
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        paddingLeft: '4px',
                        lineHeight: 0
                        // padding: '.25rem .75rem',
                      }}>
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 16 }, padding: 0 }} checked={enableBackup} onChange={(e) => {
                              setEnableBackup(e.target.checked);
                              saveToLocalStorage('enableBackup', e.target.checked);
                            }} />}
                            label={<p style={{ fontSize: '0.7rem', paddingLeft: '4px', lineHeight: '0' }}>Fix item order</p>}
                          />
                        </FormGroup>
                        <HelpOutlineIcon className="iconSVG" sx={{ width: '16px', height: '16px', marginTop: 'auto', marginBottom: 'auto', marginLeft: '-12px' }} fontSize='large' />
                      </div>

                    </Tooltip>

                  </p>
                }
              </div>
              {
                enableBackup &&

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  {/* <a href={``} target="_blank" rel="noopener noreferrer"> */}
                  {/* <input type="button" value="Open Raw Inventory Link in Browser" /> */}
                  <Button variant="contained" onClick={() => window.open(`https://steamcommunity.com/inventory/${id}/730/2?l=english&count=75`, '_blank')} style={{ backgroundColor: '#bb171a', color: 'white', margin: '6px 8px', fontSize: '0.6rem' }}>OPEN BACKUP INVENTORY DATA IN BROWSER</Button>
                  {/* </a> */}
                  <Button variant="contained" onClick={() => window.open(`steam://openurl/https://steamcommunity.com/inventory/${id}/730/2?l=english&count=75`)} style={{ backgroundColor: '#bb171a', color: 'white', margin: '6px 8px', fontSize: '0.6rem' }}>OPEN BACKUP INVENTORY DATA IN STEAM</Button>


                </div>
              }
              {enableBackup &&
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <TextField
                    label="Raw Backup Inventory Data"
                    variant='outlined'
                    value={backupResponse}
                    sx={{ width: '160px' }}
                    onChange={(e) => {
                      localStorage.setItem('backupInventoryTimestamp', Date.now().toString());
                      setBackupInventoryTimestamp(Date.now());
                      localStorage.setItem('backupInventoryData', e.target.value);
                      setBackupResponse(e.target.value)
                    }}
                    onClick={(e) => {
                      if (e?.target?.value != null) {
                        e.target.select()
                      }
                    }}
                  />
                  {backupResponse && backupInventoryTimestamp &&
                    <p style={{
                      marginTop: '4px',
                      marginBottom: '0',
                      textAlign: 'center',
                      backgroundColor: "#121212",
                      border: 'rgba(255, 255, 255, 0.23) 1px solid',
                      color: 'white',
                      fontSize: '0.9rem',
                      // padding: '.25rem .75rem',
                      borderRadius: '0.25rem'
                    }} >
                      {/* Updated<br /> */}
                      {(() => {
                        // const updatedAtUTC = items[0]["UpdatedAt"] * 1000;
                        // const now = new Date();
                        const timeDifference = Date.now() - backupInventoryTimestamp;
                        const minsDifference = Math.floor(timeDifference / (1000 * 60));
                        const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
                        const daysDifference = Math.floor(hoursDifference / 24);

                        let message;
                        if (daysDifference > 0) {
                          message = `${daysDifference} day${daysDifference !== 1 ? 's' : ''} old`;
                        } else if (hoursDifference > 0) {
                          // if (hoursDifference > 0) {
                          message = `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} old`;
                          // } else {
                          //   message = "Less than an hour ago";
                          // }
                        } else {
                          // if (hoursDifference > 0) {
                          message = `${minsDifference} min${minsDifference !== 1 ? 's' : ''} old`;
                          // } else {
                          //   message = "Less than an hour ago";
                          // }
                        }

                        return message;
                      })()}
                    </p>


                  }

                </div>

              }
              {/* </label> */}
              <div>
                <h3 style={{ flex: '1', textAlign: 'center', lineHeight: '1', margin: '0', fontSize: enableBackup ? '0.9rem' : '1rem' }}>{formatNumber(allItemValue / exchangeRate, true)} | {totalItemNum} Items</h3>

                <p style={{ flex: '1', textAlign: 'center', lineHeight: '0.1', fontSize: enableBackup ? '0.7rem' : '0.8rem' }}>1M ago: {formatNumber(allItem1MValue / exchangeRate, true)} | {((allItemValue / allItem1MValue) - 1) >= -0.0009 ? "+" : "-"}{formatNumber(Math.abs(((allItemValue / allItem1MValue)) - 1) * 100, false, 1)}%</p>
                <p style={{ flex: '1', textAlign: 'center', lineHeight: '0.1', fontSize: enableBackup ? '0.7rem' : '0.8rem' }}>6M ago: {formatNumber(allItem6MValue / exchangeRate, true)} | {((allItemValue / allItem6MValue) - 1) >= -0.0009 ? "+" : "-"}{formatNumber(Math.abs(((allItemValue / allItem6MValue)) - 1) * 100, false, 1)}%</p>
                <p style={{ flex: '1', textAlign: 'center', lineHeight: '0.1', fontSize: enableBackup ? '0.7rem' : '0.8rem' }}>1Y ago: {formatNumber(allItem1YValue / exchangeRate, true)} | {((allItemValue / allItem1YValue) - 1) >= -0.0009 ? "+" : "-"}{formatNumber(Math.abs(((allItemValue / allItem1YValue)) - 1) * 100, false, 1)}%</p>
              </div>
              <div>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleOptionsClick} sx={{ fontSize: '1.2rem' }} startIcon={<SettingsIcon sx={{ height: '28px', width: '28px' }} />}>
                  {!enableBackup ? "Options" : ""}
                </Button>

                <div style={{ display: options ? "block" : "none", zIndex: '2', position: 'absolute', backgroundColor: '#121212', padding: '8px', right: '72px' }}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={combineRepeats} onChange={(e) => {
                        setCombineRepeats(e.target.checked);
                        saveToLocalStorage('combineRepeats', e.target.checked);
                      }} />}
                      label="Stack Items"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={showTradehold} onChange={(e) => {
                        setShowTradehold(e.target.checked);
                        saveToLocalStorage('showTradeHold', e.target.checked);
                      }} />}
                      label="Show Tradehold"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={priceLinks} onChange={(e) => {
                        setPriceLinks(e.target.checked);
                        saveToLocalStorage('priceLinks', e.target.checked);
                      }} />}
                      label="Price Links To Inventory"
                    />
                  </FormGroup>
                  <Divider style={{ paddingTop: '4px', paddingBottom: '4px' }} />
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={showCase} onChange={(e) => {
                        setShowCase(e.target.checked);
                        saveToLocalStorage('showCase', e.target.checked);
                      }} />}
                      label="Show Case"
                    />
                  </FormGroup>

                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={showCaseName} onChange={(e) => {
                        setShowCaseName(e.target.checked);
                        saveToLocalStorage('showCaseName', e.target.checked);
                      }} />}
                      label="Show Case Name"
                    />
                  </FormGroup>
                  <Divider style={{ paddingTop: '4px', paddingBottom: '4px' }} />

                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={showUnboxChance} onChange={(e) => {
                        setShowUnboxChance(e.target.checked);
                        saveToLocalStorage('showUnboxChance', e.target.checked);
                      }} />}
                      label="Show Unbox Chance"
                    />
                  </FormGroup>

                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={showStickersApplied} onChange={(e) => {
                        setShowStickersApplied(e.target.checked);
                        saveToLocalStorage('showStickersApplied', e.target.checked);
                      }} />}
                      label="Show Applied Stickers"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={showInvestROI} onChange={(e) => {
                        setShowInvestROI(e.target.checked);
                        saveToLocalStorage('showInvestROI', e.target.checked);
                      }} />}
                      label="Show Investing ROI"
                    />
                  </FormGroup>
                  <Divider style={{ paddingTop: '4px', paddingBottom: '4px' }} />
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={includeFreeItems} onChange={(e) => {
                        setIncludeFreeItems(e.target.checked);
                        saveToLocalStorage('includeFreeItems', e.target.checked);
                      }} />}
                      label="Include Drops"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={onlyUnboxable} onChange={(e) => {
                        setOnlyUnboxable(e.target.checked);
                        saveToLocalStorage('onlyUnboxable', e.target.checked);
                      }} />}
                      label="Only Unboxable Items"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={selectAutoScroll} onChange={(e) => {
                        setSelectAutoScroll(e.target.checked);
                        saveToLocalStorage('selectAutoScroll', e.target.checked);
                      }} />}
                      label="Select Auto Scroll"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={selectInventoryScroll} onChange={(e) => {
                        setSelectInventoryScroll(e.target.checked);
                        saveToLocalStorage('inventoryAutoScroll', e.target.checked);
                      }} />}
                      label="Inventory Auto Scroll"
                    />
                  </FormGroup>
                </div>

              </div>

            </div>
            <Card sx={{ height: '100vh', width: '100%', margin: 'auto' }} id='inventoryCard'>
              <div style={{ height: '100%', width: '100%', margin: 'auto', paddingLeft: '4px', paddingRight: '4px', paddingBottom: '12px' }}>
                {assets.length === 0 && <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'top', alignItems: 'center', height: '100%' }}>
                  <h1>You need to provide your raw steam inventory data to analyse your Unboxing ROI</h1>
                  <Accordion sx={{ width: '70%', border: 'solid 2px rgba(50,50,50,1)' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Why Do I Need This?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>In a recent update, Valve made any new items in your inventory invisible to other users for 10 days. Most sites are requiring a browser extension to retrieve the latest inventory from your client session. Instead of requiring access to your steam account with a browser extension, I ask for you to manually retrieve the data that a browser extension would retrieve. If you cannot see your new items, you are not logged in on your browser or steam client to the account you're attempting to retrieve the inventory for. None of the links here will ask you to login to a Steam account, always log into steam using Valve's exact website <a href='https://steamcommunity.com'>https://steamcommunity.com</a> to avoid scams.</p>
                    </AccordionDetails>

                  </Accordion>
                  <div style={{ display: 'flex', flexDirection: 'row', margin: '8px', padding: '8px' }}>
                    <div style={{ flex: '1', display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'start', border: id == "" ? '1px solid white' : 'none', borderRadius: '8px' }}>
                      <h2 style={{ textAlign: 'center' }}>
                        Step 1: Find your SteamID64
                      </h2>
                      <PermIdentityIcon className="iconSVG" sx={{ width: '92px', height: '92px', marginLeft: 'auto', marginRight: 'auto' }} fontSize='large' />
                      <p></p>
                      <TextField
                        style={{ width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', width: '220px' }}
                        label="Your Steam ID64"
                        variant='outlined'
                        value={id}
                        onChange={(e) => setId(e.target.value)}
                        onClick={(e) => {
                          if (e?.target?.value != null) {
                            e.target.select()
                          }
                        }}
                      />
                      <p style={{ textAlign: 'center' }}>
                        Use any site of your choosing to find your SteamID64, I recommend this one: <a href='https://steamid.io/' target='_blank'>https://steamid.io/</a> Copy the "steamID64" and paste it above (Ctrl + V).
                        <br /><br />
                        Alternatively, if you are logged into steam, you can find your SteamID64 directly in steam, in the top left corner below your account name: <a href='https://store.steampowered.com/account/' target='_blank'>https://store.steampowered.com/account/</a>
                      </p>
                      <img style={{ width: '360px', marginLeft: 'auto', marginRight: 'auto' }} src={window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" ? "http://localhost:8080/assets/steamid64.png" : "/assets/steamid64.png"}></img>


                    </div>
                    <div style={{ flex: '1', maxWidth: '30vw', display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'start', border: response == "" && id != "" ? '1px solid white' : 'none', borderRadius: '8px', padding: '8px' }}>
                      <h2 style={{ textAlign: 'center' }}>
                        Step 2: Retrieve the raw inventory data
                      </h2>
                      <DataObjectIcon className="iconSVG" sx={{ width: '92px', height: '92px', marginLeft: 'auto', marginRight: 'auto' }} fontSize='large' />

                      <p style={{ textAlign: 'center' }}>
                        Using your SteamID64, here is your unique inventory data link: <br /> <a href={`https://steamcommunity.com/inventory/${id}/730/2?l=english&count=1000`} target="_blank" rel="noopener noreferrer">https://steamcommunity.com/inventory/{id}/730/2?l=english&count=1000</a> <br /> After following the link, copy the raw data and paste it below. <br /> (Tip: Ctrl + A and then Ctrl + C, will select all and copy the data). </p>
                      <TextField
                        style={{ width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', }}
                        label="Raw Inventory Data"
                        variant='outlined'
                        value={response}
                        onChange={(e) => {
                          localStorage.setItem('backupInventoryTimestamp', Date.now().toString());
                          setBackupInventoryTimestamp(Date.now());
                          localStorage.setItem('backupInventoryData', '');
                          setBackupResponse('')
                          localStorage.setItem('inventoryTimestamp', Date.now().toString());
                          setInventoryTimestamp(Date.now());
                          localStorage.setItem('inventoryData', e.target.value);
                          setResponse(e.target.value)
                        }}
                        onClick={(e) => {
                          if (e?.target?.value != null) {
                            e.target.select()
                          }
                        }}
                      />
                      <p style={{ textAlign: 'center' }}>Alternatively click this link to retrieve the raw data from your steam app instead: <a href={`steam://openurl/https://steamcommunity.com/inventory/${id}/730/2?l=english&count=1000`} target="_blank" rel="noopener noreferrer">steam://openurl/https://steamcommunity.com/inventory/{id}/730/2?l=english&count=1000</a>
                      </p>
                    </div>
                    <div style={{ flex: '1', display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'start', padding: '8px' }}>
                      <h2 style={{ textAlign: 'center' }}>
                        Step 3: Select your items
                      </h2>
                      <CheckIcon className="iconSVG" sx={{ width: '92px', height: '92px', marginLeft: 'auto', marginRight: 'auto' }} fontSize='large' />

                      <p style={{ textAlign: 'center' }}>
                        Click on the first item you unboxed or want to include in investment stats, then click on the last item you want to include. All items inbetween will be included in your Unboxing and Investing ROI calculations. If you press and hold the Ctrl key while clicking, you can select or deselect individual items.
                      </p>

                      <p style={{ textAlign: 'center' }}>If you cannot see your new items, you are not logged in to Steam on your desired account. Any items that were in the inventory 10 days or more ago should appear without logging in, but anything newer cannot be seen by anyone other than the account holder. None of the links here will ask you to login to a Steam account, always log into steam using Valve's exact website <a href='https://steamcommunity.com' target='_blank'>https://steamcommunity.com</a> to avoid scams.</p>

                    </div>
                  </div>

                </div>
                }
                <div style={{ display: 'flex', width: '100%', marginBottom: '6px' }}>
                  <div style={{ marginRight: 'auto', marginLeft: '6px' }}>
                    <SearchBar />
                  </div>







                  {/* <h3 style={{ margin: 'auto' }}>Cost To Unbox Inventory: {formatNumber(allCollectionValue / exchangeRate, true)}</h3> */}




                  <FormControl style={{ width: '150px', marginTop: '8px', marginRight: '4px' }}>
                    <InputLabel id="demo-simple-select-label">Sort By</InputLabel>
                    <Select
                      value={sortBy}
                      label="Sort By"
                      onChange={(event) => {
                        localStorage.setItem("invSortBy", event.target.value);
                        setSortBy(event.target.value);
                      }}
                    >
                      <MenuItem value="recent">Recent</MenuItem>
                      <MenuItem value="value">Cost</MenuItem>
                      <MenuItem value="rarity">Rarity</MenuItem>
                      <MenuItem value="invest">Invest ROI</MenuItem>
                      <MenuItem value="collection">Collection</MenuItem>
                    </Select>
                  </FormControl>
                  <IconButton aria-label="sortDirection" style={{ WebkitTransform: sortDirection ? "none" : "scaleY(-1)", marginRight: '4px', transform: sortDirection ? "none" : "scaleY(-1)" }} onClick={() => handleSortDirection(!sortDirection)}>
                    <SortIcon></SortIcon>
                  </IconButton>


                  <FormControl style={{ width: '120px', marginTop: '8px', marginRight: 'auto' }}>
                    <InputLabel id="demo-simple-select-label">Invest Type</InputLabel>
                    <Select
                      value={investType}
                      label="Investing ROI"
                      onChange={(event) => {
                        localStorage.setItem('investType', event.target.value);
                        setInvestType(event.target.value);
                      }}
                    >
                      <MenuItem value="1y">1 Year</MenuItem>
                      <MenuItem value="6m">6 Months</MenuItem>
                      <MenuItem value="1m">1 Month</MenuItem>
                    </Select>
                  </FormControl>
                  {response && inventoryTimestamp &&
                  <p style={{
                    // marginTop: '4px',
                    padding: '8px',
                    marginBottom: '0',
                    textAlign: 'center',
                    backgroundColor: "#121212",
                    border: 'rgba(255, 255, 255, 0.23) 1px solid',
                    color: 'white',
                    fontSize: '0.9rem',
                    marginRight: 'auto',
                    marginBottom: 'auto',
                    marginTop: 'auto',
                    // padding: '.25rem .75rem',
                    borderRadius: '0.25rem',
                    color: (Date.now() - inventoryTimestamp) > 1000 * 60 * 60 * 24 ? 'red' : 'white',
                    textDecoration: (Date.now() - inventoryTimestamp) > 1000 * 60 * 60 * 24 ? 'underline' : 'none',
                    cursor: 'pointer'
                    
                  }} onClick={() => {
                    scrollToElement('invInfo');
                  }} >
                    {/* Updated<br /> */}
                    {(() => {
                      // const updatedAtUTC = items[0]["UpdatedAt"] * 1000;
                      // const now = new Date();
                      const timeDifference = Date.now() - inventoryTimestamp;
                      const minsDifference = Math.floor(timeDifference / (1000 * 60));
                      const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
                      const daysDifference = Math.floor(hoursDifference / 24);

                      let message;
                      if (daysDifference > 0) {
                        message = `${daysDifference} day${daysDifference !== 1 ? 's' : ''} old`;

                      } else if (hoursDifference > 0) {
                        // if (hoursDifference > 0) {
                        message = `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} old`;
                        // } else {
                        //   message = "Less than an hour ago";
                        // }
                      } else {
                        // if (hoursDifference > 0) {
                        message = `${minsDifference} min${minsDifference !== 1 ? 's' : ''} old`;
                        // } else {
                        //   message = "Less than an hour ago";
                        // }
                      }

                      return message;
                    })()}
                    
                  </p>


                }
                  <div style={{ display: 'flex', textAlign: 'center', flexDirection: 'row', marginTop: 'auto', marginBottom: 'auto', marginRight: 'auto' }}>
                    <h3>Icon Size:</h3>

                    <Checkbox
                      onClick={handleIconSizeChange}
                      value="small"
                      checked={iconSize === 'small'}
                      disableRipple
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 16 } }}
                    />
                    <Checkbox
                      onClick={handleIconSizeChange}
                      value="medium"
                      checked={iconSize === 'medium'}
                      disableRipple
                      sx={{ marginLeft: '-10px' }}
                    />
                    <Checkbox
                      onClick={handleIconSizeChange}
                      value="large"
                      checked={iconSize === 'large'}
                      disableRipple
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 32 }, marginLeft: '-12px' }}
                    />

                  </div>
                  <ClearSelectionButton />


                </div>
                <div style={{ height: '100%', width: '100%', }}>
                  <AutoSizer>
                    {({ height, width }) => (
                      <RenderGrid
                        height={height}
                        width={width}
                        isMobile={isMobile}
                        showCase={showCase}
                        Cell={Cell}
                      />
                    )}
                  </AutoSizer>
                </div>
              </div>
            </Card>


            <RenderCollectionsAndStats />
            {/* <Table aria-label="simple table" style={{ flex: 2, width: '18vw' }}>
                    <TableBody>
                        {Object.entries(firstItemData?.RarityChances ?? []).map(([name, percent]) => {
                            var convertedRarityName = Object.keys(otherRarityNames).includes(name) ? otherRarityNames[name] : name;
                            return <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                key={name}
                            >
                                <TableCell component="th" scope="row" style={{ color: dataColors[convertedRarityName] }}>
                                    {name.replace('_', '-').replace('Contraband', 'Gloves / Knives')}
                                </TableCell>
                                <TableCell align="right">{convertedRarityName == 'Contraband' ? contraband : convertedRarityName == 'Covert' ? covert : convertedRarityName == 'Classified' ? classified : convertedRarityName == 'Restricted' ? restricted : convertedRarityName == 'Mil_Spec' ? milspec : convertedRarityName == 'Industrial' ? industrial : convertedRarityName == 'Consumer' ? consumer : 0}</TableCell>
                                <TableCell align="right">{convertedRarityName == 'Contraband' ? formatNumber(contrabandCost / exchangeRate, true) : convertedRarityName == 'Covert' ? formatNumber(covertCost / exchangeRate, true) : convertedRarityName == 'Classified' ? formatNumber(classifiedCost / exchangeRate, true) : convertedRarityName == 'Restricted' ? formatNumber(restrictedCost / exchangeRate, true) : convertedRarityName == 'Mil_Spec' ? formatNumber(milspecCost / exchangeRate, true) : convertedRarityName == 'Industrial' ? formatNumber(industrialCost / exchangeRate, true) : convertedRarityName == 'Consumer' ? formatNumber(consumerCost / exchangeRate, true) : 0}</TableCell>
                            </TableRow>
                        })
                        }
                    </TableBody>
                </Table> */}


          </div>
        </RangeSelectionProvider>
      </Box>
    </ThemeProvider>
  );
};

export default InventoryPage;