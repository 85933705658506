import React from 'react';
import { Backdrop, Paper, IconButton, Grid, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Drawer, Select, MenuItem, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { BarChart, Bar, XAxis, YAxis } from 'recharts';
import { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CookieConsent from "react-cookie-consent";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import CssBaseline from '@mui/material/CssBaseline';
import Scrollbars from 'react-custom-scrollbars-2';
import { Card, CardMedia, Chip, FormControl, Icon } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { ToggleButtonGroup, ToggleButton, FormGroup, FormControlLabel, Checkbox, Autocomplete, Accordion, AccordionSummary, TextField, AccordionDetails } from '@mui/material';
import { CaseGridItem } from './CaseGridItem';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { buffLink, csfloatLink, skinportLink, steamLink, tradeitLink } from './allLinks';
import FloatDropChances from './FloatDropChances';
import VideoController from './VideoController';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

function getWears(minFloat, maxFloat) {
    let fullFloatPercents = {};
    for (let wear of wearNames) {
        let floatRange = maxFloat - minFloat;
        let lowerRange = (wearRangeStarts[wear] ?? 0) - minFloat;
        let higherRange = (wearRanges[wear] ?? 0) - minFloat;
        let eFloatStart = lowerRange / floatRange;
        let eFloatEnd = higherRange / floatRange;
        let totalPercent = 0;
        let floatPercents = {};
        for (let wearName of wearNames) {
            let start = wearRangeStartDrops[wearName] ?? 0;
            let end = wearRanges[wearName] ?? 0;
            if (start < eFloatEnd && end > eFloatStart) {
                let realRange = end - start;
                let insideRange;
                let avgRangeFloat;
                if (eFloatStart < start && eFloatEnd > end) {
                    insideRange = end - start;
                    avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
                } else if (eFloatStart > start && eFloatEnd < end) {
                    insideRange = eFloatEnd - eFloatStart;
                    avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
                } else if (eFloatStart < start) {
                    insideRange = eFloatEnd - start;
                    avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
                } else {
                    insideRange = end - eFloatStart;
                    avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
                }
                let percentRange = insideRange / realRange;
                let percent = percentRange * wearDropRates[wearName];
                totalPercent += percent;
                floatPercents[avgRangeFloat.toFixed(4)] = percent;
            }
        }
        let floatPercentsKeys = Object.keys(floatPercents);
        let totalAvgFloat = 0;
        let totalPercents = 0;
        for (let stringPercent of floatPercentsKeys) {
            let percent = floatPercents[stringPercent];
            totalAvgFloat += (percent / totalPercent) * parseFloat(stringPercent);
            totalPercents += percent;
        }
        fullFloatPercents[wear] = (totalPercents);
    }
    return fullFloatPercents;
}

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));


const wearNames = [
    'Factory New',
    'Minimal Wear',
    'Field-Tested',
    'Well-Worn',
    'Battle-Scarred',
];

const wearRanges = {
    'Factory New': 0.07,
    'Minimal Wear': 0.15,
    'Field-Tested': 0.38,
    'Well-Worn': 0.45,
    'Battle-Scarred': 1,
};

const wearRangeStarts = {
    'Factory New': 0,
    'Minimal Wear': 0.07,
    'Field-Tested': 0.15,
    'Well-Worn': 0.38,
    'Battle-Scarred': 0.45,
    'magic': 1, // not used, but needed for calculation
};

const wearDropRates = {
    'Factory New': 0.03,
    'Minimal Wear': 0.24,
    'Field-Tested': 0.33,
    'Well-Worn': 0.24,
    'Battle-Scarred': 0.16,
};

const wearRangeStartDrops = {
    'Factory New': 0,
    'Minimal Wear': 0.08,
    'Field-Tested': 0.16,
    'Well-Worn': 0.39,
    'Battle-Scarred': 0.46,
};

const redRarityNums = { 'Contraband': 223, 'Covert': 187, 'Classified': 158, 'Restricted': 104, 'Mil_Spec': 2, 'Industrial': 94, 'Consumer': 176 };
const greenRarityNums = { 'Contraband': 199, 'Covert': 23, 'Classified': 0, 'Restricted': 50, 'Mil_Spec': 79, 'Industrial': 152, 'Consumer': 195 };
const blueRarityNums = { 'Contraband': 0, 'Covert': 26, 'Classified': 197, 'Restricted': 234, 'Mil_Spec': 228, 'Industrial': 217, 'Consumer': 217 };
const realWearNames = { 'FactoryNew': 'Factory New', 'MinimalWear': 'Minimal Wear', 'FieldTested': 'Field-Tested', 'WellWorn': 'Well-Worn', 'BattleScarred': 'Battle-Scarred' };
const smallWearNames = { 'Factory New': 'FN', 'Minimal Wear': 'MW', 'Field-Tested': 'FT', 'Well-Worn': 'WW', 'Battle-Scarred': 'BS' }



const otherRarityNames = { 'Extraordinary': 'Covert', 'Exotic': 'Classified', 'Remarkable': 'Restricted', 'High_Grade': 'Mil_Spec', 'Superior': 'Classified', 'Master': 'Covert', 'Exceptional': 'Restricted', 'Distinguished': 'Mil_Spec' }


function CharmGradients() {
    // const itemWears = getWears(caseData['Items'][item]['FloatMin'] / 100, caseData['Items'][item]['FloatMax'] / 100);
    // const data = Object.entries(itemWears)
    //     .filter(([key, value]) => value !== 0)
    //     .map(([key, value]) => ({
    //         name: key,
    //         value: itemWears[key]
    //     }));
    const cachedPricingSource = localStorage.getItem('pricingSource');
    const [pricingSource, setPricingSource] = useState(cachedPricingSource || 'Steam');
    const storedCurrency = localStorage.getItem('currency')
    const cachedUSDExchangeRate = localStorage.getItem('usdExchangeRate');
    const [exchangeRate, setExchangeRate] = useState(cachedUSDExchangeRate || 1);
    const [currency, setCurrency] = useState(storedCurrency || 'usd');
    const storedShowListings = (localStorage.getItem('showListings') ?? "true") == "true";
    const [showListings, setShowListings] = useState(storedShowListings ?? true);
    const storedShowInvest = (localStorage.getItem('showInvest') ?? "true") == "true";
    const [showInvest, setShowInvest] = useState(storedShowInvest ?? true);
    const storedShowUnbox = (localStorage.getItem('showUnbox') ?? "true") == "true";
    const [showUnbox, setShowUnbox] = useState(storedShowUnbox ?? true);
    const storedTradeitHideUnavailable = (localStorage.getItem('tradeitHide') ?? "true") == "true";
    const [tradeitHideUnavailable, setTradeitHideUnavailable] = useState(storedTradeitHideUnavailable ?? true);
    const [currencies, setCurrencies] = useState([]);
    const storedShowProfit = (localStorage.getItem('showProfit') ?? "true") == "true";
    const [showProfit, setShowProfit] = useState(storedShowProfit ?? true);
    const [rarity, setRarity] = useState(null);
    const [isMobileLayout, setIsMobileLayout] = useState(false);
    var storedSort = localStorage.getItem('searchSortBy');
    storedSort = storedSort == "invest6m" ? "invest" : storedSort == "invest1m" ? "invest" : storedSort;
    const [sortBy, setSortBy] = useState(storedSort || "unbox");
    const storedInvestType = localStorage.getItem('investType');
    const [investType, setInvestType] = useState(storedInvestType || "1y");
    const [allCharmCollections, setAllCharmCollections] = useState([]);
    const [sortedItemCosts, setSortedItemCosts] = useState([]);
    const [allDefIndexes, setAllDefIndexes] = useState([]);
    const storedDefIndexCharm = localStorage.getItem('defIndexCharm');
    const [defIndex, setDefIndex] = useState(storedDefIndexCharm || 18);
    const videoControllerRef = useRef(null);
    const CACHE_DURATION = 24 * 60 * 60 * 1000; // Cache duration in milliseconds (1 day)


    const handleTradeitHide = (sortTest) => {
        setTradeitHideUnavailable(sortTest);
        localStorage.setItem('tradeitHide', sortTest.toString());
        // setSelectedItem(item);
        // setSelectedRarity(rarity)
    };

    const handleShowUnbox = (sortTest) => {
        setShowUnbox(sortTest);
        localStorage.setItem('showUnbox', sortTest.toString());
        // setSelectedItem(item);
        // setSelectedRarity(rarity)
    };

    const handleShowProfit = (sortTest) => {
        setShowProfit(sortTest);
        localStorage.setItem('showProfit', sortTest.toString());
        // setSelectedItem(item);
        // setSelectedRarity(rarity)
    };

    const handleShowInvest = (sortTest) => {
        setShowInvest(sortTest);
        localStorage.setItem('showInvest', sortTest.toString());
        // setSelectedItem(item);
        // setSelectedRarity(rarity)
    };


    const handleShowListings = (sortTest) => {
        setShowListings(sortTest);
        localStorage.setItem('showListings', sortTest.toString());
        // setSelectedItem(item);
        // setSelectedRarity(rarity)
    };


    const theme = createTheme({
        palette: {
            mode: 'dark',
        },
        typography: {
            fontFamily: 'Barlow',
        }
    });


    useEffect(() => {
        // Initial calculation
        handleResize();

        // Add event listener for resize
        window.addEventListener("resize", handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handlePricingSourceChange = (event, newPricingSource) => {
        localStorage.setItem('pricingSource', newPricingSource.props.value)
        setPricingSource(newPricingSource.props.value);
    };

    useEffect(() => {
        const fetchCurrencies = async () => {
          try {
            const cachedCurrencies = localStorage.getItem('currencies');
            const cachedTimestamp = localStorage.getItem('currenciesTimestamp');
    
            if (
              cachedCurrencies &&
              cachedTimestamp &&
              Date.now() - Number(cachedTimestamp) < CACHE_DURATION
            ) {
              setCurrencies(JSON.parse(cachedCurrencies));
            } else {
              let url = '/currencies.json'
    
              if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
                url = 'https://csroi.com/currencies.json';
              }
    
              const response = await fetch(url);
    
              const data = await response.json();
              const currencyList = Object.keys(data);
              setCurrencies(currencyList);
    
              localStorage.setItem('currencies', JSON.stringify(currencyList));
              localStorage.setItem('currenciesTimestamp', Date.now().toString());
            }
          } catch (error) {
            console.error('Failed to fetch currencies:', error);
          }
        };
    
        const updateExchangeRateToUSD = async () => {
          try {
            const cachedCurrency = localStorage.getItem('currency');
            const cachedUSDExchangeRate = localStorage.getItem('usdExchangeRate');
            const cachedTimestamp = localStorage.getItem('usdExchangeRateTimestamp');
            // const cachedAllCases = localStorage.getItem('allCases');
            // const cachedAllLowCosts = localStorage.getItem('allLowCosts');
    
            if (
              cachedCurrency &&
              currency == cachedCurrency &&
              cachedUSDExchangeRate &&
              cachedTimestamp &&
              Date.now() - Number(cachedTimestamp) < CACHE_DURATION
            ) {
              setCurrency(cachedCurrency);
              // setPricingSource('USD');
              setExchangeRate(cachedUSDExchangeRate);
              // setAllCases(JSON.parse(cachedAllCases));
              // setAllLowCosts(JSON.parse(cachedAllLowCosts));
            } else {
              const response = await fetch(
                `https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/${currency.toLowerCase()}.json`
              );
              const data = await response.json();
              const usdExchangeRate = data[currency.toLowerCase()]['usd'];
              // console.log(data);
    
              console.log(currency);
              console.log(usdExchangeRate);
    
              setCurrency(currency);
              // setPricingSource('USD');
              setExchangeRate(usdExchangeRate);
    
              localStorage.setItem('currency', currency);
              localStorage.setItem('usdExchangeRate', usdExchangeRate);
              localStorage.setItem('usdExchangeRateTimestamp', Date.now().toString());
    
              //Piggybacking data for all cases to update daily:
              // let caseurl = '/allCases.json'
    
              // if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
              //   caseurl = 'http://localhost:8080/allCases.json';
              // }
    
              // const responseAllCases = await fetch(caseurl);
              // const textAllCases = await responseAllCases.text();
    
              // // const processedText = `[${text.slice(0, -3)}]`;
              // const processedDataAllCases = JSON.parse(textAllCases);
    
              //Piggybacking data for all cases to update daily:
              // let costUrl = '/allLowCosts.json'
    
              // if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
              //   costUrl = 'http://localhost:8080/allLowCosts.json';
              // }
    
              // const responseLowCosts = await fetch(costUrl);
              // const textLowCosts = await responseLowCosts.text();
    
              // const processedText = `[${text.slice(0, -3)}]`;
              // const processedDataLowCosts = JSON.parse(textLowCosts);
    
              // setAllCases(processedDataAllCases);
              // setAllLowCosts(processedDataLowCosts);
              // console.log(textAllCases);
              // console.log(textLowCosts);
              // localStorage.setItem('allCases', textAllCases);
              // localStorage.setItem('allLowCosts', textLowCosts);
            }
          } catch (error) {
            console.error('Failed to update exchange rate to USD:', error);
          }
        };
    
        fetchCurrencies();
        updateExchangeRateToUSD();
      }, [currency]);

    useEffect(() => {
        // Fetch data from the API using the collectionId
        const fetchData = async () => {

            try {
                let charmDataUrl = `videoTimestamps/charms/allCharms.json`;

                if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
                    charmDataUrl = `http://localhost:8080/videoTimestamps/charms/allCharms.json`;
                }

                const charmResponse = await fetch(charmDataUrl);

                const charmData = await charmResponse.json();

                const allCharmCollectionIds = charmData["collections"];
                const allDefIndexes = charmData["defIndexes"];
                setAllDefIndexes(allDefIndexes);

                var allItems = [];

                let rarityOrder = [];


                var allCharmCollections = [];
                for (let collection of allCharmCollectionIds) {
                    let url = `/case/${pricingSource.toLowerCase()}/${collection}/data.json`;

                    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
                        url = `http://localhost:8080/case/${pricingSource.toLowerCase()}/${collection}/data.json`;
                    }

                    const response = await fetch(url);
                    const data = await response.json();
                    let sortedData = Object.entries(data["ItemCosts"]);
                    // Extract rarity order from the first collection
                    if (rarityOrder.length === 0) {
                        rarityOrder = sortedData.map(([rarity]) => rarity);
                    }

                    sortedData = sortedData.map(([rarity, items]) => {
                        let sortedItems = Object.entries(items).map(([name, values]) => {
                            const caseDataIndex = charmData["collections"].indexOf(collection);
                            const ROI = data?.["ItemROIs"]?.[rarity]?.[name]?.["totalItemValue"]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0;
                            return { name, ROI, caseDataIndex, rarity, ...values };
                        });

                        if (sortBy === "invest") {
                            sortedItems.sort((a, b) => a.ROI - b.ROI);
                        }

                        if (sortBy === "cost") {
                            sortedItems.sort((a, b) => a.totalItemValue - b.totalItemValue);
                        }

                        return sortedItems;
                    });

                    allCharmCollections.push(data);
                    allItems = allItems.concat(...sortedData);
                }

                // Sort allItems by the custom rarity order and then by cost
                allItems.sort((a, b) => {
                    const rarityComparison = rarityOrder.indexOf(a.rarity) - rarityOrder.indexOf(b.rarity);
                    if (rarityComparison !== 0) {
                        return rarityComparison;
                    }
                    return b.totalItemValue - a.totalItemValue;
                });




                setSortedItemCosts(allItems);
                setAllCharmCollections(allCharmCollections);
            } catch (error) {
                console.error("Error fetching data:", error);
            }


        };

        fetchData();
    }, [pricingSource]);



    const handleResize = () => {
        // Calculate aspect ratio 
        const glanceInfoSize = window.innerWidth * 2 / 11

        // Define the threshold value for aspect ratio to differentiate between mobile and desktop
        const glanceInfoThreshold = 215; // Adjust this value based on your needs

        // Set the state based on the aspect ratio
        setIsMobileLayout(glanceInfoSize < glanceInfoThreshold);
    };

    const CustomizedLabel = (props) => {
        const { x, y, fill, value } = props;
        return (
            isMobileLayout ?
                <text x={x + 32} y={y - 10} textAnchor="middle" fontSize={16} dominantBaseline="middle" fill={"white"}>{(value * 100).toFixed(2)}%</text> :
                <text x={x + 66} y={y - 10} textAnchor="middle" fontSize={16} dominantBaseline="middle" fill={"white"}>{(value * 100).toFixed(2)}%</text>
        );
    };

    function formatNumber(number, isCurrency, maximumFractionDigits = 3) {
        // Use the user's locale
        const userLocale = navigator.language || 'en-US';

        // Create Intl.NumberFormat with user's locale
        if (isCurrency) {
            const formatter = new Intl.NumberFormat(userLocale, {
                //   minimumFractionDigits: 1,
                style: "currency",
                currency: currency,
                maximumFractionDigits: number > 1000 ? 0 : number > 100 ? maximumFractionDigits > 1 ? 1 : maximumFractionDigits : number > 0.01 ? maximumFractionDigits > 2 ? 2 : maximumFractionDigits : maximumFractionDigits > 3 ? 3 : maximumFractionDigits,
            });
            return formatter.format(number);
        } else {
            const formatter = new Intl.NumberFormat(userLocale, {
                //   minimumFractionDigits: 1,
                maximumFractionDigits: number > 1000 ? 0 : number > 100 ? maximumFractionDigits > 1 ? 1 : maximumFractionDigits : number > 0.01 ? maximumFractionDigits > 2 ? 2 : maximumFractionDigits : maximumFractionDigits > 3 ? 3 : maximumFractionDigits,

            });
            return formatter.format(number);
        }

        // Format the number

    }

    const [selectedItem, setSelectedItem] = useState("Charm | Die-cast AK");
    const [selectedRarity, setSelectedRarity] = useState("Remarkable");

    const handleItemClick = (item, rarity) => {
        setSelectedItem(item);
        setSelectedRarity(rarity);
        var defIndex = allDefIndexes[item];
        localStorage.setItem('defIndexCharm', defIndex);
        setDefIndex(defIndex);
    };

    useEffect(() => {
        if (videoControllerRef.current) {
            videoControllerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [defIndex]);

    const handlePaperClick = (event) => {
        event.stopPropagation();
    };

    return (
        <ThemeProvider theme={theme}>
            <CookieConsent
                location="bottom"
                buttonText="I understand"
                cookieName="garequest"
                style={{ background: "#2B373B", zIndex: 99999 }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
            >
                This website uses cookies for anonymous Google Analytics. By continuing to use this website, you consent to the use of cookies.
            </CookieConsent>
            <Helmet>
                {/* Page Title */}
                <title>Charm Colors - Match CS2 Charms to Your Skins | CSROI</title>

                {/* Meta Description */}
                <meta
                    name="description"
                    content="Find the perfect charm color and pattern to match your CS2 skin! Use the interactive color picker, gradient slider, and video timeline to preview charm colors in real time. Generate inspect links to try your desired charm in-game."
                />

                {/* Keywords */}
                <meta name="keywords" content="CS2, charm colors, charm patterns, CS charm, pattern number, CS2 skins, color picker, charm gradient, CS2 inspect link" />

                {/* Open Graph (Facebook) */}
                <meta property="og:title" content="Charm Colors - Find the Best CS2 Charm Patterns | CSROI" />
                <meta property="og:description" content="Explore and match CS2 charm colors to your skins. Use the color picker, gradient slider, and video preview to find the best charm pattern for your setup!" />
                <meta property="og:image" content="https://csroi.com/logo512.png" />
                <meta property="og:url" content="https://csroi.com/#/charmcolors" />

                {/* Twitter Cards */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Charm Colors - Find the Best CS2 Charm Patterns" />
                <meta name="twitter:description" content="Match CS2 charm colors to your skins with our interactive tool. Preview patterns, use the gradient slider, and generate inspect links!" />
                <meta name="twitter:image" content="https://csroi.com/logo512.png" />

                {/* Canonical URL */}
                <link rel="canonical" href="https://csroi.com/#/charmcolors" />

                {/* Schema Markup for SEO */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "Charm Colors - Match CS2 Charms to Skins",
                        "description": "Find the best charm color and pattern for your CS2 skins. Use our interactive color picker, gradient slider, and video preview to explore charm patterns and generate inspect links.",
                        "url": "https://csroi.com/#/charmcolors",
                        "image": "https://csroi.com/logo512.png",
                        "publisher": {
                            "@type": "Organization",
                            "name": "CSROI",
                            "logo": {
                                "@type": "ImageObject",
                                "url": "https://csroi.com/logo512.png"
                            }
                        }
                    })}
                </script>
            </Helmet>
            <Box sx={{ display: 'flex', padding: 0, transition: "width 5s infinite alternate, height 5s infinite alternate", }}>
                <CssBaseline />
                <Drawer
                    sx={{
                        display: isMobileLayout ? 'none' : 'initial',
                        width: drawerWidth,
                        // flexShrink: 0,

                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="permanent"
                    anchor="left"
                // open={openDrawer}
                >
                    <Scrollbars
                        autoHide
                        // autoHideTimeout={1000}
                        // autoHideDuration={200}
                        style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
                        renderView={props => <div {...props} style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }} />}
                        renderThumbVertical={({ style }) => (
                            <div style={{ ...style, backgroundColor: 'lightgray', borderRadius: '4px' }} />
                        )}
                    >
                        <DrawerHeader sx={{ paddingTop: '12px', paddingBottom: '12px' }}>
                            <Link to='/' style={{ textDecoration: 'none', color: 'inherit', width: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: 'auto', marginBottom: '6px', marginTop: '6px', cursor: 'pointer' }} >
                                    <img src="/csroi.png" class="p-2" style={{ maxWidth: '280px', width: '100%', height: "auto", objectFit: 'contain', margin: 'auto' }} />

                                </div>
                            </Link>


                        </DrawerHeader>
                        <Divider />
                        {/* <h5 style={{ textAlign: 'center', maxHeight: '180px', padding: '4px' }}>
              Welcome to the all new CSROI.com!<br /><br />
              Featuring a new overhauled React UI, full currency conversion, a new Investing ROI, upgraded simulator, among many more!<br /><br />
              Any feedback is appreciated <a href="https://twitter.com/BrongoCS" target='_blank'>@BrongoCS</a>
            </h5> */}
                        {/* <Divider /> */}
                        {/* <iframe width={drawerWidth} height="180" src="https://www.youtube.com/embed/mMCqwpVC2Zc?si=1DY9BEJKcLe66qy3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;" allowFullScreen ></iframe> */}
                        <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer' }}>
                                <p>
                                    Rankings

                                </p>
                            </div>
                        </Link>
                        <Divider />
                        <Link to='/armory' style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer' }}>
                                <p style={{ lineHeight: '0.9' }}>
                                    Armory
                                    <br />
                                    <span style={{ fontSize: '0.7rem' }}>Find the best way to spend YOUR Armory Stars</span>
                                </p>
                                {/* <Chip label="NEW" variant="outlined" size='small' sx={{ color: 'white', border: '1px white solid', fontSize: '0.8rem', height: '24px', width: '48px', padding: '0' }} /> */}

                            </div>
                        </Link>
                        <Divider />
                        <Link to='/deals' style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer' }}>
                                <p style={{ lineHeight: '0.9' }}>
                                    Deals
                                    <br />
                                    <span style={{ fontSize: '0.7rem' }}>Resell Skins to get up to 1.5x Steam Wallet Balance!</span>
                                </p>
                                <Chip label="NEW" variant="outlined" size='small' sx={{ color: 'white', border: '1px white solid', fontSize: '0.8rem', height: '24px', width: '48px', padding: '0' }} />
                            </div>
                        </Link>
                        <Divider />
                        <Link to='/inventory' style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer'}}>
                                <p style={{ lineHeight: '0.9' }}>
                                    Inventory
                                    <br />
                                    <span style={{ fontSize: '0.7rem', }}>Find YOUR Unboxing and Investing ROI!</span>
                                </p>
                                {/* <Chip label="NEW" variant="outlined" size='small' sx={{ color: 'white', border: '1px white solid', fontSize: '0.8rem', height: '24px', width: '48px', padding: '0' }} /> */}
                            </div>
                        </Link>
                        <Divider/>
                        <Link to='/charmcolors' style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.12)'  }}>
                                <p style={{ lineHeight: '0.9' }}>
                                    Charm Colors
                                    <br />
                                    <span style={{ fontSize: '0.7rem' }}>Find the color and pattern of charm to match your skin!</span>
                                </p>
                                {/* <Chip label="NEW" variant="outlined" size='small' sx={{ color: 'white', border: '1px white solid', fontSize: '0.8rem', height: '24px', width: '48px', padding: '0' }} /> */}

                            </div>
                        </Link>

                        <Divider  style={{ borderWidth: '2px' }}  />


                        {/* <p style={{textAlign: 'center', margin: '0'}}> */}
                        <div style={{ flex: '1' }}></div>


                        <Divider />

                        {/* {(!isMobileLayout || openSettings) && <div style={{ display: 'flex', marginTop: '8px', marginBottom: '8px' }}> */}

                        <FormControl sx={{ fontFamily: "'Barlow', 'Roboto', sans-serif", padding: '0 12px 8px 12px', marginTop: '12px', width: '100%' }}>
                            <InputLabel id="pricing-source-label" sx={{ paddingLeft: '14px' }}>Pricing Source</InputLabel>
                            <Select
                                //  renderInput={(params) => <TextField {...params} label="Pricing Source" />}

                                labelId="pricing-source-label"
                                id="pricing-source"
                                value={pricingSource}
                                label="Pricing Source"
                                onChange={handlePricingSourceChange}

                            >
                                <MenuItem value="Steam">Steam</MenuItem>
                                <MenuItem value="Buff">
                                    <div>
                                        Buff.163 / UUPin<Tooltip title="Buff.163 prices are preferred, but UUPin prices are used when Buff.163 prices are unavailable (e.g., containers) or when there is a significant price difference." sx={{ margin: 'auto' }}>

                                            <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '4px', marginBottom: '-4px' }} fontSize='large' />
                                        </Tooltip>
                                    </div>
                                </MenuItem>
                                <MenuItem value="CSFloat">CSFloat</MenuItem>
                                <MenuItem value="Skinport">Skinport</MenuItem>
                                <MenuItem value="Tradeit">
                                    <div>
                                        Tradeit.gg
                                        <Tooltip title="Tradeit.gg pricing reflects the cost to withdraw containers and trade back unboxed items. Estimated prices, marked in grey, are used when specific item prices are unavailable." sx={{ margin: 'auto' }}>

                                            <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '4px', marginBottom: '-4px' }} fontSize='large' />
                                        </Tooltip></div>
                                </MenuItem>
                                {/* Add more pricing source options if needed */}
                            </Select>
                        </FormControl>
                        {
                            pricingSource === "Tradeit" &&
                            <FormGroup sx={{ padding: '0 8px 12px 16px', fontSize: '0.8rem' }}>
                                <FormControlLabel sx={{ fontSize: '0.8rem' }} control={<Checkbox checked={tradeitHideUnavailable} onClick={() => handleTradeitHide(!tradeitHideUnavailable)} />} label="Show Unavailable" />
                            </FormGroup>
                        }
                        <Autocomplete
                            value={currency}
                            onChange={(event, newValue) => setCurrency(newValue)}
                            disableClearable
                            options={currencies}
                            getOptionLabel={(option) => option.toUpperCase()}
                            sx={{ fontFamily: "'Barlow', 'Roboto', sans-serif", padding: '0 12px 12px 12px' }}
                            renderInput={(params) => <TextField {...params} label="Currency" />}
                        />

                        {/* </div>} */}
                        {/* <Divider />
                        {!isMobileLayout && items.length > 0 &&
                            <p style={{
                                margin: '12px',
                                textAlign: 'center',
                                backgroundColor: "#121212",
                                border: 'rgba(255, 255, 255, 0.23) 1px solid',
                                color: 'white',
                                padding: '.25rem .75rem',
                                borderRadius: '0.25rem'
                            }} >
                                Updated<br />
                                {(() => {
                                    const updatedAtUTC = items[0]["UpdatedAt"] * 1000;
                                    const now = new Date();
                                    const timeDifference = now.getTime() - updatedAtUTC;
                                    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
                                    const daysDifference = Math.floor(hoursDifference / 24);

                                    let message;
                                    if (daysDifference > 0) {
                                        message = `${daysDifference} day${daysDifference !== 1 ? 's' : ''} ago`;
                                    } else {
                                        // if (hoursDifference > 0) {
                                        message = `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
                                        // } else {
                                        //   message = "Less than an hour ago";
                                        // }
                                    }

                                    return message;
                                })()}
                            </p>


                        } */}

                        <Divider />
                        <div style={{ margin: '12px', }}>
                            <p style={{

                                textAlign: 'center',
                                backgroundColor: "#121212",
                                border: 'rgba(255, 255, 255, 0.23) 1px solid',
                                color: 'white',
                                padding: '.25rem .75rem',
                                borderRadius: '0.25rem', textAlign: "center", margin: "auto", color: "white",
                            }}>A Project By Brongo: <br />
                                <div style={{ display: 'flex' }}>
                                    <a href="https://twitter.com/BrongoCS" target="_blank" style={{ flex: 1 }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 1200 1227" fill="none">
                                        <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" fill="white"></path>
                                    </svg></a>
                                    <a href="https://www.youtube.com/@BrongoCS" target="_blank" style={{ flex: 1 }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 576 512" fill="none"><path fill="white" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path></svg></a>
                                    <a href="https://steamcommunity.com/tradeoffer/new/?partner=83942777&amp;token=Z_ZUTuBY" style={{ flex: 1 }}><img src="/assets/steamlogo.png" style={{ width: '20px', height: '20px' }} /></a>
                                </div>
                            </p>
                        </div>
                        <Divider />
                        <Accordion sx={{ background: '#121212', padding: '4px 16px 4px 16px', margin: 0 }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                key="Float Drop Chances"
                                sx={{ padding: '0', backgroundColor: '#121212' }}
                            >
                                <Typography>Advanced Settings</Typography>

                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: '0', backgroundColor: '#121212' }}>
                                <FormControlLabel control={<Checkbox onClick={() => handleShowUnbox(!showUnbox)} checked={showUnbox} />} label="Show Unboxing ROI" />
                                <FormControlLabel control={<Checkbox onClick={() => handleShowProfit(!showProfit)} checked={showProfit} />} label="Show Profit Chance" />
                                <FormControlLabel control={<Checkbox onClick={() => handleShowInvest(!showInvest)} checked={showInvest} />} label="Show Investing ROI" />
                                <FormControlLabel control={<Checkbox onClick={() => handleShowListings(!showListings)} checked={showListings} />} label="Show Listings" />
                                {/* <FloatDropChances caseData={caseData} exchangeRate={exchangeRate} currency={currency} isMobile={isMobileLayout}/> */}
                            </AccordionDetails>


                        </Accordion>
                        <Divider />
                        <Divider />

                        <div style={{ margin: '12px', display: 'flex', flexDirection: 'row' }} >
                            <Link to="/faq" style={{ textDecoration: 'none', marginLeft: 'auto', marginRight: 'auto' }}>
                                <p style={{

                                    textAlign: 'center',
                                    backgroundColor: "#121212",
                                    border: 'rgba(255, 255, 255, 0.23) 1px solid',
                                    color: 'white',
                                    padding: '.25rem .75rem',
                                    borderRadius: '0.25rem', textAlign: "center", margin: "auto", color: "white", width: '70px', cursor: "pointer",

                                }}>FAQ
                                </p>
                            </Link>
                            <Link to="/changelog" style={{ textDecoration: 'none', marginLeft: 'auto', marginRight: 'auto' }}>
                                <p style={{

                                    textAlign: 'center',
                                    backgroundColor: "#121212",
                                    border: 'rgba(255, 255, 255, 0.23) 1px solid',
                                    color: 'white',
                                    padding: '.25rem .75rem',
                                    borderRadius: '0.25rem', textAlign: "center", margin: "auto", color: "white", width: '120px', cursor: "pointer",
                                }}>Change Log
                                </p>
                            </Link>
                        </div>


                        <Divider />
                        {/* <h5 style={{ textAlign: 'center', maxHeight: '180px', padding: '4px' }}>
              All Key Costs for third-party marketplaces are now automatically adjusted to reflect an easily achievable cost when converting to Steam Market balance.<br/><br/>
              <a href='/#/deals'>Find the best deals to get Steam Market balance here!</a>
            </h5> */}
                        {/* <h5 style={{ textAlign: 'center', maxHeight: '180px', padding: '4px' }}>
              Buff.163 has removed all containers from their site. They will continue to be tracked for historical purposes. All container prices using Buff.163 pricing from the 19th of May onwards are now instead using 80% of the Steam Market cost, as most users can convert Buff.163 balance to Steam Market balance at that rate or better.
            </h5> */}
                        {/* <Divider /> */}
                        {/* <iframe width={drawerWidth} height="180" src="https://www.youtube.com/embed/mMCqwpVC2Zc?si=1DY9BEJKcLe66qy3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;" allowFullScreen ></iframe> */}
                        <Divider />
                    </Scrollbars>
                </Drawer>
                <Box
                    component="main"
                    sx={{

                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Paper sx={{ p: 1, position: 'relative' }} >
                        <div>
                            <Link to="/">
                                <div style={{ display: isMobileLayout ? 'flex' : 'none', flexDirection: 'column', justifyContent: 'center', margin: 'auto', marginBottom: '6px', pointer: 'cursor' }}>
                                    <img src="/csroi.png" class="p-2" style={{ maxWidth: '280px', width: '100%', height: "auto", objectFit: 'contain', margin: 'auto' }} />

                                </div>
                            </Link>
                            <div ref={videoControllerRef}>
                                <VideoController defIndex={defIndex} rarity={selectedRarity} pricingSource={pricingSource} item={selectedItem} isMobile={isMobileLayout} />
                            </div>
                            <Grid container direction="row" spacing={{}} columns={{ xs: 8, sm: 12, md: 20 }} sx={{ flex: 6, justifyContent: isMobileLayout ? 'center' : 'left' }}>

                                {sortedItemCosts.map(item => (
                                    <Grid item xs={2} sm={4} md={4} key={item.name} className="grid-item" onClick={() => handleItemClick(item.name, item.rarity)} sx={{
                                        cursor: 'pointer',
                                        position: 'relative', transition: 'box-shadow 0.3s',
                                        '&:hover': {
                                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.6)'
                                        }, paddingLeft: '12px', padding: isMobileLayout ? '6px' : '12px', minWidth: isMobileLayout ? '150px' : '280px'
                                    }}>
                                        <Card style={{
                                            background: `linear-gradient(145deg, RGB(${redRarityNums[Object.keys(otherRarityNames).includes(item.rarity) ? otherRarityNames[item.rarity] : item.rarity]}, ${greenRarityNums[Object.keys(otherRarityNames).includes(item.rarity) ? otherRarityNames[item.rarity] : item.rarity]}, ${blueRarityNums[Object.keys(otherRarityNames).includes(item.rarity) ? otherRarityNames[item.rarity] : item.rarity]}) 0%, black 100%)`, paddingLeft: 0
                                        }}>
                                            <CardMedia
                                                sx={{ height: 140, backgroundSize: "contain", }}
                                                image={allCharmCollections[item.caseDataIndex]["Items"][item.name]["ImageUrl"]}
                                            />
                                            <div style={{ position: 'absolute', top: isMobileLayout ? '0' : '9px', left: isMobileLayout ? '4px' : '12px', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2, fontSize: isMobileLayout ? '16px' : '19px' }}>
                                                <span>1 in {formatNumber(1 / (allCharmCollections[item.caseDataIndex]["PercentReceive"][item.rarity] / Object.keys(allCharmCollections[item.caseDataIndex]["ItemCosts"][item.rarity]).length))}</span>
                                            </div>
                                        </Card>
                                        <div style={{ display: 'flex', flex: '1', height: '30%', marginTop: isMobileLayout ? '-16px' : '0', flexDirection: isMobileLayout ? 'column-reverse' : 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div>
                                                <h4 style={{ lineHeight: 1.2, margin: '0', marginTop: isMobileLayout ? '0.4rem' : '0.8rem', textAlign: isMobileLayout ? 'center' : 'left' }}>{item.name.split(' | ')[0]}</h4>
                                                <h3 style={{ lineHeight: 1.2, margin: '0', textAlign: isMobileLayout ? 'center' : 'left', maxWidth: '230px', maxHeight: '2lh', overflow: 'clip' }}>{item.name?.split(' | ')?.[1]?.split(' (')?.[0]}</h3>
                                                {item.name?.split(' | ')?.[1]?.split(' (').length > 1 && <h4 style={{ lineHeight: 1.2, margin: '0', textAlign: isMobileLayout ? 'center' : 'left', marginBottom: '4px' }}>{"(" + item.name?.split(' | ')?.[1]?.split(' (')?.[1]}</h4>}
                                            </div>
                                            <div style={{
                                                marginTop: 'auto', justifyContent: 'center', display: 'flex', flexDirection: 'column', marginBottom: 'auto', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2, fontSize: isMobileLayout ? '16px' : '22px', color:
                                                    allCharmCollections[item.caseDataIndex]?.ISTradeit ? (item.rarity === "Contraband" ?
                                                        (typeof allCharmCollections[item.caseDataIndex]?.KnifeEstimates?.[item.name] === "object" ?
                                                            (Object.entries(allCharmCollections[item.caseDataIndex]?.KnifeEstimates?.[item.name]).filter(([key, value]) => value === true && key.includes("StatTrak")).length * 0.1 +
                                                                Object.entries(allCharmCollections[item.caseDataIndex]?.KnifeEstimates?.[item.name]).filter(([key, value]) => value === true && !key.includes("StatTrak")).length <
                                                                Object.entries(allCharmCollections[item.caseDataIndex]?.KnifeEstimates?.[item.name]).filter(([key, value]) => value === false && key.includes("StatTrak")).length * 0.1 +
                                                                Object.entries(allCharmCollections[item.caseDataIndex]?.KnifeEstimates?.[item.name]).filter(([key, value]) => value === false && !key.includes("StatTrak")).length) :
                                                            (allCharmCollections[item.caseDataIndex]?.KnifeEstimates?.[item.name] === false)
                                                        ) ? "white" : "rgb(131 131 131)" :
                                                        (typeof allCharmCollections[item.caseDataIndex]?.ItemEstimates?.[item.rarity]?.[item.name] === "object" ?
                                                            (Object.entries(allCharmCollections[item.caseDataIndex]?.ItemEstimates?.[item.rarity]?.[item.name]).filter(([key, value]) => value === true && key.includes("StatTrak")).length * 0.1 +
                                                                Object.entries(allCharmCollections[item.caseDataIndex]?.ItemEstimates?.[item.rarity]?.[item.name]).filter(([key, value]) => value === true && !key.includes("StatTrak")).length <
                                                                Object.entries(allCharmCollections[item.caseDataIndex]?.ItemEstimates?.[item.rarity]?.[item.name]).filter(([key, value]) => value === false && key.includes("StatTrak")).length * 0.1 +
                                                                Object.entries(allCharmCollections[item.caseDataIndex]?.ItemEstimates?.[item.rarity]?.[item.name]).filter(([key, value]) => value === false && !key.includes("StatTrak")).length) :
                                                            (allCharmCollections[item.caseDataIndex]?.ItemEstimates?.[item.rarity]?.[item.name] === false)
                                                        ) ? "white" : "rgb(131 131 131)"
                                                    ) : "white"
                                            }}>
                                                {!isMobileLayout && <div style={{ display: 'flex', fontSize: isMobileLayout ? '12px' : '14px', lineHeight: '0.4', marginTop: 'auto', marginBottom: 'auto', padding: '4px', flexDirection: 'row', justifyContent: "center", alignItems: 'center', marginBottom: '-12px', color: item.ROI > -0.01 ? "green" : "rgb(187, 23, 26)" }}>{item.ROI > -0.01 ? <TrendingUpIcon style={{ width: isMobileLayout ? "18px" : '22px', height: isMobileLayout ? "14px" : "17px" }} /> : <TrendingDownIcon style={{ width: isMobileLayout ? "18px" : '22px', height: isMobileLayout ? "14px" : "17px" }} />}{(item?.ROI ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(Math.round(item.ROI)), false)}%</div>}
                                                <span style={{ margin: "auto" }}>{formatNumber(allCharmCollections[item.caseDataIndex]["ItemCosts"][item.rarity][item.name]["totalItemValue"] / exchangeRate, true)}</span>
                                                {isMobileLayout && <div style={{ display: 'flex', fontSize: isMobileLayout ? '12px' : '14px', lineHeight: '0.4', marginTop: 'auto', marginBottom: 'auto', padding: '4px', flexDirection: 'row', justifyContent: "center", alignItems: 'center', marginTop: '-10px' }}>{item.ROI > -0.01 ? <TrendingUpIcon style={{ width: isMobileLayout ? "18px" : '22px', height: isMobileLayout ? "14px" : "17px" }} /> : <TrendingDownIcon style={{ width: isMobileLayout ? "18px" : '22px', height: isMobileLayout ? "14px" : "17px" }} />}{(item?.ROI ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(Math.round(item.ROI)), false)}%</div>}
                                            </div>
                                        </div>
                                    </Grid>
                                ))}

                            </Grid>

                        </div>
                        <div style={{ height: '100px' }}></div>
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>


    );
}

export default CharmGradients;