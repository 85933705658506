import React from 'react';
import { Link } from 'react-router-dom';


function FAQ() {

    return (
        <div>
            <Link to='/' style={{ margin: 'auto', display: 'flex' }}><img src="/csroi.png" class="p-2" style={{ maxWidth: '320px', width: '100%', height: "auto", objectFit: 'contain', margin: 'auto' }} /></Link>
            <div style={{
                color: 'white',
                textAlign: 'center',
                paddingTop: '2rem',
                paddingLeft: '5rem',
                paddingRight: '5rem',
            }}>

                <h1><u>FAQ</u></h1>
                <p> If you'd like a quick explanation of any data, hover over most data points for a small tooltip explanation. Each Accordion within a collection's page also has a brief explanation if you hover over the ? icon.</p>
                <h2>Why are keys and armory stars cheaper on third party marketplaces?</h2>
                <br />

                Steam does not offer any cashout system, while third party marketplaces do. This leads to third party marketplace prices being significantly lower than Steam prices, as the sellers are willing to sell for less to receive cash.
                This also means that if you have balance on a third party marketplace, you can convert it steam balance by buying <a href="https://csroi.com/#/deals">great deals</a> on that marketplace, then sell on steam for up to 1.5x steam balance.
                Due to this, to accurately give an Unboxing ROI based on a third party marketplace's pricing an "easily achieveable" ratio of each third party marketplace to steam (after steam fees) is applied to key and armory star costs.

                <br />
                <br />
                <h2>Why are my keys cheaper or more expensive than listed in my currency?</h2>
                <br />

                While CSROI does offer many currencies, all data is based on USD and converted to your currency using the current exchange rate, which may not be accurate to the cost within CS2 for any in game purchases because Valve does not update in game store prices as regularly.

                <br />
                <br />
                <h2>How are doppler phase and gem chances calculated?</h2>
                <br />

                CSFloat offers a database service, where any skins data previously seen by CSFloat's extension is stored. By searching their database for different doppler phase types, you can compare their relative supply against each other and other knife skins in a case.
                At the bottom of the FAQ you can find the raw data I found and used to predict the chances of receiving each doppler phase and gem. This data is not perfect, as it is based on the data CSFloat has seen, and hence may not be completely accurate to the actual drop rates in game.
                With said data, CSROI predicts that for doppler knives in most cases, each phase has a 20% chance, a 9% chance for a ruby, a 9% chance for a sapphire and a 2% chance for a black pearl. For gamma doppler knives CSROI predicts that there is a 23% chance for each phase, and an 8% chance for an emerald.
                Interestingly though, as <a href="https://x.com/TDM_Jesus">TDM_Heyzeus</a> has brought to my attention, the original 3 chroma cases (Chroma, Chroma 2 and Chroma 3) drop the phases of their doppler skins much more often than other cases. The data found below seems to imply that you receive a doppler phase around 80% as often as any other knife in the case, meaning you are roughly 3.2x more likely to receive a doppler phase than another knife finish in the case.
                These cases still retain a 9% chance for a sapphire, a 9% chance for a ruby and a 2% chance for a black pearl, but the chance for a phase is 80% instead of 20%. This seems to be an error on Valve's part when they implemented the Chroma knives, where they wanted the chance for any phase to be received to be 80% total, not 80% per phase, as it was corrected to 80% total (20% per phase) in the other cases featuring chroma knife finishes.

                <br />
                <br />
                <h2>What is Unboxing ROI?</h2>
                <br />

                Unboxing ROI stands for Unboxing Return on Investment. It is the average value you can expect to receive when you open a container. For example, if a container costs $10 and has an ROI of 50%, it will return you an average of $5 per container. However, this does not take into account any losses from taxes or other fees when selling the items.


                <br />
                <br />
                <h2>What is Profit Chance?</h2>
                <br />

                Profit Chance is the chance of opening an item that is more expensive than the cost of the container. For example, if a container costs $10 and has a Profit% of 10%, on average you would need to open 10 containers to get an item worth more than $10. Again, this does not include any losses from taxes or other fees when selling the item.


                <br />
                <br />
                <h2>What is Investing ROI?</h2>
                <br />

                Investing ROI stands for Investing Return on Investment. If you bought a container around a certain timespan ago (by default one year), this is the percent increase (or decrease) in value you can expect to receive when you sell the container. This does not include any fees involved. To avoid any outlier prices providing inaccurate or constantly changing values, nearby values to the time period are also looked at and an average is used. If an item hasn't been available for the entire time period, a smaller time period is used, either 6 months or 1 month and multiplied to fit the desired time period. If the container is available in the in-game store and hence has a fixed price, an investing ROI is determined by the increase in value of the items within instead of the container's change in cost.

                <br />
                <br />
                <h2>What is Listings?</h2>
                <br />

                Listings is the number of listings for the item currently available for purchase on the Steam Community Market. This is not conclusive supply data, as it represents only one marketplace's current supply and doesn't account for items not available for sale. Even if you select another marketplace, only Steam listings are currently available and shown to ensure that the pricing data can be updated more consistently, as less data needs to be scraped per price update. This data is only available due to <a href='https://github.com/nombersDev/' target='_open'>Nomber</a>'s Steam pricing data API, which was graciously provided for free. Be sure to check out his projects <a href='https://skinledger.com/'>Skinledger</a> and <a href="https://github.com/nombersDev/casemove">Casemove</a>, two great tools for managing your inventory, storage units and tradeups.

                <br />
                <br />

                <h2>How do I view the old version of the site?</h2>
                <br />

                This current version of the site was created as a direct upgrade to the original CSROI.com and hence I will not maintain the old version of the site. If you disagree with this decision please tell me why @BrongoCS on Twitter/X . If there is too much unneccessary data for your use, e.g. you only want to view the unboxing data, the advanced settings on the home page will let you hide any undesired data.

                <br />
                <br />

                <h2>What do these "Drop Type" banners such as "Rare Drop" mean? </h2>
                <br />
                "Active Drops" can be received in your weekly care package.
                "Discontinued" items are no longer obtainable and must be bought / traded for.
                "In Game Store" items are in infinite supply and are highly recommended NOT to invest in. Buy these items in the CS2 Store tab in game.
                "Rare Drops" are very rarely obtained from your weekly care package, it is estimated that any care package has a 1% chance of containing a random Rare Drop.

                <br />
                <br />

                <h2>What is Items Within? </h2>
                <br />
                Items Within is one of the many accordions on the Collection pages, briefly giving data about all items available in the container, split up by rarity. The 1 in X stat refers to your chance to unbox a certain item, 1 in 10 means 10 unboxes are required on average to receive one of this specific item. The price provided on each item is the average unboxed price, meaning it is accounting for the chance to receive each wear. If you click an item and it has different wears available it will show each individual possible wear's cost and a bar graph to show the chance of receiving each wear. Clicking on any of these wears will lead you to your specified market's page for the specific item if you wish to buy it.
                <br />
                <br />

                <h2>What is Rarity Values? </h2>
                <br />
                Rarity Values is the new version of the previously known "ROI Percents". The size of each item and rarity is directly determined by how much value it is adding to the container, the biggest items are the most valuable to unbox. The percent values at the top of each rarity is the percentage of the Unboxing ROI provided by said rarity, e.g. if the knives have a 50% value in rarity values, it provides half of the Unboxing ROI of the container. The 1 in X value below the percent value, specifies the chance of receiving the rarity. The price at the bottom represents the average value of an unboxed item in that rarity, including any chances for wears. If you click on an item in the rarity values, it will show you the chance of receiving each wear and the average value of each wear. Clicking on any of these wears will lead you to your specified market's page for the specific item if you wish to buy it.
                <br />
                <br />

                <h2>What is History? </h2>
                <br />
                History is everything that has ever happened in the past. Jokes aside, the history accordion provides a series of graphs for the container you are viewing. November 2023 was a dark time period where CSGOTrader discontinued their free pricing API to utilise pricempire's API, which isn't free so I had to scrape my own pricing at this point to avoid those costs and hence I don't have data about that time period. I've attempted to create and update a similar pricing file at https://csroi.com/pricing.json , to continue that feature for anyone looking for a free pricing API like I once was, as long as you credit CSROI.com as your pricing source. There are time presets of 1 month, 6 month, 1 year and all time available to view these graphs, or you can use the slider below to set your custom time range.
                <br />
                <br />
                These are the graph types currently available:
                <ul>
                    <li>Case Cost - The cost to buy the container in the past</li>
                    <li>Case Cost + Key - The cost to buy the container and key in the past</li>
                    <li>Unboxing ROI - The average return from unboxing the container in the past, provided in a percent value</li>
                    <li>Investing ROI - The average return from buying the container in the past, compared to the current price, provided in a percent value</li>
                    <li>Chance To Profit On Unbox - Also known as Profit in the ranking grid, this is the past percent chance for any individual unbox to be worth more than the container (and key)</li>
                    <li>Rarities (Gloves/Knives, Covert, Classified, etc.) - The average value of an unboxed item within that rarity, accounting for any wear chances.</li>
                    <li>Multiply Rarity By Chance - This isn't specifically a graph type, but instead a modifier for the rarities. By multiplying each rarity by their chance you can see each rarity's cost change over time more clearly for comparison to each other.</li>
                    <li>Average Unbox - The average unbox's cost, accounting for each rarity's chance and each item's differing wear chances. </li>
                </ul>
                <br />
                <br />
                <h2>What is Simulator? </h2>
                <br />
                The simulator is a fun tool, to simulate opening a container as many times as you want. Knife/Glove baits are left intentionally in, for the fun of it and to show how unlikely receiving a glove/knife truly is. The default "delay" is 6 seconds to replicate the CS2 case opening experience where the animation takes around 6 seconds, but change this animation length yourself to any desired length of time in the right entry box. You can get the simulator to continue repeating simulations until your desired filter, changeable on the left entry box. You can repeat simulations until you finally get an simulation worth more than the cost to unbox (Until a profit), your desired number of times (X Times) or until you receive a specific rarity, item and/or wear (Until Filter). The previous simulations are visible directly below these options, and you can sort and clear them using the buttons to the top right of that area. All the stats about your simulations are below the previous simulations, I advise you hide this if you don't want your current unbox to be spoiled.
                <br />
                <br />
                <h2>What is Item & Float Drop Chances? </h2>
                <br />
                The Item & Float Drop Chances accordion is a tool with the main purpose of calculating and showing the chances to receive any specific wear and/or float range. It is also useful to show how maximum and minimum float of an item majorly affects where items unboxed usually have their floats dropped, as the bar graphs showing each dropping float range is squished within the float range possible. Select the rarity and item of your choice, and a bar graph showing the unboxing wear drop chances is shown above. Clicking any wear segment in the graph will zoom in and select that wear, for which you can then use the slider to determine the desired float range. For example you could view the chance for the float of a AK-47 | Inheritance Factory New to be lower than 0.01, where a table of chances for each step is presented, showing it to be a 1 in 58,389 unbox and only 9.62% of FN unboxed inheritances should be below 0.01 float.
                <br />
                <br />
                <h2>How are the costs of Past Operation rewards calculated?</h2>
                <br />

                The cost of items on the Past Operations are based on the cost of $0.45 USD per star when purchased directly from Steam. This cost is used to calculate the value of items that could previously be obtained by redeeming stars, such as special skins or other in-game items.

                <br />
                <br />
                <h2>How are these numbers calculated?</h2>

                <br />
                <a href="https://www.csgo.com.cn/news/gamebroad/20170911/206155.shtml" target="_blank">In 2017, when Valve released CS:GO to China, they were forced to publish the odds of their cases, capsules, etc.</a> This official source confirms that each rarity is 5x rarer than the past rarity to receive, except for exceedingly rare special items (knives, gloves) which are 2.5x rarer than the covert rarity, and industrial grade to mil-spec grade which are 4.8x rarer. All items within the same rarity have the same chance to receive. <br /><br />With this knowledge its possible to calculate how often you'll receive each item, but not how often you receive each wear for each item (field-tested, factory new, etc.). <br />

                <a href="https://blog.csgofloat.com/analysis-of-float-value-and-paint-seed-distribution-in-cs-go/" target="_blank">This in-depth look at float/wear values and how they work by CSGOFloat here</a> is what allows the calculation of wears for each item. Without going into too much detail, each item has a minimum wear and a maximum wear between 0-1, a.k.a a wear range. This directly determines the chance to receive each wear, with a wear range of 0-1 having the corresponding wear chances:

                <br />
                <br />

                <table style={{ margin: 'auto' }}>
                    <tbody><tr><th>WEAR</th><th>PROBABILITY</th></tr>
                        <tr><td>Factory New (0.00-0.07)</td><td>~3%</td></tr>
                        <tr><td>Minimal Wear (0.07-0.15)</td><td>~24%</td></tr>
                        <tr><td>Field-Tested (0.15-0.38)</td><td>~33%</td></tr>
                        <tr><td>Well-Worn (0.38-0.45)</td><td>~24%</td></tr>
                        <tr><td>Battle-Scarred (0.45-1.00)</td><td>~16%</td></tr>
                    </tbody></table>

                <br />

                What if the wear range of the item isn't 0-1 though? These probabilities get squished into the wear range of your item. For example, if the wear range of your item is 0-0.5 these are the corresponding probabilities:

                <br />
                <br />

                <table style={{ margin: 'auto' }}>
                    <tbody><tr><th>WEAR</th><th>PROBABILITY</th></tr>
                        <tr><td>Factory New (0.00-0.07)</td><td>~23.57%</td></tr>
                        <tr><td>Minimal Wear (0.07-0.15)</td><td>~24.43%</td></tr>
                        <tr><td>Field-Tested (0.15-0.38)</td><td>~44.89%</td></tr>
                        <tr><td>Well-Worn (0.38-0.45)</td><td>~4.15%</td></tr>
                        <tr><td>Battle-Scarred (0.45-0.5)</td><td>~2.96%</td></tr>
                    </tbody></table>

                <br />

                A Factory New (0-0.07 wear) wear in a full wear range item (0-1) has a chance of 3% and a Minimal Wear (0.07-0.15) in a full wear range item (0-1) has a chance of 24%. If you squish this in a 0-0.5 float range, these ranges for wears get shifted from 0-0.07, to 0-0.035 at 3% drop chance and 0.035-0.075 at 24% drop chance, resulting in 3% + (0.07-0.035/0.075-0.035 * 24%) = 24% chance of receiving Factory New.<br /><br />

                If you're following along you may be confused at why the table specifies a different result to the calculations, and this is because there is one last factor, missing wear ranges. On a 0-1 wear range item, there are specific floats that cannot be received from unboxing, at the edge of each wear rating. For example, at the edge between Factory New and Minimal Wear (0.07), there is a 0.01 range of wears that are NOT unboxable, meaning you will not unbox an item with a wear value between 0.07-0.08. All the missing wear ranges for a item with a wear range of 0-1 is, 0.07-0.08, 0.15-0.16, 0.38-0.39 and 0.45-0.46. The most logical theory for why these gaps exist is to distinguish the best minimal wear dropped from the worst factory new dropped, as they have to have at least 0.01 difference in wear.<br /><br />

                These missing wear ranges are also squished into the wear range of the item, e.g. in a 0-0.5 wear range item, you will not receive an unboxed item with a wear between 0.035-0.04, as in a 0-1 wear range you will not receive an unboxed item with a wear between 0.07-0.08. This means in a 0-0.5 float range, the ranges for wears get shifted from 0-0.07, to 0-0.035 at 3% drop chance and 0.04-0.075 at 24% drop chance, resulting in 3% + (0.07-0.04)/(0.075-0.04) * 24% = 23.57%.<br /><br />

                When you can predict how often you receive an item, and how often you will receive each wear of the item you can then combine all values to find an ROI and a Profit% for each container in CS:GO.<br /><br />

                <br />
                <br />
                <h2>Where are prices sourced from?</h2>
                <br />

                <s><a href="https://csgotrader.app/prices/" target="_blank">All prices are sourced from CSGOTrader's API, detailed and available here.</a> This API was chosen as it is one of the few available with BUFF.163 and Steam pricing for free, although it does offer even more pricing sources, they have not been implemented as of yet, this is something that can be added if there is demand for it.<br /><br />
                </s>

                As of November 2023, CSGOTrader has discontinued their free pricing API and has switched to using pricempire's API. In an attempt to avoid paying for a pricing API, I built a pricing scraper for Steam, Buff.163 and Skinport and conglomerated the results into a similar pricing file available at https://csroi.com/pricing.json. This pricing file is attempted to be updated every 6 hours to ensure the most accurate pricing data is available for the site. This pricing file is also available for anyone to use for free, as long as they credit CSROI.com for the data. If you have any questions about the pricing data, feel free to contact me on Twitter @BrongoCS.<br />
                As of May 2024 and this CSROI 2.0 update, I have begun utilizing an API from Skinledger/Casemove from NombersDev, as they graciously provided me access to their pricing for Steam data, allowing me to update the pricing data more consistently and accurately AND provide a new steam market listings figure. Be sure to check out their projects <a href='https://skinledger.com/'>Skinledger</a> and <a href="https://github.com/nombersDev/casemove">Casemove</a>.
                With this update, an additional pricing check has been added for more accurate pricing. If an item within a collection provides a shockingly large percent of the Unboxing ROI, a further check is done on the Steam history, and if the item has actually sold within a reasonable margin of the current listing price it'll use the listing price, if not the most recent sales provide a more reasonable price for these items and hence recent sales price is used.

                <br />
                <h2>Doppler Chances Data: </h2>
                <br />

                <table style={{ margin: 'auto', color: 'white', borderCollapse: 'collapse', width: '80%' }}>
                    <thead>
                        <tr>
                            <th>Knife</th>
                            <th>Phase</th>
                            <th>Count</th>
                            <th>Chance<br/>(some rounded, some calculated)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Karambit</td>
                            <td>Phase 1</td>
                            <td>7923</td>
                            <td>80%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Phase 2</td>
                            <td>8057</td>
                            <td>80%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Phase 3</td>
                            <td>7866</td>
                            <td>80%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Phase 4</td>
                            <td>7962</td>
                            <td>80%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Sapphire</td>
                            <td>890</td>
                            <td>9%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Ruby</td>
                            <td>915</td>
                            <td>9.3%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Black Pearl</td>
                            <td>198</td>
                            <td>2%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Damascus Steel</td>
                            <td>9854</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Gut</td>
                            <td>Phase 1</td>
                            <td>7477</td>
                            <td>80%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Phase 2</td>
                            <td>7723</td>
                            <td>80%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Phase 3</td>
                            <td>7428</td>
                            <td>80%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Phase 4</td>
                            <td>7672</td>
                            <td>80%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Sapphire</td>
                            <td>912</td>
                            <td>9.7%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Ruby</td>
                            <td>923</td>
                            <td>9.8%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Black Pearl</td>
                            <td>189</td>
                            <td>2%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Damascus Steel</td>
                            <td>9429</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Talon</td>
                            <td>Phase 1</td>
                            <td>2430</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Phase 2</td>
                            <td>2395</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Phase 3</td>
                            <td>2462</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Phase 4</td>
                            <td>2393</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Sapphire</td>
                            <td>1148</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Ruby</td>
                            <td>1075</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Black Pearl</td>
                            <td>242</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Damascus Steel</td>
                            <td>11632</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Butterfly</td>
                            <td>Phase 1</td>
                            <td>2498</td>
                            <td>20%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Phase 2</td>
                            <td>2495</td>
                            <td>20%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Phase 3</td>
                            <td>2458</td>
                            <td>20%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Phase 4</td>
                            <td>2433</td>
                            <td>20%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Sapphire</td>
                            <td>1140</td>
                            <td>9%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Ruby</td>
                            <td>1189</td>
                            <td>9.5%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Black Pearl</td>
                            <td>230</td>
                            <td>2%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Damascus Steel</td>
                            <td>12136</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Karambit Gamma</td>
                            <td>Phase 1</td>
                            <td>2396</td>
                            <td>22.3%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Phase 2</td>
                            <td>2552</td>
                            <td>23.7%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Phase 3</td>
                            <td>2478</td>
                            <td>23%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Phase 4</td>
                            <td>2510</td>
                            <td>23.3%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Emerald</td>
                            <td>823</td>
                            <td>7.6%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Black Laminate</td>
                            <td>10464</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Butterfly Gamma</td>
                            <td>Phase 1</td>
                            <td>3074</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Phase 2</td>
                            <td>3058</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Phase 3</td>
                            <td>2992</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Phase 4</td>
                            <td>2377</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Emerald</td>
                            <td>1119</td>
                            <td>8.47%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Black Laminate</td>
                            <td>12743</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Flip Gamma</td>
                            <td>Phase 1</td>
                            <td>2359</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Phase 2</td>
                            <td>2388</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Phase 3</td>
                            <td>2377</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Phase 4</td>
                            <td>2973</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Emerald</td>
                            <td>875</td>
                            <td>8%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Black Laminate</td>
                            <td>10277</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Shadow Daggers Gamma</td>
                            <td>Phase 1</td>
                            <td>2882</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Phase 2</td>
                            <td>2874</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Phase 3</td>
                            <td>2814</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Phase 4</td>
                            <td>2898</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Emerald</td>
                            <td>1001</td>
                            <td>8%</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Black Laminate</td>
                            <td>12474</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <h2>Final Results:</h2>
                <br />
                <table style={{ margin: 'auto', color: 'white', borderCollapse: 'collapse', width: '80%' }}>
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Chance</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Gamma Phases</td>
                            <td>23%</td>
                        </tr>
                        <tr>
                            <td>Gamma Emerald</td>
                            <td>8%</td>
                        </tr>
                        <tr>
                            <td>Doppler Phases - Chroma</td>
                            <td>80%</td>
                        </tr>
                        <tr>
                            <td>Doppler Phases - Prisma+</td>
                            <td>20%</td>
                        </tr>
                        <tr>
                            <td>Doppler Sapphire/Ruby - Chroma</td>
                            <td>9%</td>
                        </tr>
                        <tr>
                            <td>Doppler Sapphire/Ruby - Prisma+</td>
                            <td>9%</td>
                        </tr>
                        <tr>
                            <td>Doppler Black Pearl - Chroma</td>
                            <td>2%</td>
                        </tr>
                        <tr>
                            <td>Doppler Black Pearl - Prisma+</td>
                            <td>2%</td>
                        </tr>
                    </tbody>
                </table>


                <br />
                <br />
                <br />
                <br />
                <p style={{ textAlign: 'center' }}>
                    All containers, items and their corresponding images shown on this site are the property of Valve Corporation. CSROI.com does not guarantee that all probability calculations are correct as Valve Corporation controls all unboxing odds, this site serves merely to estimate the odds with community known data. Investing ROI serves only as an estimate based on historical data used on this site and should not be considered investing advice, past performance is not indicative of future performance.<br /><br />©2022 - 2024 CSROI.com<br /><br /><a href="http://www.onlinewebfonts.com">Unboxing Icon Provided by Online Web Fonts</a></p>

            </div>
        </div>
    );
}

export default FAQ;