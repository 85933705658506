import React, { version } from 'react';
import { Link } from 'react-router-dom';

const ChangeLog = () => {
    const changelogData = [
        {
            version: '1.4.2',
            date: '2025-03-28',
            changes: [
                <span>Start of Sparkle's Skinsearch sponsorship, if you have any feedback about the implementation, negative or positive, please reach out <a href="https://x.com/brongocs">@BrongoCS</a> </span>,
                <span>Fixed Deal's updated at timestamp to now update while the page is open, instead of once when the page is loaded</span>,
                <span>Changed History Height for mobile</span>
            ]
        },
        {
            version: '1.4.1',
            date: '2025-03-25',
            changes: [
                <span>Auto Refresh + Live Deals filter for Deals</span>,
                <span>Fullscreen inventory feature</span>,
                <span>Modified mobile case UI</span>,
                <span>Increased Rarity Values font sizes</span>,
                <span>Various bug fixes</span>
            ]
        },
        {
            version: '1.4.0',
            date: '2025-03-20',
            changes: [
                <span>Changed hosting to remove hash routing to fix Google indexing issues.</span>,
                <span>Deals page improved drastically, now featuring TF2 items for an instant way for more steam wallet funds, live csfloat listings and a reverse button, for deals that help turning your steam wallet funds into real money.</span>,
                <span>Simulator expected bug fix</span>,
                <span>Improved Mobile Rankings UI</span>
            ]
        },
        {
            version: '1.3.4',
            date: '2025-03-01',
            changes: [
                <span>Fixed an issue where the accordion opening cut off the top of Items Within. Thanks to <a href="https://x.com/ypsilondaone">@ypsilondaone</a> for the bug report</span>,
                <span>Added a simulator # for each unboxing to know which case gave you your lucky simulation. Thanks to <a href='https://x.com/MonkeyBizzCS'>@MonkeyBizzCS</a> for the idea</span>,
                <span>Fixed a bug with coloring for per item invest roi</span>
            ]
        },
        {
            version: '1.3.3',
            date: '2025-02-27',
            changes: [
                <span>Simulator optimizations and expected returns added</span>
            ]
        },
        {
            version: '1.3.2',
            date: '2025-02-25',
            changes: [
                <span>Added Doppler Gem Types to ROI Calculations and all features (please reach out if you find any bugs)</span>,
                <span>Attempted SEO improvement (reach out if you have ideas on how to improve)</span>,
                <span>Various bug fixes</span>
            ]
        },
        {
            version: '1.3.1',
            date: '2025-01-27',
            changes: [
                <span>Corrected $ per hour figures for the Armory Page when in use with fees on</span>,
                <span>Changed Third Party Marketplace Armory ROIs to utilise the Market Ratios to adjust the cost to unbox to be more realistic to the cost to unbox from that marketplace. This works the same as key costs for other marketplaces, where an easily achievable rate to convert from that marketplace to steam is used.</span>,
                <span>Fixed Major Pricing Error for EMS Katowice 2014 Capsules, causing very low prices and high ROIs</span>
            ]
        },
        {
            version: '1.3',
            date: '2024-12-24',
            changes: [
                <span>Added Charm Colors Feature</span>,
                <span>Added $ per hour estimations and best armory rewards based on risk to Armory Page</span>,
                <span>Various Bug Fixes</span>
            ]
        },
        {
            version: '1.2.2',
            date: '2024-11-29',
            changes: [
                <span>Invest ROI extrapolation (investing estimation if pricing isn't available for the entire time period) is now much less aggressive, now growth is expected to continue in the same direction, but at a much smaller magnitude</span>,
                <span>Improved Items Within by adding filters and per item invest ROI, so you can now easily find the best performing investments in each collection!</span>,
                <span>Added "Last Seen" timestamp to CSFloat Deals, to make it more clear that an item is a recently seen new deal, thanks to <a href="https://x.com/ypsilondaone">@ypsilondaone</a> for the valuable feedback about this</span>,
                <span>Improved Inventory Tool's Investing ROI Calculations, no longer using extrapolated data such as 1Y ROI for armory items with only 2 months of historical pricing and fixing errors with selected item's Investing ROI calculations.</span>
            ]
        },
        {
            version: '1.2.1.2',
            date: '2024-10-24',
            changes: [
                <span>Added an experimental "Cost To Unbox" accordion to each collection. If you're unboxing a case for 1 specific item, find out how much you would have to spend to receive it on average</span>,
                <span>Fixed an Armory Page bug that was causing the # ranking in the top right to be incorrect for the Gallery Case. Many thanks to <a href="https://x.com/ypsilondaone">@ypsilondaone</a> for letting me know.</span>,
                <span>Added applied charms to the Inventory Page</span>,
                <span>Added a tooltip to key pricing on platforms other than Steam, to explain why the key cost is considered lower on third party marketplaces.</span>,
                <span>Tweaked Armory Page Color Calculations, to represent the unprofitable nature of the Armory now compared to a week ago better.</span>,
                <span>Fixed minor inventory page bugs, such as tradehold, collection info button.</span>
            ]
        },
        {
            version: '1.2.1.1',
            date: '2024-10-18',
            changes: [
                <span>Fixed a critical bug breaking case breakdown pages. Many thanks to <a href="https://x.com/XBrute666">@XBrute666</a> for the twitter DM notifying me of the issue.</span>,
                <span>Improved Armory Page mobile experience</span>,
            ]
        },
        {
            version: '1.2.1',
            date: '2024-10-17',
            changes: [
                <span>NEW Armory Page, as suggested by <a href="https://x.com/ypsilondaone">@ypsilondaone</a> making it even easier to find how you want to spend YOUR armory stars</span>,
                <span>Added Tradeholds, sort by collection and other QOL features for the Inventory tool</span>,
                <span>Various Bug and QOL fixes, thanks to <a href="https://x.com/ypsilondaone">@ypsilondaone</a>, <a href="https://x.com/dropanet">@dropanet</a> and <a href='https://x.com/realnombers'>@realnombers</a> for their bug reports and suggestions</span>,
            ]
        },
        {
            version: '1.2',
            date: '2024-10-10',
            changes: [
                
                <span>All New Armory items added!
                <br/>Use the "Armory/Ops" Category to find the best way to spend your Armory stars!</span>,
                <span>Full Release of the Inventory Unboxing and Investing ROI Calculator!
                <br/>After many hours of development, the inventory tool is now fully released! Simply retrieve your raw inventory data and the tool will give you valuable investing roi data about your inventory. Clicking items will select them to give you in depth unboxing roi details and investing roi data. There still may be issues with the investing roi or other features here, please provide any feedback @BrongoCS on Twitter, or brongo@csroi.com via email. 
                <br/>A huge thanks to <a href='https://www.youtube.com/TheCaseOpeningChannel'>@TheQzak/The Case Opening Channel</a> for the in depth feedback on this tool, and giving many great ideas such as a small icon size for an easier inventory overview.
                <br/>Sorry mobile users, this tool isn't available for you for now, but if that's something you'd like to see, please let me know and I'll prioritize it.
                </span>,
                <span>
                    Rarity Value Breakdown on the Case Grid Item:
                    <br/>Below the the usual breakdown of each collection on the rankings/items pages, you can now see a bar of colors, which matches the value each rarity in a collection provides, it should mimic the exact size of the "Rarity Values" tool, to more easily find which rarities hold all the value in each openable. E.g. in the dreams & nightmares case the knives provide the vast majority of the value in the case, which is shown by the massive size of the yellow region in the color bar.<br/>
                    Many thanks to <a href='https://x.com/dropanet'>@dropanet</a> for the idea, they asked for a feature that'd better display where the value lies in each openable, further than what Profit% provides, and this is the solution we came up with for it.
                </span>,
                <span>
                    Start of the roll-out for per item invest ROI, beginning with the Inventory tool. This will be expanded to the item pages soon, so you can more easily distinguish which items in a collection are providing a great or not so great investing return.    
                </span>,
                <span>
                    Seperating Investing ROI type, from sorting type. A suggestion long ago, that I previously forgot about, sorry to whoever suggested this for taking so long to implement and sorry for forgetting who suggested it to credit. Now you can select a different type of investing roi, and still sort however you like. This should make it easier to find the best items to invest in, or unbox, depending on what you're looking for.    
                </span>,
                <span>
                    Change of image source for the new items, and perhaps the older items soon, to update images to the new CS2 images and attempt to fix varied caching and networking issues with retrieving many images at once from Valve's CDN.
                    <br/>Now using <a href='https://x.com/MonkeyBizzCS'>@MonkeyBizzCS</a>'s CS2 images CDN, hopefully there will be less missing image issues and even better edge caching for the images, lowering the load time for images. Many thanks go to @MonkeyBizzCS for reaching out and providing such a valuable free service.
                </span>,
                <span>
                    Improved the pricing scraper, should hopefully update prices more frequently and accurately, especially for the new items.
                </span>,
                <span>
                    Various Bug and QOL fixes
                </span>,
                <span>
                    Title, meta description, robots.txt, site map, and meta tags updated for better SEO.
                </span>,
            ]
        },
        {
            version: '1.1',
            date: '2024-08-23',
            changes: [
                
                <span>NEW Pricing Sources!
                    <br/>Buff.163 now relies on UUPin pricing for any items unavailable on Buff, such as cases or if UUPin is significantly cheaper. Many thanks to <a href='https://x.com/rumscsgo'>@rumscsgo</a> for providing the UUPin data.
                    <br/>CSFloat - Previous price history is based on Buff.163's pricing which sadly has had case price been estimated for a few months while Buff.163 hasn't been selling cases.
                    <br/>Tradeit.gg - Please welcome the new site sponsor! Tradeit is a great site for instantly trading skins, and now you can estimate your unboxing ROI based on withdrawing cases from their site and trading back the unboxed items. Investing ROI has been disabled for Tradeit.gg while a price history is developing on the site.
                </span>,
                <span>Variable Key Prices!<br/>
                    On CSROI.com, historically marketplaces outside of the Steam Market have had an unfavorable looking Unboxing ROI for cases due to having cheaper cases and items, but with the default key price used on Steam. This doesn't affect items that don't require a key to open but has been a long-standing issue for case statistics. Now, an automatically updating, easily achievable, marketplace to steam rate is used to calculate the key price for each case, making the Unboxing ROI more accurate for all cases across third party marketplaces.
                </span>,
                <span>Deals!<br/>
                During the creation of the variable key prices, a tool was made to find an easily achievable marketplace to steam rate. This tool has been repurposed into a deals page, where you can find the best deals on relatively easy to sell items across multiple marketplaces. If you want steam wallet balance for keys, skins or games, this tool will help you easily find much more profitable items to resell on steam to receive up to 50% more steam balance for your money! The deals page is still in beta, so please report any issues you find.
                <br/>Similarly to any other links on CSROI.com, the deals page uses affiliate links for marketplaces that offer them, which is the best way to support the ongoing development of the site.
                </span>,
                <span>Various Bug and QOL fixes</span>,
            ]
        },
        {
            version: '1.0.4',
            date: '2024-07-08',
            changes: [
                <span>Various Bug and QOL fixes</span>,
                <span>NEW - Your Unboxing ROI calculator, currently in a beta test phase, feel free to check it out at <a href='https://csroi.com/#/inventory'>https://csroi.com/#/inventory</a></span>,
                <span>New pricing sources are on the way!</span>,
            ]
        },
        {
            version: '1.0.3',
            date: '2024-06-13',
            changes: [
                <span>Fixed In-Game Store Investing ROI bug causing incorrect calculations, especially for Copenhagen 2024 capsules (Active next price update)</span>,
                <span>Changed History Accordion to automatically show what the investing ROI calculations are based on, e.g. In Game Store items now displaying average unbox graph automatically.</span>
            ]
        },
        {
            version: '1.0.2',
            date: '2024-05-30',
            changes: [
                <span>Corrected Pins Unboxing rarity chances to 1 : 2 rarity ratio, from the standard 1 : 5 for all other items. <a href="https://www.youtube.com/watch?v=lFxm-X20C2g">Shoutout Anomaly's video for the correction!</a></span>,
                <span>Operation Star Cost Fixed for Buff.163 Pricing (80% of Steam Cost)</span>,
                <span>History accordion minor bug fixes</span>
            ]
        },
        {
            version: '1.0.1',
            date: '2024-05-19',
            changes: [
                <span>Buff.163 container cost converted to 80% of Steam Market Cost as they have discontinued the selling of containers on their platform</span>,
            ]
        },
        {
            version: '1.0.0',
            date: '2024-05-19',
            changes: [
                <span>Initial Site Release</span>,
            ]
        },
        
        // Add more entries as needed
    ];

    return (
        <div style={{margin: 'auto', width: 'fit-content', height: 'fit-content'}}>
            <Link style={{margin: 'auto', display: 'flex'}} to='/'><img src="/csroi.png" class="p-2" style={{ maxWidth: '320px', width: '100%', height: "auto", objectFit: 'contain', margin: 'auto' }} /></Link>
            <h1 style={{margin: 'auto', textAlign: 'center', margin: '32px'}}>Changelog</h1>
            {changelogData.map((entry, index) => (
                <div key={index} style={{margin: '32px 64px 32px 64px'}}>
                    <h2>Version {entry.version}</h2>
                    <p>Date: {entry.date}</p>
                    <ul>
                        {entry.changes.map((change, i) => (
                            <li key={i}>{change}</li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
};

export default ChangeLog;