import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
// import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Theme, createTheme, ThemeProvider, ToggleButton, ToggleButtonGroup, Checkbox } from "@mui/material";
import Slider from "@mui/material/Slider";
import Button from '@mui/material/Button';



const formatter = new Intl.DateTimeFormat(undefined, {
  year: 'numeric',
  month: 'short',
  day: '2-digit'
});


export default function LineGraph({ itemNumber, itemData, caseData, exchangeRate, currency, pricingSource, sortBy, investType, isMobile, isFullscreen }) {

  function formatNumber(number, isCurrency) {
    // Use the user's locale
    const userLocale = navigator.language || 'en-US';

    // Create Intl.NumberFormat with user's locale
    if (isCurrency) {
      const formatter = new Intl.NumberFormat(userLocale, {
        //   minimumFractionDigits: 1,
        style: "currency",
        currency: currency,
        maximumFractionDigits: number > 1000 ? 0 : number > 100 ? 1 : 2,
      });
      return formatter.format(number);
    } else {
      const formatter = new Intl.NumberFormat(userLocale, {
        //   minimumFractionDigits: 1,
        maximumFractionDigits: number > 1000 ? 0 : number > 100 ? 1 : 2,
      });
      return formatter.format(number);
    }

    // Format the number

  }

  const location = useLocation();
  const url = location.pathname;
  const [rangeValue, setRangeValue] = useState(null);
  
  const [presetRangeValue, setPresetRangeValue] = useState("ALL");
  const [normaliseRarities, setNormaliseRarities] = useState(false);
  const [rangeModData, setRangeModData] = useState([[1635773400.0, 28.251450299999995],
  [1635859800.0, 28.82966355],
  ])
  const handleChange = (event, newValue) => {
    handlePresetRangeValue("CUSTOM");
    setRangeModData(data.slice(newValue[0], newValue[1] + 1));
    setRangeValue(newValue);
  };

  const handlePresetRangeValue = (preset) => {
    // console.log('reeees');
    setPresetRangeValue(preset);

    if (preset != "CUSTOM") {

      // Get current UTC epoch time
      var currentEpochTime = Date.now();

      // Calculate the epoch time for 1 year ago
      var oneYearAgoEpochTime = currentEpochTime - (365 * 24 * 60 * 60 * 1000); // assuming a non-leap year
      var oneMonthAgoEpochTime = currentEpochTime - (30 * 24 * 60 * 60 * 1000); // assuming a non-leap year
      var sixMonthsAgoEpochTime = currentEpochTime - (30 * 6 * 24 * 60 * 60 * 1000); // assuming a non-leap year
      var targetEpoch = Date.now();
      if (preset == "1Y") {
        targetEpoch = oneYearAgoEpochTime;
      } else if (preset == "1M") {
        targetEpoch = oneMonthAgoEpochTime;
      } else if (preset == "6M") {
        targetEpoch = sixMonthsAgoEpochTime;
      } else {
        targetEpoch = data[0][0] * 1000;
      }
      // var oneYearAgoEpochTime = currentEpochTime - (365 * 24 * 60 * 60 * 1000); // assuming a non-leap year
      console.log(targetEpoch);

      var closestTimestamp = null;
      var closestIndex = -1;
      var minDifference = Infinity;

      // Iterate over the timestamp list
      for (var i = 0; i < data.length; i++) {
        // Extract the timestamp from the current element
        var currentTimestamp = data[i][0] * 1000;

        // Calculate the absolute difference between the current timestamp and the target timestamp
        var difference = Math.abs(currentTimestamp - targetEpoch);

        // Check if this difference is smaller than the current minimum difference
        if (difference <= minDifference) {
          // Update the closest timestamp and the minimum difference
          closestTimestamp = currentTimestamp;
          minDifference = difference;
          closestIndex = i;
        }
      }
      console.log(closestTimestamp);
      var tempRangeModData = data.slice(closestIndex, data.length);

      setRangeModData(tempRangeModData)
      setRangeValue([closestIndex, data.length - 1]);
    }

  };

  const valuetext = (value) => {
    return `${value}`;
  };
  // const itemNumber = url.split('/')[2];
  // const [itemNumber, setItemNumber] = useState(3);
  const [data, setData] = useState([[1635773400.0, 28.251450299999995],
  [1635859800.0, 28.82966355],
  ]);

  const [allData, setAllData] = useState({});

  const [selectedDataTypes, setSelectedDataTypes] = useState([]);

  const [cache, setCache] = useState({});
  // const [platform, setPlatform] = useState("steam");
  const [disableCaseCost, setDisableCaseCost] = useState(false);

  const [first, setFirst] = useState(true);

  //const chanceModifiers = {"Contraband":0.0025575447570332483,"Covert":0.00639386189258312,"Classified":0.0319693094629156,"Restricted":0.159846547314578,"Mil_Spec":0.7992327365728901}
  const dataColors = { "Contraband": "gold", "Covert": "#eb4b4b", "Classified": "#d32ee6", "Restricted": "#8847ff", "Mil_Spec": "#4b69ff", "Industrial": "#5e98d9", "Consumer": "#B0C3D9", "CaseCost": "white", "CaseCostNoKey": "white", "ROI": "#ff7700", "Profit": "#FF006C", "iROI": "green", "AverageUnbox": "#2ED1BA" }
  const displayNames = { "Mil_Spec": "Mil-Spec", "AverageUnbox": "Average Unbox", "iROI": "Investing ROI", "ROI": caseData["CaseCost"] != 0.01 ? "Unboxing ROI" : "Average Unbox", "CaseCost": "Case Cost + Key", "CaseCostNoKey": "Case Cost", "Contraband": "Gloves / Knives" }
  const earlierSymbolName = { "Mil_Spec": "currencySymbol", "Profit": "", "AverageUnbox": "currencySymbol", "iROI": "", "ROI": caseData["CaseCost"] != 0.01 ? "" : "currencySymbol", "CaseCost": "currencySymbol",  "CaseCostNoKey": "currencySymbol", "Contraband": "currencySymbol", "Covert": "currencySymbol", "Classified": "currencySymbol", "Restricted": "currencySymbol", "Industrial": "currencySymbol", "Consumer": "currencySymbol", }
  const laterSymbolName = { "Mil_Spec": "", "Profit": "%", "AverageUnbox": "", "iROI": "%", "ROI": caseData["CaseCost"] != 0.01 ? "%" : "", "CaseCost": "", "CaseCostNoKey": "", "Contraband": "", "Covert": "", "Classified": "", "Restricted": "", "Industrial": "", "Consumer": "", }

  const otherRarityNames = { 'Extraordinary': 'Covert', 'Exotic': 'Classified', 'Remarkable': 'Restricted', 'High_Grade': 'Mil_Spec', 'Superior': 'Classified', 'Master': 'Covert', 'Exceptional': 'Restricted', 'Distinguished': 'Mil_Spec' }

  useEffect(() => {
    const fetchData = async () => {
      //Piggybacking data for all cases to update daily:
      let costUrl = `/pastData/${itemNumber.replace('s', '')}/${pricingSource.toLowerCase() + (itemNumber.includes('s') ? "Souvenir" : "")}/CaseCost.json`

      if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
        costUrl = `http://localhost:8080/pastData/${itemNumber.replace('s', '')}/${pricingSource.toLowerCase() + (itemNumber.includes('s') ? "Souvenir" : "")}/CaseCost.json`;
      }
      const response = await fetch(costUrl);
      const text = await response.text();
      // const processedText = `[${text.slice(0, -3)}]`;
      const processedData = JSON.parse(text);
      // console.log(processedData);
      if (itemData.DropType == "In Game Store" || itemData.CollectionType == "Collection") {
        setDisableCaseCost(true);
        setSelectedDataTypes(["AverageUnbox"]);
      } else if (itemData.KeyCostSteam != 0) {
        setDisableCaseCost(false);
        setSelectedDataTypes(["CaseCostNoKey"]);
      } else if (processedData.length < 1 || processedData[0][1] == processedData[processedData.length - 1][1]) {
        setDisableCaseCost(true);
        setSelectedDataTypes([caseData["CaseCost"] != 0.01 ? "ROI" : "AverageUnbox"]);
      } else {
        setDisableCaseCost(false);
        setSelectedDataTypes(["CaseCost"]);
      }
    }
    fetchData();

  }, [])

  useEffect(() => {
    var tempAllData = allData;
    const fetchData = async () => {
      const fetchPromises = selectedDataTypes.map(async dataType => {

        if (tempAllData[dataType + (normaliseRarities ? "true" : "false") + pricingSource] != null) {
          console.log("received data without query");
          console.log(dataType);
          return tempAllData[dataType + (normaliseRarities ? "true" : "false") + pricingSource];
        } else if (tempAllData[dataType + pricingSource] != null) {
          console.log("received data without query");
          return tempAllData[dataType + pricingSource];
        }
        if (dataType in caseData["PercentReceive"]) {
          var response;
          if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
            response = await fetch(`http://localhost:8080/pastData/${itemNumber.replace('s', '')}/${pricingSource.toLowerCase() + (itemNumber.includes('s') ? "Souvenir" : "")}/${dataType}.json`);
          } else {
            response = await fetch(`/pastData/${itemNumber.replace('s', '')}/${pricingSource.toLowerCase() + (itemNumber.includes('s') ? "Souvenir" : "")}/${dataType}.json`);
          }
          
          
          const text = await response.text();
          // const processedText = `[${text.slice(0, -3)}]`;
          const processedData = JSON.parse(text);
          var multiplier = 1;
          var subtract;
          // if (dataType in chanceModifiers) {
          //   multiplier = chanceModifiers[dataType];
          // } else {
          //   multiplier = 1;
          // }
          if (normaliseRarities) {
            multiplier = caseData["PercentReceive"][dataType];
          }
          const updatedData = processedData.map(pair => [pair[0], pair[1] / exchangeRate * multiplier]);
          tempAllData[dataType + (normaliseRarities ? "true" : "false") + pricingSource] = updatedData;
          // setCache({ ...cache, [dataType + normaliseRarities + itemNumber + pricingSource.toLowerCase()]: updatedData });
          return updatedData;
        }
        else if (dataType == "Profit" || dataType == "ROI") {
          var response;
          if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
            response = await fetch(`http://localhost:8080/pastData/${itemNumber.replace('s', '')}/${pricingSource.toLowerCase() + (itemNumber.includes('s') ? "Souvenir" : "")}/${dataType}.json`);
          } else {
            response = await fetch(`/pastData/${itemNumber.replace('s', '')}/${pricingSource.toLowerCase() + (itemNumber.includes('s') ? "Souvenir" : "")}/${dataType}.json`);
          }
          const text = await response.text();
          // const processedText = `[${text.slice(0, -3)}]`;
          const processedData = JSON.parse(text);
          var multiplier;
          var subtract;
          const updatedData = processedData.map(pair => [pair[0], pair[1] / (caseData["CaseCost"] != 0.01 ? 1 : exchangeRate * 100)]);
          // setCache({ ...cache, [dataType + normaliseRarities + itemNumber + pricingSource.toLowerCase()]: updatedData });
          tempAllData[dataType + pricingSource] = updatedData;
          return updatedData;
        }
        else if (dataType != "iROI" && dataType != "AverageUnbox") {
          var response;
          if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
            response = await fetch(`http://localhost:8080/pastData/${itemNumber.replace('s', '')}/${pricingSource.toLowerCase() + (itemNumber.includes('s') ? "Souvenir" : "")}/${dataType.replace("NoKey", "")}.json`);
          } else {
            response = await fetch(`/pastData/${itemNumber.replace('s', '')}/${pricingSource.toLowerCase() + (itemNumber.includes('s') ? "Souvenir" : "")}/${dataType.replace("NoKey", "")}.json`);
          }
          const text = await response.text();
          // const processedText = `[${text.slice(0, -3)}]`;
          const processedData = JSON.parse(text);
          var multiplier;
          var subtract;
          var updatedData;
          if (dataType == "CaseCost") {
            updatedData = processedData.map(pair => [pair[0], (pair[1]) / exchangeRate]);
          } else if (dataType == "CaseCostNoKey") {
            var key_response;
            if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
              key_response = await fetch(`http://localhost:8080/pastData/${itemNumber.replace('s', '')}/${pricingSource.toLowerCase() + (itemNumber.includes('s') ? "Souvenir" : "")}/KeyCost.json`);
            } else {
              key_response = await fetch(`/pastData/${itemNumber.replace('s', '')}/${pricingSource.toLowerCase() + (itemNumber.includes('s') ? "Souvenir" : "")}/KeyCost.json`);
            }
            const key_text = await key_response.text();
            const key_processedData = JSON.parse(key_text);
            updatedData = processedData.map(pair => {
              const timestamp = pair[0];
              const keyCostEntry = key_processedData.find(entry => entry[0] === timestamp);
              const keyCost = keyCostEntry ? keyCostEntry[1] : 0; // Default to 0 if no matching timestamp is found
              return [timestamp, (pair[1] - keyCost) / exchangeRate];
            });
          } else {
            var key_response;
            if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
              key_response = await fetch(`http://localhost:8080/pastData/${itemNumber.replace('s', '')}/${pricingSource.toLowerCase() + (itemNumber.includes('s') ? "Souvenir" : "")}/KeyCost.json`);
            } else {
              key_response = await fetch(`/pastData/${itemNumber.replace('s', '')}/${pricingSource.toLowerCase() + (itemNumber.includes('s') ? "Souvenir" : "")}/KeyCost.json`);
            }
            const key_text = await key_response.text();
            const key_processedData = JSON.parse(key_text);
            updatedData = processedData.map(pair => {
              const timestamp = pair[0];
              const keyCostEntry = key_processedData.find(entry => entry[0] === timestamp);
              const keyCost = keyCostEntry ? keyCostEntry[1] : 0; // Default to 0 if no matching timestamp is found
              return [timestamp, (pair[1] - keyCost) / exchangeRate];
            });
          }
          
          // setCache({ ...cache, [dataType + normaliseRarities + itemNumber + pricingSource.toLowerCase()]: updatedData });
          tempAllData[dataType + pricingSource] = updatedData;
          return updatedData;
        } else if (dataType === "AverageUnbox") {
          var allData = [];
          for (var [rarity, percent] of Object.entries(caseData['PercentReceive'])) {

            if (tempAllData[rarity]) {
              var multiplier;

              if (rarity in caseData['PercentReceive']) {
                multiplier = caseData['PercentReceive'][rarity];
              }
              //  else {
              //   multiplier = 1;
              // }
              const updatedData = tempAllData[rarity].map(pair => [pair[0], pair[1] * multiplier]);
              allData[allData.length] = updatedData;
            } else {

              var response;
              if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
                response = await fetch(`http://localhost:8080/pastData/${itemNumber.replace('s', '')}/${pricingSource.toLowerCase() + (itemNumber.includes('s') ? "Souvenir" : "")}/${rarity}.json`);
              } else {
                response = await fetch(`/pastData/${itemNumber.replace('s', '')}/${pricingSource.toLowerCase() + (itemNumber.includes('s') ? "Souvenir" : "")}/${rarity}.json`);
              }
              const text = await response.text();
              // const processedText = `[${text.slice(0, -3)}]`;
              const processedData = JSON.parse(text);
              var multiplier;
              var subtract;
              if (rarity in caseData['PercentReceive']) {
                multiplier = caseData['PercentReceive'][rarity];
              } else {
                multiplier = 1;
              }
              const updatedData = processedData.map(pair => [pair[0], pair[1] / exchangeRate * multiplier]);
              // setCache({ ...cache, [rarity + true + itemNumber + pricingSource.toLowerCase()]: updatedData });
              allData[allData.length] = updatedData;
            }

          }
          var finalData = [];
          var x = 0;

          for (var testData in allData[0]) {
            finalData[x] ??= [allData[0][x][0], 0];
            var y = 0;
            for (dataType in allData) {
              finalData[x][1] += allData[y][x][1];
              y++;
            }
            x++;
          }
          // setCache({ ...cache, [dataType + true + itemNumber + pricingSource.toLowerCase()]: finalData });
          tempAllData[dataType + pricingSource] = finalData;
          return finalData;
        } else {
          var response;
          if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
            response = await fetch(`http://localhost:8080/pastData/${itemNumber.replace('s', '')}/${pricingSource.toLowerCase() + (itemNumber.includes('s') ? "Souvenir" : "")}/CaseCost.json`);
          } else {
            response = await fetch(`/pastData/${itemNumber.replace('s', '')}/${pricingSource.toLowerCase() + (itemNumber.includes('s') ? "Souvenir" : "")}/CaseCost.json`);
          }
          const text = await response.text();
          // const processedText = `[${text.slice(0, -3)}]`;
          var processedData = JSON.parse(text);
          if (processedData.length < 1) {
            var response;
            if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
              response = await fetch(`http://localhost:8080/pastData/${itemNumber.replace('s', '')}/${pricingSource.toLowerCase() + (itemNumber.includes('s') ? "Souvenir" : "")}/ROI.json`);
            } else {
              response = await fetch(`/pastData/${itemNumber.replace('s', '')}/${pricingSource.toLowerCase() + (itemNumber.includes('s') ? "Souvenir" : "")}/ROI.json`);
            }
            const text = await response.text();
            // const processedText = `[${text.slice(0, -3)}]`;
            processedData = JSON.parse(text);
          }
          const updatedData = processedData.map(pair => [pair[0], pair[1] - caseData['KeyCost']]);
          const roiData = updatedData.map((entry, index) => {
            const date = entry[0];
            const cost = entry[1];
            let roi = 0;
            const futureCost = updatedData[updatedData.length - 1][1];
            roi = (futureCost - cost) / cost;
            return [date, roi];
          });
          // const updatedData = processedData.map(pair => [pair[0], pair[1]]);
          // setCache({ ...cache, [dataType + normaliseRarities + itemNumber + pricingSource.toLowerCase()]: roiData });
          tempAllData[dataType + pricingSource] = roiData;
          return roiData;
        }

      });
      const allTypes = await Promise.all(fetchPromises);

      const finalData = allTypes[0].map((entry1, index) =>
        allTypes.reduce((acc, type) => {
          // console.log(type);
          // console.log(index);
          var newValues = [];
          if (type.length > index) {
            newValues = [type[index][1]];
          } else {
            newValues = [type[index - 2][1]];
          }
          // const newValues = [type[index][1]];
          return acc.concat(newValues);
        }, [entry1[0]])
      );
      var tempRangeValue = rangeValue;
      if (rangeValue === null) {
        setRangeValue([0, finalData.length + 1]);
        tempRangeValue = [0, finalData.length + 1];
      }



      setRangeModData(finalData.slice(tempRangeValue[0], tempRangeValue[1]))
      setAllData(tempAllData);
      setData(finalData);
    };
    if (selectedDataTypes.length > 0) {
      fetchData();
    }

  }, [selectedDataTypes, itemNumber, pricingSource, normaliseRarities, exchangeRate]);

  useEffect(() => {
    if (first) {
      if (investType == "1y") {
        handlePresetRangeValue("1Y")
      } else if (investType == "6m") {
        handlePresetRangeValue("6M")
      } else if (investType == "1m") {
        handlePresetRangeValue("1M")
      } else {
        handlePresetRangeValue("1Y")
      }
      setFirst(false)
    } else {
      handlePresetRangeValue(presetRangeValue)
    }

  }, [data])





  // const data = dataMilspec.map((entry1, index) => [entry1[0], entry1[1], dataRestricted[index][1]]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length && rangeModData.length > 0 && selectedDataTypes.length > 0) {
      return (
        <div className="custom-tooltip" style={{ 'backgroundColor': '#121212', opacity: '80%', paddingLeft: '10px', paddingRight: '10px', paddingTop: '4px', paddingBottom: '4px', borderRadius: '8px' }}>
          {
            rangeModData[0].map((type, index) => (
              <p key={index} className="label" style={{ color: dataColors[Object.keys(otherRarityNames).includes(selectedDataTypes[index - 1]) ? otherRarityNames[selectedDataTypes[index - 1]] : selectedDataTypes[index - 1]] ?? 'white' }}>
                {index === 0 ?
                  formatter.format(new Date(rangeModData[label][0] * 1000)) + ": " :
                  (((caseData != null ? caseData['KeyCost'] != 0: true) ? (displayNames[selectedDataTypes[index - 1]] ?? selectedDataTypes[index - 1]): (displayNames[selectedDataTypes[index - 1]] ?? selectedDataTypes[index - 1]).replace(" + Key", ""))) + ": " + (earlierSymbolName[Object.keys(otherRarityNames).includes(selectedDataTypes[index - 1]) ? otherRarityNames[selectedDataTypes[index - 1]] : selectedDataTypes[index - 1]] == "currencySymbol" ? "" : earlierSymbolName[selectedDataTypes[index - 1]]) + formatNumber(selectedDataTypes[index - 1] === "ROI" && (caseData != null ? caseData["CaseCost"] == 0.01: true) ? rangeModData[label][index] : selectedDataTypes[index - 1] === "iROI" || selectedDataTypes[index - 1] === "ROI" || selectedDataTypes[index - 1] === "Profit" ? rangeModData[label][index] * 100 : rangeModData[label][index], earlierSymbolName[Object.keys(otherRarityNames).includes(selectedDataTypes[index - 1]) ? otherRarityNames[selectedDataTypes[index - 1]] : selectedDataTypes[index - 1]] == "currencySymbol") + (laterSymbolName[earlierSymbolName[Object.keys(otherRarityNames).includes(selectedDataTypes[index - 1]) ? otherRarityNames[selectedDataTypes[index - 1]] : selectedDataTypes[index - 1]]] ?? "" == "currencySymbol" ? "" : laterSymbolName[selectedDataTypes[index - 1]] ?? "")
                }
              </p>
            ))
          }
        </div>
      );
    }
    return null;
  };


  function valueLabelFormat(value) {
    return formatter.format(new Date(data[value][0] * 1000))
  }



  return (
    data.length > 1 && <div>

      <ResponsiveContainer width="100%" height={isMobile ? 320 : isFullscreen ? window.innerHeight - 300 : 500}>
        {rangeModData.length > 0 ?
          <LineChart
            // width={500} 
            height={300}
            data={rangeModData}
            margin={{

              top: 5,
              right: isMobile ? 5 : 44,
              left: isMobile ? -4 : -4,
              bottom: 5
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={0} tickCount={8} tickFormatter={(tick) => formatter.format(new Date(rangeModData[tick][0] * 1000))} />

            <YAxis type="number" domain={['dataMin', 'dataMax']} tickFormatter={(tick) => tick.toFixed(2)} tickCount={8} />
            <Tooltip content={<CustomTooltip />} />

            {/* <Legend /> */}
            {rangeModData[0].map((dataValue, index) => (
              (index !== 0) ?
                <Line
                  key={index}
                  type="monotone"
                  dataKey={index}
                  stroke={dataColors[Object.keys(otherRarityNames).includes(selectedDataTypes[index - 1]) ? otherRarityNames[selectedDataTypes[index - 1]] : selectedDataTypes[index - 1]] ?? 'white'}
                  dot={false}
                /> : ""
            ))}


            {/* <Line
        type="monotone"
        dataKey={1}
        stroke="#8884d8"
        dot={false}
        // activeDot={{ r: 8 }}
      /> */}
            {/* <Line
        type="monotone"
        dataKey={2}
        stroke="#8884d8"
        dot={false}
        // activeDot={{ r: 8 }}
      />
      <Line
        type="monotone"
        dataKey={3}
        stroke="#8884d8"
        dot={false}
        // activeDot={{ r: 8 }}
      />
      <Line
        type="monotone"
        dataKey={4}
        stroke="#8884d8"
        dot={false}
        // activeDot={{ r: 8 }}
      />
      <Line
        type="monotone"
        dataKey={5}
        stroke="#8884d8"
        dot={false}
        // activeDot={{ r: 8 }}
      /> */}
            <Legend formatter={(value) => `${caseData != undefined ? ((caseData["KeyCost"] == 0 ? (displayNames[selectedDataTypes[value - 1]] ?? selectedDataTypes[value - 1])?.replace(" + Key", ""): (displayNames[selectedDataTypes[value - 1]] ?? selectedDataTypes[value - 1]))): (displayNames[selectedDataTypes[value - 1]] ?? selectedDataTypes[value - 1])}`} >

            </Legend>
          </LineChart> :
          <div />
        }
      </ResponsiveContainer>
      {/* <div>
      <ToggleButtonGroup>
        <ToggleButton key="steam" name="platform" type="radio" value="steam" checked={platform === "steam"} onChange={(e) => setPlatform(e.currentTarget.value)}>
          Steam
        </ToggleButton>
        <ToggleButton key="buff" name="platform" type="radio"  value="buff" checked={platform === "buff"} onChange={(e) => setPlatform(e.currentTarget.value)}>
          Buff
        </ToggleButton>
      </ToggleButtonGroup>
      </div> */}
      <div style={{ display: "flex", justifyContent: "center", flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center', margin: 'auto'}}><div style={{ margin: 'auto', display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: "center" }}><Button style={{margin: '2px'}} variant={presetRangeValue == "1M" ? "contained" : "outlined"} color="error" onClick={() => handlePresetRangeValue("1M")}>1M</Button> <Button style={{margin: '2px'}} variant={presetRangeValue == "6M" ? "contained" : "outlined"} color="warning" onClick={() => handlePresetRangeValue("6M")}>6M</Button> <Button style={{margin: '2px'}} variant={presetRangeValue == "1Y" ? "contained" : "outlined"} color="success" onClick={() => handlePresetRangeValue("1Y")}>1Y</Button>  <Button style={{margin: '2px'}} variant={presetRangeValue == "ALL" ? "contained" : "outlined"} onClick={() => handlePresetRangeValue("ALL")}>ALL</Button> <Button style={{margin: '2px'}} variant={presetRangeValue == "CUSTOM" ? "contained" : "outlined"} disabled onClick={() => handlePresetRangeValue("CUSTOM")}>CUSTOM</Button></div></div>

        <Slider
          getAriaLabel={() => "Graph range"}
          value={rangeValue}
          onChange={handleChange}
          valueLabelDisplay="auto"
          valueLabelFormat={valueLabelFormat}
          getAriaValueText={valuetext}
          max={data.length - 1}
          style={{ width: isMobile ? "95%" : "92%", margin: "auto" }}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' }}>

        {/* <div style={{ margin: 'auto'}}></div> */}
        {!disableCaseCost && <div>
          <Checkbox
            type="checkbox"
            id="CaseCost"
            checked={selectedDataTypes.includes('CaseCost')}
            onChange={({ target }) => {
              if (!selectedDataTypes.includes('CaseCost')) {
                setSelectedDataTypes([...selectedDataTypes, 'CaseCost']);
              } else {
                setSelectedDataTypes(selectedDataTypes.filter(d => d !== 'CaseCost'));
              }
            }}
          />

          <label htmlFor="CaseCost">Case Cost {caseData['KeyCost'] != 0 ? "+ Key": ""}</label></div>}
        {!disableCaseCost && caseData['KeyCost'] != 0 && <div>
        <Checkbox
            type="checkbox"
            id="CaseCostNoKey"
            checked={selectedDataTypes.includes('CaseCostNoKey')}
            onChange={({ target }) => {
              if (!selectedDataTypes.includes('CaseCostNoKey')) {
                setSelectedDataTypes([...selectedDataTypes, 'CaseCostNoKey']);
              } else {
                setSelectedDataTypes(selectedDataTypes.filter(d => d !== 'CaseCostNoKey'));
              }
            }}
          />

          <label htmlFor="CaseCostNoKey">Case Cost</label></div>
        }
        <div>
          <Checkbox
            type="checkbox"
            id={(caseData["CaseCost"] != 0.01 ? "ROI" : "AverageUnbox")}
            
            checked={selectedDataTypes.includes((caseData["CaseCost"] != 0.01 ? "ROI" : "AverageUnbox"))}
            onChange={({ target }) => {
              if (!selectedDataTypes.includes((caseData["CaseCost"] != 0.01 ? "ROI" : "AverageUnbox"))) {
                setSelectedDataTypes([...selectedDataTypes, (caseData["CaseCost"] != 0.01 ? "ROI" : "AverageUnbox")]);
              } else {
                setSelectedDataTypes(selectedDataTypes.filter(d => d !== (caseData["CaseCost"] != 0.01 ? "ROI" : "AverageUnbox")));
              }
            }}
          />

          <label htmlFor="ROI" style={{ color: caseData["CaseCost"] != 0.01 ? dataColors["ROI"] : dataColors["AverageUnbox"] }}>{caseData["CaseCost"] != 0.01 ? "Unboxing ROI" : "Average Unbox"}</label>
        </div>
        <div>
          {!disableCaseCost && <div><Checkbox
            type="checkbox"
            id="iROI"
            checked={selectedDataTypes.includes('iROI')}
            onChange={({ target }) => {
              if (!selectedDataTypes.includes('iROI')) {
                setSelectedDataTypes([...selectedDataTypes, 'iROI']);
              } else {
                setSelectedDataTypes(selectedDataTypes.filter(d => d !== 'iROI'));
              }
            }}
          />
            <label htmlFor="iROI" style={{ color: dataColors["iROI"] }}>Investing ROI</label></div>}
        </div>
        {caseData["CaseCost"] != 0.01 &&
          <div><Checkbox
            type="checkbox"
            id="Profit"
            
            checked={selectedDataTypes.includes('Profit')}
            onChange={({ target }) => {
              if (!selectedDataTypes.includes('Profit')) {
                setSelectedDataTypes([...selectedDataTypes, 'Profit']);
              } else {
                setSelectedDataTypes(selectedDataTypes.filter(d => d !== 'Profit'));
              }
            }}
          />
            <label style={{ color: dataColors["Profit"] }} htmlFor="Profit">Chance To Profit On Unbox</label></div>
        }

      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' }}>

        {/* <div style={{ margin: 'auto'}}></div> */}
        {Object.entries(caseData["PercentReceive"]).map(([rarity, percent]) => (
          <label htmlFor={rarity} key={rarity} style={{ color: Object.keys(otherRarityNames).includes(rarity) ? dataColors[otherRarityNames[rarity]] : dataColors[rarity] }}>
            <Checkbox
              type="checkbox"
              id={rarity}
              checked={selectedDataTypes.includes(rarity)}
              onChange={({ target }) => {
                if (!selectedDataTypes.includes(rarity)) {
                  setSelectedDataTypes([...selectedDataTypes, rarity]);
                } else {
                  setSelectedDataTypes(selectedDataTypes.filter(d => d !== rarity));
                }
              }}
            />
            {rarity.replace('_', '-').replace('Contraband', 'Gloves / Knives')}
          </label>
        ))}

        {caseData["CaseCost"] != 0.01 &&
          <div>
            <Checkbox
              type="checkbox"
              id="AverageUnbox"
              checked={selectedDataTypes.includes('AverageUnbox')}
              style={{ color: dataColors["AverageUnbox"] }}
              onChange={({ target }) => {
                if (!selectedDataTypes.includes('AverageUnbox')) {
                  setSelectedDataTypes([...selectedDataTypes, 'AverageUnbox']);
                } else {
                  setSelectedDataTypes(selectedDataTypes.filter(d => d !== 'AverageUnbox'));
                }
              }}
            />

            <label style={{ color: dataColors["AverageUnbox"] }} htmlFor="AverageUnbox">Average Unbox</label></div>
        }
        {/* Add more checkboxes for other data types here */}
        <div>
          <label htmlFor="normaliseRarities" style={{ marginLeft: 'auto' }}>
            <Checkbox
              type="checkbox"
              id="normaliseRarities"
              checked={normaliseRarities}
              onChange={({ target }) => {
                setNormaliseRarities(!normaliseRarities);
              }}
            />
            Multiply Rarity By Chance</label>
        </div>


      </div>
    </div>
  );
}