import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';

const CollectionValues = () => {
  const [initialItems, setInitialItems] = useState([]);
  const storedSort = localStorage.getItem('sortBy');
  const [sortBy, setSortBy] = useState(storedSort || "unbox");
  const cachedPricingSource = localStorage.getItem('pricingSource');
  const [pricingSource, setPricingSource] = useState(cachedPricingSource || 'Steam');

  const [group1, setGroup1] = useState([]);
  const [group2, setGroup2] = useState([]);
  const [group3, setGroup3] = useState([]);
  const [group4, setGroup4] = useState([]);
  const [group5, setGroup5] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('/pastData/allTrackedCases.json');
      const text = await response.text();
      const processedData = JSON.parse(text);
      setInitialItems(processedData);

      const group1Items = processedData.filter((_, index) => index % 5 === 0);
      const group2Items = processedData.filter((_, index) => index % 5 === 1);
      const group3Items = processedData.filter((_, index) => index % 5 === 2);
      const group4Items = processedData.filter((_, index) => index % 5 === 3);
      const group5Items = processedData.filter((_, index) => index % 5 === 4);

      setGroup1(group1Items);
      setGroup2(group2Items);
      setGroup3(group3Items);
      setGroup4(group4Items);
      setGroup5(group5Items);
    };
    fetchData();
  }, []);

  return (
    <div>
      <Grid container xs={2} spacing={2}>
        <Grid item xs={2}>
          {group1.map((item, index) => {

            let redNum;
            let greenNum;
            let blueNum;
            let compareValue = ((sortBy == "unbox" || sortBy == "profit" || sortBy == "dropType" || sortBy == "cost") ? (pricingSource == "Steam" ? item.SteamROI : pricingSource == "CSFloat" ? item.CSFloatROI : pricingSource == "Tradeit" ? item.TradeitROI : pricingSource == "Buff" ? item.BuffROI : item.SkinportROI) : (
              pricingSource == "Steam" ? sortBy == "invest1M" ? item?.Steam1MiROI : sortBy == "invest6M" ? item?.Steam6MiROI : item?.SteamiROI ?? 0 :
                pricingSource == "CSFloat" ? sortBy == "invest1M" ? item?.CSFloat1MiROI : sortBy == "invest6M" ? item?.CSFloat6MiROI : item?.CSFloatiROI ?? 0 :
                  pricingSource == "Tradeit" ? sortBy == "invest1M" ? item?.Tradeit1MiROI : sortBy == "invest6M" ? item?.Tradeit6MiROI : item?.TradeitiROI ?? 0 :
                    pricingSource == "Buff" ? sortBy == "invest1M" ? item?.Buff1MiROI : sortBy == "invest6M" ? item?.Buff6MiROI : item?.BuffiROI ?? 0 :
                      sortBy == "invest1M" ? item?.Skinport1MiROI : sortBy == "invest6M" ? item?.Skinport6MiROI : item?.SkinportiROI ?? 0))
            if (item.CollectionType == "Collection" && (sortBy == "unbox" || sortBy == "profit" || sortBy == "dropType" || sortBy == "cost")) {
              compareValue *= 0.001;
            }
            if (sortBy == "invest1M") {
              compareValue *= 12;
            }
            if (sortBy == "invest6M") {
              compareValue *= 2;
            }
            if ((sortBy == "unbox" || sortBy == "profit" || sortBy == "dropType" || sortBy == "cost")) {
              if (compareValue > 1) {
                redNum = 223;
                greenNum = 199;
                blueNum = 0;
              } else if (compareValue > 0.8) {
                redNum = 187 + Math.abs(compareValue - 0.8) / 0.2 * 36;
                greenNum = 23 + Math.abs(compareValue - 0.8) / 0.2 * 176;
                blueNum = 26 - Math.abs(compareValue - 0.8) / 0.2 * 26;
              } else if (compareValue > 0.6) {
                redNum = 158 + Math.abs(compareValue - 0.6) / 0.2 * 29;
                greenNum = 0 + Math.abs(compareValue - 0.6) / 0.2 * 23;
                blueNum = 197 - Math.abs(compareValue - 0.6) / 0.2 * 171;
              } else if (compareValue > 0.4) {
                redNum = 104 + Math.abs(compareValue - 0.4) / 0.2 * 54;
                greenNum = 50 - Math.abs(compareValue - 0.4) / 0.2 * 50;
                blueNum = 234 - Math.abs(compareValue - 0.4) / 0.2 * 37;
              } else {
                redNum = 2 + Math.abs(compareValue) / 0.4 * 102;
                greenNum = 79 - Math.abs(compareValue) / 0.4 * 29;
                blueNum = 228 + Math.abs(compareValue) / 0.4 * 6;
              }
            } else {
              if (compareValue > 400) {
                redNum = 223;
                greenNum = 199;
                blueNum = 0;
              } else if (compareValue > 200) {
                redNum = 187 + (compareValue - 200) / 200 * 36;
                greenNum = 23 + (compareValue - 200) / 200 * 176;
                blueNum = 26 - (compareValue - 200) / 200 * 26;
              } else if (compareValue > 100) {
                redNum = 158 + (compareValue - 100) / 100 * 29;
                greenNum = 0 + (compareValue - 100) / 100 * 23;
                blueNum = 197 - (compareValue - 100) / 100 * 171;
              } else if (compareValue > 50) {
                redNum = 104 + (compareValue - 50) / 50 * 54;
                greenNum = 50 - (compareValue - 50) / 50 * 50;
                blueNum = 234 - (compareValue - 50) / 50 * 37;
              } else {
                redNum = 2;
                greenNum = 79;
                blueNum = 228;
              }
            }

            // Calculate redNum, greenNum, blueNum based on compareValue

            const bgColor = `rgb(${redNum}, ${greenNum}, ${blueNum})`;

            return (
              <Grid item key={index} xs={2} style={{ background: `linear-gradient(145deg, RGB(${redNum}, ${greenNum}, ${blueNum}) 0%, black 100%)`, }}>
                <img src={item.Image} alt={item.Name} />
                <div>{item.Name}</div>
                <div>{item.NumListings}</div>
                <div>{item.UrlName}</div>
                <div>{item.CollectionId}</div>
              </Grid>
            );

          })}
        </Grid>
        <Grid item xs={2}>
          {group2.map((item, index) => {

            let redNum;
            let greenNum;
            let blueNum;
            let compareValue = ((sortBy == "unbox" || sortBy == "profit" || sortBy == "dropType" || sortBy == "cost") ? (
              pricingSource == "Steam" ? item.SteamROI : pricingSource == "CSFloat" ? item.CSFloatROI : 
              pricingSource == "Tradeit" ? item.TradeitROI : pricingSource == "Buff" ? item.BuffROI : item.SkinportROI) : 
              (pricingSource == "Steam" ? sortBy == "invest1M" ? item?.Steam1MiROI : sortBy == "invest6M" ? item?.Steam6MiROI : item?.SteamiROI ?? 0 :
                pricingSource == "CSFloat" ? sortBy == "invest1M" ? item?.CSFloat1MiROI : sortBy == "invest6M" ? item?.CSFloat6MiROI : item?.CSFloatiROI ?? 0 :
        pricingSource == "Tradeit" ? sortBy == "invest1M" ? item?.Tradeit1MiROI : sortBy == "invest6M" ? item?.Tradeit6MiROI : item?.TradeitiROI ?? 0 :
              pricingSource == "Buff" ? sortBy == "invest1M" ? item?.Buff1MiROI : sortBy == "invest6M" ? item?.Buff6MiROI : item?.BuffiROI ?? 0 :
                sortBy == "invest1M" ? item?.Skinport1MiROI : sortBy == "invest6M" ? item?.Skinport6MiROI : item?.SkinportiROI ?? 0))
            if (item.CollectionType == "Collection" && (sortBy == "unbox" || sortBy == "profit" || sortBy == "dropType" || sortBy == "cost")) {
              compareValue *= 0.001;
            }
            if (sortBy == "invest1M") {
              compareValue *= 12;
            }
            if (sortBy == "invest6M") {
              compareValue *= 2;
            }
            if ((sortBy == "unbox" || sortBy == "profit" || sortBy == "dropType" || sortBy == "cost")) {
              if (compareValue > 1) {
                redNum = 223;
                greenNum = 199;
                blueNum = 0;
              } else if (compareValue > 0.8) {
                redNum = 187 + Math.abs(compareValue - 0.8) / 0.2 * 36;
                greenNum = 23 + Math.abs(compareValue - 0.8) / 0.2 * 176;
                blueNum = 26 - Math.abs(compareValue - 0.8) / 0.2 * 26;
              } else if (compareValue > 0.6) {
                redNum = 158 + Math.abs(compareValue - 0.6) / 0.2 * 29;
                greenNum = 0 + Math.abs(compareValue - 0.6) / 0.2 * 23;
                blueNum = 197 - Math.abs(compareValue - 0.6) / 0.2 * 171;
              } else if (compareValue > 0.4) {
                redNum = 104 + Math.abs(compareValue - 0.4) / 0.2 * 54;
                greenNum = 50 - Math.abs(compareValue - 0.4) / 0.2 * 50;
                blueNum = 234 - Math.abs(compareValue - 0.4) / 0.2 * 37;
              } else {
                redNum = 2 + Math.abs(compareValue) / 0.4 * 102;
                greenNum = 79 - Math.abs(compareValue) / 0.4 * 29;
                blueNum = 228 + Math.abs(compareValue) / 0.4 * 6;
              }
            } else {
              if (compareValue > 400) {
                redNum = 223;
                greenNum = 199;
                blueNum = 0;
              } else if (compareValue > 200) {
                redNum = 187 + (compareValue - 200) / 200 * 36;
                greenNum = 23 + (compareValue - 200) / 200 * 176;
                blueNum = 26 - (compareValue - 200) / 200 * 26;
              } else if (compareValue > 100) {
                redNum = 158 + (compareValue - 100) / 100 * 29;
                greenNum = 0 + (compareValue - 100) / 100 * 23;
                blueNum = 197 - (compareValue - 100) / 100 * 171;
              } else if (compareValue > 50) {
                redNum = 104 + (compareValue - 50) / 50 * 54;
                greenNum = 50 - (compareValue - 50) / 50 * 50;
                blueNum = 234 - (compareValue - 50) / 50 * 37;
              } else {
                redNum = 2;
                greenNum = 79;
                blueNum = 228;
              }
            }

            // Calculate redNum, greenNum, blueNum based on compareValue

            const bgColor = `rgb(${redNum}, ${greenNum}, ${blueNum})`;

            return (
              <Grid item key={index} xs={2} style={{ background: `linear-gradient(145deg, RGB(${redNum}, ${greenNum}, ${blueNum}) 0%, black 100%)`, }}>
                <img src={item.Image} alt={item.Name} />
                <div>{item.Name}</div>
                <div>{item.NumListings}</div>
                <div>{item.UrlName}</div>
                <div>{item.CollectionId}</div>
              </Grid>
            );

          })}
        </Grid>
        <Grid item xs={2}>
          {group3.map((item, index) => {

            let redNum;
            let greenNum;
            let blueNum;
            let compareValue = ((sortBy == "unbox" || sortBy == "profit" || sortBy == "dropType" || sortBy == "cost") ? (
              pricingSource == "Steam" ? item.SteamROI : pricingSource == "CSFloat" ? item.CSFloatROI : pricingSource == "Tradeit" ? item.TradeitROI : pricingSource == "Buff" ? item.BuffROI : item.SkinportROI) : 
              (pricingSource == "Steam" ? sortBy == "invest1M" ? item?.Steam1MiROI : sortBy == "invest6M" ? item?.Steam6MiROI : item?.SteamiROI ?? 0 :
              pricingSource == "Buff" ? sortBy == "invest1M" ? item?.Buff1MiROI : sortBy == "invest6M" ? item?.Buff6MiROI : item?.BuffiROI ?? 0 :
                sortBy == "invest1M" ? item?.Skinport1MiROI : sortBy == "invest6M" ? item?.Skinport6MiROI : item?.SkinportiROI ?? 0))
            if (item.CollectionType == "Collection" && (sortBy == "unbox" || sortBy == "profit" || sortBy == "dropType" || sortBy == "cost")) {
              compareValue *= 0.001;
            }
            if (sortBy == "invest1M") {
              compareValue *= 12;
            }
            if (sortBy == "invest6M") {
              compareValue *= 2;
            }
            if ((sortBy == "unbox" || sortBy == "profit" || sortBy == "dropType" || sortBy == "cost")) {
              if (compareValue > 1) {
                redNum = 223;
                greenNum = 199;
                blueNum = 0;
              } else if (compareValue > 0.8) {
                redNum = 187 + Math.abs(compareValue - 0.8) / 0.2 * 36;
                greenNum = 23 + Math.abs(compareValue - 0.8) / 0.2 * 176;
                blueNum = 26 - Math.abs(compareValue - 0.8) / 0.2 * 26;
              } else if (compareValue > 0.6) {
                redNum = 158 + Math.abs(compareValue - 0.6) / 0.2 * 29;
                greenNum = 0 + Math.abs(compareValue - 0.6) / 0.2 * 23;
                blueNum = 197 - Math.abs(compareValue - 0.6) / 0.2 * 171;
              } else if (compareValue > 0.4) {
                redNum = 104 + Math.abs(compareValue - 0.4) / 0.2 * 54;
                greenNum = 50 - Math.abs(compareValue - 0.4) / 0.2 * 50;
                blueNum = 234 - Math.abs(compareValue - 0.4) / 0.2 * 37;
              } else {
                redNum = 2 + Math.abs(compareValue) / 0.4 * 102;
                greenNum = 79 - Math.abs(compareValue) / 0.4 * 29;
                blueNum = 228 + Math.abs(compareValue) / 0.4 * 6;
              }
            } else {
              if (compareValue > 400) {
                redNum = 223;
                greenNum = 199;
                blueNum = 0;
              } else if (compareValue > 200) {
                redNum = 187 + (compareValue - 200) / 200 * 36;
                greenNum = 23 + (compareValue - 200) / 200 * 176;
                blueNum = 26 - (compareValue - 200) / 200 * 26;
              } else if (compareValue > 100) {
                redNum = 158 + (compareValue - 100) / 100 * 29;
                greenNum = 0 + (compareValue - 100) / 100 * 23;
                blueNum = 197 - (compareValue - 100) / 100 * 171;
              } else if (compareValue > 50) {
                redNum = 104 + (compareValue - 50) / 50 * 54;
                greenNum = 50 - (compareValue - 50) / 50 * 50;
                blueNum = 234 - (compareValue - 50) / 50 * 37;
              } else {
                redNum = 2;
                greenNum = 79;
                blueNum = 228;
              }
            }

            // Calculate redNum, greenNum, blueNum based on compareValue

            const bgColor = `rgb(${redNum}, ${greenNum}, ${blueNum})`;

            return (
              <Grid item key={index} xs={2} style={{ background: `linear-gradient(145deg, RGB(${redNum}, ${greenNum}, ${blueNum}) 0%, black 100%)`, }}>
                <img src={item.Image} alt={item.Name} />
                <div>{item.Name}</div>
                <div>{item.NumListings}</div>
                <div>{item.UrlName}</div>
                <div>{item.CollectionId}</div>
              </Grid>
            );

          })}
        </Grid>
        <Grid item xs={2}>
          {group4.map((item, index) => {

            let redNum;
            let greenNum;
            let blueNum;
            let compareValue = ((sortBy == "unbox" || sortBy == "profit" || sortBy == "dropType" || sortBy == "cost") ? (pricingSource == "Steam" ? item.SteamROI : pricingSource == "CSFloat" ? item.CSFloatROI : pricingSource == "Tradeit" ? item.TradeitROI : pricingSource == "Buff" ? item.BuffROI : item.SkinportROI) : (pricingSource == "Steam" ? sortBy == "invest1M" ? item?.Steam1MiROI : sortBy == "invest6M" ? item?.Steam6MiROI : item?.SteamiROI ?? 0 :
              pricingSource == "Buff" ? sortBy == "invest1M" ? item?.Buff1MiROI : sortBy == "invest6M" ? item?.Buff6MiROI : item?.BuffiROI ?? 0 :
                sortBy == "invest1M" ? item?.Skinport1MiROI : sortBy == "invest6M" ? item?.Skinport6MiROI : item?.SkinportiROI ?? 0))
            if (item.CollectionType == "Collection" && (sortBy == "unbox" || sortBy == "profit" || sortBy == "dropType" || sortBy == "cost")) {
              compareValue *= 0.001;
            }
            if (sortBy == "invest1M") {
              compareValue *= 12;
            }
            if (sortBy == "invest6M") {
              compareValue *= 2;
            }
            if ((sortBy == "unbox" || sortBy == "profit" || sortBy == "dropType" || sortBy == "cost")) {
              if (compareValue > 1) {
                redNum = 223;
                greenNum = 199;
                blueNum = 0;
              } else if (compareValue > 0.8) {
                redNum = 187 + Math.abs(compareValue - 0.8) / 0.2 * 36;
                greenNum = 23 + Math.abs(compareValue - 0.8) / 0.2 * 176;
                blueNum = 26 - Math.abs(compareValue - 0.8) / 0.2 * 26;
              } else if (compareValue > 0.6) {
                redNum = 158 + Math.abs(compareValue - 0.6) / 0.2 * 29;
                greenNum = 0 + Math.abs(compareValue - 0.6) / 0.2 * 23;
                blueNum = 197 - Math.abs(compareValue - 0.6) / 0.2 * 171;
              } else if (compareValue > 0.4) {
                redNum = 104 + Math.abs(compareValue - 0.4) / 0.2 * 54;
                greenNum = 50 - Math.abs(compareValue - 0.4) / 0.2 * 50;
                blueNum = 234 - Math.abs(compareValue - 0.4) / 0.2 * 37;
              } else {
                redNum = 2 + Math.abs(compareValue) / 0.4 * 102;
                greenNum = 79 - Math.abs(compareValue) / 0.4 * 29;
                blueNum = 228 + Math.abs(compareValue) / 0.4 * 6;
              }
            } else {
              if (compareValue > 400) {
                redNum = 223;
                greenNum = 199;
                blueNum = 0;
              } else if (compareValue > 200) {
                redNum = 187 + (compareValue - 200) / 200 * 36;
                greenNum = 23 + (compareValue - 200) / 200 * 176;
                blueNum = 26 - (compareValue - 200) / 200 * 26;
              } else if (compareValue > 100) {
                redNum = 158 + (compareValue - 100) / 100 * 29;
                greenNum = 0 + (compareValue - 100) / 100 * 23;
                blueNum = 197 - (compareValue - 100) / 100 * 171;
              } else if (compareValue > 50) {
                redNum = 104 + (compareValue - 50) / 50 * 54;
                greenNum = 50 - (compareValue - 50) / 50 * 50;
                blueNum = 234 - (compareValue - 50) / 50 * 37;
              } else {
                redNum = 2;
                greenNum = 79;
                blueNum = 228;
              }
            }

            // Calculate redNum, greenNum, blueNum based on compareValue

            const bgColor = `rgb(${redNum}, ${greenNum}, ${blueNum})`;

            return (
              <Grid item key={index} xs={2} style={{ background: `linear-gradient(145deg, RGB(${redNum}, ${greenNum}, ${blueNum}) 0%, black 100%)`, }}>
                <img src={item.Image} alt={item.Name} />
                <div>{item.Name}</div>
                <div>{item.NumListings}</div>
                <div>{item.UrlName}</div>
                <div>{item.CollectionId}</div>
              </Grid>
            );

          })}
        </Grid>
        <Grid item xs={2}>
          {group5.map((item, index) => {

            let redNum;
            let greenNum;
            let blueNum;
            let compareValue = ((sortBy == "unbox" || sortBy == "profit" || sortBy == "dropType" || sortBy == "cost") ? (pricingSource == "Steam" ? item.SteamROI : pricingSource == "CSFloat" ? item.CSFloatROI : pricingSource == "Tradeit" ? item.TradeitROI : pricingSource == "Buff" ? item.BuffROI : item.SkinportROI) : (pricingSource == "Steam" ? sortBy == "invest1M" ? item?.Steam1MiROI : sortBy == "invest6M" ? item?.Steam6MiROI : item?.SteamiROI ?? 0 :
              pricingSource == "Buff" ? sortBy == "invest1M" ? item?.Buff1MiROI : sortBy == "invest6M" ? item?.Buff6MiROI : item?.BuffiROI ?? 0 :
                sortBy == "invest1M" ? item?.Skinport1MiROI : sortBy == "invest6M" ? item?.Skinport6MiROI : item?.SkinportiROI ?? 0))
            if (item.CollectionType == "Collection" && (sortBy == "unbox" || sortBy == "profit" || sortBy == "dropType" || sortBy == "cost")) {
              compareValue *= 0.001;
            }
            if (sortBy == "invest1M") {
              compareValue *= 12;
            }
            if (sortBy == "invest6M") {
              compareValue *= 2;
            }
            if ((sortBy == "unbox" || sortBy == "profit" || sortBy == "dropType" || sortBy == "cost")) {
              if (compareValue > 1) {
                redNum = 223;
                greenNum = 199;
                blueNum = 0;
              } else if (compareValue > 0.8) {
                redNum = 187 + Math.abs(compareValue - 0.8) / 0.2 * 36;
                greenNum = 23 + Math.abs(compareValue - 0.8) / 0.2 * 176;
                blueNum = 26 - Math.abs(compareValue - 0.8) / 0.2 * 26;
              } else if (compareValue > 0.6) {
                redNum = 158 + Math.abs(compareValue - 0.6) / 0.2 * 29;
                greenNum = 0 + Math.abs(compareValue - 0.6) / 0.2 * 23;
                blueNum = 197 - Math.abs(compareValue - 0.6) / 0.2 * 171;
              } else if (compareValue > 0.4) {
                redNum = 104 + Math.abs(compareValue - 0.4) / 0.2 * 54;
                greenNum = 50 - Math.abs(compareValue - 0.4) / 0.2 * 50;
                blueNum = 234 - Math.abs(compareValue - 0.4) / 0.2 * 37;
              } else {
                redNum = 2 + Math.abs(compareValue) / 0.4 * 102;
                greenNum = 79 - Math.abs(compareValue) / 0.4 * 29;
                blueNum = 228 + Math.abs(compareValue) / 0.4 * 6;
              }
            } else {
              if (compareValue > 400) {
                redNum = 223;
                greenNum = 199;
                blueNum = 0;
              } else if (compareValue > 200) {
                redNum = 187 + (compareValue - 200) / 200 * 36;
                greenNum = 23 + (compareValue - 200) / 200 * 176;
                blueNum = 26 - (compareValue - 200) / 200 * 26;
              } else if (compareValue > 100) {
                redNum = 158 + (compareValue - 100) / 100 * 29;
                greenNum = 0 + (compareValue - 100) / 100 * 23;
                blueNum = 197 - (compareValue - 100) / 100 * 171;
              } else if (compareValue > 50) {
                redNum = 104 + (compareValue - 50) / 50 * 54;
                greenNum = 50 - (compareValue - 50) / 50 * 50;
                blueNum = 234 - (compareValue - 50) / 50 * 37;
              } else {
                redNum = 2;
                greenNum = 79;
                blueNum = 228;
              }
            }

            // Calculate redNum, greenNum, blueNum based on compareValue

            const bgColor = `rgb(${redNum}, ${greenNum}, ${blueNum})`;

            return (
              <Grid item key={index} xs={2} style={{ background: `linear-gradient(145deg, RGB(${redNum}, ${greenNum}, ${blueNum}) 0%, black 100%)`, }}>
                <img src={item.Image} alt={item.Name} />
                <div>{item.Name}</div>
                <div>{item.NumListings}</div>
                <div>{item.UrlName}</div>
                <div>{item.CollectionId}</div>
              </Grid>
            );

          })}
        </Grid>

      </Grid>
    </div>
  );
};

export default CollectionValues;