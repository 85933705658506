

//Simulate Past List
import { useState, React, useEffect, useRef } from "react";
import { Card, CardMedia, LinearProgress, Button, Tabs, Tab } from "@mui/material";
import { FixedSizeList } from 'react-window';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './SimulateAnimate.css'

const wearNames = [
    'Factory New',
    'Minimal Wear',
    'Field-Tested',
    'Well-Worn',
    'Battle-Scarred',
];

const wearRanges = {
    'Factory New': 0.07,
    'Minimal Wear': 0.15,
    'Field-Tested': 0.38,
    'Well-Worn': 0.45,
    'Battle-Scarred': 1,
};

const smallWearNames = {
    'Factory New': "FN",
    'Minimal Wear': "MW",
    'Field-Tested': "FT",
    'Well-Worn': "WW",
    'Battle-Scarred': "BS",
};

const wearRangeStarts = {
    'Factory New': 0,
    'Minimal Wear': 0.07,
    'Field-Tested': 0.15,
    'Well-Worn': 0.38,
    'Battle-Scarred': 0.45,
    'magic': 1, // not used, but needed for calculation
};

const wearDropRates = {
    'Factory New': 0.03,
    'Minimal Wear': 0.24,
    'Field-Tested': 0.33,
    'Well-Worn': 0.24,
    'Battle-Scarred': 0.16,
};

const wearRangeStartDrops = {
    'Factory New': 0,
    'Minimal Wear': 0.08,
    'Field-Tested': 0.16,
    'Well-Worn': 0.39,
    'Battle-Scarred': 0.46,
};




const redRarityNums = { 'Contraband': 223, 'Covert': 187, 'Classified': 158, 'Restricted': 104, 'Mil_Spec': 2, 'Industrial': 94, 'Consumer': 176 };
const greenRarityNums = { 'Contraband': 199, 'Covert': 23, 'Classified': 0, 'Restricted': 50, 'Mil_Spec': 79, 'Industrial': 152, 'Consumer': 195 };
const blueRarityNums = { 'Contraband': 0, 'Covert': 26, 'Classified': 197, 'Restricted': 234, 'Mil_Spec': 228, 'Industrial': 217, 'Consumer': 217 };
const realWearNames = { 'FactoryNew': 'Factory New', 'MinimalWear': 'Minimal Wear', 'FieldTested': 'Field-Tested', 'WellWorn': 'Well-Worn', 'BattleScarred': 'Battle-Scarred' };
const otherRarityNames = { 'Extraordinary': 'Covert', 'Exotic': 'Classified', 'Remarkable': 'Restricted', 'High_Grade': 'Mil_Spec', 'Superior': 'Classified', 'Master': 'Covert', 'Exceptional': 'Restricted', 'Distinguished': 'Mil_Spec' }
const smallSizedWearNames = { 'Factory New': 'FN', 'Minimal Wear': 'MW', 'Field-Tested': 'FT', 'Well-Worn': 'WW', 'Battle-Scarred': 'BS' }
const smallSizedCodeWearNames = { 'FactoryNew': 'FN', 'MinimalWear': 'MW', 'FieldTested': 'FT', 'WellWorn': 'WW', 'BattleScarred': 'BS', 'StatTrak™ FactoryNew': 'ST FN', 'StatTrak™ MinimalWear': 'ST MW', 'StatTrak™ FieldTested': 'ST FT', 'StatTrak™ WellWorn': 'ST WW', 'StatTrak™ BattleScarred': 'ST BS' }
const dataColors = { "Contraband": "gold", "Covert": "#eb4b4b", "Classified": "#d32ee6", "Restricted": "#8847ff", "Mil_Spec": "#4b69ff", "Industrial": "#5e98d9", "Consumer": "#B0C3D9", "CaseCost": "white", "ROI": "grey", "Profit": "lightgrey", "iROI": "green" };
// Function to calculate the probabilities of getting each wear within the specified float range
function getWears(minFloat, maxFloat) {
    const fullFloatPercents = {};
    for (const wear of wearNames) {
        const floatRange = maxFloat - minFloat;
        const lowerRange = (wearRangeStarts[wear] || 0) - minFloat;
        const higherRange = (wearRanges[wear] || 0) - minFloat;
        const eFloatStart = lowerRange / floatRange;
        const eFloatEnd = higherRange / floatRange;
        let totalPercent = 0;
        const floatPercents = {};
        for (const wearName of wearNames) {
            const start = wearRangeStartDrops[wearName] || 0;
            const end = wearRanges[wearName] || 0;
            if (start < eFloatEnd && end > eFloatStart) {
                const realRange = end - start;
                let insideRange;
                let avgRangeFloat;
                if (eFloatStart < start && eFloatEnd > end) {
                    insideRange = end - start;
                    avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
                } else if (eFloatStart > start && eFloatEnd < end) {
                    insideRange = eFloatEnd - eFloatStart;
                    avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
                } else if (eFloatStart < start) {
                    insideRange = eFloatEnd - start;
                    avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
                } else {
                    insideRange = end - eFloatStart;
                    avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
                }
                const percentRange = insideRange / realRange;
                const percent = percentRange * wearDropRates[wearName];
                totalPercent += percent;
                floatPercents[avgRangeFloat.toFixed(4)] = percent;
            }
        }
        const floatPercentsKeys = Object.keys(floatPercents);
        let totalAvgFloat = 0;
        let totalPercents = 0;
        for (const stringPercent of floatPercentsKeys) {
            const percent = floatPercents[stringPercent];
            totalAvgFloat += (percent / totalPercent) * parseFloat(stringPercent);
            totalPercents += percent;
        }
        fullFloatPercents[wear] = totalPercents;
        // return parseFloat(totalAvgFloat.toFixed(4));
    }
    return fullFloatPercents;
}



function Simulate({ caseData, exchangeRate, currency, isMobile, isFullscreen }) {
    function formatNumber(number, isCurrency) {
        // Use the user's locale
        const userLocale = navigator.language || 'en-US';

        // Create Intl.NumberFormat with user's locale
        if (isCurrency) {
            const formatter = new Intl.NumberFormat(userLocale, {
                //   minimumFractionDigits: 1,
                style: "currency",
                currency: currency,
                maximumFractionDigits: Math.abs(number) > 1000 ? 0 : number > 100 ? 1 : 2,
            });
            return formatter.format(number);
        } else {
            const formatter = new Intl.NumberFormat(userLocale, {
                //   minimumFractionDigits: 1,
                maximumFractionDigits: Math.abs(number) > 1000 ? 0 : number > 100 ? 1 : 2,
            });
            return formatter.format(number);
        }

        // Format the number

    }

    const listRef = useRef(null);
    const [width, setWidth] = useState(0);
    const [consumer, setConsumer] = useState(0);
    const [industrial, setIndustrial] = useState(0);
    const [milspec, setMilspec] = useState(0);
    const [restricted, setRestricted] = useState(0);
    const [classified, setClassified] = useState(0);
    const [covert, setCovert] = useState(0);
    const [contraband, setContraband] = useState(0);
    const [consumerCost, setConsumerCost] = useState(0);
    const [industrialCost, setIndustrialCost] = useState(0);
    const [milspecCost, setMilspecCost] = useState(0);
    const [restrictedCost, setRestrictedCost] = useState(0);
    const [classifiedCost, setClassifiedCost] = useState(0);
    const [covertCost, setCovertCost] = useState(0);
    const [contrabandCost, setContrabandCost] = useState(0);
    const [numbers, setNumbers] = useState([]);
    const [pastNumbers, setPastNumbers] = useState([]);
    const [totalCost, setTotalCost] = useState(0);
    const [numProfit, setNumProfit] = useState(0);
    const [totalReturn, setTotalReturn] = useState(0);
    const [numRepeats, setNumRepeats] = useState(100);
    const [delay, setDelay] = useState(6);
    const [wearsFilter, setWearsFilter] = useState('');
    const [wearsFilterChances, setWearsFilterChances] = useState('');
    const [itemsFilter, setItemsFilter] = useState('');
    const [raritiesFilter, setRaritiesFilter] = useState('');
    const [filterType, setFilterType] = useState('None');
    const [numCaseSimulates, setNumCaseSimulates] = useState(1);
    const [caseSimulates, setCaseSimulates] = useState([]);
    const [caseSimulateDivs, setCaseSimulateDivs] = useState([]);
    const [globalCaseCount, setGlobalCaseCount] = useState(1);

    function createSimulations(noAnimation = false, forceItem = null) {
        if (!noAnimation) {
            const divs = [];
            const numDivs = 50
            const allNewNumbers = [];
            const animationNumber = Math.floor(Math.random() * 5) + 1;
            for (let i = 0; i < numDivs; i++) {
                const newNumbers = [];
                for (let j = 0; j < 4; j++) {
                    if (j === 2) {
                        newNumbers.push(Math.round(Math.random() * 2147483647) / 2147483647);
                    } else {
                        newNumbers.push(Math.random());
                    }
                }
                allNewNumbers.push(newNumbers)

            }
            
            if (forceItem != null) {
                allNewNumbers[animationNumber + 1] = forceItem
            }
            setCaseSimulates(allNewNumbers)

            for (let i = 0; i < numDivs; i++) {
                const margin = i * (isMobile ? 33 : 19); // Adjust the margin as needed
                divs.push(

                    <Card className={`here ${isMobile ? "mob" : ""}animatedv${animationNumber}`} style={{
                        //   paddingLeft: "18vw",
                        animationDuration: `${Math.max(0, delay - 0.4)}s`,
                        marginLeft: `${margin}vw`,
                        transition: 'box-shadow 0.3s', position: "absolute",
                        background: `linear-gradient(145deg, RGB(${otherRarityNames.hasOwnProperty(rarity(allNewNumbers[i])) ? redRarityNums[otherRarityNames[rarity(allNewNumbers[i])]] : redRarityNums[rarity(allNewNumbers[i])]}, ${otherRarityNames.hasOwnProperty(rarity(allNewNumbers[i])) ? greenRarityNums[otherRarityNames[rarity(allNewNumbers[i])]] : greenRarityNums[rarity(allNewNumbers[i])]}, ${otherRarityNames.hasOwnProperty(rarity(allNewNumbers[i])) ? blueRarityNums[otherRarityNames[rarity(allNewNumbers[i])]] : blueRarityNums[rarity(allNewNumbers[i])]}) 0%, black 100%)`,
                    }}>
                        <CardMedia
                            sx={{ height: 140, width: isMobile ? '32vw' : '18vw', backgroundSize: "contain", }}
                            image={caseData["Items"][item(allNewNumbers[i])]["ImageUrl"]}
                        />
                        <div style={{ position: 'absolute', top: isMobile ? '-4px' : 0, right: isMobile ? '4px' : '12px', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2 }}>

                            <span>{formatNumber(cost(allNewNumbers[i]) / exchangeRate, true)}</span>
                        </div>
                        {/* <div style={{ position: 'absolute', bottom: '0px', right: '12px', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2 }}>
                            <span>1 in {(1 / (caseData["PercentReceive"][rarity(allNewNumbers[i])] / (rarity(allNewNumbers[i]) !== 'Contraband' ? Object.entries(caseData["ItemCosts"][rarity(allNewNumbers[i])]).length : Object.entries(caseData["KnifeCosts"]).length))).toFixed(1)}</span>
                        </div> */}
                        <div style={{ position: 'absolute', top: isMobile ? '-4px' : '0px', left: isMobile ? '4px' : '12px', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2 }}>
                            <span>{rarity(allNewNumbers[i]) !== "Contraband" ?
                                (
                                    Object.entries(caseData['ItemCosts'][rarity(allNewNumbers[i])][item(allNewNumbers[i])]).length >
                                        Object.entries(getWears(caseData['Items'][item(allNewNumbers[i])]['FloatMin'] / 100, caseData['Items'][item(allNewNumbers[i])]['FloatMax'] / 100))
                                            .filter(([key, percent]) => percent !== 0).length + 1 ?
                                        (allNewNumbers[i][3] > 0.1 ? '' : 'ST ') :
                                        ''
                                )
                                :
                                (
                                    Object.entries(caseData['KnifeCosts'][item(allNewNumbers[i])]).length >
                                        Object.entries(getWears(caseData['Items'][item(allNewNumbers[i])]['FloatMin'] / 100, caseData['Items'][item(allNewNumbers[i])]['FloatMax'] / 100))
                                            .filter(([key, percent]) => percent !== 0).length + 1 ?
                                        (allNewNumbers[i][3] > 0.1 ? '' : 'ST ') :
                                        ''
                                )}{smallWearNames[wear(allNewNumbers[i])]}</span>
                        </div>

                    </Card>

                );
            }

            setCaseSimulateDivs(divs)
            setNumCaseSimulates((value) => value + 1);
            const elements = document.querySelectorAll('.here');

            // Remove the class responsible for the current animation
            elements.forEach((element) => {
                element.style.animationPlayState = 'paused';
                element.classList.remove('animatedv1');
                element.classList.remove('animatedv2');
                element.classList.remove('animatedv3');
                element.classList.remove('animatedv4');
                element.classList.remove('animatedv5');
                element.classList.remove('mobanimatedv1');
                element.classList.remove('mobanimatedv2');
                element.classList.remove('mobanimatedv3');
                element.classList.remove('mobanimatedv4');
                element.classList.remove('mobanimatedv5');
            });

            // Trigger a reflow to apply class changes immediately
            elements.forEach((element) => {
                void element.offsetWidth;
            });

            // Add the class with the new animation
            elements.forEach((element) => {
                element.style.animationPlayState = 'running';
                element.classList.add(`${isMobile ? "mob" : ""}animatedv${animationNumber}`);
            });
            return allNewNumbers[animationNumber + 1]
        } else {
            var newNumbers = [];
            for (let j = 0; j < 4; j++) {
                if (j === 2) {
                    newNumbers.push(Math.round(Math.random() * 2147483647) / 2147483647);
                } else {
                    newNumbers.push(Math.random());
                }
            }
            return newNumbers;
        }

        // const viewportWidth = window.innerWidth;
        // const indivItemWidth = (isMobile ? 33 : 19)
        // const pxItemWidth = viewportWidth * indivItemWidth/100;
        // const indivItemWidthNoMargin = (isMobile ? 32 : 18)
        // const pxItemWidthNoMargin = viewportWidth * indivItemWidthNoMargin/100;
        // const totalItemWidth = numDivs * pxItemWidth;
        // const animationPercent = animationNumber == 1 ? 0 : animationNumber == 2 ? 28 : animationNumber == 3 ? 67 : animationNumber == 4 ? 79 : 112;
        // const totalAnimationWidth = pxItemWidth * numDivs;

        // const middleBarSpot = width/2;
        // const pixelsMovedOver = (animationPercent / 1000) * totalAnimationWidth;
        // console.log(middleBarSpot);
        // console.log("Item number at animation, no middle: " + (animationPercent/indivItemWidth))
        // console.log("Item number at animation, w middle: " + (animationPercent + (middleBarSpot/viewportWidth * 100))/indivItemWidth)
        // const itemSelPos = Math.round((animationPercent + (middleBarSpot/viewportWidth * 100))/indivItemWidth)-2;
        // console.log(totalAnimationWidth);
        // // console.log("Pixels moved over: " + pixelsMovedOver);
        // // const numItemsBeforeMiddleBar = Math.floor(((animationPercent + (middleBarSpot/viewportWidth * 100))/indivItemWidth)-1);
        // // console.log("Items before middle: " + numItemsBeforeMiddleBar);


        // // console.log(totalAnimationWidth);
        // const itemPercent = animationPercent / indivItemWidth;
        // // const marginMoved = ind

        
    }

    useEffect(() => {
        const handleResize = () => {
            const containerWidth = listRef?.current?.clientWidth;
            if (listRef != null) {
                setWidth(containerWidth);
            }

        };

        handleResize();

        window.addEventListener('resize', handleResize);
        clearHistory();
        generateNumbers();

        return () => window.removeEventListener('resize', handleResize);

    }, []);

    const calculatedWidth = width > 0 ? width : 500;
    const simulateUntilProfit = async () => {
        let numSimulations = 999999;
        let profitFound = false;
        let localGlobalCaseCount = globalCaseCount;

        
        let localPastNumbers = [];
        const useLocalArray = delay == null || delay === '' || delay === 0 || delay === "0";
    

        let i = 0;
        while (i < numSimulations && !profitFound) {
            var newNumbers;
            if (delay != null && delay != '' && delay != 0 && delay !== "0") {
                newNumbers = createSimulations(false, null);
            } else {
                newNumbers = createSimulations(true, null);
            }
            newNumbers.push(localGlobalCaseCount);
            const testCost = cost(newNumbers);
            localGlobalCaseCount++;

            i++;
            setNumbers(newNumbers);
            if (useLocalArray) {
                localPastNumbers.push(newNumbers);
            } else {
                setPastNumbers(prevPastNumbers => [...prevPastNumbers, newNumbers]);
            }
            setTotalReturn(prevTotalReturn => prevTotalReturn + testCost);
            setTotalCost(prevTotalCost => prevTotalCost + caseData['CaseCost']);

            if (testCost > caseData['CaseCost']) {
                setNumProfit(prevTotalCost => prevTotalCost + 1);
                profitFound = true;
            }
            var testRarity = rarity(newNumbers);
            var convertedRarityName = Object.keys(otherRarityNames).includes(testRarity) ? otherRarityNames[testRarity] : testRarity;
            if (convertedRarityName == 'Consumer') {
                setConsumer(prevConsumerCount => prevConsumerCount + 1);
                setConsumerCost(prevConsumerCost => prevConsumerCost + testCost);
            } else if (convertedRarityName == 'Industrial') {
                setIndustrial(prevIndustrialCount => prevIndustrialCount + 1);
                setIndustrialCost(prevIndustrialCost => prevIndustrialCost + testCost);
            } else if (convertedRarityName == 'Mil_Spec') {
                setMilspec(prevMilspecCount => prevMilspecCount + 1);
                setMilspecCost(prevMilspecCost => prevMilspecCost + testCost);
            } else if (convertedRarityName == 'Restricted') {
                setRestricted(prevRestrictedCount => prevRestrictedCount + 1);
                setRestrictedCost(prevRestrictedCost => prevRestrictedCost + testCost);
            } else if (convertedRarityName == 'Classified') {
                setClassified(prevClassifiedCount => prevClassifiedCount + 1);
                setClassifiedCost(prevClassifiedCost => prevClassifiedCost + testCost);
            } else if (convertedRarityName == 'Covert') {
                setCovert(prevCovertCount => prevCovertCount + 1);
                setCovertCost(prevCovertCost => prevCovertCost + testCost);
            } else if (convertedRarityName == 'Contraband') {
                setContraband(prevContrabandCount => prevContrabandCount + 1);
                setContrabandCost(prevContrabandCost => prevContrabandCost + testCost);
            }
            if (delay != null && delay != '' && delay != 0 && delay !== "0") {
                await new Promise(resolve => setTimeout(resolve, delay * 1000));
            }
            
        }
        // Update the state once at the end if using local array
        if (useLocalArray) {
            newNumbers = createSimulations(false, newNumbers, localGlobalCaseCount);
            setGlobalCaseCount(localGlobalCaseCount);
            setNumbers(newNumbers);
            setPastNumbers(prevPastNumbers => {
                const updatedPastNumbers = [...prevPastNumbers, ...localPastNumbers];
                return updatedPastNumbers;
            });
        }
    };

    const simulateXTimes = async () => {
        let i = 0;
        let localGlobalCaseCount = globalCaseCount;
        let localPastNumbers = [];
        const useLocalArray = delay == null || delay === '' || delay === 0 || delay === "0";
    
        while (i < numRepeats) {
            var newNumbers;
            if (delay != null && delay != '' && delay != 0 && delay !== "0") {
                newNumbers = createSimulations();
            } else {
                newNumbers = createSimulations(true);
            }
    
            newNumbers.push(localGlobalCaseCount);
            const testCost = cost(newNumbers);
            localGlobalCaseCount++;

    
            i++;
            setNumbers(newNumbers);
            if (useLocalArray) {
                localPastNumbers.push(newNumbers);
            } else {
                setPastNumbers(prevPastNumbers => [...prevPastNumbers, newNumbers]);
            }
            setTotalReturn(prevTotalReturn => prevTotalReturn + testCost);
            setTotalCost(prevTotalCost => prevTotalCost + caseData['CaseCost']);
    
            if (testCost > caseData['CaseCost']) {
                setNumProfit(prevTotalCost => prevTotalCost + 1);
            }
            var testRarity = rarity(newNumbers);
            var convertedRarityName = Object.keys(otherRarityNames).includes(testRarity) ? otherRarityNames[testRarity] : testRarity;
            if (convertedRarityName == 'Consumer') {
                setConsumer(prevConsumerCount => prevConsumerCount + 1);
                setConsumerCost(prevConsumerCost => prevConsumerCost + testCost);
            } else if (convertedRarityName == 'Industrial') {
                setIndustrial(prevIndustrialCount => prevIndustrialCount + 1);
                setIndustrialCost(prevIndustrialCost => prevIndustrialCost + testCost);
            } else if (convertedRarityName == 'Mil_Spec') {
                setMilspec(prevMilspecCount => prevMilspecCount + 1);
                setMilspecCost(prevMilspecCost => prevMilspecCost + testCost);
            } else if (convertedRarityName == 'Restricted') {
                setRestricted(prevRestrictedCount => prevRestrictedCount + 1);
                setRestrictedCost(prevRestrictedCost => prevRestrictedCost + testCost);
            } else if (convertedRarityName == 'Classified') {
                setClassified(prevClassifiedCount => prevClassifiedCount + 1);
                setClassifiedCost(prevClassifiedCost => prevClassifiedCost + testCost);
            } else if (convertedRarityName == 'Covert') {
                setCovert(prevCovertCount => prevCovertCount + 1);
                setCovertCost(prevCovertCost => prevCovertCost + testCost);
            } else if (convertedRarityName == 'Contraband') {
                setContraband(prevContrabandCount => prevContrabandCount + 1);
                setContrabandCost(prevContrabandCost => prevContrabandCost + testCost);
            }
    
            if (delay != null && delay != '' && delay != 0 && delay !== "0") {
                await new Promise(resolve => setTimeout(resolve, delay * 1000));
            }
        }
    
        // Update the state once at the end if using local array
        if (useLocalArray) {
            newNumbers = createSimulations(false, newNumbers, localGlobalCaseCount);
            setGlobalCaseCount(localGlobalCaseCount);
            setNumbers(newNumbers);
            setPastNumbers(prevPastNumbers => {
                const updatedPastNumbers = [...prevPastNumbers, ...localPastNumbers];
                return updatedPastNumbers;
            });
        }
    };


    //Need to add stats and also add commas for 1 in 300,000
    const simulateUntilFilter = async () => {
        let numSimulations = 999999;
        let filterFound = false;
        let localGlobalCaseCount = globalCaseCount;

        let i = 0;

        let localPastNumbers = [];
        const useLocalArray = delay == null || delay === '' || delay === 0 || delay === "0";
        var newNumbers;
        while (i < numSimulations && !filterFound) {
            if (delay != null && delay != '' && delay != 0 && delay !== "0") {
                newNumbers = createSimulations();
            } else {
                newNumbers = createSimulations(true);
            }

            newNumbers.push(localGlobalCaseCount);
            const testCost = cost(newNumbers);
            localGlobalCaseCount++;


            i++;
            setNumbers(newNumbers);
            if (useLocalArray) {
                localPastNumbers.push(newNumbers);
            } else {
                setPastNumbers(prevPastNumbers => [...prevPastNumbers, newNumbers]);
            }
            setTotalReturn(prevTotalReturn => prevTotalReturn + testCost);
            setTotalCost(prevTotalCost => prevTotalCost + caseData['CaseCost']);

            if (testCost > caseData['CaseCost']) {
                setNumProfit(prevTotalCost => prevTotalCost + 1);

            }

            var testRarity = rarity(newNumbers);
            var convertedRarityName = Object.keys(otherRarityNames).includes(testRarity) ? otherRarityNames[testRarity] : testRarity;
            if (convertedRarityName == 'Consumer') {
                setConsumer(prevConsumerCount => prevConsumerCount + 1);
                setConsumerCost(prevConsumerCost => prevConsumerCost + testCost);
            } else if (convertedRarityName == 'Industrial') {
                setIndustrial(prevIndustrialCount => prevIndustrialCount + 1);
                setIndustrialCost(prevIndustrialCost => prevIndustrialCost + testCost);
            } else if (convertedRarityName == 'Mil_Spec') {
                setMilspec(prevMilspecCount => prevMilspecCount + 1);
                setMilspecCost(prevMilspecCost => prevMilspecCost + testCost);
            } else if (convertedRarityName == 'Restricted') {
                setRestricted(prevRestrictedCount => prevRestrictedCount + 1);
                setRestrictedCost(prevRestrictedCost => prevRestrictedCost + testCost);
            } else if (convertedRarityName == 'Classified') {
                setClassified(prevClassifiedCount => prevClassifiedCount + 1);
                setClassifiedCost(prevClassifiedCost => prevClassifiedCost + testCost);
            } else if (convertedRarityName == 'Covert') {
                setCovert(prevCovertCount => prevCovertCount + 1);
                setCovertCost(prevCovertCost => prevCovertCost + testCost);
            } else if (convertedRarityName == 'Contraband') {
                setContraband(prevContrabandCount => prevContrabandCount + 1);
                setContrabandCost(prevContrabandCost => prevContrabandCost + testCost);
            }

            if (raritiesFilter != '') {
                if (raritiesFilter == rarity(newNumbers)) {
                    if (itemsFilter != '') {
                        if (itemsFilter == item(newNumbers)) {
                            if (wearsFilter != '') {
                                // console.log(wear(newNumbers));
                                // console.log(wearsFilter);
                                if (realWearNames[wearsFilter.replace('StatTrak™ ', '')] == wear(newNumbers)) {
                                    if (wearsFilter.includes('StatTrak™ ')) {
                                        if (newNumbers[3] < 0.1) {
                                            filterFound = true;
                                        }

                                    } else {
                                        filterFound = true;
                                    }

                                }
                            }
                            else {
                                filterFound = true;
                            }
                        }
                    }
                    else {
                        filterFound = true;
                    }
                }
            } else {
                filterFound = true;
            }

            if (delay != null && delay != '' && delay != 0 && delay !== "0") {
                await new Promise(resolve => setTimeout(resolve, delay * 1000));
            }

        }
        if (useLocalArray) {
            setGlobalCaseCount(localGlobalCaseCount);
            newNumbers = createSimulations(false, newNumbers);
            setNumbers(newNumbers);
            setPastNumbers(prevPastNumbers => {
                const updatedPastNumbers = [...prevPastNumbers, ...localPastNumbers];
                return updatedPastNumbers;
            });
        }
    };

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
            },
        },
    };




    const generateNumbers = () => {
        
        const newNumbers = createSimulations();
        const testCost = cost(newNumbers);
        newNumbers.push(globalCaseCount);
        setGlobalCaseCount(globalCaseCount + 1);
        setNumbers(newNumbers);
        setPastNumbers(prevPastNumbers => [...prevPastNumbers, newNumbers]);
        setTotalReturn(prevTotalReturn => prevTotalReturn + testCost);
        setTotalCost(prevTotalCost => prevTotalCost + caseData['CaseCost']);
        if (testCost > caseData['CaseCost']) {
            setNumProfit(prevTotalCost => prevTotalCost + 1);
            //   break;
        }
        var testRarity = rarity(newNumbers);
        var convertedRarityName = Object.keys(otherRarityNames).includes(testRarity) ? otherRarityNames[testRarity] : testRarity;
        if (convertedRarityName == 'Consumer') {
            setConsumer(prevConsumerCount => prevConsumerCount + 1);
            setConsumerCost(prevConsumerCost => prevConsumerCost + testCost);
        } else if (convertedRarityName == 'Industrial') {
            setIndustrial(prevIndustrialCount => prevIndustrialCount + 1);
            setIndustrialCost(prevIndustrialCost => prevIndustrialCost + testCost);
        } else if (convertedRarityName == 'Mil_Spec') {
            setMilspec(prevMilspecCount => prevMilspecCount + 1);
            setMilspecCost(prevMilspecCost => prevMilspecCost + testCost);
        } else if (convertedRarityName == 'Restricted') {
            setRestricted(prevRestrictedCount => prevRestrictedCount + 1);
            setRestrictedCost(prevRestrictedCost => prevRestrictedCost + testCost);
        } else if (convertedRarityName == 'Classified') {
            setClassified(prevClassifiedCount => prevClassifiedCount + 1);
            setClassifiedCost(prevClassifiedCost => prevClassifiedCost + testCost);
        } else if (convertedRarityName == 'Covert') {
            setCovert(prevCovertCount => prevCovertCount + 1);
            setCovertCost(prevCovertCost => prevCovertCost + testCost);
        } else if (convertedRarityName == 'Contraband') {
            setContraband(prevContrabandCount => prevContrabandCount + 1);
            setContrabandCost(prevContrabandCost => prevContrabandCost + testCost);
        }
    };

    const sortNumbers = () => {
        if (pastNumbers.length > 0) {
            const lastNumber = pastNumbers.pop();
            const costs = pastNumbers.map(numbers => ({ numbers, cost: cost(numbers) }));
            costs.sort((a, b) => a.cost - b.cost);
            const sortedNumbers = costs.map(item => item.numbers);
            sortedNumbers.push(lastNumber);
            setPastNumbers(sortedNumbers);
        }
    };

    const clearHistory = () => {
        setGlobalCaseCount(1);
        setPastNumbers([]);
        setTotalCost(0);
        setTotalReturn(0);
        setNumProfit(0);
        setConsumer(0);
        setConsumerCost(0);
        setIndustrial(0);
        setIndustrialCost(0);
        setMilspec(0);
        setMilspecCost(0);
        setRestricted(0);
        setRestrictedCost(0);
        setClassified(0);
        setClassifiedCost(0);
        setCovert(0);
        setCovertCost(0);
        setContraband(0);
        setContrabandCost(0);
    };


    function rarity(numbers) {
        var testNumber = numbers[0];
        for (const [rarityName, rarityChance] of Object.entries(caseData['PercentReceive'])) {
            if (testNumber > rarityChance) {
                testNumber -= rarityChance;
            } else {
                return rarityName;
            }
        }
    }

    function item(numbers) {
        var finalRarity = rarity(numbers)
        var whichItem = numbers[1];

        if (finalRarity != 'Contraband') {
            var itemsPossible = Object.entries(caseData['ItemCosts'][finalRarity]);
            var numItems = itemsPossible.length;
            var chancePerItem = 1 / numItems;
            for (const [itemName, itemStats] of itemsPossible) {
                if (whichItem > chancePerItem) {
                    whichItem -= chancePerItem;
                } else {
                    return itemName;
                }
            }
        } else {
            var itemsPossible = Object.entries(caseData['KnifeCosts']);
            var numItems = itemsPossible.length;
            var totalNumItems = caseData["TotalKnifeChance"];
            for (const [itemName, itemStats] of itemsPossible) {
                var thisItemChance = ((caseData["Items"][itemName]["ModifiedDropChance"] ?? 1)) / totalNumItems;
                if (whichItem > thisItemChance) {
                    whichItem -= thisItemChance;
                } else {
                    return itemName;

                }

            }
        }
    }

    function getWears(minFloat, maxFloat) {
        let fullFloatPercents = {};
        for (let wear of wearNames) {
            let floatRange = maxFloat - minFloat;
            let lowerRange = (wearRangeStarts[wear] ?? 0) - minFloat;
            let higherRange = (wearRanges[wear] ?? 0) - minFloat;
            let eFloatStart = lowerRange / floatRange;
            let eFloatEnd = higherRange / floatRange;
            let totalPercent = 0;
            let floatPercents = {};
            for (let wearName of wearNames) {
                let start = wearRangeStartDrops[wearName] ?? 0;
                let end = wearRanges[wearName] ?? 0;
                if (start < eFloatEnd && end > eFloatStart) {
                    let realRange = end - start;
                    let insideRange;
                    let avgRangeFloat;
                    if (eFloatStart < start && eFloatEnd > end) {
                        insideRange = end - start;
                        avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
                    } else if (eFloatStart > start && eFloatEnd < end) {
                        insideRange = eFloatEnd - eFloatStart;
                        avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
                    } else if (eFloatStart < start) {
                        insideRange = eFloatEnd - start;
                        avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
                    } else {
                        insideRange = end - eFloatStart;
                        avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
                    }
                    let percentRange = insideRange / realRange;
                    let percent = percentRange * wearDropRates[wearName];
                    totalPercent += percent;
                    floatPercents[avgRangeFloat.toFixed(4)] = percent;
                }
            }
            let floatPercentsKeys = Object.keys(floatPercents);
            let totalAvgFloat = 0;
            let totalPercents = 0;
            for (let stringPercent of floatPercentsKeys) {
                let percent = floatPercents[stringPercent];
                totalAvgFloat += (percent / totalPercent) * parseFloat(stringPercent);
                totalPercents += percent;
            }
            fullFloatPercents[wear] = (totalPercents);
        }
        return fullFloatPercents;
    }

    function wear(numbers) {
        var minFloat = caseData['Items'][item(numbers)]['FloatMin'];
        var maxFloat = caseData['Items'][item(numbers)]['FloatMax'];
        if (minFloat == null) {
            return null;
        }
        var wearChances = getWears(minFloat / 100, maxFloat / 100);
        var whichWear = numbers[2];
        for (const [wearName, wearChance] of Object.entries(wearChances)) {

            if (whichWear > wearChance) {
                whichWear -= wearChance;
            } else {
                return wearName;

            }
        }
    }

    function caseNum(numbers) {
        return numbers[4];
    }

    function cost(numbers) {
        var testWear;
        if (wear(numbers) !== null) {
            testWear = wear(numbers);
        } else if (caseData["KnifeCosts"] !== null) {
            if (item(numbers) in caseData["KnifeCosts"]) {
                if (Object.keys(caseData['KnifeCosts'][item(numbers)] || {}).length > 1) {
                    testWear = Object.keys(caseData['KnifeCosts'][item(numbers)]).length > 1 ? "Normal" : 'totalItemValue';
                } else {
                    testWear = 'totalItemValue';
                }
            } else {
                testWear = 'totalItemValue';
            }
        } else {
            testWear = 'totalItemValue';
        }

        var testRarity = rarity(numbers);
        if (testRarity !== 'Contraband') {
            if (Object.keys(caseData['ItemCosts'][testRarity][item(numbers)]).length > 1) {
                if (numbers[3] < 0.1 && Object.keys(caseData['ItemCosts'][testRarity][item(numbers)]).includes('StatTrak™ ' + testWear.replace(' ', '').replace('-', ''))) {
                    return parseFloat(caseData['ItemCosts'][testRarity][item(numbers)]['StatTrak™ ' + testWear.replace(' ', '').replace('-', '')].toFixed(2));
                } else {
                    return parseFloat(caseData['ItemCosts'][testRarity][item(numbers)][testWear.replace(' ', '').replace('-', '')].toFixed(2));
                }
            } else {
                return parseFloat(caseData['ItemCosts'][testRarity][item(numbers)]['totalItemValue'].toFixed(2));
            }
        } else {
            if (Object.keys(caseData['KnifeCosts'][item(numbers)]).length > 1) {
                if (numbers[3] < 0.1 && Object.keys(caseData['KnifeCosts'][item(numbers)]).includes('StatTrak™ ' + testWear.replace(' ', '').replace('-', '')) && Object.keys(caseData['KnifeCosts'][item(numbers)]).length > 1) {
                    return parseFloat(caseData['KnifeCosts'][item(numbers)]['StatTrak™ ' + testWear.replace(' ', '').replace('-', '')].toFixed(2));
                } else {
                    return parseFloat(caseData['KnifeCosts'][item(numbers)][testWear.replace(' ', '').replace('-', '')].toFixed(2));
                }
            } else {
                return parseFloat(caseData['KnifeCosts'][item(numbers)]['totalItemValue'].toFixed(2));
            }

        }

        // var rarity = rarity(numbers)


    }

    const setRarities = (event) => {
        setRaritiesFilter(
            // On autofill we get a stringified value.
            event.target.value
        );
        setItemsFilter(
            ''
        );
        setWearsFilter(
            ''
        );
        setWearsFilterChances(
            ''
        );
    };

    const setItems = (event) => {
        setItemsFilter(
            // On autofill we get a stringified value.
            event.target.value
        );
        setWearsFilter(
            ''
        );
        let testWears = getWears(caseData['Items'][event.target.value]['FloatMin'] / 100, caseData['Items'][event.target.value]['FloatMax'] / 100);
        setWearsFilterChances(
            testWears
        );
    };

    const setWears = (event) => {
        setWearsFilter(
            // On autofill we get a stringified value.
            event.target.value
        );

    };


    return (
        <div>
            <div style={{ display: 'flex', position: 'relative', flexDirection: 'row', width: isFullscreen ? "80%" : '100%', marginLeft: 'auto', marginRight: 'auto', maxWidth: '100%', height: '140px', overflowX: 'hidden', marginBottom: "16px" }}>
                <div className={'indicatorLine'}></div>
                {caseSimulateDivs}
            </div>
            {isMobile ? <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div style={{ margin: 'auto', paddingBottom: '12px', justifyContent: 'center' }}>
                    <Button variant="contained" onClick={generateNumbers} style={{ display: filterType === 'None' ? 'initial' : 'none', backgroundColor: '#bb171a', color: 'white', fontSize: '1.1rem', paddingLeft: '12px', paddingRight: '12px' }}>Simulate</Button>
                    <p style={{ display: filterType === 'Profit' ? 'block' : 'none', textAlign: 'center' }}><Button variant="contained" onClick={simulateUntilProfit} style={{ backgroundColor: '#bb171a', color: 'white', fontSize: '1.1rem', paddingLeft: '12px', paddingRight: '12px' }}>Simulate Until A Profit</Button><br />1 in {(1 / caseData['Profit']).toFixed(1)} Unbox</p>
                    <Button variant="contained" onClick={simulateXTimes} style={{ display: filterType === 'XTimes' ? 'initial' : 'none', backgroundColor: '#bb171a', color: 'white', fontSize: '1.1rem', paddingLeft: '12px', paddingRight: '12px' }}>Simulate X Times</Button>
                    <p style={{ display: filterType === 'Filter' ? 'block' : 'none', textAlign: 'center' }}><Button variant="contained" onClick={simulateUntilFilter} style={{ backgroundColor: '#bb171a', color: 'white', fontSize: '1.1rem', paddingLeft: '12px', paddingRight: '12px' }}>Simulate Until Desired Item</Button><br />1 in {((1 / (caseData["Items"]?.[itemsFilter]?.["ModifiedDropChance"] ?? 1)) / ((raritiesFilter != '' ? caseData['PercentReceive'][raritiesFilter] : 1) / (itemsFilter != '' ? (((raritiesFilter.includes("Contraband") ? caseData["TotalKnifeChance"] : Object.keys(caseData['ItemCosts'][raritiesFilter]).length))) : 1) * (wearsFilter != '' ? ((wearsFilterChances[realWearNames[wearsFilter.replace('StatTrak™ ', '')]] * (wearsFilter.includes('StatTrak™') ? 0.1 : 0.9))) : 1))).toFixed(1)} Unbox</p>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', alignSelf: 'center', marginBottom: '8px', }}>
                    <FormControl style={{ width: '200px', marginRight: '10px' }}>
                        <InputLabel id="demo-simple-select-label">Repeat Simulations</InputLabel>
                        <Select
                            value={filterType}
                            label="Repeat Simulations"
                            onChange={(event) => {
                                setFilterType(event.target.value);
                            }}
                        >
                            <MenuItem value="None">None</MenuItem>
                            <MenuItem value="Profit">Until A Profit</MenuItem>
                            <MenuItem value="XTimes">X Times</MenuItem>
                            <MenuItem value="Filter">Until Filter</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField id="outlined-basic" label="Delay (s)" variant="outlined" value={delay} type="number" onChange={(event) => {
                        setDelay(event.target.value);
                    }} style={{ width: '200px' }} />
                </div></div> : <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', alignSelf: 'center', marginBottom: '8px', }}>
                <FormControl style={{ width: '200px', marginRight: '10px' }}>
                    <InputLabel id="demo-simple-select-label">Repeat Simulations</InputLabel>
                    <Select
                        value={filterType}
                        label="Repeat Simulations"
                        onChange={(event) => {
                            setFilterType(event.target.value);
                        }}
                    >
                        <MenuItem value="None">None</MenuItem>
                        <MenuItem value="Profit">Until A Profit</MenuItem>
                        <MenuItem value="XTimes">X Times</MenuItem>
                        <MenuItem value="Filter">Until Filter</MenuItem>
                    </Select>
                </FormControl>
                <Button variant="contained" onClick={generateNumbers} style={{ display: filterType === 'None' ? 'initial' : 'none', backgroundColor: '#bb171a', color: 'white' }}>Simulate</Button>
                <p style={{ display: filterType === 'Profit' ? 'initial' : 'none', textAlign: 'center' }}><Button variant="contained" onClick={simulateUntilProfit} style={{ backgroundColor: '#bb171a', color: 'white' }}>Simulate Until A Profit</Button><br />1 in {(1 / caseData['Profit']).toFixed(1)} Unbox</p>
                <Button variant="contained" onClick={simulateXTimes} style={{ display: filterType === 'XTimes' ? 'initial' : 'none', backgroundColor: '#bb171a', color: 'white' }}>Simulate X Times</Button>
                <p style={{ display: filterType === 'Filter' ? 'initial' : 'none', textAlign: 'center' }}><Button variant="contained" onClick={simulateUntilFilter} style={{ backgroundColor: '#bb171a', color: 'white' }}>Simulate Until Desired Item</Button><br />1 in {formatNumber((1 / (caseData["Items"]?.[itemsFilter]?.["ModifiedDropChance"] ?? 1)) / ((raritiesFilter != '' ? caseData['PercentReceive'][raritiesFilter] : 1) / (itemsFilter != '' ? (((raritiesFilter.includes("Contraband") ? caseData["TotalKnifeChance"] : Object.keys(caseData['ItemCosts'][raritiesFilter]).length))) : 1) * (wearsFilter != '' ? ((wearsFilterChances[realWearNames[wearsFilter.replace('StatTrak™ ', '')]] * (wearsFilter.includes('StatTrak™') ? 0.1 : 0.9))) : 1)))} Unbox</p>
                <TextField id="outlined-basic" label="Delay (s)" variant="outlined" value={delay} type="number" onChange={(event) => {
                    setDelay(event.target.value);
                }} style={{ width: '200px' }} />
            </div>}
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', alignSelf: 'center', flexWrap: 'wrap' }}>
                <FormControl sx={{ m: 1, width: 300, display: filterType === 'Filter' ? 'inherit' : 'none' }}>
                    <InputLabel id="demo-single-select-label">Rarity</InputLabel>
                    <Select
                        value={raritiesFilter}
                        onChange={setRarities}
                        input={<OutlinedInput label="Rarity" />}
                        renderValue={(selected) => selected.replace('_', '-').replace('Contraband', 'Gloves/Knives') + ' - ' + (caseData['PercentReceive'][selected] * 100).toFixed(2) + '%'}
                    >
                        {Object.entries(caseData['PercentReceive']).map(([name, percent]) => (
                            <MenuItem key={name} value={name}>
                                <ListItemText primary={name.replace('_', '-').replace('Contraband', 'Gloves/Knives') + ' - ' + (percent * 100).toFixed(2) + '%'} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl sx={{ m: 1, width: 500, display: filterType === 'Filter' ? 'inherit' : 'none' }}>
                    <InputLabel id="demo-single-select-label">Item</InputLabel>
                    <Select
                        labelId="demo-single-select-label"
                        id="demo-single-select"
                        value={itemsFilter}
                        onChange={setItems}
                        input={<OutlinedInput label="Item" />}
                        renderValue={(selected) => <div style={{ minHeight: 'auto', display: 'flex', alignItems: 'center' }}><img style={{ width: '64px' }} src={caseData['Items'][selected]['ImageUrl']} /><ListItemText style={{ textAlign: 'center' }}>{selected.replace('Sticker | ', '') + ' - ' + ((((caseData["Items"]?.[itemsFilter]?.["ModifiedDropChance"] ?? 1)) / (raritiesFilter.includes("Contraband") ? caseData["TotalKnifeChance"] : Object.keys(caseData['ItemCosts'][raritiesFilter]).length)) * 100).toFixed(2)}%</ListItemText></div>}
                    >
                        {raritiesFilter != '' ? (
                            Object.entries(raritiesFilter.includes("Contraband") ? caseData['KnifeCosts'] : caseData['ItemCosts'][raritiesFilter.replace('-', '_')]).sort((a, b) => b[1]['totalItemValue'] - a[1]['totalItemValue']).map(([name, percent]) => (

                                <MenuItem key={name} value={name} style={{ minHeight: 'auto', display: 'flex', alignItems: 'center' }}>
                                    <img src={caseData['Items'][name]['ImageUrl']} style={{ width: '64px' }}></img>
                                    <ListItemText style={{ textAlign: 'center' }} primary={name.replace('Sticker | ', '') + ' - ' + ((((caseData["Items"][name]["ModifiedDropChance"] ?? 1)) / (raritiesFilter.includes("Contraband") ? caseData["TotalKnifeChance"] : Object.keys(caseData['ItemCosts'][raritiesFilter]).length)) * 100).toFixed(2) + '%'} secondary={(raritiesFilter.includes("Contraband") ? formatNumber(caseData['KnifeCosts'][name]['totalItemValue'] / exchangeRate, true) : formatNumber(caseData['ItemCosts'][raritiesFilter][name]['totalItemValue'] / exchangeRate, true))} />
                                </MenuItem>
                            ))
                        ) : null}


                    </Select>
                </FormControl>
                <FormControl sx={{ m: 1, width: 300, display: filterType === 'Filter' ? 'inherit' : 'none' }}>
                    <InputLabel id="demo-single-select-label">Wear</InputLabel>
                    <Select
                        labelId="demo-single-select-label"
                        id="demo-single-select"
                        value={wearsFilter}
                        onChange={setWears}
                        input={<OutlinedInput label="Wear" />}
                        renderValue={(selected) => smallSizedCodeWearNames[selected] + ' - ' + ((wearsFilterChances[realWearNames[selected.replace('StatTrak™ ', '')]] * (selected.includes('StatTrak™') ? 0.1 : 0.9)) * 100).toFixed(2) + '%'}
                    >
                        {itemsFilter != '' ? (
                            Object.entries(raritiesFilter.includes("Contraband") ? caseData['KnifeCosts'][itemsFilter] : caseData['ItemCosts'][raritiesFilter.replace('-', '_')][itemsFilter]).map(([name, percent]) => (
                                // check if name is equal to "totalItemValue"
                                name !== "totalItemValue" && (
                                    <MenuItem key={name.replace('_', '-').replace('Contraband', 'Gloves/Knives')} value={name.replace('_', '-').replace('Contraband', 'Gloves/Knives')}>
                                        <ListItemText primary={smallSizedCodeWearNames[name] + ' - ' + ((wearsFilterChances[realWearNames[name.replace('StatTrak™ ', '')]] * (name.includes('StatTrak™') ? 0.1 : 0.9)) * 100).toFixed(2) + '%'} secondary={formatNumber(percent / exchangeRate, true)} />
                                    </MenuItem>
                                )
                            ))
                        ) : null}


                    </Select>
                </FormControl>
                <TextField id="outlined-basic" label="Repeats" variant="outlined" value={numRepeats} type="number" onChange={(event) => {

                        setNumRepeats(event.target.value);


                }} style={{ width: '200px', margin: '8px', display: filterType === 'XTimes' ? 'inherit' : 'none' }} />
            </div>


            <div style={{ display: 'flex', alignItems: 'center', fontSize: isMobile ? '0.9rem' : '1rem', justifyContent: 'center' }}><h3 style={{ minWidth: '220px' }}>Previous Simulations - {formatNumber((pastNumbers.length - 1) < 0 ? 0: (pastNumbers.length - 1), false)}</h3> <div style={{ marginLeft: 'auto', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}><Button variant="outlined" onClick={sortNumbers} sx={{ marginBottom: '4px', fontSize: isMobile ? '0.7rem' : 'initial' }}>Sort</Button> <Button variant="outlined" color="error" onClick={clearHistory} sx={{ marginBottom: '4px', fontSize: isMobile ? '0.7rem' : 'initial' }}>Clear</Button></div></div>
            <div ref={listRef} style={{ height: pastNumbers.length > 1 ? "initial" : "0px", }}>

                <FixedSizeList
                    height={isMobile ? 220 : 255}
                    itemCount={pastNumbers.length - 1}
                    itemSize={isMobile ? 160 : 240}

                    layout="horizontal"
                    width={calculatedWidth}
                >
                    {({ index, style }) => {
                        const pastNumber = pastNumbers[pastNumbers.length - 2 - index];

                        return (
                            <div style={style}>
                                <Card style={{
                                    background: `linear-gradient(145deg, RGB(${otherRarityNames.hasOwnProperty(rarity(pastNumber)) ? redRarityNums[otherRarityNames[rarity(pastNumber)]] : redRarityNums[rarity(pastNumber)]}, ${otherRarityNames.hasOwnProperty(rarity(pastNumber)) ? greenRarityNums[otherRarityNames[rarity(pastNumber)]] : greenRarityNums[rarity(pastNumber)]}, ${otherRarityNames.hasOwnProperty(rarity(pastNumber)) ? blueRarityNums[otherRarityNames[rarity(pastNumber)]] : blueRarityNums[rarity(pastNumber)]}) 0%, black 100%)`,

                                    width: isMobile ? 150 : 230, paddingLeft: 4,
                                    position: 'relative', transition: 'box-shadow 0.3s',
                                }}>
                                    <CardMedia
                                        sx={{ height: 140, backgroundSize: "contain", }}
                                        image={caseData["Items"][item(pastNumber)]["ImageUrl"]}
                                    />
                                    <div style={{ position: 'absolute', bottom: '1px', right: '6px', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2, fontSize: '12px' }}>

                                    <span>#{formatNumber(caseNum(pastNumber), false)}</span>
                                    </div>
                                    <div style={{ position: 'absolute', top: '-2px', right: '6px', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2 }}>

                                        <span>{formatNumber(cost(pastNumber) / exchangeRate, true)}</span>
                                    </div>
                                    {/* <div style={{ position: 'absolute', bottom: '0px', right: '6px', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2 }}>
                                    </div> */}

                                </Card>
                                {isMobile ? <div>
                                    <h5 style={{ lineHeight: 0, marginBottom: '0' }}>
                                        {
                                            rarity(pastNumber) !== "Contraband" ?
                                                (
                                                    Object.entries(caseData['ItemCosts'][rarity(pastNumber)][item(pastNumber)]).length >
                                                        Object.entries(getWears(caseData['Items'][item(pastNumber)]['FloatMin'] / 100, caseData['Items'][item(pastNumber)]['FloatMax'] / 100))
                                                            .filter(([key, percent]) => percent !== 0).length + 1 ?
                                                        (pastNumber[3] > 0.1 ? '' : 'StatTrak™ ') :
                                                        ''
                                                ) + item(pastNumber).split(' | ')[0]
                                                :
                                                (
                                                    Object.entries(caseData['KnifeCosts'][item(pastNumber)]).length >
                                                        Object.entries(getWears(caseData['Items'][item(pastNumber)]['FloatMin'] / 100, caseData['Items'][item(pastNumber)]['FloatMax'] / 100))
                                                            .filter(([key, percent]) => percent !== 0).length + 1 ?
                                                        (pastNumber[3] > 0.1 ? '' : 'StatTrak™ ') :
                                                        ''
                                                ) + item(pastNumber).split(' | ')[0]
                                        }
                                    </h5>
                                    <h4 style={{ lineHeight: 1, marginTop: '8px', marginBottom: '0px' }}>{item(pastNumber).split(' | ')[1]}</h4>
                                    <h5 style={{ lineHeight: 0.2, marginTop: '8px' }}>{wear(pastNumber)}</h5>
                                </div> :
                                    <div>
                                        <h4 style={{ lineHeight: 0 }}>
                                            {
                                                rarity(pastNumber) !== "Contraband" ?
                                                    (
                                                        Object.entries(caseData['ItemCosts'][rarity(pastNumber)][item(pastNumber)]).length >
                                                            Object.entries(getWears(caseData['Items'][item(pastNumber)]['FloatMin'] / 100, caseData['Items'][item(pastNumber)]['FloatMax'] / 100))
                                                                .filter(([key, percent]) => percent !== 0).length + 1 ?
                                                            (pastNumber[3] > 0.1 ? '' : 'StatTrak™ ') :
                                                            ''
                                                    ) + item(pastNumber).split(' | ')[0]
                                                    :
                                                    (
                                                        Object.entries(caseData['KnifeCosts'][item(pastNumber)]).length >
                                                            Object.entries(getWears(caseData['Items'][item(pastNumber)]['FloatMin'] / 100, caseData['Items'][item(pastNumber)]['FloatMax'] / 100))
                                                                .filter(([key, percent]) => percent !== 0).length + 1 ?
                                                            (pastNumber[3] > 0.1 ? '' : 'StatTrak™ ') :
                                                            ''
                                                    ) + item(pastNumber).split(' | ')[0]
                                            }
                                        </h4>
                                        <h3 style={{ lineHeight: 0 }}>{item(pastNumber).split(' | ')[1]}</h3>
                                        <h4 style={{ lineHeight: 0.2 }}>{wear(pastNumber)}</h4>
                                    </div>}
                            </div>
                        );
                    }}
                </FixedSizeList>

            </div>
            <div style={{ display: 'flex', paddingBottom: '8px', paddingTop: '8px', flexDirection: isMobile ? 'column' : 'row' }}>

                {/* <TableContainer component={Paper}> */}
                {isMobile ?
                    <Table aria-label="simple table" style={{ flex: 2, fontSize: '8px' }}>
                        <TableBody>
                            <TableRow
                            // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row" sx={{ fontSize: '13px', lineHeight: 1, padding: '10px' }} align="center">
                                    Simulations
                                </TableCell>
                                <TableCell component="th" scope="row" sx={{ fontSize: '13px', lineHeight: 1, padding: '10px' }} align="center">
                                    Cost
                                </TableCell>
                                <TableCell component="th" scope="row" sx={{ fontSize: '13px', lineHeight: 1, padding: '10px' }} align="center">
                                    Return
                                </TableCell>


                            </TableRow>
                            <TableRow
                            // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell sx={{ fontSize: '13px', lineHeight: 1, padding: '10px' }} align="center">{formatNumber(pastNumbers.length, false)}</TableCell>
                                <TableCell sx={{ fontSize: '13px', lineHeight: 1, padding: '10px' }} align="center">{formatNumber(totalCost / exchangeRate, true)}</TableCell>
                                <TableCell sx={{ fontSize: '13px', lineHeight: 1, padding: '10px' }} align="center">{formatNumber(totalReturn / exchangeRate, true)}</TableCell>

                            </TableRow>
                            <TableRow
                            // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >

                                <TableCell component="th" scope="row" sx={{ fontSize: '13px', lineHeight: 1, padding: '10px' }} align="center">
                                    Profit / Loss
                                </TableCell>
                                <TableCell component="th" scope="row" sx={{ fontSize: '13px', lineHeight: 1, padding: '10px' }} align="center">
                                    Unboxing ROI
                                </TableCell>
                                <TableCell component="th" scope="row" sx={{ fontSize: '13px', lineHeight: 1, padding: '10px' }} align="center">
                                    Profit Rate
                                </TableCell>

                            </TableRow>
                            <TableRow
                            // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >

                                <TableCell sx={{ fontSize: '13px', lineHeight: 1, padding: '10px' }} align="center">{(totalReturn - totalCost) > 0 ? "" : "-"}{formatNumber(Math.abs(totalReturn - totalCost) / exchangeRate, true)}</TableCell>
                                <TableCell sx={{ fontSize: '13px', lineHeight: 1, padding: '10px' }} align="center">{formatNumber(totalReturn / totalCost * 100)}%</TableCell>
                                <TableCell sx={{ fontSize: '13px', lineHeight: 1, padding: '10px' }} align="center">{formatNumber(numProfit / pastNumbers.length * 100)}%</TableCell>
                            </TableRow>

                        </TableBody>
                    </Table> :
                    <Table aria-label="simple table" style={{ flex: 2, width: '18vw' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell align="right">Simulated</TableCell>
                                <TableCell align="right" style={{color: 'rgba(255, 255, 255, 0.4)'}}>Expected</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    Simulations:
                                </TableCell>
                                <TableCell align="right">{formatNumber(pastNumbers.length, false)}</TableCell>
                                <TableCell align="right" style={{color: 'rgba(255, 255, 255, 0.4)'}}>N/A</TableCell>
                            </TableRow>
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    Cost:
                                </TableCell>
                                <TableCell align="right">{formatNumber(totalCost / exchangeRate, true)}</TableCell>
                                <TableCell align="right" style={{color: 'rgba(255, 255, 255, 0.4)'}}>N/A</TableCell>
                            </TableRow>
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    Return:
                                </TableCell>
                                <TableCell align="right">{(totalReturn) > 0 ? "" : "-"}{formatNumber(Math.abs(totalReturn) / exchangeRate, true)}</TableCell>
                                <TableCell align="right" style={{color: 'rgba(255, 255, 255, 0.4)'}}>{(totalCost * caseData["ROI"]) > 0 ? "" : "-"}{formatNumber(Math.abs(totalCost * caseData["ROI"]) / exchangeRate, true)}</TableCell>
                            </TableRow>
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    Profit/Loss:
                                </TableCell>
                                <TableCell align="right">{formatNumber((totalReturn - totalCost) / exchangeRate, true)}</TableCell>
                                <TableCell align="right" style={{color: 'rgba(255, 255, 255, 0.4)'}}>{formatNumber(((totalCost * caseData["ROI"]) - totalCost) / exchangeRate, true)}</TableCell>
                            
                            </TableRow>
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    Unboxing ROI:
                                </TableCell>
                                <TableCell align="right">{formatNumber(totalReturn / totalCost * 100)}%</TableCell>
                                <TableCell align="right" style={{color: 'rgba(255, 255, 255, 0.4)'}}>{formatNumber(caseData["ROI"] * 100)}%</TableCell>
                            </TableRow>
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    Profit Rate:
                                </TableCell>
                                <TableCell align="right">{formatNumber(numProfit / pastNumbers.length * 100)}%</TableCell>
                                <TableCell align="right" style={{color: 'rgba(255, 255, 255, 0.4)'}}>{formatNumber(caseData["Profit"] * 100)}%</TableCell>
                            </TableRow>

                        </TableBody>
                    </Table>
                }
                <div style={{ flex: 0.2 }}></div>
                {/* </TableContainer> */}

                {/* <div style={{ flex: 3 }}>
                    <div style={{ margin: 'auto', width: '18vw' }}>
                        <Card style={{
                            paddingLeft: 0,
                            position: 'relative', transition: 'box-shadow 0.3s',
                            background: `linear-gradient(145deg, RGB(${otherRarityNames.hasOwnProperty(rarity(numbers)) ? redRarityNums[otherRarityNames[rarity(numbers)]] : redRarityNums[rarity(numbers)]}, ${otherRarityNames.hasOwnProperty(rarity(numbers)) ? greenRarityNums[otherRarityNames[rarity(numbers)]] : greenRarityNums[rarity(numbers)]}, ${otherRarityNames.hasOwnProperty(rarity(numbers)) ? blueRarityNums[otherRarityNames[rarity(numbers)]] : blueRarityNums[rarity(numbers)]}) 0%, black 100%)`,
                        }}>
                            <CardMedia
                                sx={{ height: 140, backgroundSize: "contain", }}
                                image={caseData["Items"][item(numbers)]["ImageUrl"]}
                            />
                            <div style={{ position: 'absolute', top: 0, right: '12px', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2 }}>

                                <span>{currencySymbol}{(cost(numbers) / exchangeRate).toFixed(2)}</span>
                            </div>

                        </Card>
                        <h4 style={{ lineHeight: 0 }}>
                            {
                                rarity(numbers) !== "Contraband" ?
                                    (
                                        Object.entries(caseData['ItemCosts'][rarity(numbers)][item(numbers)]).length >
                                            Object.entries(getWears(caseData['Items'][item(numbers)]['FloatMin'] / 100, caseData['Items'][item(numbers)]['FloatMax'] / 100))
                                                .filter(([key, percent]) => percent !== 0).length + 1 ?
                                            (numbers[3] > 0.1 ? '' : 'StatTrak™ ') :
                                            ''
                                    ) + item(numbers).split(' | ')[0]
                                    :
                                    (
                                        Object.entries(caseData['KnifeCosts'][item(numbers)]).length >
                                            Object.entries(getWears(caseData['Items'][item(numbers)]['FloatMin'] / 100, caseData['Items'][item(numbers)]['FloatMax'] / 100))
                                                .filter(([key, percent]) => percent !== 0).length + 1 ?
                                            (numbers[3] > 0.1 ? '' : 'StatTrak™ ') :
                                            ''
                                    ) + item(numbers).split(' | ')[0]
                            }
                        </h4>
                        <h3 style={{ lineHeight: 0.5 }}>{item(numbers).split(' | ')[1]}</h3>
                        <h4 style={{ lineHeight: 0.5 }}>{wear(numbers)}</h4>

                    </div>
                </div> */}
                {isMobile && <h3 style={{ textAlign: 'center', width: '100%', marginTop: '32px', lineHeight: 0 }}>Summary</h3>}
                {isMobile ? <Table aria-label="simple table" style={{ flex: 2, width: '100%', marginTop: '4px' }}>

                    <TableBody>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            key={"raritiesSim"}
                        >
                            {Object.entries(caseData['PercentReceive']).map(([name, percent]) => {
                                var convertedRarityName = Object.keys(otherRarityNames).includes(name) ? otherRarityNames[name] : name;
                                return <TableCell component="th" scope="row" align="center" sx={{ fontSize: '12px', lineHeight: 1, padding: '10px', color: dataColors[convertedRarityName] }}>
                                    {name.replace('_', '-').replace('Contraband', 'Gloves / Knives')}
                                </TableCell>
                            })
                            }
                        </TableRow>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            key={"numRaritiesSim"}
                        >
                            {Object.entries(caseData['PercentReceive']).map(([name, percent]) => {
                                var convertedRarityName = Object.keys(otherRarityNames).includes(name) ? otherRarityNames[name] : name;
                                return <TableCell sx={{ fontSize: '12px', lineHeight: 1, padding: '10px' }} align="center">{convertedRarityName == 'Contraband' ? contraband : convertedRarityName == 'Covert' ? covert : convertedRarityName == 'Classified' ? classified : convertedRarityName == 'Restricted' ? restricted : convertedRarityName == 'Mil_Spec' ? milspec : convertedRarityName == 'Industrial' ? industrial : convertedRarityName == 'Consumer' ? consumer : 0}</TableCell>

                            })

                            }
                        </TableRow>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            key={"valuesRaritiesSim"}
                        >
                            {Object.entries(caseData['PercentReceive']).map(([name, percent]) => {
                                var convertedRarityName = Object.keys(otherRarityNames).includes(name) ? otherRarityNames[name] : name;
                                return <TableCell sx={{ fontSize: '12px', lineHeight: 1, padding: '10px' }} align="center">{convertedRarityName == 'Contraband' ? formatNumber(contrabandCost / exchangeRate, true) : convertedRarityName == 'Covert' ? formatNumber(covertCost / exchangeRate, true) : convertedRarityName == 'Classified' ? formatNumber(classifiedCost / exchangeRate, true) : convertedRarityName == 'Restricted' ? formatNumber(restrictedCost / exchangeRate, true) : convertedRarityName == 'Mil_Spec' ? formatNumber(milspecCost / exchangeRate, true) : convertedRarityName == 'Industrial' ? formatNumber(industrialCost / exchangeRate, true) : convertedRarityName == 'Consumer' ? formatNumber(consumerCost / exchangeRate, true) : 0}</TableCell>

                            })

                            }
                        </TableRow>
                    </TableBody>
                </Table> : <Table aria-label="simple table" style={{ flex: 2, width: '18vw' }}>
                    <TableHead>
                        <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row" >
                                    
                                </TableCell>
                                <TableCell colSpan={2} align="right">Simulated</TableCell>
                                <TableCell colSpan={2} align="right" style={{color: 'rgba(255, 255, 255, 0.4)'}}>Expected</TableCell>
                            </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.entries(caseData['PercentReceive']).map(([name, percent]) => {
                            var convertedRarityName = Object.keys(otherRarityNames).includes(name) ? otherRarityNames[name] : name;
                            return <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                key={name}
                            >
                                <TableCell component="th" scope="row" style={{ color: dataColors[convertedRarityName] }}>
                                    {name.replace('_', '-').replace('Contraband', 'Gloves / Knives')}
                                </TableCell>
                                <TableCell align="right">{convertedRarityName == 'Contraband' ? contraband : convertedRarityName == 'Covert' ? covert : convertedRarityName == 'Classified' ? classified : convertedRarityName == 'Restricted' ? restricted : convertedRarityName == 'Mil_Spec' ? milspec : convertedRarityName == 'Industrial' ? industrial : convertedRarityName == 'Consumer' ? consumer : 0}</TableCell>
                                <TableCell align="right">{convertedRarityName == 'Contraband' ? formatNumber(contrabandCost / exchangeRate, true) : convertedRarityName == 'Covert' ? formatNumber(covertCost / exchangeRate, true) : convertedRarityName == 'Classified' ? formatNumber(classifiedCost / exchangeRate, true) : convertedRarityName == 'Restricted' ? formatNumber(restrictedCost / exchangeRate, true) : convertedRarityName == 'Mil_Spec' ? formatNumber(milspecCost / exchangeRate, true) : convertedRarityName == 'Industrial' ? formatNumber(industrialCost / exchangeRate, true) : convertedRarityName == 'Consumer' ? formatNumber(consumerCost / exchangeRate, true) : 0}</TableCell>
                                <TableCell align="right" style={{color: 'rgba(255, 255, 255, 0.4)'}}>{convertedRarityName == 'Contraband' ? formatNumber(pastNumbers.length * caseData["PercentReceive"][name]) : convertedRarityName == 'Covert' ? formatNumber(pastNumbers.length * caseData["PercentReceive"][name]) : convertedRarityName == 'Classified' ? formatNumber(pastNumbers.length * caseData["PercentReceive"][name]) : convertedRarityName == 'Restricted' ? formatNumber(pastNumbers.length * caseData["PercentReceive"][name]) : convertedRarityName == 'Mil_Spec' ? formatNumber(pastNumbers.length * caseData["PercentReceive"][name]) : convertedRarityName == 'Industrial' ? formatNumber(pastNumbers.length * caseData["PercentReceive"][name]) : convertedRarityName == 'Consumer' ? formatNumber(pastNumbers.length * caseData["PercentReceive"][name]) : 0}</TableCell>
                                <TableCell align="right" style={{color: 'rgba(255, 255, 255, 0.4)'}}>{convertedRarityName == 'Contraband' ? formatNumber((pastNumbers.length * caseData["PercentReceive"][name] * caseData["RarityValue"][name]) / exchangeRate, true) : convertedRarityName == 'Covert' ? formatNumber((pastNumbers.length * caseData["PercentReceive"][name] * caseData["RarityValue"][name]) / exchangeRate, true) : convertedRarityName == 'Classified' ? formatNumber((pastNumbers.length * caseData["PercentReceive"][name] * caseData["RarityValue"][name]) / exchangeRate, true) : convertedRarityName == 'Restricted' ? formatNumber((pastNumbers.length * caseData["PercentReceive"][name] * caseData["RarityValue"][name]) / exchangeRate, true) : convertedRarityName == 'Mil_Spec' ? formatNumber((pastNumbers.length * caseData["PercentReceive"][name] * caseData["RarityValue"][name]) / exchangeRate, true) : convertedRarityName == 'Industrial' ? formatNumber((pastNumbers.length * caseData["PercentReceive"][name] * caseData["RarityValue"][name]) / exchangeRate, true) : convertedRarityName == 'Consumer' ? formatNumber((pastNumbers.length * caseData["PercentReceive"][name] * caseData["RarityValue"][name]) / exchangeRate, true) : 0}</TableCell>
                            
                            </TableRow>
                        })
                        }
                    </TableBody>
                </Table>}
            </div>

            {/* <button onClick={generateNumbers}>Generate Numbers</button> */}

            {numbers.map((number, index) => (
                //   <li key={index}>{number}</li>

                <div key={index}>
                    {index === 2 ? (rarity(numbers) !== "Contraband" ? (Object.entries(caseData['ItemCosts'][rarity(numbers)][item(numbers)]).length === 1) : (Object.entries(caseData['KnifeCosts'][item(numbers)]).length === 1)) ? <div></div> :
                        <div /> :
                        index === 3 ? rarity(numbers) !== "Contraband" ? (Object.entries(caseData['ItemCosts'][rarity(numbers)][item(numbers)]).length <= Object.entries(getWears(caseData['Items'][item(numbers)]['FloatMin'] / 100, caseData['Items'][item(numbers)]['FloatMax'] / 100)).filter(([key, percent]) => percent !== 0).length + 1) ? <div></div> : <div><Tabs aria-label="basic tabs example" sx={{ borderRadius: '4px', }} value={numbers[3] > 0.1 ? 'Normal' : 'ST'}>
                            <Tab key='ST' label="ST" sx={{ borderRadius: '4px', background: `linear-gradient(145deg, RGB(${redRarityNums["Covert"]}, ${greenRarityNums["Covert"]}, ${blueRarityNums["Covert"]}) 0%, black 100%)`, width: (0.1 * 100) + '%', padding: '0', maxWidth: '100%', minWidth: '0', overflow: 'visible', paddingLeft: 0, }} value='ST' />
                            <Tab key='Normal' label="Normal" sx={{ borderRadius: '4px', background: `linear-gradient(145deg, RGB(${otherRarityNames.hasOwnProperty(rarity(numbers)) ? redRarityNums[otherRarityNames[rarity(numbers)]] : redRarityNums[rarity(numbers)]}, ${otherRarityNames.hasOwnProperty(rarity(numbers)) ? greenRarityNums[otherRarityNames[rarity(numbers)]] : greenRarityNums[rarity(numbers)]}, ${otherRarityNames.hasOwnProperty(rarity(numbers)) ? blueRarityNums[otherRarityNames[rarity(numbers)]] : blueRarityNums[rarity(numbers)]}) 0%, black 100%)`, width: (0.9 * 100) + '%', padding: '0', maxWidth: '100%', minWidth: '0', overflow: 'visible', paddingLeft: 0 }} value='Normal' />
                        </Tabs></div> : (Object.entries(caseData['KnifeCosts'][item(numbers)]).length <= Object.entries(getWears(caseData['Items'][item(numbers)]['FloatMin'] / 100, caseData['Items'][item(numbers)]['FloatMax'] / 100)).filter(([key, percent]) => percent !== 0).length + 1) ? <div></div> : <div><Tabs aria-label="basic tabs example" sx={{ borderRadius: '4px', }} value={numbers[3] > 0.1 ? 'Normal' : 'ST'}>
                            <Tab key='ST' label="ST" sx={{ borderRadius: '4px', background: `linear-gradient(145deg, RGB(${redRarityNums["Covert"]}, ${greenRarityNums["Covert"]}, ${blueRarityNums["Covert"]}) 0%, black 100%)`, width: (0.1 * 100) + '%', padding: '0', maxWidth: '100%', minWidth: '0', overflow: 'visible', paddingLeft: 0 }} value='ST' />
                            <Tab key='Normal' label="Normal" sx={{ borderRadius: '4px', background: `linear-gradient(145deg, RGB(${otherRarityNames.hasOwnProperty(rarity(numbers)) ? redRarityNums[otherRarityNames[rarity(numbers)]] : redRarityNums[rarity(numbers)]}, ${otherRarityNames.hasOwnProperty(rarity(numbers)) ? greenRarityNums[otherRarityNames[rarity(numbers)]] : greenRarityNums[rarity(numbers)]}, ${otherRarityNames.hasOwnProperty(rarity(numbers)) ? blueRarityNums[otherRarityNames[rarity(numbers)]] : blueRarityNums[rarity(numbers)]}) 0%, black 100%)`, width: (0.9 * 100) + '%', padding: '0', maxWidth: '100%', minWidth: '0', overflow: 'visible', paddingLeft: 0 }} value='Normal' />

                        </Tabs></div> : /*<LinearProgress variant="determinate" value={number * 100} sx={{ mt: '10px' }} />*/ <div />}

                    {index === 0 ? <Tabs aria-label="basic tabs example" value={rarity(numbers)} sx={{ borderRadius: '4px', }}>
                        {Object.entries(caseData['PercentReceive']).map(([key, percent]) => (
                            <Tab key={key} sx={{
                                width: (percent * 100) + '%', padding: '0', maxWidth: '100%', minWidth: '0', overflow: 'visible',
                                position: 'relative', transition: 'box-shadow 0.3s',
                                background: `linear-gradient(145deg, RGB(${otherRarityNames.hasOwnProperty(key) ? redRarityNums[otherRarityNames[key]] : redRarityNums[key]}, ${otherRarityNames.hasOwnProperty(key) ? greenRarityNums[otherRarityNames[key]] : greenRarityNums[key]}, ${otherRarityNames.hasOwnProperty(key) ? blueRarityNums[otherRarityNames[key]] : blueRarityNums[key]}) 0%, black 100%)`,
                            }} value={key} />
                        ))}
                    </Tabs> : <div></div>}
                    {index === 1 ? rarity(numbers) !== "Contraband" ? <Tabs aria-label="basic tabs example" value={item(numbers)} sx={{ borderRadius: '4px', }}>
                        {Object.entries(caseData['ItemCosts'][rarity(numbers)]).map(([key, percent]) => (
                            <Tab key={key} sx={{
                                width: (100 / Object.entries(caseData['ItemCosts'][rarity(numbers)]).length) + '%', padding: '0', maxWidth: '100%', minWidth: '0', overflow: 'visible', paddingLeft: 0, padding: '0', overflow: 'visible',
                                position: 'relative', transition: 'box-shadow 0.3s',
                                background: `url(${caseData["Items"][key]["ImageUrl"]}) center/contain no-repeat, linear-gradient(145deg, RGB(${otherRarityNames.hasOwnProperty(rarity(numbers)) ? redRarityNums[otherRarityNames[rarity(numbers)]] : redRarityNums[rarity(numbers)]}, ${otherRarityNames.hasOwnProperty(rarity(numbers)) ? greenRarityNums[otherRarityNames[rarity(numbers)]] : greenRarityNums[rarity(numbers)]}, ${otherRarityNames.hasOwnProperty(rarity(numbers)) ? blueRarityNums[otherRarityNames[rarity(numbers)]] : blueRarityNums[rarity(numbers)]}) 0%, black 100%)`,
                            }} value={key}>

                            </Tab>
                        ))}
                    </Tabs> : <Tabs aria-label="basic tabs example" value={item(numbers)} sx={{ borderRadius: '4px', }}>
                        {Object.entries(caseData['KnifeCosts']).map(([key, percent]) => (
                            percent === 0 ? <div></div> : <Tab key={key} sx={{ width: (100 / Object.entries(caseData['KnifeCosts']).length) + '%', padding: '0', maxWidth: '100%', minWidth: '0', overflow: 'visible', background: `url(${caseData["Items"][key]["ImageUrl"]}) center/contain no-repeat, linear-gradient(145deg, RGB(${otherRarityNames.hasOwnProperty(rarity(numbers)) ? redRarityNums[otherRarityNames[rarity(numbers)]] : redRarityNums[rarity(numbers)]}, ${otherRarityNames.hasOwnProperty(rarity(numbers)) ? greenRarityNums[otherRarityNames[rarity(numbers)]] : greenRarityNums[rarity(numbers)]}, ${otherRarityNames.hasOwnProperty(rarity(numbers)) ? blueRarityNums[otherRarityNames[rarity(numbers)]] : blueRarityNums[rarity(numbers)]}) 0%, black 100%)` }} value={key} />
                        ))}
                    </Tabs> : <div></div>}
                    {index === 2 ? <div style={{ display: caseData['Items'][item(numbers)]['FloatMin'] == null ? 'none' : 'block' }}> <Tabs sx={{ borderRadius: '4px', }} aria-label="basic tabs example" value={wear(numbers)}>
                        {Object.entries(getWears(caseData['Items'][item(numbers)]['FloatMin'] / 100, caseData['Items'][item(numbers)]['FloatMax'] / 100)).map(([key, percent]) => (
                            percent === 0 ? <div></div> : <Tab key={key} label={smallSizedWearNames[key]} sx={{ width: (percent * 100) + '%', padding: '0', maxWidth: '100%', minWidth: '0', overflow: 'visible', paddingLeft: 0, background: `linear-gradient(145deg, RGB(${otherRarityNames.hasOwnProperty(rarity(numbers)) ? redRarityNums[otherRarityNames[rarity(numbers)]] : redRarityNums[rarity(numbers)]}, ${otherRarityNames.hasOwnProperty(rarity(numbers)) ? greenRarityNums[otherRarityNames[rarity(numbers)]] : greenRarityNums[rarity(numbers)]}, ${otherRarityNames.hasOwnProperty(rarity(numbers)) ? blueRarityNums[otherRarityNames[rarity(numbers)]] : blueRarityNums[rarity(numbers)]}) 0%, black 100%)`, }} value={key} />
                        ))}

                    </Tabs>{<LinearProgress style={{ display: caseData['Items'][item(numbers)]['FloatMin'] == null ? 'none' : 'block' }} variant="determinate" value={number * 100} sx={{}} />}</div> : <div></div>}
                </div>
            ))}

        </div>
    );
}

export default Simulate;