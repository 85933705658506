import { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
import { Backdrop, Card, Radio, FormControl, InputLabel, Select, CardMedia, MenuItem, Container, Grid, Paper, ThemeProvider, Typography, Table, TableRow, TableCell, TableContainer, IconButton } from "@mui/material";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell, Legend } from 'recharts';
import CloseIcon from '@mui/icons-material/Close';
import { TableHead, TableBody, } from '@mui/material';
import { skinportLink, buffLink, steamLink, csfloatLink, tradeitLink, skinsearchLink } from './allLinks.js';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { AttachMoney } from "@mui/icons-material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SortIcon from '@mui/icons-material/Sort';
import { Link } from 'react-router-dom';

const redRarityNums = { 'Contraband': 223, 'Covert': 187, 'Classified': 158, 'Restricted': 104, 'Mil_Spec': 2, 'Industrial': 94, 'Consumer': 176 };
const greenRarityNums = { 'Contraband': 199, 'Covert': 23, 'Classified': 0, 'Restricted': 50, 'Mil_Spec': 79, 'Industrial': 152, 'Consumer': 195 };
const blueRarityNums = { 'Contraband': 0, 'Covert': 26, 'Classified': 197, 'Restricted': 234, 'Mil_Spec': 228, 'Industrial': 217, 'Consumer': 217 };
const realWearNames = { 'FactoryNew': 'Factory New', 'MinimalWear': 'Minimal Wear', 'FieldTested': 'Field-Tested', 'WellWorn': 'Well-Worn', 'BattleScarred': 'Battle-Scarred' };
const smallWearNames = { 'Factory New': 'FN', 'Minimal Wear': 'MW', 'Field-Tested': 'FT', 'Well-Worn': 'WW', 'Battle-Scarred': 'BS' }


const wearNames = [
  'Factory New',
  'Minimal Wear',
  'Field-Tested',
  'Well-Worn',
  'Battle-Scarred',
];

const wearRanges = {
  'Factory New': 0.07,
  'Minimal Wear': 0.15,
  'Field-Tested': 0.38,
  'Well-Worn': 0.45,
  'Battle-Scarred': 1,
};

const wearRangeStarts = {
  'Factory New': 0,
  'Minimal Wear': 0.07,
  'Field-Tested': 0.15,
  'Well-Worn': 0.38,
  'Battle-Scarred': 0.45,
  'magic': 1, // not used, but needed for calculation
};

const wearDropRates = {
  'Factory New': 0.03,
  'Minimal Wear': 0.24,
  'Field-Tested': 0.33,
  'Well-Worn': 0.24,
  'Battle-Scarred': 0.16,
};

const wearRangeStartDrops = {
  'Factory New': 0,
  'Minimal Wear': 0.08,
  'Field-Tested': 0.16,
  'Well-Worn': 0.39,
  'Battle-Scarred': 0.46,
};

const otherRarityNames = { 'Extraordinary': 'Covert', 'Exotic': 'Classified', 'Remarkable': 'Restricted', 'High_Grade': 'Mil_Spec', 'Superior': 'Classified', 'Master': 'Covert', 'Exceptional': 'Restricted', 'Distinguished': 'Mil_Spec' }

// function skinportLink(item, wear, stat, isRare) {
//   var statName = 'StatTrak™ ';
//   var knifeName = '★ ';
//   item = item.replace('/', '-');
//   if (wear) {
//     if (stat) {
//       return 'https://skinport.com/market?item=' + ((isRare ? knifeName : '') + statName + item.replace("Autograph Capsule | ", "").replace("Sticker | ", "") + ' (' + wear + ')').replaceAll(" ", "+").replaceAll("%2F", "-");
//     } else {
//       return 'https://skinport.com/market?item=' + ((isRare ? knifeName : '') + item.replace("Autograph Capsule | ", "").replace("Sticker | ", "") + ' (' + wear + ')').replaceAll(" ", "+").replaceAll("%2F", "-");
//     }
//   } else {
//     if (stat) {
//       return 'https://skinport.com/market?item=' + ((isRare ? knifeName : '') + statName + item.replace("Autograph Capsule | ", "").replace("Sticker | ", "")).replaceAll(" ", "+").replaceAll("%2F", "-");
//     } else {
//       return 'https://skinport.com/market?item=' + ((isRare ? knifeName : '') + item.replace("Autograph Capsule | ", "").replace("Sticker | ", "")).replaceAll(" ", "+").replaceAll("%2F", "-");
//     }
//     // return 'https://steamcommunity.com/market/listings/730/' + (isRare ? knifeName : '') + item;
//   }
// }






function getWears(minFloat, maxFloat) {
  let fullFloatPercents = {};
  for (let wear of wearNames) {
    let floatRange = maxFloat - minFloat;
    let lowerRange = (wearRangeStarts[wear] ?? 0) - minFloat;
    let higherRange = (wearRanges[wear] ?? 0) - minFloat;
    let eFloatStart = lowerRange / floatRange;
    let eFloatEnd = higherRange / floatRange;
    let totalPercent = 0;
    let floatPercents = {};
    for (let wearName of wearNames) {
      let start = wearRangeStartDrops[wearName] ?? 0;
      let end = wearRanges[wearName] ?? 0;
      if (start < eFloatEnd && end > eFloatStart) {
        let realRange = end - start;
        let insideRange;
        let avgRangeFloat;
        if (eFloatStart < start && eFloatEnd > end) {
          insideRange = end - start;
          avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
        } else if (eFloatStart > start && eFloatEnd < end) {
          insideRange = eFloatEnd - eFloatStart;
          avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
        } else if (eFloatStart < start) {
          insideRange = eFloatEnd - start;
          avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
        } else {
          insideRange = end - eFloatStart;
          avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
        }
        let percentRange = insideRange / realRange;
        let percent = percentRange * wearDropRates[wearName];
        totalPercent += percent;
        floatPercents[avgRangeFloat.toFixed(4)] = percent;
      }
    }
    let floatPercentsKeys = Object.keys(floatPercents);
    let totalAvgFloat = 0;
    let totalPercents = 0;
    for (let stringPercent of floatPercentsKeys) {
      let percent = floatPercents[stringPercent];
      totalAvgFloat += (percent / totalPercent) * parseFloat(stringPercent);
      totalPercents += percent;
    }
    fullFloatPercents[wear] = (totalPercents);
  }
  return fullFloatPercents;
}



function CaseItems({ caseData, exchangeRate, currency, isMobile, investType }) {

  var storedSort = localStorage.getItem('sortByItems');
  storedSort = (["invest", "cost"].includes(storedSort == "invest6m" ? "invest" : storedSort == "invest1m" ? "invest" : storedSort) ? storedSort : "cost");
  // console.log(storedSort);
  const [sortBy, setSortBy] = useState(storedSort || "cost");
  const storedSortDirection = (localStorage.getItem('sortDirectionItems') ?? "true") == "true";
  const [sortDirection, setSortDirection] = useState(storedSortDirection ?? true);
  const storedRaritySortDirection = (localStorage.getItem('raritySortDirectionItems') ?? "true") == "true";
  const [raritySortDirection, setRaritySortDirection] = useState(storedRaritySortDirection ?? true);
  const [sortedItemCosts, setSortedItemCosts] = useState([]);
  const [sortedKnifeCosts, setSortedKnifeCosts] = useState([]);
  const [displayRarities, setDisplayRarities] = useState({ 'Contraband': true, 'Covert': true, 'Classified': true, 'Restricted': true, 'Mil_Spec': true, 'Industrial': true, 'Consumer': true });

  useEffect(() => {
    let sortedData = Object.entries(caseData["ItemCosts"]);
    if (raritySortDirection) {
      sortedData = sortedData.reverse();
    }
    sortedData = sortedData.map(([rarity, items]) => {

      let sortedItems = Object.entries(items).map(([name, values]) => {
        const ROI = caseData?.["ItemROIs"]?.[rarity]?.[name]?.["totalItemValue"]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0;
        return { name, ROI, ...values };
      });

      if (sortBy === "invest") {
        sortedItems.sort((a, b) => {
          const comparisonResult = sortDirection ? b.ROI - a.ROI : a.ROI - b.ROI;
          return comparisonResult;
        });
      }

      if (sortBy === "cost") {
        sortedItems.sort((a, b) => {
          const comparisonResult = sortDirection ? b.totalItemValue - a.totalItemValue : a.totalItemValue - b.totalItemValue;
          return comparisonResult;
        });
      }

      return { rarity, items: sortedItems };
    });

    // Handle KnifeCosts without rarities
    if (caseData["KnifeCosts"] != null) {
      let sortedKnifeData = Object.entries(caseData["KnifeCosts"]).map(([name, values]) => {
        const ROI = caseData?.["KnifeROIs"]?.[name]?.["totalItemValue"]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0;
        return { name, ROI, ...values };
      });

      if (sortBy === "invest") {
        sortedKnifeData.sort((a, b) => {
          const comparisonResult = sortDirection ? b.ROI - a.ROI : a.ROI - b.ROI;
          return comparisonResult;
        });
      }

      if (sortBy === "cost") {
        sortedKnifeData.sort((a, b) => {
          const comparisonResult = sortDirection ? b.totalItemValue - a.totalItemValue : a.totalItemValue - b.totalItemValue;
          return comparisonResult;
        });
      }
      setSortedKnifeCosts(sortedKnifeData);
    }



    setSortedItemCosts(sortedData);

  }, [caseData, sortDirection, sortBy, investType, raritySortDirection]);

  const handleSort = (event, sort) => {
    setSortBy(sort.props.value);
    console.log(sort.props.value);
    localStorage.setItem('sortByItems', sort.props.value);
    // setSelectedItem(item);
    // setSelectedRarity(rarity)
  };

  const handleSortDirection = (sortTest) => {
    setSortDirection(sortTest);
    localStorage.setItem('sortDirectionItems', sortTest.toString());
    // setSelectedItem(item);
    // setSelectedRarity(rarity)
  };

  const handleRaritySortDirection = (sortTest) => {
    setRaritySortDirection(sortTest);
    localStorage.setItem('raritySortDirectionItems', sortTest.toString());
    // setSelectedItem(item);
    // setSelectedRarity(rarity)
  };

  function formatNumber(number, isCurrency) {
    // Use the user's locale
    const userLocale = navigator.language || 'en-US';

    // Create Intl.NumberFormat with user's locale
    if (isCurrency) {
      const formatter = new Intl.NumberFormat(userLocale, {
        //   minimumFractionDigits: 1,
        style: "currency",
        currency: currency,
        maximumFractionDigits: number > 1000 ? 0 : number > 100 ? 1 : 2,
      });
      return formatter.format(number);
    } else {
      const formatter = new Intl.NumberFormat(userLocale, {
        //   minimumFractionDigits: 1,
        maximumFractionDigits: number > 1000 ? 0 : number > 100 ? 1 : 2,
      });
      return formatter.format(number);
    }

    // Format the number

  }
  // const { caseData } = useParams();
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedRarity, setSelectedRarity] = useState(null);

  const handleItemClick = (item, rarity) => {
    // console.log('here:');
    // console.log(item);
    // console.log(rarity);
    if (rarity !== "Contraband") {
      if (Object.keys(caseData.ItemCosts[rarity][item]).length > 1) {
        setSelectedItem(item);
        setSelectedRarity(rarity)
      } else {
        var itemLink = caseData["IsCSFloat"] ? csfloatLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item) : caseData["ISTradeit"] ? tradeitLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item) : caseData["IsBuff"] ? buffLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item) : caseData["IsSkinport"] ? skinportLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, caseData) : steamLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item);
        window.open(itemLink, '_blank')
      }
    } else {
      setSelectedItem(item);
      setSelectedRarity(rarity)
    }

  };

  const handleRarityClick = (rarity) => {
    var displayRarity = Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity;
    displayRarities[displayRarity] = !displayRarities[displayRarity];
    setDisplayRarities({ ...displayRarities });

  };

  const handleCloseOverlay = () => {
    setSelectedItem(null);
    setSelectedRarity(null);
  };


  // function CustomizedLabel(x, y, fill, value) {

  //   // render () {
  //   // const {x, y, fill, value} = this.props;
  //   return <text
  //     x={x}
  //     y={y}

  //     fontSize='16'
  //     fontFamily='sans-serif'
  //     fill={fill}
  //     textAnchor="start">{value}%</text>
  //   // }
  // };

  const CustomizedLabel = (props) => {
    const { x, y, fill, value } = props;
    return (
      isMobile ?
        <text x={x + 32} y={y - 10} textAnchor="middle" fontSize={16} dominantBaseline="middle" fill={"white"}>{(value * 100).toFixed(2)}%</text> :
        <text x={x + 66} y={y - 10} textAnchor="middle" fontSize={16} dominantBaseline="middle" fill={"white"}>{(value * 100).toFixed(2)}%</text>
    );
  };


  function Overlay({ item, rarity, onClose }) {
    const itemWears = getWears(caseData['Items'][item]['FloatMin'] / 100, caseData['Items'][item]['FloatMax'] / 100);
    // const data = Object.keys(Object.entries(itemWears).filter(([key, value]) => value !== 0)).map((key) => ({
    //   name: key,
    //   value: itemWears[key]
    // }));
    const data = Object.entries(itemWears)
      .filter(([key, value]) => value !== 0)
      .map(([key, value], index) => ({
        name: key,
        value: itemWears[key]
      }));

    const handlePaperClick = (event) => {
      // Stop propagation to prevent closing when clicking inside the paper area
      event.stopPropagation();
    };

    return (
      isMobile ?
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
          onClick={onClose}
        >
          <Paper sx={{ p: 1, width: '95vw', maxHeight: '95vh', position: 'relative', overflowY: 'scroll' }} onClick={handlePaperClick}>
            <IconButton
              sx={{ position: 'absolute', top: 8, right: 8, zIndex: 1 }}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
            <div style={{ display: "flex", flexDirection: "column", width: '100%' }}>


              <Typography variant="h5" gutterBottom style={{ margin: 'auto', width: '100%', textAlign: 'center' }}>
                {item}
              </Typography>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Typography variant="h6" gutterBottom style={{ margin: 'auto', width: '100%', textAlign: 'center' }}>
                  1 in {formatNumber((1 / (caseData["Items"][item]["ModifiedDropChance"] ?? 1)) / (caseData["PercentReceive"][rarity] / (rarity === "Contraband" ? caseData["TotalKnifeChance"] : Object.keys(caseData["ItemCosts"][rarity]).length)))}
                </Typography>
                <Typography variant="h6" gutterBottom style={{
                  margin: 'auto', width: '100%', textAlign: 'center',
                  color:
                    (caseData?.ISTradeit ?
                      (rarity === "Contraband" ?
                        (typeof caseData?.KnifeEstimates?.[item] === "object" ?
                          (Object.entries(caseData?.KnifeEstimates?.[item]).filter(([key, value]) => value === true && key.includes("StatTrak")).length * 0.1 +
                            Object.entries(caseData?.KnifeEstimates?.[item]).filter(([key, value]) => value === true && !key.includes("StatTrak")).length <
                            Object.entries(caseData?.KnifeEstimates?.[item]).filter(([key, value]) => value === false && key.includes("StatTrak")).length * 0.1 +
                            Object.entries(caseData?.KnifeEstimates?.[item]).filter(([key, value]) => value === false && !key.includes("StatTrak")).length) :
                          (caseData?.KnifeEstimates?.[item] === true)
                        ) ? "white" : "rgb(131 131 131)" :
                        (typeof caseData?.ItemEstimates?.[rarity]?.[item] === "object" ?
                          (Object.entries(caseData?.ItemEstimates?.[rarity]?.[item]).filter(([key, value]) => value === true && key.includes("StatTrak")).length * 0.1 +
                            Object.entries(caseData?.ItemEstimates?.[rarity]?.[item]).filter(([key, value]) => value === true && !key.includes("StatTrak")).length <
                            Object.entries(caseData?.ItemEstimates?.[rarity]?.[item]).filter(([key, value]) => value === false && key.includes("StatTrak")).length * 0.1 +
                            Object.entries(caseData?.ItemEstimates?.[rarity]?.[item]).filter(([key, value]) => value === false && !key.includes("StatTrak")).length) :
                          (caseData?.ItemEstimates?.[rarity]?.[item] === true)
                        ) ? "white" : "rgb(131 131 131)"
                      ) : "white")
                }}>
                  {formatNumber((rarity === "Contraband" ? caseData["KnifeCosts"][item]['totalItemValue'] : caseData["ItemCosts"][rarity][item]['totalItemValue']) / exchangeRate, true)} AVG
                </Typography>
              </div>
            </div>
            {/* <Typography variant="h6" gutterBottom>
        {item.description}
      </Typography> */}
            {/* Add any additional content you want to display in the overlay */}
            {/* <BarChart width={500} height={500} data={itemWears} layout="horizontal">
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis type="number" />
      <YAxis dataKey="name" type="category" />
      <Tooltip />
      <Bar dataKey="value" fill="#8884d8" label={CustomizedLabel}>
          {Object.entries(itemWears).map((entry, index) => (
          <Cell key={`cell-${index}`} fill={"red"} />
          ))}
      </Bar>
      </BarChart> */}
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <img src={caseData["Items"][item]["ImageUrl"]} style={{ height: '150px', margin: 'auto' }} />
              <BarChart style={{ margin: 'auto' }} width={64 * data.length} height={120} data={data} layout="horizontal" barCategoryGap={1} margin={{ top: 30, right: 0, left: 0, bottom: 0 }}>
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <YAxis type="number" hide />
                <XAxis dataKey="name" type="category" tickFormatter={(tick) => smallWearNames[tick]} stroke="RGBA(255,255,255,0.8)" />
                {/* <Tooltip /> */}
                <Bar dataKey="value" fill="#8884d8" label={CustomizedLabel}>
                  {/* {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={"red"} />
          ))} */}
                </Bar>
              </BarChart>
            </div>
            <TableContainer component={Paper} >
              <Table sx={{ width: '100%' }} aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    {Object.entries((rarity == "Contraband" ? caseData["KnifeCosts"][item] : caseData["ItemCosts"][rarity][item])).filter(([key, value]) => key.includes("StatTrak")).length > 0 &&
                      <TableCell component="th" scope="row" sx={{ color: "rgb(207, 106, 50)", textAlign: 'center', margin: '0', fontSize: '0.9rem', lineHeight: '0.8' }}>
                        ST (10x Rarer)
                      </TableCell>
                    }
                    <TableCell component="th" scope="row" sx={{ textAlign: 'center', fontSize: '0.9rem', lineHeight: '0.8' }}>
                      Normal
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries((rarity == "Contraband" ? caseData["KnifeCosts"][item] : caseData["ItemCosts"][rarity][item])).filter(([key, value]) => !key.includes("StatTrak") && !key.includes('totalItemValue')).map(([key, value]) => (
                    <TableRow key={key} style={{ width: '128px', textAlign: 'center' }}>
                      <TableCell key={key} component="th" scope="row" sx={{ textAlign: 'center', fontSize: '0.9rem', lineHeight: '0.8' }} >

                        {smallWearNames[realWearNames[key]]}
                      </TableCell>

                      {Object.entries((rarity == "Contraband" ? caseData["KnifeCosts"][item] : caseData["ItemCosts"][rarity][item])).filter(([key, value]) => key.includes("StatTrak")).length > 0 &&
                        <TableCell key={key} component="th" scope="row" sx={{
                          cursor: 'pointer', textAlign: 'center', fontSize: '0.9rem', lineHeight: '0.8',
                        }}>
                          <a style={{ color: rarity == "Contraband" ? (caseData?.KnifeEstimates?.[item]?.[key] ?? false) ? "rgb(131 131 131)" : "rgb(207, 106, 50)" : (caseData?.ItemEstimates?.[rarity]?.[item]?.[key] ?? false) ? "rgb(131 131 131)" : "rgb(207, 106, 50)", textDecoration: 'none' }} href={caseData["IsCSFloat"] ? csfloatLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], true, rarity === 'Contraband') : caseData["ISTradeit"] ? tradeitLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], true, rarity === 'Contraband') : caseData["IsBuff"] ? buffLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], true, rarity === 'Contraband') : caseData["IsSkinport"] ? skinportLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, caseData, true, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : steamLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], true, rarity === 'Contraband')} target="_blank">

                            <div style={{ textDecoration: 'underline', }}>{formatNumber((rarity == "Contraband" ? caseData["KnifeCosts"][item] : caseData["ItemCosts"][rarity][item])["StatTrak\u2122 " + key] / exchangeRate, true)}</div>
                            <div style={{ display: 'flex', fontSize: '11px', lineHeight: '0.4', marginTop: 'auto', marginBottom: 'auto', padding: '2px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', color: (rarity == "Contraband" ? caseData?.["KnifeROIs"]?.[item] : caseData?.["ItemROIs"]?.[rarity]?.[item])?.["StatTrak\u2122 " + key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] > -0.01 ? "green" : "rgb(187, 23, 26)" }}>{(rarity == "Contraband" ? caseData?.["KnifeROIs"]?.[item] : caseData?.["ItemROIs"]?.[rarity]?.[item])?.["StatTrak\u2122 " + key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] > -0.01 ? <TrendingUpIcon style={{ width: "16px", height: "12px" }} /> : <TrendingDownIcon style={{ width: "16px", height: "12px" }} />}{((rarity == "Contraband" ? caseData?.["KnifeROIs"]?.[item] : caseData?.["ItemROIs"]?.[rarity]?.[item])?.["StatTrak\u2122 " + key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(Math.round((rarity == "Contraband" ? caseData?.["KnifeROIs"]?.[item] : caseData?.["ItemROIs"]?.[rarity]?.[item])?.["StatTrak\u2122 " + key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0)), false)}%</div>
                          </a>
                        </TableCell>

                      }
                      <TableCell key={key} component="th" scope="row" sx={{
                        cursor: 'pointer', textAlign: 'center', fontSize: '0.9rem', lineHeight: '0.8',
                      }}>
                        <a style={{
                          textDecoration: 'none', color: rarity == "Contraband" ? (caseData?.KnifeEstimates?.[item]?.[key] ?? false) ? "rgb(131 131 131)" : "white" : (caseData?.ItemEstimates?.[rarity]?.[item]?.[key] ?? false) ? "rgb(131 131 131)" : "white"
                        }} href={caseData["IsCSFloat"] ? csfloatLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : caseData["ISTradeit"] ? tradeitLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : caseData["IsBuff"] ? buffLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : caseData["IsSkinport"] ? skinportLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, caseData, false, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : steamLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband')} target="_blank">

                          <div style={{ textDecoration: 'underline', }}>{formatNumber(value / exchangeRate, true)}</div>
                          <div style={{ display: 'flex', fontSize: '11px', lineHeight: '0.4', marginTop: 'auto', marginBottom: 'auto', padding: '2px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', color: (rarity == "Contraband" ? caseData?.["KnifeROIs"]?.[item] : caseData?.["ItemROIs"]?.[rarity]?.[item])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] > -0.01 ? "green" : "rgb(187, 23, 26)" }}>{(rarity == "Contraband" ? caseData?.["KnifeROIs"]?.[item] : caseData?.["ItemROIs"]?.[rarity]?.[item])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] > -0.01 ? <TrendingUpIcon style={{ width: "16px", height: "12px" }} /> : <TrendingDownIcon style={{ width: "16px", height: "12px" }} />}{((rarity == "Contraband" ? caseData?.["KnifeROIs"]?.[item] : caseData?.["ItemROIs"]?.[rarity]?.[item])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(Math.round((rarity == "Contraband" ? caseData?.["KnifeROIs"]?.[item] : caseData?.["ItemROIs"]?.[rarity]?.[item])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0)), false)}%</div>
                        </a>
                      </TableCell>

                    </TableRow>
                  ))}
                  {/* {Object.entries((rarity == "Contraband" ? caseData["KnifeCosts"][item] : caseData["ItemCosts"][rarity][item])).filter(([key, value]) => key.includes("StatTrak")).length > 0 ?

                                <TableRow>
                                    
                                    {Object.entries((rarity == "Contraband" ? caseData["KnifeCosts"][item] : caseData["ItemCosts"][rarity][item])).filter(([key, value]) => key.includes("StatTrak")).map(([key, value]) => (
                                        <TableCell key={key} component="th" scope="row" sx={{ color: "rgb(207, 106, 50)", cursor: 'pointer', textDecoration: 'underline', textAlign: 'center' }} onClick={() => window.open(caseData["IsBuff"] ? buffLink(caseData.CollectionType == "Souvenir" ? "Souvenir ": "" + item, realWearNames[key.replace('StatTrak™ ', '')], true, rarity === 'Contraband') : caseData["IsSkinport"] ? skinportLink(caseData.CollectionType == "Souvenir" ? "Souvenir ": "" + caseData.CollectionType == "Souvenir" ? "Souvenir ": "" + item, caseData, true, realWearNames[key.replace('StatTrak™ ', '')], true, rarity === 'Contraband') : steamLink(caseData.CollectionType == "Souvenir" ? "Souvenir ": "" + caseData.CollectionType == "Souvenir" ? "Souvenir ": "" + item, realWearNames[key.replace('StatTrak™ ', '')], true, rarity === 'Contraband'), "_blank")}>
                                            {currencySymbol}{formatNumber(value / exchangeRate)}
                                        </TableCell>
                                    ))}
                                </TableRow> : <div />}
                            <TableRow>
                                
                                {Object.entries((rarity == "Contraband" ? caseData["KnifeCosts"][item] : caseData["ItemCosts"][rarity][item])).filter(([key, value]) => !key.includes("StatTrak") && !key.includes('totalItemValue')).map(([key, value]) => (
                                    <TableCell key={key} component="th" scope="row" onClick={() => window.open(caseData["IsBuff"] ? buffLink((caseData.SouvenirPackName != null ? "Souvenir ": "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : caseData["IsSkinport"] ? skinportLink((caseData.SouvenirPackName != null ? "Souvenir ": "") + item, caseData,  false,  realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : steamLink((caseData.SouvenirPackName != null ? "Souvenir ": "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband'))} sx={{ cursor: 'pointer', textDecoration: 'underline', textAlign: 'center' }}>
                                        {currencySymbol}{formatNumber(value / exchangeRate)}
                                    </TableCell>
                                ))}
                            </TableRow> */}
                  {/* <TableRow>
              <TableCell component="th" rowspan="2" scope="row">
              Total Item Value
              </TableCell>
              <TableCell colSpan={Object.entries((rarity == "Contraband" ? caseData["KnifeCosts"][item]: caseData["ItemCosts"][rarity][item])).filter(([key, value]) => !key.includes("StatTrak")).length}>${totalItemValue.toFixed(2)}</TableCell>
          </TableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
            <a href={skinsearchLink(item, false, caseData?.CollectionType)} style={{ margin: 'auto', marginTop: '4px', height: '70px' }} target="_blank" rel="noopener noreferrer">
              <img src="/assets/skinsearch/300x70v2.jpg" style={{ height: 'auto', objectFit: 'contain', width: '100%', margin: 'auto', marginTop: '8px', height: '75px' }} />
            </a>
          </Paper>
        </Backdrop> :
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, }}
          open={true}
          onClick={onClose}
        >
          <Paper sx={{ p: 2, position: 'relative' }} onClick={handlePaperClick}>
            <IconButton
              sx={{ position: 'absolute', top: 8, right: 8, zIndex: 1 }}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography variant="h6" gutterBottom style={{ margin: 'auto', width: '100%', textAlign: 'center' }}>
                1 in {formatNumber((1 / (caseData["Items"][item]["ModifiedDropChance"] ?? 1)) / (caseData["PercentReceive"][rarity] / (rarity === "Contraband" ? caseData["TotalKnifeChance"] : Object.keys(caseData["ItemCosts"][rarity]).length)))}
              </Typography>
              <Typography variant="h5" gutterBottom style={{ margin: 'auto', width: '100%', textAlign: 'center' }}>
                {item}
              </Typography>
              <Typography variant="h6" gutterBottom style={{
                margin: 'auto', width: '100%', textAlign: 'center', color:
                  (caseData?.ISTradeit ? (rarity === "Contraband" ?
                    (typeof caseData?.KnifeEstimates?.[item] === "object" ?
                      (Object.entries(caseData?.KnifeEstimates?.[item]).filter(([key, value]) => value === true && key.includes("StatTrak")).length * 0.1 +
                        Object.entries(caseData?.KnifeEstimates?.[item]).filter(([key, value]) => value === true && !key.includes("StatTrak")).length <
                        Object.entries(caseData?.KnifeEstimates?.[item]).filter(([key, value]) => value === false && key.includes("StatTrak")).length * 0.1 +
                        Object.entries(caseData?.KnifeEstimates?.[item]).filter(([key, value]) => value === false && !key.includes("StatTrak")).length) :
                      (caseData?.KnifeEstimates?.[item] === true)
                    ) ? "white" : "rgb(131 131 131)" :
                    (typeof caseData?.ItemEstimates?.[rarity]?.[item] === "object" ?
                      (Object.entries(caseData?.ItemEstimates?.[rarity]?.[item]).filter(([key, value]) => value === true && key.includes("StatTrak")).length * 0.1 +
                        Object.entries(caseData?.ItemEstimates?.[rarity]?.[item]).filter(([key, value]) => value === true && !key.includes("StatTrak")).length <
                        Object.entries(caseData?.ItemEstimates?.[rarity]?.[item]).filter(([key, value]) => value === false && key.includes("StatTrak")).length * 0.1 +
                        Object.entries(caseData?.ItemEstimates?.[rarity]?.[item]).filter(([key, value]) => value === false && !key.includes("StatTrak")).length) :
                      (caseData?.ItemEstimates?.[rarity]?.[item] === true)
                    ) ? "white" : "rgb(131 131 131)"
                  ) : "white")
              }}>
                {formatNumber((rarity === "Contraband" ? caseData["KnifeCosts"][item]['totalItemValue'] : caseData["ItemCosts"][rarity][item]['totalItemValue']) / exchangeRate, true)} AVG
              </Typography>
            </div>
            {/* <Typography variant="h6" gutterBottom>
    {item.description}
  </Typography> */}
            {/* Add any additional content you want to display in the overlay */}
            {/* <BarChart width={500} height={500} data={itemWears} layout="horizontal">
  <CartesianGrid strokeDasharray="3 3" />
  <XAxis type="number" />
  <YAxis dataKey="name" type="category" />
  <Tooltip />
  <Bar dataKey="value" fill="#8884d8" label={CustomizedLabel}>
      {Object.entries(itemWears).map((entry, index) => (
      <Cell key={`cell-${index}`} fill={"red"} />
      ))}
  </Bar>
  </BarChart> */}
            <div style={{ display: 'flex', flexDirection: 'horizontal', }}>
              <img src={caseData["Items"][item]["ImageUrl"]} style={{ height: '250px' }} />
              <BarChart style={{ marginLeft: 'auto' }} width={128 * data.length} height={250} data={data} layout="horizontal" barCategoryGap={1} margin={{ top: 50, right: 0, left: 0, bottom: 0 }}>
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <YAxis type="number" hide />
                <XAxis dataKey="name" type="category" width={150} hide />
                {/* <Tooltip /> */}
                <Bar dataKey="value" fill="#8884d8" label={CustomizedLabel}>
                  {/* {data.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={"red"} />
      ))} */}
                </Bar>
              </BarChart>
            </div>
            <TableContainer component={Paper} >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    {Object.entries((rarity == "Contraband" ? caseData["KnifeCosts"][item] : caseData["ItemCosts"][rarity][item])).filter(([key, value]) => !key.includes("StatTrak") && !key.includes('totalItemValue')).map(([key, value]) => (
                      <TableCell key={key} style={{ width: '128px', textAlign: 'center' }}>{realWearNames[key]}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries((rarity == "Contraband" ? caseData["KnifeCosts"][item] : caseData["ItemCosts"][rarity][item])).filter(([key, value]) => key.includes("StatTrak")).length > 0 ?

                    <TableRow>
                      <TableCell component="th" scope="row" sx={{ color: "rgb(207, 106, 50)" }}>
                        ST (10x Rarer)
                      </TableCell>
                      {Object.entries((rarity == "Contraband" ? caseData["KnifeCosts"][item] : caseData["ItemCosts"][rarity][item])).filter(([key, value]) => key.includes("StatTrak")).map(([key, value]) => (
                        <TableCell key={key} component="th" scope="row" sx={{
                          color: rarity == "Contraband" ? (caseData?.KnifeEstimates?.[item]?.[key] ?? false) ? "rgb(131 131 131)" : "rgb(207, 106, 50)" : (caseData?.ItemEstimates?.[rarity]?.[item]?.[key] ?? false) ? "rgb(131 131 131)" : "rgb(207, 106, 50)",
                          cursor: 'pointer', textAlign: 'center',
                        }}>
                          <a style={{ color: rarity == "Contraband" ? (caseData?.KnifeEstimates?.[item]?.[key] ?? false) ? "rgb(131 131 131)" : "rgb(207, 106, 50)" : (caseData?.ItemEstimates?.[rarity]?.[item]?.[key] ?? false) ? "rgb(131 131 131)" : "rgb(207, 106, 50)", textDecoration: 'none', }} href={caseData["IsCSFloat"] ? csfloatLink(caseData.CollectionType == "Souvenir" ? "Souvenir " : "" + item, realWearNames[key.replace('StatTrak™ ', '')], true, rarity === 'Contraband') : caseData["ISTradeit"] ? tradeitLink(caseData.CollectionType == "Souvenir" ? "Souvenir " : "" + item, realWearNames[key.replace('StatTrak™ ', '')], true, rarity === 'Contraband') : caseData["IsBuff"] ? buffLink(caseData.CollectionType == "Souvenir" ? "Souvenir " : "" + item, realWearNames[key.replace('StatTrak™ ', '')], true, rarity === 'Contraband') : caseData["IsSkinport"] ? skinportLink(caseData.CollectionType == "Souvenir" ? "Souvenir " : "" + caseData.CollectionType == "Souvenir" ? "Souvenir " : "" + item, caseData, true, realWearNames[key.replace('StatTrak™ ', '')], true, rarity === 'Contraband') : steamLink(caseData.CollectionType == "Souvenir" ? "Souvenir " : "" + caseData.CollectionType == "Souvenir" ? "Souvenir " : "" + item, realWearNames[key.replace('StatTrak™ ', '')], true, rarity === 'Contraband')} target="_blank">

                            <div style={{ textDecoration: 'underline', }}>{formatNumber(value / exchangeRate, true)}</div>
                            <div style={{ display: 'flex', fontSize: '12px', lineHeight: '0.4', marginTop: '-3px', marginBottom: 'auto', padding: '2px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', color: ((rarity == "Contraband" ? caseData?.["KnifeROIs"]?.[item] : caseData?.["ItemROIs"]?.[rarity]?.[item])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0) > -0.01 ? "green" : "rgb(187, 23, 26)" }}>{((rarity == "Contraband" ? caseData?.["KnifeROIs"]?.[item] : caseData?.["ItemROIs"]?.[rarity]?.[item])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0) > -0.01 ? <TrendingUpIcon style={{ width: "16px", height: "12px" }} /> : <TrendingDownIcon style={{ width: "16px", height: "12px" }} />}{((rarity == "Contraband" ? caseData?.["KnifeROIs"]?.[item] : caseData?.["ItemROIs"]?.[rarity]?.[item])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(Math.round((rarity == "Contraband" ? caseData?.["KnifeROIs"]?.[item] : caseData?.["ItemROIs"]?.[rarity]?.[item])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0)), false)}%</div>
                          </a>
                        </TableCell>

                      ))}
                    </TableRow> : <div />}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Normal
                    </TableCell>
                    {Object.entries((rarity == "Contraband" ? caseData["KnifeCosts"][item] : caseData["ItemCosts"][rarity][item])).filter(([key, value]) => !key.includes("StatTrak") && !key.includes('totalItemValue')).map(([key, value]) => (
                      <TableCell key={key} component="th" scope="row" sx={{
                        cursor: 'pointer', textAlign: 'center',
                      }}>
                        <a style={{ color: rarity == "Contraband" ? (caseData?.KnifeEstimates?.[item]?.[key] ?? false) ? "rgb(131 131 131)" : "white" : (caseData?.ItemEstimates?.[rarity]?.[item]?.[key] ?? false) ? "rgb(131 131 131)" : "white", textDecoration: 'none', }} href={caseData["IsCSFloat"] ? csfloatLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : caseData["ISTradeit"] ? tradeitLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : caseData["IsBuff"] ? buffLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : caseData["IsSkinport"] ? skinportLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, caseData, false, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : steamLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband')} target="_blank">
                          <div style={{ textDecoration: 'underline', }}>{formatNumber(value / exchangeRate, true)}</div>
                          <div style={{ display: 'flex', fontSize: '12px', lineHeight: '0.4', marginTop: '-3px', marginBottom: 'auto', padding: '2px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', color: ((rarity == "Contraband" ? caseData?.["KnifeROIs"]?.[item] : caseData?.["ItemROIs"]?.[rarity]?.[item])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0) > -0.01 ? "green" : "rgb(187, 23, 26)" }}>{((rarity == "Contraband" ? caseData?.["KnifeROIs"]?.[item] : caseData["ItemROIs"]?.[rarity]?.[item])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0) > -0.01 ? <TrendingUpIcon style={{ width: "16px", height: "12px" }} /> : <TrendingDownIcon style={{ width: "16px", height: "12px" }} />}{((rarity == "Contraband" ? caseData?.["KnifeROIs"]?.[item] : caseData?.["ItemROIs"]?.[rarity]?.[item])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(Math.round((rarity == "Contraband" ? caseData?.["KnifeROIs"]?.[item] : caseData?.["ItemROIs"]?.[rarity]?.[item])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0)), false)}%</div>
                        </a>
                      </TableCell>
                    ))}
                  </TableRow>
                  {/* <TableRow>
          <TableCell component="th" rowspan="2" scope="row">
          Total Item Value
          </TableCell>
          <TableCell colSpan={Object.entries((rarity == "Contraband" ? caseData["KnifeCosts"][item]: caseData["ItemCosts"][rarity][item])).filter(([key, value]) => !key.includes("StatTrak")).length}>${totalItemValue.toFixed(2)}</TableCell>
      </TableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
            <a href={skinsearchLink(item, false, caseData?.CollectionType)} style={{ margin: 'auto', marginTop: '4px', height: '75px' }} target="_blank" rel="noopener noreferrer">
              <img src="/assets/skinsearch/750x75.jpg" style={{ height: 'auto', objectFit: 'contain', width: '100%', margin: 'auto', marginTop: '8px', marginBottom: '-12px', height: '75px', maxHeight: '6vh' }} />
            </a>
          </Paper>
        </Backdrop>
    );
  }


  return (

    <Container width="100%" height={400} maxWidth="none" style={{ padding: '0', paddingTop: '3px' }}>
      {selectedItem && (
        <Overlay item={selectedItem} rarity={selectedRarity} onClose={handleCloseOverlay} />
      )}
      <Grid container spacing={1} sx={{ padding: 0 }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', flexWrap: 'wrap' }}>
          <FormControl sx={{ marginRight: '4px', }}>
            <InputLabel id="sortby-label">Sort By</InputLabel>
            <Select
              //  renderInput={(params) => <TextField {...params} label="Pricing Source" />}

              labelId="sortby-label"
              id="sortbyitems"
              value={sortBy}
              style={{ minWidth: '120px', fontSize: '13px', height: '56px' }}
              label="Sort By  "
              onChange={handleSort}
            >
              <MenuItem value="invest"><div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center', width: '100%', pointerEvents: 'none' }}><TrendingUpIcon className="iconSVGMob" fontSize='small' sx={{ width: '28px', height: '28px' }} /> INVEST ROI
                <Tooltip title={
                  <Typography variant="body" component="div" style={{ textAlign: 'center' }}>
                    Return on Investment if you had purchased this container around {investType == "1y" ? "a year" : investType == "6m" ? "6 months" : "a month"} ago.
                  </Typography>
                }>
                  <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '8px' }} fontSize='large' />
                </Tooltip></div></MenuItem>
              {/* <MenuItem value="invest6M"><div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center', width: '100%' }}><TrendingUpIcon className="iconSVGMob" fontSize='small' sx={{ width: '28px', height: '28px' }} /> 6M INVEST ROI
                        <Tooltip title={
                          <Typography variant="body" component="div" style={{ textAlign: 'center' }}>
                            Return on Investment if you had purchased this container around 6 months ago.
                          </Typography>
                        }>
                          <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '8px' }} fontSize='large' />
                        </Tooltip></div></MenuItem>
                      <MenuItem value="invest1M"><div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center', width: '100%' }}><TrendingUpIcon className="iconSVGMob" fontSize='small' sx={{ width: '28px', height: '28px' }} /> 1M INVEST ROI
                        <Tooltip title={
                          <Typography variant="body" component="div" style={{ textAlign: 'center' }}>
                            Return on Investment if you had purchased this container around a month ago.
                          </Typography>
                        }>
                          <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '8px' }} fontSize='large' />
                        </Tooltip></div></MenuItem> */}
              <MenuItem value="cost"><div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center', width: '100%', pointerEvents: 'none' }}><AttachMoney className="iconSVGMob" fontSize='small' sx={{ width: '28px', height: '28px' }} /> PRICE
                <Tooltip title={
                  <Typography variant="body" component="div" style={{ textAlign: 'center' }}>
                    Sort by the cost to unbox. duh.
                  </Typography>
                }>
                  <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '8px' }} fontSize='large' />
                </Tooltip></div></MenuItem>
            </Select>
          </FormControl>
          <IconButton aria-label="sortDirection" style={{ marginTop: '3px', WebkitTransform: sortDirection ? "none" : "scaleY(-1)", transform: sortDirection ? "none" : "scaleY(-1)" }} onClick={() => handleSortDirection(!sortDirection)}>
            <SortIcon></SortIcon>
          </IconButton>
          <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '10%' }}>
            <p>Rarity:</p>
            <IconButton aria-label="raritySortDirection" style={{ marginTop: '3px', WebkitTransform: !raritySortDirection ? "none" : "scaleY(-1)", transform: !raritySortDirection ? "none" : "scaleY(-1)" }} onClick={() => handleRaritySortDirection(!raritySortDirection)}>
              <SortIcon></SortIcon>
            </IconButton>
            {/* Color dot for each rarity in sortedItemCosts */}
            {
              (sortedKnifeCosts ?? []).length > 0 && !raritySortDirection &&
              <Radio
                checked={displayRarities[Object.keys(otherRarityNames).includes("Contraband") ? otherRarityNames["Contraband"] : "Contraband"]}
                key={"Contraband" + "radio"}
                onClick={() => (handleRarityClick("Contraband"))}
                sx={{
                  marginTop: '3px',
                  color: `rgb(${redRarityNums[Object.keys(otherRarityNames).includes("Contraband") ? otherRarityNames["Contraband"] : "Contraband"]}, ${greenRarityNums[Object.keys(otherRarityNames).includes("Contraband") ? otherRarityNames["Contraband"] : "Contraband"]}, ${blueRarityNums[Object.keys(otherRarityNames).includes("Contraband") ? otherRarityNames["Contraband"] : "Contraband"]})`,
                  '&.Mui-checked': {
                    color: `rgb(${redRarityNums[Object.keys(otherRarityNames).includes("Contraband") ? otherRarityNames["Contraband"] : "Contraband"]}, ${greenRarityNums[Object.keys(otherRarityNames).includes("Contraband") ? otherRarityNames["Contraband"] : "Contraband"]}, ${blueRarityNums[Object.keys(otherRarityNames).includes("Contraband") ? otherRarityNames["Contraband"] : "Contraband"]})`,
                  },
                }}
              />
            }
            {sortedItemCosts != null ? sortedItemCosts.map(({ rarity }) => (
              <Radio
                checked={displayRarities[Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity]}
                key={rarity + "radio"}
                onClick={() => (handleRarityClick(rarity))}
                sx={{
                  marginTop: '3px',
                  color: `rgb(${redRarityNums[Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity]}, ${greenRarityNums[Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity]}, ${blueRarityNums[Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity]})`,
                  '&.Mui-checked': {
                    color: `rgb(${redRarityNums[Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity]}, ${greenRarityNums[Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity]}, ${blueRarityNums[Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity]})`,
                  },
                }}
              />
            )) : null}
            {
              (sortedKnifeCosts ?? []).length > 0 && raritySortDirection &&
              <Radio
                checked={displayRarities[Object.keys(otherRarityNames).includes("Contraband") ? otherRarityNames["Contraband"] : "Contraband"]}
                key={"Contraband" + "radio"}
                onClick={() => (handleRarityClick("Contraband"))}
                sx={{
                  marginTop: '3px',
                  color: `rgb(${redRarityNums[Object.keys(otherRarityNames).includes("Contraband") ? otherRarityNames["Contraband"] : "Contraband"]}, ${greenRarityNums[Object.keys(otherRarityNames).includes("Contraband") ? otherRarityNames["Contraband"] : "Contraband"]}, ${blueRarityNums[Object.keys(otherRarityNames).includes("Contraband") ? otherRarityNames["Contraband"] : "Contraband"]})`,
                  '&.Mui-checked': {
                    color: `rgb(${redRarityNums[Object.keys(otherRarityNames).includes("Contraband") ? otherRarityNames["Contraband"] : "Contraband"]}, ${greenRarityNums[Object.keys(otherRarityNames).includes("Contraband") ? otherRarityNames["Contraband"] : "Contraband"]}, ${blueRarityNums[Object.keys(otherRarityNames).includes("Contraband") ? otherRarityNames["Contraband"] : "Contraband"]})`,
                  },
                }}
              />
            }

          </div>
        </div>
        {sortedKnifeCosts != null && !raritySortDirection ? <Grid item xs={12} key={"Contraband"} sx={{ display: 'flex', flexDirection: 'row', }}>
          {/* <Container sx={{flex: 1, margin: 'auto'}}>
                        <Typography variant="h7" sx={{marginRight:"auto", marginTop:"auto", marginBottom:"auto"}}>
                            <Typography variant="h5" gutterBottom>
                                {rarity}
                            </Typography>
                            <Typography variant="h6">{'$' + caseData["RarityValue"][rarity].toFixed(2)}</Typography>
                            <Typography variant="h7" >{'1 in ' + (1 / caseData["PercentReceive"][rarity]).toFixed(1) + ' cases'}</Typography>
                            
                        </Typography>
                    </Container> */}


          <Grid container direction="row" spacing={{}} columns={{ xs: 6, sm: 12, md: 20 }} sx={{ flex: 6, justifyContent: isMobile ? 'center' : 'left' }}>

            {displayRarities["Contraband"] && sortedKnifeCosts.map(({ name, totalItemValue, ROI }) => (
              Object.keys(caseData?.ItemCosts?.["Contraband"]?.[name] ?? []).length < 1 ?

                <Grid item xs={2} sm={4} md={4} key={name} className="grid-item" onClick={() => handleItemClick(name, "Contraband")} sx={{
                  position: 'relative', transition: 'box-shadow 0.3s',
                  '&:hover': {
                    boxShadow: '0 8px 16px 0 rgba(0,0,0,0.6)'
                  }, paddingLeft: '12px', padding: isMobile ? '4px' : '12px', minWidth: isMobile ? '150px' : '280px', cursor: 'pointer',
                }}>
                  <Card style={{
                    background: `linear-gradient(145deg, RGB(${redRarityNums["Contraband"]}, ${greenRarityNums["Contraband"]}, ${blueRarityNums["Contraband"]}) 0%, black 100%)`, paddingLeft: 0
                  }}>
                    <CardMedia
                      sx={{ height: 140, backgroundSize: "contain", }}
                      image={caseData["Items"][name]["ImageUrl"]}
                    />
                    <div style={{
                      position: 'absolute', bottom: isMobile ? '83.8%' : '8%', right: isMobile ? '4px' : '12px', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2, fontSize: isMobile ? '16px' : '22px',
                    }}>
                    </div>
                    <div style={{ position: 'absolute', top: isMobile ? '-0.5px' : '9px', left: isMobile ? '4px' : '16px', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2, fontSize: isMobile ? '16px' : '19px' }}>
                      <span>1 in {formatNumber((1 / (caseData["Items"][name]["ModifiedDropChance"] ?? 1)) / (caseData["PercentReceive"]["Contraband"] / caseData["TotalKnifeChance"]))}</span>
                    </div>
                  </Card>
                  <div style={{ display: 'flex', flex: '1', height: '30%', marginTop: isMobile ? name?.replace("★ (Vanilla)", " Vanilla")?.split(' | ')?.[1]?.split(' (').length > 1 ? '-4px' : '-16px' : '0', flexDirection: isMobile ? 'column-reverse' : 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                      <h4 style={{ lineHeight: 1.2, margin: '0', marginTop: isMobile ? '0.4rem' : '0.8rem', textAlign: isMobile ? 'center' : 'left' }}>{name.replace("★ (Vanilla)", " Vanilla").split(' | ')[0]}</h4>
                      <h3 style={{ lineHeight: 1.2, margin: '0', textAlign: isMobile ? 'center' : 'left', maxWidth: '230px', maxHeight: '2lh', overflow: 'clip' }}>{name?.replace("★ (Vanilla)", " Vanilla")?.split(' | ')?.[1]?.split(' (')?.[0]}</h3>
                      {name?.replace("★ (Vanilla)", " Vanilla")?.split(' | ')?.[1]?.split(' (').length > 1 && <h4 style={{ lineHeight: 1.2, margin: '0', textAlign: isMobile ? 'center' : 'left', marginBottom: '4px' }}>{"(" + name?.replace("★ (Vanilla)", " Vanilla")?.split(' | ')?.[1]?.split(' (')?.[1]}</h4>}
                    </div>
                    <div style={{
                      backgroundColor: isMobile ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
                      marginTop: 'auto', justifyContent: 'center', display: 'flex', flexDirection: 'column', marginBottom: 'auto', padding: '4px', borderRadius: '4px 4px 4px 4px', zIndex: 2, fontSize: isMobile ? '16px' : '22px', color:
                        (
                          (
                            caseData?.ISTradeit ?
                              ((typeof caseData?.KnifeEstimates?.[name] === "object" ?
                                (Object.entries(caseData?.KnifeEstimates?.[name]).filter(([key, value]) => value === true && key.includes("StatTrak")).length * 0.1 +
                                  Object.entries(caseData?.KnifeEstimates?.[name]).filter(([key, value]) => value === true && !key.includes("StatTrak")).length <
                                  Object.entries(caseData?.KnifeEstimates?.[name]).filter(([key, value]) => value === false && key.includes("StatTrak")).length * 0.1 +
                                  Object.entries(caseData?.KnifeEstimates?.[name]).filter(([key, value]) => value === false && !key.includes("StatTrak")).length) :
                                (caseData?.KnifeEstimates?.[name] === false)
                              ) ? "white" : "rgb(131 131 131)") : "white"
                          )
                        )
                    }}>
                      {!isMobile && <div style={{ display: 'flex', fontSize: isMobile ? '12px' : '14px', lineHeight: '0.4', marginTop: 'auto', marginBottom: 'auto', padding: '4px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: '-12px', color: ROI > -0.01 ? "green" : "rgb(187, 23, 26)" }}>{ROI > -0.01 ? <TrendingUpIcon style={{ width: isMobile ? "18px" : '22px', height: isMobile ? "14px" : "17px" }} /> : <TrendingDownIcon style={{ width: isMobile ? "18px" : '22px', height: isMobile ? "14px" : "17px" }} />}{(ROI ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(Math.round(ROI)), false)}%</div>}
                      <span style={{ margin: "auto" }}>{formatNumber(totalItemValue / exchangeRate, true)}</span>
                      {isMobile && <div style={{ display: 'flex', fontSize: isMobile ? '12px' : '14px', lineHeight: '0.4', marginTop: 'auto', marginBottom: 'auto', padding: '4px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: '-10px' }}>{ROI > -0.01 ? <TrendingUpIcon style={{ width: isMobile ? "18px" : '22px', height: isMobile ? "14px" : "17px" }} /> : <TrendingDownIcon style={{ width: isMobile ? "18px" : '22px', height: isMobile ? "14px" : "17px" }} />}{(ROI ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(Math.round(ROI)), false)}%</div>}
                    </div>
                  </div>
                </Grid> :
                <Link to={caseData["IsCSFloat"] ? csfloatLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + name) : caseData["ISTradeit"] ? tradeitLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + name) : caseData["IsBuff"] ? buffLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + name) : caseData["IsSkinport"] ? skinportLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + name, caseData) : steamLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + name)} target="_blank">
                  <Grid item xs={2} sm={4} md={4} key={name} className="grid-item" sx={{
                    position: 'relative', transition: 'box-shadow 0.3s',
                    '&:hover': {
                      boxShadow: '0 8px 16px 0 rgba(0,0,0,0.6)'
                    }, paddingLeft: '12px', padding: isMobile ? '4px' : '12px', minWidth: isMobile ? '150px' : '280px'
                  }}>
                    <Card style={{
                      background: `linear-gradient(145deg, RGB(${redRarityNums["Contraband"]}, ${greenRarityNums["Contraband"]}, ${blueRarityNums["Contraband"]}) 0%, black 100%)`, paddingLeft: 0
                    }}>
                      <CardMedia
                        sx={{ height: 140, backgroundSize: "contain", }}
                        image={caseData["Items"][name]["ImageUrl"]}
                      />
                      <div style={{
                        position: 'absolute', bottom: isMobile ? '83.8%' : '8%', right: isMobile ? '4px' : '12px', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2, fontSize: isMobile ? '16px' : '22px',
                      }}>
                      </div>
                      <div style={{ position: 'absolute', top: isMobile ? '-0.5px' : '9px', left: isMobile ? '4px' : '16px', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2, fontSize: isMobile ? '16px' : '19px' }}>
                        <span>1 in {formatNumber((1 / (caseData["Items"][name]["ModifiedDropChance"] ?? 1)) / (caseData["PercentReceive"]["Contraband"] / caseData["TotalKnifeChance"]))}</span>
                      </div>
                    </Card>
                    <div style={{ display: 'flex', flex: '1', height: '30%', marginTop: isMobile ? name?.replace("★ (Vanilla)", " Vanilla")?.split(' | ')?.[1]?.split(' (').length > 1 ? '-4px' : '-16px' : '0', flexDirection: isMobile ? 'column-reverse' : 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div>
                        <h4 style={{ lineHeight: 1.2, margin: '0', marginTop: isMobile ? '0.4rem' : '0.8rem', textAlign: isMobile ? 'center' : 'left' }}>{name.replace("★ (Vanilla)", " Vanilla").split(' | ')[0]}</h4>
                        <h3 style={{ lineHeight: 1.2, margin: '0', textAlign: isMobile ? 'center' : 'left', maxWidth: '230px', maxHeight: '2lh', overflow: 'clip' }}>{name?.replace("★ (Vanilla)", " Vanilla")?.split(' | ')?.[1]?.split(' (')?.[0]}</h3>
                        {name?.replace("★ (Vanilla)", " Vanilla")?.split(' | ')?.[1]?.split(' (').length > 1 && <h4 style={{ lineHeight: 1.2, margin: '0', textAlign: isMobile ? 'center' : 'left', marginBottom: '4px' }}>{"(" + name?.replace("★ (Vanilla)", " Vanilla")?.split(' | ')?.[1]?.split(' (')?.[1]}</h4>}
                      </div>
                      <div style={{
                        backgroundColor: isMobile ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
                        marginTop: 'auto', justifyContent: 'center', display: 'flex', flexDirection: 'column', marginBottom: 'auto', padding: '4px', borderRadius: '4px 4px 4px 4px', zIndex: 2, fontSize: isMobile ? '16px' : '22px', color:
                          (
                            (
                              caseData?.ISTradeit ?
                                ((typeof caseData?.KnifeEstimates?.[name] === "object" ?
                                  (Object.entries(caseData?.KnifeEstimates?.[name]).filter(([key, value]) => value === true && key.includes("StatTrak")).length * 0.1 +
                                    Object.entries(caseData?.KnifeEstimates?.[name]).filter(([key, value]) => value === true && !key.includes("StatTrak")).length <
                                    Object.entries(caseData?.KnifeEstimates?.[name]).filter(([key, value]) => value === false && key.includes("StatTrak")).length * 0.1 +
                                    Object.entries(caseData?.KnifeEstimates?.[name]).filter(([key, value]) => value === false && !key.includes("StatTrak")).length) :
                                  (caseData?.KnifeEstimates?.[name] === false)
                                ) ? "white" : "rgb(131 131 131)") : "white"
                            )
                          )
                      }}>
                        {!isMobile && <div style={{ display: 'flex', fontSize: isMobile ? '12px' : '14px', lineHeight: '0.4', marginTop: 'auto', marginBottom: 'auto', padding: '4px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: '-12px', color: ROI > -0.01 ? "green" : "rgb(187, 23, 26)" }}>{ROI > -0.01 ? <TrendingUpIcon style={{ width: isMobile ? "18px" : '22px', height: isMobile ? "14px" : "17px" }} /> : <TrendingDownIcon style={{ width: isMobile ? "18px" : '22px', height: isMobile ? "14px" : "17px" }} />}{(ROI ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(Math.round(ROI)), false)}%</div>}
                        <span style={{ margin: "auto" }}>{formatNumber(totalItemValue / exchangeRate, true)}</span>
                        {isMobile && <div style={{ display: 'flex', fontSize: isMobile ? '12px' : '14px', lineHeight: '0.4', marginTop: 'auto', marginBottom: 'auto', padding: '4px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: '-10px' }}>{ROI > -0.01 ? <TrendingUpIcon style={{ width: isMobile ? "18px" : '22px', height: isMobile ? "14px" : "17px" }} /> : <TrendingDownIcon style={{ width: isMobile ? "18px" : '22px', height: isMobile ? "14px" : "17px" }} />}{(ROI ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(Math.round(ROI)), false)}%</div>}
                      </div>
                    </div>
                  </Grid>
                </Link>
            ))}

          </Grid>
        </Grid> : null}
        {/* Only show rarities that are true in displayRarities */}
        {sortedItemCosts.filter(({ rarity }) => displayRarities[Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity]).map(({ rarity, items }) => (
          <Grid item xs={12} key={rarity} sx={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
            {/* <Container sx={{flex: 1, margin: 'auto'}}>
                        <Typography variant="h7" sx={{marginRight:"auto", marginTop:"auto", marginBottom:"auto"}}>
                            <Typography variant="h5" gutterBottom>
                                {rarity}
                            </Typography>
                            <Typography variant="h6">{'$' + caseData["RarityValue"][rarity].toFixed(2)}</Typography>
                            <Typography variant="h7" >{'1 in ' + (1 / caseData["PercentReceive"][rarity]).toFixed(1) + ' cases'}</Typography>
                            
                        </Typography>
                    </Container> */}


            <Grid container direction="row" spacing={{}} columns={{ xs: 8, sm: 12, md: 20 }} sx={{ flex: 6, justifyContent: isMobile ? 'center' : 'left' }}>

              {items.map(item => (
                Object.keys(caseData?.ItemCosts?.[rarity]?.[item.name] ?? []).length <= 1 ?
                  <Grid item xs={2} sm={4} md={4} key={item.name} className="grid-item" sx={{
                    cursor: 'pointer',
                    position: 'relative', transition: 'box-shadow 0.3s',
                    '&:hover': {
                      boxShadow: '0 8px 16px 0 rgba(0,0,0,0.6)'
                    }, paddingLeft: '12px', padding: isMobile ? '6px' : '12px', minWidth: isMobile ? '150px' : '280px'
                  }}>
                    <a style={{ textDecoration: 'none', color: 'white' }} href={caseData["IsCSFloat"] ? csfloatLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item.name) : caseData["ISTradeit"] ? tradeitLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item.name) : caseData["IsBuff"] ? buffLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item.name) : caseData["IsSkinport"] ? skinportLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item.name, caseData) : steamLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item.name)} target="_blank">

                      <Card style={{
                        background: `linear-gradient(145deg, RGB(${redRarityNums[Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity]}, ${greenRarityNums[Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity]}, ${blueRarityNums[Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity]}) 0%, black 100%)`, paddingLeft: 0
                      }}>
                        <CardMedia
                          sx={{ height: 140, backgroundSize: "contain", }}
                          image={caseData["Items"][item.name]["ImageUrl"]}
                        />
                        <div style={{ position: 'absolute', top: isMobile ? '0' : '9px', left: isMobile ? '4px' : '16px', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2, fontSize: isMobile ? '16px' : '19px' }}>
                          <span>1 in {formatNumber(1 / (caseData["PercentReceive"][rarity] / Object.keys(caseData["ItemCosts"][rarity]).length))}</span>
                        </div>


                      </Card>
                      <div style={{ display: 'flex', flex: '1', height: '30%', marginTop: isMobile ? item?.name?.replace("★ (Vanilla)", " Vanilla")?.split(' | ')?.[1]?.split(' (').length > 1 ? '-4px' : '-16px' : '0', flexDirection: isMobile ? 'column-reverse' : 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                          <h4 style={{ lineHeight: 1.2, margin: '0', marginTop: isMobile ? '0.4rem' : '0.8rem', textAlign: isMobile ? 'center' : 'left' }}>{item.name?.replace("★ (Vanilla)", " Vanilla").split(' | ')[0]}</h4>
                          <h3 style={{ lineHeight: 1.2, margin: '0', textAlign: isMobile ? 'center' : 'left', maxWidth: '230px', maxHeight: '2lh', overflow: 'clip' }}>{item.name?.replace("★ (Vanilla)", " Vanilla")?.split(' | ')?.[1]?.split(' (')?.[0]}</h3>
                          {item.name?.replace("★ (Vanilla)", " Vanilla")?.split(' | ')?.[1]?.split(' (').length > 1 && <h4 style={{ lineHeight: 1.2, margin: '0', textAlign: isMobile ? 'center' : 'left', marginBottom: '4px' }}>{"(" + item.name?.replace("★ (Vanilla)", " Vanilla")?.split(' | ')?.[1]?.split(' (')?.[1]}</h4>}
                        </div>
                        <div style={{
                          backgroundColor: isMobile ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
                          marginTop: 'auto', justifyContent: 'center', display: 'flex', flexDirection: 'column', marginBottom: 'auto', padding: '4px', borderRadius: '4px 4px 4px 4px', zIndex: 2, fontSize: isMobile ? '16px' : '22px', color:
                            caseData?.ISTradeit ? (rarity === "Contraband" ?
                              (typeof caseData?.KnifeEstimates?.[item.name] === "object" ?
                                (Object.entries(caseData?.KnifeEstimates?.[item.name]).filter(([key, value]) => value === true && key.includes("StatTrak")).length * 0.1 +
                                  Object.entries(caseData?.KnifeEstimates?.[item.name]).filter(([key, value]) => value === true && !key.includes("StatTrak")).length <
                                  Object.entries(caseData?.KnifeEstimates?.[item.name]).filter(([key, value]) => value === false && key.includes("StatTrak")).length * 0.1 +
                                  Object.entries(caseData?.KnifeEstimates?.[item.name]).filter(([key, value]) => value === false && !key.includes("StatTrak")).length) :
                                (caseData?.KnifeEstimates?.[item.name] === false)
                              ) ? "white" : "rgb(131 131 131)" :
                              (typeof caseData?.ItemEstimates?.[rarity]?.[item.name] === "object" ?
                                (Object.entries(caseData?.ItemEstimates?.[rarity]?.[item.name]).filter(([key, value]) => value === true && key.includes("StatTrak")).length * 0.1 +
                                  Object.entries(caseData?.ItemEstimates?.[rarity]?.[item.name]).filter(([key, value]) => value === true && !key.includes("StatTrak")).length <
                                  Object.entries(caseData?.ItemEstimates?.[rarity]?.[item.name]).filter(([key, value]) => value === false && key.includes("StatTrak")).length * 0.1 +
                                  Object.entries(caseData?.ItemEstimates?.[rarity]?.[item.name]).filter(([key, value]) => value === false && !key.includes("StatTrak")).length) :
                                (caseData?.ItemEstimates?.[rarity]?.[item.name] === false)
                              ) ? "white" : "rgb(131 131 131)"
                            ) : "white"
                        }}>
                          {!isMobile && <div style={{ display: 'flex', fontSize: isMobile ? '12px' : '14px', lineHeight: '0.4', marginTop: 'auto', marginBottom: 'auto', padding: '4px', flexDirection: 'row', justifyContent: "center", alignItems: 'center', marginBottom: '-12px', color: item.ROI > -0.01 ? "green" : "rgb(187, 23, 26)" }}>{item.ROI > -0.01 ? <TrendingUpIcon style={{ width: isMobile ? "18px" : '22px', height: isMobile ? "14px" : "17px" }} /> : <TrendingDownIcon style={{ width: isMobile ? "18px" : '22px', height: isMobile ? "14px" : "17px" }} />}{(item?.ROI ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(Math.round(item.ROI)), false)}%</div>}
                          <span style={{ margin: "auto" }}>{formatNumber(caseData["ItemCosts"][rarity][item.name]["totalItemValue"] / exchangeRate, true)}</span>
                          {isMobile && <div style={{ display: 'flex', fontSize: isMobile ? '12px' : '14px', lineHeight: '0.4', marginTop: 'auto', marginBottom: 'auto', padding: '4px', flexDirection: 'row', justifyContent: "center", alignItems: 'center', marginTop: '-10px' }}>{item.ROI > -0.01 ? <TrendingUpIcon style={{ width: isMobile ? "18px" : '22px', height: isMobile ? "14px" : "17px" }} /> : <TrendingDownIcon style={{ width: isMobile ? "18px" : '22px', height: isMobile ? "14px" : "17px" }} />}{(item?.ROI ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(Math.round(item.ROI)), false)}%</div>}
                        </div>
                      </div>
                    </a>
                  </Grid>

                  : <Grid item xs={2} sm={4} md={4} key={item.name} className="grid-item" onClick={() => handleItemClick(item.name, rarity)} sx={{
                    cursor: 'pointer',
                    position: 'relative', transition: 'box-shadow 0.3s',
                    '&:hover': {
                      boxShadow: '0 8px 16px 0 rgba(0,0,0,0.6)'
                    }, paddingLeft: '12px', padding: isMobile ? '6px' : '12px', minWidth: isMobile ? '150px' : '280px'
                  }}>
                    <Card style={{
                      background: `linear-gradient(145deg, RGB(${redRarityNums[Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity]}, ${greenRarityNums[Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity]}, ${blueRarityNums[Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity]}) 0%, black 100%)`, paddingLeft: 0
                    }}>
                      <CardMedia
                        sx={{ height: 140, backgroundSize: "contain", }}
                        image={caseData["Items"][item.name]["ImageUrl"]}
                      />
                      <div style={{ position: 'absolute', top: isMobile ? '0' : '9px', left: isMobile ? '4px' : '16px', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2, fontSize: isMobile ? '16px' : '19px' }}>
                        <span>1 in {formatNumber(1 / (caseData["PercentReceive"][rarity] / Object.keys(caseData["ItemCosts"][rarity]).length))}</span>
                      </div>


                    </Card>
                    <div style={{ display: 'flex', flex: '1', height: '30%', marginTop: isMobile ? item?.name?.replace("★ (Vanilla)", " Vanilla")?.split(' | ')?.[1]?.split(' (').length > 1 ? '-4px' : '-16px' : '0', flexDirection: isMobile ? 'column-reverse' : 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div>
                        <h4 style={{ lineHeight: 1.2, margin: '0', marginTop: isMobile ? '0.4rem' : '0.8rem', textAlign: isMobile ? 'center' : 'left' }}>{item.name?.replace("★ (Vanilla)", " Vanilla").split(' | ')[0]}</h4>
                        <h3 style={{ lineHeight: 1.2, margin: '0', textAlign: isMobile ? 'center' : 'left', maxWidth: '230px', maxHeight: '2lh', overflow: 'clip' }}>{item.name?.replace("★ (Vanilla)", " Vanilla")?.split(' | ')?.[1]?.split(' (')?.[0]}</h3>
                        {item.name?.replace("★ (Vanilla)", " Vanilla")?.split(' | ')?.[1]?.split(' (').length > 1 && <h4 style={{ lineHeight: 1.2, margin: '0', textAlign: isMobile ? 'center' : 'left', marginBottom: '4px' }}>{"(" + item.name?.replace("★ (Vanilla)", " Vanilla")?.split(' | ')?.[1]?.split(' (')?.[1]}</h4>}
                      </div>
                      <div style={{
                        backgroundColor: isMobile ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
                        marginTop: 'auto', justifyContent: 'center', display: 'flex', flexDirection: 'column', marginBottom: 'auto', padding: '4px', borderRadius: '4px 4px 4px 4px', zIndex: 2, fontSize: isMobile ? '16px' : '22px', color:
                          caseData?.ISTradeit ? (rarity === "Contraband" ?
                            (typeof caseData?.KnifeEstimates?.[item.name] === "object" ?
                              (Object.entries(caseData?.KnifeEstimates?.[item.name]).filter(([key, value]) => value === true && key.includes("StatTrak")).length * 0.1 +
                                Object.entries(caseData?.KnifeEstimates?.[item.name]).filter(([key, value]) => value === true && !key.includes("StatTrak")).length <
                                Object.entries(caseData?.KnifeEstimates?.[item.name]).filter(([key, value]) => value === false && key.includes("StatTrak")).length * 0.1 +
                                Object.entries(caseData?.KnifeEstimates?.[item.name]).filter(([key, value]) => value === false && !key.includes("StatTrak")).length) :
                              (caseData?.KnifeEstimates?.[item.name] === false)
                            ) ? "white" : "rgb(131 131 131)" :
                            (typeof caseData?.ItemEstimates?.[rarity]?.[item.name] === "object" ?
                              (Object.entries(caseData?.ItemEstimates?.[rarity]?.[item.name]).filter(([key, value]) => value === true && key.includes("StatTrak")).length * 0.1 +
                                Object.entries(caseData?.ItemEstimates?.[rarity]?.[item.name]).filter(([key, value]) => value === true && !key.includes("StatTrak")).length <
                                Object.entries(caseData?.ItemEstimates?.[rarity]?.[item.name]).filter(([key, value]) => value === false && key.includes("StatTrak")).length * 0.1 +
                                Object.entries(caseData?.ItemEstimates?.[rarity]?.[item.name]).filter(([key, value]) => value === false && !key.includes("StatTrak")).length) :
                              (caseData?.ItemEstimates?.[rarity]?.[item.name] === false)
                            ) ? "white" : "rgb(131 131 131)"
                          ) : "white"
                      }}>
                        {!isMobile && <div style={{ display: 'flex', fontSize: isMobile ? '12px' : '14px', lineHeight: '0.4', marginTop: 'auto', marginBottom: 'auto', padding: '4px', flexDirection: 'row', justifyContent: "center", alignItems: 'center', marginBottom: '-12px', color: item.ROI > -0.01 ? "green" : "rgb(187, 23, 26)" }}>{item.ROI > -0.01 ? <TrendingUpIcon style={{ width: isMobile ? "18px" : '22px', height: isMobile ? "14px" : "17px" }} /> : <TrendingDownIcon style={{ width: isMobile ? "18px" : '22px', height: isMobile ? "14px" : "17px" }} />}{(item?.ROI ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(Math.round(item.ROI)), false)}%</div>}
                        <span style={{ margin: "auto" }}>{formatNumber(caseData["ItemCosts"][rarity][item.name]["totalItemValue"] / exchangeRate, true)}</span>
                        {isMobile && <div style={{ display: 'flex', fontSize: isMobile ? '12px' : '14px', lineHeight: '0.4', marginTop: 'auto', marginBottom: 'auto', padding: '4px', flexDirection: 'row', justifyContent: "center", alignItems: 'center', marginTop: '-10px' }}>{item.ROI > -0.01 ? <TrendingUpIcon style={{ width: isMobile ? "18px" : '22px', height: isMobile ? "14px" : "17px" }} /> : <TrendingDownIcon style={{ width: isMobile ? "18px" : '22px', height: isMobile ? "14px" : "17px" }} />}{(item?.ROI ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(Math.round(item.ROI)), false)}%</div>}
                      </div>
                    </div>
                  </Grid>
              ))}

            </Grid>
          </Grid>

        ))}
        {sortedKnifeCosts != null && raritySortDirection && displayRarities["Contraband"] ? <Grid item xs={12} key={"Contraband"} sx={{ display: 'flex', flexDirection: 'row', }}>
          {/* <Container sx={{flex: 1, margin: 'auto'}}>
                        <Typography variant="h7" sx={{marginRight:"auto", marginTop:"auto", marginBottom:"auto"}}>
                            <Typography variant="h5" gutterBottom>
                                {rarity}
                            </Typography>
                            <Typography variant="h6">{'$' + caseData["RarityValue"][rarity].toFixed(2)}</Typography>
                            <Typography variant="h7" >{'1 in ' + (1 / caseData["PercentReceive"][rarity]).toFixed(1) + ' cases'}</Typography>
                            
                        </Typography>
                    </Container> */}


          <Grid container direction="row" spacing={{}} columns={{ xs: 6, sm: 12, md: 20 }} sx={{ flex: 6, justifyContent: isMobile ? 'center' : 'left' }}>

            {sortedKnifeCosts.map(({ name, totalItemValue, ROI }) => (
              Object.keys(caseData?.ItemCosts?.["Contraband"]?.[name] ?? []).length < 1 ?

                <Grid item xs={2} sm={4} md={4} key={name} className="grid-item" onClick={() => handleItemClick(name, "Contraband")} sx={{
                  position: 'relative', transition: 'box-shadow 0.3s',
                  '&:hover': {
                    boxShadow: '0 8px 16px 0 rgba(0,0,0,0.6)'
                  }, paddingLeft: '12px', padding: isMobile ? '4px' : '12px', minWidth: isMobile ? '150px' : '280px', cursor: 'pointer',
                }}>
                  <Card style={{
                    background: `linear-gradient(145deg, RGB(${redRarityNums["Contraband"]}, ${greenRarityNums["Contraband"]}, ${blueRarityNums["Contraband"]}) 0%, black 100%)`, paddingLeft: 0
                  }}>
                    <CardMedia
                      sx={{ height: 140, backgroundSize: "contain", }}
                      image={caseData["Items"][name]["ImageUrl"]}
                    />
                    <div style={{
                      position: 'absolute', bottom: isMobile ? '83.8%' : '8%', right: isMobile ? '4px' : '12px', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2, fontSize: isMobile ? '16px' : '22px',
                    }}>
                    </div>
                    <div style={{ position: 'absolute', top: isMobile ? '-0.5px' : '9px', left: isMobile ? '4px' : '16px', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2, fontSize: isMobile ? '16px' : '19px' }}>
                      <span>1 in {formatNumber((1 / (caseData["Items"][name]["ModifiedDropChance"] ?? 1)) / (caseData["PercentReceive"]["Contraband"] / caseData["TotalKnifeChance"]))}</span>
                    </div>
                  </Card>
                  <div style={{ display: 'flex', flex: '1', height: '30%', marginTop: isMobile ? name?.replace("★ (Vanilla)", " Vanilla")?.split(' | ')?.[1]?.split(' (').length > 1 ? '-4px' : '-16px' : '0', flexDirection: isMobile ? 'column-reverse' : 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                      <h4 style={{ lineHeight: 1.2, margin: '0', marginTop: isMobile ? '0.4rem' : '0.8rem', textAlign: isMobile ? 'center' : 'left' }}>{name.replace("★ (Vanilla)", " Vanilla").split(' | ')[0]}</h4>
                      <h3 style={{ lineHeight: 1.2, margin: '0', textAlign: isMobile ? 'center' : 'left', maxWidth: '230px', maxHeight: '2lh', overflow: 'clip' }}>{name?.replace("★ (Vanilla)", " Vanilla")?.split(' | ')?.[1]?.split(' (')?.[0]}</h3>
                      {name?.replace("★ (Vanilla)", " Vanilla")?.split(' | ')?.[1]?.split(' (').length > 1 && <h4 style={{ lineHeight: 1.2, margin: '0', textAlign: isMobile ? 'center' : 'left', marginBottom: '4px' }}>{"(" + name?.replace("★ (Vanilla)", " Vanilla")?.split(' | ')?.[1]?.split(' (')?.[1]}</h4>}
                    </div>
                    <div style={{
                      backgroundColor: isMobile ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
                      marginTop: 'auto', justifyContent: 'center', display: 'flex', flexDirection: 'column', marginBottom: 'auto', padding: '4px', borderRadius: '4px 4px 4px 4px', zIndex: 2, fontSize: isMobile ? '16px' : '22px', color:
                        (
                          (
                            caseData?.ISTradeit ?
                              ((typeof caseData?.KnifeEstimates?.[name] === "object" ?
                                (Object.entries(caseData?.KnifeEstimates?.[name]).filter(([key, value]) => value === true && key.includes("StatTrak")).length * 0.1 +
                                  Object.entries(caseData?.KnifeEstimates?.[name]).filter(([key, value]) => value === true && !key.includes("StatTrak")).length <
                                  Object.entries(caseData?.KnifeEstimates?.[name]).filter(([key, value]) => value === false && key.includes("StatTrak")).length * 0.1 +
                                  Object.entries(caseData?.KnifeEstimates?.[name]).filter(([key, value]) => value === false && !key.includes("StatTrak")).length) :
                                (caseData?.KnifeEstimates?.[name] === false)
                              ) ? "white" : "rgb(131 131 131)") : "white"
                          )
                        )
                    }}>
                      {!isMobile && <div style={{ display: 'flex', fontSize: isMobile ? '12px' : '14px', lineHeight: '0.4', marginTop: 'auto', marginBottom: 'auto', padding: '4px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: '-12px', color: ROI > -0.01 ? "green" : "rgb(187, 23, 26)" }}>{ROI > -0.01 ? <TrendingUpIcon style={{ width: isMobile ? "18px" : '22px', height: isMobile ? "14px" : "17px" }} /> : <TrendingDownIcon style={{ width: isMobile ? "18px" : '22px', height: isMobile ? "14px" : "17px" }} />}{(ROI ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(Math.round(ROI)), false)}%</div>}
                      <span style={{ margin: "auto" }}>{formatNumber(totalItemValue / exchangeRate, true)}</span>
                      {isMobile && <div style={{ display: 'flex', fontSize: isMobile ? '12px' : '14px', lineHeight: '0.4', marginTop: 'auto', marginBottom: 'auto', padding: '4px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: '-10px' }}>{ROI > -0.01 ? <TrendingUpIcon style={{ width: isMobile ? "18px" : '22px', height: isMobile ? "14px" : "17px" }} /> : <TrendingDownIcon style={{ width: isMobile ? "18px" : '22px', height: isMobile ? "14px" : "17px" }} />}{(ROI ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(Math.round(ROI)), false)}%</div>}
                    </div>
                  </div>
                </Grid> :
                <Link to={caseData["IsCSFloat"] ? csfloatLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + name) : caseData["ISTradeit"] ? tradeitLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + name) : caseData["IsBuff"] ? buffLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + name) : caseData["IsSkinport"] ? skinportLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + name, caseData) : steamLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + name)} target="_blank">
                  <Grid item xs={2} sm={4} md={4} key={name} className="grid-item" sx={{
                    position: 'relative', transition: 'box-shadow 0.3s',
                    '&:hover': {
                      boxShadow: '0 8px 16px 0 rgba(0,0,0,0.6)'
                    }, paddingLeft: '12px', padding: isMobile ? '4px' : '12px', minWidth: isMobile ? '150px' : '280px'
                  }}>
                    <Card style={{
                      background: `linear-gradient(145deg, RGB(${redRarityNums["Contraband"]}, ${greenRarityNums["Contraband"]}, ${blueRarityNums["Contraband"]}) 0%, black 100%)`, paddingLeft: 0
                    }}>
                      <CardMedia
                        sx={{ height: 140, backgroundSize: "contain", }}
                        image={caseData["Items"][name]["ImageUrl"]}
                      />
                      <div style={{
                        position: 'absolute', bottom: isMobile ? '83.8%' : '8%', right: isMobile ? '4px' : '12px', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2, fontSize: isMobile ? '16px' : '22px',
                      }}>
                      </div>
                      <div style={{ position: 'absolute', top: isMobile ? '-0.5px' : '9px', left: isMobile ? '4px' : '16px', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2, fontSize: isMobile ? '16px' : '19px' }}>
                        <span>1 in {formatNumber((1 / (caseData["Items"][name]["ModifiedDropChance"] ?? 1)) / (caseData["PercentReceive"]["Contraband"] / caseData["TotalKnifeChance"]))}</span>
                      </div>
                    </Card>
                    <div style={{ display: 'flex', flex: '1', height: '30%', marginTop: isMobile ? name?.replace("★ (Vanilla)", " Vanilla")?.split(' | ')?.[1]?.split(' (').length > 1 ? '-4px' : '-16px' : '0', flexDirection: isMobile ? 'column-reverse' : 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div>
                        <h4 style={{ lineHeight: 1.2, margin: '0', marginTop: isMobile ? '0.4rem' : '0.8rem', textAlign: isMobile ? 'center' : 'left' }}>{name.replace("★ (Vanilla)", " Vanilla").split(' | ')[0]}</h4>
                        <h3 style={{ lineHeight: 1.2, margin: '0', textAlign: isMobile ? 'center' : 'left', maxWidth: '230px', maxHeight: '2lh', overflow: 'clip' }}>{name?.replace("★ (Vanilla)", " Vanilla")?.split(' | ')?.[1]?.split(' (')?.[0]}</h3>
                        {name?.replace("★ (Vanilla)", " Vanilla")?.split(' | ')?.[1]?.split(' (').length > 1 && <h4 style={{ lineHeight: 1.2, margin: '0', textAlign: isMobile ? 'center' : 'left', marginBottom: '4px' }}>{"(" + name?.replace("★ (Vanilla)", " Vanilla")?.split(' | ')?.[1]?.split(' (')?.[1]}</h4>}
                      </div>
                      <div style={{
                        backgroundColor: isMobile ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
                        marginTop: 'auto', justifyContent: 'center', display: 'flex', flexDirection: 'column', marginBottom: 'auto', padding: '4px', borderRadius: '4px 4px 4px 4px', zIndex: 2, fontSize: isMobile ? '16px' : '22px', color:
                          (
                            (
                              caseData?.ISTradeit ?
                                ((typeof caseData?.KnifeEstimates?.[name] === "object" ?
                                  (Object.entries(caseData?.KnifeEstimates?.[name]).filter(([key, value]) => value === true && key.includes("StatTrak")).length * 0.1 +
                                    Object.entries(caseData?.KnifeEstimates?.[name]).filter(([key, value]) => value === true && !key.includes("StatTrak")).length <
                                    Object.entries(caseData?.KnifeEstimates?.[name]).filter(([key, value]) => value === false && key.includes("StatTrak")).length * 0.1 +
                                    Object.entries(caseData?.KnifeEstimates?.[name]).filter(([key, value]) => value === false && !key.includes("StatTrak")).length) :
                                  (caseData?.KnifeEstimates?.[name] === false)
                                ) ? "white" : "rgb(131 131 131)") : "white"
                            )
                          )
                      }}>
                        {!isMobile && <div style={{ display: 'flex', fontSize: isMobile ? '12px' : '14px', lineHeight: '0.4', marginTop: 'auto', marginBottom: 'auto', padding: '4px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: '-12px', color: ROI > -0.01 ? "green" : "rgb(187, 23, 26)" }}>{ROI > -0.01 ? <TrendingUpIcon style={{ width: isMobile ? "18px" : '22px', height: isMobile ? "14px" : "17px" }} /> : <TrendingDownIcon style={{ width: isMobile ? "18px" : '22px', height: isMobile ? "14px" : "17px" }} />}{(ROI ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(Math.round(ROI)), false)}%</div>}
                        <span style={{ margin: "auto" }}>{formatNumber(totalItemValue / exchangeRate, true)}</span>
                        {isMobile && <div style={{ display: 'flex', fontSize: isMobile ? '12px' : '14px', lineHeight: '0.4', marginTop: 'auto', marginBottom: 'auto', padding: '4px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: '-10px' }}>{ROI > -0.01 ? <TrendingUpIcon style={{ width: isMobile ? "18px" : '22px', height: isMobile ? "14px" : "17px" }} /> : <TrendingDownIcon style={{ width: isMobile ? "18px" : '22px', height: isMobile ? "14px" : "17px" }} />}{(ROI ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(Math.round(ROI)), false)}%</div>}
                      </div>
                    </div>
                  </Grid>
                </Link>
            ))}

          </Grid>
        </Grid> : null}


      </Grid>
    </Container>

  );
}

export default CaseItems;
