import { useEffect, useState, useRef } from "react";
import React from "react";
// import { useParams } from "react-router-dom";
import { Backdrop, Card, CardMedia, Container, Grid, Paper, ThemeProvider, Typography, Table, TableRow, TableCell, TableContainer, IconButton } from "@mui/material";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell, Legend } from 'recharts';
import { TableHead, TableBody, } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { skinportLink, buffLink, steamLink, csfloatLink, tradeitLink, skinsearchLink } from './allLinks.js';
import "./RarityValues.css";
import OpenBox from './OpenBox.svg';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

const redRarityNums = { 'Contraband': 223, 'Covert': 187, 'Classified': 158, 'Restricted': 104, 'Mil_Spec': 2, 'Industrial': 94, 'Consumer': 176 };
const greenRarityNums = { 'Contraband': 199, 'Covert': 23, 'Classified': 0, 'Restricted': 50, 'Mil_Spec': 79, 'Industrial': 152, 'Consumer': 195 };
const blueRarityNums = { 'Contraband': 0, 'Covert': 26, 'Classified': 197, 'Restricted': 234, 'Mil_Spec': 228, 'Industrial': 217, 'Consumer': 217 };
const realWearNames = { 'FactoryNew': 'Factory New', 'MinimalWear': 'Minimal Wear', 'FieldTested': 'Field-Tested', 'WellWorn': 'Well-Worn', 'BattleScarred': 'Battle-Scarred' };
const smallWearNames = { 'Factory New': 'FN', 'Minimal Wear': 'MW', 'Field-Tested': 'FT', 'Well-Worn': 'WW', 'Battle-Scarred': 'BS' }


const wearNames = [
  'Factory New',
  'Minimal Wear',
  'Field-Tested',
  'Well-Worn',
  'Battle-Scarred',
];

const wearRanges = {
  'Factory New': 0.07,
  'Minimal Wear': 0.15,
  'Field-Tested': 0.38,
  'Well-Worn': 0.45,
  'Battle-Scarred': 1,
};

const wearRangeStarts = {
  'Factory New': 0,
  'Minimal Wear': 0.07,
  'Field-Tested': 0.15,
  'Well-Worn': 0.38,
  'Battle-Scarred': 0.45,
  'magic': 1, // not used, but needed for calculation
};

const wearDropRates = {
  'Factory New': 0.03,
  'Minimal Wear': 0.24,
  'Field-Tested': 0.33,
  'Well-Worn': 0.24,
  'Battle-Scarred': 0.16,
};

const wearRangeStartDrops = {
  'Factory New': 0,
  'Minimal Wear': 0.08,
  'Field-Tested': 0.16,
  'Well-Worn': 0.39,
  'Battle-Scarred': 0.46,
};

const otherRarityNames = { 'Extraordinary': 'Covert', 'Exotic': 'Classified', 'Remarkable': 'Restricted', 'High_Grade': 'Mil_Spec', 'Superior': 'Classified', 'Master': 'Covert', 'Exceptional': 'Restricted', 'Distinguished': 'Mil_Spec' }





function getWears(minFloat, maxFloat) {
  let fullFloatPercents = {};
  for (let wear of wearNames) {
    let floatRange = maxFloat - minFloat;
    let lowerRange = (wearRangeStarts[wear] ?? 0) - minFloat;
    let higherRange = (wearRanges[wear] ?? 0) - minFloat;
    let eFloatStart = lowerRange / floatRange;
    let eFloatEnd = higherRange / floatRange;
    let totalPercent = 0;
    let floatPercents = {};
    for (let wearName of wearNames) {
      let start = wearRangeStartDrops[wearName] ?? 0;
      let end = wearRanges[wearName] ?? 0;
      if (start < eFloatEnd && end > eFloatStart) {
        let realRange = end - start;
        let insideRange;
        let avgRangeFloat;
        if (eFloatStart < start && eFloatEnd > end) {
          insideRange = end - start;
          avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
        } else if (eFloatStart > start && eFloatEnd < end) {
          insideRange = eFloatEnd - eFloatStart;
          avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
        } else if (eFloatStart < start) {
          insideRange = eFloatEnd - start;
          avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
        } else {
          insideRange = end - eFloatStart;
          avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
        }
        let percentRange = insideRange / realRange;
        let percent = percentRange * wearDropRates[wearName];
        totalPercent += percent;
        floatPercents[avgRangeFloat.toFixed(4)] = percent;
      }
    }
    let floatPercentsKeys = Object.keys(floatPercents);
    let totalAvgFloat = 0;
    let totalPercents = 0;
    for (let stringPercent of floatPercentsKeys) {
      let percent = floatPercents[stringPercent];
      totalAvgFloat += (percent / totalPercent) * parseFloat(stringPercent);
      totalPercents += percent;
    }
    fullFloatPercents[wear] = (totalPercents);
  }
  return fullFloatPercents;
}



function RarityValues({ caseData, exchangeRate, currency, isMobile, investType }) {

  function formatNumber(number, isCurrency) {
    // Use the user's locale
    const userLocale = navigator.language || 'en-US';

    // Create Intl.NumberFormat with user's locale
    if (isCurrency) {
      const formatter = new Intl.NumberFormat(userLocale, {
        //   minimumFractionDigits: 1,
        style: "currency",
        currency: currency,
        maximumFractionDigits: number > 1000 ? 0 : number > 100 ? 1 : 2,
      });
      return formatter.format(number);
    } else {
      const formatter = new Intl.NumberFormat(userLocale, {
        //   minimumFractionDigits: 1,
        maximumFractionDigits: number > 1000 ? 0 : number > 100 ? 1 : 2,
      });
      return formatter.format(number);
    }

    // Format the number

  }

  // const { caseData } = useParams();
  const [hoverItem, setHoverItem] = useState(null);
  const [hoverRarity, setHoverRarity] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedRarity, setSelectedRarity] = useState(null);
  const [, forceUpdate] = useState();



  const handleHover = (item, rarity) => {
    setHoverItem(item);
    setHoverRarity(rarity)
  }

  const handleItemClick = (item, rarity) => {
    // console.log('here:');
    // console.log(item);
    // console.log(rarity);
    if (rarity !== "Contraband") {
      if (Object.keys(caseData.ItemCosts[rarity][item]).length > 1) {
        setSelectedItem(item);
        setSelectedRarity(rarity)
      } else {
        var itemLink = caseData["IsCSFloat"] ? csfloatLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item) : caseData["ISTradeit"] ? tradeitLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item) : caseData["IsBuff"] ? buffLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item) : caseData["IsSkinport"] ? skinportLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, caseData) : steamLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item);
        window.open(itemLink, '_blank')
      }
    } else {
      setSelectedItem(item);
      setSelectedRarity(rarity)
    }

  };

  const handleCloseOverlay = () => {
    setSelectedItem(null);
    setSelectedRarity(null);
  };

  // function CustomizedLabel(x, y, fill, value) {

  //   // render () {
  //   // const {x, y, fill, value} = this.props;
  //   return <text
  //     x={x}
  //     y={y}

  //     fontSize='16'
  //     fontFamily='sans-serif'
  //     fill={fill}
  //     textAnchor="start">{value}%</text>
  //   // }
  // };

  const CustomizedLabel = (props) => {
    const { x, y, fill, value } = props;
    return (
      isMobile ?
        <text x={x + 32} y={y - 10} textAnchor="middle" fontSize={16} dominantBaseline="middle" fill={"white"}>{(value * 100).toFixed(2)}%</text> :
        <text x={x + 66} y={y - 10} textAnchor="middle" fontSize={18} dominantBaseline="middle" fill={"white"}>{(value * 100).toFixed(2)}%</text>
    );
  };


  function Overlay({ item, rarity, onClose }) {
    const itemWears = getWears(caseData['Items'][item]['FloatMin'] / 100, caseData['Items'][item]['FloatMax'] / 100);
    // const data = Object.keys(Object.entries(itemWears).filter(([key, value]) => value !== 0)).map((key) => ({
    //   name: key,
    //   value: itemWears[key]
    // }));
    const data = Object.entries(itemWears)
      .filter(([key, value]) => value !== 0)
      .map(([key, value], index) => ({
        name: key,
        value: itemWears[key]
      }));

    const handlePaperClick = (event) => {
      // Stop propagation to prevent closing when clicking inside the paper area
      event.stopPropagation();
    };

    return (
      isMobile ?
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
          onClick={onClose}
        >
          <Paper sx={{ p: 1, width: '95vw', maxHeight: '95vh', position: 'relative', overflowY: 'scroll' }} onClick={handlePaperClick}>
            <IconButton
              sx={{ position: 'absolute', top: 8, right: 8, zIndex: 1 }}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
            <div style={{ display: "flex", flexDirection: "column", width: '100%' }}>


              <Typography variant="h5" gutterBottom style={{ margin: 'auto', width: '100%', textAlign: 'center' }}>
                {item}
              </Typography>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Typography variant="h6" gutterBottom style={{ margin: 'auto', width: '100%', textAlign: 'center' }}>
                  1 in {formatNumber((1 / (caseData["Items"][item]["ModifiedDropChance"] ?? 1)) / (caseData["PercentReceive"][rarity] / (rarity === "Contraband" ? caseData["TotalKnifeChance"] : Object.keys(caseData["ItemCosts"][rarity]).length)))}
                </Typography>
                <Typography variant="h6" gutterBottom style={{
                  margin: 'auto', width: '100%', textAlign: 'center',
                  color:
                    (caseData?.ISTradeit ?
                      (rarity === "Contraband" ?
                        (typeof caseData?.KnifeEstimates?.[item] === "object" ?
                          (Object.entries(caseData?.KnifeEstimates?.[item]).filter(([key, value]) => value === true && key.includes("StatTrak")).length * 0.1 +
                            Object.entries(caseData?.KnifeEstimates?.[item]).filter(([key, value]) => value === true && !key.includes("StatTrak")).length <
                            Object.entries(caseData?.KnifeEstimates?.[item]).filter(([key, value]) => value === false && key.includes("StatTrak")).length * 0.1 +
                            Object.entries(caseData?.KnifeEstimates?.[item]).filter(([key, value]) => value === false && !key.includes("StatTrak")).length) :
                          (caseData?.KnifeEstimates?.[item] === true)
                        ) ? "white" : "rgb(131 131 131)" :
                        (typeof caseData?.ItemEstimates?.[rarity]?.[item] === "object" ?
                          (Object.entries(caseData?.ItemEstimates?.[rarity]?.[item]).filter(([key, value]) => value === true && key.includes("StatTrak")).length * 0.1 +
                            Object.entries(caseData?.ItemEstimates?.[rarity]?.[item]).filter(([key, value]) => value === true && !key.includes("StatTrak")).length <
                            Object.entries(caseData?.ItemEstimates?.[rarity]?.[item]).filter(([key, value]) => value === false && key.includes("StatTrak")).length * 0.1 +
                            Object.entries(caseData?.ItemEstimates?.[rarity]?.[item]).filter(([key, value]) => value === false && !key.includes("StatTrak")).length) :
                          (caseData?.ItemEstimates?.[rarity]?.[item] === true)
                        ) ? "white" : "rgb(131 131 131)"
                      ) : "white")
                }}>
                  {formatNumber((rarity === "Contraband" ? caseData["KnifeCosts"][item]['totalItemValue'] : caseData["ItemCosts"][rarity][item]['totalItemValue']) / exchangeRate, true)} AVG
                </Typography>
              </div>
            </div>
            {/* <Typography variant="h6" gutterBottom>
        {item.description}
      </Typography> */}
            {/* Add any additional content you want to display in the overlay */}
            {/* <BarChart width={500} height={500} data={itemWears} layout="horizontal">
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis type="number" />
      <YAxis dataKey="name" type="category" />
      <Tooltip />
      <Bar dataKey="value" fill="#8884d8" label={CustomizedLabel}>
          {Object.entries(itemWears).map((entry, index) => (
          <Cell key={`cell-${index}`} fill={"red"} />
          ))}
      </Bar>
      </BarChart> */}
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <img src={caseData["Items"][item]["ImageUrl"]} style={{ height: '150px', margin: 'auto' }} />
              <BarChart style={{ margin: 'auto' }} width={64 * data.length} height={120} data={data} layout="horizontal" barCategoryGap={1} margin={{ top: 30, right: 0, left: 0, bottom: 0 }}>
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <YAxis type="number" hide />
                <XAxis dataKey="name" type="category" tickFormatter={(tick) => smallWearNames[tick]} stroke="RGBA(255,255,255,0.8)" />
                {/* <Tooltip /> */}
                <Bar dataKey="value" fill="#8884d8" label={CustomizedLabel}>
                  {/* {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={"red"} />
          ))} */}
                </Bar>
              </BarChart>
            </div>
            <TableContainer component={Paper} >
              <Table sx={{ width: '100%' }} aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    {Object.entries((rarity == "Contraband" ? caseData["KnifeCosts"][item] : caseData["ItemCosts"][rarity][item])).filter(([key, value]) => key.includes("StatTrak")).length > 0 &&
                      <TableCell component="th" scope="row" sx={{ color: "rgb(207, 106, 50)", textAlign: 'center', margin: '0', fontSize: '0.9rem', lineHeight: '0.8' }}>
                        ST (10x Rarer)
                      </TableCell>
                    }
                    <TableCell component="th" scope="row" sx={{ textAlign: 'center', fontSize: '0.9rem', lineHeight: '0.8' }}>
                      Normal
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries((rarity == "Contraband" ? caseData["KnifeCosts"][item] : caseData["ItemCosts"][rarity][item])).filter(([key, value]) => !key.includes("StatTrak") && !key.includes('totalItemValue')).map(([key, value]) => (
                    <TableRow key={key} style={{ width: '128px', textAlign: 'center' }}>
                      <TableCell key={key} component="th" scope="row" sx={{ textAlign: 'center', fontSize: '0.9rem', lineHeight: '0.8', }} >

                        {smallWearNames[realWearNames[key]]}
                      </TableCell>

                      {Object.entries((rarity == "Contraband" ? caseData["KnifeCosts"][item] : caseData["ItemCosts"][rarity][item])).filter(([key, value]) => key.includes("StatTrak")).length > 0 &&
                        <TableCell key={key} component="th" scope="row" sx={{
                          cursor: 'pointer', textAlign: 'center', fontSize: '0.9rem', lineHeight: '0.8',
                        }}>
                          <a style={{ color: rarity == "Contraband" ? (caseData?.KnifeEstimates?.[item]?.[key] ?? false) ? "rgb(131 131 131)" : "rgb(207, 106, 50)" : (caseData?.ItemEstimates?.[rarity]?.[item]?.[key] ?? false) ? "rgb(131 131 131)" : "rgb(207, 106, 50)", textDecoration: 'none' }} href={caseData["IsCSFloat"] ? csfloatLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], true, rarity === 'Contraband') : caseData["ISTradeit"] ? tradeitLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], true, rarity === 'Contraband') : caseData["IsBuff"] ? buffLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], true, rarity === 'Contraband') : caseData["IsSkinport"] ? skinportLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, caseData, true, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : steamLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], true, rarity === 'Contraband')} target="_blank">

                            <div style={{ textDecoration: 'underline', }}>{formatNumber((rarity == "Contraband" ? caseData["KnifeCosts"][item] : caseData["ItemCosts"][rarity][item])["StatTrak\u2122 " + key] / exchangeRate, true)}</div>
                            <div style={{ display: 'flex', fontSize: '11px', lineHeight: '0.4', marginTop: 'auto', marginBottom: 'auto', padding: '2px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', color: (rarity == "Contraband" ? caseData?.["KnifeROIs"]?.[item] : caseData?.["ItemROIs"]?.[rarity]?.[item])?.["StatTrak\u2122 " + key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] > -0.01 ? "green" : "rgb(187, 23, 26)" }}>{(rarity == "Contraband" ? caseData?.["KnifeROIs"]?.[item] : caseData?.["ItemROIs"]?.[rarity]?.[item])?.["StatTrak\u2122 " + key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] > -0.01 ? <TrendingUpIcon style={{ width: "16px", height: "12px" }} /> : <TrendingDownIcon style={{ width: "16px", height: "12px" }} />}{((rarity == "Contraband" ? caseData?.["KnifeROIs"]?.[item] : caseData?.["ItemROIs"]?.[rarity]?.[item])?.["StatTrak\u2122 " + key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(Math.round((rarity == "Contraband" ? caseData?.["KnifeROIs"]?.[item] : caseData?.["ItemROIs"]?.[rarity]?.[item])?.["StatTrak\u2122 " + key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0)), false)}%</div>
                          </a>
                        </TableCell>

                      }
                      <TableCell key={key} component="th" scope="row" sx={{
                        cursor: 'pointer', textAlign: 'center', fontSize: '0.9rem', lineHeight: '0.8',
                      }}>
                        <a style={{
                          textDecoration: 'none', color: rarity == "Contraband" ? (caseData?.KnifeEstimates?.[item]?.[key] ?? false) ? "rgb(131 131 131)" : "white" : (caseData?.ItemEstimates?.[rarity]?.[item]?.[key] ?? false) ? "rgb(131 131 131)" : "white"
                        }} href={caseData["IsCSFloat"] ? csfloatLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : caseData["ISTradeit"] ? tradeitLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : caseData["IsBuff"] ? buffLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : caseData["IsSkinport"] ? skinportLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, caseData, false, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : steamLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband')} target="_blank">

                          <div style={{ textDecoration: 'underline', }}>{formatNumber(value / exchangeRate, true)}</div>
                          <div style={{ display: 'flex', fontSize: '11px', lineHeight: '0.4', marginTop: 'auto', marginBottom: 'auto', padding: '2px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', color: (rarity == "Contraband" ? caseData?.["KnifeROIs"]?.[item] : caseData?.["ItemROIs"]?.[rarity]?.[item])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] > -0.01 ? "green" : "rgb(187, 23, 26)" }}>{(rarity == "Contraband" ? caseData?.["KnifeROIs"]?.[item] : caseData?.["ItemROIs"]?.[rarity]?.[item])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] > -0.01 ? <TrendingUpIcon style={{ width: "16px", height: "12px" }} /> : <TrendingDownIcon style={{ width: "16px", height: "12px" }} />}{((rarity == "Contraband" ? caseData?.["KnifeROIs"]?.[item] : caseData?.["ItemROIs"]?.[rarity]?.[item])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(Math.round((rarity == "Contraband" ? caseData?.["KnifeROIs"]?.[item] : caseData?.["ItemROIs"]?.[rarity]?.[item])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0)), false)}%</div>
                        </a>
                      </TableCell>

                    </TableRow>
                  ))}
                  {/* {Object.entries((rarity == "Contraband" ? caseData["KnifeCosts"][item] : caseData["ItemCosts"][rarity][item])).filter(([key, value]) => key.includes("StatTrak")).length > 0 ?

                                <TableRow>
                                    
                                    {Object.entries((rarity == "Contraband" ? caseData["KnifeCosts"][item] : caseData["ItemCosts"][rarity][item])).filter(([key, value]) => key.includes("StatTrak")).map(([key, value]) => (
                                        <TableCell key={key} component="th" scope="row" sx={{ color: "rgb(207, 106, 50)", cursor: 'pointer', textDecoration: 'underline', textAlign: 'center' }} onClick={() => window.open(caseData["IsBuff"] ? buffLink(caseData.CollectionType == "Souvenir" ? "Souvenir ": "" + item, realWearNames[key.replace('StatTrak™ ', '')], true, rarity === 'Contraband') : caseData["IsSkinport"] ? skinportLink(caseData.CollectionType == "Souvenir" ? "Souvenir ": "" + caseData.CollectionType == "Souvenir" ? "Souvenir ": "" + item, caseData, true, realWearNames[key.replace('StatTrak™ ', '')], true, rarity === 'Contraband') : steamLink(caseData.CollectionType == "Souvenir" ? "Souvenir ": "" + caseData.CollectionType == "Souvenir" ? "Souvenir ": "" + item, realWearNames[key.replace('StatTrak™ ', '')], true, rarity === 'Contraband'), "_blank")}>
                                            {currencySymbol}{formatNumber(value / exchangeRate)}
                                        </TableCell>
                                    ))}
                                </TableRow> : <div />}
                            <TableRow>
                                
                                {Object.entries((rarity == "Contraband" ? caseData["KnifeCosts"][item] : caseData["ItemCosts"][rarity][item])).filter(([key, value]) => !key.includes("StatTrak") && !key.includes('totalItemValue')).map(([key, value]) => (
                                    <TableCell key={key} component="th" scope="row" onClick={() => window.open(caseData["IsBuff"] ? buffLink((caseData.SouvenirPackName != null ? "Souvenir ": "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : caseData["IsSkinport"] ? skinportLink((caseData.SouvenirPackName != null ? "Souvenir ": "") + item, caseData,  false,  realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : steamLink((caseData.SouvenirPackName != null ? "Souvenir ": "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband'))} sx={{ cursor: 'pointer', textDecoration: 'underline', textAlign: 'center' }}>
                                        {currencySymbol}{formatNumber(value / exchangeRate)}
                                    </TableCell>
                                ))}
                            </TableRow> */}
                  {/* <TableRow>
              <TableCell component="th" rowspan="2" scope="row">
              Total Item Value
              </TableCell>
              <TableCell colSpan={Object.entries((rarity == "Contraband" ? caseData["KnifeCosts"][item]: caseData["ItemCosts"][rarity][item])).filter(([key, value]) => !key.includes("StatTrak")).length}>${totalItemValue.toFixed(2)}</TableCell>
          </TableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
            <a href={skinsearchLink(item, false, caseData?.CollectionType)} style={{ margin: 'auto', marginTop: '4px', height: '70px' }} target="_blank" rel="noopener noreferrer">
              <img src="/assets/skinsearch/300x70v2.jpg" style={{ height: 'auto', objectFit: 'contain', width: '100%', margin: 'auto', marginTop: '8px', height: '75px' }} />
            </a>
          </Paper>
        </Backdrop> :
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, }}
          open={true}
          onClick={onClose}
        >
          <Paper sx={{ p: 2, position: 'relative' }} onClick={handlePaperClick}>
            <IconButton
              sx={{ position: 'absolute', top: 8, right: 8, zIndex: 1 }}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography variant="h6" gutterBottom style={{ margin: 'auto', width: '100%', textAlign: 'center' }}>
                1 in {formatNumber((1 / (caseData["Items"][item]["ModifiedDropChance"] ?? 1)) / (caseData["PercentReceive"][rarity] / (rarity === "Contraband" ? caseData["TotalKnifeChance"] : Object.keys(caseData["ItemCosts"][rarity]).length)))}
              </Typography>
              <Typography variant="h5" gutterBottom style={{ margin: 'auto', width: '100%', textAlign: 'center' }}>
                {item}
              </Typography>
              <Typography variant="h6" gutterBottom style={{
                margin: 'auto', width: '100%', textAlign: 'center', color:
                  (caseData?.ISTradeit ? (rarity === "Contraband" ?
                    (typeof caseData?.KnifeEstimates?.[item] === "object" ?
                      (Object.entries(caseData?.KnifeEstimates?.[item]).filter(([key, value]) => value === true && key.includes("StatTrak")).length * 0.1 +
                        Object.entries(caseData?.KnifeEstimates?.[item]).filter(([key, value]) => value === true && !key.includes("StatTrak")).length <
                        Object.entries(caseData?.KnifeEstimates?.[item]).filter(([key, value]) => value === false && key.includes("StatTrak")).length * 0.1 +
                        Object.entries(caseData?.KnifeEstimates?.[item]).filter(([key, value]) => value === false && !key.includes("StatTrak")).length) :
                      (caseData?.KnifeEstimates?.[item] === true)
                    ) ? "white" : "rgb(131 131 131)" :
                    (typeof caseData?.ItemEstimates?.[rarity]?.[item] === "object" ?
                      (Object.entries(caseData?.ItemEstimates?.[rarity]?.[item]).filter(([key, value]) => value === true && key.includes("StatTrak")).length * 0.1 +
                        Object.entries(caseData?.ItemEstimates?.[rarity]?.[item]).filter(([key, value]) => value === true && !key.includes("StatTrak")).length <
                        Object.entries(caseData?.ItemEstimates?.[rarity]?.[item]).filter(([key, value]) => value === false && key.includes("StatTrak")).length * 0.1 +
                        Object.entries(caseData?.ItemEstimates?.[rarity]?.[item]).filter(([key, value]) => value === false && !key.includes("StatTrak")).length) :
                      (caseData?.ItemEstimates?.[rarity]?.[item] === true)
                    ) ? "white" : "rgb(131 131 131)"
                  ) : "white")
              }}>
                {formatNumber((rarity === "Contraband" ? caseData["KnifeCosts"][item]['totalItemValue'] : caseData["ItemCosts"][rarity][item]['totalItemValue']) / exchangeRate, true)} AVG
              </Typography>
            </div>
            {/* <Typography variant="h6" gutterBottom>
    {item.description}
  </Typography> */}
            {/* Add any additional content you want to display in the overlay */}
            {/* <BarChart width={500} height={500} data={itemWears} layout="horizontal">
  <CartesianGrid strokeDasharray="3 3" />
  <XAxis type="number" />
  <YAxis dataKey="name" type="category" />
  <Tooltip />
  <Bar dataKey="value" fill="#8884d8" label={CustomizedLabel}>
      {Object.entries(itemWears).map((entry, index) => (
      <Cell key={`cell-${index}`} fill={"red"} />
      ))}
  </Bar>
  </BarChart> */}
            <div style={{ display: 'flex', flexDirection: 'horizontal', }}>
              <img src={caseData["Items"][item]["ImageUrl"]} style={{ height: '250px' }} />
              <BarChart style={{ marginLeft: 'auto' }} width={128 * data.length} height={250} data={data} layout="horizontal" barCategoryGap={1} margin={{ top: 50, right: 0, left: 0, bottom: 0 }}>
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <YAxis type="number" hide />
                <XAxis dataKey="name" type="category" width={150} hide />
                {/* <Tooltip /> */}
                <Bar dataKey="value" fill="#8884d8" label={CustomizedLabel}>
                  {/* {data.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={"red"} />
      ))} */}
                </Bar>
              </BarChart>
            </div>
            <TableContainer component={Paper} >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    {Object.entries((rarity == "Contraband" ? caseData["KnifeCosts"][item] : caseData["ItemCosts"][rarity][item])).filter(([key, value]) => !key.includes("StatTrak") && !key.includes('totalItemValue')).map(([key, value]) => (
                      <TableCell key={key} style={{ width: '128px', textAlign: 'center' }}>{realWearNames[key]}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries((rarity == "Contraband" ? caseData["KnifeCosts"][item] : caseData["ItemCosts"][rarity][item])).filter(([key, value]) => key.includes("StatTrak")).length > 0 ?

                    <TableRow>
                      <TableCell component="th" scope="row" sx={{ color: "rgb(207, 106, 50)" }}>
                        ST (10x Rarer)
                      </TableCell>
                      {Object.entries((rarity == "Contraband" ? caseData["KnifeCosts"][item] : caseData["ItemCosts"][rarity][item])).filter(([key, value]) => key.includes("StatTrak")).map(([key, value]) => (
                        <TableCell key={key} component="th" scope="row" sx={{
                          color: rarity == "Contraband" ? (caseData?.KnifeEstimates?.[item]?.[key] ?? false) ? "rgb(131 131 131)" : "rgb(207, 106, 50)" : (caseData?.ItemEstimates?.[rarity]?.[item]?.[key] ?? false) ? "rgb(131 131 131)" : "rgb(207, 106, 50)",
                          cursor: 'pointer', textAlign: 'center',
                        }}>
                          <a style={{ color: rarity == "Contraband" ? (caseData?.KnifeEstimates?.[item]?.[key] ?? false) ? "rgb(131 131 131)" : "rgb(207, 106, 50)" : (caseData?.ItemEstimates?.[rarity]?.[item]?.[key] ?? false) ? "rgb(131 131 131)" : "rgb(207, 106, 50)", textDecoration: 'none', }} href={caseData["IsCSFloat"] ? csfloatLink(caseData.CollectionType == "Souvenir" ? "Souvenir " : "" + item, realWearNames[key.replace('StatTrak™ ', '')], true, rarity === 'Contraband') : caseData["ISTradeit"] ? tradeitLink(caseData.CollectionType == "Souvenir" ? "Souvenir " : "" + item, realWearNames[key.replace('StatTrak™ ', '')], true, rarity === 'Contraband') : caseData["IsBuff"] ? buffLink(caseData.CollectionType == "Souvenir" ? "Souvenir " : "" + item, realWearNames[key.replace('StatTrak™ ', '')], true, rarity === 'Contraband') : caseData["IsSkinport"] ? skinportLink(caseData.CollectionType == "Souvenir" ? "Souvenir " : "" + caseData.CollectionType == "Souvenir" ? "Souvenir " : "" + item, caseData, true, realWearNames[key.replace('StatTrak™ ', '')], true, rarity === 'Contraband') : steamLink(caseData.CollectionType == "Souvenir" ? "Souvenir " : "" + caseData.CollectionType == "Souvenir" ? "Souvenir " : "" + item, realWearNames[key.replace('StatTrak™ ', '')], true, rarity === 'Contraband')} target="_blank">

                            <div style={{ textDecoration: 'underline', }}>{formatNumber(value / exchangeRate, true)}</div>
                            <div style={{ display: 'flex', fontSize: '12px', lineHeight: '0.4', marginTop: '-3px', marginBottom: 'auto', padding: '2px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', color: ((rarity == "Contraband" ? caseData?.["KnifeROIs"]?.[item] : caseData?.["ItemROIs"]?.[rarity]?.[item])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0) > -0.01 ? "green" : "rgb(187, 23, 26)" }}>{((rarity == "Contraband" ? caseData?.["KnifeROIs"]?.[item] : caseData?.["ItemROIs"]?.[rarity]?.[item])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0) > -0.01 ? <TrendingUpIcon style={{ width: "16px", height: "12px" }} /> : <TrendingDownIcon style={{ width: "16px", height: "12px" }} />}{((rarity == "Contraband" ? caseData?.["KnifeROIs"]?.[item] : caseData?.["ItemROIs"]?.[rarity]?.[item])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(Math.round((rarity == "Contraband" ? caseData?.["KnifeROIs"]?.[item] : caseData?.["ItemROIs"]?.[rarity]?.[item])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0)), false)}%</div>
                          </a>
                        </TableCell>

                      ))}
                    </TableRow> : <div />}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Normal
                    </TableCell>
                    {Object.entries((rarity == "Contraband" ? caseData["KnifeCosts"][item] : caseData["ItemCosts"][rarity][item])).filter(([key, value]) => !key.includes("StatTrak") && !key.includes('totalItemValue')).map(([key, value]) => (
                      <TableCell key={key} component="th" scope="row" sx={{
                        cursor: 'pointer', textAlign: 'center',
                      }}>
                        <a style={{ color: rarity == "Contraband" ? (caseData?.KnifeEstimates?.[item]?.[key] ?? false) ? "rgb(131 131 131)" : "white" : (caseData?.ItemEstimates?.[rarity]?.[item]?.[key] ?? false) ? "rgb(131 131 131)" : "white", textDecoration: 'none', }} href={caseData["IsCSFloat"] ? csfloatLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : caseData["ISTradeit"] ? tradeitLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : caseData["IsBuff"] ? buffLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : caseData["IsSkinport"] ? skinportLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, caseData, false, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : steamLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband')} target="_blank">
                          <div style={{ textDecoration: 'underline', }}>{formatNumber(value / exchangeRate, true)}</div>
                          <div style={{ display: 'flex', fontSize: '12px', lineHeight: '0.4', marginTop: '-3px', marginBottom: 'auto', padding: '2px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', color: ((rarity == "Contraband" ? caseData?.["KnifeROIs"]?.[item] : caseData?.["ItemROIs"]?.[rarity]?.[item])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0) > -0.01 ? "green" : "rgb(187, 23, 26)" }}>{((rarity == "Contraband" ? caseData?.["KnifeROIs"]?.[item] : caseData["ItemROIs"]?.[rarity]?.[item])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0) > -0.01 ? <TrendingUpIcon style={{ width: "16px", height: "12px" }} /> : <TrendingDownIcon style={{ width: "16px", height: "12px" }} />}{((rarity == "Contraband" ? caseData?.["KnifeROIs"]?.[item] : caseData?.["ItemROIs"]?.[rarity]?.[item])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(Math.round((rarity == "Contraband" ? caseData?.["KnifeROIs"]?.[item] : caseData?.["ItemROIs"]?.[rarity]?.[item])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0)), false)}%</div>
                        </a>
                      </TableCell>
                    ))}
                  </TableRow>
                  {/* <TableRow>
          <TableCell component="th" rowspan="2" scope="row">
          Total Item Value
          </TableCell>
          <TableCell colSpan={Object.entries((rarity == "Contraband" ? caseData["KnifeCosts"][item]: caseData["ItemCosts"][rarity][item])).filter(([key, value]) => !key.includes("StatTrak")).length}>${totalItemValue.toFixed(2)}</TableCell>
      </TableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
            <a href={skinsearchLink(item, false, caseData?.CollectionType)} style={{ margin: 'auto', marginTop: '4px', height: '75px' }} target="_blank" rel="noopener noreferrer">
              <img src="/assets/skinsearch/750x75.jpg" style={{ height: 'auto', objectFit: 'contain', width: '100%', margin: 'auto', marginTop: '8px', marginBottom: '-12px', height: '75px', maxHeight: '6vh' }} />
            </a>

          </Paper>
        </Backdrop>
    );
  }

  // const [allRaritySizeRefs, setAllRaritySizeRefs] = useState({});

  useEffect(() => {
    // const newRefs = {};

    // for (const [rarity, value] of Object.entries(caseData['RarityROIPercents'])) {
    // newRefs[rarity] = React.createRef();
    // }

    // setHoverRarity(Object.keys(caseData['RarityROIPercents']).includes('Contraband')
    //     ? Object.keys(caseData['RarityROIPercents'])[1]
    //     : Object.keys(caseData['RarityROIPercents'])[0]);

    // setHoverItem(Object.keys(caseData["ItemCosts"][Object.keys(caseData['RarityROIPercents']).includes("Contraband") ? Object.keys(caseData['RarityROIPercents'])[1] : Object.keys(caseData['RarityROIPercents'])[0]])[0]);

    // Update the state with the new ref objects
    // setAllRaritySizeRefs(newRefs);
    // const handleResize = () => {
    //     setWindowSize({
    //         width: window.innerWidth,
    //         height: window.innerHeight,
    //     });
    // };

    // // Add a window resize event listener
    // window.addEventListener('resize', handleResize);

    // // Cleanup the event listener when the component unmounts
    // return () => {
    //     window.removeEventListener('resize', handleResize);
    // };
  }, []);

  // const [windowSize, setWindowSize] = useState({
  //     width: window.innerWidth,
  //     height: window.innerHeight,
  // });

  useEffect(() => {
    forceUpdate({});
  }, []);

  return (

    // hoverRarity &&
    (isMobile ?
      <Container width="100%" maxWidth="none" sx={{ padding: 0 }} >
        {selectedItem && (
          <Overlay item={selectedItem} rarity={selectedRarity} onClose={handleCloseOverlay} />
        )}
        <div style={{ display: 'flex', height: '80vh', flexDirection: 'column', }} >
          {Object.entries(caseData['RarityROIPercents']).map(([rarity, value], index) => {
            const originalPageSize = 90; // Assuming 3 items per column
            const extraSize = Math.max(0, ((rarity == "Contraband" ? Object.keys(caseData['KnifeCosts']).length : Object.keys(caseData['ItemCosts'][rarity]).length) - 3) * (originalPageSize / 3));
            const pageSize = Math.max(100, originalPageSize + extraSize);
            const maxTotalItemValue = Math.max(
              ...Object.entries(rarity == "Contraband" ? caseData['KnifeCosts'] : caseData['ItemCosts'][rarity]).map(
                ([name, item]) => item.totalItemValue * (caseData["Items"][name]["ModifiedDropChance"] ?? 1)
              )
            );
            return (
              <div style={{
                width: '100%',
                height: `${value * 100}%`,
                position: 'relative',
                marginRight: "-2px",
                key: { rarity }
              }}>

                <Grid
                  key={rarity}
                  item
                  xs={Math.floor((value * originalPageSize) / 6)} // Calculate xs based on your flexRatio
                  // ref={allRaritySizeRefs[rarity]}
                  style={{
                    height: '100%',
                    width: `100%`,
                    background: `linear-gradient(145deg, RGB(${redRarityNums[Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity]}, ${greenRarityNums[Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity]}, ${blueRarityNums[Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity]}) 20%, black 100%)`,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column', margin: 'auto', alignItems: 'center',
                    justifyContent: 'center',
                    // top: `${Math.max(2, Math.min(4, value * 7))}rem`,
                    left: '2%',
                    // paddingLeft: '40px',
                    // top: '46%',
                    // transform: 'translate(-50%, -50%)',
                    // paddingTop: '4px',
                    zIndex: 2, position: 'absolute', pointerEvents: 'none',
                    width: '70px'
                  }}>
                    <h1 style={{ lineHeight: 1, margin: 'auto', fontWeight: 'bold', letterSpacing: '-1px', fontSize: `${Math.max(1.2, Math.min(1.6, value * 5))}rem` }}>{(value * 100).toFixed(1)}%</h1>
                    <h1 style={{ lineHeight: 0, textAlign: 'center', fontWeight: 'bold', fontSize: `${Math.max(0.75, Math.min(1, value * 3))}rem` }}>1 in {formatNumber(1 / (caseData["PercentReceive"][rarity]))}</h1>
                  </div>
                  {/* <h2 style={{lineHeight: 0}}>{rarity.replace('Contraband', 'Gloves / Knives').replace('_', '-')}</h2> */}

                  <div className="column" style={{ height: '100%', width: '100%' }}>
                    <Scrollbars
                      autoHide
                      autoHideTimeout={1000}
                      autoHideDuration={200}

                      sx={{
                        maxHeight: '100%', display: 'flex', width: '100%'
                      }}
                      // initialScrollTop={300}
                      renderThumbHorizontal={({ style }) => (
                        <div style={{ ...style, backgroundColor: 'lightgray', borderRadius: '4px', }} />
                      )}
                      renderView={({ style }) => (
                        <div style={{ ...style, height: '100%', display: 'flex', }} />
                      )}
                    >
                      {/* Content for each column */}
                      {/* <div style={{ height: '2vh' }}> */}

                      {/* </div> */}

                      <div style={{ height: '100%', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', flexDirection: 'column', marginRight: '48px' }}>
                        {/* <div style={{ width: '100px' }}> */}

                        {/* </div> */}
                        <div style={{ width: '20vw', height: '100%' }}>

                        </div>
                        {Object.entries(rarity == "Contraband" ? caseData['KnifeCosts'] : caseData['ItemCosts'][rarity]).sort((a, b) => b[1].totalItemValue * (caseData["Items"][b[0]]["ModifiedDropChance"] ?? 1) - a[1].totalItemValue * (caseData["Items"][a[0]]["ModifiedDropChance"] ?? 1)).map(([name, item], index) => {

                          const modifiedDropChance = caseData["Items"][name]["ModifiedDropChance"] ?? 1;
                          const flexRatio = (item.totalItemValue * modifiedDropChance) / maxTotalItemValue;
                          // const ogWidth = allRaritySizeRefs[rarity]?.current?.offsetWidth > allRaritySizeRefs[rarity]?.current?.offsetHeight ?
                          //     (allRaritySizeRefs[rarity]?.current?.offsetWidth ?? 0) - (allRaritySizeRefs[rarity]?.current?.offsetHeight ?? 0) < 0.7 * (allRaritySizeRefs[rarity]?.current?.offsetHeight ?? 0) ?
                          //         (allRaritySizeRefs[rarity]?.current?.offsetHeight ?? 0) :
                          //         (allRaritySizeRefs[rarity]?.current?.offsetHeight ?? 0) :
                          //     (allRaritySizeRefs[rarity]?.current?.offsetHeight ?? 0) - (allRaritySizeRefs[rarity]?.current?.offsetWidth ?? 0) < 0.7 * (allRaritySizeRefs[rarity]?.current?.offsetHeight ?? 0) ?
                          //         (allRaritySizeRefs[rarity]?.current?.offsetWidth ?? 0) :
                          //         (allRaritySizeRefs[rarity]?.current?.offsetWidth ?? 0)
                          const ogWidth = 1 * value;
                          var imageWidth = value > 0.1 ? value > 0.2 ? value > 0.3 ? value > 0.4 ? value > 0.6 ? value > 0.8 ?
                            (ogWidth ?? 0) * 0.3 :
                            (ogWidth ?? 0) * 0.35 :
                            (ogWidth ?? 0) * 0.45 :
                            (ogWidth ?? 0) * 0.6 :
                            (ogWidth ?? 0) * 0.75 :
                            (ogWidth ?? 0) * 0.8 :
                            (ogWidth ?? 0) * 0.9
                          if (Object.entries(rarity == "Contraband" ? caseData['KnifeCosts'] : caseData['ItemCosts'][rarity]).sort((a, b) => b[1].totalItemValue - a[1].totalItemValue).length === 1) {
                            imageWidth = ogWidth * 0.8;
                          }
                          // (ogWidth ?? 0)
                          var finalAreaWidth = Math.sqrt((imageWidth * imageWidth) * flexRatio)
                          if ((Object.keys(caseData?.['ItemCosts']?.[rarity] ?? [])?.length ?? 0) === 1) {
                            if (value > 0.5) {
                              finalAreaWidth *= 0.5;
                            } else if (value > 0.3) {
                              finalAreaWidth *= 0.8;
                            }

                          }
                          return (
                            <div className="corner-outlines-mob" style={{
                              height: (finalAreaWidth * 90).toString() + 'vh',
                              width: (finalAreaWidth * 90).toString() + 'vh',
                              // '--w': `${finalAreaWidth * 0.2}px`, '--b': `${finalAreaWidth * 0.02}px`,
                            }} key={name}>
                              {Object.keys(caseData?.ItemCosts?.[rarity]?.[name] ?? []).length > 1 || rarity == "Contraband" ?
                                <div
                                  //   key={name}
                                  //   item
                                  //   xs={Math.max(40,Math.floor(flexRatio * 12))} // 12 columns in the grid system
                                  onMouseEnter={() => handleHover(name, rarity)}

                                  onClick={() => handleItemClick(name, rarity)}
                                  // onMouseLeave={}
                                  style={{
                                    cursor: 'pointer',
                                    background: `url(${caseData["Items"][name]["ImageUrl"]}) center/contain no-repeat`,
                                    // flex: flexRatio,

                                    // minWidth: `${Math.floorvalue * 100}%`,
                                    height: (finalAreaWidth * 90).toString() + 'vh',
                                    width: (finalAreaWidth * 90).toString() + 'vh',

                                  }}
                                >
                                  {/* Your content */}
                                </div> :
                                <a href={caseData["IsCSFloat"] ? csfloatLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + name) : caseData["ISTradeit"] ? tradeitLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + name) : caseData["IsBuff"] ? buffLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + name) : caseData["IsSkinport"] ? skinportLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + name, caseData) : steamLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + name)} target="_blank">
                                  <div
                                    //   key={name}
                                    //   item
                                    //   xs={Math.max(40,Math.floor(flexRatio * 12))} // 12 columns in the grid system
                                    onMouseEnter={() => handleHover(name, rarity)}

                                    // onClick={() => handleItemClick(name, rarity)}
                                    // onMouseLeave={}
                                    style={{
                                      cursor: 'pointer',
                                      background: `url(${caseData["Items"][name]["ImageUrl"]}) center/contain no-repeat`,
                                      // flex: flexRatio,

                                      // minWidth: `${Math.floorvalue * 100}%`,
                                      height: (finalAreaWidth * 90).toString() + 'vh',
                                      width: (finalAreaWidth * 90).toString() + 'vh',

                                    }}
                                  >
                                    {/* Your content */}
                                  </div>
                                </a>
                              }
                            </div>
                          );
                        })}
                        <div style={{ width: '20vw', height: '100%' }}>

                        </div>
                      </div>

                    </Scrollbars>
                  </div>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column', margin: 'auto', alignItems: 'center',
                    justifyContent: 'center',
                    // bottom: `-${Math.max(1.5, Math.min(4, value * 10)) - 1}rem`,
                    right: '4%',
                    width: '60px',
                    // top: '20%',
                    // height: '44px',
                    // transform: 'translate(-50%, -50%)',
                    // paddingTop: '4px',
                    zIndex: 2, position: 'absolute', pointerEvents: 'none'
                  }}>
                    <h1 style={{ lineHeight: 1, margin: 'auto', fontWeight: 'bold', fontSize: `${Math.max(0.5, Math.min(0.8, value * 3))}rem`, }}>AVG:</h1>
                    <h1 style={{ lineHeight: 0, paddingBottom: '0', fontWeight: 'bold', fontSize: `${Math.max(1, Math.min(1.6, value * 5))}rem`, }}>{formatNumber(caseData['RarityValue'][rarity] / exchangeRate, true)}</h1>
                  </div>
                </Grid>

              </div>
            );
          })}

        </div>
        {/* <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '15vh', background: `linear-gradient(325deg, RGB(${redRarityNums[Object.keys(otherRarityNames).includes(hoverRarity) ? otherRarityNames[hoverRarity] : hoverRarity]}, ${greenRarityNums[Object.keys(otherRarityNames).includes(hoverRarity) ? otherRarityNames[hoverRarity] : hoverRarity]}, ${blueRarityNums[Object.keys(otherRarityNames).includes(hoverRarity) ? otherRarityNames[hoverRarity] : hoverRarity]}) 20%, black 100%)`, }}>
                <img src={caseData["Items"][hoverItem]["ImageUrl"]} style={{ height: 'inherit', margin: 'auto' }} />
                <h1 style={{ margin: 'auto', textAlign: 'center' }}>{hoverItem}</h1>
                <h1 style={{ margin: 'auto', textAlign: 'center' }}>{currencySymbol}{formatNumber((hoverRarity === "Contraband" ? caseData["KnifeCosts"][hoverItem]['totalItemValue'] : caseData["ItemCosts"][hoverRarity][hoverItem]['totalItemValue']) / exchangeRate)} every {formatNumber(1 / (caseData["PercentReceive"][hoverRarity] / (hoverRarity === "Contraband" ? Object.keys(caseData["KnifeCosts"]).length : Object.keys(caseData["ItemCosts"][hoverRarity]).length)))}</h1>
            </div> */}
      </Container>
      :
      <Container width="100%" maxWidth="none"  >
        {selectedItem && (
          <Overlay item={selectedItem} rarity={selectedRarity} onClose={handleCloseOverlay} />
        )}
        <div style={{ display: 'flex' }}>
          {Object.entries(caseData['RarityROIPercents']).map(([rarity, value], index) => {
            const originalPageSize = 90; // Assuming 3 items per column
            const extraSize = Math.max(0, ((rarity == "Contraband" ? Object.keys(caseData['KnifeCosts']).length : Object.keys(caseData['ItemCosts'][rarity]).length) - 3) * (originalPageSize / 3));
            const pageSize = Math.max(100, originalPageSize + extraSize);
            const maxTotalItemValue = Math.max(
              ...Object.entries(rarity == "Contraband" ? caseData['KnifeCosts'] : caseData['ItemCosts'][rarity]).map(
                ([name, item]) => item.totalItemValue * (caseData["Items"][name]["ModifiedDropChance"] ?? 1)
              )
            );
            return (
              <div style={{
                height: '70vh',
                width: `${value * 100}%`,
                position: 'relative',
                marginRight: "-2px",
                key: { rarity }
              }}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column', margin: 'auto', alignItems: 'center',
                  justifyContent: 'center', top: `${Math.max(2, Math.min(4, value * 7))}rem`,
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  paddingTop: '4px',
                  zIndex: 2, position: 'absolute', pointerEvents: 'none',
                  width: '300px'
                }}>
                  <h1 style={{ lineHeight: 1, margin: 'auto', fontWeight: 'bold', letterSpacing: '-1px', fontSize: `${Math.max(1.8, Math.min(4.5, value * 8))}rem` }}>{(value * 100).toFixed(1)}%</h1>
                  <h1 style={{ lineHeight: 0, textAlign: 'center', fontWeight: 'bold', fontSize: `${Math.max(0.9, Math.min(2, value * 4.5))}rem` }}>1 in {formatNumber(1 / (caseData["PercentReceive"][rarity]))}</h1>
                </div>
                <Grid
                  key={rarity}
                  item
                  xs={Math.floor((value * originalPageSize) / 6)} // Calculate xs based on your flexRatio
                  // ref={allRaritySizeRefs[rarity]}
                  style={{
                    height: '100%',
                    width: `100%`,
                    background: `linear-gradient(145deg, RGB(${redRarityNums[Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity]}, ${greenRarityNums[Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity]}, ${blueRarityNums[Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity]}) 20%, black 100%)`,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {/* <h2 style={{lineHeight: 0}}>{rarity.replace('Contraband', 'Gloves / Knives').replace('_', '-')}</h2> */}

                  <div className="column" style={{ height: '100%', width: '100%' }}>
                    <Scrollbars
                      autoHide
                      autoHideTimeout={1000}
                      autoHideDuration={200}

                      // initialScrollTop={300}
                      renderThumbVertical={({ style }) => (
                        <div style={{ ...style, backgroundColor: 'lightgray', borderRadius: '4px', }} />
                      )}
                      renderView={({ style }) => (
                        <div style={{ ...style, display: 'flex', alignItems: 'center' }} />
                      )}
                    >
                      {/* Content for each column */}
                      {/* <div style={{ height: '2vh' }}> */}

                      {/* </div> */}
                      {/* <div style={{ height: '5vh' }}> */}

                      {/* </div> */}
                      <div style={{ width: '100%', maxHeight: '100%', margin: 0, display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-evenly' }}>
                        <div style={{ height: '10vh', width: '100%' }}>

                        </div>
                        {Object.entries(rarity == "Contraband" ? caseData['KnifeCosts'] : caseData['ItemCosts'][rarity]).sort((a, b) => b[1].totalItemValue * (caseData["Items"][b[0]]["ModifiedDropChance"] ?? 1) - a[1].totalItemValue * (caseData["Items"][a[0]]["ModifiedDropChance"] ?? 1)).map(([name, item], index) => {

                          const modifiedDropChance = caseData["Items"][name]["ModifiedDropChance"] ?? 1;
                          const flexRatio = (item.totalItemValue * modifiedDropChance) / maxTotalItemValue;
                          // const ogWidth = allRaritySizeRefs[rarity]?.current?.offsetWidth > allRaritySizeRefs[rarity]?.current?.offsetHeight ?
                          //     (allRaritySizeRefs[rarity]?.current?.offsetWidth ?? 0) - (allRaritySizeRefs[rarity]?.current?.offsetHeight ?? 0) < 0.7 * (allRaritySizeRefs[rarity]?.current?.offsetHeight ?? 0) ?
                          //         (allRaritySizeRefs[rarity]?.current?.offsetHeight ?? 0) :
                          //         (allRaritySizeRefs[rarity]?.current?.offsetHeight ?? 0) :
                          //     (allRaritySizeRefs[rarity]?.current?.offsetHeight ?? 0) - (allRaritySizeRefs[rarity]?.current?.offsetWidth ?? 0) < 0.7 * (allRaritySizeRefs[rarity]?.current?.offsetHeight ?? 0) ?
                          //         (allRaritySizeRefs[rarity]?.current?.offsetWidth ?? 0) :
                          //         (allRaritySizeRefs[rarity]?.current?.offsetWidth ?? 0)
                          const ogWidth = 1 * value;
                          var imageWidth = value > 0.1 ? value > 0.2 ? value > 0.3 ? value > 0.4 ? value > 0.6 ? value > 0.8 ?
                            (ogWidth ?? 0) * 0.2 :
                            (ogWidth ?? 0) * 0.25 :
                            (ogWidth ?? 0) * 0.35 :
                            (ogWidth ?? 0) * 0.5 :
                            (ogWidth ?? 0) * 0.7 :
                            (ogWidth ?? 0) * 0.9 :
                            (ogWidth ?? 0) * 0.95
                          if (Object.entries(rarity == "Contraband" ? caseData['KnifeCosts'] : caseData['ItemCosts'][rarity]).sort((a, b) => b[1].totalItemValue * (caseData["Items"][b[0]]["ModifiedDropChance"] ?? 1) - a[1].totalItemValue * (caseData["Items"][a[0]]["ModifiedDropChance"] ?? 1)).length === 1) {
                            imageWidth = ogWidth * 0.8;
                          }
                          // (ogWidth ?? 0)
                          var finalAreaWidth = Math.sqrt((imageWidth * imageWidth) * flexRatio)
                          if ((Object.keys(caseData?.['ItemCosts']?.[rarity] ?? [])?.length ?? 0) === 1) {
                            if (value > 0.5) {
                              finalAreaWidth *= 0.5;
                            } else if (value > 0.3) {
                              finalAreaWidth *= 0.8;
                            }

                          }
                          return (
                            Object.keys(caseData?.ItemCosts?.[rarity]?.[name] ?? []).length > 1 || rarity == "Contraband" ?
                              <div className="corner-outlines" style={{
                                height: (finalAreaWidth * 80).toString() + 'vw',
                                width: (finalAreaWidth * 80).toString() + 'vw',
                                // '--w': `${finalAreaWidth * 0.2}px`, '--b': `${finalAreaWidth * 0.02}px`,
                              }} key={name}>


                                <div
                                  //   key={name}
                                  //   item
                                  //   xs={Math.max(40,Math.floor(flexRatio * 12))} // 12 columns in the grid system
                                  onMouseEnter={() => handleHover(name, rarity)}

                                  onClick={() => handleItemClick(name, rarity)}
                                  // onMouseLeave={}
                                  style={{
                                    cursor: 'pointer',
                                    background: `url(${caseData["Items"][name]["ImageUrl"]}) center/contain no-repeat`,
                                    // flex: flexRatio,

                                    // minWidth: `${Math.floorvalue * 100}%`,
                                    height: (finalAreaWidth * 80).toString() + 'vw',
                                    width: (finalAreaWidth * 80).toString() + 'vw',

                                  }}
                                >
                                  {/* Your content */}
                                </div>
                              </div> :
                              <a href={caseData["IsCSFloat"] ? csfloatLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + name) : caseData["ISTradeit"] ? tradeitLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + name) : caseData["IsBuff"] ? buffLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + name) : caseData["IsSkinport"] ? skinportLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + name, caseData) : steamLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + name)} target="_blank">

                                <div className="corner-outlines" style={{
                                  height: (finalAreaWidth * 80).toString() + 'vw',
                                  width: (finalAreaWidth * 80).toString() + 'vw',
                                  // '--w': `${finalAreaWidth * 0.2}px`, '--b': `${finalAreaWidth * 0.02}px`,
                                }} key={name}>


                                  <div
                                    //   key={name}
                                    //   item
                                    //   xs={Math.max(40,Math.floor(flexRatio * 12))} // 12 columns in the grid system
                                    onMouseEnter={() => handleHover(name, rarity)}


                                    // onMouseLeave={}
                                    style={{
                                      cursor: 'pointer',
                                      background: `url(${caseData["Items"][name]["ImageUrl"]}) center/contain no-repeat`,
                                      // flex: flexRatio,

                                      // minWidth: `${Math.floorvalue * 100}%`,
                                      height: (finalAreaWidth * 80).toString() + 'vw',
                                      width: (finalAreaWidth * 80).toString() + 'vw',

                                    }}
                                  >
                                    {/* Your content */}
                                  </div>
                                </div>
                              </a>
                          );
                        })}
                        <div style={{ height: '10vh', width: '100%' }}>

                        </div>
                      </div>

                    </Scrollbars>
                  </div>
                </Grid>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column', margin: 'auto', alignItems: 'center',
                  justifyContent: 'center', bottom: `-${Math.max(1.5, Math.min(4, value * 10)) - 1}rem`,
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  // paddingTop: '4px',
                  zIndex: 2, position: 'absolute', pointerEvents: 'none'
                }}>
                  <h1 style={{ lineHeight: 1, margin: 'auto', fontWeight: 'bold', fontSize: `${Math.max(0.6, Math.min(2, value * 4.5))}rem`, }}>AVG:</h1>
                  <h1 style={{ lineHeight: 0, paddingBottom: '0.5rem', fontWeight: 'bold', fontSize: `${Math.max(1.4, Math.min(4.5, value * 8))}rem`, }}>{formatNumber(caseData['RarityValue'][rarity] / exchangeRate, true)}</h1>
                </div>
              </div>
            );
          })}

        </div>
        {hoverItem != null ? <div onMouseEnter={() => handleHover(null, null)} style={{ display: 'flex', flexDirection: 'row', height: '15vh', background: `linear-gradient(325deg, RGB(${redRarityNums[Object.keys(otherRarityNames).includes(hoverRarity) ? otherRarityNames[hoverRarity] : hoverRarity]}, ${greenRarityNums[Object.keys(otherRarityNames).includes(hoverRarity) ? otherRarityNames[hoverRarity] : hoverRarity]}, ${blueRarityNums[Object.keys(otherRarityNames).includes(hoverRarity) ? otherRarityNames[hoverRarity] : hoverRarity]}) 20%, black 100%)`, marginRight: (((2) * Object.keys(caseData?.PercentReceive ?? []).length - 1) + "px") }}>
          <img src={caseData["Items"][hoverItem]["ImageUrl"]} style={{ height: 'inherit', margin: 'auto' }} />
          <h1 style={{ margin: 'auto', textAlign: 'center' }}>{hoverItem}</h1>
          <h1 style={{ margin: 'auto', textAlign: 'center', display: 'flex', flexDirection: 'row' }}>
            <div style={{
              marginRight: '7px',
              marginTop: '0px',
              color:
                (caseData?.ISTradeit ? (hoverRarity === "Contraband" ?
                  (typeof caseData?.KnifeEstimates?.[hoverItem] === "object" ?
                    (Object.entries(caseData?.KnifeEstimates?.[hoverItem]).filter(([key, value]) => value === true && key.includes("StatTrak")).length * 0.1 +
                      Object.entries(caseData?.KnifeEstimates?.[hoverItem]).filter(([key, value]) => value === true && !key.includes("StatTrak")).length <
                      Object.entries(caseData?.KnifeEstimates?.[hoverItem]).filter(([key, value]) => value === false && key.includes("StatTrak")).length * 0.1 +
                      Object.entries(caseData?.KnifeEstimates?.[hoverItem]).filter(([key, value]) => value === false && !key.includes("StatTrak")).length) :
                    (caseData?.KnifeEstimates?.[hoverItem] === false)
                  ) ? "white" : "rgb(131 131 131)" :
                  (typeof caseData?.ItemEstimates?.[hoverRarity]?.[hoverItem] === "object" ?
                    (Object.entries(caseData?.ItemEstimates?.[hoverRarity]?.[hoverItem]).filter(([key, value]) => value === true && key.includes("StatTrak")).length * 0.1 +
                      Object.entries(caseData?.ItemEstimates?.[hoverRarity]?.[hoverItem]).filter(([key, value]) => value === true && !key.includes("StatTrak")).length <
                      Object.entries(caseData?.ItemEstimates?.[hoverRarity]?.[hoverItem]).filter(([key, value]) => value === false && key.includes("StatTrak")).length * 0.1 +
                      Object.entries(caseData?.ItemEstimates?.[hoverRarity]?.[hoverItem]).filter(([key, value]) => value === false && !key.includes("StatTrak")).length) :
                    (caseData?.ItemEstimates?.[hoverRarity]?.[hoverItem] === false)
                  ) ? "white" : "rgb(131 131 131)"
                ) : "white")
            }}>

              {formatNumber((hoverRarity === "Contraband" ? caseData["KnifeCosts"][hoverItem]['totalItemValue'] : caseData["ItemCosts"][hoverRarity][hoverItem]['totalItemValue']) / exchangeRate, true)}
              <div style={{ display: 'flex', fontSize: isMobile ? '16px' : '18px', lineHeight: '0.5', marginTop: '-8px', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>{(hoverRarity === "Contraband" ? caseData?.["KnifeROIs"]?.[hoverItem]?.["totalItemValue"]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] : caseData?.["ItemROIs"]?.[hoverRarity]?.[hoverItem]?.["totalItemValue"]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"]) > -0.01 ? <TrendingUpIcon style={{ width: isMobile ? "20px" : '26px', height: isMobile ? "16px" : "20px" }} /> : <TrendingDownIcon style={{ width: isMobile ? "20px" : '26px', height: isMobile ? "16px" : "20px" }} />}{(hoverRarity === "Contraband" ? caseData?.["KnifeROIs"]?.[hoverItem]?.["totalItemValue"]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] : caseData?.["ItemROIs"]?.[hoverRarity]?.[hoverItem]?.["totalItemValue"]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.round(Math.abs(hoverRarity === "Contraband" ? caseData?.["KnifeROIs"]?.[hoverItem]?.["totalItemValue"]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0 : caseData?.["ItemROIs"]?.[hoverRarity]?.[hoverItem]?.["totalItemValue"]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0)), false, 0)}%</div>

            </div>  every {formatNumber((1 / (caseData["Items"][hoverItem]["ModifiedDropChance"] ?? 1)) / (caseData["PercentReceive"][hoverRarity] / (hoverRarity === "Contraband" ? caseData["TotalKnifeChance"] : Object.keys(caseData["ItemCosts"][hoverRarity]).length)))}

          </h1>
        </div> :
          <div style={{ display: 'flex', flexDirection: 'row', height: '15vh', justifyContent: 'space-around', marginRight: (((2) * Object.keys(caseData?.PercentReceive ?? []).length - 1) + "px") }}>
            <img src={caseData.ImageUrl} style={{ margin: 'auto', height: 'inherit' }} />
            <h1 style={{ textAlign: 'center', margin: 'auto' }}>{caseData.Case}
            </h1>
            {caseData.CaseCost !== 0.01 && <div style={{ lineHeight: 0, margin: 'auto', fontSize: '1rem' }}>
              <h1 style={{ lineHeight: 0.3, textAlign: "center", justifyContent: 'center', padding: '8px', margin: 'auto' }}>{formatNumber(((caseData.CaseCost - (caseData.KeyCost ?? 0)) ?? 0) / exchangeRate, true)}</h1>
              {caseData.KeyCost == 0 ? <span style={{ fontSize: '16px', lineHeight: 0.2, textAlign: 'center', display: 'none', visibility: 'hidden', marginTop: '8px' }}>+{formatNumber((caseData.KeyCost ?? 0) / exchangeRate, true)} Key </span> : <span style={{ fontSize: '16px', lineHeight: 0.2, textAlign: 'center', display: 'block', marginTop: '8px' }}>+{formatNumber((caseData.KeyCost ?? 0) / exchangeRate, true)} Key</span>}
            </div>}
            {/* <h2 style={{ margin: 'auto', textAlign: 'center' }}> */}
            {/* <Tooltip title="Return on Investment for opening this container today" sx={{ margin: 'auto' }}> */}
            <div style={{ display: "flex", fontSize: '1.3rem', alignItems: "center", justifyContent: 'center', flexDirection: 'column', textAlign: 'center', margin: 'auto' }}>
              Unboxing ROI
              {<img src={OpenBox} className="iconSVGMob" style={{ width: '28px', height: '28px', marginBottom: '4px' }} />}
              {caseData.CaseCost !== 0.01 ? <h3 style={{ margin: 0, lineHeight: 0.7 }}>{formatNumber(caseData.ROI * 100)}%</h3> : <h3 style={{ margin: 0, lineHeight: 0.7 }}>${formatNumber((caseData.ROI) / 100 / exchangeRate)}</h3>}

            </div>

            {/* </Tooltip> */}
            {/* <Tooltip title="Chance for each unbox to be worth more than the cost of opening" sx={{ margin: 'auto' }}> */}
            <div style={{ display: "flex", fontSize: '1.3rem', alignItems: "center", justifyContent: 'center', flexDirection: 'column', textAlign: 'center', margin: 'auto' }}>
              Profit
              <PriceCheckIcon className="iconSVG" sx={{ width: '28px', height: '28px', marginBottom: '4px' }} fontSize='large' />
              <h3 style={{ margin: 0, lineHeight: 0.7 }}>{formatNumber((caseData.Profit) * 100)}%</h3>
            </div>

            {/* </Tooltip> */}
            {/* </h2> */}
          </div>}
      </Container>
    )
  );
}

export default RarityValues;
