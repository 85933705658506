const dopplerTypes = [
    "Emerald",
    "Ruby",
    "Sapphire",
    "Black Pearl",
    "Phase 1",
    "Phase 2",
    "Phase 3",
    "Phase 4"
];

export function skinsearchLink(item, isContainer, collectionType) {
    var isCollection = collectionType == "Armory" || collectionType == "Past Operation" || collectionType == "Collection";
    var isStickerCollection = collectionType == "Sticker" || collectionType == "Autograph" || (collectionType == "Past Operation" && item.includes("Sticker"));
    const modifiedItem = item.replaceAll(" | ★ (Vanilla)", "").replaceAll(",","").replaceAll("-","").replaceAll(":","").replaceAll(" &", "").replaceAll("(", "").replaceAll(")","").replaceAll(" |", "").replaceAll("/", "").replaceAll(' ', "_").toLowerCase();
    var url = ""
    if (isContainer) {
        if (isStickerCollection) {
            url = "https://skinsearch.com/capsule/" + modifiedItem;
        }
        else if (item.includes("Charm")) {
            url = "https://skinsearch.com/category/other/keychains";
        }
        else if (item.includes("Patch")) {
            url = "https://skinsearch.com/category/other/patches";
        }
        else if (item.includes("Music Kit")) {
            url = "https://skinsearch.com/category/other/music_kits";
        }
        else if (item.includes("Sealed Graffiti")) {
            url = "https://skinsearch.com/category/other/graffiti";
        }
        else if (item.includes("Agent")) {
            url = "https://skinsearch.com/category/other/agents";
        }
        else if (item.includes("Pin")) {
            url = "https://skinsearch.com/category/other/pins";
        }
        else if (item.includes("Sticker") || item.includes("Autograph")) {
            url = "https://skinsearch.com/categories/stickers/" + modifiedItem;
        }
        else if (isCollection) { 
            url = "https://skinsearch.com/category/collections/" + modifiedItem;
        }
        else if (isContainer) {
            url = "https://skinsearch.com/crate/" + modifiedItem;
        }
    } else {
        const weaponType = item.split(" |")[0].toLowerCase();
        const allOther = weaponType + " | ";
        const skinType = item.toLowerCase().replaceAll(allOther, "").replaceAll(" ", "_").replaceAll("★_(", "").replaceAll(")", "");
        url = "https://skinsearch.com/" + weaponType.replaceAll(" ", "_") + "/" + skinType;
    }
    url += "?ref=csroi";
    return url;
}

export function skinportLink(item, caseData, isContainer, wear, stat, isRare) {
    for (var dopplerType of dopplerTypes) {
        if (item.includes(dopplerType)) {
            item = item.replace(" - " + dopplerType, "");
        }
    }
    var statName = 'StatTrak™ ';
    var knifeName = '★ ';
    var cat = item.includes("Sticker") ? "Sticker" : item.includes("Patch") ? "Patch" : item.includes("Music Kit") ? "Music Kit" : item.includes("Sealed Graffiti") ? "Graffiti" : (caseData?.Case?.includes("Agent") ?? item?.includes("Agent")) ? "Agent" : null;
    var statOnly = stat ? "&stattrak=1" : "";
    if (caseData == null && isContainer == null && wear == null && stat == null && isRare == null) {
        return 'https://skinport.com/market?search=' + item + '&sort=price&order=asc&r=csroi';
    }
    if (isContainer) {
        cat = null;
        stat = "";
        item = item.replaceAll('/', '-')
    } else {
        item = item.replaceAll('/', '-').replaceAll("Sticker | ", "").replaceAll("Patch | ", "").replaceAll("Music Kit | ", "").replaceAll('StatTrak™ ', "").replaceAll("Sealed Graffiti | ", "");
    }
    // item = item.replace(" | ★ (Vanilla)", "");


    if (item.includes(' | ') && cat != "Agent" && !item.includes("Vanilla")) {
        var weap = item.split(' | ')[0];
        var skin = item.split(' | ')[1];
        if (wear != null) {
            if (((stat ?? false) ? stat : null) != null) {
                return 'https://skinport.com/market?item=' + skin + '&search=' + statName + item.replaceAll(knifeName, '') + ' (' + wear + ') ' + (cat != null ? "&cat=" + cat : "") + statOnly + '&sort=price&order=asc&r=csroi';
            } else {
                return 'https://skinport.com/market?item=' + skin + '&search=' + item.replaceAll(knifeName, '') + ' (' + wear + ') ' + (cat != null ? "&cat=" + cat : "") + statOnly + '&sort=price&order=asc&r=csroi';
            }
        } else {
            return 'https://skinport.com/market?item=' + item.replaceAll('Autograph Capsule | ', '').replaceAll(knifeName, '') + (cat != null ? "&cat=" + cat : "") + statOnly + '&sort=price&order=asc&r=csroi';
        }
    } else if (item.includes("Vanilla")) {
        if (stat ?? false) {
            return 'https://skinport.com/market?item=Vanilla&type=' + item.replaceAll('Autograph Capsule | ', '').replaceAll(knifeName, '').replaceAll(" | (Vanilla)", '') + (cat != null ? "&cat=" + cat : "") + statOnly + '&search=' + statName + '&sort=price&order=asc&r=csroi';
        } else {
            return 'https://skinport.com/market?item=Vanilla&type=' + item.replaceAll('Autograph Capsule | ', '').replaceAll(knifeName, '').replaceAll(" | (Vanilla)", '') + (cat != null ? "&cat=" + cat : "") + statOnly + '&sort=price&order=asc&r=csroi';
        }
    } else if (item.includes(' | ') && cat == "Agent") {
        var skin = item.split(' | ')[0];
        var weap = item.split(' | ')[1];
        return 'https://skinport.com/market?item=' + skin + (cat != null ? "&cat=" + cat : "") + stat + '&sort=price&order=asc&r=csroi';
    }

    if (wear != null) {
        if (((stat ?? false) ? stat : null) != null) {
            return 'https://skinport.com/market?item=' + item + '&search=' + statName + item.replaceAll(knifeName, '') + ' (' + wear + ') ' + (cat != null ? "&cat=" + cat : "") + stat + '&sort=price&order=asc&r=csroi';
        } else {
            return 'https://skinport.com/market?item=' + item + '&search=' + item.replaceAll(knifeName, '') + ' (' + wear + ') ' + (cat != null ? "&cat=" + cat : "") + stat + '&sort=price&order=asc&r=csroi';
        }
    } else {
        return 'https://skinport.com/market?item=' + item.replaceAll('Autograph Capsule | ', '').replaceAll(knifeName, '') + (cat != null ? "&cat=" + cat : "") + stat + '&sort=price&order=asc&r=csroi';
    }
}

export function buffLink(item, wear, stat, isRare) {
    for (var dopplerType of dopplerTypes) {
        if (item.includes(dopplerType)) {
            item = item.replace(" - " + dopplerType, "");
        }
    }
    var statName = 'StatTrak™ ';
    var knifeName = '★ ';
    item = item.replace('/', '-').replace(" | ★ (Vanilla)", "");
    if (wear) {
        if (stat) {
            return 'https://buff.163.com/market/csgo#tab=selling&page_num=1&search=' + (isRare ? knifeName : '') + statName + item + ' (' + wear + ') ';
        } else {
            return 'https://buff.163.com/market/csgo#tab=selling&page_num=1&search=' + (isRare ? knifeName : '') + item + ' (' + wear + ') ';
        }
    } else {
        if (stat) {
            return 'https://buff.163.com/market/csgo#tab=selling&page_num=1&search=' + (isRare ? knifeName : '') + statName + item;
        } else {
            return 'https://buff.163.com/market/csgo#tab=selling&page_num=1&search=' + (isRare ? knifeName : '') + item;
        }
        // return 'https://steamcommunity.com/market/listings/730/' + (isRare ? knifeName : '') + item;
    }
}


export function steamLink(item, wear, stat, isRare) {
    for (var dopplerType of dopplerTypes) {
        if (item.includes(dopplerType)) {
            item = item.replace(" - " + dopplerType, "");
        }
    }
    var statName = 'StatTrak™ ';
    var knifeName = '★ ';
    item = item.replace('/', '-').replace(" | ★ (Vanilla)", "");
    if (wear) {
        if (stat) {
            return 'https://steamcommunity.com/market/listings/730/' + (isRare ? knifeName : '') + statName + item + ' (' + wear + ') ';
        } else {
            return 'https://steamcommunity.com/market/listings/730/' + (isRare ? knifeName : '') + item + ' (' + wear + ') ';
        }
    } else {
        if (stat) {
            return 'https://steamcommunity.com/market/listings/730/' + (isRare ? knifeName : '') + statName + item;
        } else {
            return 'https://steamcommunity.com/market/listings/730/' + (isRare ? knifeName : '') + item;
        }
        // return 'https://steamcommunity.com/market/listings/730/' + (isRare ? knifeName : '') + item;
    }
}

export function csfloatLink(item, wear, stat, isRare) {
    for (var dopplerType of dopplerTypes) {
        if (item.includes(dopplerType)) {
            item = item.replace(" - " + dopplerType, "");
        }
    }
    var statName = 'StatTrak™ ';
    var knifeName = '★ ';
    item = item.replace('/', '-').replace(" | ★ (Vanilla)", "");
    if (wear) {
        if (stat) {
            return 'https://csfloat.com/search?sort_by=lowest_price&market_hash_name=' + (isRare ? knifeName : '') + statName + item + ' (' + wear + ')&ref=csroi';
        } else {
            return 'https://csfloat.com/search?sort_by=lowest_price&market_hash_name=' + (isRare ? knifeName : '') + item + ' (' + wear + ')&ref=csroi';
        }
    } else {
        if (stat) {
            return 'https://csfloat.com/search?sort_by=lowest_price&market_hash_name=' + (isRare ? knifeName : '') + statName + item + '&ref=csroi';
        } else {
            return 'https://csfloat.com/search?sort_by=lowest_price&market_hash_name=' + (isRare ? knifeName : '') + item + '&ref=csroi';
        }
        // return 'https://steamcommunity.com/market/listings/730/' + (isRare ? knifeName : '') + item;
    }
}

export function tradeitLink(item, wear, stat, isRare) {
    for (var dopplerType of dopplerTypes) {
        if (item.includes(dopplerType)) {
            item = item.replace(" - " + dopplerType, "");
        }
    }
    var statName = 'StatTrak™ ';
    var knifeName = '★ ';
    item = item.replace('/', '-').replace(" | ★ (Vanilla)", "");
    if (wear) {
        if (stat) {
            return 'https://tradeit.gg/csgo/trade?search=' + (isRare ? knifeName : '') + statName + item + ' (' + wear + ')&aff=csroi';
        } else {
            return 'https://tradeit.gg/csgo/trade?search=' + (isRare ? knifeName : '') + item + ' (' + wear + ')&aff=csroi';
        }
    } else {
        if (stat) {
            return 'https://tradeit.gg/csgo/trade?search=' + (isRare ? knifeName : '') + statName + item + '&aff=csroi';
        } else {
            return 'https://tradeit.gg/csgo/trade?search=' + (isRare ? knifeName : '') + item + '&aff=csroi';
        }
        // return 'https://steamcommunity.com/market/listings/730/' + (isRare ? knifeName : '') + item;
    }
}