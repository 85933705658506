import { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
import { Backdrop, Card, CardMedia, Container, Grid, Paper, ThemeProvider, Typography, Table, TableRow, TableCell, TableContainer, LinearProgress, Button, Tabs, Tab, TextField, Checkbox } from "@mui/material";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell, Legend } from 'recharts';
import { TableHead, TableBody, } from '@mui/material';
import Scrollbars from "react-custom-scrollbars-2";
import './FloatRanges.css';
import { ToggleButtonGroup, ToggleButton, Slider } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const redRarityNums = { 'Contraband': 223, 'Covert': 187, 'Classified': 158, 'Restricted': 104, 'Mil_Spec': 2, 'Industrial': 94, 'Consumer': 176 };
const greenRarityNums = { 'Contraband': 199, 'Covert': 23, 'Classified': 0, 'Restricted': 50, 'Mil_Spec': 79, 'Industrial': 152, 'Consumer': 195 };
const blueRarityNums = { 'Contraband': 0, 'Covert': 26, 'Classified': 197, 'Restricted': 234, 'Mil_Spec': 228, 'Industrial': 217, 'Consumer': 217 };
const realWearNames = { 'FactoryNew': 'Factory New', 'MinimalWear': 'Minimal Wear', 'FieldTested': 'Field-Tested', 'WellWorn': 'Well-Worn', 'BattleScarred': 'Battle-Scarred' };



const wearNames = [
    'Factory New',
    'Minimal Wear',
    'Field-Tested',
    'Well-Worn',
    'Battle-Scarred',
];


const higherRarity = {
    'Consumer': 'Industrial',
    'Industrial': 'Mil_Spec',
    'Mil_Spec': 'Restricted',
    'Restricted': 'Classified',
    'Classified': 'Covert',
    // 'magic': 1, // not used, but needed for calculation
};


const wearRangeStarts = {
    'Factory New': 0,
    'Minimal Wear': 0.07,
    'Field-Tested': 0.15,
    'Well-Worn': 0.38,
    'Battle-Scarred': 0.45,
    'magic': 1, // not used, but needed for calculation
};

const wearRangeSizes = {
    'Factory New': 0.07,
    'Minimal Wear': 0.08,
    'Field-Tested': 0.23,
    'Well-Worn': 0.07,
    'Battle-Scarred': 0.55,
};

const wearDropRates = {
    'Factory New': 0.03,
    'Minimal Wear': 0.24,
    'Field-Tested': 0.33,
    'Well-Worn': 0.24,
    'Battle-Scarred': 0.16,
};

const wearRangeStartDrops = {
    'Factory New': 0,
    'Minimal Wear': 0.08,
    'Field-Tested': 0.16,
    'Well-Worn': 0.39,
    'Battle-Scarred': 0.46,
};

const otherRarityNames = { 'Extraordinary': 'Covert', 'Exotic': 'Classified', 'Remarkable': 'Restricted', 'High_Grade': 'Mil_Spec', 'Superior': 'Classified', 'Master': 'Covert', 'Exceptional': 'Restricted', 'Distinguished': 'Mil_Spec' }


function steamLink(item, wear, stat, isRare) {
    var statName = 'StatTrak™ ';
    var knifeName = '★ ';
    item = item.replace('/', '-');
    if (wear) {
        if (stat) {
            return 'https://steamcommunity.com/market/listings/730/' + (isRare ? knifeName : '') + statName + item + ' (' + wear + ') ';
        } else {
            return 'https://steamcommunity.com/market/listings/730/' + (isRare ? knifeName : '') + item + ' (' + wear + ') ';
        }
    } else {
        if (stat) {
            return 'https://steamcommunity.com/market/listings/730/' + (isRare ? knifeName : '') + statName + item;
        } else {
            return 'https://steamcommunity.com/market/listings/730/' + (isRare ? knifeName : '') + item;
        }
        // return 'https://steamcommunity.com/market/listings/730/' + (isRare ? knifeName : '') + item;
    }
}


function getWears(minFloat, maxFloat) {
    let fullFloatPercents = {};
    for (let wear of wearNames) {
        let floatRange = maxFloat - minFloat;
        let lowerRange = (wearRangeStarts[wear] ?? 0) - minFloat;
        let higherRange = (wearRanges[wear] ?? 0) - minFloat;
        let eFloatStart = lowerRange / floatRange;
        let eFloatEnd = higherRange / floatRange;
        let totalPercent = 0;
        let floatPercents = {};
        for (let wearName of wearNames) {
            let start = wearRangeStartDrops[wearName] ?? 0;
            let end = wearRanges[wearName] ?? 0;
            if (start < eFloatEnd && end > eFloatStart) {
                let realRange = end - start;
                let insideRange;
                let avgRangeFloat;
                if (eFloatStart < start && eFloatEnd > end) {
                    insideRange = end - start;
                    avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
                } else if (eFloatStart > start && eFloatEnd < end) {
                    insideRange = eFloatEnd - eFloatStart;
                    avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
                } else if (eFloatStart < start) {
                    insideRange = eFloatEnd - start;
                    avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
                } else {
                    insideRange = end - eFloatStart;
                    avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
                }
                let percentRange = insideRange / realRange;
                let percent = percentRange * wearDropRates[wearName];
                totalPercent += percent;
                floatPercents[avgRangeFloat.toFixed(4)] = percent;
            }
        }
        let floatPercentsKeys = Object.keys(floatPercents);
        let totalAvgFloat = 0;
        let totalPercents = 0;
        for (let stringPercent of floatPercentsKeys) {
            let percent = floatPercents[stringPercent];
            totalAvgFloat += (percent / totalPercent) * parseFloat(stringPercent);
            totalPercents += percent;
        }
        fullFloatPercents[wear] = (totalPercents);
    }
    return fullFloatPercents;
}

const wearRanges = {
    'Factory New': 0.07,
    'Minimal Wear': 0.15,
    'Field-Tested': 0.38,
    'Well-Worn': 0.45,
    'Battle-Scarred': 1,
};

const smallWearNames = { 'Factory New': 'FN', 'Minimal Wear': 'MW', 'Field-Tested': 'FT', 'Well-Worn': 'WW', 'Battle-Scarred': 'BS' }


function bruteForceAverageWear(minFloat, maxFloat, wearStart, wearEnd) {
    /* let rollTest = 0;
    let realRoll = 0;
    const range = maxFloat - minFloat;
    //const wearStart = wearRangeStarts[wear] || 0;
    //const wearEnd = wearRanges[wear] || 0;
    const realWearStart = (wearStart * range) + minFloat;
    const realWearEnd = (wearEnd * range) + minFloat;
    const realWearRange = realWearEnd - realWearStart;
    
    while (wearEnd > realRoll && rollTest < 1) {
      realRoll = returnRealFloat(minFloat, maxFloat, rollTest);
      rollTest += 0.01;
    }
    
    realRoll = 0;
    rollTest -= 0.02;
    while (wearEnd > realRoll && rollTest < 1) {
      realRoll = returnRealFloat(minFloat, maxFloat, rollTest);
      rollTest += 0.001;
    }
    
    realRoll = 0;
    rollTest -= 0.002;
    while (wearEnd > realRoll && rollTest < 1) {
      realRoll = returnRealFloat(minFloat, maxFloat, rollTest);
      rollTest += 0.0001;
    }
    
    realRoll = 0;
    rollTest -= 0.0002;
    while (wearEnd > realRoll && rollTest < 1) {
      realRoll = returnRealFloat(minFloat, maxFloat, rollTest);
      rollTest += 0.00001;
    }
    
    rollTest -= 0.00001;
    let rollMin = rollTest;
    while (wearStart < realRoll && rollMin > 0) {
      realRoll = returnRealFloat(minFloat, maxFloat, rollMin);
      rollMin -= 0.01;
    }
    
    rollMin += 0.02;
    realRoll = 1;
    while (wearStart < realRoll && rollMin > 0) {
      realRoll = returnRealFloat(minFloat, maxFloat, rollMin);
      rollMin -= 0.001;
    }
    
    rollMin += 0.002;
    realRoll = 1;
    while (wearStart < realRoll && rollMin > 0) {
      realRoll = returnRealFloat(minFloat, maxFloat, rollMin);
      rollMin -= 0.0001;
    }
    
    rollMin += 0.0002;
    realRoll = 1;
    while (wearStart < realRoll && rollMin > 0) {
      realRoll = returnRealFloat(minFloat, maxFloat, rollMin);
      rollMin -= 0.00001;
    }
    
    rollMin += 0.00001;
    
    const avgWear = parseFloat(returnRealFloat(minFloat, maxFloat, ((rollTest + rollMin) / 2)).toFixed(4)); */

    //Need to calc average wear based on my chatgpt instructions since it cant create that code properly
    const whereWears = whereWearsPlaced(minFloat, maxFloat);
    let totalWearChance = 0;
    for (const customWearRange of Object.entries(whereWears)) {

        if (customWearRange[1]['end'] <= wearEnd && customWearRange[1]['start'] >= wearStart) {
            totalWearChance += wearDropRates[customWearRange[0]];
            customWearRange[1]['chance'] = wearDropRates[customWearRange[0]];
        } else {
            const customStart = customWearRange[1]['start'] >= wearStart ? customWearRange[1]['start'] : wearStart;
            const customEnd = customWearRange[1]['end'] <= wearEnd ? customWearRange[1]['end'] : wearEnd;
            if (customEnd > customStart) {
                let dropRateForCustomWearRange = wearDropRates[customWearRange[0]];
                let range = customEnd - customStart;
                let rangeSize = (range / (customWearRange[1]['end'] - customWearRange[1]['start']));
                totalWearChance += rangeSize * dropRateForCustomWearRange;
                customWearRange[1]['chance'] = rangeSize * dropRateForCustomWearRange;
            }
        }
    }
    let averageFloat = 0;
    for (const customWearRange of Object.entries(whereWears)) {

        if (customWearRange[1]['end'] <= wearEnd && customWearRange[1]['start'] >= wearStart) {
            var avgFloatInRange = (customWearRange[1]['end'] + customWearRange[1]['start']) / 2;
            averageFloat += (customWearRange[1]['chance'] / totalWearChance) * avgFloatInRange;
        } else {
            const customStart = customWearRange[1]['start'] >= wearStart ? customWearRange[1]['start'] : wearStart;
            const customEnd = customWearRange[1]['end'] <= wearEnd ? customWearRange[1]['end'] : wearEnd;
            if (customEnd > customStart) {
                var avgFloatInRange = (customEnd + customStart) / 2;
                averageFloat += (customWearRange[1]['chance'] / totalWearChance) * avgFloatInRange;
            }
        }
    }

    return { avgWear: averageFloat, percentChance: totalWearChance };
}

function reverseAverageWear(minFloat, maxFloat, avgWear, startMin, startMax) {
    // Extract the entered value and convert it to a float
    var numRuns = 0;
    var prevWear = bruteForceAverageWear(minFloat, maxFloat, startMin, startMax);
    var currMin = startMin;
    var currMax = startMax;
    var avgWearTest = prevWear['avgWear'];


    while (numRuns < 1000) {
        if (avgWearTest < avgWear) {
            currMin = currMin + 0.01;
            prevWear = bruteForceAverageWear(minFloat, maxFloat, currMin, currMax);
            avgWearTest = prevWear['avgWear'];
            numRuns++;
            if (avgWearTest > avgWear) {
                break;
            }
        } else {
            currMax = currMax - 0.01;
            prevWear = bruteForceAverageWear(minFloat, maxFloat, currMin, currMax);
            avgWearTest = prevWear['avgWear'];
            numRuns++;
            if (avgWearTest < avgWear) {
                break;
            }
        }


    }
    if (avgWearTest < avgWear) {
        currMax = currMax + 0.01;

    } else {
        currMin = currMin - 0.01;
    }

    prevWear = bruteForceAverageWear(minFloat, maxFloat, currMin, currMax);
    avgWearTest = prevWear['avgWear'];
    while (numRuns < 2000) {
        if (avgWearTest < avgWear) {
            currMin = currMin + 0.001;
            prevWear = bruteForceAverageWear(minFloat, maxFloat, currMin, currMax);
            avgWearTest = prevWear['avgWear'];
            numRuns++;
            if (avgWearTest > avgWear) {
                break;
            }
        } else {
            currMax = currMax - 0.001;
            prevWear = bruteForceAverageWear(minFloat, maxFloat, currMin, currMax);
            avgWearTest = prevWear['avgWear'];
            numRuns++;
            if (avgWearTest < avgWear) {
                break;
            }
        }

    }
    if (avgWearTest < avgWear) {
        currMax = currMax + 0.001;
    } else {
        currMin = currMin - 0.001;
    }

    prevWear = bruteForceAverageWear(minFloat, maxFloat, currMin, currMax);
    avgWearTest = prevWear['avgWear'];
    while (numRuns < 3000) {
        if (avgWearTest < avgWear) {
            currMin = currMin + 0.0001;
            prevWear = bruteForceAverageWear(minFloat, maxFloat, currMin, currMax);
            avgWearTest = prevWear['avgWear'];
            numRuns++;
            if (avgWearTest > avgWear) {
                break;
            }
        } else {
            currMax = currMax - 0.0001;
            prevWear = bruteForceAverageWear(minFloat, maxFloat, currMin, currMax);
            avgWearTest = prevWear['avgWear'];
            numRuns++;
            if (avgWearTest < avgWear) {
                break;
            }
        }

    }
    if (avgWearTest < avgWear) {
        currMax = currMax + 0.0001;
    } else {
        currMin = currMin - 0.0001;
    }

    prevWear = bruteForceAverageWear(minFloat, maxFloat, currMin, currMax);
    avgWearTest = prevWear['avgWear'];
    while (numRuns < 4000) {
        if (avgWearTest < avgWear) {
            currMin = currMin + 0.00001;
            prevWear = bruteForceAverageWear(minFloat, maxFloat, currMin, currMax);
            avgWearTest = prevWear['avgWear'];
            numRuns++;
            if (avgWearTest > avgWear) {
                break;
            }
        } else {
            currMax = currMax - 0.00001;
            prevWear = bruteForceAverageWear(minFloat, maxFloat, currMin, currMax);
            avgWearTest = prevWear['avgWear'];
            numRuns++;
            if (avgWearTest < avgWear) {
                break;
            }
        }

    }
    if (avgWearTest < avgWear) {
        currMax = currMax + 0.00001;
    } else {
        currMin = currMin - 0.00001;
    }

    prevWear = bruteForceAverageWear(minFloat, maxFloat, currMin, currMax);
    avgWearTest = prevWear['avgWear'];
    while (numRuns < 5000) {
        if (avgWearTest < avgWear) {
            currMin = currMin + 0.000001;
            prevWear = bruteForceAverageWear(minFloat, maxFloat, currMin, currMax);
            avgWearTest = prevWear['avgWear'];
            numRuns++;
            if (avgWearTest > avgWear) {
                break;
            }
        } else {
            currMax = currMax - 0.000001;
            prevWear = bruteForceAverageWear(minFloat, maxFloat, currMin, currMax);
            avgWearTest = prevWear['avgWear'];
            numRuns++;
            if (avgWearTest < avgWear) {
                break;
            }
        }

    }

    //  console.log(avgFloat);

    prevWear['rangeMin'] = currMin;
    prevWear['rangeMax'] = currMax;

    return prevWear;
}



function whereWearsPlaced(minFloat, maxFloat) {
    const allWears = {};
    for (const wearName of wearNames) {
        const wearStart = wearRangeStartDrops[wearName];
        const wearEnd = wearRanges[wearName];
        const adjustedStart = wearStart * (maxFloat - minFloat) + minFloat;
        const adjustedEnd = wearEnd * (maxFloat - minFloat) + minFloat;
        allWears[wearName] = { 'start': adjustedStart, 'end': adjustedEnd, 'size': ((adjustedEnd - adjustedStart) * 100000000).toFixed(0), 'height': wearDropRates[wearName] / ((adjustedEnd - adjustedStart) * 100), 'rawRate': wearDropRates[wearName] };
    }
    // console.log(filterWearsInRange(allWears, 0, 0.2));
    return allWears;
}

function filterWearsInRange(wearData, rangeStart, rangeEnd) {
    // Validate input range
    if (typeof rangeStart !== 'number' || typeof rangeEnd !== 'number' || rangeStart > rangeEnd) {
        throw new Error('Invalid input range');
    }

    const filteredWears = {};

    for (const [wearType, data] of Object.entries(wearData)) {
        const { start, end, size, height } = data;

        // Calculate the intersection of ranges
        const newStart = Math.max(rangeStart, start);
        const newEnd = Math.min(rangeEnd, end);

        // Skip wears that don't intersect with the specified range
        if (newStart >= newEnd) {
            continue;
        }

        // Calculate adjusted properties based on the intersection
        const adjustedSize = ((newEnd - newStart) * 100000000).toFixed();
        const adjustedHeight = (height * (newEnd - newStart) / (end - start)).toFixed(2);

        // Add the filtered wear to the result
        filteredWears[wearType] = {
            start: newStart,
            end: newEnd,
            size: adjustedSize,
            height: adjustedHeight,
        };
    }

    return filteredWears;
}


function returnRealFloat(minFloat, maxFloat, roll) {
    let percentSoFar = 0;
    let percentIntoWear = 0;
    let wearDrop = ['Factory New', 0];

    for (const dropPercent of Object.entries(wearDropRates)) {
        if (roll < (percentSoFar + dropPercent[1])) {
            percentIntoWear = (roll - percentSoFar) / (dropPercent[1]);
            wearDrop = dropPercent;
            break;
        }
        percentSoFar += dropPercent[1];
    }

    const range = maxFloat - minFloat;
    const wearStart = wearRangeStartDrops[wearDrop[0]] || 0;
    const wearEnd = wearRanges[wearDrop[0]] || 0;
    const realWearStart = (wearStart * range) + minFloat;
    const realWearEnd = (wearEnd * range) + minFloat;
    const realWearRange = realWearEnd - realWearStart;
    const finalWear = (realWearRange * percentIntoWear) + realWearStart;

    return finalWear;
}

//   console.log(bruteForceAverageWear(0,1,0,0.07))


const ruler = [
    { key: '0.07', percent: 0.07, name: 'Factory New' },
    { key: '0.15', percent: 0.08, name: 'Minimal Wear' },
    { key: '0.38', percent: 0.23, name: 'Field-Tested' },
    { key: '0.45', percent: 0.07, name: 'Well-Worn' },
    { key: '1', percent: 0.55, name: 'Battle-Scarred' },
];

function findWearRanges(minFloat, maxFloat) {
    const wearSize = (maxFloat - minFloat) / 100;
    var allWears = {};
    for (const wear in wearRanges) {
        const floatRequired = ((wearRanges[wear] - minFloat / 100) / (wearSize))
        if (floatRequired < 1 && floatRequired > 0) {
            allWears[wear] = Math.floor(floatRequired * 1000000000) / 1000000000;
        } else if (floatRequired > 0) {
            allWears[wear] = 1;
            break;
        }

    }
    return allWears;
}

function combineWearRanges(floatRanges, caseData) {
    const allWearChanges = {};
    const allWearChangeItems = {};
    for (const rarity in floatRanges) {
        allWearChanges[rarity] = [];
        allWearChangeItems[rarity] = {};
        for (const item in floatRanges[rarity]) {
            for (const wearName in floatRanges[rarity][item]) {
                if (!allWearChanges[rarity].includes(floatRanges[rarity][item][wearName])) {
                    allWearChanges[rarity].push(floatRanges[rarity][item][wearName]);
                }
            }
        }
        allWearChanges[rarity] = allWearChanges[rarity].sort((a, b) => a - b);
        var lastWear = 0;
        for (const floatValueIndex in allWearChanges[rarity]) {
            const wearChange = allWearChanges[rarity][floatValueIndex]
            // console.log(floatValue);
            var totalWearChangeCost = 0;
            allWearChangeItems[rarity][wearChange] = {};
            for (const item in floatRanges[rarity]) {
                var itemWearAtFloat = null;
                // console.log(floatRanges[rarity][item]);
                const reversedWears = Object.fromEntries(Object.entries(floatRanges[rarity][item]).reverse());

                for (const wearName in reversedWears) {
                    // console.log(wearName);

                    if (wearChange <= floatRanges[rarity][item][wearName]) {

                        itemWearAtFloat = wearName;
                        // allWearChanges[rarity].push(floatRanges[rarity][item][wearName]);
                    }
                    // else {
                    //     break;
                    // }
                }
                // console.log(itemWearAtFloat);
                // console.log("Wear Change: " + wearChange.toFixed(4));
                // console.log("Items Wear Change: " + floatRanges[rarity][item][itemWearAtFloat]?.toFixed(4));
                allWearChangeItems[rarity][wearChange][item] = itemWearAtFloat;

                totalWearChangeCost += caseData['ItemCosts'][rarity][item][itemWearAtFloat.replace('-', '').replace(' ', '')];

            }
            // console.log(allWearChanges[rarity]);

            allWearChangeItems[rarity][wearChange]["size"] = wearChange - lastWear;
            allWearChangeItems[rarity][wearChange]["cost"] = totalWearChangeCost / Object.keys(caseData['ItemCosts'][rarity]).length;
            lastWear = wearChange;
        }
    }

    return allWearChangeItems;
}

function findWearAtFloat(givenFloat, minFloat, maxFloat) {
    const wearRange = (maxFloat - minFloat) / 100;
    return (givenFloat * wearRange) + minFloat / 100;
}

// function howManyItemsBefore(comboFloatRange, caseData) { 
//     listAllWears 

// }

// function getAllSelectedFloats(selectedTabs) {
//     for (rarity in Object.values(comboFloatRange)) {
//         for (item in Object.values(comboFloatRange)) {
//             const floatMin = caseData["Items"][item]["FloatMin"]
//             const floatMax = caseData["Items"][item]["FloatMax"]
//             const givenFloat = Object.keys(comboFloatRange)[0];
//             const wearAtFloat = findWearAtFloat(givenFloat, floatMin, floatMax);

//         }
//     }
// }


function FloatDropChances({ caseData, exchangeRate, currency, isMobile, isFullscreen }) {

    function formatNumber(number, isCurrency) {
        // Use the user's locale
        const userLocale = navigator.language || 'en-US';
      
        // Create Intl.NumberFormat with user's locale
        if (isCurrency) {
          const formatter = new Intl.NumberFormat(userLocale, {
            //   minimumFractionDigits: 1,
            style: "currency",
            currency: currency,
            maximumFractionDigits: number > 1000 ? 0 : number > 100 ? 1 : 2,
          });
          return formatter.format(number);
        } else {
          const formatter = new Intl.NumberFormat(userLocale, {
            //   minimumFractionDigits: 1,
            maximumFractionDigits: number > 1000 ? 0 : number > 100 ? 1 : 2,
          });
          return formatter.format(number);
        }
      
        // Format the number
        
      }
    const [allFloatRanges, setAllFloatRanges] = useState(null);
    const [combinedFloatRanges, setCombinedFloatRanges] = useState(null);
    const [activeRarity, setActiveRarity] = useState(null);
    const [displayAvgFloat, setDisplayAvgFloat] = useState(null);
    const [activeInputWear, setActiveInputWear] = useState(null);
    const [activeInputItem, setActiveInputItem] = useState(null);
    const [resalePercent, setResalePercent] = useState([0, 100000000]);
    const [hoveredWear, setHoveredWear] = useState(null);
    // const [rangeValue, setRangeValue] = useState([0, 5]);
    const [activeOutputItem, setActiveOutputItem] = useState(null);
    const [displayMinFloat, setDisplayMinFloat] = useState('0.00');
    const [displayMaxFloat, setDisplayMaxFloat] = useState('1.00');
    const [stattrak, setStattrak] = useState("Any");

    const handleDisplayMinFloat = (event) => {
        const newValue = event.target.value;

        // Update the state with the string value
        setDisplayMinFloat(newValue);
    };

    const handleDisplayMaxFloat = (event) => {
        const newValue = event.target.value;

        // Update the state with the string value
        setDisplayMaxFloat(newValue);
    };


    const handleDisplayAvgFloat = (event) => {
        setDisplayAvgFloat(parseFloat(event.target.value));
    };

    const handleWearClick = (wearLevel) => {
        setActiveInputWear(activeInputWear == wearLevel ? null : wearLevel);
        setResalePercent([(activeInputWear == wearLevel ? null : wearLevel) !== null ? 0 : caseData["Items"][activeInputItem]["FloatMin"] / 100 * 100000000, (activeInputWear == wearLevel ? null : wearLevel) !== null ? 100000000 : caseData["Items"][activeInputItem]["FloatMax"] / 100 * 100000000])

        const bruteTest = activeInputItem !== null ? bruteForceAverageWear(caseData["Items"][activeInputItem]["FloatMin"] / 100, caseData["Items"][activeInputItem]["FloatMax"] / 100, (activeInputWear == wearLevel ? null : wearLevel) == null ? ([0, 100000000][0] / 100000000) : (wearRangeStarts[(activeInputWear == wearLevel ? null : wearLevel)] + (([0, 100000000][0] / 100000000) * wearRangeSizes[(activeInputWear == wearLevel ? null : wearLevel)])), (activeInputWear == wearLevel ? null : wearLevel) == null ? ([0, 100000000][1] / 100000000) : (wearRangeStarts[(activeInputWear == wearLevel ? null : wearLevel)] + (([0, 100000000][1] / 100000000) * wearRangeSizes[(activeInputWear == wearLevel ? null : wearLevel)]))) : null;
        setDisplayAvgFloat(bruteTest['avgWear'].toFixed(5));
        setDisplayMinFloat((activeInputWear == wearLevel ? null : wearLevel) == null ? (caseData["Items"][activeInputItem]["FloatMin"] / 100).toFixed(5) : (wearRangeStarts[activeInputWear == wearLevel ? null : wearLevel] + ((0 / 100000000) * wearRangeSizes[activeInputWear == wearLevel ? null : wearLevel])).toFixed(5));
        setDisplayMaxFloat((activeInputWear == wearLevel ? null : wearLevel) == null ? (caseData["Items"][activeInputItem]["FloatMax"] / 100).toFixed(5) : (wearRangeStarts[activeInputWear == wearLevel ? null : wearLevel] + ((100000000 / 100000000) * wearRangeSizes[activeInputWear == wearLevel ? null : wearLevel])).toFixed(5));
        // const bruteTest = InputItem !== null ? bruteForceAverageWear(caseData["Items"][InputItem]["FloatMin"] / 100, caseData["Items"][InputItem]["FloatMax"] / 100, activeInputWear == null ? (resalePercent[0] / 100000000) : (wearRangeStarts[activeInputWear] + ((resalePercent[0] / 100000000) * wearRangeSizes[activeInputWear])), activeInputWear == null ? (resalePercent[1] / 100000000) : (wearRangeStarts[activeInputWear] + ((resalePercent[1] / 100000000) * wearRangeSizes[activeInputWear]))) : null;
        // setDisplayAvgFloat(bruteTest['avgWear'].toFixed(5));
        // setDisplayMinFloat(valueLabelFormat(activeInputWear !== null ? 0 : caseData["Items"][InputItem]["FloatMin"] / 100 * 100000000));
        // setDisplayMaxFloat(valueLabelFormat(activeInputWear !== null ? 100000000 : caseData["Items"][InputItem]["FloatMax"] / 100 * 100000000));
    };

    const handleResaleSlider = (event, outputFloat) => {
        setResalePercent(outputFloat);
        setDisplayMinFloat(activeInputWear == null ? (outputFloat[0] / 100000000) : (wearRangeStarts[activeInputWear] + ((outputFloat[0] / 100000000) * wearRangeSizes[activeInputWear])).toFixed(5));
        setDisplayMaxFloat(activeInputWear == null ? (outputFloat[1] / 100000000) : (wearRangeStarts[activeInputWear] + ((outputFloat[1] / 100000000) * wearRangeSizes[activeInputWear])).toFixed(5));

        const bruteTest = activeInputItem !== null ? bruteForceAverageWear(caseData["Items"][activeInputItem]["FloatMin"] / 100, caseData["Items"][activeInputItem]["FloatMax"] / 100, activeInputWear == null ? (outputFloat[0] / 100000000) : (wearRangeStarts[activeInputWear] + ((outputFloat[0] / 100000000) * wearRangeSizes[activeInputWear])), activeInputWear == null ? (outputFloat[1] / 100000000) : (wearRangeStarts[activeInputWear] + ((outputFloat[1] / 100000000) * wearRangeSizes[activeInputWear]))) : null;
        setDisplayAvgFloat(bruteTest['avgWear'].toFixed(5));
    };

    const handleAvgFloat = (avgFloat, startMin, startMax) => {
        // const avgFloat = parseFloat(event.target.value);
        var avgWearDesiredData = null;
        // Check if the value is a number and within the range [0, 1]
        if (!isNaN(avgFloat) && avgFloat >= 0 && avgFloat <= 1) {
            // Update the state with the valid value
            avgWearDesiredData = reverseAverageWear(caseData["Items"][activeInputItem]["FloatMin"] / 100, caseData["Items"][activeInputItem]["FloatMax"] / 100, avgFloat, startMin, startMax)
        } else {
            // If the entered value is not valid, you can handle it accordingly
            // For example, you can show an error message or reset the input
            avgWearDesiredData = reverseAverageWear(caseData["Items"][activeInputItem]["FloatMin"] / 100, caseData["Items"][activeInputItem]["FloatMax"] / 100, brute['avgFloat'], startMin, startMax)
        }
        setResalePercent([calculateRawWearNumber(avgWearDesiredData['rangeMin']), calculateRawWearNumber(avgWearDesiredData['rangeMax'])]);
        setDisplayMinFloat(avgWearDesiredData['rangeMin'].toFixed(5));
        setDisplayMaxFloat(avgWearDesiredData['rangeMax'].toFixed(5));
    };



    const handleMinFloat = (event) => {
        // Extract the entered value as a string
        let stringValue = event.target.value.toString();

        // Remove leading zeros
        stringValue = stringValue.replace(/^0+/, '');

        // Parse the string as a float
        const minFloat = parseFloat(stringValue);

        // Check if the value is a number and within the range [0, 1]
        if (!isNaN(minFloat) && minFloat >= 0 && minFloat <= 1) {
            // Update the state with the valid value
            setResalePercent([calculateRawWearNumber(minFloat), resalePercent[1]]);
            const bruteTest = activeInputItem !== null ? bruteForceAverageWear(caseData["Items"][activeInputItem]["FloatMin"] / 100, caseData["Items"][activeInputItem]["FloatMax"] / 100, activeInputWear == null ? (calculateRawWearNumber(minFloat) / 100000000) : (wearRangeStarts[activeInputWear] + ((calculateRawWearNumber(minFloat) / 100000000) * wearRangeSizes[activeInputWear])), activeInputWear == null ? (resalePercent[1] / 100000000) : (wearRangeStarts[activeInputWear] + ((resalePercent[1] / 100000000) * wearRangeSizes[activeInputWear]))) : null;
            setDisplayAvgFloat(bruteTest['avgWear'].toFixed(5));

        } else {
            // If the entered value is not valid, you can handle it accordingly
            // For example, you can show an error message or reset the input
            setResalePercent([0, resalePercent[1]]);
            const bruteTest = activeInputItem !== null ? bruteForceAverageWear(caseData["Items"][activeInputItem]["FloatMin"] / 100, caseData["Items"][activeInputItem]["FloatMax"] / 100, activeInputWear == null ? (0 / 100000000) : (wearRangeStarts[activeInputWear] + ((0 / 100000000) * wearRangeSizes[activeInputWear])), activeInputWear == null ? (resalePercent[1] / 100000000) : (wearRangeStarts[activeInputWear] + ((resalePercent[1] / 100000000) * wearRangeSizes[activeInputWear]))) : null;
            setDisplayAvgFloat(bruteTest['avgWear'].toFixed(5));
        }
    };

    const handleMaxFloat = (event) => {
        // Extract the entered value as a string
        let stringValue = event.target.value.toString();

        // Remove leading zeros
        stringValue = stringValue.replace(/^0+/, '');

        // Parse the string as a float
        const maxFloat = parseFloat(stringValue)

        // Check if the value is a number and within the range [0, 1]
        if (!isNaN(maxFloat) && maxFloat >= 0 && maxFloat <= 1) {
            // Update the state with the valid value
            setResalePercent([resalePercent[0], calculateRawWearNumber(maxFloat)]);
            const bruteTest = activeInputItem !== null ? bruteForceAverageWear(caseData["Items"][activeInputItem]["FloatMin"] / 100, caseData["Items"][activeInputItem]["FloatMax"] / 100, activeInputWear == null ? (resalePercent[0] / 100000000) : (wearRangeStarts[activeInputWear] + ((resalePercent[0] / 100000000) * wearRangeSizes[activeInputWear])), activeInputWear == null ? (calculateRawWearNumber(maxFloat) / 100000000) : (wearRangeStarts[activeInputWear] + ((calculateRawWearNumber(maxFloat) / 100000000) * wearRangeSizes[activeInputWear]))) : null;
            setDisplayAvgFloat(bruteTest['avgWear'].toFixed(5));
        } else {
            // If the entered value is not valid, you can handle it accordingly
            // For example, you can show an error message or reset the input
            setResalePercent([resalePercent[0], 1]);
            const bruteTest = activeInputItem !== null ? bruteForceAverageWear(caseData["Items"][activeInputItem]["FloatMin"] / 100, caseData["Items"][activeInputItem]["FloatMax"] / 100, activeInputWear == null ? (resalePercent[0] / 100000000) : (wearRangeStarts[activeInputWear] + ((resalePercent[0] / 100000000) * wearRangeSizes[activeInputWear])), activeInputWear == null ? (1 / 100000000) : (wearRangeStarts[activeInputWear] + ((1 / 100000000) * wearRangeSizes[activeInputWear]))) : null;
            setDisplayAvgFloat(bruteTest['avgWear'].toFixed(5));
        }
    };

    // const handleMaxFloat = (maxFloat) => {
    //     setResalePercent([resalePercent[0], parseFloat(maxFloat)]);
    // };

    const handleStatTrak = (event, newPricingSource) => {
        setStattrak(newPricingSource.props.value);
    };

    const handleRarityClick = (event, newRarity) => {
        // console.log("what new");
        // console.log(newRarity);
        setActiveRarity(newRarity);
        // setActiveInputItem(null);
        if (activeInputItem != null) {
            handleInputItemClick(newRarity == "Contraband" ? Object.keys(caseData["KnifeCosts"])[0] : Object.keys(caseData["ItemCosts"][newRarity])[0]);
        }

        // setResalePercent([caseData["Items"][newRarity == "Contraband" ? Object.keys(caseData["KnifeCosts"])[0] : Object.keys(caseData["ItemCosts"][newRarity])[0]]["FloatMin"] / 100 * 100000000, caseData["Items"][activeInputItem]["FloatMax"] / 100 * 100000000])
        // setActiveOutputFloat(null)
    };

    const handleInputItemClick = (InputItem) => {
        setActiveInputItem(InputItem);
        const tempResalePercent = [activeInputWear !== null ? 0 : caseData["Items"][InputItem]["FloatMin"] / 100 * 100000000, activeInputWear !== null ? 100000000 : caseData["Items"][InputItem]["FloatMax"] / 100 * 100000000];
        setResalePercent(tempResalePercent)
        const bruteTest = InputItem !== null ? bruteForceAverageWear(caseData["Items"][InputItem]["FloatMin"] / 100, caseData["Items"][InputItem]["FloatMax"] / 100, activeInputWear == null ? (tempResalePercent[0] / 100000000) : (wearRangeStarts[activeInputWear] + ((tempResalePercent[0] / 100000000) * wearRangeSizes[activeInputWear])), activeInputWear == null ? (tempResalePercent[1] / 100000000) : (wearRangeStarts[activeInputWear] + ((tempResalePercent[1] / 100000000) * wearRangeSizes[activeInputWear]))) : null;
        setDisplayAvgFloat(bruteTest['avgWear'].toFixed(5));
        setDisplayMinFloat(valueLabelFormat(activeInputWear !== null ? 0 : caseData["Items"][InputItem]["FloatMin"] / 100 * 100000000));
        setDisplayMaxFloat(valueLabelFormat(activeInputWear !== null ? 100000000 : caseData["Items"][InputItem]["FloatMax"] / 100 * 100000000));

    };

    const handleOutputItemClick = (OutputItem) => {
        setActiveOutputItem(OutputItem);
    };
    




    useEffect(() => {

        const tempAllFloatRanges = {};
        const tempActiveTabs = {};
        for (const rarity in caseData["ItemCosts"]) {
            tempActiveTabs[rarity] = null;


            const lastRarity = Object.keys(caseData["ItemCosts"]).pop();
            // console.log(lastKey);
            if (rarity != lastRarity) {
                tempAllFloatRanges[rarity] = {};
                for (const item in caseData["ItemCosts"][rarity]) {
                    // console.log(item);
                    // console.log(caseData["Items"][item]["FloatMin"]);
                    // console.log(caseData["Items"][item]["FloatMax"]);
                    // console.log(findWearRanges(caseData["Items"][item]["FloatMin"], caseData["Items"][item]["FloatMax"]));
                    tempAllFloatRanges[rarity][item] = (findWearRanges(caseData["Items"][item]["FloatMin"], caseData["Items"][item]["FloatMax"]));
                }
            }
        }
        const lastRarity = Object.keys(caseData["ItemCosts"]).pop();
        // console.log("ahh");
        // console.log(lastRarity);
        setActiveRarity(lastRarity);
        // console.log(tempAllFloatRanges);
        // console.log(combineWearRanges(tempAllFloatRanges));
        setActiveTabs(tempActiveTabs);
        setAllFloatRanges(tempAllFloatRanges);
        // console.log("kkmm");
        // console.log(lastRarity);
        // console.log(higherRarity[lastRarity]);
        // console.log(tempAllFloatRanges[higherRarity[lastRarity]]);

        setCombinedFloatRanges(combineWearRanges(tempAllFloatRanges, caseData));

        // console.log("Where");
        // console.log(whereWearsPlaced(0,1));

        // console.log(findWearRanges(0.06,0.8))
    }, [])

    const [activeTabs, setActiveTabs] = useState({});

    const handleChange = (rarity, newValue) => {
        // Update the active tab for the specific rarity
        // console.log(activeTabs[rarity]);
        setActiveTabs({ ...activeTabs, [rarity]: (activeTabs[rarity] == newValue ? null : newValue) });
    };

    var previousPreviousFloat = 0;
    var previousFloat = 0;

    const CustomizedLabel = (props) => {
        const { x, y, fill, value } = props;
        return (
            <text x={x + 66} y={y - 10} textAnchor="middle" fontSize={16} dominantBaseline="middle" fill={"white"}>{(value * 100).toFixed(2)}%</text>
        );
    };

    const itemWears = activeInputItem !== null ? getWears(caseData['Items'][activeInputItem]['FloatMin'] / 100, caseData['Items'][activeInputItem]['FloatMax'] / 100) : null;
    // const data = Object.keys(Object.entries(itemWears).filter(([key, value]) => value !== 0)).map((key) => ({
    //   name: key,
    //   value: itemWears[key]
    // }));
    const data = activeInputItem !== null ? Object.entries(itemWears)
        .filter(([key, value]) => value !== 0)
        .map(([key, value], index) => ({
            name: key,
            value: itemWears[key]
        })) : [];

    const maxValue = Math.max(...data.map(point => point.value));

    function valueLabelFormat(value) {
        return activeInputWear == null ? (value / 100000000).toFixed(5) : (wearRangeStarts[activeInputWear] + ((value / 100000000) * wearRangeSizes[activeInputWear])).toFixed(5)
    }

    function calculateRawWearNumber(result) {
        if (activeInputWear === null) {
            // Inverse of (value / 100000000)
            return result * 100000000;
        } else {
            // Inverse of (wearRangeStarts[activeInputWear] + (value / 100000000) * wearRangeSizes[activeInputWear])
            return ((result - wearRangeStarts[activeInputWear]) / wearRangeSizes[activeInputWear]) * 100000000;
        }
    }



    const whereWears = activeInputItem !== null ? whereWearsPlaced(caseData["Items"][activeInputItem]["FloatMin"] / 100, caseData["Items"][activeInputItem]["FloatMax"] / 100) : null;

    const maxWear = activeInputItem !== null ? Math.max(...Object.values(whereWears).map(wear => wear.height)) : null;

    const brute = activeInputItem !== null ? bruteForceAverageWear(caseData["Items"][activeInputItem]["FloatMin"] / 100, caseData["Items"][activeInputItem]["FloatMax"] / 100, activeInputWear == null ? (resalePercent[0] / 100000000) : (wearRangeStarts[activeInputWear] + ((resalePercent[0] / 100000000) * wearRangeSizes[activeInputWear])), activeInputWear == null ? (resalePercent[1] / 100000000) : (wearRangeStarts[activeInputWear] + ((resalePercent[1] / 100000000) * wearRangeSizes[activeInputWear]))) : null;

    // console.log(maxWear)

    return (

        <Container width="100%" style={{ padding: '0', minHeight: isFullscreen ? '100vh': 'auto' }} maxWidth="none">
            {/* <Grid container spacing={2}> */}

            <div style={{ overflow: 'hidden', height: '100%' }}>
                {/* <BarChart style={{ marginLeft: 'auto' }} width={128 * data.length} height={250} data={data} layout="horizontal" barCategoryGap={1} margin={{ top: 50, right: 0, left: 0, bottom: 0 }}>

                            <YAxis type="number" hide />
                            <XAxis dataKey="name" type="category" width={150} hide />

                            <Bar dataKey="value" fill="#8884d8" label={CustomizedLabel}>

                            </Bar>
                        </BarChart> */}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '12px' }}>
                    {activeInputItem != null && <img src={caseData["Items"][activeInputItem]["ImageUrl"]} style={{ width: '128px', paddingRight: '4px' }} />}
                    {activeInputItem != null ? <h2 style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center', height: '100%', fontSize: isMobile ? '1.2rem' : '1.5em' }}>{stattrak == "Only StatTrak" ? "StatTrak™ ": ""}{activeInputItem}{isMobile && activeInputWear && <br />}{activeInputWear != null ? " (" + activeInputWear + ")" : ''}:<br />{activeInputWear != null && (brute['percentChance'] / (activeInputWear == null ? 1 : itemWears[activeInputWear])).toFixed(4) != "1.0000" ? ("(" + parseFloat(displayMinFloat).toString() + " - " + parseFloat(displayMaxFloat).toString()) + "): " : ""}1 in {activeRarity !== null ? formatNumber((1 / (caseData["Items"][activeInputItem]["ModifiedDropChance"] ?? 1)) / ((caseData["PercentReceive"][activeRarity] / (activeRarity === "Contraband" ? caseData["TotalKnifeChance"] : Object.keys(caseData["ItemCosts"][activeRarity]).length)) * (itemWears !== null && activeInputWear !== null ? (itemWears[activeInputWear]) : 1) * (stattrak == "Only StatTrak" ? 0.1 : (stattrak == "Only Normal" ? 0.9 : 1)) * (brute['percentChance'] / (activeInputWear == null ? 1 : itemWears[activeInputWear])))) : null}</h2> : <div />}
                </div>
                {/* {activeInputItem != null ? <h3 style={{marginTop: '0', marginBottom: '12px'}}>Item Chance: {activeRarity !== null ? ((caseData["PercentReceive"][activeRarity] / Object.keys(activeRarity === "Contraband" ? caseData["KnifeCosts"] : caseData["ItemCosts"][activeRarity]).length) * 100).toFixed(3): null}%</h3> : <div />}
                        {activeInputWear != null ? <h3 style={{marginTop: '0', marginBottom: '12px'}}>Wear Chance: {itemWears !== null ? (itemWears[activeInputWear] * 100).toFixed(3) + "%": ''}</h3> : <div style={{display: 'none'}}/>}
                        {activeInputItem != null ? <h3 style={{marginTop: '0', marginBottom: '12px'}}>Items Kept{activeInputWear == null ? "" : " In Wear"}: {(brute['percentChance'] / (activeInputWear == null ? 1 : itemWears[activeInputWear]) * 100).toFixed(2)}%</h3> : <div />} */}

                {isMobile ? <div style={{ height: activeInputItem == null ? "0" : '60vh', overflow: 'hidden' }}> <div style={{
                    display: activeInputItem != null ? 'flex' : 'none', flexDirection: 'column', position: 'relative',
                    paddingLeft: 0, marginLeft: '2%', marginRight: '2%',
                    height: activeInputWear != null ? ((1 / wearRangeSizes[activeInputWear]) * 100) + "%" : "100%",
                    overflow: 'hidden',
                    bottom: activeInputWear != null ? ((wearRangeStarts[activeInputWear]) * ((1 / wearRangeSizes[activeInputWear]) * 100)) + "%" : "0%",
                }}>
                    <div style={{
                        position: 'absolute', zIndex: 4, pointerEvents: 'none', width: '100%', height: '100%', background: `linear-gradient(0deg, rgba(128, 128, 128, 0.5) ${(100 - (activeInputWear == null ? resalePercent[1] : 100000000) * 0.000001)}%, transparent ${(100 - (activeInputWear == null ? resalePercent[1] : 100000000)) * 0.000001}%), linear-gradient(180deg,  rgba(128, 128, 128, 0.5) ${(activeInputWear == null ? resalePercent[0] : 0) * 0.000001}%, transparent ${(activeInputWear == null ? resalePercent[0] : 0) * 0.000001}%)`, // Add the linear gradient
                    }}>
                        {/* <div style={{position: 'absolute', zIndex: 4, pointerEvents: 'none', width: '100%', height: '100%', background: ``, // Add the linear gradient
                    }}></div> */}

                    </div>
                    {/* Other components */}
                    {activeInputItem !== null ? Object.entries(wearRangeSizes).map(([wearType, percentage], index) => (
                        <div
                            key={wearType + "graph"}
                            style={{
                                height: `${percentage * 100}%`,
                                borderTop: index === 0 ? '1px solid RGBA(255,255,255,0.3)' : 'none',
                                borderBottom: '1px solid RGBA(255,255,255,0.3)',
                                // height: '100%',
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: 'white',
                                cursor: 'pointer',

                                zIndex: 3, // Increase zIndex for the active wear range
                                // opacity: activeInputWear && activeInputWear !== wearType ? 0.3 : 1, // Adjust opacity for non-active wear ranges
                                cursor: 'pointer',
                                background: `linear-gradient(0deg, rgba(128, 128, 128, 0.5) ${(100 - (wearType != activeInputWear ? 100000000 : resalePercent[1]) * 0.000001)}%, transparent ${(100 - (wearType != activeInputWear ? 100000000 : resalePercent[1])) * 0.000001}%), linear-gradient(180deg,  rgba(128, 128, 128, 0.5) ${(wearType == activeInputWear ? resalePercent[0] : 0) * 0.000001}%, transparent ${(wearType == activeInputWear ? resalePercent[0] : 0) * 0.000001}%)`, // Add the linear gradient

                                // border: hoveredWear == wearType ? '2px solid yellow': 'none', // Add your hover styl  here
                                backgroundColor: hoveredWear == wearType ? 'rgba(128, 128, 128, 0.2)' : 'transparent', // Add your hover background color
                                cursor: 'pointer'
                                // },
                            }}
                            onMouseEnter={() => setHoveredWear(wearType)}
                            onMouseLeave={() => setHoveredWear(null)}
                            onClick={() => handleWearClick(wearType)}
                        >
                            {activeInputItem != null &&
                                activeInputWear !== wearType ?
                                <h4 style={{ textAlign: 'center' }}>{wearType} - {itemWears !== null ? formatNumber(itemWears[wearType] * 100) : ''}% - {activeRarity !== null ? activeInputItem !== null ? formatNumber((activeRarity == "Contraband" ? caseData["KnifeCosts"][activeInputItem][wearType.replace('-', '').replace(' ', '')] : caseData["ItemCosts"][activeRarity][activeInputItem][wearType.replace('-', '').replace(' ', '')]) / exchangeRate, true) : '' : ''}</h4>
                                : <h4 style={{ textAlign: 'center' }}>{wearType}<br />{itemWears !== null ? formatNumber(itemWears[wearType] * 100) : ''}%<br />{
                                    activeRarity !== null ? activeInputItem !== null ? 
                                (<span>
                                    {formatNumber((activeRarity == "Contraband" ? caseData["KnifeCosts"][activeInputItem][wearType.replace('-', '').replace(' ', '')] : caseData["ItemCosts"][activeRarity][activeInputItem][wearType.replace('-', '').replace(' ', '')]) / exchangeRate, true)}
                                </span>) : '' : ''
                                }
                                </h4>}

                        </div>
                    )) : <div />}
                    {activeInputItem !== null ? Object.entries(whereWears).map(([wearType, data]) => (
                        <div
                            key={wearType}
                            style={{
                                position: 'absolute',
                                top: `${data.start * 100}%`,
                                height: `${(data.end - data.start) * 100}%`,
                                background: `linear-gradient(145deg, RGB(${redRarityNums[Object.keys(otherRarityNames).includes(activeRarity) ? otherRarityNames[activeRarity] : activeRarity]}, ${greenRarityNums[Object.keys(otherRarityNames).includes(activeRarity) ? otherRarityNames[activeRarity] : activeRarity]}, ${blueRarityNums[Object.keys(otherRarityNames).includes(activeRarity) ? otherRarityNames[activeRarity] : activeRarity]}) 0%, black 140%)`,
                                width: `${(data.height / maxWear) * 100}%`,
                                bottom: '0',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: 'white',
                                // zIndex: activeInputWear === wearType ? 3 : 2, // Increase zIndex for the active wear range
                                // opacity: activeInputWear && activeInputWear !== wearType ? 0.3 : 1, // Adjust opacity for non-active wear ranges
                            }}
                        >
                            {/* {wearType} */}
                        </div>
                    )) : <div />}
                </div> </div>
                    : <div style={{
                        display: activeInputItem != null ? 'flex' : 'none', flexDirection: 'row', height: '30vh', position: 'relative',
                        paddingLeft: 0, marginLeft: '2%', marginRight: '2%', width: activeInputWear != null ? ((1 / wearRangeSizes[activeInputWear]) * 96) + "%" : "auto", overflow: 'hidden', right: activeInputWear != null ? ((wearRangeStarts[activeInputWear]) * ((1 / wearRangeSizes[activeInputWear]) * 96)) + "%" : "0%",
                    }}>
                        <div style={{
                            position: 'absolute', zIndex: 4, pointerEvents: 'none', width: '100%', height: '100%', background: `linear-gradient(to left, rgba(128, 128, 128, 0.5) ${(100 - (activeInputWear == null ? resalePercent[1] : 100000000) * 0.000001)}%, transparent ${(100 - (activeInputWear == null ? resalePercent[1] : 100000000)) * 0.000001}%), linear-gradient(to right,  rgba(128, 128, 128, 0.5) ${(activeInputWear == null ? resalePercent[0] : 0) * 0.000001}%, transparent ${(activeInputWear == null ? resalePercent[0] : 0) * 0.000001}%)`, // Add the linear gradient
                        }}>
                            {/* <div style={{position: 'absolute', zIndex: 4, pointerEvents: 'none', width: '100%', height: '100%', background: ``, // Add the linear gradient
                    }}></div> */}

                        </div>
                        {/* Other components */}
                        {activeInputItem !== null ? Object.entries(wearRangeSizes).map(([wearType, percentage], index) => (
                            <div
                                key={wearType + "graph"}
                                style={{
                                    width: `${percentage * 100}%`,
                                    borderLeft: index === 0 ? '1px solid white' : 'none',
                                    borderRight: '1px solid white',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: 'white',
                                    cursor: 'pointer',

                                    zIndex: 3, // Increase zIndex for the active wear range
                                    // opacity: activeInputWear && activeInputWear !== wearType ? 0.3 : 1, // Adjust opacity for non-active wear ranges
                                    cursor: 'pointer',
                                    background: `linear-gradient(to left, rgba(128, 128, 128, 0.5) ${(100 - (wearType != activeInputWear ? 100000000 : resalePercent[1]) * 0.000001)}%, transparent ${(100 - (wearType != activeInputWear ? 100000000 : resalePercent[1])) * 0.000001}%), linear-gradient(to right,  rgba(128, 128, 128, 0.5) ${(wearType == activeInputWear ? resalePercent[0] : 0) * 0.000001}%, transparent ${(wearType == activeInputWear ? resalePercent[0] : 0) * 0.000001}%)`, // Add the linear gradient

                                    // border: hoveredWear == wearType ? '2px solid yellow': 'none', // Add your hover styles here
                                    backgroundColor: hoveredWear == wearType ? 'rgba(128, 128, 128, 0.2)' : 'transparent', // Add your hover background color
                                    cursor: 'pointer'
                                    // },
                                }}
                                onMouseEnter={() => setHoveredWear(wearType)}
                                onMouseLeave={() => setHoveredWear(null)}
                                onClick={() => handleWearClick(wearType)}
                            >
                                {activeInputItem != null && <h4 style={{ textAlign: 'center' }}>{wearType}<br />{itemWears !== null ? formatNumber(itemWears[wearType] * 100) : ''}%<br />{activeRarity !== null ? activeInputItem !== null ? formatNumber((activeRarity == "Contraband" ? caseData["KnifeCosts"][activeInputItem][wearType.replace('-', '').replace(' ', '')] : caseData["ItemCosts"][activeRarity][activeInputItem][wearType.replace('-', '').replace(' ', '')]) / exchangeRate, true) : '' : ''}</h4>}
                            </div>
                        )) : <div />}
                        {activeInputItem !== null ? Object.entries(whereWears).map(([wearType, data]) => (
                            <div
                                key={wearType}
                                style={{
                                    position: 'absolute',
                                    left: `${data.start * 100}%`,
                                    width: `${(data.end - data.start) * 100}%`,
                                    background: `linear-gradient(145deg, RGB(${redRarityNums[Object.keys(otherRarityNames).includes(activeRarity) ? otherRarityNames[activeRarity] : activeRarity]}, ${greenRarityNums[Object.keys(otherRarityNames).includes(activeRarity) ? otherRarityNames[activeRarity] : activeRarity]}, ${blueRarityNums[Object.keys(otherRarityNames).includes(activeRarity) ? otherRarityNames[activeRarity] : activeRarity]}) 0%, black 140%)`,
                                    height: `${(data.height / maxWear) * 100}%`,
                                    bottom: '0',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: 'white',
                                    // zIndex: activeInputWear === wearType ? 3 : 2, // Increase zIndex for the active wear range
                                    // opacity: activeInputWear && activeInputWear !== wearType ? 0.3 : 1, // Adjust opacity for non-active wear ranges
                                }}
                            >
                                {/* {wearType} */}
                            </div>
                        )) : <div />}
                    </div>}
                <div style={{ marginLeft: isMobile ? '3%' : '2%', marginRight: isMobile ? '3%' : '2%', display: activeInputItem != null ? "block" : "none" }}>
                    {/* <div style={{marginLeft: '2%', marginRight: '2%', height: '3rem'}}> */}
                    {/* </div> */}
                    <Slider
                        sx={{ marginTop: '8px' }}
                        value={resalePercent}
                        onChange={handleResaleSlider}
                        aria-labelledby="slider"
                        valueLabelDisplay="auto"
                        valueLabelFormat={valueLabelFormat}
                        // step={10}
                        // marks
                        min={0}
                        max={100000000}
                    />
                    {isMobile ?
                        <div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>

                                <TextField style={{ marginRight: '4px' }} id="outlined-basic" label="Min Float" variant="outlined" type="number" step="0.01" value={displayMinFloat} onChange={handleDisplayMinFloat} onBlur={handleMinFloat} onKeyUp={handleMinFloat} />
                                {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}> */}

                                {/* <Button variant="contained" style={{ backgroundColor: '#bb171a', color: 'white', marginRight: '4px' }} onClick={(event) => handleResaleSlider(null, [activeInputWear == null ? caseData["Items"][activeInputItem]["FloatMin"] / 100 * 100000000 : 0, activeInputWear == null ? caseData["Items"][activeInputItem]["FloatMax"] / 100 * 100000000 : 100000000])}>Reset</Button> */}

                                {/* <TextField id="outlined-basic" label="AVG Float" variant="outlined" type="number" value={displayAvgFloat ?? ''} onChange={(event) => handleDisplayAvgFloat(event)} /> */}
                                {/* <Button variant="contained" style={{ backgroundColor: '#bb171a', color: 'white', marginLeft: '4px' }} onClick={(event) => handleAvgFloat(displayAvgFloat, activeInputWear == null ? caseData["Items"][activeInputItem]["FloatMin"] / 100 : wearRangeStarts[activeInputWear], activeInputWear == null ? caseData["Items"][activeInputItem]["FloatMax"] / 100 : wearRanges[activeInputWear])}>Find AVG Float</Button> */}

                                {/* </div> */}
                                <TextField id="outlined-basic" label="Max Float" variant="outlined" type="number" step="0.01" value={displayMaxFloat} onChange={handleDisplayMaxFloat} onBlur={handleMaxFloat} onKeyUp={handleMaxFloat} />
                                {/* <FormControl style={{ width: '200px', marginRight: '10px' }}>
                    <InputLabel id="demo-simple-select-label">Repeat Simulations</InputLabel>
                    <Select
                        value={filterType}
                        label="Repeat Simulations"
                        onChange={(event) => {
                            // setFilterType(event.target.value);
                        }}
                    >
                        <MenuItem value="None">None</MenuItem>
                        <MenuItem value="Profit">Until A Profit</MenuItem>
                        <MenuItem value="XTimes">X Times</MenuItem>
                        <MenuItem value="Filter">Until Filter</MenuItem>
                    </Select>
                </FormControl> */}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', }}>

                                {/* <TextField id="outlined-basic" label="Min Float" variant="outlined" type="number" step="0.01" value={displayMinFloat} onChange={handleDisplayMinFloat} onBlur={handleMinFloat} onKeyUp={handleMinFloat} /> */}
                                {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}> */}

                                <Button variant="contained" style={{ backgroundColor: '#bb171a', color: 'white', marginRight: '4px', flex: '1', fontSize: '0.8rem' }} onClick={(event) => handleResaleSlider(null, [activeInputWear == null ? caseData["Items"][activeInputItem]["FloatMin"] / 100 * 100000000 : 0, activeInputWear == null ? caseData["Items"][activeInputItem]["FloatMax"] / 100 * 100000000 : 100000000])}>Reset</Button>

                                <TextField style={{ flex: '2' }} id="outlined-basic" label="AVG Float" variant="outlined" type="number" value={displayAvgFloat ?? ''} onChange={(event) => handleDisplayAvgFloat(event)} />
                                <Button variant="contained" style={{ backgroundColor: '#bb171a', color: 'white', marginLeft: '4px', flex: '1', fontSize: '0.8rem' }} onClick={(event) => handleAvgFloat(displayAvgFloat, activeInputWear == null ? caseData["Items"][activeInputItem]["FloatMin"] / 100 : wearRangeStarts[activeInputWear], activeInputWear == null ? caseData["Items"][activeInputItem]["FloatMax"] / 100 : wearRanges[activeInputWear])}>Find AVG Float</Button>

                                {/* </div> */}
                                {/* <TextField id="outlined-basic" label="Max Float" variant="outlined" type="number" step="0.01" value={displayMaxFloat} onChange={handleDisplayMaxFloat} onBlur={handleMaxFloat} onKeyUp={handleMaxFloat} /> */}
                                {/* <FormControl style={{ width: '200px', marginRight: '10px' }}>
                    <InputLabel id="demo-simple-select-label">Repeat Simulations</InputLabel>
                    <Select
                        value={filterType}
                        label="Repeat Simulations"
                        onChange={(event) => {
                            // setFilterType(event.target.value);
                        }}
                    >
                        <MenuItem value="None">None</MenuItem>
                        <MenuItem value="Profit">Until A Profit</MenuItem>
                        <MenuItem value="XTimes">X Times</MenuItem>
                        <MenuItem value="Filter">Until Filter</MenuItem>
                    </Select>
                </FormControl> */}
                            </div>
                        </div> :
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', }}>
                            <TextField id="outlined-basic" label="Min Float" variant="outlined" type="number" step="0.01" value={displayMinFloat} onChange={handleDisplayMinFloat} onBlur={handleMinFloat} onKeyUp={handleMinFloat} />
                            {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}> */}

                            <Button variant="contained" style={{ backgroundColor: '#bb171a', color: 'white', marginRight: '4px' }} onClick={(event) => handleResaleSlider(null, [activeInputWear == null ? caseData["Items"][activeInputItem]["FloatMin"] / 100 * 100000000 : 0, activeInputWear == null ? caseData["Items"][activeInputItem]["FloatMax"] / 100 * 100000000 : 100000000])}>Reset</Button>

                            <TextField id="outlined-basic" label="AVG Float" variant="outlined" type="number" value={displayAvgFloat ?? ''} onChange={(event) => handleDisplayAvgFloat(event)} />
                            <Button variant="contained" style={{ backgroundColor: '#bb171a', color: 'white', marginLeft: '4px' }} onClick={(event) => handleAvgFloat(displayAvgFloat, activeInputWear == null ? caseData["Items"][activeInputItem]["FloatMin"] / 100 : wearRangeStarts[activeInputWear], activeInputWear == null ? caseData["Items"][activeInputItem]["FloatMax"] / 100 : wearRanges[activeInputWear])}>Find AVG Float</Button>

                            {/* </div> */}
                            <TextField id="outlined-basic" label="Max Float" variant="outlined" type="number" step="0.01" value={displayMaxFloat} onChange={handleDisplayMaxFloat} onBlur={handleMaxFloat} onKeyUp={handleMaxFloat} />
                            {/* <FormControl style={{ width: '200px', marginRight: '10px' }}>
                <InputLabel id="demo-simple-select-label">Repeat Simulations</InputLabel>
                <Select
                    value={filterType}
                    label="Repeat Simulations"
                    onChange={(event) => {
                        // setFilterType(event.target.value);
                    }}
                >
                    <MenuItem value="None">None</MenuItem>
                    <MenuItem value="Profit">Until A Profit</MenuItem>
                    <MenuItem value="XTimes">X Times</MenuItem>
                    <MenuItem value="Filter">Until Filter</MenuItem>
                </Select>
            </FormControl> */}
                        </div>
                    }
                    {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: '4px', display: (activeInputItem != null ? "flex" : "none") }}>
                        
                    </div> */}
                    {/* <h3 style={{ width: '100%', textAlign: 'center', height: '0', marginBottom: '4rem', marginTop: '1rem' }}>Desired Float Range</h3> */}
                    {activeInputItem != null && isMobile && <TableContainer >
                        <Table sx={{}} aria-label="simple table">
                            <TableHead>
                                <TableRow >
                                    <TableCell align="center" />
                                    <TableCell align="center">Chance</TableCell>
                                    <TableCell align="center">Total Chance</TableCell>
                                    </TableRow>
                            </TableHead>
                            <TableBody>
                                
                                <TableRow>
                                <TableCell align="center">{activeRarity.replace('_', '-').replace("Contraband", "Gloves / Knives")}</TableCell>
                                <TableCell align="center">{formatNumber(caseData["PercentReceive"][activeRarity] * 100)}%</TableCell>
                                <TableCell align="center">1 in {formatNumber(1 / caseData["PercentReceive"][activeRarity])}</TableCell>
                                </TableRow>
                                <TableRow>
                                <TableCell align="center">{activeInputItem}</TableCell>
                                <TableCell align="center">{activeRarity != "Contraband" ? formatNumber(1 / Object.keys(caseData["ItemCosts"][activeRarity]).length * 100) : formatNumber(((1 * (caseData["Items"][activeInputItem]["ModifiedDropChance"] ?? 1)) / caseData["TotalKnifeChance"])  * 100)}%</TableCell>
                                    
                                <TableCell align="center">1 in {formatNumber((1 / (caseData["Items"][activeInputItem]["ModifiedDropChance"] ?? 1)) / (caseData["PercentReceive"][activeRarity] / (activeRarity != "Contraband" ? (Object.keys(caseData["ItemCosts"][activeRarity]).length) : caseData["TotalKnifeChance"])))}</TableCell>
                                
                                </TableRow>
                                <TableRow>
                                <TableCell align="center">
                                        <FormControl sx={{ flex: 1, marginRight: '4px', }}>
                                            <InputLabel id="pricing-source-label">StatTrak</InputLabel>
                                            <Select
                                                //  renderInput={(params) => <TextField {...params} label="StatTrak" />}

                                                labelId="isStattrak-label"
                                                id="isStattrak"
                                                value={stattrak}
                                                label="StatTrak"
                                                onChange={handleStatTrak}
                                            >
                                                <MenuItem value="Any">Any</MenuItem>
                                                <MenuItem value="Only Normal">Only Normal</MenuItem>
                                                <MenuItem value="Only StatTrak">Only StatTrak</MenuItem>
                                                {/* Add more pricing source options if needed */}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell align="center">{stattrak == "Any" ? 100 : stattrak == "Only Normal" ? 90 : 10}%</TableCell>
                                    
                                    <TableCell align="center">1 in {formatNumber((1 / (caseData["Items"][activeInputItem]["ModifiedDropChance"] ?? 1)) / (caseData["PercentReceive"][activeRarity] / (activeRarity != "Contraband" ? (Object.keys(caseData["ItemCosts"][activeRarity]).length) : caseData["TotalKnifeChance"]) * (stattrak == "Any" ? 1 : stattrak == "Only Normal" ? 0.9 : 0.1)))}</TableCell>
                                    
                                </TableRow>
                                <TableRow>
                                <TableCell align="center">{activeInputWear != null ? activeInputWear : "Any Wear"}</TableCell>
                                <TableCell align="center">{activeInputWear == null ? 100 : itemWears !== null ? formatNumber(itemWears[activeInputWear] * 100) : 100}%</TableCell>
                                <TableCell align="center">1 in {formatNumber((1 / (caseData["Items"][activeInputItem]["ModifiedDropChance"] ?? 1)) / (caseData["PercentReceive"][activeRarity] / (activeRarity != "Contraband" ? (Object.keys(caseData["ItemCosts"][activeRarity]).length) : caseData["TotalKnifeChance"]) * (activeInputWear == null ? 1 : itemWears !== null ? (itemWears[activeInputWear] * 1) : 1)    *  (stattrak == "Any" ? 1 : stattrak == "Only Normal" ? 0.9 : 0.1))) }</TableCell>
                                    
                                </TableRow>
                                <TableRow>
                                <TableCell align="center">{parseFloat(displayMinFloat).toString()} - {parseFloat(displayMaxFloat).toString()}</TableCell>
                                <TableCell align="center">{formatNumber(brute['percentChance'] / (activeInputWear == null ? 1 : itemWears[activeInputWear]) * 100)}%</TableCell>
                                <TableCell align="center">1 in {formatNumber((1 / (caseData["Items"][activeInputItem]["ModifiedDropChance"] ?? 1)) / (caseData["PercentReceive"][activeRarity] / (activeRarity != "Contraband" ? (Object.keys(caseData["ItemCosts"][activeRarity]).length) : caseData["TotalKnifeChance"]) * (activeInputWear == null ? 1 : itemWears !== null ? (itemWears[activeInputWear] * 1) : 1) * (brute['percentChance'] / (activeInputWear == null ? 1 : itemWears[activeInputWear]))*  (stattrak == "Any" ? 1 : stattrak == "Only Normal" ? 0.9 : 0.1))) }</TableCell>
                                    
                                </TableRow>
       

                            </TableBody>
                        </Table>
                    </TableContainer>}
                    {activeInputItem != null && !isMobile && <TableContainer >
                        <Table sx={{}} aria-label="simple table">
                            <TableHead>
                                <TableRow >
                                    <TableCell align="center" />
                                    <TableCell align="center">{activeRarity.replace('_', '-').replace("Contraband", "Gloves / Knives")}</TableCell>
                                    <TableCell align="center">{activeInputItem}</TableCell>
                                    <TableCell align="center">
                                        <FormControl sx={{ flex: 1, marginRight: '4px', }}>
                                            <InputLabel id="pricing-source-label">StatTrak</InputLabel>
                                            <Select
                                                //  renderInput={(params) => <TextField {...params} label="StatTrak" />}

                                                labelId="isStattrak-label"
                                                id="isStattrak"
                                                value={stattrak}
                                                label="StatTrak"
                                                onChange={handleStatTrak}
                                            >
                                                <MenuItem value="Any">Any</MenuItem>
                                                <MenuItem value="Only Normal">Only Normal</MenuItem>
                                                <MenuItem value="Only StatTrak">Only StatTrak</MenuItem>
                                                {/* Add more pricing source options if needed */}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell align="center">{activeInputWear != null ? activeInputWear : "Any Wear"}</TableCell>
                                    <TableCell align="center">{parseFloat(displayMinFloat).toString()} - {parseFloat(displayMaxFloat).toString()}</TableCell>
                                    {/* <TableCell align="right"></TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="left">Chance</TableCell>
                                    <TableCell align="center">{formatNumber(caseData["PercentReceive"][activeRarity] * 100)}%</TableCell>
                                    <TableCell align="center">{activeRarity != "Contraband" ? formatNumber(1 / Object.keys(caseData["ItemCosts"][activeRarity]).length * 100) : formatNumber(((1 * (caseData["Items"][activeInputItem]["ModifiedDropChance"] ?? 1)) / caseData["TotalKnifeChance"])  * 100)}%</TableCell>
                                    <TableCell align="center">{stattrak == "Any" ? 100 : stattrak == "Only Normal" ? 90 : 10}%</TableCell>
                                    <TableCell align="center">{activeInputWear == null ? 100 : itemWears !== null ? formatNumber(itemWears[activeInputWear] * 100) : 100}%</TableCell>
                                    <TableCell align="center">{formatNumber(brute['percentChance'] / (activeInputWear == null ? 1 : itemWears[activeInputWear]) * 100)}%</TableCell>
                                    {/* <TableCell /> */}
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left">Total Chance</TableCell>
                                    <TableCell align="center">1 in {formatNumber(1 / caseData["PercentReceive"][activeRarity])}</TableCell>
                                    <TableCell align="center">1 in {formatNumber((1 / (caseData["Items"][activeInputItem]["ModifiedDropChance"] ?? 1)) / (caseData["PercentReceive"][activeRarity] / (activeRarity != "Contraband" ? (Object.keys(caseData["ItemCosts"][activeRarity]).length) : caseData["TotalKnifeChance"])))}</TableCell>
                                    <TableCell align="center">1 in {formatNumber((1 / (caseData["Items"][activeInputItem]["ModifiedDropChance"] ?? 1)) / (caseData["PercentReceive"][activeRarity] / (activeRarity != "Contraband" ? (Object.keys(caseData["ItemCosts"][activeRarity]).length) : caseData["TotalKnifeChance"]) * (stattrak == "Any" ? 1 : stattrak == "Only Normal" ? 0.9 : 0.1)))}</TableCell>
                                    <TableCell align="center">1 in {formatNumber((1 / (caseData["Items"][activeInputItem]["ModifiedDropChance"] ?? 1)) / (caseData["PercentReceive"][activeRarity] / (activeRarity != "Contraband" ? (Object.keys(caseData["ItemCosts"][activeRarity]).length) : (caseData["TotalKnifeChance"])) * (activeInputWear == null ? 1 : itemWears !== null ? (itemWears[activeInputWear] * 1) : 1)    *  (stattrak == "Any" ? 1 : stattrak == "Only Normal" ? 0.9 : 0.1))) }</TableCell>
                                    <TableCell align="center">1 in {formatNumber((1 / (caseData["Items"][activeInputItem]["ModifiedDropChance"] ?? 1)) / (caseData["PercentReceive"][activeRarity] / (activeRarity != "Contraband" ? (Object.keys(caseData["ItemCosts"][activeRarity]).length) : (caseData["TotalKnifeChance"])) * (activeInputWear == null ? 1 : itemWears !== null ? (itemWears[activeInputWear] * 1) : 1) * (brute['percentChance'] / (activeInputWear == null ? 1 : itemWears[activeInputWear]))*  (stattrak == "Any" ? 1 : stattrak == "Only Normal" ? 0.9 : 0.1))) }</TableCell>
                                    {/* <TableCell align="right">{activeRarity !== null ? ((caseData["PercentReceive"][activeRarity] / Object.keys(activeRarity === "Contraband" ? caseData["KnifeCosts"] : caseData["ItemCosts"][activeRarity]).length) * 100 * (itemWears !== null && activeInputWear !== null ? (itemWears[activeInputWear]) : 1) * (brute['percentChance'] / (activeInputWear == null ? 1 : itemWears[activeInputWear]))).toFixed(4) : null}% or 1 in {activeRarity !== null ? (1 / ((caseData["PercentReceive"][activeRarity] / Object.keys(activeRarity === "Contraband" ? caseData["KnifeCosts"] : caseData["ItemCosts"][activeRarity]).length) * (itemWears !== null && activeInputWear !== null ? (itemWears[activeInputWear]) : 1) * (brute['percentChance'] / (activeInputWear == null ? 1 : itemWears[activeInputWear])))).toFixed(1) : null}</TableCell> */}
                                </TableRow>

                            </TableBody>
                        </Table>
                    </TableContainer>}

                </div>
                {combinedFloatRanges !== null ?
                    <div style={{ display: 'flex', paddingTop: '8px', flexDirection: 'row', height: '100%', maxHeight: '80vh', }}>
                        {/* // Main Console */}
                        <div style={{ flex: '1', justifyContent: 'center', height: '100%', alignItems: 'center' }}>
                            <ToggleButtonGroup
                                value={activeRarity}
                                sx={{ margin: 'auto', paddingTop: '12px' }}
                                aria-label="colorful toggle button group"
                                orientation="vertical"
                                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 'auto' }}

                            >
                                {
                                    caseData["KnifeCosts"] != null &&
                                    <ToggleButton
                                        key={`toggleButton-${"Contraband"}`}
                                        value={"Contraband"}
                                        onClick={handleRarityClick}
                                        style={{
                                            width: '90%',
                                            cursor: 'pointer',
                                            background: `linear-gradient(145deg, RGB(${redRarityNums[Object.keys(otherRarityNames).includes("Contraband") ? otherRarityNames["Contraband"] : "Contraband"]}, ${greenRarityNums[Object.keys(otherRarityNames).includes("Contraband") ? otherRarityNames["Contraband"] : "Contraband"]}, ${blueRarityNums[Object.keys(otherRarityNames).includes("Contraband") ? otherRarityNames["Contraband"] : "Contraband"]}) 0%, black 100%)`,
                                            color: 'white',
                                            border: 'none'
                                        }}
                                    >
                                        Gloves / Knives
                                    </ToggleButton>
                                }

                                {Object.entries(caseData["ItemCosts"]).map(([rarityBut, items], index) => (

                                    <ToggleButton
                                        key={`toggleButton-${rarityBut}`}
                                        value={rarityBut}
                                        onClick={handleRarityClick}
                                        style={{
                                            cursor: 'pointer',
                                            width: '90%',
                                            background: `linear-gradient(145deg, RGB(${redRarityNums[Object.keys(otherRarityNames).includes(rarityBut) ? otherRarityNames[rarityBut] : rarityBut]}, ${greenRarityNums[Object.keys(otherRarityNames).includes(rarityBut) ? otherRarityNames[rarityBut] : rarityBut]}, ${blueRarityNums[Object.keys(otherRarityNames).includes(rarityBut) ? otherRarityNames[rarityBut] : rarityBut]}) 0%, black 100%)`,
                                            color: 'white',
                                            border: 'none'
                                        }}
                                    >
                                        {rarityBut.replace('_', '-')}
                                    </ToggleButton>

                                ))}

                            </ToggleButtonGroup>

                        </div>
                        {/* // Input Skins */}
                        <div style={{ display: 'flex', flex: '5', flexDirection: 'row', overflow: 'auto', flexWrap: 'wrap', alignContent: 'flex-start', justifyContent: 'center' }}>
                            {Object.entries(activeRarity === 'Contraband' ? caseData["KnifeCosts"] : caseData["ItemCosts"][activeRarity]).sort(([nameA, itemA], [nameB, itemB]) => {
                                // Sort based on the cost (assuming itemCost is a numeric value)
                                const costA = isNaN(itemA["totalItemValue"]) ? Infinity : itemA["totalItemValue"];
                                const costB = isNaN(itemB["totalItemValue"]) ? Infinity : itemB["totalItemValue"];
                                return costB - costA;
                            }).map(([name, item]) => (activeRarity !== "Contraband" || (activeRarity == "Contraband" ? !Object.keys(caseData["KnifeCosts"][name]).includes("Normal") : false) ?
                                <Grid onClick={() => handleInputItemClick(name)} item xs={2} sm={4} md={4} key={name} className="grid-item" sx={{
                                    flex: 1,
                                    height: '190px',
                                    // width: '16vw',
                                    minWidth: isMobile ? '240px' : '260px',
                                    flexGrow: 0,
                                    position: 'relative', transition: 'box-shadow 0.3s',
                                    // '&:hover': {
                                    //     boxShadow: '0 8px 16px 0 rgba(0,0,0,0.6)'
                                    // }, 
                                    backgroundImage: activeInputItem == name ? `linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1))` : 'none',
                                    paddingLeft: '12px', padding: '12px',
                                    cursor: 'pointer',

                                    // Apply gray overlay for NaN prices

                                }}>
                                    <Card style={{
                                        background: `linear-gradient(145deg, RGB(${redRarityNums[Object.keys(otherRarityNames).includes(activeRarity) ? otherRarityNames[activeRarity] : activeRarity]}, ${greenRarityNums[Object.keys(otherRarityNames).includes(activeRarity) ? otherRarityNames[activeRarity] : activeRarity]}, ${blueRarityNums[Object.keys(otherRarityNames).includes(activeRarity) ? otherRarityNames[activeRarity] : activeRarity]}) 0%, black 100%)`, paddingLeft: 0
                                    }}>
                                        <CardMedia
                                            sx={{ height: 120, backgroundSize: "contain", position: 'relative', zIndex: 0 }}
                                            image={caseData["Items"][name]["ImageUrl"]}
                                        />

                                        <div style={{ position: 'absolute', top: '8px', right: '12px', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2,
                                            color: caseData?.ISTradeit ? (activeRarity === "Contraband" ? 
                                                (typeof caseData?.KnifeEstimates?.[name] === "object" ? 
                                                  (Object.entries(caseData?.KnifeEstimates?.[name]).filter(([key, value]) => value === true && key.includes("StatTrak")).length * 0.1 + 
                                                  Object.entries(caseData?.KnifeEstimates?.[name]).filter(([key, value]) => value === true && !key.includes("StatTrak")).length < 
                                                  Object.entries(caseData?.KnifeEstimates?.[name]).filter(([key, value]) => value === false && key.includes("StatTrak")).length * 0.1 + 
                                                  Object.entries(caseData?.KnifeEstimates?.[name]).filter(([key, value]) => value === false && !key.includes("StatTrak")).length) : 
                                                  (caseData?.KnifeEstimates?.[name] === false)
                                                ) ? "white" : "rgb(131 131 131)" :
                                                (typeof caseData?.ItemEstimates?.[activeRarity]?.[name] === "object" ? 
                                                  (Object.entries(caseData?.ItemEstimates?.[activeRarity]?.[name]).filter(([key, value]) => value === true && key.includes("StatTrak")).length * 0.1 + 
                                                  Object.entries(caseData?.ItemEstimates?.[activeRarity]?.[name]).filter(([key, value]) => value === true && !key.includes("StatTrak")).length < 
                                                  Object.entries(caseData?.ItemEstimates?.[activeRarity]?.[name]).filter(([key, value]) => value === false && key.includes("StatTrak")).length * 0.1 + 
                                                  Object.entries(caseData?.ItemEstimates?.[activeRarity]?.[name]).filter(([key, value]) => value === false && !key.includes("StatTrak")).length) : 
                                                  (caseData?.ItemEstimates?.[activeRarity]?.[name] === false)
                                                ) ? "white" : "rgb(131 131 131)"
                                              ) : "white"
                                         }}>
                                            {(
                                                <span>{formatNumber((activeRarity === 'Contraband' ? caseData["KnifeCosts"] : caseData["ItemCosts"][activeRarity])[name]["totalItemValue"] / exchangeRate, true)}</span>
                                            )}
                                        </div>
                                        <h4 style={{ position: 'absolute', bottom: '11px', right: '12px', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2, fontSize: '0.9rem' }}>
                                            {/* {!isNaN((activeRarity === 'Contraband' ? caseData["KnifeCosts"] : caseData["ItemCosts"][activeRarity])[name][activeInputWear]) && ( */}
                                            AVG Float:
                                            {/* )} */}
                                        </h4>
                                        <h3 style={{ position: 'absolute', bottom: '-8px', right: '12px', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2, fontSize: '1.1rem' }}>
                                            {/* {!isNaN((activeRarity === 'Contraband' ? caseData["KnifeCosts"] : caseData["ItemCosts"][activeRarity])[name][activeInputWear]) && activeInputWear != null && ( */}


                                            {bruteForceAverageWear(caseData["Items"][name]["FloatMin"] / 100, caseData["Items"][name]["FloatMax"] / 100, caseData["Items"][name]["FloatMin"] / 100, caseData["Items"][name]["FloatMax"] / 100)["avgWear"].toFixed(4)}
                                            {/* )} */}
                                        </h3>
                                        {/* <div style={{ position: 'absolute', top: '8px', right: '12px', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2 }}>
                                            <span>{currencySymbol}{activeRarity !== null ? activeInputItem !== null ? (caseData["ItemCosts"][activeRarity][name]["totalItemValue"]/exchangeRate).toFixed(2): '': 'null'}</span>
                                        </div> */}
                                        <div style={{ position: 'absolute', top: '8px', left: '16px', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2 }}>
                                            <span>1 in {activeRarity !== null ? formatNumber(((1 / (caseData["Items"][name]["ModifiedDropChance"] ?? 1)) / (caseData["PercentReceive"][activeRarity] / (activeRarity === "Contraband" ? (caseData["TotalKnifeChance"]) : Object.keys(caseData["ItemCosts"][activeRarity]).length)))) : null}</span>
                                        </div>
                                    </Card>
                                    <h4 style={{ lineHeight: 1.2, margin: '0', zIndex: 2, width: '140px', paddingTop: '4px', fontSize: '0.9rem' }}>{name.split(' | ')[0]}</h4>
                                    <h3 style={{ lineHeight: 1.2, margin: '0', zIndex: 2 , width: '165px', fontSize: '1.1rem'}}>{name.split(' | ')[1]}</h3>
                                </Grid> : <div />
                            ))}



                        </div>

                    </div>
                    : <Tab></Tab>}</div>


            {/* </Grid> */}
        </Container >

    );
}

export default FloatDropChances;
