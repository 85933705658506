import * as React from 'react';
import CookieConsent from "react-cookie-consent";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ToggleButtonGroup, ToggleButton, FormGroup, FormControlLabel, Checkbox, ButtonGroup, Button, Chip } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OpenBox from './OpenBox.svg';
import './Rankings.css'
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { ArrowForwardRounded, AttachMoney, LocalShipping, PriceCheck, Refresh } from '@mui/icons-material';
import SortIcon from '@mui/icons-material/Sort';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { buffLink, csfloatLink, skinportLink, steamLink, tradeitLink } from './allLinks';
import Slider from '@mui/material/Slider';
import Drawer from '@mui/material/Drawer';
import Scrollbars from 'react-custom-scrollbars-2';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { Switch } from '@mui/material';
import { useRef } from 'react';


function DealsPage() {
  const [open, setOpen] = React.useState(true);
  const [currencies, setCurrencies] = useState([]);
  const [ratios, setRatios] = useState([]);
  const [reverseRatios, setReverseRatios] = useState([]);
  const [rangeMax, setRangeMax] = useState(10000);
  const cachedSearch = localStorage.getItem('searchDeals');
  const [inputValue, setInputValue] = useState(cachedSearch || '');
  const [isMobileLayout, setIsMobileLayout] = useState(false);
  const storedSortDirection = (localStorage.getItem('sortDirectionDeals') ?? "true") == "true";
  const [sortDirection, setSortDirection] = useState(storedSortDirection ?? true);
  const cachedTypes = JSON.parse(localStorage.getItem('cachedTypesDeals'));
  const cachedPricingSource = localStorage.getItem('pricingSourceDeals');
  const [pricingSource, setPricingSource] = useState(cachedPricingSource || 'csfloat');
  const storedCurrency = localStorage.getItem('currency')
  const cachedUSDExchangeRate = localStorage.getItem('usdExchangeRate');
  const [exchangeRate, setExchangeRate] = useState(cachedUSDExchangeRate || 1);
  const [currency, setCurrency] = useState(storedCurrency || 'usd');
  const [rangeValue, setRangeValue] = useState([0, 10000]);
  const [loading, setLoading] = useState(false);
  const [types, setTypes] = useState(cachedTypes || [
    "Case"
  ]);
  const storedSort = localStorage.getItem('sortByDeals');
  const [options, setOptions] = useState([]);
  const [sortBy, setSortBy] = useState(storedSort || "unbox");
  const StyledToggleButton = styled(ToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: '#bb171a',
      marginLeft: '-1px',
    }
  });
  const [filteredItems, setFilteredItems] = useState([]);
  const cachedReverseDeals = (localStorage.getItem('reverseDeals') ?? "false") == "true";
  const lastFetchTimeRef = useRef(0);


  const [autoRefresh, setAutoRefresh] = useState(false);
  const [onlyLive, setOnlyLive] = useState(false);
  const [reverseDeals, setReverseDeals] = useState(cachedReverseDeals ?? false);

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

  function formatNumber(number, isCurrency) {
    // Use the user's locale
    const userLocale = navigator.language || 'en-US';

    // Create Intl.NumberFormat with user's locale
    if (isCurrency) {
      const formatter = new Intl.NumberFormat(userLocale, {
        //   minimumFractionDigits: 1,
        style: "currency",
        currency: currency,
        maximumFractionDigits: number > 1000 ? 0 : number > 100 ? 1 : 2,
      });
      return formatter.format(number);
    } else {
      const formatter = new Intl.NumberFormat(userLocale, {
        //   minimumFractionDigits: 1,
        maximumFractionDigits: number > 1000 ? 0 : number > 100 ? 1 : 2,
      });
      return formatter.format(number);
    }

    // Format the number

  }

  const handleSort = (event, sort) => {
    setSortBy(sort.props.value);
    localStorage.setItem('sortByDeals', sort.props.value);
    // setSelectedItem(item);
    // setSelectedRarity(rarity)
  };

  const handleSortDirection = (sortTest) => {
    setSortDirection(sortTest);
    localStorage.setItem('sortDirectionDeals', sortTest.toString());
    // setSelectedItem(item);
    // setSelectedRarity(rarity)
  };

  const handleToggle = (event) => {
    setAutoRefresh(event.target.checked);
  };

  const handlePricingSourceChange = (event, newPricingSource) => {
    let temp;

    // Check if newPricingSource has props and value
    if (newPricingSource && newPricingSource.props && newPricingSource.props.value !== undefined) {
      temp = newPricingSource.props.value;
    } else {
      temp = newPricingSource;
    }

    if (temp !== null) {
      setPricingSource(temp);
      localStorage.setItem('pricingSourceDeals', temp.toString());

      var filtered = (reverseDeals ? reverseRatios ?? {} : ratios ?? {})?.[temp]?.["ratios"].filter(item => item.price / exchangeRate >= rangeValue[0] && item.price / exchangeRate <= rangeValue[1]);
      if (filtered != undefined) {
        const prices = filtered.map(item => item.price);
        const max = Math.max(...((reverseDeals ? reverseRatios : ratios)[temp]["ratios"].map(item => item.price)));
        setRangeMax((max / exchangeRate).toFixed(2));
        const itemNames = filtered.map(item => item.item);
        setOptions(itemNames);
        const searchTerms = inputValue.toLowerCase().split(' ').filter(term => term); // Split by space and remove empty terms

        filtered = filtered.filter(item => {
          const itemName = item.item.toLowerCase();
          return searchTerms.every(term => itemName.includes(term)); // Ensure all terms are found in item name
        });
        // filtered = filtered.filter(item => item.item.toLowerCase().includes(inputValue.toLowerCase()));
        if (temp == "tradeit" && reverseDeals) {
          // reverse order of filtered
          filtered = filtered.reverse();
        }
        if (temp == "csfloat" && onlyLive && !reverseDeals) {
          const fallbackUpdatedAt = ratios["updated_at"];
          const majorityBucket = getMajorityBucket(filtered, fallbackUpdatedAt);

          // Filter out items whose effective timestamp falls into the majority bucket
          filtered = filtered.filter((item) => {
            const ts = (item.timestamp ?? fallbackUpdatedAt) * 1000;
            const bucket = getBucket(ts);
            return bucket !== majorityBucket;
          });
        }
        setFilteredItems(filtered);
      }
    }
  };

  const getBucket = (ts) => Math.floor(ts / 60000);

  // Helper function to determine the majority bucket from your items
  const getMajorityBucket = (items, fallbackUpdatedAt) => {
    const freqMap = {};
    items.forEach((item) => {
      // Use item.timestamp if available; otherwise use the fallback (e.g. ratios.updated_at)
      const ts = (item.timestamp ?? fallbackUpdatedAt) * 1000; // convert to ms
      const bucket = getBucket(ts);
      freqMap[bucket] = (freqMap[bucket] || 0) + 1;
    });

    let majorityBucket = null;
    let maxCount = 0;
    for (const bucket in freqMap) {
      if (freqMap[bucket] > maxCount) {
        majorityBucket = Number(bucket);
        maxCount = freqMap[bucket];
      }
    }
    return majorityBucket;
  };

  const CACHE_DURATION = 12 * 60 * 60 * 1000; // Cache duration in milliseconds (12 hours)
  const fetchRatios = async () => {
    setLoading(true);
    try {
      if (reverseDeals) {
        let url = 'https://api.csroi.com/reverseMarketRatios.json';
        const lastUpdatedStr = localStorage.getItem('lastUpdated');
        const lastFetchedVersion = localStorage.getItem('lastFetchedVersion'); // Store last ?v used
        const currentTime = new Date().getTime();
        const fiveAndHalfMinutes = 5 * 60 * 1000;
        // var oldRange = [0, 10000];

        let useVersion = lastFetchedVersion;

        if (!lastUpdatedStr || (currentTime - (parseFloat(lastUpdatedStr) * 1000)) >= fiveAndHalfMinutes) {
          useVersion = currentTime; // New version
          localStorage.setItem('lastFetchedVersion', useVersion);
        }

        url += `?v=${useVersion}`; // Always use stored ?v to avoid reverting to old data

        const response = await fetch(url);
        const data = await response.json();
        setReverseRatios(data);

        if (data.updated_at) {
          localStorage.setItem('lastUpdated', data.updated_at);
        }

        const searchTerms = inputValue.toLowerCase().split(' ').filter(term => term);
        var filtered = data[pricingSource]["ratios"].filter(item => {
          const itemName = item.item.toLowerCase();
          return item.price / exchangeRate >= rangeValue[0] &&
            item.price / exchangeRate <= rangeValue[1] &&
            searchTerms.every(term => itemName.includes(term));
        });

        const max = Math.max(...data[pricingSource]["ratios"].map(item => item.price));
        setRangeMax((max / exchangeRate).toFixed(2));
        setOptions(filtered.map(item => item.item));

        if (pricingSource == "tradeit" && reverseDeals) {
          filtered = filtered.reverse();
        }
        setFilteredItems(filtered);

      } else {
        let url = 'https://api.csroi.com/marketRatios.json';
        const lastUpdatedStr = localStorage.getItem('lastUpdated');
        const lastFetchedVersion = localStorage.getItem('lastFetchedVersion');
        const currentTime = new Date().getTime();
        const fiveAndHalfMinutes = 5 * 60 * 1000;
        // var oldRange = [0, 10000];

        let useVersion = lastFetchedVersion;

        if (!lastUpdatedStr || (currentTime - (parseFloat(lastUpdatedStr) * 1000)) >= fiveAndHalfMinutes) {
          useVersion = currentTime;
          localStorage.setItem('lastFetchedVersion', useVersion);
        }

        url += `?v=${useVersion}`;

        const response = await fetch(url);
        const data = await response.json();
        setRatios(data);

        if (data.updated_at) {
          localStorage.setItem('lastUpdated', data.updated_at);
        }

        const searchTerms = inputValue.toLowerCase().split(' ').filter(term => term);
        var filtered = data[pricingSource]["ratios"].filter(item => {
          const itemName = item.item.toLowerCase();
          return item.price / exchangeRate >= rangeValue[0] &&
            item.price / exchangeRate <= rangeValue[1] &&
            searchTerms.every(term => itemName.includes(term));
        });

        const max = Math.max(...data[pricingSource]["ratios"].map(item => item.price));
        setRangeMax((max / exchangeRate).toFixed(2));
        setOptions(filtered.map(item => item.item));

        if (pricingSource == "tradeit" && reverseDeals) {
          filtered = filtered.reverse();
        }
        if (pricingSource == "csfloat" && onlyLive && !reverseDeals) {
          const fallbackUpdatedAt = ratios["updated_at"];
          const majorityBucket = getMajorityBucket(filtered, fallbackUpdatedAt);

          // Filter out items whose effective timestamp falls into the majority bucket
          filtered = filtered.filter((item) => {
            const ts = (item.timestamp ?? fallbackUpdatedAt) * 1000;
            const bucket = getBucket(ts);
            return bucket !== majorityBucket;
          });
        }
        setFilteredItems(filtered);
      }
    } catch (error) {
      console.error('Failed to fetch market ratios:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!autoRefresh) return;

    const checkAndFetch = async () => {
      const now = Date.now();
      // Prevent another fetch if one was done less than 30 seconds ago.
      if (now - lastFetchTimeRef.current < 30000) return;

      const lastUpdatedStr = localStorage.getItem("lastUpdated");
      const currentTime = now;
      const fiveMinutes = 5 * 60 * 1000;

      if (!lastUpdatedStr || currentTime - parseFloat(lastUpdatedStr) * 1000 >= fiveMinutes) {
        lastFetchTimeRef.current = now;
        console.log("Fetching new data with current filters...");
        await fetchRatios();
      }
    };

    // Immediately check and then set an interval
    checkAndFetch();
    const interval = setInterval(checkAndFetch, 30000);

    return () => clearInterval(interval);
  }, [autoRefresh, reverseDeals, inputValue, pricingSource, onlyLive, rangeValue]);

  useEffect(() => {
    if (reverseDeals && reverseRatios.length == 0 || !reverseDeals && ratios.length == 0) {
      fetchRatios();
    } else {
      // var oldRange = [0, 10000];
      const searchTerms = inputValue.toLowerCase().split(' ').filter(term => term); // Split by space and remove empty terms

      var filtered = (reverseDeals ? reverseRatios : ratios)[pricingSource]["ratios"].filter(item => {
        const itemName = item.item.toLowerCase();
        return item.price / exchangeRate >= rangeValue[0] &&
          item.price / exchangeRate <= rangeValue[1] &&
          searchTerms.every(term => itemName.includes(term)); // Ensure all terms are found in item name
      });
      const prices = filtered.map(item => item.price);
      const max = Math.max(...((reverseDeals ? reverseRatios : ratios)[pricingSource]["ratios"].map(item => item.price)));
      setRangeMax((max / exchangeRate).toFixed(2));
      const itemNames = filtered.map(item => item.item);
      setOptions(itemNames);

      if (pricingSource == "tradeit" && reverseDeals) {
        filtered = filtered.reverse();
      }
      if (pricingSource == "csfloat" && onlyLive && !reverseDeals) {
        const fallbackUpdatedAt = ratios["updated_at"];
        const majorityBucket = getMajorityBucket(filtered, fallbackUpdatedAt);

        // Filter out items whose effective timestamp falls into the majority bucket
        filtered = filtered.filter((item) => {
          const ts = (item.timestamp ?? fallbackUpdatedAt) * 1000;
          const bucket = getBucket(ts);
          return bucket !== majorityBucket;
        });
      }
      setFilteredItems(filtered);
    }

    // localStorage.setItem('currencies', JSON.stringify(currencyList));
    // localStorage.setItem('currenciesTimestamp', Date.now().toString());
  }, [reverseDeals, onlyLive]);

  // useEffect(() => {
  //   console.log("test3");
  //   fetchRatios();
  // }, []);

  const UpdatedTimestamp = ({ updatedAt }) => {
    const [timeAgo, setTimeAgo] = useState("");

    useEffect(() => {
      const updateTimestamp = () => {
        const now = new Date();
        const timeDifference = now.getTime() - updatedAt * 1000;

        const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
        const minutesDifference = Math.floor((timeDifference / (1000 * 60)) % 60);
        const daysDifference = Math.floor(hoursDifference / 24);

        let message;
        if (daysDifference > 0) {
          message = `${daysDifference} day${daysDifference !== 1 ? "s" : ""} ago`;
        } else if (hoursDifference > 0) {
          message = `${hoursDifference} hour${hoursDifference !== 1 ? "s" : ""} ago`;
        } else {
          message = `${minutesDifference} minute${minutesDifference !== 1 ? "s" : ""} ago`;
        }

        setTimeAgo(message);
      };

      // Update every 60 seconds
      updateTimestamp();
      const interval = setInterval(updateTimestamp, 60000);

      return () => clearInterval(interval); // Cleanup on unmount
    }, [updatedAt]);

    return <>{timeAgo}</>;
  };

  const Row = ({ index, style }) => {
    const item = filteredItems[index];
    let redNum;
    let greenNum;
    let blueNum;
    // let walletRate = ((item.steam_price - (0.13 * item.steam_price)) / item.price);
    let walletRate = reverseDeals && pricingSource == "tradeit" ? (item.price / item.steam_price) : 1 / item.ratio;
    let compareRate = walletRate;
    // pricingSource == "tradeit" && !reverseDeals ? (item.price / item.steam_price) - 0.15 : pricingSource == "tradeit" && reverseDeals ? walletRate * 1.9 : walletRate;
    if (reverseDeals) {
      if (compareRate > 1) {
        redNum = 223;
        greenNum = 199;
        blueNum = 0;
      } else if (compareRate > 0.9) {
        redNum = 187 + Math.abs(compareRate - 0.9) / 0.1 * 36;
        greenNum = 23 + Math.abs(compareRate - 0.9) / 0.1 * 176;
        blueNum = 26 - Math.abs(compareRate - 0.9) / 0.1 * 26;
      } else if (compareRate > 0.8) {
        redNum = 158 + Math.abs(compareRate - 0.8) / 0.1 * 29;
        greenNum = 0 + Math.abs(compareRate - 0.8) / 0.1 * 23;
        blueNum = 197 - Math.abs(compareRate - 0.8) / 0.1 * 171;
      } else if (compareRate > 0.7) {
        redNum = 104 + Math.abs(compareRate - 0.7) / 0.1 * 54;
        greenNum = 50 - Math.abs(compareRate - 0.7) / 0.1 * 50;
        blueNum = 234 - Math.abs(compareRate - 0.7) / 0.1 * 37;
      } else {
        redNum = 2 + Math.abs(compareRate) / 0.7 * 102;
        greenNum = 79 - Math.abs(compareRate) / 0.7 * 29;
        blueNum = 228 + Math.abs(compareRate) / 0.7 * 6;
      }
    } else {
      if (compareRate > 1.5) {
        redNum = 223;
        greenNum = 199;
        blueNum = 0;
      } else if (compareRate > 1.4) {
        redNum = 187 + Math.abs(compareRate - 1.4) / 0.1 * 36;
        greenNum = 23 + Math.abs(compareRate - 1.4) / 0.1 * 176;
        blueNum = 26 - Math.abs(compareRate - 1.4) / 0.1 * 26;
      } else if (compareRate > 1.3) {
        redNum = 158 + Math.abs(compareRate - 1.3) / 0.1 * 29;
        greenNum = 0 + Math.abs(compareRate - 1.3) / 0.1 * 23;
        blueNum = 197 - Math.abs(compareRate - 1.3) / 0.1 * 171;
      } else if (compareRate > 1.2) {
        redNum = 104 + Math.abs(compareRate - 1.2) / 0.1 * 54;
        greenNum = 50 - Math.abs(compareRate - 1.2) / 0.1 * 50;
        blueNum = 234 - Math.abs(compareRate - 1.2) / 0.1 * 37;
      } else {
        redNum = 2 + Math.abs(compareRate) / 1.2 * 102;
        greenNum = 79 - Math.abs(compareRate) / 1.2 * 29;
        blueNum = 228 + Math.abs(compareRate) / 1.2 * 6;
      }
    }


    const customStyle = {
      background: `linear-gradient(${isMobileLayout ? "150deg" : "170deg"}, RGB(${redNum}, ${greenNum}, ${blueNum}) 0%, black 100%)`, height: isMobileLayout ? '160px' : '140px', borderRadius: '8px', width: '99%', boxSizing: 'content', lineHeight: isMobileLayout ? '1' : '0', display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap'
    };

    return (
      <tr style={{ ...style, ...customStyle }}>
        {isMobileLayout ? (
          <>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', padding: '4px' }}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ flex: 6, textAlign: 'center' }}>
                  <img src={item.item_image} style={{ width: '100%', maxHeight: '94px', margin: 'auto', objectFit: 'contain' }} />
                </div>
                <div style={{ flex: 10, textAlign: 'center', verticalAlign: 'middle', lineHeight: 1, fontSize: '16px' }}>
                  <p>
                    {item.item.includes("StatTrak™") && "StatTrak™"}
                    {item.item.includes("StatTrak™") && <br />}
                    {item.item.replace("StatTrak™", "").replace(/\(.*\)/, "").trim()}
                    {item.item.match(/\(.*\)/) && <br />}
                    {item.item.match(/\(.*\)/) && item.item.match(/\(.*\)/)[0]}
                  </p>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', paddingTop: '2px' }}>
                <div style={{ flex: 2, textAlign: 'center', verticalAlign: 'middle' }}>
                  <p style={{ fontSize: '14px', lineHeight: '0' }}>{reverseDeals ? "Steam" : pricingSource.replace("csfloat", "CSFloat").replace("skinport", "Skinport").replace("buff163", "Buff.163").replace("youpin", "UUPin").replace("tradeit", "Tradeit").replace("_withdraw", "").replace("tf2", "TF2")}</p>
                  {reverseDeals ? (
                    <a href={pricingSource == "tf2" ? (item.referral_link) : pricingSource == "skinport" ? skinportLink(item.item) : pricingSource == "buff163" ? buffLink(item.item) : pricingSource == "youpin" ? buffLink(item.item) : pricingSource.includes("tradeit") ? tradeitLink(item.item) : csfloatLink(item.item)} target='_blank'>
                      {formatNumber(item.steam_price / exchangeRate, true)}
                    </a>
                  ) : (
                    <a href={pricingSource == "tf2" ? (item.steam_link) : steamLink(item.item)} target='_blank'>
                      {formatNumber(item.price / exchangeRate, true)}
                    </a>
                  )}
                </div>
                <div style={{ flex: 1, textAlign: 'center', verticalAlign: 'middle' }}>

                  <p style={{ fontSize: '14px', lineHeight: '0' }}>{reverseDeals ?
                    pricingSource !== "tradeit" ? Math.round((1 - (item.price / item.steam_price)) * 100) + "% Less" :
                      Math.round(((item.price / item.steam_price) - 1) * 100) + "% More"
                    : pricingSource !== "tradeit" ? Math.round((1 - (item.price / item.steam_price)) * 100) + "% Off" :
                      Math.round((1 - (item.steam_price / item.price)) * 100) + "% Less"
                  }
                  </p>

                  <ArrowForwardRounded style={{ marginTop: 'auto', marginBottom: 'auto', width: '30px', height: '24px' }} />
                </div>
                <div style={{ flex: 2, textAlign: 'center', verticalAlign: 'middle' }}>
                  <p style={{ fontSize: '14px', lineHeight: '0' }}>{reverseDeals ? pricingSource.replace("csfloat", "CSFloat").replace("skinport", "Skinport").replace("buff163", "Buff.163").replace("youpin", "UUPin").replace("tradeit", "Tradeit").replace("_withdraw", "").replace("tf2", "TF2") : "Steam"}</p>

                  {reverseDeals ? (
                    <a href={pricingSource == "tf2" ? (item.steam_link) : steamLink(item.item)} target='_blank'>
                      {formatNumber(item.price / exchangeRate, true)}
                    </a>
                  ) : (
                    <a href={pricingSource == "tf2" ? (item.referral_link) : pricingSource == "skinport" ? skinportLink(item.item) : pricingSource == "buff163" ? buffLink(item.item) : pricingSource == "youpin" ? buffLink(item.item) : pricingSource.includes("tradeit") ? tradeitLink(item.item) : csfloatLink(item.item)} target='_blank'>
                      {formatNumber(item.steam_price / exchangeRate, true)}
                    </a>
                  )}
                </div>
              </div>
              {/* <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <div style={{ flex: 2, textAlign: 'center', verticalAlign: 'middle' }}>
          {pricingSource.includes("tradeit") ? 
            Math.round((1 - (item.steam_price / item.price)) * 100) + "% More"
            : Math.round((1 - (item.price / item.steam_price)) * 100) + "% Off"}
        </div>
        <div style={{ flex: 4, textAlign: 'center', verticalAlign: 'middle' }}>
          {formatNumber(pricingSource == "tradeit" ? (item.price / item.steam_price) : walletRate, true)}
        </div>
      </div> */}
            </div>
          </>
        ) : (
          <>
            <td style={{ flex: 4, paddingLeft: '4px' }}>
              <img src={item.item_image} style={{ width: '100%', maxHeight: '135px', margin: 'auto', objectFit: 'contain' }} />
            </td>
            <td style={{ flex: 10, textAlign: 'center', verticalAlign: 'middle', lineHeight: '1' }}>{item.item}</td>
            <td style={{ flex: 2, textAlign: 'center', verticalAlign: 'middle' }}>
              {!reverseDeals ?
                <a href={pricingSource == "tf2" ? (item.referral_link) : pricingSource == "skinport" ? skinportLink(item.item) : pricingSource == "buff163" ? buffLink(item.item) : pricingSource == "youpin" ? buffLink(item.item) : pricingSource.includes("tradeit") ? tradeitLink(item.item) : csfloatLink(item.item)} target='_blank'>
                  {formatNumber(item.price / exchangeRate, true)}
                </a> :
                (
                  <a href={pricingSource == "tf2" ? (item.steam_link) : steamLink(item.item)} target='_blank'>
                    {formatNumber(item.steam_price / exchangeRate, true)}
                  </a>
                )}
            </td>
            <td style={{ flex: 1, textAlign: 'center', verticalAlign: 'middle' }}>
              <ArrowForwardRounded style={{ marginTop: 'auto', marginBottom: 'auto', width: '30px', height: '24px' }} />
            </td>
            <td style={{ flex: 2, textAlign: 'center', verticalAlign: 'middle' }}>
              {!reverseDeals ?
                <a href={pricingSource == "tf2" ? (item.steam_link) : steamLink(item.item)} target='_blank'>
                  {formatNumber(item.steam_price / exchangeRate, true)}
                </a> :
                (
                  <a href={pricingSource == "tf2" ? (item.referral_link) : pricingSource == "skinport" ? skinportLink(item.item) : pricingSource == "buff163" ? buffLink(item.item) : pricingSource == "youpin" ? buffLink(item.item) : pricingSource.includes("tradeit") ? tradeitLink(item.item) : csfloatLink(item.item)} target='_blank'>
                    {formatNumber(item.price / exchangeRate, true)}
                  </a>
                )}
            </td>
            <td style={{ flex: 2, textAlign: 'center', verticalAlign: 'middle' }}>
              {reverseDeals ?
                pricingSource !== "tradeit" ? Math.round((1 - (item.price / item.steam_price)) * 100) + "% Less" :
                  Math.round(((item.price / item.steam_price) - 1) * 100) + "% More"
                : pricingSource !== "tradeit" ? Math.round((1 - (item.price / item.steam_price)) * 100) + "% Off" :
                  Math.round((1 - (item.steam_price / item.price)) * 100) + "% Less"
              }
            </td>
            {pricingSource == "csfloat" && !reverseDeals && <td style={{ flex: 2, textAlign: 'center', verticalAlign: 'middle' }}>

              {(() => {
                const updatedAtUTC = (item.timestamp ?? (reverseDeals ? reverseRatios : ratios)["updated_at"]) * 1000;
                const now = new Date();
                const timeDifference = now.getTime() - updatedAtUTC;

                const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
                const minutesDifference = Math.floor((timeDifference / (1000 * 60 * 60)) * 60);
                const daysDifference = Math.floor(hoursDifference / 24);

                let message;
                if (daysDifference > 0) {
                  message = `${daysDifference} day${daysDifference !== 1 ? 's' : ''}`;
                } else if (hoursDifference > 0) {
                  // if (hoursDifference > 0) {
                  message = `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''}`;
                  // } else {
                  //   message = "Less than an hour ago";
                  // }
                } else {
                  message = `${minutesDifference} min${minutesDifference !== 1 ? 's' : ''}`;
                }
                return message;
              })()}
            </td>}
            <td style={{ flex: 4, textAlign: 'center', verticalAlign: 'middle' }}>
              {formatNumber(walletRate, true)}
            </td>
          </>
        )}
      </tr>
    );
  };


  function valueLabelFormat(value) {
    return formatNumber(value, true);
  }

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const cachedCurrencies = localStorage.getItem('currencies');
        const cachedTimestamp = localStorage.getItem('currenciesTimestamp');

        if (
          cachedCurrencies &&
          cachedTimestamp &&
          Date.now() - Number(cachedTimestamp) < CACHE_DURATION
        ) {
          setCurrencies(JSON.parse(cachedCurrencies));
        } else {
          let url = '/currencies.json'

          if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
            url = 'https://csroi.com/currencies.json';
          }

          const response = await fetch(url);
          const data = await response.json();
          const currencyList = Object.keys(data);
          setCurrencies(currencyList);

          localStorage.setItem('currencies', JSON.stringify(currencyList));
          localStorage.setItem('currenciesTimestamp', Date.now().toString());
        }
      } catch (error) {
        console.error('Failed to fetch currencies:', error);
      }
    };

    const updateExchangeRateToUSD = async () => {
      try {
        const cachedCurrency = localStorage.getItem('currency');
        const cachedUSDExchangeRate = localStorage.getItem('usdExchangeRate');
        const cachedTimestamp = localStorage.getItem('usdExchangeRateTimestamp');


        if (
          cachedCurrency &&
          currency == cachedCurrency &&
          cachedUSDExchangeRate &&
          cachedTimestamp &&
          Date.now() - Number(cachedTimestamp) < CACHE_DURATION
        ) {
          setCurrency(cachedCurrency);
          // setPricingSource('USD');
          setExchangeRate(cachedUSDExchangeRate);

        } else {
          const response = await fetch(
            `https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/${currency.toLowerCase()}.json`
          );
          const data = await response.json();
          const usdExchangeRate = data[currency.toLowerCase()]['usd'];
          // console.log(data);

          console.log(currency);
          console.log(usdExchangeRate);

          setCurrency(currency);
          // setPricingSource('USD');
          setExchangeRate(usdExchangeRate);

          localStorage.setItem('currency', currency);
          localStorage.setItem('usdExchangeRate', usdExchangeRate);
          localStorage.setItem('usdExchangeRateTimestamp', Date.now().toString());


        }
      } catch (error) {
        console.error('Failed to update exchange rate to USD:', error);
      }
    };

    fetchCurrencies();
    updateExchangeRateToUSD();
  }, [currency]);

  const handleRangeChange = (event, newValue) => {
    setRangeValue(newValue);
    localStorage.setItem('dealsRange', JSON.stringify(newValue));
    const [minPrice, maxPrice] = newValue;
    var filtered = (reverseDeals ? reverseRatios : ratios)[pricingSource]["ratios"].filter(item => item.price / exchangeRate >= minPrice && item.price / exchangeRate <= maxPrice);

    const itemNames = filtered.map(item => item.item);
    setOptions(itemNames);
    // filtered = filtered.filter(item => item.item.toLowerCase().includes(inputValue.toLowerCase()));
    const searchTerms = inputValue.toLowerCase().split(' ').filter(term => term); // Split by space and remove empty terms

    var filtered = filtered.filter(item => {
      const itemName = item.item.toLowerCase();
      return searchTerms.every(term => itemName.includes(term)); // Ensure all terms are found in item name
    });
    if (pricingSource == "tradeit" && reverseDeals) {
      filtered = filtered.reverse();
    }
    if (pricingSource == "csfloat" && onlyLive && !reverseDeals) {
      const fallbackUpdatedAt = ratios["updated_at"];
      const majorityBucket = getMajorityBucket(filtered, fallbackUpdatedAt);

      // Filter out items whose effective timestamp falls into the majority bucket
      filtered = filtered.filter((item) => {
        const ts = (item.timestamp ?? fallbackUpdatedAt) * 1000;
        const bucket = getBucket(ts);
        return bucket !== majorityBucket;
      });
    }
    setFilteredItems(filtered);

  };


  const handleChange = (type) => {
    const typeValue = type.target.value;
    let newTypes;

    if (!types.includes(typeValue)) {
      newTypes = [...types, typeValue];
      if (typeValue === "Graffiti") {
        newTypes.push("Pins");
      }
    } else {
      newTypes = types.filter(x => x !== typeValue);
      if (typeValue === "Graffiti") {
        newTypes = newTypes.filter(x => x !== "Pins");
      }
    }

    localStorage.setItem('cachedTypesDeals', JSON.stringify(newTypes));
    setTypes(newTypes);
  };

  const handleInputChange = (event, newInputValue) => {
    localStorage.setItem('searchDeals', newInputValue);
    setInputValue(newInputValue);

    const searchTerms = newInputValue.toLowerCase().split(' ').filter(term => term); // Split by space and remove empty terms

    var filtered = (reverseDeals ? reverseRatios : ratios)[pricingSource]["ratios"].filter(item => {
      const itemName = item.item.toLowerCase();
      return item.price / exchangeRate >= rangeValue[0] &&
        item.price / exchangeRate <= rangeValue[1] &&
        searchTerms.every(term => itemName.includes(term)); // Ensure all terms are found in item name
    });

    if (pricingSource == "tradeit" && reverseDeals) {
      filtered = filtered.reverse();
    }
    if (pricingSource == "csfloat" && onlyLive && !reverseDeals) {
      const fallbackUpdatedAt = ratios["updated_at"];
      const majorityBucket = getMajorityBucket(filtered, fallbackUpdatedAt);

      // Filter out items whose effective timestamp falls into the majority bucket
      filtered = filtered.filter((item) => {
        const ts = (item.timestamp ?? fallbackUpdatedAt) * 1000;
        const bucket = getBucket(ts);
        return bucket !== majorityBucket;
      });
    }
    setFilteredItems(filtered);
  };


  const valuetext = (value) => {
    return `${value}`;
  };


  const handleResize = () => {
    // Calculate aspect ratio 
    const glanceInfoSize = window.innerWidth * 2 / 11

    // Define the threshold value for aspect ratio to differentiate between mobile and desktop
    const glanceInfoThreshold = 215; // Adjust this value based on your needs

    // Set the state based on the aspect ratio
    setIsMobileLayout(glanceInfoSize < glanceInfoThreshold);
  };

  useEffect(() => {
    // Initial calculation
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };

  }, []);

  const theme = createTheme({
    palette: {
      mode: 'dark',
    },
    typography: {
      fontFamily: 'Barlow',
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="garequest"
        style={{ background: "#2B373B", zIndex: 99999 }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies for anonymous Google Analytics. By continuing to use this website, you consent to the use of cookies.
      </CookieConsent>
      <Helmet>
        {/* Standard Meta Tags */}
        <title>CSROI Deals! | Find the Best Items to Buy & Sell on the Steam Community Market!</title>
        <meta
          name="description"
          content="Choose your favorite marketplace and set your desired price range to discover unbeatable deals for skins. Buy low on platforms like CSFloat, Buff, and more – then flip them on the Steam Market for maximum Steam balance!"
        />
        <meta
          name="keywords"
          content="CS2, CS:GO, skinport, csfloat, buff, uupin, tradeit, roi, case, capsule, souvenir, sticker, armory, stars, invest, pin, graffiti, autographs, listings, profit, unbox, simulator, history, music kit, collection, operation"
        />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="CSROI Deals! | Best Deals to Buy & Sell Skins on Steam" />
        <meta
          property="og:description"
          content="Discover top deals across multiple marketplaces. Find skin listings on CSFloat, Buff, and more – then resell on the Steam Community Market for maximum profit and Steam balance."
        />
        <meta property="og:image" content="https://csroi.com/logo512.png" />
        <meta property="og:url" content="https://csroi.com/#/deals" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="CSROI Deals! | Buy Low, Sell High on the Steam Market" />
        <meta
          name="twitter:description"
          content="Explore top skin deals from various marketplaces. Buy low on third‑party platforms and resell on Steam for the best return on your money!"
        />
        <meta name="twitter:image" content="https://csroi.com/logo512.png" />

        {/* Canonical URL */}
        <link rel="canonical" href="https://csroi.com/#/deals" />

        {/* Schema Markup (JSON-LD) */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "CSROI Deals",
            "description": "Find the best skin deals across supported marketplaces. Buy low on third-party sites like CSFloat and resell on the Steam Community Market to maximize your Steam balance.",
            "url": "https://csroi.com/#/deals",
            "image": "https://csroi.com/logo512.png",
            "publisher": {
              "@type": "Organization",
              "name": "CSROI",
              "logo": {
                "@type": "ImageObject",
                "url": "https://csroi.com/logo512.png"
              }
            }
          })}
        </script>
      </Helmet>

      <Box sx={{ display: 'flex', padding: 0, transition: "width 5s infinite alternate, height 5s infinite alternate", }}>
        <Drawer
          sx={{
            display: isMobileLayout ? 'none' : 'initial',
            width: "240px",
            yOverflow: 'scroll',
            // flexShrink: 0,

            '& .MuiDrawer-paper': {
              width: "240px",
              boxSizing: 'border-box',
            },
          }}
          variant="permanent"
          anchor="left"
        // open={openDrawer}
        >
          <Scrollbars
            autoHide
            // autoHideTimeout={1000}
            // autoHideDuration={200}
            renderView={props => <div {...props} style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }} />}
            renderThumbVertical={({ style }) => (
              <div style={{ ...style, backgroundColor: 'lightgray', borderRadius: '4px' }} />
            )}
          >
            <DrawerHeader sx={{ paddingTop: '12px', paddingBottom: '12px' }}>
              <Link to='/' style={{ textDecoration: 'none', color: 'inherit', width: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: 'auto', marginBottom: '6px', marginTop: '6px', cursor: 'pointer' }} >
                  <img src="/csroi.png" class="p-2" style={{ maxWidth: '280px', width: '100%', height: "auto", objectFit: 'contain', margin: 'auto' }} />

                </div>
              </Link>


            </DrawerHeader>
            <Divider />
            {/* <h5 style={{ textAlign: 'center', maxHeight: '180px', padding: '4px' }}>
              Welcome to the all new CSROI.com!<br /><br />
              Featuring a new overhauled React UI, full currency conversion, a new Investing ROI, upgraded simulator, among many more!<br /><br />
              Any feedback is appreciated <a href="https://twitter.com/BrongoCS" target='_blank'>@BrongoCS</a>
            </h5> */}
            {/* <Divider /> */}
            {/* <iframe width={drawerWidth} height="180" src="https://www.youtube.com/embed/mMCqwpVC2Zc?si=1DY9BEJKcLe66qy3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;" allowFullScreen ></iframe> */}
            {/* <Divider /> */}
            <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer' }}>
                <p>
                  Rankings

                </p>
              </div>
            </Link>
            <Divider />
            <Link to='/armory' style={{ textDecoration: 'none', color: 'inherit' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer' }}>
                <p style={{ lineHeight: '0.9' }}>
                  Armory
                  <br />
                  <span style={{ fontSize: '0.7rem' }}>Find the best way to spend YOUR Armory Stars</span>
                </p>
                {/* <Chip label="NEW" variant="outlined" size='small' sx={{ color: 'white', border: '1px white solid', fontSize: '0.8rem', height: '24px', width: '48px', padding: '0' }} /> */}

              </div>
            </Link>
            <Divider />
            <Link to='/deals' style={{ textDecoration: 'none', color: 'inherit' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.12)' }}>
                <p style={{ lineHeight: '0.9' }}>
                  Deals
                  <br />
                  <span style={{ fontSize: '0.7rem' }}>Resell Skins to get up to 1.5x Steam Wallet Balance!</span>
                </p>
                <Chip label="NEW" variant="outlined" size='small' sx={{ color: 'white', border: '1px white solid', fontSize: '0.8rem', height: '24px', width: '48px', padding: '0' }} />
              </div>
            </Link>
            <Divider style={{ borderWidth: '2px' }} />
            <Link to='/inventory' style={{ textDecoration: 'none', color: 'inherit' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer' }}>
                <p style={{ lineHeight: '0.9' }}>
                  Inventory
                  <br />
                  <span style={{ fontSize: '0.7rem', }}>Find YOUR Unboxing and Investing ROI!</span>
                </p>
                {/* <Chip label="NEW" variant="outlined" size='small' sx={{ color: 'white', border: '1px white solid', fontSize: '0.8rem', height: '24px', width: '48px', padding: '0' }} /> */}
              </div>
            </Link>
            <Divider />
            <Link to='/charmcolors' style={{ textDecoration: 'none', color: 'inherit' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer', }}>
                <p style={{ lineHeight: '0.9' }}>
                  Charm Colors
                  <br />
                  <span style={{ fontSize: '0.7rem' }}>Find the color and pattern of charm to match your skin!</span>
                </p>
                {/* <Chip label="NEW" variant="outlined" size='small' sx={{ color: 'white', border: '1px white solid', fontSize: '0.8rem', height: '24px', width: '48px', padding: '0' }} /> */}

              </div>
            </Link>

            <Divider />
            <div style={{ flex: '1' }}></div>
            <Divider />
            <Autocomplete
              value={currency}
              onChange={(event, newValue) => setCurrency(newValue)}
              disableClearable
              options={currencies}
              getOptionLabel={(option) => option.toUpperCase()}
              sx={{ fontFamily: "'Barlow', 'Roboto', sans-serif", padding: '12px 12px 12px 12px' }}
              renderInput={(params) => <TextField {...params} label="Currency" />}
            />
            {/* <p style={{textAlign: 'center', margin: '0'}}> */}

            {/* {(!isMobileLayout || openSettings) && <div style={{ display: 'flex', marginTop: '8px', marginBottom: '8px' }}> */}

            <Divider />
            {!isMobileLayout && (
              <p
                style={{
                  margin: "12px",
                  textAlign: "center",
                  backgroundColor: "#121212",
                  border: "rgba(255, 255, 255, 0.23) 1px solid",
                  color: "white",
                  padding: ".25rem .75rem",
                  marginBottom: "6px",
                  borderRadius: "0.25rem",
                }}
              >
                Updated<br />
                <UpdatedTimestamp updatedAt={(reverseDeals ? reverseRatios : ratios)["updated_at"]} />
              </p>
            )}
            <span style={{ fontSize: '10px', lineHeight: '1.2', padding: '12px', paddingTop: '0', textAlign: 'center' }}>(exc. Buff, Steam and UUPin, updated roughly every 6 hours with Rankings)</span>
            {/* </div>} */}
            {/* <Divider />
            {!isMobileLayout && items?.length > 0 &&
              <p style={{
                margin: '12px',
                textAlign: 'center',
                backgroundColor: "#121212",
                border: 'rgba(255, 255, 255, 0.23) 1px solid',
                color: 'white',
                padding: '.25rem .75rem',
                borderRadius: '0.25rem'
              }} >
                Updated<br />
                {(() => {
                  const updatedAtUTC = items[0]["UpdatedAt"] * 1000;
                  const now = new Date();
                  const timeDifference = now.getTime() - updatedAtUTC;
                  const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
                  const daysDifference = Math.floor(hoursDifference / 24);

                  let message;
                  if (daysDifference > 0) {
                    message = `${daysDifference} day${daysDifference !== 1 ? 's' : ''} ago`;
                  } else {
                    // if (hoursDifference > 0) {
                    message = `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
                    // } else {
                    //   message = "Less than an hour ago";
                    // }
                  }

                  return message;
                })()}
              </p>


            } */}

            <Divider />
            <div style={{ margin: '12px', }}>
              <p style={{

                textAlign: 'center',
                backgroundColor: "#121212",
                border: 'rgba(255, 255, 255, 0.23) 1px solid',
                color: 'white',
                padding: '.25rem .75rem',
                borderRadius: '0.25rem', textAlign: "center", margin: "auto", color: "white",
              }}>A Project By Brongo: <br />
                <div style={{ display: 'flex' }}>
                  <a href="https://twitter.com/BrongoCS" target="_blank" style={{ flex: 1 }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 1200 1227" fill="none">
                    <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" fill="white"></path>
                  </svg></a>
                  <a href="https://www.youtube.com/@BrongoCS" target="_blank" style={{ flex: 1 }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 576 512" fill="none"><path fill="white" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path></svg></a>
                  <a href="https://steamcommunity.com/tradeoffer/new/?partner=83942777&amp;token=Z_ZUTuBY" style={{ flex: 1 }}><img src="/assets/steamlogo.png" style={{ width: '20px', height: '20px' }} /></a>
                </div>
              </p>
            </div>
            <Divider />

            <div style={{ margin: '12px', display: 'flex', flexDirection: 'row' }} >
              <Link to="/faq" style={{ textDecoration: 'none', marginLeft: 'auto', marginRight: 'auto' }}>
                <p style={{

                  textAlign: 'center',
                  backgroundColor: "#121212",
                  border: 'rgba(255, 255, 255, 0.23) 1px solid',
                  color: 'white',
                  padding: '.25rem .75rem',
                  borderRadius: '0.25rem', textAlign: "center", margin: "auto", color: "white", width: '70px', cursor: "pointer",

                }}>FAQ
                </p>
              </Link>
              <Link to="/changelog" style={{ textDecoration: 'none', marginLeft: 'auto', marginRight: 'auto' }}>
                <p style={{

                  textAlign: 'center',
                  backgroundColor: "#121212",
                  border: 'rgba(255, 255, 255, 0.23) 1px solid',
                  color: 'white',
                  padding: '.25rem .75rem',
                  borderRadius: '0.25rem', textAlign: "center", margin: "auto", color: "white", width: '120px', cursor: "pointer",
                }}>Change Log
                </p>
              </Link>
            </div>

            <Divider />
            {/* <h5 style={{ textAlign: 'center', maxHeight: '180px', padding: '4px' }}>
              All Key Costs for third-party marketplaces are now automatically adjusted to reflect an easily achievable cost when converting to Steam Market balance. This improves ROI calculations.
            </h5> */}
            {/* <h5 style={{ textAlign: 'center', maxHeight: '180px', padding: '4px' }}>
              Buff.163 has removed all containers from their site. They will continue to be tracked for historical purposes. All container prices using Buff.163 pricing from the 19th of May onwards are now instead using 80% of the Steam Market cost, as most users can convert Buff.163 balance to Steam Market balance at that rate or better.
            </h5> */}
            {/* <Divider /> */}
            {/* <iframe width={drawerWidth} height="180" src="https://www.youtube.com/embed/mMCqwpVC2Zc?si=1DY9BEJKcLe66qy3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;" allowFullScreen ></iframe> */}
            <Divider />
          </Scrollbars>
        </Drawer>
        <Container sx={{ mb: 4, mt: "4px", maxWidth: '1400px !important' }}>

          <Container style={{ maxWidth: '1400px', display: 'flex', flexDirection: 'row', padding: '0', margin: '0', flexWrap: 'wrap', justifyContent: 'center', marginTop: '16px', alignItems: 'center' }}>
            {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: 'auto', marginBottom: '6px', marginTop: '6px', }}>
            <img src="/csroi.png" class="p-2" style={{ maxWidth: '280px', width: '100%', height: "auto", objectFit: 'contain', margin: 'auto' }} />
          </div> */}
            {/* <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{ mr: 2 }}
                >
                  <MenuIcon />
                </IconButton> */}


            {/* <div style={{ display: isMobileLayout ? 'flex' : 'none', flexDirection: 'row', justifyContent: 'center', margin: 'auto', marginBottom: '6px', marginTop: '14px', }}>
            <img src="/csroi.png" class="p-2" style={{ maxWidth: '280px', width: '100%', height: "auto", objectFit: 'contain', margin: 'auto' }} />

          </div> */}
            <Link to="/">
              <div style={{ display: isMobileLayout ? 'flex' : 'none', flexDirection: 'column', justifyContent: 'center', margin: 'auto', marginBottom: '6px', pointer: 'cursor' }}>
                <img src="/csroi.png" class="p-2" style={{ maxWidth: '280px', width: '100%', height: "auto", objectFit: 'contain', margin: 'auto' }} />

              </div>
            </Link>
            {isMobileLayout && <h5 style={{ margin: 'auto', textAlign: 'center', paddingBottom: '4px' }}>Want to pay less for your Steam Wallet balance? Find the best deals across the supported marketplaces!</h5>}
            {isMobileLayout &&
              <Select
                value={pricingSource}
                onChange={handlePricingSourceChange}
                variant="outlined"
                size="large"
                style={{ margin: 'auto', display: 'flex', alignItems: 'center', height: '57px' }}
                renderValue={(value) => {
                  return (
                    <div style={{ fontSize: '1rem', display: 'flex', alignItems: 'center' }}>
                      <img src={'/assets/' + (pricingSource == "csfloat" ? "csfloatlogo.png" : pricingSource == "skinport" ? "skinportlogo.png" : pricingSource == "buff163" ? "bufflogo.webp" : pricingSource == "youpin" ? "uupinlogo.png" : pricingSource.includes("tradeit") ? "tradeitlogo.svg" : "tradeitlogo.svg")} style={{ height: '32px', padding: '2px', paddingRight: '12px' }} />
                      <span style={{ lineHeight: '32px', verticalAlign: 'middle' }}>{value.replace("csfloat", "CSFloat").replace("skinport", "Skinport").replace("buff163", "Buff.163").replace("youpin", "UUPin").replace("tradeit", "Tradeit").replace("_withdraw", "").replace("tf2", "TF2")}</span>
                    </div>
                  )
                }}
              >
                <MenuItem value="csfloat" style={{ fontSize: '1rem', display: 'flex', alignItems: 'center' }}>
                  <img src='/assets/csfloatlogo.png' style={{ height: '32px', padding: '2px', paddingRight: '12px' }} />
                  <span style={{ lineHeight: '32px', verticalAlign: 'middle' }}>CSFloat</span>
                </MenuItem>
                <MenuItem value="skinport" style={{ fontSize: '1rem', display: 'flex', alignItems: 'center' }}>
                  <img src='/assets/skinportlogo.png' style={{ height: '32px', padding: '2px', paddingRight: '12px' }} />
                  <span style={{ lineHeight: '32px', verticalAlign: 'middle' }}>Skinport</span>
                </MenuItem>
                <MenuItem value="buff163" style={{ fontSize: '1rem', display: 'flex', alignItems: 'center' }}>
                  <img src='/assets/bufflogo.webp' style={{ height: '32px', padding: '2px', paddingRight: '12px' }} />
                  <span style={{ lineHeight: '32px', verticalAlign: 'middle' }}>Buff.163</span>
                </MenuItem>
                <MenuItem value="youpin" style={{ fontSize: '1rem', display: 'flex', alignItems: 'center' }}>
                  <img src='/assets/uupinlogo.png' style={{ height: '32px', padding: '2px', paddingRight: '12px' }} />
                  <span style={{ lineHeight: '32px', verticalAlign: 'middle' }}>UUPin</span>
                </MenuItem>
                <MenuItem value="tradeit" style={{ fontSize: '1rem', display: 'flex', alignItems: 'center' }}>
                  <img src='/assets/tradeitlogo.svg' style={{ height: '32px', padding: '2px', paddingRight: '12px' }} />
                  <span style={{ lineHeight: '32px', verticalAlign: 'middle' }}>Tradeit</span>
                </MenuItem>
                <MenuItem value="tf2" style={{ fontSize: '1rem', display: 'flex', alignItems: 'center' }}>
                  <img src='/assets/tf2logo.svg' style={{ height: '32px', padding: '2px', paddingRight: '12px' }} />
                  <span style={{ lineHeight: '32px', verticalAlign: 'middle' }}>{!reverseDeals ? "TF2 (INSTANT)" : "TF2"}</span>
                  <Tooltip title="All CS2 items have a 1 week trade and market hold. TF2 items do not have this same restriction, so you can near instantly have access to more steam wallet funds for your money, with a little effort." sx={{ margin: 'auto' }}>

                    <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '4px', }} fontSize='large' />
                  </Tooltip>
                </MenuItem>
              </Select>

            }
            <Autocomplete
              inputValue={inputValue}
              onInputChange={handleInputChange}
              freeSolo
              disablePortal
              id="combo-box-demo"
              options={options}
              filterOptions={(options, { inputValue }) => {
                const searchTerms = inputValue.toLowerCase().split(' ').filter(term => term);
                return options.filter((option) => {
                  const normalizedOption = option.toLowerCase();
                  return searchTerms.every(term => normalizedOption.includes(term));
                });
              }}
              sx={{ margin: 'auto', width: isMobileLayout ? '150px' : '250px', marginBottom: isMobileLayout ? 'auto' : 'auto', marginLeft: isMobileLayout ? 'auto' : '0' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search"
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                />
              )}
            />

            {!isMobileLayout &&
              <TextField
                style={{ marginRight: '4px', marginLeft: 'auto', width: '110px' }}
                id="outlined-basic"
                label="Min Price"
                variant="outlined"
                type="number"
                step="0.01"
                value={rangeValue[0]}
                onChange={(e) => handleRangeChange(e, [parseFloat(e.target.value), rangeValue[1]])}
                onBlur={(e) => handleRangeChange(e, [parseFloat(e.target.value), rangeValue[1]])}
                onKeyUp={(e) => handleRangeChange(e, [parseFloat(e.target.value), rangeValue[1]])}
              />
            }
            {isMobileLayout &&
              <Button
                variant="contained"
                style={{ backgroundColor: '#bb171a', color: 'white', fontSize: '0.9rem', paddingLeft: '12px', paddingRight: '12px', marginTop: '8px' }}
                onClick={() => {
                  localStorage.setItem('reverseDeals', (!reverseDeals).toString());
                  setReverseDeals(!reverseDeals);
                }}>
                {reverseDeals ? "Steam" : currency.toUpperCase()}
                <ArrowForwardRounded style={{ marginTop: 'auto', marginBottom: 'auto', width: '20px', height: '16px' }} />
                {reverseDeals ? currency.toUpperCase() : "Steam"}
              </Button>
            }
            <Slider
              getAriaLabel={() => "Price range"}
              value={rangeValue}
              onChange={handleRangeChange}
              valueLabelDisplay="on"
              valueLabelFormat={valueLabelFormat}
              getAriaValueText={valuetext}
              max={rangeMax}
              style={{ width: isMobileLayout ? "100%" : "30%", marginLeft: "48px", marginRight: "48px", marginBottom: "8px", marginTop: isMobileLayout ? "40px" : "auto" }}
            />

            {isMobileLayout &&
              <Autocomplete
                value={currency}
                onChange={(event, newValue) => setCurrency(newValue)}
                disableClearable
                options={currencies}
                getOptionLabel={(option) => option.toUpperCase()}
                sx={{ fontFamily: "'Barlow', 'Roboto', sans-serif", padding: '12px 12px 12px 12px' }}
                renderInput={(params) => <TextField {...params} label="Currency" />}
              />
            }
            {isMobileLayout &&
              <TextField
                style={{ marginRight: '4px', width: '90px' }}
                id="outlined-basic"
                label="Min Price"
                variant="outlined"
                type="number"
                step="0.01"
                value={rangeValue[0]}
                onChange={(e) => handleRangeChange(e, [parseFloat(e.target.value), rangeValue[1]])}
                onBlur={(e) => handleRangeChange(e, [parseFloat(e.target.value), rangeValue[1]])}
                onKeyUp={(e) => handleRangeChange(e, [parseFloat(e.target.value), rangeValue[1]])}
              />
            }
            <TextField
              style={{ marginRight: '4px', width: isMobileLayout ? '90px' : '110px' }}
              id="outlined-basic"
              label="Max Price"
              variant="outlined"
              type="number"
              step="0.01"
              value={rangeValue[1].toFixed(0)}
              onChange={(e) => handleRangeChange(e, [rangeValue[0], parseFloat(e.target.value)])}
              onBlur={(e) => handleRangeChange(e, [rangeValue[0], parseFloat(e.target.value)])}
              onKeyUp={(e) => handleRangeChange(e, [rangeValue[0], parseFloat(e.target.value)])}
            />


            {/* <Autocomplete
            value={currency}
            onChange={(event, newValue) => setCurrency(newValue)}
            disableClearable
            options={currencies}
            getOptionLabel={(option) => option.toUpperCase()}
            sx={{ fontFamily: "'Barlow', 'Roboto', sans-serif", padding: '0 12px 12px 12px', marginRight: 'auto', marginTop: 'auto', marginBottom: 'auto' }}
            renderInput={(params) => <TextField {...params} label="Currency" />}
          /> */}


          </Container>
          <Container sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '4px', maxWidth: '1400px !important' }}>
            {!isMobileLayout && <h3 style={{ margin: 'auto', textAlign: 'center' }}>Want to pay less for your Steam Wallet balance? Find the best deals across the supported marketplaces!</h3>}
            {!isMobileLayout && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', paddingBottom: '16px', paddingTop: '16px' }}>
              <Button
                variant="contained"
                style={{ backgroundColor: '#bb171a', color: 'white', fontSize: '1.1rem', paddingLeft: '12px', paddingRight: '12px' }}
                onClick={() => {
                  localStorage.setItem('reverseDeals', (!reverseDeals).toString());
                  setReverseDeals(!reverseDeals);

                }}>
                {reverseDeals ? "Steam" : currency.toUpperCase()}
                <ArrowForwardRounded style={{ marginTop: 'auto', marginBottom: 'auto', width: '20px', height: '16px' }} />
                {reverseDeals ? currency.toUpperCase() : "Steam"}
              </Button>
              <ToggleButtonGroup
                value={pricingSource}
                exclusive
                onChange={handlePricingSourceChange}
                variant="outlined"
                size="large"
                style={{ margin: 'auto', }}
              >
                <ToggleButton value="csfloat" style={{ fontSize: '0.9rem' }}>
                  <img src='/assets/csfloatlogo.png' style={{ height: '32px', padding: '2px', paddingRight: '12px' }} />
                  CSFloat
                </ToggleButton>
                <ToggleButton value="skinport" style={{ fontSize: '0.9rem' }}>
                  <img src='/assets/skinportlogo.png' style={{ height: '32px', padding: '2px', paddingRight: '12px' }} />
                  Skinport
                </ToggleButton>
                <ToggleButton value="buff163" style={{ fontSize: '0.9rem' }}>
                  <img src='/assets/bufflogo.webp' style={{ height: '32px', padding: '2px', paddingRight: '12px' }} />
                  Buff.163
                </ToggleButton>
                <ToggleButton value="youpin" style={{ fontSize: '0.9rem' }}>
                  <img src='/assets/uupinlogo.png' style={{ height: '32px', padding: '2px', paddingRight: '12px' }} />
                  UUPin
                </ToggleButton>
                <ToggleButton value="tradeit" style={{ fontSize: '0.9rem' }}>
                  <img src='/assets/tradeitlogo.svg' style={{ height: '32px', padding: '2px', paddingRight: '12px' }} />
                  Tradeit
                </ToggleButton>
                <ToggleButton value="tf2" style={{ fontSize: '0.9rem' }}>
                  <img src='/assets/tf2logo.svg' style={{ height: '32px', padding: '2px', paddingRight: '12px' }} />
                  <span>{!reverseDeals ? "TF2 (INSTANT)" : "TF2"}
                    {!reverseDeals && <Tooltip title="All CS2 items have a 1 week trade and market hold. TF2 items do not have this same restriction, so you can near instantly have access to more steam wallet funds for your money, with a little effort." sx={{ margin: 'auto' }}>

                      <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '4px', marginBottom: '-4px' }} fontSize='large' />
                    </Tooltip>
                    }
                  </span>
                </ToggleButton>

              </ToggleButtonGroup>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <FormGroup style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginBottom: '-4px' }}>
                  <IconButton onClick={fetchRatios} style={{ marginRight: '8px' }}>
                    <Refresh />
                  </IconButton>
                  <FormControlLabel
                    control={<Switch checked={autoRefresh} onChange={handleToggle} />}
                    label="Auto-Refresh"
                  />
                </FormGroup>
                {pricingSource == "csfloat" && !reverseDeals &&
                  <FormControlLabel
                    style={{ marginTop: '-4px' }}
                    control={<Switch checked={onlyLive} onChange={() => setOnlyLive(!onlyLive)} />}
                    label="Only Live Deals"
                  />
                }
              </div>
            </div>
            }
          </Container>
          <div style={{ maxWidth: '1400px !important', height: '75vh', margin: 'auto', display: 'flex', flexDirection: 'column' }}>
            {loading && (
              <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto' }} />
            )}
            <AutoSizer>
              {({ height, width }) => {
                return (


                  <table style={{ width: '100%', margin: 'auto', fontSize: '1.2rem' }}>
                    {!isMobileLayout &&
                      <thead style={{ display: 'flex', marginRight: '32px' }}>

                        <th style={{ flex: 4, padding: '4px' }}></th>
                        <th style={{ flex: 10, textAlign: 'center' }}>Item</th>
                        <th style={{ flex: 2, textAlign: 'center' }}>{reverseDeals ? "Steam" : pricingSource.replace("csfloat", "CSFloat").replace("skinport", "Skinport").replace("buff163", "Buff.163").replace("youpin", "UUPin").replace("tradeit", "Tradeit").replace("_withdraw", "").replace("tf2", "TF2")}</th>
                        <th style={{ flex: 1, textAlign: 'center' }}></th>
                        <th style={{ flex: 2, textAlign: 'center' }}>{reverseDeals ? pricingSource.replace("csfloat", "CSFloat").replace("skinport", "Skinport").replace("buff163", "Buff.163").replace("youpin", "UUPin").replace("tradeit", "Tradeit").replace("_withdraw", "").replace("tf2", "TF2") : "Steam"}</th>
                        <th style={{ flex: 2, textAlign: 'center' }}></th>
                        {pricingSource == "csfloat" && !reverseDeals && <th style={{ flex: 2, textAlign: 'center' }}>Last Seen</th>}
                        <th style={{ flex: 4, textAlign: 'center', lineHeight: '1' }}>{reverseDeals ? (currency.toUpperCase() + " per Steam Wallet Balance") : ("Steam Wallet Balance per " + currency.toUpperCase())}</th>

                      </thead>
                    }
                    <tbody>

                      <List
                        height={height}
                        itemCount={filteredItems.length}
                        itemSize={isMobileLayout ? 168 : 152}
                        width={width}
                        style={{ margin: 'auto' }}
                      >
                        {Row}
                      </List>
                    </tbody>
                  </table>

                );
              }}
            </AutoSizer>
          </div>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default function Deals() {
  return <DealsPage />;
}