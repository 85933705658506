import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { Accordion, AccordionSummary, Typography, AccordionDetails, Card, CardMedia, IconButton } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import CaseItems from "./CaseItems";
import LineGraph from "./LineGraph";
import Simulate from './SimulateAnimate';
import UnboxCost from './UnboxCost';
import OpenBox from './OpenBox.svg';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import Fullscreen from '@mui/icons-material/Fullscreen';
import FullscreenExit from '@mui/icons-material/FullscreenExit';

import getSymbolFromCurrency from 'currency-symbol-map';
import Tooltip from "@mui/material/Tooltip";
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import RarityValues from "./RarityValues";
import Scrollbars from "react-custom-scrollbars-2";
import FloatRanges from "./FloatRanges";
import TradeupPlanner from "./TradeupPlanner";
import FloatDropChances from "./FloatDropChances";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import './Rankings.css';
import Button from "@mui/material/Button";
import { skinportLink, buffLink, steamLink, csfloatLink, tradeitLink, skinsearchLink } from './allLinks.js';
import { CaseGridItem } from "./CaseGridItem.js";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Help, HighlightOff } from "@mui/icons-material";
import ReactGA from 'react-ga4';
import CookieConsent from "react-cookie-consent";
import { Helmet } from "react-helmet-async";
import { all } from "axios";
import { Link } from 'react-router-dom';

// ReactGA.pageview(window.location.pathname + window.location.search);

const redRarityNums = { 'Contraband': 223, 'Covert': 187, 'Classified': 158, 'Restricted': 104, 'Mil_Spec': 2, 'Industrial': 94, 'Consumer': 176 };
const greenRarityNums = { 'Contraband': 199, 'Covert': 23, 'Classified': 0, 'Restricted': 50, 'Mil_Spec': 79, 'Industrial': 152, 'Consumer': 195 };
const blueRarityNums = { 'Contraband': 0, 'Covert': 26, 'Classified': 197, 'Restricted': 234, 'Mil_Spec': 228, 'Industrial': 217, 'Consumer': 217 };
const realWearNames = { 'FactoryNew': 'Factory New', 'MinimalWear': 'Minimal Wear', 'FieldTested': 'Field-Tested', 'WellWorn': 'Well-Worn', 'BattleScarred': 'Battle-Scarred' };
const otherRarityNames = { 'Extraordinary': 'Covert', 'Exotic': 'Classified', 'Remarkable': 'Restricted', 'High_Grade': 'Mil_Spec', 'Superior': 'Classified', 'Master': 'Covert', 'Exceptional': 'Restricted', 'Distinguished': 'Mil_Spec' }


function DashboardContent() {
  const [currencies, setCurrencies] = useState([]);
  const { collectionId } = useParams();
  const [caseData, setCaseData] = useState(null);
  const [chaseItems, setChaseItems] = useState(null);
  const [redNum, setRedNum] = useState(null);
  const [greenNum, setGreenNum] = useState(null);
  const [blueNum, setBlueNum] = useState(null);
  const storedCurrency = localStorage.getItem('currency');
  const [currency, setCurrency] = useState(storedCurrency || 'USD');
  const [exchangeRate, setExchangeRate] = useState(null);
  const [currencySymbol, setCurrencySymbol] = useState('');
  const CACHE_DURATION = 24 * 60 * 60 * 1000; // Cache duration in milliseconds (1 day)
  var storedSort = localStorage.getItem('sortBy');
  storedSort = storedSort == "invest6m" ? "invest" : storedSort == "invest1m" ? "invest" : storedSort;
  const [sortBy, setSortBy] = useState(storedSort || "unbox");
  const [item, setItem] = useState(null);
  const [index, setIndex] = useState(null);
  const cachedPricingSource = localStorage.getItem('pricingSource');
  const [pricingSource, setPricingSource] = useState(cachedPricingSource || 'Steam');
  // const cachedAllCases = localStorage.getItem('allCases');
  // const [allCases, setAllCases] = useState(cachedAllCases || {});
  // const cachedAllLowCosts = localStorage.getItem('allLowCosts');
  // const [allLowCosts, setAllLowCosts] = useState(cachedAllLowCosts || {});
  const [settings, setSettings] = useState(false);
  const storedShowUnbox = (localStorage.getItem('showUnbox') ?? "true") == "true";
  const [showUnbox, setShowUnbox] = useState(storedShowUnbox ?? true);
  const storedShowProfit = (localStorage.getItem('showProfit') ?? "true") == "true";
  const [showProfit, setShowProfit] = useState(storedShowProfit ?? true);
  const storedShowInvest = (localStorage.getItem('showInvest') ?? "true") == "true";
  const [showInvest, setShowInvest] = useState(storedShowInvest ?? true);
  const storedShowListings = (localStorage.getItem('showListings') ?? "true") == "true";
  const [showListings, setShowListings] = useState(storedShowListings ?? true);
  let valuePercentage;
  const [fullscreenAccordionId, setFullscreenAccordionId] = useState(null);
  const storedInvestType = localStorage.getItem('investType');
  const [investType, setInvestType] = useState(storedInvestType || "1y");
  const [hideSkinsearch, setHideSkinsearch] = useState(false);

  const theme = createTheme({
    palette: {
      mode: 'dark',
    },
    typography: {
      fontFamily: 'Barlow',
    }
  });


  useEffect(() => {
    const fetchData = async () => {
      try {
        let url = '/pastData/allTrackedCases.json';

        if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
          url = 'http://localhost:8080/pastData/allTrackedCases.json';
        }
        // if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
        //   url = 'https://localhost:44396/jsondata';
        // }

        const response = await fetch(url);
        const data = await response.json();

        const matchingItemIndex = data.findIndex((item) => item.UrlName == collectionId);
        // Use matchingItemIndex as needed, for example:
        // console.log(matchingItemIndex);

        if (matchingItemIndex !== -1) {
          const matchingItem = data[matchingItemIndex];
          setItem(matchingItem);
          setIndex(matchingItemIndex);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  const [isMobileLayout, setIsMobileLayout] = useState(false);

  useEffect(() => {
    // Initial calculation
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);



  const handleResize = () => {
    // Calculate aspect ratio 
    const glanceInfoSize = window.innerWidth * 2 / 11

    // Define the threshold value for aspect ratio to differentiate between mobile and desktop
    const glanceInfoThreshold = 215; // Adjust this value based on your needs

    // Set the state based on the aspect ratio
    setIsMobileLayout(glanceInfoSize < glanceInfoThreshold);
  };

  function formatNumber(number, isCurrency) {
    // Use the user's locale
    const userLocale = navigator.language || 'en-US';

    // Create Intl.NumberFormat with user's locale
    if (isCurrency) {
      const formatter = new Intl.NumberFormat(userLocale, {
        //   minimumFractionDigits: 1,
        style: "currency",
        currency: currency,
        maximumFractionDigits: number > 1000 ? 0 : number > 100 ? 1 : 2,
      });
      return formatter.format(number);
    } else {
      const formatter = new Intl.NumberFormat(userLocale, {
        //   minimumFractionDigits: 1,
        maximumFractionDigits: number > 1000 ? 0 : number > 100 ? 1 : 2,
      });
      return formatter.format(number);
    }

    // Format the number

  }

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const cachedCurrencies = localStorage.getItem('currencies');
        const cachedTimestamp = localStorage.getItem('currenciesTimestamp');

        if (
          cachedCurrencies &&
          cachedTimestamp &&
          Date.now() - Number(cachedTimestamp) < CACHE_DURATION
        ) {
          setCurrencies(JSON.parse(cachedCurrencies));
        } else {
          let url = '/currencies.json'

          if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
            url = 'https://csroi.com/currencies.json';
          }

          const response = await fetch(url);

          const data = await response.json();
          const currencyList = Object.keys(data);
          setCurrencies(currencyList);

          localStorage.setItem('currencies', JSON.stringify(currencyList));
          localStorage.setItem('currenciesTimestamp', Date.now().toString());
        }
      } catch (error) {
        console.error('Failed to fetch currencies:', error);
      }
    };

    const updateExchangeRateToUSD = async () => {
      try {
        const cachedCurrency = localStorage.getItem('currency');
        const cachedUSDExchangeRate = localStorage.getItem('usdExchangeRate');
        const cachedTimestamp = localStorage.getItem('usdExchangeRateTimestamp');
        // const cachedAllCases = localStorage.getItem('allCases');
        // const cachedAllLowCosts = localStorage.getItem('allLowCosts');

        if (
          cachedCurrency &&
          currency == cachedCurrency &&
          cachedUSDExchangeRate &&
          cachedTimestamp &&
          Date.now() - Number(cachedTimestamp) < CACHE_DURATION
        ) {
          setCurrency(cachedCurrency);
          // setPricingSource('USD');
          setExchangeRate(cachedUSDExchangeRate);
          // setAllCases(JSON.parse(cachedAllCases));
          // setAllLowCosts(JSON.parse(cachedAllLowCosts));
        } else {
          const response = await fetch(
            `https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/${currency.toLowerCase()}.json`
          );
          const data = await response.json();
          const usdExchangeRate = data[currency.toLowerCase()]['usd'];
          // console.log(data);

          console.log(currency);
          console.log(usdExchangeRate);

          setCurrency(currency);
          // setPricingSource('USD');
          setExchangeRate(usdExchangeRate);

          localStorage.setItem('currency', currency);
          localStorage.setItem('usdExchangeRate', usdExchangeRate);
          localStorage.setItem('usdExchangeRateTimestamp', Date.now().toString());

          //Piggybacking data for all cases to update daily:
          // let caseurl = '/allCases.json'

          // if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
          //   caseurl = 'http://localhost:8080/allCases.json';
          // }

          // const responseAllCases = await fetch(caseurl);
          // const textAllCases = await responseAllCases.text();

          // // const processedText = `[${text.slice(0, -3)}]`;
          // const processedDataAllCases = JSON.parse(textAllCases);

          //Piggybacking data for all cases to update daily:
          // let costUrl = '/allLowCosts.json'

          // if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
          //   costUrl = 'http://localhost:8080/allLowCosts.json';
          // }

          // const responseLowCosts = await fetch(costUrl);
          // const textLowCosts = await responseLowCosts.text();

          // const processedText = `[${text.slice(0, -3)}]`;
          // const processedDataLowCosts = JSON.parse(textLowCosts);

          // setAllCases(processedDataAllCases);
          // setAllLowCosts(processedDataLowCosts);
          // console.log(textAllCases);
          // console.log(textLowCosts);
          // localStorage.setItem('allCases', textAllCases);
          // localStorage.setItem('allLowCosts', textLowCosts);
        }
      } catch (error) {
        console.error('Failed to update exchange rate to USD:', error);
      }
    };

    fetchCurrencies();
    updateExchangeRateToUSD();
  }, [currency]);


  // useEffect(() => {
  // const fetchCurrencies = async () => {
  //   try {
  //     const cachedCurrencies = localStorage.getItem('currencies');
  //     const cachedTimestamp = localStorage.getItem('currenciesTimestamp');

  //     if (
  //       cachedCurrencies &&
  //       cachedTimestamp &&
  //       Date.now() - Number(cachedTimestamp) < CACHE_DURATION
  //     ) {
  //       setCurrencies(JSON.parse(cachedCurrencies));
  //     } else {
  //       const response = await fetch(
  //         'https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies.json'
  //       );
  //       const data = await response.json();
  //       const currencyList = Object.keys(data);
  //       setCurrencies(currencyList);

  //       localStorage.setItem('currencies', JSON.stringify(currencyList));
  //       localStorage.setItem('currenciesTimestamp', Date.now().toString());
  //     }
  //   } catch (error) {
  //     console.error('Failed to fetch currencies:', error);
  //   }
  // };



  //   const updateExchangeRateToUSD = async () => {
  //     try {
  //       const cachedCurrency = localStorage.getItem('currency');
  //       const cachedUSDExchangeRate = localStorage.getItem('usdExchangeRate');
  //       const cachedTimestamp = localStorage.getItem('usdExchangeRateTimestamp');
  //       const cachedAllCases = localStorage.getItem('allCases');
  //       const cachedAllLowCosts = localStorage.getItem('allLowCosts');

  //       if (
  //         cachedCurrency &&
  //         cachedUSDExchangeRate &&
  //         cachedTimestamp &&
  //         Date.now() - Number(cachedTimestamp) < CACHE_DURATION
  //       ) {
  //         setCurrency(cachedCurrency);
  //         // setPricingSource('USD');
  //         setExchangeRate(cachedUSDExchangeRate);
  //         setAllCases(JSON.parse(cachedAllCases));
  //         setAllLowCosts(JSON.parse(cachedAllLowCosts));
  //       } else {
  //         const response = await fetch(
  //           `https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/${currency.toLowerCase()}/usd.json`
  //         );
  //         const data = await response.json();
  //         const usdExchangeRate = data['usd'];



  //         setCurrency(currency);
  //         // setPricingSource('USD');
  //         setExchangeRate(usdExchangeRate);

  //         localStorage.setItem('currency', currency);
  //         localStorage.setItem('usdExchangeRate', usdExchangeRate);
  //         localStorage.setItem('usdExchangeRateTimestamp', Date.now().toString());

  //         //Piggybacking data for all cases to update daily:
  //         const responseAllCases = await fetch(`/allCases.json`);
  //         const textAllCases = await responseAllCases.text();
  //         // const processedText = `[${text.slice(0, -3)}]`;
  //         const processedDataAllCases = JSON.parse(textAllCases);

  //         const responseLowCosts = await fetch(`/allLowCosts.json`);
  //         const textLowCosts = await responseLowCosts.text();
  //         // const processedText = `[${text.slice(0, -3)}]`;
  //         const processedDataLowCosts = JSON.parse(textLowCosts);

  //         setAllCases(processedDataAllCases);
  //         setAllLowCosts(processedDataLowCosts);

  //         localStorage.setItem('allCases', textAllCases);
  //         localStorage.setItem('allLowCosts', textLowCosts);
  //       }
  //     } catch (error) {
  //       console.error('Failed to update exchange rate to USD:', error);
  //     }
  //   };

  //   // fetchCurrencies();
  //   updateExchangeRateToUSD();
  // }, [currency]);

  const handlePricingSourceChange = (event, newPricingSource) => {
    // console.log(event);
    // console.log(newPricingSource);
    localStorage.setItem('pricingSource', newPricingSource.props.value);
    setPricingSource(newPricingSource.props.value);
  };

  useEffect(() => {
    if (!window.location.href.includes('localhost')) {
      ReactGA.send({ hitType: "pageview", page: "/" + collectionId, title: collectionId });

    }
  }, []);

  // let redNum;
  // let greenNum;
  // let blueNum;

  useEffect(() => {
    // Fetch data from the API using the collectionId
    const fetchData = async () => {
      if (item != null) {
        try {
          let url = `/case/${pricingSource.toLowerCase() + (collectionId.includes('souvenir') ? 'Souvenir' : '')}/${item.CollectionId}/data.json`;

          if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
            url = `http://localhost:8080/case/${pricingSource.toLowerCase() + (collectionId.includes('souvenir') ? 'Souvenir' : '')}/${item.CollectionId}/data.json`;
          }

          const response = await fetch(url);

          const data = await response.json();
          // console.log(data.ROI);
          // if (data.ROI > 1.3) {
          //   setRedNum(223);
          //   setGreenNum(199);
          //   setBlueNum(0);
          // } else if (data.ROI > 1) {
          //   setRedNum(187 + Math.abs(data.ROI - 1) / 0.3 * 36);
          //   setGreenNum(23 + Math.abs(data.ROI - 1) / 0.3 * 176);
          //   setBlueNum(26 - Math.abs(data.ROI - 1) / 0.3 * 26);
          // } else if (data.ROI > 0.7) {
          //   setRedNum(158 + Math.abs(data.ROI - 0.7) / 0.3 * 29);
          //   setGreenNum(0 + Math.abs(data.ROI - 0.7) / 0.3 * 23);
          //   setBlueNum(197 - Math.abs(data.ROI - 0.7) / 0.3 * 171);
          // } else if (data.ROI > 0.4) {
          //   setRedNum(104 + Math.abs(data.ROI - 0.4) / 0.3 * 54);
          //   setGreenNum(50 - Math.abs(data.ROI - 0.4) / 0.3 * 50);
          //   setBlueNum(234 - Math.abs(data.ROI - 0.4) / 0.3 * 37);
          // } else {
          //   setRedNum(2 + Math.abs(data.ROI) / 0.4 * 102);
          //   setGreenNum(79 - Math.abs(data.ROI) / 0.4 * 29);
          //   setBlueNum(228 + Math.abs(data.ROI) / 0.4 * 6);
          // }
          let compareValue = (sortBy == "unbox" ? data.ROI : (pricingSource == "Steam" ? sortBy == "invest1M" ? item?.Steam1MiROI : sortBy == "invest6M" ? item?.Steam6MiROI : item?.SteamiROI ?? 0 :
            pricingSource == "CSFloat" ? sortBy == "invest1M" ? item?.CSFloat1MiROI : sortBy == "invest6M" ? item?.CSFloat6MiROI : item?.CSFloatiROI ?? 0 :
              pricingSource == "Tradeit" ? sortBy == "invest1M" ? item?.Tradeit1MiROI : sortBy == "invest6M" ? item?.Tradeit6MiROI : item?.TradeitiROI ?? 0 :
                pricingSource == "Buff" ? sortBy == "invest1M" ? item?.Buff1MiROI : sortBy == "invest6M" ? item?.Buff6MiROI : item?.BuffiROI ?? 0 :
                  sortBy == "invest1M" ? item?.Skinport1MiROI : sortBy == "invest6M" ? item?.Skinport6MiROI : item?.SkinportiROI ?? 0))
          if (item.CollectionType == "Collection" && sortBy == "unbox") {
            compareValue *= 0.001;
          }
          if (sortBy == "invest1M") {
            compareValue *= 12;
          }
          if (sortBy == "invest6M") {
            compareValue *= 2;
          }
          if (sortBy == "unbox") {
            if (compareValue > 1) {
              setRedNum(223);
              setGreenNum(199);
              setBlueNum(0);
            } else if (compareValue > 0.8) {
              setRedNum(187 + Math.abs(compareValue - 0.8) / 0.2 * 36);
              setGreenNum(23 + Math.abs(compareValue - 0.8) / 0.2 * 176);
              setBlueNum(26 - Math.abs(compareValue - 0.8) / 0.2 * 26);
            } else if (compareValue > 0.6) {
              setRedNum(158 + Math.abs(compareValue - 0.6) / 0.2 * 29);
              setGreenNum(0 + Math.abs(compareValue - 0.6) / 0.2 * 23);
              setBlueNum(197 - Math.abs(compareValue - 0.6) / 0.2 * 171);
            } else if (compareValue > 0.4) {
              setRedNum(104 + Math.abs(compareValue - 0.4) / 0.2 * 54);
              setGreenNum(50 - Math.abs(compareValue - 0.4) / 0.2 * 50);
              setBlueNum(234 - Math.abs(compareValue - 0.4) / 0.2 * 37);
            } else {
              setRedNum(2 + Math.abs(compareValue) / 0.4 * 102);
              setGreenNum(79 - Math.abs(compareValue) / 0.4 * 29);
              setBlueNum(228 + Math.abs(compareValue) / 0.4 * 6);
            }
          } else {
            if (compareValue > 400) {
              setRedNum(223);
              setGreenNum(199);
              setBlueNum(0);
            } else if (compareValue > 200) {
              setRedNum(187 + (compareValue - 200) / 200 * 36);
              setGreenNum(23 + (compareValue - 200) / 200 * 176);
              setBlueNum(26 - (compareValue - 200) / 200 * 26);
            } else if (compareValue > 100) {
              setRedNum(158 + (compareValue - 100) / 100 * 29);
              setGreenNum(0 + (compareValue - 100) / 100 * 23);
              setBlueNum(197 - (compareValue - 100) / 100 * 171);
            } else if (compareValue > 50) {
              setRedNum(104 + (compareValue - 50) / 50 * 54);
              setGreenNum(50 - (compareValue - 50) / 50 * 50);
              setBlueNum(234 - (compareValue - 50) / 50 * 37);
            } else {
              setRedNum(2);
              setGreenNum(79);
              setBlueNum(228);
            }
          }

          valuePercentage = (data.ROI / 2) * 100;
          setCaseData(data);
          var tempChaseItems = [];
          // Find chase items:
          // loop through "RarityROIPercents" that are sorted by the value in the rarity
          var sortedRarityROIPercents = Object.entries(data.RarityROIPercents).sort((a, b) => {
            return b[1] - a[1];
          });
          for (var i = 0; i < sortedRarityROIPercents.length; i++) {
            const rarity = sortedRarityROIPercents[i][0];
            const roi = sortedRarityROIPercents[i][1];
            // Loop through items in rarity by looking at the "ItemCosts" for the rarity

            for (const [itemName, itemData] of (rarity == "Contraband" ? Object.entries(data.KnifeCosts) : Object.entries(data.ItemCosts[rarity]))) {
              const averageRarityValue = data["RarityValue"][rarity];
              if (itemData["totalItemValue"] >= averageRarityValue * 0.5) {
                var totalRarityValue = averageRarityValue * (rarity == "Contraband" ? ((data?.["TotalKnifeChance"] ?? Object.keys(data.KnifeCosts).length) / (data?.["Items"]?.[itemName]?.["ModifiedDropChance"] ?? 1)) : Object.keys(data.ItemCosts[rarity]).length);
                var itemRarityValue = (itemData["totalItemValue"] / totalRarityValue) * roi * (rarity == "Contraband" ? 1.5 : 1);
                if (itemRarityValue > 0.02) {
                  tempChaseItems.push({ name: itemName.split(" | ")[0] + " | " + itemName.split(" | ")[1], value: itemRarityValue });
                }
              }
            }
          }

          // Sort chase items by value
          tempChaseItems.sort((a, b) => b.value - a.value);
          setChaseItems(tempChaseItems);

          // console.log(allTotalItemValue);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }

    };

    fetchData();
  }, [item, pricingSource]);


  useEffect(() => {
    setCurrencySymbol(getSymbolFromCurrency(currency));
  }, [currency]);

  return item != null && (
    <ThemeProvider theme={theme}>
      <div style={{ display: 'flex', paddingTop: '4px', minHeight: '100vh', flexDirection: !isMobileLayout ? "row" : 'column', }}>
        <CookieConsent
          location="bottom"
          buttonText="I understand"
          cookieName="garequest"
          style={{ background: "#2B373B", zIndex: 99999 }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          This website uses cookies for anonymous Google Analytics. By continuing to use this website, you consent to the use of cookies.
        </CookieConsent>
        <Helmet>
          {/* Standard Meta Tags */}
          <title>{item?.Name} - CSROI.com</title>
          <meta
            name="description"
            content={`Find and simulate unboxing the items in the ${item?.Name}. Discover expected value/unboxing roi, wear drop chances, historical data, and more! Featuring great items like ${chaseItems?.slice(0, 10).map(item => item.name).join(', ')}.`}
          />
          <meta name="keywords" content="cs2, csgo, roi, case, capsule, souvenir, sticker, armory, stars, invest, pin, graffiti, autographs, listings, profit, unbox, simulator, history, music kit, collection, operation" />

          {/* Open Graph (Facebook) */}
          <meta property="og:title" content={`${item?.Name} - CSROI.com`} />
          <meta property="og:description" content={`Unboxing simulation and investing analysis for ${item?.Name}. View ROI, best chase items, and market trends!`} />
          <meta property="og:image" content={item?.image || "https://csroi.com/logo512.png"} />
          <meta property="og:url" content={`https://csroi.com/cases/${item?.id}`} />

          {/* Twitter Cards */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={`${item?.Name} - CSROI.com`} />
          <meta name="twitter:description" content={`Analyze ${item?.Name} case unboxing ROI, profit chances, and historical prices.`} />
          <meta name="twitter:image" content={item?.Image || "https://csroi.com/logo512.png"} />

          {/* Canonical URL */}
          <link rel="canonical" href={`https://csroi.com/#/item/${item?.UrlName}`} />

          {/* Schema Markup (JSON-LD) */}
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Product",
              "name": item?.Name,
              "description": `Find and simulate unboxing the items in the ${item?.Name}. Discover expected value, wear drop chances, historical data, and more! Featuring great items like ${chaseItems?.slice(0, 10).map(item => item.name).join(', ')}.`,
              "url": `https://csroi.com/#/item/${item?.UrlName}`,
              "image": item?.Image || "https://csroi.com/logo512.png",
              "brand": {
                "@type": "Brand",
                "name": "CSROI"
              },
              "offers": {
                "@type": "Offer",
                "priceCurrency": "USD",
                "price": item?.CollectionPriceSteam || "0.00",
                "availability": "https://schema.org/InStock",
                "url": `https://csroi.com/#/item/${item?.UrlName}`,
                "hasMerchantReturnPolicy": {
                  "@type": "MerchantReturnPolicy",
                  "applicableCountry": "US",
                  "returnPolicyCategory": "https://schema.org/NoReturns"
                },
                "shippingDetails": {
                  "@type": "OfferShippingDetails",
                  "shippingRate": {
                    "@type": "MonetaryAmount",
                    "currency": "USD",
                    "value": "0.00"
                  },
                  "shippingDestination": {
                    "@type": "DefinedRegion",
                    "addressCountry": "US"
                  },
                  "deliveryTime": {
                    "@type": "ShippingDeliveryTime",
                    "handlingTime": {
                      "@type": "QuantitativeValue",
                      "minValue": 0,
                      "maxValue": 1,
                      "unitCode": "DAY"
                    },
                    "transitTime": {
                      "@type": "QuantitativeValue",
                      "minValue": 0,
                      "maxValue": 2,
                      "unitCode": "DAY"
                    }
                  }
                }

              },
              "additionalProperty": [
                {
                  "@type": "PropertyValue",
                  "name": "Unboxing ROI",
                  "value": formatNumber(item?.SteamROI * 100, false)
                },
                {
                  "@type": "PropertyValue",
                  "name": "Profit Chance",
                  "value": formatNumber(item?.ProfitSteam * 100, false)
                },
                {
                  "@type": "PropertyValue",
                  "name": "Investing ROI",
                  "value": formatNumber(item?.SteamiROI, false)
                }
              ]
            })}
          </script>
        </Helmet>


        {caseData ?

          !isMobileLayout ? <div style={{ flex: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '-4px' }}>
            <Link to="/"><img src="/csroi.png" className="p-2" style={{ width: '100%', height: "auto", objectFit: 'contain', padding: '32px', paddingBottom: '4px', paddingTop: '4px' }} /></Link>
            <div style={{ paddingLeft: '8px', paddingRight: '8px', width: '100%' }}>{
              // CaseGridItem(item, null, pricingSource, currency, exchangeRate, sortBy, isMobileLayout, showUnbox, showProfit, showInvest)
              <CaseGridItem
                key={item.id}
                item={item}
                // index={index}
                pricingSource={pricingSource}
                currency={currency}
                exchangeRate={exchangeRate}
                sortBy={sortBy}
                isMobileLayout={isMobileLayout}
                showUnbox={showUnbox}
                showProfit={showProfit}
                showInvest={showInvest}
                numListings={item.NumListings}
                showListings={showListings}
                investType={investType}
                isCaseEstimate={caseData.IsCaseEstimate ?? false}
              />
            }</div>
            {/* <Card sx={{
          backgroundColor: 'transparent', backgroundImage: 'none',
          position: 'relative', transition: 'box-shadow 0.3s',
          '&:hover': {
            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.6)'
          }, overflow: 'inherit', margin: '8px', marginTop: 0,  marginBottom: '4px', width: '95%', padding: '6px', paddingTop: 0, minWidth: '275px', position: 'relative', minHeight: '10vw',
        }} >
          {item?.DropType == "Discontinued" && <div className="ribbon" style={{top: '19px'}}>DISCONTINUED</div>}
          {item?.DropType == "Rare Drop" && <div className="orangeRibbon" style={{top: '19px'}}>RARE DROP</div>}
          {item?.DropType == "Active Drop" && <div className="greenRibbon" style={{top: '19px'}}>ACTIVE DROP</div>}
          {item?.DropType == "In Game Store" && <div className="greenStoreRibbon" style={{top: '21px'}}>IN GAME STORE</div>}

          <div
            className="grid-item"
            style={{ background: `linear-gradient(145deg, RGB(${redNum}, ${greenNum}, ${blueNum}) 0%, black 100%)`, borderRadius: '4px' }}
          >
            <CardMedia
              sx={{ height: 160, backgroundSize: "contain", }}
              image={removeSlashSection(caseData?.SouvenirPackImg ?? caseData?.ImageUrl)}
            />

          </div>

          <div style={{ alignItems: 'center', display: 'flex', padding: '8px', }}>
            <h3 style={{ lineHeight: 1.1, margin: '0', width: '100%', minHeight: '3.5em', textAlign: "center", justifyContent: 'center', paddingLeft: '8px', paddingRight: '8px', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', justifySelf: 'center' }}>{caseData.Case.split(' | ').map((text, index) => (
              <React.Fragment key={index}>
                {caseData.SouvenirPackName ?? text}
                <br />
              </React.Fragment>
            ))}
            </h3>

          </div>
          {/* <h6 style={{ display: "flex", alignItems: "center", lineHeight: 1, margin: 0, paddingTop: '16px'}}>
                          <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexWrap: 'wrap' }}>
                            UNBOX
                          </div>


                          <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexWrap: 'wrap' }}>
                            INVEST
                          </div>
                        </h6> */}
            {/* <h4 style={{ display: "flex", alignItems: "center", lineHeight: 1, padding: '4px', paddingBottom: '8px', margin: 0 }}>
                          <Tooltip title="Unboxing ROI">
                          <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column' }}>
                            <img src={OpenBox} style={{width: '32px', height: '32px'}} />
                            <span>{(caseData.ROI * 100).toFixed(1)}%</span>
                          </div>
                          </Tooltip>
                          <h2 style={{ lineHeight: 0.5, textAlign: "center", justifyContent: 'center',  padding: '8px', margin: '0.8rem' }}>{currencySymbol}{(((caseData.CaseCost ?? 0) - caseData.KeyCost) / exchangeRate).toFixed(2)}</h2>
                        

                          <Tooltip title="Investing ROI">
                          <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column' }}>
                            <TrendingUpIcon className="iconSVG" style={{width: '32px', height: '32px'}} />
                            <span>{((caseData.ROI - 0.5) * 100).toFixed(1)}%</span>
                          </div>
                          </Tooltip>
                        </h4> 
          <h4 style={{ display: "flex", alignItems: "center", lineHeight: 1, padding: '4px', paddingBottom: '16px', paddingTop: '0', margin: 0 }}>
            <Tooltip title="Return on Investment for opening this container today">
              <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                Unboxing ROI
                {sortBy == "unbox" ? <img src={OpenBox} className="iconSVG" style={{ fill: 'green' }} /> : <img src={OpenBox} className="iconSVG" />}
                {item?.CollectionType !== "Collection" ? <span>{formatNumber(caseData.ROI * 100)}%</span> : <span>${formatNumber((caseData.ROI) / 100 / exchangeRate)}</span>}

                <span>{formatNumber(caseData.ROI * 100)}%</span> 

              </div>
            </Tooltip>
            {item?.CollectionType !== "Collection" && item != null && <div>
              <h2 style={{ lineHeight: 0.3, textAlign: "center", justifyContent: 'center', padding: '8px', margin: '0.5rem', marginTop: '-4px' }}>{currencySymbol}{formatNumber(((caseData.CaseCost ?? 0) - caseData.KeyCost) / exchangeRate)}</h2>
              {caseData.KeyCost == 0 ? item.CollectionType === "Past Operation" ? <span style={{ fontSize: '12px', lineHeight: 0.2, textAlign: 'center', display: 'block' }}>{((pricingSource == "Steam" ? item.CollectionPriceSteam : pricingSource == "Buff" ? item.CollectionPriceBuff : item.CollectionPriceSkinport) ?? 0) / 0.45} Stars</span> :  <span></span> : <span style={{ fontSize: '12px', lineHeight: 0.2, textAlign: 'center', display: 'block', marginBottom: (caseData.KeyCost != 0  ? '16px': '0px') }}>+{currencySymbol}{formatNumber((caseData.KeyCost ?? 0) / exchangeRate)} Key</span>}
              <span style={{ fontSize: '12px', lineHeight: 0, textAlign: 'center', display: 'block', marginTop: (item.KeyCost == 0 ? item.CollectionType === "Past Operation" ? '24px' : '4px' : '24px') }}>{formatNumber(caseData.Profit * 100)}% Profit</span>
            </div>}
            
            <Tooltip title="Return on Investment if you had purchased this container a year ago">
                                    <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                                      Investing ROI
                                      {sortBy == "unbox" ? (pricingSource == "Steam" ? sortBy == "invest1M" ? item?.Steam1MiROI : sortBy == "invest6M" ? item?.Steam6MiROI : item?.SteamiROI ?? 0 : 
                                      pricingSource == "Buff" ? sortBy == "invest1M" ? item?.Buff1MiROI : sortBy == "invest6M" ? item?.Buff6MiROI : item?.BuffiROI ?? 0 : 
                                      sortBy == "invest1M" ? item?.Skinport1MiROI : sortBy == "invest6M" ? item?.Skinport6MiROI : item?.SkinportiROI ?? 0) > 0 ? <TrendingUpIcon className="iconSVG" fontSize='large' /> : <TrendingDownIcon className="iconSVG" fontSize='large' /> : (pricingSource == "Steam" ? sortBy == "invest1M" ? item?.Steam1MiROI : sortBy == "invest6M" ? item?.Steam6MiROI : item?.SteamiROI ?? 0 : 
                                      pricingSource == "Buff" ? sortBy == "invest1M" ? item?.Buff1MiROI : sortBy == "invest6M" ? item?.Buff6MiROI : item?.BuffiROI ?? 0 : 
                                      sortBy == "invest1M" ? item?.Skinport1MiROI : sortBy == "invest6M" ? item?.Skinport6MiROI : item?.SkinportiROI ?? 0) > 0 ? <TrendingUpIcon className="iconSVG" fontSize='large' style={{ fill: 'green' }} /> : <TrendingDownIcon className="iconSVG" fontSize='large' style={{ fill: 'rgb(187, 23, 26)' }} />}

                                      {sortBy == "unbox" ? 
                                      <span  style={{fontSize: '1.15rem'}}>{formatNumber(((pricingSource == "Steam" ? item?.SteamiROI ?? 0 : pricingSource == "Buff" ? item?.BuffiROI ?? 0 : item?.SkinportiROI ?? 0)))}%</span> 
                                      : (pricingSource == "Steam" ? sortBy == "invest1M" ? item?.Steam1MiROI : sortBy == "invest6M" ? item?.Steam6MiROI : item?.SteamiROI ?? 0 : 
                                      pricingSource == "Buff" ? sortBy == "invest1M" ? item?.Buff1MiROI : sortBy == "invest6M" ? item?.Buff6MiROI : item?.BuffiROI ?? 0 : 
                                      sortBy == "invest1M" ? item?.Skinport1MiROI : sortBy == "invest6M" ? item?.Skinport6MiROI : item?.SkinportiROI ?? 0) > 0 ? 
                                      <span  style={{fontSize: '1.15rem', color: 'green' }}>{formatNumber((pricingSource == "Steam" ? sortBy == "invest1M" ? item?.Steam1MiROI : sortBy == "invest6M" ? item?.Steam6MiROI : item?.SteamiROI ?? 0 : 
                                      pricingSource == "Buff" ? sortBy == "invest1M" ? item?.Buff1MiROI : sortBy == "invest6M" ? item?.Buff6MiROI : item?.BuffiROI ?? 0 : 
                                      sortBy == "invest1M" ? item?.Skinport1MiROI : sortBy == "invest6M" ? item?.Skinport6MiROI : item?.SkinportiROI ?? 0))}%</span> : 
                                      <span  style={{fontSize: '1.15rem', color: 'rgb(187, 23, 26)' }}>{formatNumber((pricingSource == "Steam" ? sortBy == "invest1M" ? item?.Steam1MiROI : sortBy == "invest6M" ? item?.Steam6MiROI : item?.SteamiROI ?? 0 : 
                                      pricingSource == "Buff" ? sortBy == "invest1M" ? item?.Buff1MiROI : sortBy == "invest6M" ? item?.Buff6MiROI : item?.BuffiROI ?? 0 : 
                                      sortBy == "invest1M" ? item?.Skinport1MiROI : sortBy == "invest6M" ? item?.Skinport6MiROI : item?.SkinportiROI ?? 0))}%
                                      </span>}

                                    </div>
                                  </Tooltip>
          </h4>
        </Card> */}
            <a
              href={caseData["IsCSFloat"] ? csfloatLink(caseData["SouvenirPackName"] ?? caseData["Case"]) :
                caseData["ISTradeit"] ? tradeitLink(caseData["SouvenirPackName"] ?? caseData["Case"]) :
                  caseData["IsBuff"] ? buffLink(caseData["SouvenirPackName"] ?? caseData["Case"]) :
                    caseData["IsSkinport"] ? skinportLink(caseData["SouvenirPackName"] ?? caseData["Case"], caseData, true) :
                      steamLink(caseData["SouvenirPackName"] ?? caseData["Case"])}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                backgroundColor: '#bb171a',
                color: 'white',
                margin: '6px 8px',
                padding: '10px 16px',
                textDecoration: 'none',
                display: item?.CollectionType !== "Collection" && item?.CollectionType !== "Past Operation" ? 'inline-block' : 'none',
                borderRadius: '4px',
                fontWeight: 'bold',
                fontSize: '14px'
              }}
            >
              OPEN IN {pricingSource.toUpperCase()}
            </a>

            {/* <Button variant="contained" onClick={() => window.open(caseData["IsCSFloat"] ? csfloatLink(caseData["SouvenirPackName"] ?? caseData["Case"]) : caseData["ISTradeit"] ? tradeitLink(caseData["SouvenirPackName"] ?? caseData["Case"]) : caseData["IsBuff"] ? buffLink(caseData["SouvenirPackName"] ?? caseData["Case"]) : caseData["IsSkinport"] ? skinportLink(caseData["SouvenirPackName"] ?? caseData["Case"], caseData, true) : steamLink(caseData["SouvenirPackName"] ?? caseData["Case"]), '_blank')} style={{ backgroundColor: '#bb171a', color: 'white', margin: '6px 8px', display: item?.CollectionType !== "Collection" && item?.CollectionType !== "Past Operation" ? 'initial' : 'none' }}>OPEN IN {pricingSource.toUpperCase()}</Button> */}
            {Object.entries(caseData["PercentReceive"]).map(([rarity, percent]) => (
              <Card style={{
                margin: '6px',
                marginLeft: '8px',
                marginRight: '8px',
                width: '95%',
                background: `linear-gradient(145deg, RGB(${redRarityNums[Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity]}, ${greenRarityNums[Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity]}, ${blueRarityNums[Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity]}) 0%, black 100%)`, paddingLeft: 0
              }}>
                <h4 style={{ textAlign: 'center', margin: '2px', height: 18, marginTop: '-2px' }}>{rarity.replace('Contraband', 'Gloves / Knives').replace('_', '-')}</h4>
                <p style={{ textAlign: 'center', margin: '2px', }}>{formatNumber(caseData['RarityValue'][rarity] / exchangeRate, true)} every {formatNumber(1 / percent)}</p>
              </Card>))}

            <div style={{ display: 'flex', marginTop: '4px', width: '95%', justifyContent: 'center', alignContent: 'center', flexWrap: 'wrap', gap: '8px' }}>

              <FormControl sx={{ marginRight: '4px', }}>
                <InputLabel id="pricing-source-label">Pricing Source</InputLabel>
                <Select
                  //  renderInput={(params) => <TextField {...params} label="Pricing Source" />}

                  labelId="pricing-source-label"
                  id="pricing-source"
                  value={pricingSource}
                  label="Pricing Source"
                  sx={{ width: '180px' }}
                  onChange={handlePricingSourceChange}
                >
                  <MenuItem value="Steam">Steam</MenuItem>
                  <MenuItem value="Buff">
                    <div>
                      Buff.163 / UUPin<Tooltip title="Buff.163 prices are preferred, but UUPin prices are used when Buff.163 prices are unavailable (e.g., containers) or when there is a significant price difference." sx={{ margin: 'auto' }}>

                        <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '4px', marginBottom: '-4px' }} fontSize='large' />
                      </Tooltip>
                    </div>
                  </MenuItem>
                  <MenuItem value="CSFloat">CSFloat</MenuItem>
                  <MenuItem value="Skinport">Skinport</MenuItem>
                  <MenuItem value="Tradeit">
                    <div>
                      Tradeit.gg
                      <Tooltip title="Tradeit.gg pricing reflects the cost to withdraw containers and trade back unboxed items. Estimated prices, marked in grey, are used when specific item prices are unavailable." sx={{ margin: 'auto' }}>

                        <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '4px', marginBottom: '-4px' }} fontSize='large' />
                      </Tooltip></div>
                  </MenuItem>
                </Select>
              </FormControl>
              <Autocomplete
                disableClearable
                value={currency}
                onChange={(event, newValue) => setCurrency(newValue)}
                options={currencies}
                getOptionLabel={(option) => option.toUpperCase()}
                sx={{ fontFamily: "'Barlow', 'Roboto', sans-serif", width: '140px' }}
                renderInput={(params) => <TextField {...params} label="Currency" />}
              />
              <FormControl style={{ width: '120px', marginTop: '12px', margin: 'auto' }}>
                <InputLabel id="demo-simple-select-label">Invest Type</InputLabel>
                <Select
                  value={investType}
                  label="Investing ROI"
                  style={{ textAlign: 'left', fontSize: '16px' }}
                  onChange={(event) => {
                    localStorage.setItem('investType', event.target.value);
                    setInvestType(event.target.value);
                  }}
                >
                  <MenuItem value="1y">1 Year</MenuItem>
                  <MenuItem value="6m">6 Months</MenuItem>
                  <MenuItem value="1m">1 Month</MenuItem>
                </Select>
              </FormControl>
            </div>
            {pricingSource == "Tradeit" && <p style={{ fontSize: '0.9rem', textAlign: 'center', margin: '8px', }}>
              Tradeit.gg pricing reflects the cost to withdraw containers and trade back unboxed items. Estimated prices, marked in grey, are used when specific item prices are unavailable.
            </p>}
            <a href={skinsearchLink(caseData["SouvenirPackName"] ?? caseData["Case"], true, item?.CollectionType)} style={{ margin: 'auto', marginTop: '4px', padding: '8px', }} target="_blank" rel="noopener noreferrer">
              <img src="/assets/skinsearch/360x120-3.jpg" style={{ maxHeight: '11vh', height: 'auto', objectFit: 'contain', width: '100%', margin: 'auto', marginBottom: '4px', }} />
            </a>

          </div> : <div>
          
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
                margin: '8px',
                textAlign: 'center',
              }}
            >
              
              {/* First Row (Image & Invest Type) */}
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '8px',
                  width: '100%',
                  maxWidth: '350px'
                }}
              >
                <Link to="/" style={{ margin: 'auto' }}>
                  <img
                    src="/assets/csroiicon.png"
                    className="p-2"
                    style={{
                      maxWidth: '80px',
                      width: '100%',
                      height: 'auto',
                      objectFit: 'contain',
                      margin: 'auto',
                      marginTop: '8px',
                    }}
                  />
                </Link>
                <FormControl
                  style={{
                    width: 'calc(100% - 100px)',
                    maxWidth: '200px',
                    margin: 'auto',
                    marginTop: '8px',
                  }}
                >
                  <InputLabel id="demo-simple-select-label">Invest Type</InputLabel>
                  <Select
                    value={investType}
                    label="Investing ROI"
                    style={{
                      textAlign: 'left',
                      fontSize: '16px',
                      height: '56px',
                    }}
                    onChange={(event) => {
                      localStorage.setItem('investType', event.target.value);
                      setInvestType(event.target.value);
                    }}
                  >
                    <MenuItem value="1y">1 Year</MenuItem>
                    <MenuItem value="6m">6 Months</MenuItem>
                    <MenuItem value="1m">1 Month</MenuItem>
                  </Select>
                </FormControl>
              </div>

              {/* Second Row (Pricing Source & Currency) */}
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '2px',
                  width: '100%',
                  maxWidth: '350px'
                }}
              >
                <FormControl
                  style={{
                    width: '180px',
                    margin: 'auto',
                    marginTop: '2px',
                    textAlign: 'left', // Ensures label and text are left-aligned
                  }}
                >
                  <InputLabel id="pricing-source-label">Pricing Source</InputLabel>
                  <Select
                    labelId="pricing-source-label"
                    id="pricing-source"
                    value={pricingSource}
                    label="Pricing Source"
                    sx={{ width: '100%', textAlign: 'left' }} // Ensure the text inside Select is left-aligned
                    onChange={handlePricingSourceChange}
                  >
                    <MenuItem value="Steam">Steam</MenuItem>
                    <MenuItem value="Buff">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        Buff.163 / UUPin
                        <Tooltip
                          title="Buff.163 prices are preferred, but UUPin prices are used when Buff.163 prices are unavailable (e.g., containers) or when there is a significant price difference."
                        >
                          <HelpOutlineIcon
                            className="iconSVG"
                            sx={{
                              width: '20px',
                              height: '20px',
                              marginLeft: '4px',
                              marginBottom: '-4px',
                            }}
                            fontSize="large"
                          />
                        </Tooltip>
                      </div>
                    </MenuItem>
                    <MenuItem value="CSFloat">CSFloat</MenuItem>
                    <MenuItem value="Skinport">Skinport</MenuItem>
                    <MenuItem value="Tradeit">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        Tradeit.gg
                        <Tooltip
                          title="Tradeit.gg pricing reflects the cost to withdraw containers and trade back unboxed items. Estimated prices, marked in grey, are used when specific item prices are unavailable."
                        >
                          <HelpOutlineIcon
                            className="iconSVG"
                            sx={{
                              width: '20px',
                              height: '20px',
                              marginLeft: '4px',
                              marginBottom: '-4px',
                            }}
                            fontSize="large"
                          />
                        </Tooltip>
                      </div>
                    </MenuItem>
                  </Select>
                </FormControl>

                <Autocomplete
                  disableClearable
                  value={currency}
                  onChange={(event, newValue) => setCurrency(newValue)}
                  options={currencies}
                  getOptionLabel={(option) => option.toUpperCase()}
                  sx={{
                    fontFamily: "'Barlow', 'Roboto', sans-serif",
                    width: '120px',
                    margin: 'auto',
                    marginTop: '2px',
                  }}
                  renderInput={(params) => <TextField {...params} label="Currency" />}
                />
              </div>
            </div>
            <a
                  href={skinsearchLink(caseData["SouvenirPackName"] ?? caseData["Case"], true, item?.CollectionType)}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    style={{
                      maxWidth: '350px', // Ensures it doesn’t go beyond 950px
                      width: '100%', // Scales down if needed
                      maxHeight: '9vh', // Keeps height fixed
                      objectFit: 'contain'
                    }}
                    src="/assets/skinsearch/350x80.jpg"
                  />
                </a>



            <div style={{ flex: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-around', margin: '8px', marginTop: '4px' }}>

              <div style={{ marginLeft: '0px', marginRight: '0px' }}>{
                // CaseGridItem(item, null, pricingSource, currency, exchangeRate, sortBy, isMobileLayout, showUnbox, showProfit, showInvest)
                <CaseGridItem
                  key={item.id}
                  item={item}
                  // index={index}
                  pricingSource={pricingSource}
                  currency={currency}
                  exchangeRate={exchangeRate}
                  sortBy={sortBy}
                  isMobileLayout={isMobileLayout}
                  showUnbox={showUnbox}
                  showProfit={showProfit}
                  showInvest={showInvest}
                  numListings={item.NumListings}
                  showListings={showListings}
                  investType={investType}
                  isCaseEstimate={caseData.IsCaseEstimate ?? false}
                />
              }
              </div>

              {/* <Card sx={{
            position: 'relative', transition: 'box-shadow 0.3s',
            '&:hover': {
              boxShadow: '0 8px 16px 0 rgba(0,0,0,0.6)'
            }, width: '350px', height: '100%', background: `linear-gradient(145deg, RGB(${redNum}, ${greenNum}, ${blueNum}) 0%, black 100%)`, marginTop: '6px',   position: 'relative', minHeight: '10vw'
          }} >
            <a href={`/item/${(item?.CollectionId + (item?.CollectionType == "Souvenir" ? "s" : "")) + (item?.CollectionType == "Souvenir" ? "s" : "")}`} style={{ textDecoration: 'none', color: 'inherit', display: 'flex' }}>
              <div style={{ alignItems: 'center', display: 'flex', flex: '1', padding: '8px', flexDirection: 'column', alignContent: 'space-between' }}>
                <h5 style={{ lineHeight: 1.1, margin: '0', width: '100%', textAlign: "center", minHeight: '3.5em', justifyContent: 'center', textAlign: 'center', margin: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center', justifySelf: 'center' }}>{item?.Name.split(' | ').map((text, index) => (
                  <React.Fragment key={index}>
                    {text}
                    <br />
                  </React.Fragment>
                ))}
                </h5>
                {item.CollectionType !== "Collection" && <h6 style={{ lineHeight: 0, margin: 0, marginBottom: '20px', marginTop: 'auto' }}>
                                    <h1 style={{ lineHeight: 0.3, textAlign: "center", justifyContent: 'center', padding: '8px', margin: '0.2rem', marginTop: '4px' }}>{formatNumber(((pricingSource == "Steam" ? item.CollectionPriceSteam : pricingSource == "Buff" ? item.CollectionPriceBuff : item.CollectionPriceSkinport) ?? 0, true) / exchangeRate)}</h1>
                                    {item.KeyCost == 0 ? item.CollectionType === "Past Operation" ? <span style={{ fontSize: '10px', lineHeight: 0.2, textAlign: 'center', display: 'block', }}>{((pricingSource == "Steam" ? item.CollectionPriceSteam : pricingSource == "Buff" ? item.CollectionPriceBuff : item.CollectionPriceSkinport) ?? 0) / 0.45} Stars</span> : <span style={{ fontSize: '10px', lineHeight: 0.2, textAlign: 'center', display: 'block', visibility: 'hidden' }}>+{formatNumber((item.KeyCost ?? 0) / exchangeRate, true)} Key </span> : <span style={{ fontSize: '10px', lineHeight: 0.2, textAlign: 'center', display: 'block', }}>+{formatNumber((item.KeyCost ?? 0) / exchangeRate, true)} Key</span>}
                                    <span style={{ fontSize: '11px', lineHeight: 0.3, textAlign: 'center', display: 'block', marginTop: '18px' }}>{formatNumber((pricingSource == "Steam" ? item.ProfitSteam : pricingSource == "Buff" ? item.ProfitBuff : item.ProfitSkinport) * 100)}% Profit</span>
                                  </h6>}
              </div>
              <div
                className="grid-item"
                style={{
                  position: 'relative'
                }}
              >
                <CardMedia
                  sx={{ height: '140px', minWidth: '140px', backgroundSize: "contain", position: 'relative' }}
                  image={removeSlashSection(item?.Image)}
                />
                {item?.DropType == "Discontinued" && <div class="ribbon">DISCONTINUED</div>}
                {item?.DropType == "Rare Drop" && <div class="orangeRibbon">RARE DROP</div>}
                {item?.DropType == "Active Drop" && <div class="greenRibbon">ACTIVE DROP</div>}
                {item?.DropType == "In Game Store" && <div class="greenStoreRibbon">IN GAME STORE</div>}
                <div style={{ position: 'absolute', top: 0, right: '12px', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2 }}>
                  <span style={{ fontSize: '24px' }}>#{index + 1}</span>
                </div> 

              </div>


              <h6 style={{ display: "flex", height: "100%", alignItems: "center", lineHeight: 0.5, padding: '4px', flex: '1', paddingTop: '0', margin: 'auto', flexDirection: 'column', justifyContent: 'center', alignContent: 'center' }}>
                <Tooltip title="Return on Investment for opening this container today" sx={{ margin: 'auto' }}>
                  <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', marginBottom: '18px' }}>
                    Unboxing ROI
                    {sortBy == "unbox" ? <img src={OpenBox} className="iconSVGMob" style={{ fill: 'green' }} /> : <img src={OpenBox} className="iconSVGMob" />}
                    {item?.CollectionType !== "Collection" ? <h2 style={{ lineHeight: 0.5, margin: 0 }}>{formatNumber((pricingSource == "Steam" ? item?.SteamROI : pricingSource == "Buff" ? item?.BuffROI : item?.SkinportROI) * 100)}%</h2> : <h2 style={{ lineHeight: 0.5, margin: 0 }}>${formatNumber((pricingSource == "Steam" ? item?.SteamROI : pricingSource == "Buff" ? item?.BuffROI : item?.SkinportROI) / 100 / exchangeRate)}</h2>}

                  </div>
                </Tooltip>
                {/* <div>
          <h2 style={{ lineHeight: 0.3, textAlign: "center", justifyContent: 'center', padding: '8px', margin: '0.5rem' }}>{currencySymbol}{(((pricingSource == "Steam" ? item?.CollectionPriceSteam : item?.CollectionPriceBuff) ?? 0) / exchangeRate).toFixed(2)}</h2>
          {item?.KeyCost == 0 ? <span></span> : <span style={{ fontSize: '12px', lineHeight: 0, textAlign: 'center', display: 'block' }}>+{currencySymbol}{(((pricingSource == "Steam" ? item?.KeyCost : item?.KeyCost) ?? 0) / exchangeRate).toFixed(2)} Key</span>}
        </div> 
                <Tooltip title="Return on Investment if you had purchased this container a year ago">
                                    <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', margin: 'auto' }}>
                                      Investing ROI
                                      {sortBy == "unbox" ? (pricingSource == "Steam" ? sortBy == "invest1M" ? item?.Steam1MiROI : sortBy == "invest6M" ? item?.Steam6MiROI : item?.SteamiROI ?? 0 : 
                                      pricingSource == "Buff" ? sortBy == "invest1M" ? item?.Buff1MiROI : sortBy == "invest6M" ? item?.Buff6MiROI : item?.BuffiROI ?? 0 : 
                                      sortBy == "invest1M" ? item?.Skinport1MiROI : sortBy == "invest6M" ? item?.Skinport6MiROI : item?.SkinportiROI ?? 0) > 0 ? 
                                      <TrendingUpIcon className="iconSVG" fontSize='large' /> : <TrendingDownIcon className="iconSVG" fontSize='large' /> : 
                                      (pricingSource == "Steam" ? sortBy == "invest1M" ? item?.Steam1MiROI : sortBy == "invest6M" ? item?.Steam6MiROI : item?.SteamiROI ?? 0 : 
                                      pricingSource == "Buff" ? sortBy == "invest1M" ? item?.Buff1MiROI : sortBy == "invest6M" ? item?.Buff6MiROI : item?.BuffiROI ?? 0 : 
                                      sortBy == "invest1M" ? item?.Skinport1MiROI : sortBy == "invest6M" ? item?.Skinport6MiROI : item?.SkinportiROI ?? 0) > 0 ? <TrendingUpIcon className="iconSVG" fontSize='large' style={{ fill: 'green' }} /> : <TrendingDownIcon className="iconSVG" fontSize='large' style={{ fill: 'rgb(187, 23, 26)' }} />}

                                      {sortBy == "unbox" ? 
                                      <h2 style={{ lineHeight: 0.5, margin: 0 }}>{formatNumber(((pricingSource == "Steam" ? item?.SteamiROI ?? 0 : pricingSource == "Buff" ? item?.BuffiROI ?? 0 : item?.SkinportiROI ?? 0)))}%</h2> 
                                      : (pricingSource == "Steam" ? sortBy == "invest1M" ? item?.Steam1MiROI : sortBy == "invest6M" ? item?.Steam6MiROI : item?.SteamiROI ?? 0 : 
                                      pricingSource == "Buff" ? sortBy == "invest1M" ? item?.Buff1MiROI : sortBy == "invest6M" ? item?.Buff6MiROI : item?.BuffiROI ?? 0 : 
                                      sortBy == "invest1M" ? item?.Skinport1MiROI : sortBy == "invest6M" ? item?.Skinport6MiROI : item?.SkinportiROI ?? 0) > 0 ? 
                                      <h2 style={{ lineHeight: 0.5, margin: 0, color: 'green' }}>{formatNumber((pricingSource == "Steam" ? sortBy == "invest1M" ? item?.Steam1MiROI : sortBy == "invest6M" ? item?.Steam6MiROI : item?.SteamiROI ?? 0 : 
                                      pricingSource == "Buff" ? sortBy == "invest1M" ? item?.Buff1MiROI : sortBy == "invest6M" ? item?.Buff6MiROI : item?.BuffiROI ?? 0 : 
                                      sortBy == "invest1M" ? item?.Skinport1MiROI : sortBy == "invest6M" ? item?.Skinport6MiROI : item?.SkinportiROI ?? 0))}%</h2> : 
                                      <h2 style={{ lineHeight: 0.5, margin: 0, color: 'rgb(187, 23, 26)' }}>{formatNumber((pricingSource == "Steam" ? sortBy == "invest1M" ? item?.Steam1MiROI : sortBy == "invest6M" ? item?.Steam6MiROI : item?.SteamiROI ?? 0 : 
                                      pricingSource == "Buff" ? sortBy == "invest1M" ? item?.Buff1MiROI : sortBy == "invest6M" ? item?.Buff6MiROI : item?.BuffiROI ?? 0 : 
                                      sortBy == "invest1M" ? item?.Skinport1MiROI : sortBy == "invest6M" ? item?.Skinport6MiROI : item?.SkinportiROI ?? 0))}%
                                      </h2>}
                                    </div>
                                  </Tooltip>
              </h6>

            </a>

          </Card> */}

              <div style={{ display: 'flex', flexDirection: 'column', minWidth: '200px', width: '100%', maxWidth: '350px' }}>
                <a
                  href={caseData["IsCSFloat"] ? csfloatLink(caseData["SouvenirPackName"] ?? caseData["Case"]) :
                    caseData["ISTradeit"] ? tradeitLink(caseData["SouvenirPackName"] ?? caseData["Case"]) :
                      caseData["IsBuff"] ? buffLink(caseData["SouvenirPackName"] ?? caseData["Case"]) :
                        caseData["IsSkinport"] ? skinportLink(caseData["SouvenirPackName"] ?? caseData["Case"], caseData, true) :
                          steamLink(caseData["SouvenirPackName"] ?? caseData["Case"])}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    backgroundColor: '#bb171a',
                    color: 'white',
                    margin: '6px 8px',
                    padding: '10px 16px',
                    textDecoration: 'none',
                    display: item?.CollectionType !== "Collection" && item?.CollectionType !== "Past Operation" ? 'inline-block' : 'none',
                    borderRadius: '4px',
                    fontWeight: 'bold',
                    fontSize: '14px',
                    textAlign: 'center'
                  }}
                >
                  OPEN IN {pricingSource.toUpperCase()}
                </a>{Object.entries(caseData["PercentReceive"]).map(([rarity, percent]) => (
                  <Card style={{
                    marginTop: '6px',
                    // marginLeft: '8px',
                    // marginRight: '8px',
                    // width: '95%',
                    background: `linear-gradient(145deg, RGB(${redRarityNums[Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity]}, ${greenRarityNums[Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity]}, ${blueRarityNums[Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity]}) 0%, black 100%)`, paddingLeft: 0
                  }}>
                    <h4 style={{ textAlign: 'center', margin: '2px', height: 18, marginTop: '-2px' }}>{rarity.replace('Contraband', 'Gloves / Knives').replace('_', '-')}</h4>
                    <p style={{ textAlign: 'center', margin: '2px', }}>{formatNumber(caseData['RarityValue'][rarity] / exchangeRate, true)} every {formatNumber(1 / percent)}</p>
                  </Card>))}
                  <a href={skinsearchLink(caseData["SouvenirPackName"] ?? caseData["Case"], true, item?.CollectionType)} style={{ margin: 'auto', marginTop: '4px', padding: '8px', }} target="_blank" rel="noopener noreferrer">
                    <img src="/assets/skinsearch/360x120-3.jpg" style={{ height: 'auto', objectFit: 'contain', width: '100%', margin: 'auto',  maxHeight: '13vh' }} />
                  </a>
              </div>


            </div> </div>
          :
          <div />
        }


        {caseData ? !isMobileLayout ? (
          <div style={{ flex: 12, margin: '8px', maxHeight: hideSkinsearch ? '100vh' : '92vh' }}>
            <div
              style={{

                maxHeight: '7vh',
                display: hideSkinsearch ? 'none' : 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {/* Wrapper to match image width */}
              <div style={{ position: 'relative', display: 'inline-block', maxHeight: '7vh', maxWidth: '100%' }}>
                {/* Image Link */}
                <a
                  href={skinsearchLink(caseData["SouvenirPackName"] ?? caseData["Case"], true, item?.CollectionType)}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', maxHeight: '7vh',  height: '100%' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    style={{
                      maxWidth: '950px', // Ensures it doesn’t go beyond 950px
                      width: '100%', // Scales down if needed
                      // height: '75px', // Keeps height fixed
                      maxHeight: '7vh',
                      objectFit: 'contain'
                    }}
                    src="/assets/skinsearch/950x75.jpg"
                  />
                </a>

                {/* Close Button */}
                <IconButton
                  size="small"
                  onClick={() => setHideSkinsearch(true)}
                  sx={{
                    position: 'absolute',
                    right: '4px', // Anchored to the right of the image
                    top: '50%',
                    transform: 'translateY(-50%)', // Centers it vertically
                    color: 'white',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
                    border: '2px solid white',
                    zIndex: 10
                  }}
                >
                  <CloseIcon fontSize="medium" />
                </IconButton>
              </div>
            </div>






            <Scrollbars
              // autoHide
              // autoHideTimeout={1000}
              // autoHideDuration={200}
              style={{
                position: !isMobileLayout ? 'relative' : 'fixed'
              }}
              renderThumbVertical={({ style }) => (
                <div style={{ ...style, backgroundColor: 'lightgray', borderRadius: '4px' }} />
              )}
            >
              <div className="sr-only">
                <Typography variant="body1">
                  All items in this container and their chances. View wear chances and costs by selecting an item. Items in this container include: {Object.keys(caseData?.["Items"] ?? {})?.join(', ')}
                </Typography>
              </div>

              {fullscreenAccordionId === 'Items Within' ? (
                <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 10, overflow: 'auto' }}>
                  <Accordion onChange={(e, expanded) => {
                    if (!expanded) {
                      setFullscreenAccordionId(null);
                    }
                    // existing code...
                  }} defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      key="Items Within"
                    >
                      <Typography>Items Within</Typography>

                      <Tooltip title="All items in this container and their chances. View wear chances and costs by selecting an item" sx={{ margin: 'auto' }}>

                        <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '8px' }} fontSize='large' />
                      </Tooltip>
                      <IconButton onClick={(e) => {
                        e.stopPropagation();
                        setFullscreenAccordionId(fullscreenAccordionId === 'Items Within' ? null : 'Items Within');
                      }} style={{ margin: 0, marginRight: '10px', height: '20px', width: '20px' }}>
                        {fullscreenAccordionId === 'Items Within' ? <FullscreenExit /> : <Fullscreen />}
                      </IconButton>
                    </AccordionSummary>
                    <AccordionDetails>
                      <CaseItems caseData={caseData} exchangeRate={exchangeRate} currency={currency} isMobile={isMobileLayout} investType={investType} />
                    </AccordionDetails>

                  </Accordion>
                  {/* <button onClick={() => setFullscreenAccordionId(null)}>Exit Fullscreen</button> */}
                </div>
              ) : (
                <Accordion onChange={(e, expanded) => {
                  if (expanded) {
                    ReactGA.event({
                      category: 'Items Within',
                      action: 'accordion_open',
                      label: 'Items Within - ' + caseData.Case, // Customize with the specific accordion label
                    });
                  } else {
                    ReactGA.event({
                      category: 'Items Within',
                      action: 'accordion_close',
                      label: 'Items Within - ' + caseData.Case, // Customize with the specific accordion label
                    });
                  }
                }} >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    key="Items Within"
                  >
                    <Typography>Items Within</Typography>

                    <Tooltip title="All items in this container and their chances. View wear chances and costs by selecting an item" sx={{ margin: 'auto' }}>

                      <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '8px' }} fontSize='large' />
                    </Tooltip>
                    <IconButton onClick={(e) => {
                      e.stopPropagation();
                      setFullscreenAccordionId(fullscreenAccordionId === 'Items Within' ? null : 'Items Within');
                    }} style={{ margin: 0, marginRight: '10px', height: '20px', width: '20px' }}>
                      {fullscreenAccordionId === 'Items Within' ? <FullscreenExit /> : <Fullscreen />}
                    </IconButton>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CaseItems caseData={caseData} exchangeRate={exchangeRate} currency={currency} isMobile={isMobileLayout} investType={investType} />
                  </AccordionDetails>
                  {/* <button onClick={() => setFullscreenAccordionId('Items Within')}>Go Fullscreen Accordion 1</button> */}

                </Accordion>

              )}

              <div className="sr-only">
                <Typography variant="body1">
                  Simulate opening this container and repeat simulations until you get your desired outcome
                </Typography>
              </div>
              {fullscreenAccordionId === 'Simulator' ? (
                <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 10, overflow: 'auto' }}>
                  <Accordion onChange={(e, expanded) => {
                    if (!expanded) {
                      setFullscreenAccordionId(null);
                    }
                    // existing code...
                  }} defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      key="Simulator"
                    >
                      <Typography>Simulator</Typography>
                      <Tooltip title="Simulate opening this container and repeat simulations until you get your desired outcome" sx={{ margin: 'auto' }}>

                        <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '8px' }} fontSize='large' />
                      </Tooltip>
                      <IconButton onClick={(e) => {
                        e.stopPropagation();
                        setFullscreenAccordionId(fullscreenAccordionId === 'Simulator' ? null : 'Simulator');
                      }} style={{ margin: 0, marginRight: '10px', height: '20px', width: '20px' }}>
                        {fullscreenAccordionId === 'Simulator' ? <FullscreenExit /> : <Fullscreen />}
                      </IconButton>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Simulate caseData={caseData} exchangeRate={exchangeRate} currency={currency} isMobile={isMobileLayout} isFullscreen={true} />
                    </AccordionDetails>

                  </Accordion>
                  {/* <button onClick={() => setFullscreenAccordionId(null)}>Exit Fullscreen</button> */}
                </div>
              ) : (
                <Accordion onChange={(e, expanded) => {
                  if (expanded) {
                    ReactGA.event({
                      category: 'Simulator',
                      action: 'accordion_open',
                      label: 'Simulator - ' + caseData.Case, // Customize with the specific accordion label
                    });
                  } else {
                    ReactGA.event({
                      category: 'Simulator',
                      action: 'accordion_close',
                      label: 'Simulator - ' + caseData.Case, // Customize with the specific accordion label
                    });
                  }
                }} >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    key="Simulator"
                  >
                    <Typography>Simulator</Typography>
                    <Tooltip title="Simulate opening this container and repeat simulations until you get your desired outcome" sx={{ margin: 'auto' }}>

                      <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '8px' }} fontSize='large' />
                    </Tooltip>
                    <IconButton onClick={(e) => {
                      e.stopPropagation();
                      setFullscreenAccordionId(fullscreenAccordionId === 'Simulator' ? null : 'Simulator');
                    }} style={{ margin: 0, marginRight: '10px', height: '20px', width: '20px' }}>
                      {fullscreenAccordionId === 'Simulator' ? <FullscreenExit /> : <Fullscreen />}
                    </IconButton>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Simulate caseData={caseData} exchangeRate={exchangeRate} currency={currency} isMobile={isMobileLayout} />
                  </AccordionDetails>

                </Accordion>

              )}

              <div className="sr-only">
                <Typography variant="body1">
                  Select an item and view chances for each wear. The graph shows exactly how these chances are calculated and where wear/float values are most likely to appear. Select a wear and/or set your own AVG, Min and Max Float to view estimated chances for specific floats.
                </Typography>
              </div>
              {fullscreenAccordionId === 'Float Drop Chances' ? (
                <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 10, overflow: 'auto' }}>
                  <Accordion onChange={(e, expanded) => {
                    if (!expanded) {
                      setFullscreenAccordionId(null);
                    }
                    // existing code...
                  }} sx={{ padding: '0' }} defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      key="Float Drop Chances"
                    >
                      <Typography>Item & Float Drop Chances</Typography>
                      <Tooltip title="Select an item and view chances for each wear. The graph shows exactly how these chances are calculated and where wear/float values are most likely to appear. Select a wear and/or set your own AVG, Min and Max Float to view estimated chances for specific floats." sx={{ margin: 'auto' }}>

                        <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '8px' }} fontSize='large' />
                      </Tooltip>
                      <IconButton onClick={(e) => {
                        e.stopPropagation();
                        setFullscreenAccordionId(fullscreenAccordionId === 'Float Drop Chances' ? null : 'Float Drop Chances');
                      }} style={{ margin: 0, marginRight: '10px', height: '20px', width: '20px' }}>
                        {fullscreenAccordionId === 'Float Drop Chances' ? <FullscreenExit /> : <Fullscreen />}
                      </IconButton>
                    </AccordionSummary>
                    <AccordionDetails>
                      <FloatDropChances caseData={caseData} exchangeRate={exchangeRate} currency={currency} isMobile={isMobileLayout} isFullscreen={true} />
                    </AccordionDetails>


                  </Accordion>
                  {/* <button onClick={() => setFullscreenAccordionId(null)}>Exit Fullscreen</button> */}
                </div>
              ) : item != null && (item["CollectionType"] == "Past Operation" || item["CollectionType"] == "Case" || item["CollectionType"] == "Collection" || item["CollectionType"] == "Souvenir" || (item["CollectionType"] == "Armory" && item["Name"].includes("Collection") && !item["Name"].includes("Sticker")) || item["Name"].includes("Limited Edition Item")) && (
                <Accordion onChange={(e, expanded) => {
                  if (expanded) {
                    ReactGA.event({
                      category: 'Float Drop Chances',
                      action: 'accordion_open',
                      label: 'Float Drop Chances - ' + caseData.Case, // Customize with the specific accordion label
                    });
                  } else {
                    ReactGA.event({
                      category: 'Float Drop Chances',
                      action: 'accordion_close',
                      label: 'Float Drop Chances - ' + caseData.Case, // Customize with the specific accordion label
                    });
                  }
                }} sx={{ padding: '0' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    key="Float Drop Chances"
                  >
                    <Typography>Item & Float Drop Chances</Typography>
                    <Tooltip title="Select an item and view chances for each wear. The graph shows exactly how these chances are calculated and where wear/float values are most likely to appear. Select a wear and/or set your own AVG, Min and Max Float to view estimated chances for specific floats." sx={{ margin: 'auto' }}>

                      <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '8px' }} fontSize='large' />
                    </Tooltip>
                    <IconButton onClick={(e) => {
                      e.stopPropagation();
                      setFullscreenAccordionId(fullscreenAccordionId === 'Float Drop Chances' ? null : 'Float Drop Chances');
                    }} style={{ margin: 0, marginRight: '10px', height: '20px', width: '20px' }}>
                      {fullscreenAccordionId === 'Float Drop Chances' ? <FullscreenExit /> : <Fullscreen />}
                    </IconButton>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FloatDropChances caseData={caseData} exchangeRate={exchangeRate} currency={currency} isMobile={isMobileLayout} />
                  </AccordionDetails>


                </Accordion>

              )}

              <div className="sr-only">
                <Typography variant="body1">
                  View all items, and find an estimated cost for you to unbox one yourself
                </Typography>
              </div>
              {fullscreenAccordionId === 'Cost To Unbox' ? (
                <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 10, overflow: 'auto' }}>
                  <Accordion onChange={(e, expanded) => {
                    if (!expanded) {
                      setFullscreenAccordionId(null);
                    }
                    // existing code...
                  }} defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      key="Cost To Unbox"
                    >
                      <Typography>Cost To Unbox</Typography>

                      <Tooltip title="View all items, and find an estimated cost for you to unbox one yourself" sx={{ margin: 'auto' }}>

                        <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '8px' }} fontSize='large' />
                      </Tooltip>
                      <IconButton onClick={(e) => {
                        e.stopPropagation();
                        setFullscreenAccordionId(fullscreenAccordionId === 'Cost To Unbox' ? null : 'Cost To Unbox');
                      }} style={{ margin: 0, marginRight: '10px', height: '20px', width: '20px' }}>
                        {fullscreenAccordionId === 'Cost To Unbox' ? <FullscreenExit /> : <Fullscreen />}
                      </IconButton>
                    </AccordionSummary>
                    <AccordionDetails>
                      <UnboxCost caseData={caseData} exchangeRate={exchangeRate} currency={currency} isMobile={isMobileLayout} />
                    </AccordionDetails>

                  </Accordion>
                  {/* <button onClick={() => setFullscreenAccordionId(null)}>Exit Fullscreen</button> */}
                </div>
              ) : (
                <Accordion style={{ display: item["CollectionType"] == "Collection" ? 'none' : 'block' }} onChange={(e, expanded) => {
                  if (expanded) {
                    ReactGA.event({
                      category: 'Cost To Unbox',
                      action: 'accordion_open',
                      label: 'Cost To Unbox - ' + caseData.Case, // Customize with the specific accordion label
                    });
                  } else {
                    ReactGA.event({
                      category: 'Cost To Unbox',
                      action: 'accordion_close',
                      label: 'Cost To Unbox - ' + caseData.Case, // Customize with the specific accordion label
                    });
                  }
                }} >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    key="Cost To Unbox"
                  >
                    <Typography>Cost To Unbox</Typography>

                    <Tooltip title="View all items, and find an estimated cost for you to unbox each item yourself" sx={{ margin: 'auto' }}>

                      <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '8px' }} fontSize='large' />
                    </Tooltip>
                    <IconButton onClick={(e) => {
                      e.stopPropagation();
                      setFullscreenAccordionId(fullscreenAccordionId === 'Cost To Unbox' ? null : 'Cost To Unbox');
                    }} style={{ margin: 0, marginRight: '10px', height: '20px', width: '20px' }}>
                      {fullscreenAccordionId === 'Cost To Unbox' ? <FullscreenExit /> : <Fullscreen />}
                    </IconButton>
                  </AccordionSummary>
                  <AccordionDetails>
                    <UnboxCost caseData={caseData} exchangeRate={exchangeRate} currency={currency} isMobile={isMobileLayout} />
                  </AccordionDetails>
                  {/* <button onClick={() => setFullscreenAccordionId('Cost To Unbox')}>Go Fullscreen Accordion 1</button> */}

                </Accordion>

              )}

              <div className="sr-only">
                <Typography variant="body1">
                  All historical data used for this container. November 2023 marks the period where the pricing source was changed
                </Typography>
              </div>
              {fullscreenAccordionId === 'History' ? (
                <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 10, overflow: 'auto' }}>
                  <Accordion onChange={(e, expanded) => {
                    if (!expanded) {
                      setFullscreenAccordionId(null);
                    }
                    // existing code...
                  }} defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      key="History"
                    >
                      <Typography>History</Typography>
                      <Tooltip title="All historical data used for this container. November 2023 marks the period where the pricing source was changed" sx={{ margin: 'auto' }}>

                        <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '8px' }} fontSize='large' />
                      </Tooltip>
                      <IconButton onClick={(e) => {
                        e.stopPropagation();
                        setFullscreenAccordionId(fullscreenAccordionId === 'History' ? null : 'History');
                      }} style={{ margin: 0, marginRight: '10px', height: '20px', width: '20px' }}>
                        {fullscreenAccordionId === 'History' ? <FullscreenExit /> : <Fullscreen />}
                      </IconButton>
                    </AccordionSummary>
                    <AccordionDetails>
                      <LineGraph itemNumber={(item?.CollectionId + (item?.CollectionType == "Souvenir" ? "s" : ""))} itemData={item} caseData={caseData} exchangeRate={exchangeRate} currency={currency} pricingSource={pricingSource} sortBy={sortBy} investType={investType} isMobile={isMobileLayout} isFullscreen={true} />
                    </AccordionDetails>

                  </Accordion>
                  {/* <button onClick={() => setFullscreenAccordionId(null)}>Exit Fullscreen</button> */}
                </div>
              ) : (
                <Accordion onChange={(e, expanded) => {
                  if (expanded) {
                    ReactGA.event({
                      category: 'History',
                      action: 'accordion_open',
                      label: 'History - ' + caseData.Case, // Customize with the specific accordion label
                    });
                  } else {
                    ReactGA.event({
                      category: 'History',
                      action: 'accordion_close',
                      label: 'History - ' + caseData.Case, // Customize with the specific accordion label
                    });
                  }
                }} defaultExpanded={sortBy != "unbox"}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    key="History"
                  >
                    <Typography>History</Typography>
                    <Tooltip title="All historical data used for this container. November 2023 marks the period where the pricing source was changed" sx={{ margin: 'auto' }}>

                      <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '8px' }} fontSize='large' />
                    </Tooltip>
                    <IconButton onClick={(e) => {
                      e.stopPropagation();
                      setFullscreenAccordionId(fullscreenAccordionId === 'History' ? null : 'History');
                    }} style={{ margin: 0, marginRight: '10px', height: '20px', width: '20px' }}>
                      {fullscreenAccordionId === 'History' ? <FullscreenExit /> : <Fullscreen />}
                    </IconButton>
                  </AccordionSummary>
                  <AccordionDetails>
                    <LineGraph itemNumber={(item?.CollectionId + (item?.CollectionType == "Souvenir" ? "s" : ""))} itemData={item} caseData={caseData} exchangeRate={exchangeRate} currency={currency} pricingSource={pricingSource} sortBy={sortBy} investType={investType} isMobile={isMobileLayout} />
                  </AccordionDetails>

                </Accordion>

              )}

              <div className="sr-only">
                <Typography variant="body1">
                  Compare all rarities in this container, the size and percent describes how much of the Unboxing ROI is provided by each rarity and item. Each rarity provides these percents of the container's value: {Object.entries(caseData["RarityROIPercents"]).map(([rarity, value]) => `${rarity} - ${formatNumber(value * 100, false)}%`).join(', ')}
                </Typography>
              </div>
              {fullscreenAccordionId === 'Rarity Values' ? (
                <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 10, overflow: 'auto' }}>
                  <Accordion onChange={(e, expanded) => {
                    if (!expanded) {
                      setFullscreenAccordionId(null);
                    }
                    // existing code...
                  }} defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      key="Rarity Values"
                    >
                      <Typography>Rarity Values</Typography>
                      <Tooltip title="Compare all rarities in this container, the size and percent describes how much of the Unboxing ROI is provided by each rarity and item" sx={{ margin: 'auto', }}>

                        <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '8px' }} fontSize='large' />
                      </Tooltip>
                      <IconButton onClick={(e) => {
                        e.stopPropagation();
                        setFullscreenAccordionId(fullscreenAccordionId === 'Rarity Values' ? null : 'Rarity Values');
                      }} style={{ margin: 0, marginRight: '10px', height: '20px', width: '20px' }}>
                        {fullscreenAccordionId === 'Rarity Values' ? <FullscreenExit /> : <Fullscreen />}
                      </IconButton>
                    </AccordionSummary>
                    <AccordionDetails>
                      <RarityValues caseData={caseData} exchangeRate={exchangeRate} currency={currency} isMobile={isMobileLayout} investType={investType} />
                    </AccordionDetails>

                  </Accordion>
                  {/* <button onClick={() => setFullscreenAccordionId(null)}>Exit Fullscreen</button> */}
                </div>
              ) : (
                <Accordion onChange={(e, expanded) => {
                  if (expanded) {
                    ReactGA.event({
                      category: 'Rarity Values',
                      action: 'accordion_open',
                      label: 'Rarity Values - ' + caseData.Case, // Customize with the specific accordion label
                    });
                  } else {
                    ReactGA.event({
                      category: 'Rarity Values',
                      action: 'accordion_close',
                      label: 'Rarity Values - ' + caseData.Case, // Customize with the specific accordion label
                    });
                  }
                }} defaultExpanded={sortBy == "unbox"}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    key="Rarity Values"
                  >
                    <Typography>Rarity Values</Typography>
                    <Tooltip title="Compare all rarities in this container, the size and percent describes how much of the Unboxing ROI is provided by each rarity and item" sx={{ margin: 'auto', }}>

                      <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '8px' }} fontSize='large' />
                    </Tooltip>
                    <IconButton onClick={(e) => {
                      e.stopPropagation();
                      setFullscreenAccordionId(fullscreenAccordionId === 'Rarity Values' ? null : 'Rarity Values');
                    }} style={{ margin: 0, marginRight: '10px', height: '20px', width: '20px' }}>
                      {fullscreenAccordionId === 'Rarity Values' ? <FullscreenExit /> : <Fullscreen />}
                    </IconButton>
                  </AccordionSummary>
                  <AccordionDetails>
                    <RarityValues caseData={caseData} exchangeRate={exchangeRate} currency={currency} isMobile={isMobileLayout} investType={investType} />
                  </AccordionDetails>

                </Accordion>

              )}




              {/* {item != null ? ((item["CollectionType"] == "Past Operation" && !item["Name"].includes("Sticker") && !item["Name"].includes("Patch") && item["Name"].includes("Collection")) || item["CollectionType"] == "Case" || item["CollectionType"] == "Collection") &&
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Tradeup Planner</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TradeupPlanner caseData={caseData} exchangeRate={exchangeRate} currencySymbol={currencySymbol} allCases={allCases} allLowCosts={allLowCosts} />
                </AccordionDetails>

              </Accordion> : <div></div>
            } */}
              <div style={{ height: '500px' }}>

              </div>
            </Scrollbars>
          </div>
        )
          : (<div style={{ flex: 10, margin: '8px', marginTop: '0px', maxHeight: '100%', }}>
            {pricingSource == "Tradeit" && <p style={{ fontSize: '0.9rem', textAlign: 'center', margin: '8px', }}>
              Tradeit.gg pricing reflects the cost to withdraw containers and trade back unboxed items. Estimated prices, marked in grey, are used when specific item prices are unavailable.
            </p>}
            <div className="sr-only">
              <Typography variant="body1">
                All items in this container and their chances. View wear chances and costs by selecting an item
              </Typography>
            </div>
            <Accordion onChange={(e, expanded) => {
              if (expanded) {
                ReactGA.event({
                  category: 'Items Within',
                  action: 'accordion_open',
                  label: 'Items Within - ' + caseData.Case, // Customize with the specific accordion label
                });
              } else {
                ReactGA.event({
                  category: 'Items Within',
                  action: 'accordion_close',
                  label: 'Items Within - ' + caseData.Case, // Customize with the specific accordion label
                });
              }
            }} >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                key="Items Within"
              >
                <Typography>Items Within</Typography>
                <Tooltip title="All items in this container and their chances. View wear chances and costs by selecting an item" sx={{ margin: 'auto' }}>

                  <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '8px' }} fontSize='large' />
                </Tooltip>
              </AccordionSummary>
              <AccordionDetails>
                <CaseItems caseData={caseData} exchangeRate={exchangeRate} currency={currency} isMobile={isMobileLayout} investType={investType} />
              </AccordionDetails>

            </Accordion>
            <div className="sr-only">
              <Typography variant="body1">
                Simulate opening this container and repeat simulations until you get your desired outcome
              </Typography>
            </div>
            <Accordion onChange={(e, expanded) => {
              if (expanded) {
                ReactGA.event({
                  category: 'Simulator',
                  action: 'accordion_open',
                  label: 'Simulator - ' + caseData.Case, // Customize with the specific accordion label
                });
              } else {
                ReactGA.event({
                  category: 'Simulator',
                  action: 'accordion_close',
                  label: 'Simulator - ' + caseData.Case, // Customize with the specific accordion label
                });
              }
            }} >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                key="Simulator"
              >
                <Typography>Simulator</Typography>
                <Tooltip title="Simulate opening this container and repeat simulations until you get your desired outcome" sx={{ margin: 'auto' }}>

                  <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '8px' }} fontSize='large' />
                </Tooltip>
              </AccordionSummary>
              <AccordionDetails>
                <Simulate caseData={caseData} exchangeRate={exchangeRate} currency={currency} isMobile={isMobileLayout} />
              </AccordionDetails>

            </Accordion>
            <div className="sr-only">
              <Typography variant="body1">
                Select an item and view chances for each wear. The graph shows exactly how these chances are calculated and where wear/float values are most likely to appear. Select a wear and/or set your own AVG, Min and Max Float to view estimated chances for specific floats.
              </Typography>
            </div>
            {item != null ? (item["CollectionType"] == "Past Operation" || item["CollectionType"] == "Case" || item["CollectionType"] == "Collection" || item["CollectionType"] == "Souvenir" || (item["CollectionType"] == "Armory" && item["Name"].includes("Collection") && !item["Name"].includes("Sticker"))) &&

              <Accordion onChange={(e, expanded) => {
                if (expanded) {
                  ReactGA.event({
                    category: 'Float Drop Chances',
                    action: 'accordion_open',
                    label: 'Float Drop Chances - ' + caseData.Case, // Customize with the specific accordion label
                  });
                } else {
                  ReactGA.event({
                    category: 'Float Drop Chances',
                    action: 'accordion_close',
                    label: 'Float Drop Chances - ' + caseData.Case, // Customize with the specific accordion label
                  });
                }
              }} sx={{ padding: '0' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  key="Float Drop Chances"
                >
                  <Typography>Item & Float Drop Chances</Typography>
                  <Tooltip title="Select an item and view chances for each wear. The graph shows exactly how these chances are calculated and where wear/float values are most likely to appear. Select a wear and/or set your own AVG, Min and Max Float to view estimated chances for specific floats." sx={{ margin: 'auto' }}>

                    <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '8px' }} fontSize='large' />
                  </Tooltip>
                </AccordionSummary>
                <AccordionDetails>
                  <FloatDropChances caseData={caseData} exchangeRate={exchangeRate} currency={currency} isMobile={isMobileLayout} />
                </AccordionDetails>


              </Accordion> : <div></div>
            }
            <div className="sr-only">
              <Typography variant="body1">
                View all items, and find an estimated cost for you to unbox each item yourself
              </Typography>
            </div>
            <Accordion
              style={{ display: item["CollectionType"] == "Collection" ? 'none' : 'block' }}
              onChange={(e, expanded) => {
                if (expanded) {
                  ReactGA.event({
                    category: 'Cost To Unbox',
                    action: 'accordion_open',
                    label: 'Cost To Unbox - ' + caseData.Case, // Customize with the specific accordion label
                  });
                } else {
                  ReactGA.event({
                    category: 'Cost To Unbox',
                    action: 'accordion_close',
                    label: 'Cost To Unbox - ' + caseData.Case, // Customize with the specific accordion label
                  });
                }
              }} >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                key="Cost To Unbox"
              >
                <Typography>Cost To Unbox</Typography>
                <Tooltip title="View all items, and find an estimated cost for you to unbox each item yourself" sx={{ margin: 'auto' }}>

                  <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '8px' }} fontSize='large' />
                </Tooltip>
              </AccordionSummary>
              <AccordionDetails>
                <UnboxCost caseData={caseData} exchangeRate={exchangeRate} currency={currency} isMobile={isMobileLayout} />
              </AccordionDetails>

            </Accordion>
            <div className="sr-only">
              <Typography variant="body1">
                All historical data used for this container. November 2023 marks the period where the pricing source was changed
              </Typography>
            </div>
            <Accordion onChange={(e, expanded) => {
              if (expanded) {
                ReactGA.event({
                  category: 'History',
                  action: 'accordion_open',
                  label: 'History - ' + caseData.Case, // Customize with the specific accordion label
                });
              } else {
                ReactGA.event({
                  category: 'History',
                  action: 'accordion_close',
                  label: 'History - ' + caseData.Case, // Customize with the specific accordion label
                });
              }
            }} defaultExpanded={sortBy != "unbox"}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                key="History"
              >
                <Typography>History</Typography>
                <Tooltip title="All historical data used for this container. November 2023 marks the period where the pricing source was changed" sx={{ margin: 'auto' }}>

                  <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '8px' }} fontSize='large' />
                </Tooltip>
              </AccordionSummary>
              <AccordionDetails>
                <LineGraph itemNumber={(item?.CollectionId + (item?.CollectionType == "Souvenir" ? "s" : ""))} itemData={item} caseData={caseData} exchangeRate={exchangeRate} currency={currency} pricingSource={pricingSource} sortBy={sortBy} investType={investType} isMobile={isMobileLayout} />
              </AccordionDetails>

            </Accordion>
            <div className="sr-only">
              <Typography variant="body1">
                Compare all rarities in this container, the size and percent describes how much of the Unboxing ROI is provided by each rarity and item
              </Typography>
            </div>
            <Accordion defaultExpanded={sortBy == "unbox"} onChange={(e, expanded) => {
              if (expanded) {
                ReactGA.event({
                  category: 'Rarity Values',
                  action: 'accordion_open',
                  label: 'Rarity Values - ' + caseData.Case, // Customize with the specific accordion label
                });
              } else {
                ReactGA.event({
                  category: 'Rarity Values',
                  action: 'accordion_close',
                  label: 'Rarity Values - ' + caseData.Case, // Customize with the specific accordion label
                });
              }
            }} >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                key="Rarity Values"
              >
                <Typography>Rarity Values</Typography>
                <Tooltip title="Compare all rarities in this container, the size and percent describes how much of the Unboxing ROI is provided by each rarity and item" sx={{ margin: 'auto', }}>

                  <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '8px' }} fontSize='large' />
                </Tooltip>
              </AccordionSummary>
              <AccordionDetails>
                <RarityValues caseData={caseData} exchangeRate={exchangeRate} currency={currency} isMobile={isMobileLayout} investType={investType} />
              </AccordionDetails>

            </Accordion>


            {/* <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Tradeup Targets</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FloatRanges caseData={caseData} exchangeRate={exchangeRate} currencySymbol={currencySymbol} />
        </AccordionDetails>

        
      </Accordion> */}

            {/* {item != null ? ((item["CollectionType"] == "Past Operation" && !item["Name"].includes("Sticker") && !item["Name"].includes("Patch") && item["Name"].includes("Collection")) || item["CollectionType"] == "Case" || item["CollectionType"] == "Collection") &&
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Tradeup Planner</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TradeupPlanner caseData={caseData} exchangeRate={exchangeRate} currencySymbol={currencySymbol} allCases={allCases} allLowCosts={allLowCosts} />
            </AccordionDetails>

          </Accordion> : <div></div>
        } */}
            <div style={{ height: '500px' }}>

            </div>

          </div>) : (
          <div></div>
        )}
      </div>
    </ThemeProvider>
  );
}

function removeSlashSection(imageUrl) {
  return imageUrl?.replace(/\/\d+fx\d+f$/, '/');
}

export default function Dashboard() {
  return <DashboardContent />;
}