import { Grid, Card, CardMedia, Tooltip } from "@mui/material";
import OpenBox from './OpenBox.svg';
import './Rankings.css';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import React, { useEffect } from 'react';
import { buffLink, csfloatLink, skinportLink, steamLink, tradeitLink } from "./allLinks";
import { Link } from 'react-router-dom';



function removeSlashSection(imageUrl) {
  return imageUrl.replace(/\/\d+fx\d+f$/, '/');
}

const redRarityNums = { 'Contraband': 223, 'Covert': 187, 'Classified': 158, 'Restricted': 104, 'Mil_Spec': 2, 'Industrial': 94, 'Consumer': 176 };
const greenRarityNums = { 'Contraband': 199, 'Covert': 23, 'Classified': 0, 'Restricted': 50, 'Mil_Spec': 79, 'Industrial': 152, 'Consumer': 195 };
const blueRarityNums = { 'Contraband': 0, 'Covert': 26, 'Classified': 197, 'Restricted': 234, 'Mil_Spec': 228, 'Industrial': 217, 'Consumer': 217 };

const otherRarityNames = { 'Extraordinary': 'Covert', 'Exotic': 'Classified', 'Remarkable': 'Restricted', 'High_Grade': 'Mil_Spec', 'Superior': 'Classified', 'Master': 'Covert', 'Exceptional': 'Restricted', 'Distinguished': 'Mil_Spec' }


export const CaseGridItem = ({ item, index, pricingSource, currency, exchangeRate, sortBy, isMobileLayout, showUnbox, showProfit, showInvest, numListings, showListings, alwaysShadow, investType, isCaseEstimate }) => {
  // console.log(showListings)
  function formatNumber(number, isCurrency) {
    // Use the user's locale
    const userLocale = navigator.language || 'en-US';

    // Create Intl.NumberFormat with user's locale
    if (isCurrency) {
      const formatter = new Intl.NumberFormat(userLocale, {
        //   minimumFractionDigits: 1,
        style: "currency",
        currency: currency,
        maximumFractionDigits: number > 1000 ? 0 : number > 100 ? 1 : 2,
      });
      return formatter.format(number);
    } else {
      const formatter = new Intl.NumberFormat(userLocale, {
        //   minimumFractionDigits: 1,
        maximumFractionDigits: number > 1000 ? 0 : number > 100 ? 1 : 2,
      });
      return formatter.format(number);
    }

    // Format the number

  }
  // Calculate the percentage of the value
  const valuePercentage = ((pricingSource == "Steam" ? item.SteamROI : pricingSource == "CSFloat" ? item.CSFloatROI : pricingSource == "Tradeit" ? item.TradeitROI : pricingSource == "Buff" ? item.BuffROI : item.SkinportROI) / 2) * 100;

  let redNum;
  let greenNum;
  let blueNum;
  let compareValue = ((sortBy == "unbox" || sortBy == "profit" || sortBy == "dropType" || sortBy == "cost") ?
    (pricingSource == "Steam" ? item.SteamROI : pricingSource == "CSFloat" ? item.CSFloatROI : pricingSource == "Tradeit" ? item.TradeitROI : pricingSource == "Buff" ? item.BuffROI : item.SkinportROI) :
    (pricingSource == "Steam" ? investType == "1m" ? item?.Steam1MiROI : investType == "6m" ? item?.Steam6MiROI : item?.SteamiROI ?? 0 :
      pricingSource == "CSFloat" ? investType == "1m" ? item?.CSFloat1MiROI : investType == "6m" ? item?.CSFloat6MiROI : item?.CSFloatiROI ?? 0 :
        pricingSource == "Tradeit" ? investType == "1m" ? item?.Tradeit1MiROI : investType == "6m" ? item?.Tradeit6MiROI : item?.TradeitiROI ?? 0 :
          pricingSource == "Buff" ? investType == "1m" ? item?.Buff1MiROI : investType == "6m" ? item?.Buff6MiROI : item?.BuffiROI ?? 0 :
            investType == "1m" ? item?.Skinport1MiROI : investType == "6m" ? item?.Skinport6MiROI : item?.SkinportiROI ?? 0))
  if (item.YourROI != null) {
    compareValue = item.YourROI;
  }
  if (item.CollectionType == "Collection" && (sortBy == "unbox" || sortBy == "profit" || sortBy == "dropType" || sortBy == "cost")) {
    compareValue *= 0.001;
  }
  if (investType == "1m" && sortBy == "invest") {
    compareValue *= 12;
  }
  if (investType == "6m" && sortBy == "invest") {
    compareValue *= 2;
  }
  if ((sortBy == "unbox" || sortBy == "profit" || sortBy == "dropType" || sortBy == "cost")) {
    if (compareValue > 1) {
      redNum = 223;
      greenNum = 199;
      blueNum = 0;
    } else if (compareValue > 0.8) {
      redNum = 187 + Math.abs(compareValue - 0.8) / 0.2 * 36;
      greenNum = 23 + Math.abs(compareValue - 0.8) / 0.2 * 176;
      blueNum = 26 - Math.abs(compareValue - 0.8) / 0.2 * 26;
    } else if (compareValue > 0.6) {
      redNum = 158 + Math.abs(compareValue - 0.6) / 0.2 * 29;
      greenNum = 0 + Math.abs(compareValue - 0.6) / 0.2 * 23;
      blueNum = 197 - Math.abs(compareValue - 0.6) / 0.2 * 171;
    } else if (compareValue > 0.4) {
      redNum = 104 + Math.abs(compareValue - 0.4) / 0.2 * 54;
      greenNum = 50 - Math.abs(compareValue - 0.4) / 0.2 * 50;
      blueNum = 234 - Math.abs(compareValue - 0.4) / 0.2 * 37;
    } else {
      redNum = 2 + Math.abs(compareValue) / 0.4 * 102;
      greenNum = 79 - Math.abs(compareValue) / 0.4 * 29;
      blueNum = 228 + Math.abs(compareValue) / 0.4 * 6;
    }
  } else {
    if (compareValue > 100) {
      redNum = 223;
      greenNum = 199;
      blueNum = 0;
    } else if (compareValue > 75) {
      redNum = 187 + (compareValue - 75) / 25 * 36;
      greenNum = 23 + (compareValue - 75) / 25 * 176;
      blueNum = 26 - (compareValue - 75) / 25 * 26;
    } else if (compareValue > 50) {
      redNum = 158 + (compareValue - 50) / 25 * 29;
      greenNum = 0 + (compareValue - 50) / 25 * 23;
      blueNum = 197 - (compareValue - 50) / 25 * 171;
    } else if (compareValue > 25) {
      redNum = 104 + (compareValue - 25) / 25 * 54;
      greenNum = 50 - (compareValue - 25) / 25 * 50;
      blueNum = 234 - (compareValue - 25) / 25 * 37;
    } else {
      redNum = 2;
      greenNum = 79;
      blueNum = 228;
    }
  }

  // var itemIndex = items.findIndex((testItem) => testItem.CollectionId === item.CollectionId) + 1;

  return (
    //    isMobileLayout ?

    <Grid item justify="center" alignItems="center" xs={4} key={item.Name + item.CollectionId + item.CollectionType} className="grid-item" style={{
      padding: '3px', minWidth: '324px', position: 'relative', flex: 4, flexGrow: 'grow', maxWidth: '100%'
    }}>

      {/* <div key={item.CollectionId}> */}
      <Card sx={{
        position: 'relative', transition: 'box-shadow 0.3s',
        boxShadow: alwaysShadow ? '0 8px 16px 0 rgba(0,0,0,0.6)' : '0 0 0 0 rgba(0,0,0,0.6)',
        '&:hover': {
          boxShadow: '0 8px 16px 0 rgba(0,0,0,0.6)'
        }, height: '100%', background: `linear-gradient(145deg, RGB(${redNum}, ${greenNum}, ${blueNum}) 0%, black 100%)`, padding: '6px', paddingTop: '6px'
      }} >

        <Link to={item?.UrlName !== null && `/item/${item.UrlName}`} style={{ textDecoration: 'none', color: 'inherit', display: 'flex' }}>
          <div
            className="grid-item"
            style={{
              position: 'relative', display: 'contents'
            }}
          >

            {item.DropType == "Discontinued" && <div class="ribbon">DISCONTINUED</div>}
            {item.DropType == "Rare Drop" && <div class="orangeRibbon">RARE DROP</div>}
            {item.DropType == "Active Drop" && <div class="greenRibbon">ACTIVE DROP</div>}
            {item.DropType == "In Game Store" && <div class="greenStoreRibbon">IN GAME STORE</div>}
            {item.DropType == "Armory" && <div class="greenArmoryRibbon">ARMORY</div>}


          </div>
          {item.NumItems !== null && <div style={{ position: 'absolute', top: '-4px', right: '4px', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2 }}>
            {item.NumItems > 1 && <span style={{ fontSize: '1.4rem' }}>x{item.NumItems}</span>}
          </div>}
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <span className="sr-only">
              {item.Name} container costs {formatNumber(((pricingSource == "Steam" ? item.CollectionPriceSteam : pricingSource == "CSFloat" ? item.CollectionPriceCSFloat : pricingSource == "Tradeit" ? item.CollectionPriceTradeit : pricingSource == "Buff" ? item.CollectionPriceBuff : item.CollectionPriceSkinport) ?? 0) / exchangeRate, true)}. Unboxing ROI: {formatNumber((pricingSource == "Steam" ? item.SteamROI : pricingSource == "CSFloat" ? item.CSFloatROI : pricingSource == "Tradeit" ? item.TradeitROI : pricingSource == "Buff" ? item.BuffROI : item.SkinportROI) * 100)}%. Profit: {formatNumber((pricingSource == "Steam" ? item.ProfitSteam : pricingSource == "CSFloat" ? item.ProfitCSFloat : pricingSource == "Tradeit" ? item.ProfitTradeit : pricingSource == "Buff" ? item.ProfitBuff : item.ProfitSkinport) * 100)}%. Investing ROI: {formatNumber((
                        pricingSource == "Steam" ? investType == "1m" ? item?.Steam1MiROI : investType == "6m" ? item?.Steam6MiROI : item?.SteamiROI ?? 0 :
                          pricingSource == "CSFloat" ? investType == "1m" ? item?.CSFloat1MiROI : investType == "6m" ? item?.CSFloat6MiROI : item?.CSFloatiROI ?? 0 :
                            pricingSource == "Tradeit" ? investType == "1m" ? item?.Tradeit1MiROI : investType == "6m" ? item?.Tradeit6MiROI : item?.TradeitiROI ?? 0 :
                              pricingSource == "Buff" ? investType == "1m" ? item?.Buff1MiROI : investType == "6m" ? item?.Buff6MiROI : item?.BuffiROI ?? 0 :
                                investType == "1m" ? item?.Skinport1MiROI : investType == "6m" ? item?.Skinport6MiROI : item?.SkinportiROI ?? 0))}%
            </span>

            <div style={{ alignItems: 'center', display: 'flex', flex: '1', padding: '8px',  paddingBottom: '0', flexDirection: 'row', alignContent: 'space-between' }}>
              <CardMedia
                sx={{ height: '140px', minWidth: '140px', backgroundSize: "contain", position: 'relative', marginTop: 'auto', marginBottom: 'auto', marginLeft: '8px' }}
                image={removeSlashSection(item.Image)}
              />
              <div style={{ width: '100%', padding: '4px' }}>
                <h4 style={{ lineHeight: 1.1, margin: '0', width: '100%', textAlign: "center", minHeight: '3.5em', justifyContent: 'center', textAlign: 'center', margin: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center', justifySelf: 'center' }}>{item.Name.split(' | ').map((text, index) => (
                  <React.Fragment key={index}>
                    {text}
                    <br />
                  </React.Fragment>
                ))}
                </h4>
                <Tooltip title={(pricingSource == "Steam" ? item.KeyCostSteam : pricingSource == "CSFloat" ? item.KeyCostCSFloat : pricingSource == "Tradeit" ? item.KeyCostTradeit : pricingSource == "Buff" ? item.KeyCostBuff : item.KeyCostSkinport) == 0 ? "Cost To Unbox" : pricingSource != "Steam" ? "Cost To Unbox, Key Cost has been automatically adjusted to an easily achievable rate if converted to Steam Balance. Check Deals for examples of how to do this." : "Cost To Unbox"}>
                {/* <a href={item?.DropType != "Past Operation" && (pricingSource == "Steam" ? steamLink(item?.Name ?? ''): pricingSource == "Buff" ? buffLink(item?.Name): pricingSource == "Skinport" ? skinportLink(item?.Name) : pricingSource == "Tradeit" ? tradeitLink(item?.Name) : csfloatLink(item?.Name))} target="_blank"> */}
                {item.YourCost == null ? item.CollectionType !== "Collection" && <h6 style={{ lineHeight: 0, margin: 0, marginBottom: '16px', marginTop: 'auto' }}>
                  <h1 style={{ lineHeight: 0.3, textAlign: "center", justifyContent: 'center', padding: '8px', margin: '0.2rem', marginTop: '4px', color: (isCaseEstimate ?? false) ? "rgb(131 131 131)": "" }}>{formatNumber(((pricingSource == "Steam" ? item.CollectionPriceSteam : pricingSource == "CSFloat" ? item.CollectionPriceCSFloat : pricingSource == "Tradeit" ? item.CollectionPriceTradeit : pricingSource == "Buff" ? item.CollectionPriceBuff : item.CollectionPriceSkinport) ?? 0) / exchangeRate, true)}</h1>
                  {(pricingSource == "Steam" ? item.KeyCostSteam : pricingSource == "CSFloat" ? item.KeyCostCSFloat : pricingSource == "Tradeit" ? item.KeyCostTradeit : pricingSource == "Buff" ? item.KeyCostBuff : item.KeyCostSkinport) == 0 ? 
                  item.CollectionType === "Past Operation" ? 
                  <span style={{ fontSize: '10px', lineHeight: 0.2, textAlign: 'center', display: 'block', }}>{Math.ceil(((pricingSource == "Steam" ? item.CollectionPriceSteam : pricingSource == "CSFloat" ? item.CollectionPriceCSFloat : pricingSource == "Tradeit" ? item.CollectionPriceTradeit : pricingSource == "Buff" ? item.CollectionPriceBuff : item.CollectionPriceSkinport) ?? 0) / 0.45)} Stars</span> : 
                  item.CollectionType === "Armory" ? 
                  <span style={{ fontSize: '10px', lineHeight: 0.2, textAlign: 'center', display: 'block', }}>{Math.ceil(((pricingSource == "Steam" ? item.CollectionPriceSteam : pricingSource == "CSFloat" ? item.CollectionPriceCSFloat : pricingSource == "Tradeit" ? item.CollectionPriceTradeit : pricingSource == "Buff" ? item.CollectionPriceBuff : item.CollectionPriceSkinport) ?? 0) / 0.4)} Stars</span> : 
                  <span style={{ fontSize: '10px', lineHeight: 0.2, textAlign: 'center', display: 'block', visibility: 'hidden' }}>+{formatNumber(((pricingSource == "Steam" ? item.KeyCostSteam : pricingSource == "CSFloat" ? item.KeyCostCSFloat : pricingSource == "Tradeit" ? item.KeyCostTradeit : pricingSource == "Buff" ? item.KeyCostBuff : item.KeyCostSkinport) ?? 0) / exchangeRate, true)} Key </span> : 
                  <span style={{ fontSize: '10px', lineHeight: 0.2, textAlign: 'center', display: 'block', }}>+{formatNumber(((pricingSource == "Steam" ? item.KeyCostSteam : pricingSource == "CSFloat" ? item.KeyCostCSFloat : pricingSource == "Tradeit" ? item.KeyCostTradeit : pricingSource == "Buff" ? item.KeyCostBuff : item.KeyCostSkinport) ?? 0) / exchangeRate, true)} Key</span>}
                </h6> :
                  <h6 style={{ lineHeight: 0, margin: 0, marginBottom: '16px', marginTop: 'auto' }}>
                    <h1 style={{ lineHeight: 0.3, textAlign: "center", justifyContent: 'center', paddingTop: '8px', paddingBottom: '8px', margin: '0.2rem', marginTop: '4px' }}>{item.CollectionPriceSteam == 0.01 ? "Drop" : formatNumber((item.YourCost) / exchangeRate, true)} → {formatNumber((item.YourReturn) / exchangeRate, true)}</h1>
                    {<span style={{ fontSize: '14px', lineHeight: 0.9, textAlign: 'center', display: 'block', color: (item.YourCost - item.YourReturn) > 0 ? "red" : "limegreen" }}>{(item.YourCost - item.YourReturn) > 0 ? "-" : "+"}{formatNumber(Math.abs(item.YourCost - item.YourReturn) / exchangeRate, true)}</span>}

                    {/* <h1 style={{ lineHeight: 0.3, textAlign: "center", justifyContent: 'center', padding: '8px', margin: '0.2rem', marginTop: '4px' }}></h1> */}

                  </h6>
                }
                </Tooltip>
                {/* </a> */}
              </div>


            </div>
            {index != null && <div style={{ position: 'absolute', top: '-4px', right: '4px', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2 }}>
              <span style={{ fontSize: '24px' }}>#{index + 1}</span>
            </div>}



            {/* <h6 style={{ display: "flex", alignItems: "center", lineHeight: 1, margin: 0, paddingTop: '16px'}}>
         <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexWrap: 'wrap' }}>
           UNBOX
         </div>


         <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexWrap: 'wrap' }}>
           INVEST
         </div>
       </h6> */}
            
            <h6 style={{ display: "flex", height: "100%", width: '100%', alignItems: "center", lineHeight: 0.5, padding: '4px', flex: '1', paddingTop: '0', margin: 'auto', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', marginTop: '-2px' }}>

              {item.YourROI != null &&
                <Tooltip title="Your Return on Investment for opening this container" sx={{ margin: 'auto', display: showUnbox ? "initial" : "none", }}>
                  <div style={{ display: showUnbox ? "flex" : "none", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', marginBottom: '8px', marginTop: '8px', fontSize: '0.8rem', lineHeight: 0.9 }}>
                    Your ROI
                    {(sortBy == "unbox" || sortBy == "profit" || sortBy == "dropType" || sortBy == "cost") ? <img src={OpenBox} className="iconSVGMob" style={{ fill: 'green', width: '30px', height: '30px', margin: '2px' }} /> : <img src={OpenBox} className="iconSVGMob" style={{ width: '30px', height: '30px', margin: '2px' }} />}
                    {item.CollectionType !== "Collection" ? <h2 style={{ lineHeight: 0.5, margin: 0 }}>{formatNumber((item.YourROI) * 100)}%</h2> : <h2 style={{ lineHeight: 0.5, margin: 0 }}>{formatNumber((item.YourROI) / 100 / exchangeRate, true)}</h2>}

                  </div>

                </Tooltip>
              }
              {item.YourProfit != null && (item.YourProfit != 1 || item.CollectionType !== "Collection") &&
                <Tooltip title="Percent of unboxings that were profitable" sx={{ margin: 'auto', display: showProfit ? "initial" : "none" }}>
                  <div style={{ display: showProfit ? "flex" : "none", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', marginBottom: '8px', marginTop: '8px', fontSize: '0.8rem', lineHeight: 0.9 }}>
                    Your Profit
                    <PriceCheckIcon className="iconSVG" sx={{ width: '30px', height: '30px', margin: '2px' }} fontSize='large' />
                    <h2 style={{ lineHeight: 0.5, margin: 0 }}>{formatNumber((item.YourProfit) * 100)}%</h2>
                  </div>

                </Tooltip>
              }
              {item.YourROI != null &&
                <div style={{ width: '1px', borderLeft: 'white 2px solid', height: '95%', marginTop: '2px', display: "initial" }}></div>
              }
              <Tooltip title="Return on Investment for opening this container now" sx={{ margin: 'auto', display: showUnbox ? "initial" : "none", }}>
                <div style={{ display: showUnbox ? "flex" : "none", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', marginBottom: '8px', marginTop: '8px', fontSize: '0.8rem', lineHeight: 0.9 }}>
                  {item.YourROI == null ? "Unboxing ROI" : "Expected ROI"}
                  {(sortBy == "unbox" || sortBy == "profit" || sortBy == "dropType" || sortBy == "cost") ? <img src={OpenBox} className="iconSVGMob" style={{ fill: 'green', width: '30px', height: '30px', margin: '2px' }} /> : <img src={OpenBox} className="iconSVGMob" style={{ width: '30px', height: '30px', margin: '2px' }} />}
                  {item.CollectionType !== "Collection" ? <h2 style={{ lineHeight: 0.5, margin: 0 }}>{formatNumber((pricingSource == "Steam" ? item.SteamROI : pricingSource == "CSFloat" ? item.CSFloatROI : pricingSource == "Tradeit" ? item.TradeitROI : pricingSource == "Buff" ? item.BuffROI : item.SkinportROI) * 100)}%</h2> : <h2 style={{ lineHeight: 0.5, margin: 0 }}>{formatNumber((pricingSource == "Steam" ? item.SteamROI : pricingSource == "CSFloat" ? item.CSFloatROI : pricingSource == "Tradeit" ? item.TradeitROI : pricingSource == "Buff" ? item.BuffROI : item.SkinportROI) / 100 / exchangeRate, true)}</h2>}

                </div>

              </Tooltip>
              <Tooltip title="Chance for each unbox to be worth more than the cost of opening" sx={{ margin: 'auto', display: (showProfit && ((pricingSource == "Steam" ? item.ProfitSteam : pricingSource == "CSFloat" ? item.ProfitCSFloat : pricingSource == "Tradeit" ? item.ProfitTradeit : pricingSource == "Buff" ? item.ProfitBuff : item.ProfitSkinport) < 0.99)) ? "initial" : "none" }}>
                <div style={{ display: (showProfit && ((pricingSource == "Steam" ? item.ProfitSteam : pricingSource == "CSFloat" ? item.ProfitCSFloat : pricingSource == "Tradeit" ? item.ProfitTradeit : pricingSource == "Buff" ? item.ProfitBuff : item.ProfitSkinport) < 0.99)) ? "flex" : "none", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', marginBottom: '8px', marginTop: '8px', fontSize: '0.8rem', lineHeight: 0.9 }}>
                  {item.YourROI == null ? "Profit" : "Expected Profit"}
                  <PriceCheckIcon className="iconSVG" sx={{ width: '30px', height: '30px', margin: '2px' }} fontSize='large' />
                  <h2 style={{ lineHeight: 0.5, margin: 0 }}>{formatNumber((pricingSource == "Steam" ? item.ProfitSteam : pricingSource == "CSFloat" ? item.ProfitCSFloat : pricingSource == "Tradeit" ? item.ProfitTradeit : pricingSource == "Buff" ? item.ProfitBuff : item.ProfitSkinport) * 100)}%</h2>
                </div>

              </Tooltip>

              {/* <span style={{ fontSize: '11px', lineHeight: 1, textAlign: 'center', display: 'block', marginTop: '4px' }}></span> */}


              {/* <div>
           <h2 style={{ lineHeight: 0.3, textAlign: "center", justifyContent: 'center', padding: '8px', margin: '0.5rem' }}>{currencySymbol}{(((pricingSource == "Steam" ? item.CollectionPriceSteam : item.CollectionPriceBuff) ?? 0) / exchangeRate).toFixed(2)}</h2>
           {item.KeyCost == 0 ? <span></span> : <span style={{ fontSize: '12px', lineHeight: 0, textAlign: 'center', display: 'block' }}>+{currencySymbol}{(((pricingSource == "Steam" ? item.KeyCost : item.KeyCost) ?? 0) / exchangeRate).toFixed(2)} Key</span>}
         </div> */}

              {/*<div style={{ flex: 1 }}></div>  Spacer */}
              <div style={{ width: '1px', borderLeft: 'white 2px solid', height: '95%', marginTop: '2px', display: showInvest ? pricingSource !== "Tradeit" ? "initial" : "none" : "none", }}></div>
              <Tooltip style={{ margin: 'auto', display: showInvest ? pricingSource !== "Tradeit" ? "initial" : "none" : "none" }} title={<p>{((item.DropType == "In Game Store") ? 'This container is in the "In Game Store". It has a fixed price from Valve for the foreseeable future and should have an investing ROI of 0%. The investing ROI provided in these cases is based on the value of the items within and how that has changed over the specific time period.' : "")}{(item.DropType == "In Game Store") && (<br />)}{(item.DropType == "In Game Store") && (<br />)}Return on Investment if you had purchased this container around {(investType == "1m" ? "a month" : investType == "6m" ? "6 months" : "a year") + " ago. "}</p>}>
                <div style={{
                  display: showInvest ? pricingSource !== "Tradeit" ? "flex" : "none" : "none", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', margin: 'auto', marginBottom: '8px', marginTop: investType == "1m" || investType == "6m" ? '-4px' : '8px', fontSize: '0.8rem', lineHeight: isMobileLayout ? (investType == "1m" || investType == "6m" ? '0.9' : '0.9') : (investType == "1m" || investType == "6m" ? '1' : '0.9')

                }}>
                  {investType == "1m" ? "1M " : investType == "6m" ? "6M " : ""}{(investType == "1m" || investType == "6m") && <br />} Investing ROI
                  {(sortBy == "unbox" || sortBy == "profit" || sortBy == "dropType" || sortBy == "cost") ?
                    (pricingSource == "Steam" ? investType == "1m" ? item?.Steam1MiROI : investType == "6m" ? item?.Steam6MiROI : item?.SteamiROI ?? 0 :
                      pricingSource == "CSFloat" ? investType == "1m" ? item?.CSFloat1MiROI : investType == "6m" ? item?.CSFloat6MiROI : item?.CSFloatiROI ?? 0 :
                        pricingSource == "Tradeit" ? investType == "1m" ? item?.Tradeit1MiROI : investType == "6m" ? item?.Tradeit6MiROI : item?.TradeitiROI ?? 0 :
                          pricingSource == "Buff" ? investType == "1m" ? item?.Buff1MiROI : investType == "6m" ? item?.Buff6MiROI : item?.BuffiROI ?? 0 :
                            investType == "1m" ? item?.Skinport1MiROI : investType == "6m" ? item?.Skinport6MiROI : item?.SkinportiROI ?? 0) >= -0.05 ?
                      <TrendingUpIcon className="iconSVG" fontSize='large' sx={{ width: '30px', height: '30px', margin: '2px', marginTop: investType == "1m" || investType == "6m" ? '0px' : '2px', marginBottom: investType == "1m" || investType == "6m" ? '0px' : '2px' }} /> : <TrendingDownIcon className="iconSVG" fontSize='large' sx={{ width: '30px', height: '30px', margin: '2px', marginTop: investType == "1m" || investType == "6m" ? '0px' : '2px' }} /> :
                    (pricingSource == "Steam" ? investType == "1m" ? item?.Steam1MiROI : investType == "6m" ? item?.Steam6MiROI : item?.SteamiROI ?? 0 :
                      pricingSource == "CSFloat" ? investType == "1m" ? item?.CSFloat1MiROI : investType == "6m" ? item?.CSFloat6MiROI : item?.CSFloatiROI ?? 0 :
                        pricingSource == "Tradeit" ? investType == "1m" ? item?.Tradeit1MiROI : investType == "6m" ? item?.Tradeit6MiROI : item?.TradeitiROI ?? 0 :
                          pricingSource == "Buff" ? investType == "1m" ? item?.Buff1MiROI : investType == "6m" ? item?.Buff6MiROI : item?.BuffiROI ?? 0 :
                            investType == "1m" ? item?.Skinport1MiROI : investType == "6m" ? item?.Skinport6MiROI : item?.SkinportiROI ?? 0) >= -0.05 ? <TrendingUpIcon className="iconSVG" fontSize='large' style={{ fill: 'green', width: '30px', height: '30px', margin: '2px', marginTop: investType == "1m" || investType == "6m" ? '0px' : '2px' }} /> : <TrendingDownIcon className="iconSVG" fontSize='large' style={{ fill: 'rgb(187, 23, 26)', width: '30px', height: '30px', margin: '2px', marginTop: investType == "1m" || investType == "6m" ? '0px' : '2px' }} />}

                  {(sortBy == "unbox" || sortBy == "profit" || sortBy == "dropType" || sortBy == "cost") ?
                    <h2 style={{ lineHeight: 0.5, margin: 0 }}>{formatNumber((
                      pricingSource == "Steam" ? investType == "1m" ? item?.Steam1MiROI : investType == "6m" ? item?.Steam6MiROI : item?.SteamiROI ?? 0 :
                        pricingSource == "CSFloat" ? investType == "1m" ? item?.CSFloat1MiROI : investType == "6m" ? item?.CSFloat6MiROI : item?.CSFloatiROI ?? 0 :
                          pricingSource == "Tradeit" ? investType == "1m" ? item?.Tradeit1MiROI : investType == "6m" ? item?.Tradeit6MiROI : item?.TradeitiROI ?? 0 :
                            pricingSource == "Buff" ? investType == "1m" ? item?.Buff1MiROI : investType == "6m" ? item?.Buff6MiROI : item?.BuffiROI ?? 0 :
                              investType == "1m" ? item?.Skinport1MiROI : investType == "6m" ? item?.Skinport6MiROI : item?.SkinportiROI ?? 0))}%</h2>
                    : (pricingSource == "Steam" ? investType == "1m" ? item?.Steam1MiROI : investType == "6m" ? item?.Steam6MiROI : item?.SteamiROI ?? 0 :
                      pricingSource == "CSFloat" ? investType == "1m" ? item?.CSFloat1MiROI : investType == "6m" ? item?.CSFloat6MiROI : item?.CSFloatiROI ?? 0 :
                        pricingSource == "Tradeit" ? investType == "1m" ? item?.Tradeit1MiROI : investType == "6m" ? item?.Tradeit6MiROI : item?.TradeitiROI ?? 0 :
                          pricingSource == "Buff" ? investType == "1m" ? item?.Buff1MiROI : investType == "6m" ? item?.Buff6MiROI : item?.BuffiROI ?? 0 :
                            investType == "1m" ? item?.Skinport1MiROI : investType == "6m" ? item?.Skinport6MiROI : item?.SkinportiROI ?? 0) >= -0.05 ?
                      <h2 style={{ lineHeight: 0.5, margin: 0, color: 'green' }}>{formatNumber((
                        pricingSource == "Steam" ? investType == "1m" ? item?.Steam1MiROI : investType == "6m" ? item?.Steam6MiROI : item?.SteamiROI ?? 0 :
                          pricingSource == "CSFloat" ? investType == "1m" ? item?.CSFloat1MiROI : investType == "6m" ? item?.CSFloat6MiROI : item?.CSFloatiROI ?? 0 :
                            pricingSource == "Tradeit" ? investType == "1m" ? item?.Tradeit1MiROI : investType == "6m" ? item?.Tradeit6MiROI : item?.TradeitiROI ?? 0 :
                              pricingSource == "Buff" ? investType == "1m" ? item?.Buff1MiROI : investType == "6m" ? item?.Buff6MiROI : item?.BuffiROI ?? 0 :
                                investType == "1m" ? item?.Skinport1MiROI : investType == "6m" ? item?.Skinport6MiROI : item?.SkinportiROI ?? 0))}%</h2> :
                      <h2 style={{ lineHeight: 0.5, margin: 0, color: 'rgb(187, 23, 26)' }}>{formatNumber((
                        pricingSource == "Steam" ? investType == "1m" ? item?.Steam1MiROI : investType == "6m" ? item?.Steam6MiROI : item?.SteamiROI ?? 0 :
                          pricingSource == "CSFloat" ? investType == "1m" ? item?.CSFloat1MiROI : investType == "6m" ? item?.CSFloat6MiROI : item?.CSFloatiROI ?? 0 :
                            pricingSource == "Tradeit" ? investType == "1m" ? item?.Tradeit1MiROI : investType == "6m" ? item?.Tradeit6MiROI : item?.TradeitiROI ?? 0 :
                              pricingSource == "Buff" ? investType == "1m" ? item?.Buff1MiROI : investType == "6m" ? item?.Buff6MiROI : item?.BuffiROI ?? 0 :
                                investType == "1m" ? item?.Skinport1MiROI : investType == "6m" ? item?.Skinport6MiROI : item?.SkinportiROI ?? 0))}%
                      </h2>}
                </div>
              </Tooltip>
              {numListings != 0 && numListings != null &&
                <Tooltip title="Number of listings on Steam for this specific case" style={{ margin: 'auto', display: showListings ? "initial" : "none" }}>
                  <div style={{ display: showListings ? "flex" : "none", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', marginBottom: '8px', marginTop: '8px', fontSize: '0.8rem', lineHeight: 0.9 }}>
                    Listings
                    <LocalShippingIcon className="iconSVG" sx={{ width: '30px', height: '30px', margin: '2px' }} fontSize='large' />
                    <h2 style={{ lineHeight: 0.5, margin: 0 }}>{formatNumber(numListings, false)}</h2>
                  </div>

                </Tooltip>
              }
            </h6>
            {
              (item.RarityValuesSteam != null && item.RarityChances != null && Object.keys(item?.RarityChances)?.length > 1) ?
              // Progress bar with multiple colors represented by the values within rarityvaluesteam
              <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', marginLeft: '-5px', marginRight: '-5px', marginTop: '4px', marginBottom: '-6px' }}>
                <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', width: '100%', height: '5px', borderRadius: '4px', overflow: 'hidden', backgroundColor: 'rgb(0,0,0,0.3)', padding: '0px', flexWrap: 'nowrap' }}>
                {Object.entries(item.RarityChances).map(([rarity, value], index) => {
                  const size = (value * item.RarityValuesSteam[rarity]) / (item.SteamROI * (item.CollectionPriceSteam + item.KeyCostSteam)) * 100;
                  const rarityKey = Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity;
                  return (
                    <div key={index} style={{ backgroundColor: `RGB(${redRarityNums[rarityKey]}, ${greenRarityNums[rarityKey]}, ${blueRarityNums[rarityKey]})`, width: `${size}%`, height: '100%', borderTopLeftRadius: index == 0 ? '4px' : '0px', borderBottomLeftRadius: index == 0 ? '4px' : '0px', borderTopRightRadius: index == Object.keys(item.RarityChances).length - 1 ? '4px' : '0px', borderBottomRightRadius: index == Object.keys(item.RarityChances).length - 1 ? '4px' : '0px', overflow: 'hidden' }}></div>
                  );
                })}
                </div>
              </div> :
              <div style={{height: '4px'}}>
              </div>
            }
          </div>


        </Link>
        {/* </div> */}
      </Card>
    </Grid>
    //      :
    //      <Grid item justify="center" alignItems="center" xs={4} key={item.Name + item.CollectionId + item.CollectionType} className="grid-item" style={{
    //        padding: '6px', minWidth: '275px', position: 'relative', flex: 4, flexBasis: '15%', minHeight: '10vw', flexGrow: 'grow'
    //      }}>
    //        {item.DropType == "Discontinued" && <div class="ribbon">DISCONTINUED</div>}
    //        {item.DropType == "Rare Drop" && <div class="orangeRibbon">RARE DROP</div>}
    //        {item.DropType == "Active Drop" && <div class="greenRibbon">ACTIVE DROP</div>}
    //        {item.DropType == "In Game Store" && <div class="greenStoreRibbon">IN GAME STORE</div>}
    //        {/* <div key={item.CollectionId}> */}
    //        <Card sx={{
    //          position: 'relative', transition: 'box-shadow 0.3s',
    //          '&:hover': {
    //            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.6)'
    //          }, height: '100%'
    //        }} >
    //          <a href={`/item/${item.CollectionId + (item.CollectionType == "Souvenir" ? "s" : "")}`} style={{ textDecoration: 'none', color: 'inherit' }}>
    //            <div
    //              className="grid-item"
    //              style={{
    //                background: `linear-gradient(145deg, RGB(${redNum}, ${greenNum}, ${blueNum}) 0%, black 100%)`
    //              }}
    //            >
    //              <CardMedia
    //                sx={{ height: 160, backgroundSize: "contain", }}
    //                image={removeSlashSection(item.Image)}
    //              />
    //              <div style={{ position: 'absolute', top: 0, right: '12px', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2 }}>
    //                <span style={{ fontSize: '24px' }}>#{index + 1}</span>
    //              </div>

    //            </div>
    //            <div style={{ alignItems: 'center', display: 'flex', padding: '8px', }}>
    //              <h3 style={{ lineHeight: 1.1, margin: '0', width: '100%', textAlign: "center", minHeight: '3.5em', justifyContent: 'center', paddingLeft: '8px', paddingRight: '8px', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', justifySelf: 'center' }}>{item.Name.split(' | ').map((text, index) => (
    //                <React.Fragment key={index}>
    //                  {text}
    //                  <br />
    //                </React.Fragment>
    //              ))}
    //              </h3>

    //            </div>
    //            {/* <h6 style={{ display: "flex", alignItems: "center", lineHeight: 1, margin: 0, paddingTop: '16px'}}>
    //      <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexWrap: 'wrap' }}>
    //        UNBOX
    //      </div>


    //      <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexWrap: 'wrap' }}>
    //        INVEST
    //      </div>
    //    </h6> */}
    //            <h5 style={{ display: "flex", alignItems: "center", lineHeight: 1, padding: '4px', paddingBottom: '16px', paddingTop: '0', margin: 0 }}>
    //              <Tooltip title="Return on Investment for opening this container today">
    //                <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', }}>
    //                  Unboxing ROI
    //                  {sortBy == "unbox" ? <img src={OpenBox} className="iconSVG" style={{ fill: 'green' }} /> : <img src={OpenBox} className="iconSVG" />}
    //                  {item.CollectionType !== "Collection" ? <span style={{ fontSize: '1.15rem' }}>{formatNumber((pricingSource == "Steam" ? item.SteamROI : pricingSource == "CSFloat" ? item.CSFloatROI : pricingSource == "Tradeit" ? item.TradeitROI : pricingSource == "Buff" ? item.BuffROI : item.SkinportROI) * 100)}%</span> : <span style={{ fontSize: '1.15rem' }}>${formatNumber((pricingSource == "Steam" ? item.SteamROI : pricingSource == "CSFloat" ? item.CSFloatROI : pricingSource == "Tradeit" ? item.TradeitROI : pricingSource == "Buff" ? item.BuffROI : item.SkinportROI) / 100 / exchangeRate)}</span>}

    //                </div>
    //              </Tooltip>
    //              {/* <div>
    //        <h2 style={{ lineHeight: 0.3, textAlign: "center", justifyContent: 'center', padding: '8px', margin: '0.5rem' }}>{currencySymbol}{(((pricingSource == "Steam" ? item.CollectionPriceSteam : item.CollectionPriceBuff) ?? 0) / exchangeRate).toFixed(2)}</h2>
    //        {item.KeyCost == 0 ? <span></span> : <span style={{ fontSize: '12px', lineHeight: 0, textAlign: 'center', display: 'block' }}>+{currencySymbol}{(((pricingSource == "Steam" ? item.KeyCost : item.KeyCost) ?? 0) / exchangeRate).toFixed(2)} Key</span>}
    //      </div> */}
    //              {item.CollectionType !== "Collection" && <div>
    //                <h1 style={{ lineHeight: 0.3, textAlign: "center", justifyContent: 'center', padding: '8px', margin: '0.5rem', marginTop: '-4px' }}>{currencySymbol}{formatNumber(((pricingSource == "Steam" ? item.CollectionPriceSteam : pricingSource == "Buff" ? item.CollectionPriceBuff : item.CollectionPriceSkinport) ?? 0) / exchangeRate)}</h1>
    //                {item.KeyCost == 0 ? item.CollectionType === "Past Operation" ? <span style={{ fontSize: '11px', lineHeight: 0.2, textAlign: 'center', display: 'block', }}>{((pricingSource == "Steam" ? item.CollectionPriceSteam : pricingSource == "Buff" ? item.CollectionPriceBuff : item.CollectionPriceSkinport) ?? 0) / 0.45} Stars</span> : <span style={{ fontSize: '11px', lineHeight: 0.2, textAlign: 'center', display: 'block', marginBottom: '22px', visibility: 'hidden' }}>+{currencySymbol}{formatNumber((item.KeyCost ?? 0) / exchangeRate)} Key</span> : <span style={{ fontSize: '11px', lineHeight: 0.2, textAlign: 'center', display: 'block', marginBottom: (item.KeyCost != 0 ? '16px' : '0px') }}>+{currencySymbol}{formatNumber((item.KeyCost ?? 0) / exchangeRate)} Key</span>}
    //                <span style={{ fontSize: '12px', lineHeight: 0, textAlign: 'center', display: 'block', marginTop: '20px' }}>{formatNumber((pricingSource == "Steam" ? item.ProfitSteam : pricingSource == "Buff" ? item.ProfitBuff : item.ProfitSkinport) * 100)}% Profit</span>
    //              </div>}
    //              {/*<div style={{ flex: 1 }}></div>  Spacer */}
    //              <Tooltip title="Return on Investment if you had purchased this container a year ago">
    //                <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
    //                  Investing ROI
    //                  {sortBy == "unbox" ? (pricingSource == "Steam" ? investType == "1m" ? item?.Steam1MiROI : investType == "6m" ? item?.Steam6MiROI : item?.SteamiROI ?? 0 :
    //                    pricingSource == "Buff" ? investType == "1m" ? item?.Buff1MiROI : investType == "6m" ? item?.Buff6MiROI : item?.BuffiROI ?? 0 :
    //                      investType == "1m" ? item?.Skinport1MiROI : investType == "6m" ? item?.Skinport6MiROI : item?.SkinportiROI ?? 0) > 0 ? <TrendingUpIcon className="iconSVG" fontSize='large' /> : <TrendingDownIcon className="iconSVG" fontSize='large' /> : (pricingSource == "Steam" ? investType == "1m" ? item?.Steam1MiROI : investType == "6m" ? item?.Steam6MiROI : item?.SteamiROI ?? 0 :
    //                        pricingSource == "Buff" ? investType == "1m" ? item?.Buff1MiROI : investType == "6m" ? item?.Buff6MiROI : item?.BuffiROI ?? 0 :
    //                          investType == "1m" ? item?.Skinport1MiROI : investType == "6m" ? item?.Skinport6MiROI : item?.SkinportiROI ?? 0) > 0 ? <TrendingUpIcon className="iconSVG" fontSize='large' style={{ fill: 'green' }} /> : <TrendingDownIcon className="iconSVG" fontSize='large' style={{ fill: 'rgb(187, 23, 26)' }} />}

    //                  {sortBy == "unbox" ?
    //                    <span style={{ fontSize: '1.15rem' }}>{formatNumber(((pricingSource == "Steam" ? item?.SteamiROI ?? 0 : pricingSource == "Buff" ? item?.BuffiROI ?? 0 : item?.SkinportiROI ?? 0)))}%</span>
    //                    : (pricingSource == "Steam" ? investType == "1m" ? item?.Steam1MiROI : investType == "6m" ? item?.Steam6MiROI : item?.SteamiROI ?? 0 :
    //                      pricingSource == "Buff" ? investType == "1m" ? item?.Buff1MiROI : investType == "6m" ? item?.Buff6MiROI : item?.BuffiROI ?? 0 :
    //                        investType == "1m" ? item?.Skinport1MiROI : investType == "6m" ? item?.Skinport6MiROI : item?.SkinportiROI ?? 0) > 0 ?
    //                      <span style={{ fontSize: '1.15rem', color: 'green' }}>{formatNumber((pricingSource == "Steam" ? investType == "1m" ? item?.Steam1MiROI : investType == "6m" ? item?.Steam6MiROI : item?.SteamiROI ?? 0 :
    //                        pricingSource == "Buff" ? investType == "1m" ? item?.Buff1MiROI : investType == "6m" ? item?.Buff6MiROI : item?.BuffiROI ?? 0 :
    //                          investType == "1m" ? item?.Skinport1MiROI : investType == "6m" ? item?.Skinport6MiROI : item?.SkinportiROI ?? 0))}%</span> :
    //                      <span style={{ fontSize: '1.15rem', color: 'rgb(187, 23, 26)' }}>{formatNumber((pricingSource == "Steam" ? investType == "1m" ? item?.Steam1MiROI : investType == "6m" ? item?.Steam6MiROI : item?.SteamiROI ?? 0 :
    //                        pricingSource == "Buff" ? investType == "1m" ? item?.Buff1MiROI : investType == "6m" ? item?.Buff6MiROI : item?.BuffiROI ?? 0 :
    //                          investType == "1m" ? item?.Skinport1MiROI : investType == "6m" ? item?.Skinport6MiROI : item?.SkinportiROI ?? 0))}%
    //                      </span>}

    //                </div>
    //              </Tooltip>
    //            </h5>

    //          </a>
    //          {/* </div> */}
    //        </Card>
    //      </Grid>

  )
};