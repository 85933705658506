import React from 'react';
import { Backdrop, Paper, IconButton, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Drawer, Select, MenuItem, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { BarChart, Bar, XAxis, YAxis } from 'recharts';
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CookieConsent from "react-cookie-consent";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import CssBaseline from '@mui/material/CssBaseline';
import Scrollbars from 'react-custom-scrollbars-2';
import { Card, CardMedia, Chip, FormControl, Icon } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { ToggleButtonGroup, ToggleButton, FormGroup, FormControlLabel, Checkbox, Autocomplete, Accordion, AccordionSummary, TextField, AccordionDetails } from '@mui/material';
import { CaseGridItem } from './CaseGridItem';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { buffLink, csfloatLink, skinportLink, steamLink, tradeitLink } from './allLinks';
import FloatDropChances from './FloatDropChances';
import VideoController from './VideoController';
import { Link } from 'react-router-dom';

function getWears(minFloat, maxFloat) {
    let fullFloatPercents = {};
    for (let wear of wearNames) {
        let floatRange = maxFloat - minFloat;
        let lowerRange = (wearRangeStarts[wear] ?? 0) - minFloat;
        let higherRange = (wearRanges[wear] ?? 0) - minFloat;
        let eFloatStart = lowerRange / floatRange;
        let eFloatEnd = higherRange / floatRange;
        let totalPercent = 0;
        let floatPercents = {};
        for (let wearName of wearNames) {
            let start = wearRangeStartDrops[wearName] ?? 0;
            let end = wearRanges[wearName] ?? 0;
            if (start < eFloatEnd && end > eFloatStart) {
                let realRange = end - start;
                let insideRange;
                let avgRangeFloat;
                if (eFloatStart < start && eFloatEnd > end) {
                    insideRange = end - start;
                    avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
                } else if (eFloatStart > start && eFloatEnd < end) {
                    insideRange = eFloatEnd - eFloatStart;
                    avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
                } else if (eFloatStart < start) {
                    insideRange = eFloatEnd - start;
                    avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
                } else {
                    insideRange = end - eFloatStart;
                    avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
                }
                let percentRange = insideRange / realRange;
                let percent = percentRange * wearDropRates[wearName];
                totalPercent += percent;
                floatPercents[avgRangeFloat.toFixed(4)] = percent;
            }
        }
        let floatPercentsKeys = Object.keys(floatPercents);
        let totalAvgFloat = 0;
        let totalPercents = 0;
        for (let stringPercent of floatPercentsKeys) {
            let percent = floatPercents[stringPercent];
            totalAvgFloat += (percent / totalPercent) * parseFloat(stringPercent);
            totalPercents += percent;
        }
        fullFloatPercents[wear] = (totalPercents);
    }
    return fullFloatPercents;
}

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));


const wearNames = [
    'Factory New',
    'Minimal Wear',
    'Field-Tested',
    'Well-Worn',
    'Battle-Scarred',
];

const wearRanges = {
    'Factory New': 0.07,
    'Minimal Wear': 0.15,
    'Field-Tested': 0.38,
    'Well-Worn': 0.45,
    'Battle-Scarred': 1,
};

const wearRangeStarts = {
    'Factory New': 0,
    'Minimal Wear': 0.07,
    'Field-Tested': 0.15,
    'Well-Worn': 0.38,
    'Battle-Scarred': 0.45,
    'magic': 1, // not used, but needed for calculation
};

const wearDropRates = {
    'Factory New': 0.03,
    'Minimal Wear': 0.24,
    'Field-Tested': 0.33,
    'Well-Worn': 0.24,
    'Battle-Scarred': 0.16,
};

const wearRangeStartDrops = {
    'Factory New': 0,
    'Minimal Wear': 0.08,
    'Field-Tested': 0.16,
    'Well-Worn': 0.39,
    'Battle-Scarred': 0.46,
};

const redRarityNums = { 'Contraband': 223, 'Covert': 187, 'Classified': 158, 'Restricted': 104, 'Mil_Spec': 2, 'Industrial': 94, 'Consumer': 176 };
const greenRarityNums = { 'Contraband': 199, 'Covert': 23, 'Classified': 0, 'Restricted': 50, 'Mil_Spec': 79, 'Industrial': 152, 'Consumer': 195 };
const blueRarityNums = { 'Contraband': 0, 'Covert': 26, 'Classified': 197, 'Restricted': 234, 'Mil_Spec': 228, 'Industrial': 217, 'Consumer': 217 };
const realWearNames = { 'FactoryNew': 'Factory New', 'MinimalWear': 'Minimal Wear', 'FieldTested': 'Field-Tested', 'WellWorn': 'Well-Worn', 'BattleScarred': 'Battle-Scarred' };
const smallWearNames = { 'Factory New': 'FN', 'Minimal Wear': 'MW', 'Field-Tested': 'FT', 'Well-Worn': 'WW', 'Battle-Scarred': 'BS' }



const otherRarityNames = { 'Extraordinary': 'Covert', 'Exotic': 'Classified', 'Remarkable': 'Restricted', 'High_Grade': 'Mil_Spec', 'Superior': 'Classified', 'Master': 'Covert', 'Exceptional': 'Restricted', 'Distinguished': 'Mil_Spec' }


function Skin() {
    // const itemWears = getWears(caseData['Items'][item]['FloatMin'] / 100, caseData['Items'][item]['FloatMax'] / 100);
    // const data = Object.entries(itemWears)
    //     .filter(([key, value]) => value !== 0)
    //     .map(([key, value]) => ({
    //         name: key,
    //         value: itemWears[key]
    //     }));
    const [itemsAndCollections, setItemsAndCollections] = useState(null);
    const [skinData, setSkinData] = useState(null);
    const { skinId } = useParams();
    const cachedPricingSource = localStorage.getItem('pricingSource');
    const [pricingSource, setPricingSource] = useState(cachedPricingSource || 'Steam');
    const storedCurrency = localStorage.getItem('currency')
    const cachedUSDExchangeRate = localStorage.getItem('usdExchangeRate');
    const [exchangeRate, setExchangeRate] = useState(cachedUSDExchangeRate || 1);
    const [currency, setCurrency] = useState(storedCurrency || 'usd');
    const [itemName, setItemName] = useState('');
    const storedShowListings = (localStorage.getItem('showListings') ?? "true") == "true";
    const [showListings, setShowListings] = useState(storedShowListings ?? true);
    const storedShowInvest = (localStorage.getItem('showInvest') ?? "true") == "true";
    const [showInvest, setShowInvest] = useState(storedShowInvest ?? true);
    const storedShowUnbox = (localStorage.getItem('showUnbox') ?? "true") == "true";
    const [showUnbox, setShowUnbox] = useState(storedShowUnbox ?? true);
    const storedTradeitHideUnavailable = (localStorage.getItem('tradeitHide') ?? "true") == "true";
    const [tradeitHideUnavailable, setTradeitHideUnavailable] = useState(storedTradeitHideUnavailable ?? true);
    const [currencies, setCurrencies] = useState([]);
    const storedShowProfit = (localStorage.getItem('showProfit') ?? "true") == "true";
    const [showProfit, setShowProfit] = useState(storedShowProfit ?? true);
    const [allTrackedCases, setAllTrackedCases] = useState([]);
    const [theseCases, setTheseCases] = useState([]);
    const [caseData, setCaseData] = useState(null);
    const [rarity, setRarity] = useState(null);
    const [isMobileLayout, setIsMobileLayout] = useState(false);
    var storedSort = localStorage.getItem('searchSortBy');
    storedSort = storedSort == "invest6m" ? "invest" : storedSort == "invest1m" ? "invest" : storedSort;
    const [sortBy, setSortBy] = useState(storedSort || "unbox");
    const storedInvestType = localStorage.getItem('investType');
    const [investType, setInvestType] = useState(storedInvestType || "1y");

    useEffect(() => {
        const fetchData = async () => {

            let url = '/pastData/allTrackedCases.json';

            if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
                url = 'http://localhost:8080/pastData/allTrackedCases.json';
            }

            const response = await fetch(url);
            const text = await response.text();
            const processedData = JSON.parse(text);

            setAllTrackedCases(processedData);
            var allSkinDataNames = skinData?.CollectionData?.map((item) => item.CollectionId);
            var tempTheseCases = processedData.filter((item) => allSkinDataNames.includes(item.Name));
            setTheseCases(tempTheseCases);
            if (tempTheseCases[0] != null) {
                try {
                    let url = `/case/${pricingSource.toLowerCase() + (tempTheseCases[0].UrlName.includes('souvenir') ? 'Souvenir' : '')}/${tempTheseCases[0].CollectionId}/data.json`;

                    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
                        url = `http://localhost:8080/case/${pricingSource.toLowerCase() + (tempTheseCases[0].UrlName.includes('souvenir') ? 'Souvenir' : '')}/${tempTheseCases[0].CollectionId}/data.json`;
                    }

                    const response = await fetch(url);

                    const data = await response.json();
                    console.log(data);
                    setCaseData(data);
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }
        };
        if (skinData != null) {
            fetchData();
        }




    }, [skinData]);

    const handleTradeitHide = (sortTest) => {
        setTradeitHideUnavailable(sortTest);
        localStorage.setItem('tradeitHide', sortTest.toString());
        // setSelectedItem(item);
        // setSelectedRarity(rarity)
    };

    const handleShowUnbox = (sortTest) => {
        setShowUnbox(sortTest);
        localStorage.setItem('showUnbox', sortTest.toString());
        // setSelectedItem(item);
        // setSelectedRarity(rarity)
    };

    const handleShowProfit = (sortTest) => {
        setShowProfit(sortTest);
        localStorage.setItem('showProfit', sortTest.toString());
        // setSelectedItem(item);
        // setSelectedRarity(rarity)
    };

    const handleShowInvest = (sortTest) => {
        setShowInvest(sortTest);
        localStorage.setItem('showInvest', sortTest.toString());
        // setSelectedItem(item);
        // setSelectedRarity(rarity)
    };


    const handleShowListings = (sortTest) => {
        setShowListings(sortTest);
        localStorage.setItem('showListings', sortTest.toString());
        // setSelectedItem(item);
        // setSelectedRarity(rarity)
    };


    const theme = createTheme({
        palette: {
            mode: 'dark',
        },
        typography: {
            fontFamily: 'Barlow',
        }
    });


    useEffect(() => {
        // Initial calculation
        handleResize();

        // Add event listener for resize
        window.addEventListener("resize", handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handlePricingSourceChange = (event, newPricingSource) => {
        localStorage.setItem('pricingSource', newPricingSource.props.value)
        setPricingSource(newPricingSource.props.value);
    };



    const handleResize = () => {
        // Calculate aspect ratio 
        const glanceInfoSize = window.innerWidth * 2 / 11

        // Define the threshold value for aspect ratio to differentiate between mobile and desktop
        const glanceInfoThreshold = 215; // Adjust this value based on your needs

        // Set the state based on the aspect ratio
        setIsMobileLayout(glanceInfoSize < glanceInfoThreshold);
    };

    const CustomizedLabel = (props) => {
        const { x, y, fill, value } = props;
        return (
            isMobileLayout ?
                <text x={x + 32} y={y - 10} textAnchor="middle" fontSize={16} dominantBaseline="middle" fill={"white"}>{(value * 100).toFixed(2)}%</text> :
                <text x={x + 66} y={y - 10} textAnchor="middle" fontSize={16} dominantBaseline="middle" fill={"white"}>{(value * 100).toFixed(2)}%</text>
        );
    };

    function formatNumber(number, isCurrency, maximumFractionDigits = 3) {
        // Use the user's locale
        const userLocale = navigator.language || 'en-US';

        // Create Intl.NumberFormat with user's locale
        if (isCurrency) {
            const formatter = new Intl.NumberFormat(userLocale, {
                //   minimumFractionDigits: 1,
                style: "currency",
                currency: currency,
                maximumFractionDigits: number > 1000 ? 0 : number > 100 ? maximumFractionDigits > 1 ? 1 : maximumFractionDigits : number > 0.01 ? maximumFractionDigits > 2 ? 2 : maximumFractionDigits : maximumFractionDigits > 3 ? 3 : maximumFractionDigits,
            });
            return formatter.format(number);
        } else {
            const formatter = new Intl.NumberFormat(userLocale, {
                //   minimumFractionDigits: 1,
                maximumFractionDigits: number > 1000 ? 0 : number > 100 ? maximumFractionDigits > 1 ? 1 : maximumFractionDigits : number > 0.01 ? maximumFractionDigits > 2 ? 2 : maximumFractionDigits : maximumFractionDigits > 3 ? 3 : maximumFractionDigits,

            });
            return formatter.format(number);
        }

        // Format the number

    }

    useEffect(() => {
        let url = '/itemsandcollections.json';

        if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
            url = 'http://localhost:8080/itemsandcollections.json';
        }

        fetch(url)
            .then(response => response.json())
            .then(data => {
                setItemsAndCollections(data);

                // Assuming skinId contains the keywords separated by "-"
                const keywords = skinId.split('-');
                const itemsArray = Object.keys(data);
                const foundItemKey = itemsArray.find(itemKey =>
                    keywords.every(keyword => itemKey.toLowerCase().includes(keyword))
                );

                if (foundItemKey) {
                    console.log('Found item:', data[foundItemKey]);
                    setSkinData(data[foundItemKey]);
                    setItemName(foundItemKey);
                    setRarity(data[foundItemKey].CollectionData[0].Rarity);
                } else {
                    console.log('No item found with the given keywords.');
                }
            })
            .catch(error => console.error('Error:', error));
    }, [skinId]);




    const handlePaperClick = (event) => {
        event.stopPropagation();
    };

    const itemWears = getWears(skinData?.CollectionData?.[0]?.FloatMin / 100, skinData?.CollectionData?.[0]?.FloatMax / 100);
    // const data = Object.keys(Object.entries(itemWears).filter(([key, value]) => value !== 0)).map((key) => ({
    //   name: key,
    //   value: itemWears[key]
    // }));
    const data = Object.entries(itemWears)
        .filter(([key, value]) => value !== 0)
        .map(([key, value], index) => ({
            name: key,
            value: itemWears[key]
        }));

    return (
        <ThemeProvider theme={theme}>
            <CookieConsent
                location="bottom"
                buttonText="I understand"
                cookieName="garequest"
                style={{ background: "#2B373B", zIndex: 99999 }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
            >
                This website uses cookies for anonymous Google Analytics. By continuing to use this website, you consent to the use of cookies.
            </CookieConsent>
            <title>CSROI.com | Find The Best Cases, Capsules, Armory Rewards and More to Invest and Unbox!</title>
            <meta name="description" content="Find the Unboxing Return, Chance to Profit, Investing Return and Market listings alongside in depth historical data to find YOUR favourite CS2 cases, armory rewards, capsules, souvenirs, patches, collections and more!" />
            <meta name="keywords" content="cs2, csgo, roi, case, capsule, souvenir, sticker, armory, stars, invest, pin, graffiti, autographs, listings, profit, unbox, simulator, history, music kit, collection, operation"></meta>
            <Box sx={{ display: 'flex', padding: 0, transition: "width 5s infinite alternate, height 5s infinite alternate", }}>
                <CssBaseline />
                <Drawer
                    sx={{
                        display: isMobileLayout ? 'none' : 'initial',
                        width: drawerWidth,
                        // flexShrink: 0,

                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="permanent"
                    anchor="left"
                // open={openDrawer}
                >
                    <Scrollbars
                        autoHide
                        // autoHideTimeout={1000}
                        // autoHideDuration={200}
                        style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
                        renderView={props => <div {...props} style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }} />}
                        renderThumbVertical={({ style }) => (
                            <div style={{ ...style, backgroundColor: 'lightgray', borderRadius: '4px' }} />
                        )}
                    >
                        <DrawerHeader sx={{ paddingTop: '12px', paddingBottom: '12px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: 'auto', marginBottom: '6px', marginTop: '6px', }}>
                                <img src="/csroi.png" class="p-2" style={{ maxWidth: '280px', width: '100%', height: "auto", objectFit: 'contain', margin: 'auto' }} />

                            </div>


                        </DrawerHeader>
                        <Divider style={{ borderWidth: '2px' }} />
                        {/* <h5 style={{ textAlign: 'center', maxHeight: '180px', padding: '4px' }}>
              Welcome to the all new CSROI.com!<br /><br />
              Featuring a new overhauled React UI, full currency conversion, a new Investing ROI, upgraded simulator, among many more!<br /><br />
              Any feedback is appreciated <a href="https://twitter.com/BrongoCS" target='_blank'>@BrongoCS</a>
            </h5> */}
                        {/* <Divider /> */}
                        {/* <iframe width={drawerWidth} height="180" src="https://www.youtube.com/embed/mMCqwpVC2Zc?si=1DY9BEJKcLe66qy3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;" allowFullScreen ></iframe> */}
                        <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.12)' }}>
                                <p>
                                    Rankings

                                </p>
                            </div>
                        </Link>
                        <Divider style={{ borderWidth: '2px' }} />
                        <Link to='/armory' style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer' }}>
                                <p style={{ lineHeight: '0.9' }}>
                                    Armory
                                    <br />
                                    <span style={{ fontSize: '0.7rem' }}>Find the best way to spend YOUR Armory Stars</span>
                                </p>
                                <Chip label="NEW" variant="outlined" size='small' sx={{ color: 'white', border: '1px white solid', fontSize: '0.8rem', height: '24px', width: '48px', padding: '0' }} />

                            </div>
                        </Link>
                        <Divider />
                        <Link to='/inventory' style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer' }}>
                                <p style={{ lineHeight: '0.9' }}>
                                    Inventory
                                    <br />
                                    <span style={{ fontSize: '0.7rem', }}>Find YOUR Unboxing and Investing ROI!</span>
                                </p>
                                <Chip label="NEW" variant="outlined" size='small' sx={{ color: 'white', border: '1px white solid', fontSize: '0.8rem', height: '24px', width: '48px', padding: '0' }} />
                            </div>
                        </Link>
                        <Divider />
                        <Link to='/deals' style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer' }}>
                                <p style={{ lineHeight: '0.9' }}>
                                    Deals
                                    <br />
                                    <span style={{ fontSize: '0.7rem' }}>Resell Skins to get up to 1.5x Steam Wallet Balance!</span>
                                </p>
                            </div>
                        </Link>
                        <Divider />

                        {/* <p style={{textAlign: 'center', margin: '0'}}> */}
                        <div style={{ flex: '1' }}></div>


                        <Divider />

                        {/* {(!isMobileLayout || openSettings) && <div style={{ display: 'flex', marginTop: '8px', marginBottom: '8px' }}> */}

                        <FormControl sx={{ fontFamily: "'Barlow', 'Roboto', sans-serif", padding: '0 12px 8px 12px', marginTop: '12px', width: '100%' }}>
                            <InputLabel id="pricing-source-label" sx={{ paddingLeft: '14px' }}>Pricing Source</InputLabel>
                            <Select
                                //  renderInput={(params) => <TextField {...params} label="Pricing Source" />}

                                labelId="pricing-source-label"
                                id="pricing-source"
                                value={pricingSource}
                                label="Pricing Source"
                                onChange={handlePricingSourceChange}

                            >
                                <MenuItem value="Steam">Steam</MenuItem>
                                <MenuItem value="Buff">
                                    <div>
                                        Buff.163 / UUPin<Tooltip title="Buff.163 prices are preferred, but UUPin prices are used when Buff.163 prices are unavailable (e.g., containers) or when there is a significant price difference." sx={{ margin: 'auto' }}>

                                            <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '4px', marginBottom: '-4px' }} fontSize='large' />
                                        </Tooltip>
                                    </div>
                                </MenuItem>
                                <MenuItem value="CSFloat">CSFloat</MenuItem>
                                <MenuItem value="Skinport">Skinport</MenuItem>
                                <MenuItem value="Tradeit">
                                    <div>
                                        Tradeit.gg
                                        <Tooltip title="Tradeit.gg pricing reflects the cost to withdraw containers and trade back unboxed items. Estimated prices, marked in grey, are used when specific item prices are unavailable." sx={{ margin: 'auto' }}>

                                            <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '4px', marginBottom: '-4px' }} fontSize='large' />
                                        </Tooltip></div>
                                </MenuItem>
                                {/* Add more pricing source options if needed */}
                            </Select>
                        </FormControl>
                        {
                            pricingSource === "Tradeit" &&
                            <FormGroup sx={{ padding: '0 8px 12px 16px', fontSize: '0.8rem' }}>
                                <FormControlLabel sx={{ fontSize: '0.8rem' }} control={<Checkbox checked={tradeitHideUnavailable} onClick={() => handleTradeitHide(!tradeitHideUnavailable)} />} label="Show Unavailable" />
                            </FormGroup>
                        }
                        <Autocomplete
                            value={currency}
                            onChange={(event, newValue) => setCurrency(newValue)}
                            disableClearable
                            options={currencies}
                            getOptionLabel={(option) => option.toUpperCase()}
                            sx={{ fontFamily: "'Barlow', 'Roboto', sans-serif", padding: '0 12px 12px 12px' }}
                            renderInput={(params) => <TextField {...params} label="Currency" />}
                        />

                        {/* </div>} */}
                        {/* <Divider />
                        {!isMobileLayout && items.length > 0 &&
                            <p style={{
                                margin: '12px',
                                textAlign: 'center',
                                backgroundColor: "#121212",
                                border: 'rgba(255, 255, 255, 0.23) 1px solid',
                                color: 'white',
                                padding: '.25rem .75rem',
                                borderRadius: '0.25rem'
                            }} >
                                Updated<br />
                                {(() => {
                                    const updatedAtUTC = items[0]["UpdatedAt"] * 1000;
                                    const now = new Date();
                                    const timeDifference = now.getTime() - updatedAtUTC;
                                    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
                                    const daysDifference = Math.floor(hoursDifference / 24);

                                    let message;
                                    if (daysDifference > 0) {
                                        message = `${daysDifference} day${daysDifference !== 1 ? 's' : ''} ago`;
                                    } else {
                                        // if (hoursDifference > 0) {
                                        message = `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
                                        // } else {
                                        //   message = "Less than an hour ago";
                                        // }
                                    }

                                    return message;
                                })()}
                            </p>


                        } */}

                        <Divider />
                        <div style={{ margin: '12px', }}>
                            <p style={{

                                textAlign: 'center',
                                backgroundColor: "#121212",
                                border: 'rgba(255, 255, 255, 0.23) 1px solid',
                                color: 'white',
                                padding: '.25rem .75rem',
                                borderRadius: '0.25rem', textAlign: "center", margin: "auto", color: "white",
                            }}>A Project By Brongo: <br />
                                <div style={{ display: 'flex' }}>
                                    <a href="https://twitter.com/BrongoCS" target="_blank" style={{ flex: 1 }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 1200 1227" fill="none">
                                        <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" fill="white"></path>
                                    </svg></a>
                                    <a href="https://www.youtube.com/@BrongoCS" target="_blank" style={{ flex: 1 }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 576 512" fill="none"><path fill="white" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path></svg></a>
                                    <a href="https://steamcommunity.com/tradeoffer/new/?partner=83942777&amp;token=Z_ZUTuBY" style={{ flex: 1 }}><img src="/assets/steamlogo.png" style={{ width: '20px', height: '20px' }} /></a>
                                </div>
                            </p>
                        </div>
                        <Divider />
                        <Accordion sx={{ background: '#121212', padding: '4px 16px 4px 16px', margin: 0 }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                key="Float Drop Chances"
                                sx={{ padding: '0', backgroundColor: '#121212' }}
                            >
                                <Typography>Advanced Settings</Typography>

                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: '0', backgroundColor: '#121212' }}>
                                <FormControlLabel control={<Checkbox onClick={() => handleShowUnbox(!showUnbox)} checked={showUnbox} />} label="Show Unboxing ROI" />
                                <FormControlLabel control={<Checkbox onClick={() => handleShowProfit(!showProfit)} checked={showProfit} />} label="Show Profit Chance" />
                                <FormControlLabel control={<Checkbox onClick={() => handleShowInvest(!showInvest)} checked={showInvest} />} label="Show Investing ROI" />
                                <FormControlLabel control={<Checkbox onClick={() => handleShowListings(!showListings)} checked={showListings} />} label="Show Listings" />
                                {/* <FloatDropChances caseData={caseData} exchangeRate={exchangeRate} currency={currency} isMobile={isMobileLayout}/> */}
                            </AccordionDetails>


                        </Accordion>
                        <Divider />
                        <Divider />

                        <div style={{ margin: '12px', display: 'flex', flexDirection: 'row' }} >
                            <p onClick={() => window.location.href = '/#/faq'} style={{

                                textAlign: 'center',
                                backgroundColor: "#121212",
                                border: 'rgba(255, 255, 255, 0.23) 1px solid',
                                color: 'white',
                                padding: '.25rem .75rem',
                                borderRadius: '0.25rem', textAlign: "center", margin: "auto", color: "white", width: '70px', cursor: "pointer",
                            }}>FAQ
                            </p>
                            <p onClick={() => window.location.href = '/#/changelog'} style={{

                                textAlign: 'center',
                                backgroundColor: "#121212",
                                border: 'rgba(255, 255, 255, 0.23) 1px solid',
                                color: 'white',
                                padding: '.25rem .75rem',
                                borderRadius: '0.25rem', textAlign: "center", margin: "auto", color: "white", width: '120px', cursor: "pointer",
                            }}>Change Log
                            </p>
                        </div>


                        <Divider />
                        {/* <h5 style={{ textAlign: 'center', maxHeight: '180px', padding: '4px' }}>
              All Key Costs for third-party marketplaces are now automatically adjusted to reflect an easily achievable cost when converting to Steam Market balance.<br/><br/>
              <a href='/#/deals'>Find the best deals to get Steam Market balance here!</a>
            </h5> */}
                        {/* <h5 style={{ textAlign: 'center', maxHeight: '180px', padding: '4px' }}>
              Buff.163 has removed all containers from their site. They will continue to be tracked for historical purposes. All container prices using Buff.163 pricing from the 19th of May onwards are now instead using 80% of the Steam Market cost, as most users can convert Buff.163 balance to Steam Market balance at that rate or better.
            </h5> */}
                        {/* <Divider /> */}
                        {/* <iframe width={drawerWidth} height="180" src="https://www.youtube.com/embed/mMCqwpVC2Zc?si=1DY9BEJKcLe66qy3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;" allowFullScreen ></iframe> */}
                        <Divider />
                    </Scrollbars>
                </Drawer>
                <Box
                    component="main"
                    sx={{

                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Paper sx={{ p: 2, position: 'relative' }} >
                        <div style={{ display: "flex", flexDirection: "column", width: '100%' }}>
                            <Typography variant="h5" gutterBottom style={{ margin: 'auto', width: '100%', textAlign: 'center' }}>
                                {itemName}
                            </Typography>
                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <Typography variant="h6" gutterBottom style={{ margin: 'auto', width: '100%', textAlign: 'center' }}>
                                    1 in {formatNumber(1 / (skinData?.CollectionData?.[0]?.Chance ?? 1) * (skinData?.CollectionData?.[0]?.NumRarity ?? 1), false)}
                                </Typography>
                                <Typography variant="h6" gutterBottom style={{
                                    margin: 'auto', width: '100%', textAlign: 'center',
                                    // color: (caseData?.ISTradeit ? (rarity === "Contraband" ?
                                    //     (typeof caseData?.KnifeEstimates?.[item] === "object" ?
                                    //         (Object.entries(caseData?.KnifeEstimates?.[item]).filter(([key, value]) => value === true && key.includes("StatTrak")).length * 0.1 +
                                    //             Object.entries(caseData?.KnifeEstimates?.[item]).filter(([key, value]) => value === true && !key.includes("StatTrak")).length <
                                    //             Object.entries(caseData?.KnifeEstimates?.[item]).filter(([key, value]) => value === false && key.includes("StatTrak")).length * 0.1 +
                                    //             Object.entries(caseData?.KnifeEstimates?.[item]).filter(([key, value]) => value === false && !key.includes("StatTrak")).length) :
                                    //         (caseData?.KnifeEstimates?.[item] === true)
                                    //     ) ? "white" : "rgb(131 131 131)" :
                                    //     (typeof caseData?.ItemEstimates?.[rarity]?.[item] === "object" ?
                                    //         (Object.entries(caseData?.ItemEstimates?.[rarity]?.[item]).filter(([key, value]) => value === true && key.includes("StatTrak")).length * 0.1 +
                                    //             Object.entries(caseData?.ItemEstimates?.[rarity]?.[item]).filter(([key, value]) => value === true && !key.includes("StatTrak")).length <
                                    //             Object.entries(caseData?.ItemEstimates?.[rarity]?.[item]).filter(([key, value]) => value === false && key.includes("StatTrak")).length * 0.1 +
                                    //             Object.entries(caseData?.ItemEstimates?.[rarity]?.[item]).filter(([key, value]) => value === false && !key.includes("StatTrak")).length) :
                                    //         (caseData?.ItemEstimates?.[rarity]?.[item] === true)
                                    //     ) ? "white" : "rgb(131 131 131)"
                                    // ) : "white")
                                }}>
                                    {formatNumber((pricingSource == "Steam" ? skinData?.CollectionData?.[0]?.SteamCost?.totalItemValue : pricingSource == "Buff" ? skinData?.CollectionData?.[0]?.BuffCost?.totalItemValue : pricingSource == "Skinport" ? skinData?.CollectionData?.[0]?.SkinportCost?.totalItemValue : pricingSource == "Tradeit" ? skinData?.CollectionData?.[0]?.TradeitCost?.totalItemValue : skinData?.CollectionData?.[0]?.CSFloatCost?.totalItemValue) / exchangeRate, true)} AVG
                                </Typography>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', }}>
                            <div style={{ flex: 1 }}>
                                <div style={{ display: 'flex', flexDirection: 'horizontal', justifyContent: 'center' }}>
                                    <img src={skinData?.CollectionData?.[0]?.ItemImage} style={{ height: '300px', marginLeft: 'auto' }} />
                                    <BarChart style={{ marginRight: 'auto' }} width={128 * data.length} height={300} data={data} layout="horizontal" barCategoryGap={1} margin={{ top: 50, right: 0, left: 0, bottom: 0 }}>
                                        {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                        <YAxis type="number" hide />
                                        <XAxis dataKey="name" type="category" width={150} hide />
                                        {/* <Tooltip /> */}
                                        <Bar dataKey="value" fill="#8884d8" label={CustomizedLabel}>
                                            {/* {data.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={"red"} />
      ))} */}
                                        </Bar>
                                    </BarChart>
                                </div>
                                {skinData != null &&
                                    <TableContainer component={Paper} sx={{}} >
                                        <Table sx={{ width: `${(128 * data.length) + 400}px`, margin: 'auto' }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell></TableCell>
                                                    {Object.entries(skinData?.CollectionData?.[0]?.["SteamCost"] ?? []).filter(([key, value]) => !key.includes("StatTrak") && !key.includes('totalItemValue')).map(([key, value]) => (
                                                        <TableCell key={key} style={{ width: '128px', textAlign: 'center' }}>{realWearNames[key]}</TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {Object.entries(skinData?.CollectionData?.[0]?.[pricingSource == "Steam" ? "SteamCost" : pricingSource == "Buff" ? "BuffCost" : pricingSource == "Skinport" ? "SkinportCost" : pricingSource == "CSFloat" ? "CSFloatCost" : "TradeitCost"]).filter(([key, value]) => key.includes("StatTrak")).length > 0 ?

                                                    <TableRow>
                                                        <TableCell component="th" scope="row" sx={{ color: "rgb(207, 106, 50)" }}>
                                                            ST (10x Rarer)
                                                        </TableCell>
                                                        {Object.entries((skinData?.CollectionData?.[0]?.[pricingSource == "Steam" ? "SteamCost" : pricingSource == "Buff" ? "BuffCost" : pricingSource == "Skinport" ? "SkinportCost" : pricingSource == "CSFloat" ? "CSFloatCost" : "TradeitCost"])).filter(([key, value]) => key.includes("StatTrak")).map(([key, value]) => (
                                                            <TableCell key={key} component="th" scope="row" sx={{
                                                                color: ((skinData?.CollectionData?.[0]?.[pricingSource == "Steam" ? "SteamCost" : pricingSource == "Buff" ? "BuffCost" : pricingSource == "Skinport" ? "SkinportCost" : pricingSource == "CSFloat" ? "CSFloatCost" : "TradeitCost"]?.[key] ?? false) && pricingSource == "Tradeit") ? "rgb(131 131 131)" : "rgb(207, 106, 50)",
                                                                cursor: 'pointer', textAlign: 'center',
                                                            }}
                                                            // onClick={() => window.open(caseData["IsCSFloat"] ? csfloatLink(caseData.CollectionType == "Souvenir" ? "Souvenir " : "" + item, realWearNames[key.replace('StatTrak™ ', '')], true, rarity === 'Contraband') : caseData["ISTradeit"] ? tradeitLink(caseData.CollectionType == "Souvenir" ? "Souvenir " : "" + item, realWearNames[key.replace('StatTrak™ ', '')], true, rarity === 'Contraband') : caseData["IsBuff"] ? buffLink(caseData.CollectionType == "Souvenir" ? "Souvenir " : "" + item, realWearNames[key.replace('StatTrak™ ', '')], true, rarity === 'Contraband') : caseData["IsSkinport"] ? skinportLink(caseData.CollectionType == "Souvenir" ? "Souvenir " : "" + caseData.CollectionType == "Souvenir" ? "Souvenir " : "" + item, caseData, true, realWearNames[key.replace('StatTrak™ ', '')], true, rarity === 'Contraband') : steamLink(caseData.CollectionType == "Souvenir" ? "Souvenir " : "" + caseData.CollectionType == "Souvenir" ? "Souvenir " : "" + item, realWearNames[key.replace('StatTrak™ ', '')], true, rarity === 'Contraband'), "_blank")}
                                                            >

                                                                <div style={{ textDecoration: 'underline', }}>{formatNumber(value / exchangeRate, true)}</div>
                                                                {/* <div style={{ display: 'flex', fontSize: '12px', lineHeight: '0.4', marginTop: '-3px', marginBottom: 'auto', padding: '2px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',  color: (rarity == "Contraband" ? caseData["KnifeROIs"][item] : caseData["ItemROIs"][rarity][item])[key][investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] > -0.01 ? "green" : "rgb(187, 23, 26)" }}>{(rarity == "Contraband" ? caseData["KnifeROIs"][item] : caseData["ItemROIs"][rarity][item])[key][investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] > -0.01 ? <TrendingUpIcon style={{ width: "16px", height: "12px" }} /> : <TrendingDownIcon style={{ width: "16px", height: "12px"  }} />}{((rarity == "Contraband" ? caseData["KnifeROIs"][item] : caseData["ItemROIs"][rarity][item])[key][investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(Math.round((rarity == "Contraband" ? caseData["KnifeROIs"][item] : caseData["ItemROIs"][rarity][item])[key][investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"])), false)}%</div> */}

                                                            </TableCell>
                                                        ))}
                                                    </TableRow> : <div />}
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Normal
                                                    </TableCell>
                                                    {Object.entries((skinData?.CollectionData?.[0]?.[pricingSource == "Steam" ? "SteamCost" : pricingSource == "Buff" ? "BuffCost" : pricingSource == "Skinport" ? "SkinportCost" : pricingSource == "CSFloat" ? "CSFloatCost" : "TradeitCost"] ?? [])).filter(([key, value]) => !key.includes("StatTrak") && !key.includes('totalItemValue')).map(([key, value]) => (
                                                        <TableCell key={key} component="th" scope="row"
                                                            //   onClick={() => window.open(caseData["IsCSFloat"] ? csfloatLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : caseData["ISTradeit"] ? tradeitLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : caseData["IsBuff"] ? buffLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : caseData["IsSkinport"] ? skinportLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, caseData, false, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : steamLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband'))}
                                                            sx={{
                                                                cursor: 'pointer', textAlign: 'center',
                                                                color: ((skinData?.CollectionData?.[0]?.[pricingSource == "Steam" ? "SteamCost" : pricingSource == "Buff" ? "BuffCost" : pricingSource == "Skinport" ? "SkinportCost" : pricingSource == "CSFloat" ? "CSFloatCost" : "TradeitCost"]?.[key] ?? false) && pricingSource == "Tradeit") ? "rgb(131 131 131)" : "white",

                                                            }}>
                                                            <div style={{ textDecoration: 'underline', }}>{formatNumber(value / exchangeRate, true)}</div>
                                                            {/* <div style={{ display: 'flex', fontSize: '12px', lineHeight: '0.4', marginTop: '-3px', marginBottom: 'auto', padding: '2px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',  color: (rarity == "Contraband" ? caseData["KnifeROIs"][item] : caseData["ItemROIs"][rarity][item])[key][investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] > -0.01 ? "green" : "rgb(187, 23, 26)" }}>{(rarity == "Contraband" ? caseData["KnifeROIs"][item] : caseData["ItemROIs"][rarity][item])[key][investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] > -0.01 ? <TrendingUpIcon style={{ width: "16px", height: "12px" }} /> : <TrendingDownIcon style={{ width: "16px", height: "12px"  }} />}{((rarity == "Contraband" ? caseData["KnifeROIs"][item] : caseData["ItemROIs"][rarity][item])[key][investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(Math.round((rarity == "Contraband" ? caseData["KnifeROIs"][item] : caseData["ItemROIs"][rarity][item])[key][investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"])), false)}%</div> */}

                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                                {/* <TableRow>
          <TableCell component="th" rowspan="2" scope="row">
          Total Item Value
          </TableCell>
          <TableCell colSpan={Object.entries((rarity == "Contraband" ? caseData["KnifeCosts"][item]: caseData["ItemCosts"][rarity][item])).filter(([key, value]) => !key.includes("StatTrak")).length}>${totalItemValue.toFixed(2)}</TableCell>
      </TableRow> */}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                            </div>

                        </div>
                        
                        <div style={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'center', overflowX: "scroll" }}>
                            {theseCases.map((caseItem, index) => (
                                <div key={index} style={{ minWidth: '400px', }}>
                                    <CaseGridItem
                                        item={caseItem}
                                        key={caseItem.CollectionId}
                                        index={index}
                                        pricingSource={pricingSource}
                                        currency={currency}
                                        exchangeRate={exchangeRate}
                                        sortBy={sortBy}
                                        isMobileLayout={isMobileLayout}
                                        showUnbox={showUnbox}
                                        showProfit={showProfit}
                                        showInvest={showInvest}
                                        numListings={caseItem.NumListings}
                                        showListings={showListings}
                                        investType={investType}
                                        isCaseEstimate={pricingSource == "Tradeit" ? theseCases[0]?.TradeitEstimateCase : false}
                                    />
                                </div>
                            ))}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Tooltip style={{ margin: 'auto', display: showInvest ? pricingSource !== "Tradeit" ? "initial" : "none" : "none" }} title={<p>Return on Investment if you had purchased this container around {(investType == "1m" ? "a month" : investType == "6m" ? "6 months" : "a year") + " ago. "}</p>}>
                                <div style={{
                                    display: showInvest ? pricingSource !== "Tradeit" ? "flex" : "none" : "none", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', margin: 'auto', marginBottom: '8px', marginTop: investType == "1m" || investType == "6m" ? '-4px' : '8px', fontSize: '1.2rem', lineHeight: isMobileLayout ? (investType == "1m" || investType == "6m" ? '0.9' : '0.9') : (investType == "1m" || investType == "6m" ? '1' : '0.9')

                                }}>
                                    1 Year
                                    <TrendingUpIcon className="iconSVG" fontSize='large' style={{ fill: 'green', width: '48px', height: '48px', margin: '2px' }} />
                                    {/* : <TrendingDownIcon className="iconSVG" fontSize='large' style={{ fill: 'rgb(187, 23, 26)', width: '30px', height: '30px', margin: '2px', marginTop: investType == "1m" || investType == "6m" ? '0px' : '2px' }} />} */}

                                    {
                                        <h2 style={{ lineHeight: 0.5, margin: 0 }}>{0}%
                                        </h2>}
                                </div>
                            </Tooltip>
                            <Tooltip style={{ margin: 'auto', display: showInvest ? pricingSource !== "Tradeit" ? "initial" : "none" : "none" }} title={<p>Return on Investment if you had purchased this container around {(investType == "1m" ? "a month" : investType == "6m" ? "6 months" : "a year") + " ago. "}</p>}>
                                <div style={{
                                    display: showInvest ? pricingSource !== "Tradeit" ? "flex" : "none" : "none", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', margin: 'auto', marginBottom: '8px', marginTop: investType == "1m" || investType == "6m" ? '-4px' : '8px', fontSize: '1.2rem', lineHeight: isMobileLayout ? (investType == "1m" || investType == "6m" ? '0.9' : '0.9') : (investType == "1m" || investType == "6m" ? '1' : '0.9')

                                }}>
                                    6 Months
                                    <TrendingUpIcon className="iconSVG" fontSize='large' style={{ fill: 'green', width: '48px', height: '48px', margin: '2px' }} />
                                    {/* : <TrendingDownIcon className="iconSVG" fontSize='large' style={{ fill: 'rgb(187, 23, 26)', width: '30px', height: '30px', margin: '2px', marginTop: investType == "1m" || investType == "6m" ? '0px' : '2px' }} />} */}

                                    {
                                        <h2 style={{ lineHeight: 0.5, margin: 0 }}>{0}%
                                        </h2>}
                                </div>
                            </Tooltip>
                            <Tooltip style={{ margin: 'auto', display: showInvest ? pricingSource !== "Tradeit" ? "initial" : "none" : "none" }} title={<p>Return on Investment if you had purchased this container around {(investType == "1m" ? "a month" : investType == "6m" ? "6 months" : "a year") + " ago. "}</p>}>
                                <div style={{
                                    display: showInvest ? pricingSource !== "Tradeit" ? "flex" : "none" : "none", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', margin: 'auto', marginBottom: '8px', marginTop: investType == "1m" || investType == "6m" ? '-4px' : '8px', fontSize: '1.2rem', lineHeight: isMobileLayout ? (investType == "1m" || investType == "6m" ? '0.9' : '0.9') : (investType == "1m" || investType == "6m" ? '1' : '0.9')

                                }}>
                                    1 Month
                                    <TrendingUpIcon className="iconSVG" fontSize='large' style={{ fill: 'green', width: '48px', height: '48px', margin: '2px', }} />
                                    {/* : <TrendingDownIcon className="iconSVG" fontSize='large' style={{ fill: 'rgb(187, 23, 26)', width: '30px', height: '30px', margin: '2px', marginTop: investType == "1m" || investType == "6m" ? '0px' : '2px' }} />} */}

                                    {
                                        <h2 style={{ lineHeight: 0.5, margin: 0 }}>{0}%
                                        </h2>}
                                </div>
                            </Tooltip>
                        </div>
                        {skinData != null &&
                            <div>
                                <TableContainer component={Paper} sx={{}} >
                                    <Table sx={{ width: `${(128 * data.length) + 400}px`, margin: 'auto' }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                {Object.entries(skinData?.CollectionData?.[0]?.["SteamCost"] ?? []).filter(([key, value]) => !key.includes('totalItemValue')).map(([key, value]) => (
                                                    <TableCell key={key} style={{ width: '128px', textAlign: 'center' }}>{(key.includes("StatTrak™ ") ? "ST " : "") + smallWearNames[realWearNames[key.replace("StatTrak™ ", "")]]}</TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    Steam
                                                </TableCell>
                                                {Object.entries((skinData?.CollectionData?.[0]?.["SteamCost"])).filter(([key, value]) => !key.includes('totalItemValue')).map(([key, value]) => (
                                                    <TableCell key={key} component="th" scope="row"
                                                        //   onClick={() => window.open(caseData["IsCSFloat"] ? csfloatLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : caseData["ISTradeit"] ? tradeitLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : caseData["IsBuff"] ? buffLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : caseData["IsSkinport"] ? skinportLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, caseData, false, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : steamLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband'))}
                                                        sx={{
                                                            cursor: 'pointer', textAlign: 'center',

                                                        }}>
                                                        <a href={steamLink(itemName ?? "", realWearNames[key.replace("StatTrak™ ", "")], key.includes("StatTrak™ "))} target="_blank">
                                                            <div style={{ textDecoration: 'underline', }}>{formatNumber(value / exchangeRate, true)}</div>
                                                        </a>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    Buff.163/UUPin
                                                </TableCell>
                                                {Object.entries((skinData?.CollectionData?.[0]?.["BuffCost"])).filter(([key, value]) => !key.includes('totalItemValue')).map(([key, value]) => (
                                                    <TableCell key={key} component="th" scope="row"
                                                        //   onClick={() => window.open(caseData["IsCSFloat"] ? csfloatLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : caseData["ISTradeit"] ? tradeitLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : caseData["IsBuff"] ? buffLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : caseData["IsSkinport"] ? skinportLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, caseData, false, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : steamLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband'))}
                                                        sx={{
                                                            cursor: 'pointer', textAlign: 'center',

                                                        }}>
                                                        <a href={buffLink(itemName, realWearNames[key.replace("StatTrak™ ", "")], key.includes("StatTrak™ "))} target="_blank">
                                                            <div style={{ textDecoration: 'underline', }}>{formatNumber(value / exchangeRate, true)}</div>
                                                        </a>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    CSFloat
                                                </TableCell>
                                                {Object.entries((skinData?.CollectionData?.[0]?.["CSFloatCost"])).filter(([key, value]) => !key.includes('totalItemValue')).map(([key, value]) => (
                                                    <TableCell key={key} component="th" scope="row"
                                                        //   onClick={() => window.open(caseData["IsCSFloat"] ? csfloatLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : caseData["ISTradeit"] ? tradeitLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : caseData["IsBuff"] ? buffLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : caseData["IsSkinport"] ? skinportLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, caseData, false, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : steamLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband'))}
                                                        sx={{
                                                            cursor: 'pointer', textAlign: 'center',

                                                        }}>
                                                        <a href={csfloatLink(itemName, realWearNames[key.replace("StatTrak™ ", "")], key.includes("StatTrak™ "))} target="_blank">
                                                            <div style={{ textDecoration: 'underline', }}>{formatNumber(value / exchangeRate, true)}</div>
                                                        </a>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    Skinport
                                                </TableCell>
                                                {Object.entries((skinData?.CollectionData?.[0]?.["SkinportCost"])).filter(([key, value]) => !key.includes('totalItemValue')).map(([key, value]) => (
                                                    <TableCell key={key} component="th" scope="row"
                                                        //   onClick={() => window.open(caseData["IsCSFloat"] ? csfloatLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : caseData["ISTradeit"] ? tradeitLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : caseData["IsBuff"] ? buffLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : caseData["IsSkinport"] ? skinportLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, caseData, false, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : steamLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband'))}
                                                        sx={{
                                                            cursor: 'pointer', textAlign: 'center',

                                                        }}>
                                                        <a href={skinportLink(itemName, null, null, realWearNames[key.replace("StatTrak™ ", "")], key.includes("StatTrak™ "))} target="_blank">
                                                            <div style={{ textDecoration: 'underline', }}>{formatNumber(value / exchangeRate, true)}</div>
                                                        </a>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    Tradeit.gg
                                                </TableCell>
                                                {Object.entries((skinData?.CollectionData?.[0]?.["TradeitCost"])).filter(([key, value]) => !key.includes('totalItemValue')).map(([key, value]) => (
                                                    <TableCell key={key} component="th" scope="row"
                                                        //   onClick={() => window.open(caseData["IsCSFloat"] ? csfloatLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : caseData["ISTradeit"] ? tradeitLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : caseData["IsBuff"] ? buffLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : caseData["IsSkinport"] ? skinportLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, caseData, false, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband') : steamLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, realWearNames[key.replace('StatTrak™ ', '')], false, rarity === 'Contraband'))}
                                                        sx={{
                                                            cursor: 'pointer', textAlign: 'center',

                                                        }}>
                                                        <a href={tradeitLink(itemName, realWearNames[key.replace("StatTrak™ ", "")], key.includes("StatTrak™ "))} target="_blank">
                                                            <div style={{ textDecoration: 'underline', }}>{formatNumber(value / exchangeRate, true)}</div>
                                                        </a>
                                                        {/* <div style={{ display: 'flex', fontSize: '12px', lineHeight: '0.4', marginTop: '-3px', marginBottom: 'auto', padding: '2px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',  color: (rarity == "Contraband" ? caseData["KnifeROIs"][item] : caseData["ItemROIs"][rarity][item])[key][investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] > -0.01 ? "green" : "rgb(187, 23, 26)" }}>{(rarity == "Contraband" ? caseData["KnifeROIs"][item] : caseData["ItemROIs"][rarity][item])[key][investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] > -0.01 ? <TrendingUpIcon style={{ width: "16px", height: "12px" }} /> : <TrendingDownIcon style={{ width: "16px", height: "12px"  }} />}{((rarity == "Contraband" ? caseData["KnifeROIs"][item] : caseData["ItemROIs"][rarity][item])[key][investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(Math.round((rarity == "Contraband" ? caseData["KnifeROIs"][item] : caseData["ItemROIs"][rarity][item])[key][investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"])), false)}%</div> */}

                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        }
                        {theseCases[0] != null && caseData != null && skinData != null && itemName != null && rarity != null && (theseCases[0]["CollectionType"] == "Past Operation" || theseCases[0]["CollectionType"] == "Case" || theseCases[0]["CollectionType"] == "Collection" || theseCases[0]["CollectionType"] == "Souvenir" || (theseCases[0]["CollectionType"] == "Armory" && theseCases[0]["Name"].includes("Collection") && !theseCases[0]["Name"].includes("Sticker")) || theseCases[0]["Name"].includes("Limited Edition Item")) && (
                            <Accordion sx={{ padding: '0' }} key={skinId}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    key="Float Drop Chances"
                                >
                                    <Typography>Item & Float Drop Chances</Typography>
                                    <Tooltip title="Select an item and view chances for each wear. The graph shows exactly how these chances are calculated and where wear/float values are most likely to appear. Select a wear and/or set your own AVG, Min and Max Float to view estimated chances for specific floats." sx={{ margin: 'auto' }}>
                                        <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '8px' }} fontSize='large' />
                                    </Tooltip>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FloatDropChances key={skinId} caseData={caseData} exchangeRate={exchangeRate} currency={currency} isMobile={isMobileLayout} itemName={itemName} forceRarity={rarity} />
                                </AccordionDetails>
                            </Accordion>
                        )}
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>


    );
}

export default Skin;