import * as React from 'react';
import CookieConsent from "react-cookie-consent";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ToggleButtonGroup, ToggleButton, FormGroup, FormControlLabel, Checkbox, ButtonGroup, Button, Chip } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OpenBox from './OpenBox.svg';
import './Rankings.css';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { ArrowForwardRounded, AttachMoney, LocalShipping, PriceCheck } from '@mui/icons-material';
import SortIcon from '@mui/icons-material/Sort';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { buffLink, csfloatLink, skinportLink, steamLink, tradeitLink } from './allLinks';
import Slider from '@mui/material/Slider';
import Drawer from '@mui/material/Drawer';
import Scrollbars from 'react-custom-scrollbars-2';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import Box from '@mui/material/Box';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import './Armory.css';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';


function ArmoryPage() {
  const [open, setOpen] = React.useState(true);
  const [currencies, setCurrencies] = useState([]);
  const [rangeMax, setRangeMax] = useState(10000);
  const cachedSearch = localStorage.getItem('searchDeals');
  const [inputValue, setInputValue] = useState(cachedSearch || '');
  const [isMobileLayout, setIsMobileLayout] = useState(false);
  const storedSortDirection = (localStorage.getItem('sortDirectionDeals') ?? "true") == "true";
  const cachedPricingSource = localStorage.getItem('pricingSource');
  const [pricingSource, setPricingSource] = useState(cachedPricingSource || 'Steam');
  const cachedStarsHourly = localStorage.getItem('starsPerHour');
  const [starsPerHour, setStarsPerHour] = useState(cachedStarsHourly || 10);
  const storedCurrency = localStorage.getItem('currency')
  const cachedUSDExchangeRate = localStorage.getItem('usdExchangeRate');
  const [exchangeRate, setExchangeRate] = useState(cachedUSDExchangeRate || 1);
  const [currency, setCurrency] = useState(storedCurrency || 'usd');
  const [initialItems, setInitialItems] = useState([
  ]);
  const storedShowFees = (localStorage.getItem('showFees') ?? "false") == "true";
  const [showFees, setShowFees] = useState(storedShowFees ?? false);
  const storedCustomStarsOn = (localStorage.getItem('customStarsOn') ?? "false") == "true";
  const [customStarsOn, setCustomStarsOn] = useState(storedCustomStarsOn ?? false);
  const storedCustomStars = Number.parseInt(localStorage.getItem('customStars'), 10);
  const customStarsValue = isNaN(storedCustomStars) ? 10 : storedCustomStars;
  const [customStars, setCustomStars] = useState(customStarsValue);
  const [customStarString, setCustomStarString] = useState(customStarsValue.toString());
  const redRarityNums = { 'Contraband': 223, 'Covert': 187, 'Classified': 158, 'Restricted': 104, 'Mil_Spec': 2, 'Industrial': 94, 'Consumer': 176 };
  const greenRarityNums = { 'Contraband': 199, 'Covert': 23, 'Classified': 0, 'Restricted': 50, 'Mil_Spec': 79, 'Industrial': 152, 'Consumer': 195 };
  const blueRarityNums = { 'Contraband': 0, 'Covert': 26, 'Classified': 197, 'Restricted': 234, 'Mil_Spec': 228, 'Industrial': 217, 'Consumer': 217 };
  const marketFees = { 'Steam': '0.13', 'Buff': '0.025', 'CSFloat': '0.02', 'Skinport': '0.12', 'Tradeit': '0.1' };
  const otherRarityNames = { 'Extraordinary': 'Covert', 'Exotic': 'Classified', 'Remarkable': 'Restricted', 'High_Grade': 'Mil_Spec', 'Superior': 'Classified', 'Master': 'Covert', 'Exceptional': 'Restricted', 'Distinguished': 'Mil_Spec' }
  const [marketRatio, setMarketRatio] = useState(1);


  const handlePricingSourceChange = (event, newPricingSource) => {
    localStorage.setItem('pricingSource', newPricingSource.props.value)
    setPricingSource(newPricingSource.props.value);
  };

  const handleStarsPerHourChange = (event, newStarsPerHour) => {
    localStorage.setItem('starsPerHour', newStarsPerHour.props.value)
    setStarsPerHour(newStarsPerHour.props.value);
  };

  const handleFees = (sortTest) => {
    setShowFees(sortTest);
    localStorage.setItem('showFees', sortTest.toString());
  };

  const handleCustomStarsOn = (sortTest) => {
    setCustomStarsOn(sortTest);
    localStorage.setItem('customStarsOn', sortTest.toString());
  };

  const handleCustomStars = (sortTest) => {
    setCustomStarString(sortTest.toString());
    const parsedValue = Number.parseInt(sortTest, 10);
    const validValue = isNaN(parsedValue) ? 10 : parsedValue;
    setCustomStars(validValue);
    localStorage.setItem('customStars', validValue.toString());
  };


  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

  function formatNumber(number, isCurrency) {
    // Use the user's locale
    const userLocale = navigator.language || 'en-US';

    // Create Intl.NumberFormat with user's locale
    if (isCurrency) {
      const formatter = new Intl.NumberFormat(userLocale, {
        //   minimumFractionDigits: 1,
        style: "currency",
        currency: currency,
        maximumFractionDigits: number > 1000 ? 0 : number > 100 ? 1 : 2,
      });
      return formatter.format(number);
    } else {
      const formatter = new Intl.NumberFormat(userLocale, {
        //   minimumFractionDigits: 1,
        maximumFractionDigits: number > 1000 ? 0 : number > 100 ? 1 : 2,
      });
      return formatter.format(number);
    }

    // Format the number

  }

  const CACHE_DURATION = 12 * 60 * 60 * 1000; // Cache duration in milliseconds (12 hours)


  function valueLabelFormat(value) {
    return formatNumber(value, true);
  }

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const cachedCurrencies = localStorage.getItem('currencies');
        const cachedTimestamp = localStorage.getItem('currenciesTimestamp');

        if (
          cachedCurrencies &&
          cachedTimestamp &&
          Date.now() - Number(cachedTimestamp) < CACHE_DURATION
        ) {
          setCurrencies(JSON.parse(cachedCurrencies));
        } else {
          let url = '/currencies.json'

          if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
            url = 'https://csroi.com/currencies.json';
          }

          const response = await fetch(url);
          const data = await response.json();
          const currencyList = Object.keys(data);
          setCurrencies(currencyList);

          localStorage.setItem('currencies', JSON.stringify(currencyList));
          localStorage.setItem('currenciesTimestamp', Date.now().toString());
        }
      } catch (error) {
        console.error('Failed to fetch currencies:', error);
      }
    };

    const updateExchangeRateToUSD = async () => {
      try {
        const cachedCurrency = localStorage.getItem('currency');
        const cachedUSDExchangeRate = localStorage.getItem('usdExchangeRate');
        const cachedTimestamp = localStorage.getItem('usdExchangeRateTimestamp');


        if (
          cachedCurrency &&
          currency == cachedCurrency &&
          cachedUSDExchangeRate &&
          cachedTimestamp &&
          Date.now() - Number(cachedTimestamp) < CACHE_DURATION
        ) {
          setCurrency(cachedCurrency);
          // setPricingSource('USD');
          setExchangeRate(cachedUSDExchangeRate);

        } else {
          const response = await fetch(
            `https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/${currency.toLowerCase()}.json`
          );
          const data = await response.json();
          const usdExchangeRate = data[currency.toLowerCase()]['usd'];
          // console.log(data);

          console.log(currency);
          console.log(usdExchangeRate);

          setCurrency(currency);
          // setPricingSource('USD');
          setExchangeRate(usdExchangeRate);

          localStorage.setItem('currency', currency);
          localStorage.setItem('usdExchangeRate', usdExchangeRate);
          localStorage.setItem('usdExchangeRateTimestamp', Date.now().toString());


        }
      } catch (error) {
        console.error('Failed to update exchange rate to USD:', error);
      }
    };

    fetchCurrencies();
    updateExchangeRateToUSD();
  }, [currency]);

  const handleResize = () => {
    // Calculate aspect ratio 
    const glanceInfoSize = window.innerWidth * 2 / 11

    // Define the threshold value for aspect ratio to differentiate between mobile and desktop
    const glanceInfoThreshold = 215; // Adjust this value based on your needs

    // Set the state based on the aspect ratio
    setIsMobileLayout(glanceInfoSize < glanceInfoThreshold);
  };

  useEffect(() => {
    const fetchData = async () => {

      let url = '/pastData/allTrackedCases.json';

      if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
        url = 'http://localhost:8080/pastData/allTrackedCases.json';
      }

      const response = await fetch(url);
      const text = await response.text();
      const processedData = JSON.parse(text);


      // Filter the data to include only items with DropType "Armory"
      const filteredData = processedData.filter(item => item.DropType === "Armory");
      // Set the market ratio by dividing the sticker cost by 0.4 to compare to steam
      const tempMarketItem = filteredData[filteredData.findIndex(item => item.Name === "Elemental Craft Stickers Collection")]
      const tempMarketPrice = (pricingSource == "Steam" ? tempMarketItem.CollectionPriceSteam : pricingSource == "CSFloat" ? tempMarketItem.CollectionPriceCSFloat : pricingSource == "Tradeit" ? tempMarketItem.CollectionPriceTradeit : pricingSource == "Buff" ? tempMarketItem.CollectionPriceBuff : tempMarketItem.CollectionPriceSkinport);
      setMarketRatio(tempMarketPrice / 0.4);
      // Find the gallery case, and move it to the position based on Star ROI which is ((pricingSource == "Steam" ? item.CollectionPriceSteam : pricingSource == "CSFloat" ? item.CollectionPriceCSFloat : pricingSource == "Tradeit" ? item.CollectionPriceTradeit : pricingSource == "Buff" ? item.CollectionPriceBuff : item.CollectionPriceSkinport) / 0.8)
      const galleryIndex = filteredData.findIndex(item => item.Name === "Gallery Case");
      if (galleryIndex !== -1) {
        const galleryItem = filteredData[galleryIndex];
        filteredData.splice(galleryIndex, 1);

        // Calculate the Star ROI
        const starROI = (pricingSource == "Steam" ? galleryItem.CollectionPriceSteam :
          pricingSource == "CSFloat" ? galleryItem.CollectionPriceCSFloat :
            pricingSource == "Tradeit" ? galleryItem.CollectionPriceTradeit :
              pricingSource == "Buff" ? galleryItem.CollectionPriceBuff :
                galleryItem.CollectionPriceSkinport) / (0.8 * (tempMarketPrice / 0.4));

        // Find the new position based on Star ROI
        let newPosition = filteredData.findIndex(item => {
          const itemROI = (pricingSource == "Steam" ? item.SteamROI :
            pricingSource == "CSFloat" ? item.CSFloatROI :
              pricingSource == "Tradeit" ? item.TradeitROI :
                pricingSource == "Buff" ? item.BuffROI :
                  item.SkinportROI);
          return starROI > itemROI;
        });

        // If no such position is found, place it at the end
        if (newPosition === -1) {
          newPosition = filteredData.length;
        }

        // Insert the gallery item at the new position
        filteredData.splice(newPosition, 0, galleryItem);
      }
      setInitialItems(filteredData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    // Set the market ratio by dividing the sticker cost by 0.4 to compare to steam
    if (initialItems.length > 1) {
      const tempMarketItem = initialItems[initialItems.findIndex(item => item.Name === "Elemental Craft Stickers Collection")]
      const tempMarketPrice = (pricingSource == "Steam" ? tempMarketItem.CollectionPriceSteam : pricingSource == "CSFloat" ? tempMarketItem.CollectionPriceCSFloat : pricingSource == "Tradeit" ? tempMarketItem.CollectionPriceTradeit : pricingSource == "Buff" ? tempMarketItem.CollectionPriceBuff : tempMarketItem.CollectionPriceSkinport);
      setMarketRatio(tempMarketPrice / 0.4);
      // Find the gallery case, and move it to the position based on Star ROI which is ((pricingSource == "Steam" ? item.CollectionPriceSteam : pricingSource == "CSFloat" ? item.CollectionPriceCSFloat : pricingSource == "Tradeit" ? item.CollectionPriceTradeit : pricingSource == "Buff" ? item.CollectionPriceBuff : item.CollectionPriceSkinport) / 0.8)
      const galleryIndex = initialItems.findIndex(item => item.Name === "Gallery Case");
      if (galleryIndex !== -1) {
        const galleryItem = initialItems[galleryIndex];
        initialItems.splice(galleryIndex, 1);

        // Calculate the Star ROI
        const starROI = (pricingSource == "Steam" ? galleryItem.CollectionPriceSteam :
          pricingSource == "CSFloat" ? galleryItem.CollectionPriceCSFloat :
            pricingSource == "Tradeit" ? galleryItem.CollectionPriceTradeit :
              pricingSource == "Buff" ? galleryItem.CollectionPriceBuff :
                galleryItem.CollectionPriceSkinport) / (0.8 * (tempMarketPrice / 0.4));

        // Find the new position based on Star ROI
        let newPosition = initialItems.findIndex(item => {
          const itemROI = (pricingSource == "Steam" ? item.SteamROI :
            pricingSource == "CSFloat" ? item.CSFloatROI :
              pricingSource == "Tradeit" ? item.TradeitROI :
                pricingSource == "Buff" ? item.BuffROI :
                  item.SkinportROI);
          return starROI > itemROI;
        });

        // If no such position is found, place it at the end
        if (newPosition === -1) {
          newPosition = initialItems.length;
        }

        // Insert the gallery item at the new position
        initialItems.splice(newPosition, 0, galleryItem);
      }
      setInitialItems(initialItems);
    }
  }, [pricingSource])

  useEffect(() => {
    // Initial calculation
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const theme = createTheme({
    palette: {
      mode: 'dark',
    },
    typography: {
      fontFamily: 'Barlow',
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="garequest"
        style={{ background: "#2B373B", zIndex: 99999 }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies for anonymous Google Analytics. By continuing to use this website, you consent to the use of cookies.
      </CookieConsent>
      <Helmet>
        {/* Standard Meta Tags */}
        <title>CSROI Armory | Find the Best Way to Spend Your Stars!</title>
        <meta
          name="description"
          content="Explore all CS2 Armory rewards and discover the best ways to spend your stars. Compare battlepass-like rewards, maximize ROI, and boost your in-game profit with our detailed analysis."
        />
        <meta
          name="keywords"
          content="CS2 armory, CS2 rewards, battlepass rewards, stars, CS2, CS:GO, ROI, invest, gaming rewards, in-game rewards, unboxing, profit, capsule, case, armory, tradeit, buff, csfloat, skinport"
        />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="CSROI Armory | Spend Your Stars Wisely in CS2" />
        <meta
          property="og:description"
          content="Discover and compare CS2 Armory rewards. Find the best way to spend your stars to maximize battlepass rewards and boost your in-game ROI."
        />
        <meta property="og:image" content="https://csroi.com/logo512.png" />
        <meta property="og:url" content="https://csroi.com/#/armory" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="CSROI Armory | Best Way to Spend Your Stars" />
        <meta
          name="twitter:description"
          content="Compare CS2 Armory rewards and discover the smartest ways to invest your stars for maximum ROI in-game."
        />
        <meta name="twitter:image" content="https://csroi.com/logo512.png" />

        {/* Canonical URL */}
        <link rel="canonical" href="https://csroi.com/#/armory" />

        {/* Schema Markup (JSON-LD) */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "CSROI Armory",
            "description": "Explore all CS2 Armory rewards and find the best way to spend your stars. Compare battlepass rewards, maximize ROI, and invest smartly in in-game items.",
            "url": "https://csroi.com/#/armory",
            "image": "https://csroi.com/logo512.png",
            "publisher": {
              "@type": "Organization",
              "name": "CSROI",
              "logo": {
                "@type": "ImageObject",
                "url": "https://csroi.com/logo512.png"
              }
            }
          })}
        </script>
      </Helmet>
      <div style={{ display: isMobileLayout ? 'flex' : 'none', flexDirection: 'column', justifyContent: 'center', margin: 'auto', marginBottom: '6px', marginTop: '6px', pointer: 'cursor' }} onClick={() => window.location.href = '/#/'}>
        <img src="/csroi.png" class="p-2" style={{ maxWidth: '280px', width: '100%', height: "auto", objectFit: 'contain', margin: 'auto' }} />

      </div>
      <div style={{ display: 'flex', padding: 0, transition: "width 5s infinite alternate, height 5s infinite alternate", height: '100%', width: '100%' }}>
        <Drawer
          sx={{
            display: isMobileLayout ? 'none' : 'initial',
            width: "240px",
            // flexShrink: 0,

            '& .MuiDrawer-paper': {
              width: "240px",
              boxSizing: 'border-box',
            },
          }}
          variant="permanent"
          anchor="left"
        // open={openDrawer}
        >
          <Scrollbars
            autoHide
            // autoHideTimeout={1000}
            // autoHideDuration={200}
            renderView={props => <div {...props} style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }} />}
            renderThumbVertical={({ style }) => (
              <div style={{ ...style, backgroundColor: 'lightgray', borderRadius: '4px' }} />
            )}
          >
            <DrawerHeader sx={{ paddingTop: '12px', paddingBottom: '12px' }}>
              <Link to='/' style={{ textDecoration: 'none', color: 'inherit', width: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: 'auto', marginBottom: '6px', marginTop: '6px', cursor: 'pointer' }} >
                  <img src="/csroi.png" class="p-2" style={{ maxWidth: '280px', width: '100%', height: "auto", objectFit: 'contain', margin: 'auto' }} />

                </div>
              </Link>


            </DrawerHeader>
            <Divider />
            {/* <h5 style={{ textAlign: 'center', maxHeight: '180px', padding: '4px' }}>
              Welcome to the all new CSROI.com!<br /><br />
              Featuring a new overhauled React UI, full currency conversion, a new Investing ROI, upgraded simulator, among many more!<br /><br />
              Any feedback is appreciated <a href="https://twitter.com/BrongoCS" target='_blank'>@BrongoCS</a>
            </h5> */}
            {/* <Divider /> */}
            {/* <iframe width={drawerWidth} height="180" src="https://www.youtube.com/embed/mMCqwpVC2Zc?si=1DY9BEJKcLe66qy3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;" allowFullScreen ></iframe> */}
            {/* <Divider /> */}
            <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer' }}>
                <p>
                  Rankings

                </p>
              </div>
            </Link>
            <Divider />
            <Link to='/armory' style={{ textDecoration: 'none', color: 'inherit' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.12)' }}>
                <p style={{ lineHeight: '0.9' }}>
                  Armory
                  <br />
                  <span style={{ fontSize: '0.7rem' }}>Find the best way to spend YOUR Armory Stars</span>
                </p>
                {/* <Chip label="NEW" variant="outlined" size='small' sx={{ color: 'white', border: '1px white solid', fontSize: '0.8rem', height: '24px', width: '48px', padding: '0' }} /> */}

              </div>
            </Link>
            <Divider style={{ borderWidth: '2px' }}  />
            <Link to='/deals' style={{ textDecoration: 'none', color: 'inherit' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer' }}>
                <p style={{ lineHeight: '0.9' }}>
                  Deals
                  <br />
                  <span style={{ fontSize: '0.7rem' }}>Resell Skins to get up to 1.5x Steam Wallet Balance!</span>
                </p>
                <Chip label="NEW" variant="outlined" size='small' sx={{ color: 'white', border: '1px white solid', fontSize: '0.8rem', height: '24px', width: '48px', padding: '0' }} />
              </div>
            </Link>
            <Divider />
            <Link to='/inventory' style={{ textDecoration: 'none', color: 'inherit' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer' }}>
                <p style={{ lineHeight: '0.9' }}>
                  Inventory
                  <br />
                  <span style={{ fontSize: '0.7rem', }}>Find YOUR Unboxing and Investing ROI!</span>
                </p>
                {/* <Chip label="NEW" variant="outlined" size='small' sx={{ color: 'white', border: '1px white solid', fontSize: '0.8rem', height: '24px', width: '48px', padding: '0' }} /> */}
              </div>
            </Link>
            <Divider />
            <Link to='/charmcolors' style={{ textDecoration: 'none', color: 'inherit' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer', }}>
                <p style={{ lineHeight: '0.9' }}>
                  Charm Colors
                  <br />
                  <span style={{ fontSize: '0.7rem' }}>Find the color and pattern of charm to match your skin!</span>
                </p>
                {/* <Chip label="NEW" variant="outlined" size='small' sx={{ color: 'white', border: '1px white solid', fontSize: '0.8rem', height: '24px', width: '48px', padding: '0' }} /> */}

              </div>
            </Link>
            
            <Divider />

            <div style={{ flex: '1' }}></div>
            <Divider />
            <FormControl sx={{ fontFamily: "'Barlow', 'Roboto', sans-serif", padding: '0 12px 8px 12px', marginTop: '12px', width: '100%' }}>
              <InputLabel id="pricing-source-label" sx={{ paddingLeft: '14px' }}>Pricing Source</InputLabel>
              <Select
                //  renderInput={(params) => <TextField {...params} label="Pricing Source" />}

                labelId="pricing-source-label"
                id="pricing-source"
                value={pricingSource}
                label="Pricing Source"
                onChange={handlePricingSourceChange}

              >
                <MenuItem value="Steam">Steam</MenuItem>
                <MenuItem value="Buff">
                  <div>
                    Buff.163 / UUPin<Tooltip title="Buff.163 prices are preferred, but UUPin prices are used when Buff.163 prices are unavailable (e.g., containers) or when there is a significant price difference." sx={{ margin: 'auto' }}>

                      <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '4px', marginBottom: '-4px' }} fontSize='large' />
                    </Tooltip>
                  </div>
                </MenuItem>
                <MenuItem value="CSFloat">CSFloat</MenuItem>
                <MenuItem value="Skinport">Skinport</MenuItem>
                <MenuItem value="Tradeit">
                  <div>
                    Tradeit.gg
                    <Tooltip title="Tradeit.gg pricing reflects the cost to withdraw containers and trade back unboxed items. Estimated prices, marked in grey, are used when specific item prices are unavailable." sx={{ margin: 'auto' }}>

                      <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '4px', marginBottom: '-4px' }} fontSize='large' />
                    </Tooltip></div>
                </MenuItem>
                {/* Add more pricing source options if needed */}
              </Select>
            </FormControl>
            <Autocomplete
              value={currency}
              onChange={(event, newValue) => setCurrency(newValue)}
              disableClearable
              options={currencies}
              getOptionLabel={(option) => option.toUpperCase()}
              sx={{ fontFamily: "'Barlow', 'Roboto', sans-serif", padding: '12px 12px 12px 12px' }}
              renderInput={(params) => <TextField {...params} label="Currency" />}
            />
            {/* <p style={{textAlign: 'center', margin: '0'}}> */}

            {/* {(!isMobileLayout || openSettings) && <div style={{ display: 'flex', marginTop: '8px', marginBottom: '8px' }}> */}

            <Divider />
            {!isMobileLayout && initialItems.length > 0 &&
              <p style={{
                margin: '12px',
                textAlign: 'center',
                backgroundColor: "#121212",
                border: 'rgba(255, 255, 255, 0.23) 1px solid',
                color: 'white',
                padding: '.25rem .75rem',
                borderRadius: '0.25rem'
              }} >
                Updated<br />
                {(() => {
                  const updatedAtUTC = initialItems[0]["UpdatedAt"] * 1000;
                  const now = new Date();
                  const timeDifference = now.getTime() - updatedAtUTC;
                  const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
                  const daysDifference = Math.floor(hoursDifference / 24);

                  let message;
                  if (daysDifference > 0) {
                    message = `${daysDifference} day${daysDifference !== 1 ? 's' : ''} ago`;
                  } else {
                    // if (hoursDifference > 0) {
                    message = `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
                    // } else {
                    //   message = "Less than an hour ago";
                    // }
                  }

                  return message;
                })()}
              </p>


            }
            {/* </div>} */}
            {/* <Divider />
            {!isMobileLayout && items?.length > 0 &&
              <p style={{
                margin: '12px',
                textAlign: 'center',
                backgroundColor: "#121212",
                border: 'rgba(255, 255, 255, 0.23) 1px solid',
                color: 'white',
                padding: '.25rem .75rem',
                borderRadius: '0.25rem'
              }} >
                Updated<br />
                {(() => {
                  const updatedAtUTC = items[0]["UpdatedAt"] * 1000;
                  const now = new Date();
                  const timeDifference = now.getTime() - updatedAtUTC;
                  const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
                  const daysDifference = Math.floor(hoursDifference / 24);

                  let message;
                  if (daysDifference > 0) {
                    message = `${daysDifference} day${daysDifference !== 1 ? 's' : ''} ago`;
                  } else {
                    // if (hoursDifference > 0) {
                    message = `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
                    // } else {
                    //   message = "Less than an hour ago";
                    // }
                  }

                  return message;
                })()}
              </p>


            } */}

            <Divider />
            <div style={{ margin: '12px', }}>
              <p style={{

                textAlign: 'center',
                backgroundColor: "#121212",
                border: 'rgba(255, 255, 255, 0.23) 1px solid',
                color: 'white',
                padding: '.25rem .75rem',
                borderRadius: '0.25rem', textAlign: "center", margin: "auto", color: "white",
              }}>A Project By Brongo: <br />
                <div style={{ display: 'flex' }}>
                  <a href="https://twitter.com/BrongoCS" target="_blank" style={{ flex: 1 }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 1200 1227" fill="none">
                    <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" fill="white"></path>
                  </svg></a>
                  <a href="https://www.youtube.com/@BrongoCS" target="_blank" style={{ flex: 1 }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 576 512" fill="none"><path fill="white" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path></svg></a>
                  <a href="https://steamcommunity.com/tradeoffer/new/?partner=83942777&amp;token=Z_ZUTuBY" style={{ flex: 1 }}><img src="/assets/steamlogo.png" style={{ width: '20px', height: '20px' }} /></a>
                </div>
              </p>
            </div>
            <Divider />

            <div style={{ margin: '12px', display: 'flex', flexDirection: 'row' }} >
              <Link to="/faq" style={{ textDecoration: 'none', marginLeft: 'auto', marginRight: 'auto' }}>
                <p style={{

                  textAlign: 'center',
                  backgroundColor: "#121212",
                  border: 'rgba(255, 255, 255, 0.23) 1px solid',
                  color: 'white',
                  padding: '.25rem .75rem',
                  borderRadius: '0.25rem', textAlign: "center", margin: "auto", color: "white", width: '70px', cursor: "pointer",

                }}>FAQ
                </p>
              </Link>
              <Link to="/changelog" style={{ textDecoration: 'none', marginLeft: 'auto', marginRight: 'auto' }}>
                <p style={{

                  textAlign: 'center',
                  backgroundColor: "#121212",
                  border: 'rgba(255, 255, 255, 0.23) 1px solid',
                  color: 'white',
                  padding: '.25rem .75rem',
                  borderRadius: '0.25rem', textAlign: "center", margin: "auto", color: "white", width: '120px', cursor: "pointer",
                }}>Change Log
                </p>
              </Link>
            </div>

            <Divider />
            {/* <h5 style={{ textAlign: 'center', maxHeight: '180px', padding: '4px' }}>
              All Key Costs for third-party marketplaces are now automatically adjusted to reflect an easily achievable cost when converting to Steam Market balance. This improves ROI calculations.
            </h5> */}
            {/* <h5 style={{ textAlign: 'center', maxHeight: '180px', padding: '4px' }}>
              Buff.163 has removed all containers from their site. They will continue to be tracked for historical purposes. All container prices using Buff.163 pricing from the 19th of May onwards are now instead using 80% of the Steam Market cost, as most users can convert Buff.163 balance to Steam Market balance at that rate or better.
            </h5> */}
            {/* <Divider /> */}
            {/* <iframe width={drawerWidth} height="180" src="https://www.youtube.com/embed/mMCqwpVC2Zc?si=1DY9BEJKcLe66qy3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;" allowFullScreen ></iframe> */}
            <Divider />
          </Scrollbars>
        </Drawer>
        <div style={{ width: '98%' }}>
          {initialItems.length > 0 &&
            <div style={{ margin: '16px', display: 'flex', flexDirection: 'column', height: '99vh', width: '98%', overflowY: 'scroll', overflowX: 'hidden', lineHeight: '12px', fontSize: isMobileLayout ? '0.6rem' : '1rem' }}>
              <div className="sr-only">
                <Typography variant="body1">
                  {"Easily find the best armory options and the risk involved, alongside a " + currency.toUpperCase() + " per hour statistic based on how you play the game. This is just an estimate, and does not take price fluctuations into account"}
                </Typography>
              </div>
              <Accordion defaultExpanded={true} sx={{ margin: '0 !important' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  key="Best Armory Options"
                >
                  <Typography>Best Armory Options</Typography>
                  <Tooltip title={"Easily find the best armory options and the risk involved, alongside a " + currency.toUpperCase() + " per hour statistic based on how you play the game. This is just an estimate, and does not take price fluctuations into account"} sx={{ margin: 'auto', }}>

                    <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '8px' }} fontSize='large' />
                  </Tooltip>

                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ display: isMobileLayout ? 'none' : 'flex', flexDirection: 'row', height: '100px', flexWrap: 'wrap' }}>
                    <div style={{ minWidth: '250px', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', flex: '3' }}>
                      {
                        customStarsOn ?
                          <TextField
                            id="outlined-number"
                            label="Custom ★ per Hour"
                            type="number"
                            value={customStarString}
                            defaultValue={customStars}
                            onChange={(event) => handleCustomStars(event.target.value)}
                            slotProps={{
                              inputLabel: {
                                shrink: true,
                              },
                            }}
                          /> :
                          <FormControl sx={{ fontFamily: "'Barlow', 'Roboto', sans-serif", padding: '0 12px 8px 12px', marginTop: '6px', width: '100%', height: '50px' }}>
                            <InputLabel id="pricing-source-label" sx={{ paddingLeft: '14px' }}>★ per Hour</InputLabel>
                            <Select
                              //  renderInput={(params) => <TextField {...params} label="Pricing Source" />}

                              labelId="pricing-source-label"
                              id="pricing-source"
                              value={starsPerHour}
                              label="★ per Hour"
                              onChange={handleStarsPerHourChange}
                              sx={{ padding: '0' }}

                            >
                              <MenuItem value={8}>8 ★ - Casual (5 Passes)</MenuItem>
                              <MenuItem value={10}>10 ★ - Competitive (5 Passes)</MenuItem>
                              <MenuItem value={11}>11 ★ - Arms Race (5 Passes)</MenuItem>
                              <MenuItem value={12}>12 ★ - Wingman (5 Passes)</MenuItem>
                              <MenuItem value={15}>15 ★ - Deathmatch (5 Passes)</MenuItem>
                              {/* <MenuItem value={15}>15 ★ - Arms Race (5 Passes)</MenuItem> */}

                            </Select>
                          </FormControl>
                      }
                      <div style={{ display: 'flex', flexDirection: 'row', fontSize: '0.8rem', marginTop: '4px' }}>
                        <FormControlLabel style={{ margin: '0' }} control={<Checkbox onClick={() => handleFees(!showFees)} checked={showFees} />} label={<Typography style={{ fontSize: '0.9rem', lineHeight: '1.2' }}>{"Include " + pricingSource + " Fees"}</Typography>} />
                        <FormControlLabel style={{ margin: '0' }} control={<Checkbox onClick={() => handleCustomStarsOn(!customStarsOn)} checked={customStarsOn} />} label={<Typography style={{ fontSize: '0.9rem', lineHeight: '1.2' }}>{"Custom ★ per Hour"}</Typography>} />
                      </div>
                    </div>
                    {(() => {
                      const itemIndex = initialItems.findIndex(item => item.Name === "Gallery Case");
                      const item = initialItems[itemIndex];
                      return (
                        <Link to={item?.UrlName !== null && `/item/${item.UrlName}`} style={{ textDecoration: 'none', color: 'inherit', flex: '3', lineHeight: '1', height: '100%', margin: "0px 4px 4px 4px", flexDirection: 'row', display: 'flex', border: '1.5px solid rgba(255, 255, 255, 0.12)', borderRadius: '4px', justifyContent: 'space-around', alignContent: 'center' }}>
                          <h3 style={{ textAlign: 'center', margin: 'auto' }}>No Risk:
                            <Tooltip title="Outside of price fluctuations you always receive the same item, resulting in no risk." sx={{ margin: 'auto' }}>
                              <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '4px', marginBottom: '-4px' }} fontSize='large' />
                            </Tooltip>
                            <br />{formatNumber(((((((pricingSource == "Steam" ? item.CollectionPriceSteam : pricingSource == "CSFloat" ? item.CollectionPriceCSFloat : pricingSource == "Tradeit" ? item.CollectionPriceTradeit : pricingSource == "Buff" ? item.CollectionPriceBuff : item.CollectionPriceSkinport) * (1 - (showFees ? marketFees[pricingSource] : 0))) - (0.8 * marketRatio)) / 2) * (customStarsOn ? customStars : starsPerHour))) / exchangeRate, true)} per Hour</h3>
                          <img src={item.Image} style={{ margin: 'auto', width: 'auto', height: '100%', maxWidth: '90%', objectFit: 'contain' }} />
                        </Link>
                      );
                    })()}
                    {(() => {
                      const ROILookupName = pricingSource == "Steam" ? "SteamROI" : pricingSource == "CSFloat" ? "CSFloatROI" : pricingSource == "Tradeit" ? "TradeitROI" : pricingSource == "Buff" ? "BuffROI" : "SkinportROI";
                      const RarityValueLookup = pricingSource == "Steam" ? "RarityValuesSteam" : pricingSource == "CSFloat" ? "RarityValuesCSFloat" : pricingSource == "Tradeit" ? "RarityValuesTradeit" : pricingSource == "Buff" ? "RarityValuesBuff" : "RarityValuesSkinport";
                      const CollectionPriceLookupName = pricingSource == "Steam" ? "CollectionPriceSteam" : pricingSource == "Buff" ? "CollectionPriceBuff" : pricingSource == "CSFloat" ? "CollectionPriceCSFloat" : pricingSource == "Skinport" ? "CollectionPriceSkinport" : pricingSource == "Tradeit" ? "CollectionPriceTradeit" : "CollectionPriceSteam";

                      const compareValue = (item) => ((pricingSource == "Steam" ? item.CollectionPriceSteam : pricingSource == "CSFloat" ? item.CollectionPriceCSFloat : pricingSource == "Tradeit" ? item.CollectionPriceTradeit : pricingSource == "Buff" ? item.CollectionPriceBuff : item.CollectionPriceSkinport) / (0.8 * marketRatio));

                      // Find the item with the greatest sum of (rarity value * rarity chance)
                      const highestSumItem = initialItems
                        .filter(item => item.Name !== "Gallery Case")
                        .reduce((maxItem, currentItem) => {
                          // Filter rarities with a chance higher than 0.03 for the current item
                          const currentFilteredRarities = Object.keys(currentItem.RarityChances).filter(rarity => currentItem.RarityChances[rarity] > 0.03);
                          const maxFilteredRarities = Object.keys(maxItem.RarityChances).filter(rarity => maxItem.RarityChances[rarity] > 0.03);

                          // Calculate the number of rewards redeemable with 40 stars:
                          const collectionPrice = currentItem[CollectionPriceLookupName];
                          const maxCollectionPrice = maxItem[CollectionPriceLookupName];

                          const sumRarityChances = currentFilteredRarities.reduce((acc, rarity) => {
                            return acc + currentItem.RarityChances[rarity];
                          }, 0);

                          const sum = currentFilteredRarities.reduce((acc, rarity) => {
                            return acc + ((currentItem[RarityValueLookup][rarity] * ((currentItem.RarityChances[rarity]) / sumRarityChances)) / collectionPrice);
                          }, 0);

                          const maxSumRarityChances = maxFilteredRarities.reduce((acc, rarity) => {
                            return acc + maxItem.RarityChances[rarity];
                          }, 0);

                          const maxSum = maxFilteredRarities.reduce((acc, rarity) => {
                            return acc + ((maxItem[RarityValueLookup][rarity] * ((maxItem.RarityChances[rarity]) / maxSumRarityChances)) / maxCollectionPrice);
                          }, 0);

                          return sum > maxSum ? currentItem : maxItem;
                        }, initialItems[0]);

                      const galleryCase = initialItems.find(item => item.Name === "Gallery Case");
                      // const lowRiskItem = compareValue(galleryCase) > compareValue(highestSumItem) ? galleryCase : highestSumItem;
                      const lowRiskItem = compareValue(galleryCase) > highestSumItem[ROILookupName] ? galleryCase : highestSumItem;


                      const perHourValue = lowRiskItem.Name === "Gallery Case"
                        ? formatNumber(((((((pricingSource == "Steam" ? lowRiskItem.CollectionPriceSteam : pricingSource == "CSFloat" ? lowRiskItem.CollectionPriceCSFloat : pricingSource == "Tradeit" ? lowRiskItem.CollectionPriceTradeit : pricingSource == "Buff" ? lowRiskItem.CollectionPriceBuff : lowRiskItem.CollectionPriceSkinport) * (1 - (showFees ? marketFees[pricingSource] : 0))) - (0.8 * marketRatio)) / 2) * (customStarsOn ? customStars : starsPerHour))) / exchangeRate, true)
                        : formatNumber((((((lowRiskItem[ROILookupName] * (1 - (showFees ? marketFees[pricingSource] : 0)) - 1) * (0.4 * marketRatio)) * (customStarsOn ? customStars : starsPerHour))) / exchangeRate), true);

                      return (
                        <Link to={lowRiskItem?.UrlName !== null && `/item/${lowRiskItem.UrlName}`} style={{ textDecoration: 'none', lineHeight: '1', color: 'inherit', flex: '3', height: '100%', flexDirection: 'row', display: 'flex', border: '1.5px solid rgba(255, 255, 255, 0.12)', borderRadius: '4px', justifyContent: 'space-around', alignContent: 'center' }}>
                          <h3 style={{ textAlign: 'center', margin: 'auto' }}>Low Risk:
                            <Tooltip title="Comparing all armory rewards, this is the best option if you only receive items with a 1 in 32 chance or lower. The per hour figure does include more rare items however." sx={{ margin: 'auto' }}>
                              <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '4px', marginBottom: '-4px' }} fontSize='large' />
                            </Tooltip>
                            <br />
                            {perHourValue} per Hour</h3>
                          <img src={lowRiskItem.Image} style={{ margin: 'auto', width: 'auto', height: '100%', maxWidth: '90%', objectFit: 'contain' }} />
                        </Link>
                      );
                    })()}
                    {(() => {
                      const ROILookupName = pricingSource == "Steam" ? "SteamROI" : pricingSource == "CSFloat" ? "CSFloatROI" : pricingSource == "Tradeit" ? "TradeitROI" : pricingSource == "Buff" ? "BuffROI" : "SkinportROI";
                      const RarityValueLookup = pricingSource == "Steam" ? "RarityValuesSteam" : pricingSource == "CSFloat" ? "RarityValuesCSFloat" : pricingSource == "Tradeit" ? "RarityValuesTradeit" : pricingSource == "Buff" ? "RarityValuesBuff" : "RarityValuesSkinport";
                      const CollectionPriceLookupName = pricingSource == "Steam" ? "CollectionPriceSteam" : pricingSource == "Buff" ? "CollectionPriceBuff" : pricingSource == "CSFloat" ? "CollectionPriceCSFloat" : pricingSource == "Skinport" ? "CollectionPriceSkinport" : pricingSource == "Tradeit" ? "CollectionPriceTradeit" : "CollectionPriceSteam";

                      const compareValue = (item) => ((pricingSource == "Steam" ? item.CollectionPriceSteam : pricingSource == "CSFloat" ? item.CollectionPriceCSFloat : pricingSource == "Tradeit" ? item.CollectionPriceTradeit : pricingSource == "Buff" ? item.CollectionPriceBuff : item.CollectionPriceSkinport) / (0.8 * marketRatio));

                      // Find the item with the greatest sum of (rarity value * rarity chance)
                      const highestSumItem = initialItems
                        .filter(item => item.Name !== "Gallery Case")
                        .reduce((maxItem, currentItem) => {
                          // Filter rarities with a chance higher than 0.005 for the current item
                          const currentFilteredRarities = Object.keys(currentItem.RarityChances).filter(rarity => currentItem.RarityChances[rarity] > 0.005);
                          const maxFilteredRarities = Object.keys(maxItem.RarityChances).filter(rarity => maxItem.RarityChances[rarity] > 0.005);

                          // Calculate the number of rewards redeemable with 40 stars:
                          const collectionPrice = currentItem[CollectionPriceLookupName];
                          const maxCollectionPrice = maxItem[CollectionPriceLookupName];

                          const sumRarityChances = currentFilteredRarities.reduce((acc, rarity) => {
                            return acc + currentItem.RarityChances[rarity];
                          }, 0);

                          const sum = currentFilteredRarities.reduce((acc, rarity) => {
                            return acc + ((currentItem[RarityValueLookup][rarity] * ((currentItem.RarityChances[rarity]) / sumRarityChances)) / collectionPrice);
                          }, 0);

                          const maxSumRarityChances = maxFilteredRarities.reduce((acc, rarity) => {
                            return acc + maxItem.RarityChances[rarity];
                          }, 0);

                          const maxSum = maxFilteredRarities.reduce((acc, rarity) => {
                            return acc + ((maxItem[RarityValueLookup][rarity] * ((maxItem.RarityChances[rarity]) / maxSumRarityChances)) / maxCollectionPrice);
                          }, 0);

                          return sum > maxSum ? currentItem : maxItem;
                        }, initialItems[0]);

                      const galleryCase = initialItems.find(item => item.Name === "Gallery Case");
                      const mediumRiskItem = compareValue(galleryCase) > highestSumItem[ROILookupName] ? galleryCase : highestSumItem;

                      const perHourValue = mediumRiskItem.Name === "Gallery Case"
                        ? formatNumber(((((((pricingSource == "Steam" ? mediumRiskItem.CollectionPriceSteam : pricingSource == "CSFloat" ? mediumRiskItem.CollectionPriceCSFloat : pricingSource == "Tradeit" ? mediumRiskItem.CollectionPriceTradeit : pricingSource == "Buff" ? mediumRiskItem.CollectionPriceBuff : mediumRiskItem.CollectionPriceSkinport) * (1 - (showFees ? marketFees[pricingSource] : 0))) - (0.8 * marketRatio)) / 2) * (customStarsOn ? customStars : starsPerHour))) / exchangeRate, true)
                        : formatNumber(((((((mediumRiskItem[ROILookupName] * (1 - (showFees ? marketFees[pricingSource] : 0))) - 1) * (0.4 * marketRatio)) * (customStarsOn ? customStars : starsPerHour))) / exchangeRate), true);

                      return (
                        <Link to={mediumRiskItem?.UrlName !== null && `/item/${mediumRiskItem.UrlName}`} style={{ textDecoration: 'none', lineHeight: '1', color: 'inherit', flex: '3', margin: "0px 4px 4px 4px", height: '100%', flexDirection: 'row', display: 'flex', border: '1.5px solid rgba(255, 255, 255, 0.12)', borderRadius: '4px', justifyContent: 'space-around', alignContent: 'center' }}>
                          <h3 style={{ textAlign: 'center', margin: 'auto' }}>Medium Risk:
                            <Tooltip title="Comparing all armory rewards, this is the best option if you only receive items with a 1 in 160 chance or lower. The per hour figure does include more rare items however." sx={{ margin: 'auto' }}>
                              <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '4px', marginBottom: '-4px' }} fontSize='large' />
                            </Tooltip>
                            <br />
                            {perHourValue} per Hour</h3>
                          <img src={mediumRiskItem.Image} style={{ margin: 'auto', width: 'auto', height: '100%', maxWidth: '90%', objectFit: 'contain' }} />
                        </Link>
                      );
                    })()}
                    {(() => {
                      const ROILookupName = pricingSource == "Steam" ? "SteamROI" : pricingSource == "CSFloat" ? "CSFloatROI" : pricingSource == "Tradeit" ? "TradeitROI" : pricingSource == "Buff" ? "BuffROI" : "SkinportROI";

                      const compareValue = (item) => ((pricingSource == "Steam" ? item.CollectionPriceSteam : pricingSource == "CSFloat" ? item.CollectionPriceCSFloat : pricingSource == "Tradeit" ? item.CollectionPriceTradeit : pricingSource == "Buff" ? item.CollectionPriceBuff : item.CollectionPriceSkinport) / (0.8 * marketRatio));

                      const highestROIItem = initialItems.reduce((maxItem, currentItem) => {
                        return currentItem[ROILookupName] > (maxItem[ROILookupName] || 0) ? currentItem : maxItem;
                      }, initialItems[0]);

                      const galleryCase = initialItems.find(item => item.Name === "Gallery Case");
                      const highRiskItem = compareValue(galleryCase) > highestROIItem[ROILookupName] ? galleryCase : highestROIItem;

                      const perHourValue = highRiskItem.Name === "Gallery Case"
                        ? formatNumber(((((((pricingSource == "Steam" ? highRiskItem.CollectionPriceSteam : pricingSource == "CSFloat" ? highRiskItem.CollectionPriceCSFloat : pricingSource == "Tradeit" ? highRiskItem.CollectionPriceTradeit : pricingSource == "Buff" ? highRiskItem.CollectionPriceBuff : highRiskItem.CollectionPriceSkinport) * (1 - (showFees ? marketFees[pricingSource] : 0))) - (0.8 * marketRatio)) / 2) * (customStarsOn ? customStars : starsPerHour))) / exchangeRate, true)
                        : formatNumber(((((((highRiskItem[ROILookupName] * (1 - (showFees ? marketFees[pricingSource] : 0))) - 1) * (0.4 * marketRatio)) * (customStarsOn ? customStars : starsPerHour))) / exchangeRate), true);

                      return (
                        <Link to={highRiskItem?.UrlName !== null && `/item/${highRiskItem.UrlName}`} style={{ textDecoration: 'none', color: 'inherit', flex: '3', margin: "0px 4px 4px 4px", height: '100%', flexDirection: 'row', display: 'flex', border: '1.5px solid rgba(255, 255, 255, 0.12)', borderRadius: '4px', justifyContent: 'space-around', alignContent: 'center', lineHeight: '1' }}>
                          <h3 style={{ textAlign: 'center', margin: 'auto' }}>High Risk:
                            <Tooltip title="Comparing all armory rewards, this is the best option including every possible item" sx={{ margin: 'auto' }}>
                              <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '4px', marginBottom: '-4px' }} fontSize='large' />
                            </Tooltip>
                            <br />
                            {perHourValue} per Hour</h3>
                          <img src={highRiskItem.Image} style={{ margin: 'auto', width: 'auto', height: '100%', maxWidth: '90%', objectFit: 'contain' }} />
                        </Link>
                      );
                    })()}
                  </div>
                </AccordionDetails>

              </Accordion>

              <div style={{ display: 'flex', flexDirection: 'row', flex: '3', flexWrap: 'wrap', minHeight: isMobileLayout ? '500px' : '180px' }}>

                {/* {(() => {
                  const itemIndex = initialItems.findIndex(item => item.Name === "Limited Edition Item");
                  const item = initialItems[itemIndex];
                  let redNum;
                  let greenNum;
                  let blueNum;
                  const compareValue = (pricingSource == "Steam" ? item.SteamROI : pricingSource == "CSFloat" ? item.CSFloatROI : pricingSource == "Tradeit" ? item.TradeitROI : pricingSource == "Buff" ? item.BuffROI : item.SkinportROI)
                  if (compareValue > 1.3) {
                    redNum = 223;
                    greenNum = 199;
                    blueNum = 0;
                  } else if (compareValue > 1.3) {
                    redNum = 187 + Math.abs(compareValue - 1.3) / 0.15 * 36;
                    greenNum = 23 + Math.abs(compareValue - 1.3) / 0.15 * 176;
                    blueNum = 26 - Math.abs(compareValue - 1.3) / 0.15 * 26;
                  } else if (compareValue > 1.15) {
                    redNum = 158 + Math.abs(compareValue - 1.15) / 0.15 * 29;
                    greenNum = 0 + Math.abs(compareValue - 1.15) / 0.15 * 23;
                    blueNum = 197 - Math.abs(compareValue - 1.15) / 0.15 * 171;
                  } else if (compareValue > 1) {
                    redNum = 104 + Math.abs(compareValue - 1) / 0.15 * 54;
                    greenNum = 50 - Math.abs(compareValue - 1) / 0.15 * 50;
                    blueNum = 234 - Math.abs(compareValue - 1) / 0.15 * 37;
                  } else {
                    redNum = 2 + Math.abs(compareValue) / 1 * 102;
                    greenNum = 79 - Math.abs(compareValue) / 1 * 29;
                    blueNum = 228 + Math.abs(compareValue) / 1 * 6;
                  }
                  return (
                    <a href={item?.UrlName !== null && `/item/${item.UrlName}`} className="hover-lift" style={{ borderRadius: '4px', minWidth: '300px', flex: '1', margin: '4px', position: 'relative', background: `linear-gradient(145deg, RGB(${redNum}, ${greenNum}, ${blueNum}) 0%, black 100%)`, textDecoration: 'none', color: 'inherit', display: 'flex' }}>

                      <h2 style={{ position: 'absolute', marginLeft: '12px' }}>LIMITED EDITION ITEM</h2>
                      <div style={{ display: 'flex', flexDirection: 'row', position: 'absolute', bottom: '40%', left: '0', height: '60px', width: '40%', justifyContent: 'center' }}>
                        <Tooltip title="Return on Investment for opening this container now">
                          <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', fontSize: isMobileLayout ? '0.6rem' : '0.9rem', lineHeight: 0.9, height: '100%', justifyContent: 'space-between' }}>
                            Star ROI
                            <img src={OpenBox} className="iconSVGMob" style={{ fill: 'green', width: '32px', height: '32px', margin: '2px' }} />
                            <h2 style={{ lineHeight: 0.5, margin: 0 }}>{formatNumber(((pricingSource == "Steam" ? item.SteamROI : pricingSource == "CSFloat" ? item.CSFloatROI : pricingSource == "Tradeit" ? item.TradeitROI : pricingSource == "Buff" ? item.BuffROI : item.SkinportROI) * (1 - (showFees ? marketFees[pricingSource] : 0))) * 100)}%</h2>

                          </div>

                        </Tooltip>
                        <Tooltip title="Chance for each unbox to be worth more than the cost of opening">
                          <div style={{ display: "flex", alignItems: "center", flex: '1', flexDirection: 'column', textAlign: 'center', fontSize: isMobileLayout ? '0.6rem' : '0.9rem', lineHeight: 0.9, height: '100%', justifyContent: 'space-between' }}>
                            Profit
                            <PriceCheckIcon className="iconSVG" sx={{ width: '32px', height: '32px', margin: '2px' }} fontSize='large' />
                            <h2 style={{ lineHeight: 0.5, margin: 0 }}>{formatNumber((pricingSource == "Steam" ? item.ProfitSteam : pricingSource == "CSFloat" ? item.ProfitCSFloat : pricingSource == "Tradeit" ? item.ProfitTradeit : pricingSource == "Buff" ? item.ProfitBuff : item.ProfitSkinport) * 100)}%</h2>
                          </div>

                        </Tooltip>

                      </div>
                      <h2 style={{ position: 'absolute', top: '0', right: '12px' }}>
                        #{itemIndex + 1}
                      </h2>
                      <h3 style={{ position: 'absolute', bottom: '0', left: '12px' }}>
                        25 ★ / {formatNumber((pricingSource == "Steam" ? item.CollectionPriceSteam : pricingSource == "Buff" ? item.CollectionPriceBuff : pricingSource == "CSFloat" ? item.CollectionPriceCSFloat : pricingSource == "Skinport" ? item.CollectionPriceSkinport : pricingSource == "Tradeit" ? item.CollectionPriceTradeit : item.CollectionPriceSteam) / exchangeRate, true)} → {formatNumber(((pricingSource == "Steam" ? item.CollectionPriceSteam * item.SteamROI : pricingSource == "Buff" ? item.CollectionPriceBuff * item.BuffROI : pricingSource == "CSFloat" ? item.CollectionPriceCSFloat * item.CSFloatROI : pricingSource == "Skinport" ? item.CollectionPriceSkinport * item.SkinportROI : pricingSource == "Tradeit" ? item.CollectionPriceTradeit * item.TradeitROI : item.CollectionPriceSteam * item.SteamROI) * (1 - (showFees ? marketFees[pricingSource] : 0))) / exchangeRate, true)} AVG
                      </h3>
                      <img src={item.Image} style={{ pointerEvents: 'none', position: 'absolute', right: '0', width: 'auto', height: '100%', maxWidth: '90%', padding: '16px', objectFit: 'contain' }} />
                      {
                        (item.RarityValuesSteam != null && item.RarityChances != null && Object.keys(item?.RarityChances)?.length > 1) ?
                          // Progress bar with multiple colors represented by the values within rarityvaluesteam
                          <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', marginLeft: '-5px', marginRight: '-5px', marginTop: '4px', marginBottom: '-6px' }}>
                            <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', width: '100%', height: '5px', borderRadius: '4px', overflow: 'hidden', backgroundColor: 'rgb(0,0,0,0.3)', padding: '0px', flexWrap: 'nowrap' }}>
                              {Object.entries(item.RarityChances).map(([rarity, value], index) => {
                                const size = (value * item.RarityValuesSteam[rarity]) / (item.SteamROI * (item.CollectionPriceSteam + item.KeyCostSteam)) * 100;
                                const rarityKey = Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity;
                                return (
                                  <div key={index} style={{ backgroundColor: `RGB(${redRarityNums[rarityKey]}, ${greenRarityNums[rarityKey]}, ${blueRarityNums[rarityKey]})`, width: `${size}%`, height: '100%', borderTopLeftRadius: index == 0 ? '4px' : '0px', borderBottomLeftRadius: index == 0 ? '4px' : '0px', borderTopRightRadius: index == Object.keys(item.RarityChances).length - 1 ? '4px' : '0px', borderBottomRightRadius: index == Object.keys(item.RarityChances).length - 1 ? '4px' : '0px', overflow: 'hidden' }}></div>
                                );
                              })}
                            </div>
                          </div> :
                          <div style={{ height: '4px' }}>
                          </div>
                      }
                    </a>
                  );
                })()} */}

                {(() => {
                  const itemIndex = initialItems.findIndex(item => item.Name === "Missing Link Charms");
                  const item = initialItems[itemIndex];
                  let redNum;
                  let greenNum;
                  let blueNum;
                  const compareValue = (pricingSource == "Steam" ? item.SteamROI : pricingSource == "CSFloat" ? item.CSFloatROI : pricingSource == "Tradeit" ? item.TradeitROI : pricingSource == "Buff" ? item.BuffROI : item.SkinportROI)
                  if (compareValue > 1.35) {
                    redNum = 223;
                    greenNum = 199;
                    blueNum = 0;
                  } else if (compareValue > 1.25) {
                    redNum = 187 + Math.abs(compareValue - 1.25) / 0.1 * 36;
                    greenNum = 23 + Math.abs(compareValue - 1.25) / 0.1 * 176;
                    blueNum = 26 - Math.abs(compareValue - 1.25) / 0.1 * 26;
                  } else if (compareValue > 1.15) {
                    redNum = 158 + Math.abs(compareValue - 1.15) / 0.1 * 29;
                    greenNum = 0 + Math.abs(compareValue - 1.15) / 0.1 * 23;
                    blueNum = 197 - Math.abs(compareValue - 1.15) / 0.1 * 171;
                  } else if (compareValue > 1) {
                    redNum = 104 + Math.abs(compareValue - 1) / 0.15 * 54;
                    greenNum = 50 - Math.abs(compareValue - 1) / 0.15 * 50;
                    blueNum = 234 - Math.abs(compareValue - 1) / 0.15 * 37;
                  } else {
                    redNum = 2 + Math.abs(compareValue) / 1 * 102;
                    greenNum = 79 - Math.abs(compareValue) / 1 * 29;
                    blueNum = 228 + Math.abs(compareValue) / 1 * 6;
                  }
                  return (
                    <Link to={item?.UrlName !== null && `/item/${item.UrlName}`} className="hover-lift" style={{ minWidth: '300px', borderRadius: '4px', flex: '1', margin: '4px', position: 'relative', background: `linear-gradient(145deg, RGB(${redNum}, ${greenNum}, ${blueNum}) 0%, black 100%)`, textDecoration: 'none', color: 'inherit', display: 'flex' }}>
                      <h2 style={{ position: 'absolute', marginLeft: '12px' }}>MISSING LINK CHARMS</h2>
                      <div style={{ display: 'flex', flexDirection: 'row', position: 'absolute', bottom: '40%', left: '0', height: '60px', width: '40%', justifyContent: 'center' }}>
                        <Tooltip title="Return on Investment for opening this container now">
                          <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', fontSize: isMobileLayout ? '0.6rem' : '0.9rem', lineHeight: 0.9, height: '100%', justifyContent: 'space-between' }}>
                            Star ROI
                            <img src={OpenBox} className="iconSVGMob" style={{ fill: 'green', width: '32px', height: '32px', margin: '2px' }} />
                            <h2 style={{ lineHeight: 0.5, margin: 0 }}>{formatNumber(((pricingSource == "Steam" ? item.SteamROI : pricingSource == "CSFloat" ? item.CSFloatROI : pricingSource == "Tradeit" ? item.TradeitROI : pricingSource == "Buff" ? item.BuffROI : item.SkinportROI) * (1 - (showFees ? marketFees[pricingSource] : 0))) * 100)}%</h2>

                          </div>

                        </Tooltip>
                        <Tooltip title="Chance for each unbox to be worth more than the cost of opening">
                          <div style={{ display: "flex", alignItems: "center", flex: '1', flexDirection: 'column', textAlign: 'center', fontSize: isMobileLayout ? '0.6rem' : '0.9rem', lineHeight: 0.9, height: '100%', justifyContent: 'space-between' }}>
                            Profit
                            <PriceCheckIcon className="iconSVG" sx={{ width: '32px', height: '32px', margin: '2px' }} fontSize='large' />
                            <h2 style={{ lineHeight: 0.5, margin: 0 }}>{formatNumber((pricingSource == "Steam" ? item.ProfitSteam : pricingSource == "CSFloat" ? item.ProfitCSFloat : pricingSource == "Tradeit" ? item.ProfitTradeit : pricingSource == "Buff" ? item.ProfitBuff : item.ProfitSkinport) * 100)}%</h2>
                          </div>

                        </Tooltip>

                      </div>
                      <h2 style={{ position: 'absolute', top: '0', right: '12px' }}>
                        #{itemIndex + 1}
                      </h2>
                      <h3 style={{ position: 'absolute', bottom: '0', left: '12px' }}>3 ★ / {formatNumber((pricingSource == "Steam" ? item.CollectionPriceSteam : pricingSource == "Buff" ? item.CollectionPriceBuff : pricingSource == "CSFloat" ? item.CollectionPriceCSFloat : pricingSource == "Skinport" ? item.CollectionPriceSkinport : pricingSource == "Tradeit" ? item.CollectionPriceTradeit : item.CollectionPriceSteam) / exchangeRate, true)} → {formatNumber(((pricingSource == "Steam" ? item.CollectionPriceSteam * item.SteamROI : pricingSource == "Buff" ? item.CollectionPriceBuff * item.BuffROI : pricingSource == "CSFloat" ? item.CollectionPriceCSFloat * item.CSFloatROI : pricingSource == "Skinport" ? item.CollectionPriceSkinport * item.SkinportROI : pricingSource == "Tradeit" ? item.CollectionPriceTradeit * item.TradeitROI : item.CollectionPriceSteam * item.SteamROI) * (1 - (showFees ? marketFees[pricingSource] : 0))) / exchangeRate, true)} AVG</h3>
                      <img src={item.Image} style={{ pointerEvents: 'none', position: 'absolute', right: '0', width: 'auto', height: '100%', maxWidth: '90%', padding: '16px', objectFit: 'contain' }} />
                      <div style={{ bottom: '0', position: 'absolute', width: '100%', margin: '5px', paddingRight: '10px' }}>
                        {
                          (item.RarityValuesSteam != null && item.RarityChances != null && Object.keys(item?.RarityChances)?.length > 1) ?
                            // Progress bar with multiple colors represented by the values within rarityvaluesteam
                            <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', marginLeft: '-5px', marginRight: '-5px', marginTop: '4px', marginBottom: '-6px' }}>
                              <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', width: '100%', height: '6px', borderRadius: '4px', overflow: 'hidden', backgroundColor: 'rgb(0,0,0,0.3)', padding: '0px', flexWrap: 'nowrap' }}>
                                {Object.entries(item.RarityChances).map(([rarity, value], index) => {
                                  const size = (value * item.RarityValuesSteam[rarity]) / (item.SteamROI * (item.CollectionPriceSteam + item.KeyCostSteam)) * 100;
                                  const rarityKey = Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity;
                                  return (
                                    <div key={index} style={{ backgroundColor: `RGB(${redRarityNums[rarityKey]}, ${greenRarityNums[rarityKey]}, ${blueRarityNums[rarityKey]})`, width: `${size}%`, height: '100%', borderTopLeftRadius: index == 0 ? '4px' : '0px', borderBottomLeftRadius: index == 0 ? '4px' : '0px', borderTopRightRadius: index == Object.keys(item.RarityChances).length - 1 ? '4px' : '0px', borderBottomRightRadius: index == Object.keys(item.RarityChances).length - 1 ? '4px' : '0px', overflow: 'hidden' }}></div>
                                  );
                                })}
                              </div>
                            </div> :
                            <div style={{ height: '4px' }}>
                            </div>
                        }
                      </div>
                    </Link>
                  );
                })()}
                {(() => {
                  const itemIndex = initialItems.findIndex(item => item.Name === "Small Arms Charms");
                  const item = initialItems[itemIndex];
                  let redNum;
                  let greenNum;
                  let blueNum;
                  const compareValue = (pricingSource == "Steam" ? item.SteamROI : pricingSource == "CSFloat" ? item.CSFloatROI : pricingSource == "Tradeit" ? item.TradeitROI : pricingSource == "Buff" ? item.BuffROI : item.SkinportROI)
                  if (compareValue > 1.35) {
                    redNum = 223;
                    greenNum = 199;
                    blueNum = 0;
                  } else if (compareValue > 1.25) {
                    redNum = 187 + Math.abs(compareValue - 1.25) / 0.1 * 36;
                    greenNum = 23 + Math.abs(compareValue - 1.25) / 0.1 * 176;
                    blueNum = 26 - Math.abs(compareValue - 1.25) / 0.1 * 26;
                  } else if (compareValue > 1.15) {
                    redNum = 158 + Math.abs(compareValue - 1.15) / 0.1 * 29;
                    greenNum = 0 + Math.abs(compareValue - 1.15) / 0.1 * 23;
                    blueNum = 197 - Math.abs(compareValue - 1.15) / 0.1 * 171;
                  } else if (compareValue > 1) {
                    redNum = 104 + Math.abs(compareValue - 1) / 0.15 * 54;
                    greenNum = 50 - Math.abs(compareValue - 1) / 0.15 * 50;
                    blueNum = 234 - Math.abs(compareValue - 1) / 0.15 * 37;
                  } else {
                    redNum = 2 + Math.abs(compareValue) / 1 * 102;
                    greenNum = 79 - Math.abs(compareValue) / 1 * 29;
                    blueNum = 228 + Math.abs(compareValue) / 1 * 6;
                  }
                  return (
                    <Link to={item?.UrlName !== null && `/item/${item.UrlName}`} className="hover-lift" style={{ minWidth: '300px', borderRadius: '4px', flex: '1', margin: '4px', position: 'relative', background: `linear-gradient(145deg, RGB(${redNum}, ${greenNum}, ${blueNum}) 0%, black 100%)`, textDecoration: 'none', color: 'inherit', display: 'flex' }}>
                      <h2 style={{ position: 'absolute', marginLeft: '12px' }}>SMALL ARMS CHARMS</h2>
                      <div style={{ display: 'flex', flexDirection: 'row', position: 'absolute', bottom: '40%', left: '0', height: '60px', width: '40%', justifyContent: 'center' }}>
                        <Tooltip title="Return on Investment for opening this container now">
                          <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', fontSize: isMobileLayout ? '0.6rem' : '0.9rem', lineHeight: 0.9, height: '100%', justifyContent: 'space-between' }}>
                            Star ROI
                            <img src={OpenBox} className="iconSVGMob" style={{ fill: 'green', width: '32px', height: '32px', margin: '2px' }} />
                            <h2 style={{ lineHeight: 0.5, margin: 0 }}>{formatNumber(((pricingSource == "Steam" ? item.SteamROI : pricingSource == "CSFloat" ? item.CSFloatROI : pricingSource == "Tradeit" ? item.TradeitROI : pricingSource == "Buff" ? item.BuffROI : item.SkinportROI) * (1 - (showFees ? marketFees[pricingSource] : 0))) * 100)}%</h2>

                          </div>

                        </Tooltip>
                        <Tooltip title="Chance for each unbox to be worth more than the cost of opening">
                          <div style={{ display: "flex", alignItems: "center", flex: '1', flexDirection: 'column', textAlign: 'center', fontSize: isMobileLayout ? '0.6rem' : '0.9rem', lineHeight: 0.9, height: '100%', justifyContent: 'space-between' }}>
                            Profit
                            <PriceCheckIcon className="iconSVG" sx={{ width: '32px', height: '32px', margin: '2px' }} fontSize='large' />
                            <h2 style={{ lineHeight: 0.5, margin: 0 }}>{formatNumber((pricingSource == "Steam" ? item.ProfitSteam : pricingSource == "CSFloat" ? item.ProfitCSFloat : pricingSource == "Tradeit" ? item.ProfitTradeit : pricingSource == "Buff" ? item.ProfitBuff : item.ProfitSkinport) * 100)}%</h2>
                          </div>

                        </Tooltip>

                      </div>
                      <h2 style={{ position: 'absolute', top: '0', right: '12px' }}>
                        #{itemIndex + 1}
                      </h2>
                      <h3 style={{ position: 'absolute', bottom: '0', left: '12px' }}>3 ★ / {formatNumber((pricingSource == "Steam" ? item.CollectionPriceSteam : pricingSource == "Buff" ? item.CollectionPriceBuff : pricingSource == "CSFloat" ? item.CollectionPriceCSFloat : pricingSource == "Skinport" ? item.CollectionPriceSkinport : pricingSource == "Tradeit" ? item.CollectionPriceTradeit : item.CollectionPriceSteam) / exchangeRate, true)} → {formatNumber(((pricingSource == "Steam" ? item.CollectionPriceSteam * item.SteamROI : pricingSource == "Buff" ? item.CollectionPriceBuff * item.BuffROI : pricingSource == "CSFloat" ? item.CollectionPriceCSFloat * item.CSFloatROI : pricingSource == "Skinport" ? item.CollectionPriceSkinport * item.SkinportROI : pricingSource == "Tradeit" ? item.CollectionPriceTradeit * item.TradeitROI : item.CollectionPriceSteam * item.SteamROI) * (1 - (showFees ? marketFees[pricingSource] : 0))) / exchangeRate, true)} AVG</h3>
                      <img src={item.Image} style={{ pointerEvents: 'none', position: 'absolute', right: '0', width: 'auto', height: '100%', maxWidth: '90%', padding: '16px', objectFit: 'contain' }} />
                      <div style={{ bottom: '0', position: 'absolute', width: '100%', margin: '5px', paddingRight: '10px' }}>
                        {
                          (item.RarityValuesSteam != null && item.RarityChances != null && Object.keys(item?.RarityChances)?.length > 1) ?
                            // Progress bar with multiple colors represented by the values within rarityvaluesteam
                            <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', marginLeft: '-5px', marginRight: '-5px', marginTop: '4px', marginBottom: '-6px' }}>
                              <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', width: '100%', height: '6px', borderRadius: '4px', overflow: 'hidden', backgroundColor: 'rgb(0,0,0,0.3)', padding: '0px', flexWrap: 'nowrap' }}>
                                {Object.entries(item.RarityChances).map(([rarity, value], index) => {
                                  const size = (value * item.RarityValuesSteam[rarity]) / (item.SteamROI * (item.CollectionPriceSteam + item.KeyCostSteam)) * 100;
                                  const rarityKey = Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity;
                                  return (
                                    <div key={index} style={{ backgroundColor: `RGB(${redRarityNums[rarityKey]}, ${greenRarityNums[rarityKey]}, ${blueRarityNums[rarityKey]})`, width: `${size}%`, height: '100%', borderTopLeftRadius: index == 0 ? '4px' : '0px', borderBottomLeftRadius: index == 0 ? '4px' : '0px', borderTopRightRadius: index == Object.keys(item.RarityChances).length - 1 ? '4px' : '0px', borderBottomRightRadius: index == Object.keys(item.RarityChances).length - 1 ? '4px' : '0px', overflow: 'hidden' }}></div>
                                  );
                                })}
                              </div>
                            </div> :
                            <div style={{ height: '4px' }}>
                            </div>
                        }
                      </div>
                    </Link>

                  );
                })()}

              </div>
              <div style={{ display: 'flex', flexDirection: 'row', flex: '6', minHeight: isMobileLayout ? '500px' : '180px', flexWrap: 'wrap' }}>
                {(() => {
                  const itemIndex = initialItems.findIndex(item => item.Name === "The Overpass 2024 Collection");
                  const item = initialItems[itemIndex];
                  let redNum;
                  let greenNum;
                  let blueNum;
                  const compareValue = (pricingSource == "Steam" ? item.SteamROI : pricingSource == "CSFloat" ? item.CSFloatROI : pricingSource == "Tradeit" ? item.TradeitROI : pricingSource == "Buff" ? item.BuffROI : item.SkinportROI)
                  if (compareValue > 1.35) {
                    redNum = 223;
                    greenNum = 199;
                    blueNum = 0;
                  } else if (compareValue > 1.25) {
                    redNum = 187 + Math.abs(compareValue - 1.25) / 0.1 * 36;
                    greenNum = 23 + Math.abs(compareValue - 1.25) / 0.1 * 176;
                    blueNum = 26 - Math.abs(compareValue - 1.25) / 0.1 * 26;
                  } else if (compareValue > 1.15) {
                    redNum = 158 + Math.abs(compareValue - 1.15) / 0.1 * 29;
                    greenNum = 0 + Math.abs(compareValue - 1.15) / 0.1 * 23;
                    blueNum = 197 - Math.abs(compareValue - 1.15) / 0.1 * 171;
                  } else if (compareValue > 1) {
                    redNum = 104 + Math.abs(compareValue - 1) / 0.15 * 54;
                    greenNum = 50 - Math.abs(compareValue - 1) / 0.15 * 50;
                    blueNum = 234 - Math.abs(compareValue - 1) / 0.15 * 37;
                  } else {
                    redNum = 2 + Math.abs(compareValue) / 1 * 102;
                    greenNum = 79 - Math.abs(compareValue) / 1 * 29;
                    blueNum = 228 + Math.abs(compareValue) / 1 * 6;
                  }
                  return (
                    <Link to={item?.UrlName !== null && `/item/${item.UrlName}`} className="hover-lift" style={{ minWidth: '300px', borderRadius: '4px', flex: '1', margin: '4px', position: 'relative', background: `linear-gradient(145deg, RGB(${redNum}, ${greenNum}, ${blueNum}) 0%, black 100%)`, textDecoration: 'none', color: 'inherit', display: 'flex' }}>
                      <h2 style={{ position: 'absolute', marginLeft: '12px' }}>OVERPASS 2024</h2>
                      <div style={{ display: 'flex', flexDirection: 'row', position: 'absolute', bottom: '40%', left: '0', height: '60px', width: '40%', justifyContent: 'center' }}>
                        <Tooltip title="Return on Investment for opening this container now">
                          <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', fontSize: isMobileLayout ? '0.6rem' : '0.9rem', lineHeight: 0.9, height: '100%', justifyContent: 'space-between' }}>
                            Star ROI
                            <img src={OpenBox} className="iconSVGMob" style={{ fill: 'green', width: '32px', height: '32px', margin: '2px' }} />
                            <h2 style={{ lineHeight: 0.5, margin: 0 }}>{formatNumber(((pricingSource == "Steam" ? item.SteamROI : pricingSource == "CSFloat" ? item.CSFloatROI : pricingSource == "Tradeit" ? item.TradeitROI : pricingSource == "Buff" ? item.BuffROI : item.SkinportROI) * (1 - (showFees ? marketFees[pricingSource] : 0))) * 100)}%</h2>

                          </div>

                        </Tooltip>
                        <Tooltip title="Chance for each unbox to be worth more than the cost of opening">
                          <div style={{ display: "flex", alignItems: "center", flex: '1', flexDirection: 'column', textAlign: 'center', fontSize: isMobileLayout ? '0.6rem' : '0.9rem', lineHeight: 0.9, height: '100%', justifyContent: 'space-between' }}>
                            Profit
                            <PriceCheckIcon className="iconSVG" sx={{ width: '32px', height: '32px', margin: '2px' }} fontSize='large' />
                            <h2 style={{ lineHeight: 0.5, margin: 0 }}>{formatNumber((pricingSource == "Steam" ? item.ProfitSteam : pricingSource == "CSFloat" ? item.ProfitCSFloat : pricingSource == "Tradeit" ? item.ProfitTradeit : pricingSource == "Buff" ? item.ProfitBuff : item.ProfitSkinport) * 100)}%</h2>
                          </div>

                        </Tooltip>

                      </div>
                      <h2 style={{ position: 'absolute', top: '0', right: '12px' }}>
                        #{itemIndex + 1}
                      </h2>
                      <h3 style={{ position: 'absolute', bottom: '0', left: '12px' }}>4 ★ / {formatNumber((pricingSource == "Steam" ? item.CollectionPriceSteam : pricingSource == "Buff" ? item.CollectionPriceBuff : pricingSource == "CSFloat" ? item.CollectionPriceCSFloat : pricingSource == "Skinport" ? item.CollectionPriceSkinport : pricingSource == "Tradeit" ? item.CollectionPriceTradeit : item.CollectionPriceSteam) / exchangeRate, true)} → {formatNumber(((pricingSource == "Steam" ? item.CollectionPriceSteam * item.SteamROI : pricingSource == "Buff" ? item.CollectionPriceBuff * item.BuffROI : pricingSource == "CSFloat" ? item.CollectionPriceCSFloat * item.CSFloatROI : pricingSource == "Skinport" ? item.CollectionPriceSkinport * item.SkinportROI : pricingSource == "Tradeit" ? item.CollectionPriceTradeit * item.TradeitROI : item.CollectionPriceSteam * item.SteamROI) * (1 - (showFees ? marketFees[pricingSource] : 0))) / exchangeRate, true)} AVG</h3>
                      <img src={item.Image} style={{ pointerEvents: 'none', position: 'absolute', right: '0', width: 'auto', height: '100%', maxWidth: '90%', padding: '16px', objectFit: 'contain' }} />
                      <div style={{ bottom: '0', position: 'absolute', width: '100%', margin: '5px', paddingRight: '10px' }}>
                        {
                          (item.RarityValuesSteam != null && item.RarityChances != null && Object.keys(item?.RarityChances)?.length > 1) ?
                            // Progress bar with multiple colors represented by the values within rarityvaluesteam
                            <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', marginLeft: '-5px', marginRight: '-5px', marginTop: '4px', marginBottom: '-6px' }}>
                              <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', width: '100%', height: '6px', borderRadius: '4px', overflow: 'hidden', backgroundColor: 'rgb(0,0,0,0.3)', padding: '0px', flexWrap: 'nowrap' }}>
                                {Object.entries(item.RarityChances).map(([rarity, value], index) => {
                                  const size = (value * item.RarityValuesSteam[rarity]) / (item.SteamROI * (item.CollectionPriceSteam + item.KeyCostSteam)) * 100;
                                  const rarityKey = Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity;
                                  return (
                                    <div key={index} style={{ backgroundColor: `RGB(${redRarityNums[rarityKey]}, ${greenRarityNums[rarityKey]}, ${blueRarityNums[rarityKey]})`, width: `${size}%`, height: '100%', borderTopLeftRadius: index == 0 ? '4px' : '0px', borderBottomLeftRadius: index == 0 ? '4px' : '0px', borderTopRightRadius: index == Object.keys(item.RarityChances).length - 1 ? '4px' : '0px', borderBottomRightRadius: index == Object.keys(item.RarityChances).length - 1 ? '4px' : '0px', overflow: 'hidden' }}></div>
                                  );
                                })}
                              </div>
                            </div> :
                            <div style={{ height: '4px' }}>
                            </div>
                        }
                      </div>
                    </Link>
                  );
                })()}

                {(() => {
                  const itemIndex = initialItems.findIndex(item => item.Name === "The Graphic Design Collection");
                  const item = initialItems[itemIndex];
                  let redNum;
                  let greenNum;
                  let blueNum;
                  const compareValue = (pricingSource == "Steam" ? item.SteamROI : pricingSource == "CSFloat" ? item.CSFloatROI : pricingSource == "Tradeit" ? item.TradeitROI : pricingSource == "Buff" ? item.BuffROI : item.SkinportROI)
                  if (compareValue > 1.35) {
                    redNum = 223;
                    greenNum = 199;
                    blueNum = 0;
                  } else if (compareValue > 1.25) {
                    redNum = 187 + Math.abs(compareValue - 1.25) / 0.1 * 36;
                    greenNum = 23 + Math.abs(compareValue - 1.25) / 0.1 * 176;
                    blueNum = 26 - Math.abs(compareValue - 1.25) / 0.1 * 26;
                  } else if (compareValue > 1.15) {
                    redNum = 158 + Math.abs(compareValue - 1.15) / 0.1 * 29;
                    greenNum = 0 + Math.abs(compareValue - 1.15) / 0.1 * 23;
                    blueNum = 197 - Math.abs(compareValue - 1.15) / 0.1 * 171;
                  } else if (compareValue > 1) {
                    redNum = 104 + Math.abs(compareValue - 1) / 0.15 * 54;
                    greenNum = 50 - Math.abs(compareValue - 1) / 0.15 * 50;
                    blueNum = 234 - Math.abs(compareValue - 1) / 0.15 * 37;
                  } else {
                    redNum = 2 + Math.abs(compareValue) / 1 * 102;
                    greenNum = 79 - Math.abs(compareValue) / 1 * 29;
                    blueNum = 228 + Math.abs(compareValue) / 1 * 6;
                  }
                  return (
                    <Link to={item?.UrlName !== null && `/item/${item.UrlName}`} className="hover-lift" style={{ minWidth: '300px', borderRadius: '4px', flex: '1', margin: '4px', position: 'relative', background: `linear-gradient(145deg, RGB(${redNum}, ${greenNum}, ${blueNum}) 0%, black 100%)`, textDecoration: 'none', color: 'inherit', display: 'flex' }}>
                      <h2 style={{ position: 'absolute', marginLeft: '12px' }}>GRAPHIC COLLECTION</h2>
                      <div style={{ display: 'flex', flexDirection: 'row', position: 'absolute', bottom: '40%', left: '0', height: '60px', width: '40%', justifyContent: 'center' }}>
                        <Tooltip title="Return on Investment for opening this container now">
                          <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', fontSize: isMobileLayout ? '0.6rem' : '0.9rem', lineHeight: 0.9, height: '100%', justifyContent: 'space-between' }}>
                            Star ROI
                            <img src={OpenBox} className="iconSVGMob" style={{ fill: 'green', width: '32px', height: '32px', margin: '2px' }} />
                            <h2 style={{ lineHeight: 0.5, margin: 0 }}>{formatNumber(((pricingSource == "Steam" ? item.SteamROI : pricingSource == "CSFloat" ? item.CSFloatROI : pricingSource == "Tradeit" ? item.TradeitROI : pricingSource == "Buff" ? item.BuffROI : item.SkinportROI) * (1 - (showFees ? marketFees[pricingSource] : 0))) * 100)}%</h2>

                          </div>

                        </Tooltip>
                        <Tooltip title="Chance for each unbox to be worth more than the cost of opening">
                          <div style={{ display: "flex", alignItems: "center", flex: '1', flexDirection: 'column', textAlign: 'center', fontSize: isMobileLayout ? '0.6rem' : '0.9rem', lineHeight: 0.9, height: '100%', justifyContent: 'space-between' }}>
                            Profit
                            <PriceCheckIcon className="iconSVG" sx={{ width: '32px', height: '32px', margin: '2px' }} fontSize='large' />
                            <h2 style={{ lineHeight: 0.5, margin: 0 }}>{formatNumber((pricingSource == "Steam" ? item.ProfitSteam : pricingSource == "CSFloat" ? item.ProfitCSFloat : pricingSource == "Tradeit" ? item.ProfitTradeit : pricingSource == "Buff" ? item.ProfitBuff : item.ProfitSkinport) * 100)}%</h2>
                          </div>

                        </Tooltip>

                      </div>
                      <h2 style={{ position: 'absolute', top: '0', right: '12px' }}>
                        #{itemIndex + 1}
                      </h2>
                      <h3 style={{ position: 'absolute', bottom: '0', left: '12px' }}>4 ★ / {formatNumber((pricingSource == "Steam" ? item.CollectionPriceSteam : pricingSource == "Buff" ? item.CollectionPriceBuff : pricingSource == "CSFloat" ? item.CollectionPriceCSFloat : pricingSource == "Skinport" ? item.CollectionPriceSkinport : pricingSource == "Tradeit" ? item.CollectionPriceTradeit : item.CollectionPriceSteam) / exchangeRate, true)} → {formatNumber(((pricingSource == "Steam" ? item.CollectionPriceSteam * item.SteamROI : pricingSource == "Buff" ? item.CollectionPriceBuff * item.BuffROI : pricingSource == "CSFloat" ? item.CollectionPriceCSFloat * item.CSFloatROI : pricingSource == "Skinport" ? item.CollectionPriceSkinport * item.SkinportROI : pricingSource == "Tradeit" ? item.CollectionPriceTradeit * item.TradeitROI : item.CollectionPriceSteam * item.SteamROI) * (1 - (showFees ? marketFees[pricingSource] : 0))) / exchangeRate, true)} AVG</h3>
                      <img src={item.Image} style={{ pointerEvents: 'none', position: 'absolute', right: '0', width: 'auto', height: '100%', maxWidth: '90%', padding: '16px', objectFit: 'contain' }} />
                      <div style={{ bottom: '0', position: 'absolute', width: '100%', margin: '5px', paddingRight: '10px' }}>
                        {
                          (item.RarityValuesSteam != null && item.RarityChances != null && Object.keys(item?.RarityChances)?.length > 1) ?
                            // Progress bar with multiple colors represented by the values within rarityvaluesteam
                            <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', marginLeft: '-5px', marginRight: '-5px', marginTop: '4px', marginBottom: '-6px' }}>
                              <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', width: '100%', height: '6px', borderRadius: '4px', overflow: 'hidden', backgroundColor: 'rgb(0,0,0,0.3)', padding: '0px', flexWrap: 'nowrap' }}>
                                {Object.entries(item.RarityChances).map(([rarity, value], index) => {
                                  const size = (value * item.RarityValuesSteam[rarity]) / (item.SteamROI * (item.CollectionPriceSteam + item.KeyCostSteam)) * 100;
                                  const rarityKey = Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity;
                                  return (
                                    <div key={index} style={{ backgroundColor: `RGB(${redRarityNums[rarityKey]}, ${greenRarityNums[rarityKey]}, ${blueRarityNums[rarityKey]})`, width: `${size}%`, height: '100%', borderTopLeftRadius: index == 0 ? '4px' : '0px', borderBottomLeftRadius: index == 0 ? '4px' : '0px', borderTopRightRadius: index == Object.keys(item.RarityChances).length - 1 ? '4px' : '0px', borderBottomRightRadius: index == Object.keys(item.RarityChances).length - 1 ? '4px' : '0px', overflow: 'hidden' }}></div>
                                  );
                                })}
                              </div>
                            </div> :
                            <div style={{ height: '4px' }}>
                            </div>
                        }
                      </div>
                    </Link>
                  );
                })()}

                {(() => {
                  const itemIndex = initialItems.findIndex(item => item.Name === "The Sport & Field Collection");
                  const item = initialItems[itemIndex];
                  let redNum;
                  let greenNum;
                  let blueNum;
                  const compareValue = (pricingSource == "Steam" ? item.SteamROI : pricingSource == "CSFloat" ? item.CSFloatROI : pricingSource == "Tradeit" ? item.TradeitROI : pricingSource == "Buff" ? item.BuffROI : item.SkinportROI)
                  if (compareValue > 1.35) {
                    redNum = 223;
                    greenNum = 199;
                    blueNum = 0;
                  } else if (compareValue > 1.25) {
                    redNum = 187 + Math.abs(compareValue - 1.25) / 0.1 * 36;
                    greenNum = 23 + Math.abs(compareValue - 1.25) / 0.1 * 176;
                    blueNum = 26 - Math.abs(compareValue - 1.25) / 0.1 * 26;
                  } else if (compareValue > 1.15) {
                    redNum = 158 + Math.abs(compareValue - 1.15) / 0.1 * 29;
                    greenNum = 0 + Math.abs(compareValue - 1.15) / 0.1 * 23;
                    blueNum = 197 - Math.abs(compareValue - 1.15) / 0.1 * 171;
                  } else if (compareValue > 1) {
                    redNum = 104 + Math.abs(compareValue - 1) / 0.15 * 54;
                    greenNum = 50 - Math.abs(compareValue - 1) / 0.15 * 50;
                    blueNum = 234 - Math.abs(compareValue - 1) / 0.15 * 37;
                  } else {
                    redNum = 2 + Math.abs(compareValue) / 1 * 102;
                    greenNum = 79 - Math.abs(compareValue) / 1 * 29;
                    blueNum = 228 + Math.abs(compareValue) / 1 * 6;
                  }
                  return (
                    <Link to={item?.UrlName !== null && `/item/${item.UrlName}`} className="hover-lift" style={{ minWidth: '300px', borderRadius: '4px', flex: '1', margin: '4px', position: 'relative', background: `linear-gradient(145deg, RGB(${redNum}, ${greenNum}, ${blueNum}) 0%, black 100%)`, textDecoration: 'none', color: 'inherit', display: 'flex' }}>
                      <h2 style={{ position: 'absolute', marginLeft: '12px' }}>SPORT & FIELD COLLECTION</h2>
                      <div style={{ display: 'flex', flexDirection: 'row', position: 'absolute', bottom: '40%', left: '0', height: '60px', width: '40%', justifyContent: 'center' }}>
                        <Tooltip title="Return on Investment for opening this container now">
                          <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', fontSize: isMobileLayout ? '0.6rem' : '0.9rem', lineHeight: 0.9, height: '100%', justifyContent: 'space-between' }}>
                            Star ROI
                            <img src={OpenBox} className="iconSVGMob" style={{ fill: 'green', width: '32px', height: '32px', margin: '2px' }} />
                            <h2 style={{ lineHeight: 0.5, margin: 0 }}>{formatNumber(((pricingSource == "Steam" ? item.SteamROI : pricingSource == "CSFloat" ? item.CSFloatROI : pricingSource == "Tradeit" ? item.TradeitROI : pricingSource == "Buff" ? item.BuffROI : item.SkinportROI) * (1 - (showFees ? marketFees[pricingSource] : 0))) * 100)}%</h2>

                          </div>

                        </Tooltip>
                        <Tooltip title="Chance for each unbox to be worth more than the cost of opening">
                          <div style={{ display: "flex", alignItems: "center", flex: '1', flexDirection: 'column', textAlign: 'center', fontSize: isMobileLayout ? '0.6rem' : '0.9rem', lineHeight: 0.9, height: '100%', justifyContent: 'space-between' }}>
                            Profit
                            <PriceCheckIcon className="iconSVG" sx={{ width: '32px', height: '32px', margin: '2px' }} fontSize='large' />
                            <h2 style={{ lineHeight: 0.5, margin: 0 }}>{formatNumber((pricingSource == "Steam" ? item.ProfitSteam : pricingSource == "CSFloat" ? item.ProfitCSFloat : pricingSource == "Tradeit" ? item.ProfitTradeit : pricingSource == "Buff" ? item.ProfitBuff : item.ProfitSkinport) * 100)}%</h2>
                          </div>

                        </Tooltip>

                      </div>
                      <h2 style={{ position: 'absolute', top: '0', right: '12px' }}>
                        #{itemIndex + 1}
                      </h2>
                      <h3 style={{ position: 'absolute', bottom: '0', left: '12px' }}>4 ★ / {formatNumber((pricingSource == "Steam" ? item.CollectionPriceSteam : pricingSource == "Buff" ? item.CollectionPriceBuff : pricingSource == "CSFloat" ? item.CollectionPriceCSFloat : pricingSource == "Skinport" ? item.CollectionPriceSkinport : pricingSource == "Tradeit" ? item.CollectionPriceTradeit : item.CollectionPriceSteam) / exchangeRate, true)} → {formatNumber(((pricingSource == "Steam" ? item.CollectionPriceSteam * item.SteamROI : pricingSource == "Buff" ? item.CollectionPriceBuff * item.BuffROI : pricingSource == "CSFloat" ? item.CollectionPriceCSFloat * item.CSFloatROI : pricingSource == "Skinport" ? item.CollectionPriceSkinport * item.SkinportROI : pricingSource == "Tradeit" ? item.CollectionPriceTradeit * item.TradeitROI : item.CollectionPriceSteam * item.SteamROI) * (1 - (showFees ? marketFees[pricingSource] : 0))) / exchangeRate, true)} AVG</h3>
                      <img src={item.Image} style={{ pointerEvents: 'none', position: 'absolute', right: '0', width: 'auto', height: '100%', maxWidth: '90%', padding: '16px', objectFit: 'contain' }} />
                      <div style={{ bottom: '0', position: 'absolute', width: '100%', margin: '5px', paddingRight: '10px' }}>
                        {
                          (item.RarityValuesSteam != null && item.RarityChances != null && Object.keys(item?.RarityChances)?.length > 1) ?
                            // Progress bar with multiple colors represented by the values within rarityvaluesteam
                            <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', marginLeft: '-5px', marginRight: '-5px', marginTop: '4px', marginBottom: '-6px' }}>
                              <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', width: '100%', height: '6px', borderRadius: '4px', overflow: 'hidden', backgroundColor: 'rgb(0,0,0,0.3)', padding: '0px', flexWrap: 'nowrap' }}>
                                {Object.entries(item.RarityChances).map(([rarity, value], index) => {
                                  const size = (value * item.RarityValuesSteam[rarity]) / (item.SteamROI * (item.CollectionPriceSteam + item.KeyCostSteam)) * 100;
                                  const rarityKey = Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity;
                                  return (
                                    <div key={index} style={{ backgroundColor: `RGB(${redRarityNums[rarityKey]}, ${greenRarityNums[rarityKey]}, ${blueRarityNums[rarityKey]})`, width: `${size}%`, height: '100%', borderTopLeftRadius: index == 0 ? '4px' : '0px', borderBottomLeftRadius: index == 0 ? '4px' : '0px', borderTopRightRadius: index == Object.keys(item.RarityChances).length - 1 ? '4px' : '0px', borderBottomRightRadius: index == Object.keys(item.RarityChances).length - 1 ? '4px' : '0px', overflow: 'hidden' }}></div>
                                  );
                                })}
                              </div>
                            </div> :
                            <div style={{ height: '4px' }}>
                            </div>
                        }
                      </div>
                    </Link>
                  );
                })()}
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', flex: '3', minHeight: isMobileLayout ? '500px' : '180px', flexWrap: 'wrap' }}>
                {(() => {
                  const itemIndex = initialItems.findIndex(item => item.Name === "Gallery Case");
                  const item = initialItems[itemIndex];
                  let redNum;
                  let greenNum;
                  let blueNum;
                  const compareValue = ((pricingSource == "Steam" ? item.CollectionPriceSteam : pricingSource == "CSFloat" ? item.CollectionPriceCSFloat : pricingSource == "Tradeit" ? item.CollectionPriceTradeit : pricingSource == "Buff" ? item.CollectionPriceBuff : item.CollectionPriceSkinport) / (0.8 * marketRatio))
                  if (compareValue > 1.35) {
                    redNum = 223;
                    greenNum = 199;
                    blueNum = 0;
                  } else if (compareValue > 1.25) {
                    redNum = 187 + Math.abs(compareValue - 1.25) / 0.1 * 36;
                    greenNum = 23 + Math.abs(compareValue - 1.25) / 0.1 * 176;
                    blueNum = 26 - Math.abs(compareValue - 1.25) / 0.1 * 26;
                  } else if (compareValue > 1.15) {
                    redNum = 158 + Math.abs(compareValue - 1.15) / 0.1 * 29;
                    greenNum = 0 + Math.abs(compareValue - 1.15) / 0.1 * 23;
                    blueNum = 197 - Math.abs(compareValue - 1.15) / 0.1 * 171;
                  } else if (compareValue > 1) {
                    redNum = 104 + Math.abs(compareValue - 1) / 0.15 * 54;
                    greenNum = 50 - Math.abs(compareValue - 1) / 0.15 * 50;
                    blueNum = 234 - Math.abs(compareValue - 1) / 0.15 * 37;
                  } else {
                    redNum = 2 + Math.abs(compareValue) / 1 * 102;
                    greenNum = 79 - Math.abs(compareValue) / 1 * 29;
                    blueNum = 228 + Math.abs(compareValue) / 1 * 6;
                  }
                  return (
                    <Link to={item?.UrlName !== null && `/item/${item.UrlName}`} className="hover-lift" style={{ minWidth: '300px', borderRadius: '4px', flex: '1', margin: '4px', position: 'relative', background: `linear-gradient(145deg, RGB(${redNum}, ${greenNum}, ${blueNum}) 0%, black 100%)`, textDecoration: 'none', color: 'inherit', display: 'flex' }}>
                      <h2 style={{ position: 'absolute', marginLeft: '12px' }}>GALLERY CASE</h2>
                      <div style={{ display: 'flex', flexDirection: 'row', position: 'absolute', bottom: '40%', left: '0', height: '60px', width: '40%', justifyContent: 'center' }}>
                        <Tooltip title="Return on Investment for opening this container now">
                          <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', fontSize: isMobileLayout ? '0.6rem' : '0.9rem', lineHeight: 0.9, height: '100%', justifyContent: 'space-between' }}>
                            Star ROI
                            <img src={OpenBox} className="iconSVGMob" style={{ fill: 'green', width: '32px', height: '32px', margin: '2px' }} />
                            <h2 style={{ lineHeight: 0.5, margin: 0 }}>{formatNumber((((pricingSource == "Steam" ? item.CollectionPriceSteam : pricingSource == "CSFloat" ? item.CollectionPriceCSFloat : pricingSource == "Tradeit" ? item.CollectionPriceTradeit : pricingSource == "Buff" ? item.CollectionPriceBuff : item.CollectionPriceSkinport) / (0.8 * marketRatio)) * (1 - (showFees ? marketFees[pricingSource] : 0))) * 100)}%</h2>

                          </div>

                        </Tooltip>
                        {/* <Tooltip title="Chance for each unbox to be worth more than the cost of opening">
                        <div style={{ display: "flex", alignItems: "center", flex: '1', flexDirection: 'column', textAlign: 'center', fontSize: isMobileLayout ? '0.6rem' : '0.9rem', lineHeight: 0.9, height: '100%', justifyContent: 'space-between' }}>
                          Profit
                          <PriceCheckIcon className="iconSVG" sx={{ width: '32px', height: '32px', margin: '2px' }} fontSize='large' />
                          <h2 style={{ lineHeight: 0.5, margin: 0 }}>{formatNumber((pricingSource == "Steam" ? item.ProfitSteam : pricingSource == "CSFloat" ? item.ProfitCSFloat : pricingSource == "Tradeit" ? item.ProfitTradeit : pricingSource == "Buff" ? item.ProfitBuff : item.ProfitSkinport) * 100)}%</h2>
                        </div>

                      </Tooltip> */}

                      </div>
                      <h2 style={{ position: 'absolute', top: '0', right: '12px' }}>
                        #{itemIndex + 1}
                      </h2>
                      <h3 style={{ position: 'absolute', bottom: '0', left: '12px' }}>2 ★ / {formatNumber((0.8 * marketRatio) / exchangeRate, true)} → {formatNumber(((pricingSource == "Steam" ? item.CollectionPriceSteam : pricingSource == "Buff" ? item.CollectionPriceBuff : pricingSource == "CSFloat" ? item.CollectionPriceCSFloat : pricingSource == "Skinport" ? item.CollectionPriceSkinport : pricingSource == "Tradeit" ? item.CollectionPriceTradeit : item.CollectionPriceSteam) * (1 - (showFees ? marketFees[pricingSource] : 0))) / exchangeRate, true)} AVG</h3>
                      <img src={item.Image} style={{ pointerEvents: 'none', position: 'absolute', right: '0', width: 'auto', height: '100%', maxWidth: '90%', padding: '16px', objectFit: 'contain' }} />

                    </Link>
                  );
                })()}
                {(() => {
                  const itemIndex = initialItems.findIndex(item => item.Name === "Elemental Craft Stickers Collection");
                  const item = initialItems[itemIndex];
                  let redNum;
                  let greenNum;
                  let blueNum;
                  const compareValue = (pricingSource == "Steam" ? item.SteamROI : pricingSource == "CSFloat" ? item.CSFloatROI : pricingSource == "Tradeit" ? item.TradeitROI : pricingSource == "Buff" ? item.BuffROI : item.SkinportROI)
                  if (compareValue > 1.35) {
                    redNum = 223;
                    greenNum = 199;
                    blueNum = 0;
                  } else if (compareValue > 1.25) {
                    redNum = 187 + Math.abs(compareValue - 1.25) / 0.1 * 36;
                    greenNum = 23 + Math.abs(compareValue - 1.25) / 0.1 * 176;
                    blueNum = 26 - Math.abs(compareValue - 1.25) / 0.1 * 26;
                  } else if (compareValue > 1.15) {
                    redNum = 158 + Math.abs(compareValue - 1.15) / 0.1 * 29;
                    greenNum = 0 + Math.abs(compareValue - 1.15) / 0.1 * 23;
                    blueNum = 197 - Math.abs(compareValue - 1.15) / 0.1 * 171;
                  } else if (compareValue > 1) {
                    redNum = 104 + Math.abs(compareValue - 1) / 0.15 * 54;
                    greenNum = 50 - Math.abs(compareValue - 1) / 0.15 * 50;
                    blueNum = 234 - Math.abs(compareValue - 1) / 0.15 * 37;
                  } else {
                    redNum = 2 + Math.abs(compareValue) / 1 * 102;
                    greenNum = 79 - Math.abs(compareValue) / 1 * 29;
                    blueNum = 228 + Math.abs(compareValue) / 1 * 6;
                  }
                  return (
                    <Link to={item?.UrlName !== null && `/item/${item.UrlName}`} className="hover-lift" style={{ minWidth: '300px', borderRadius: '4px', flex: '1', margin: '4px', position: 'relative', background: `linear-gradient(145deg, RGB(${redNum}, ${greenNum}, ${blueNum}) 0%, black 100%)`, textDecoration: 'none', color: 'inherit', display: 'flex' }}>
                      <h2 style={{ position: 'absolute', marginLeft: '12px' }}>ELEMENTAL CRAFT STICKERS</h2>
                      <div style={{ display: 'flex', flexDirection: 'row', position: 'absolute', bottom: '40%', left: '0', height: '60px', width: '40%', justifyContent: 'center' }}>
                        <Tooltip title="Return on Investment for opening this container now">
                          <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', fontSize: isMobileLayout ? '0.6rem' : '0.9rem', lineHeight: 0.9, height: '100%', justifyContent: 'space-between' }}>
                            Star ROI
                            <img src={OpenBox} className="iconSVGMob" style={{ fill: 'green', width: '32px', height: '32px', margin: '2px' }} />
                            <h2 style={{ lineHeight: 0.5, margin: 0 }}>{formatNumber(((pricingSource == "Steam" ? item.SteamROI : pricingSource == "CSFloat" ? item.CSFloatROI : pricingSource == "Tradeit" ? item.TradeitROI : pricingSource == "Buff" ? item.BuffROI : item.SkinportROI) * (1 - (showFees ? marketFees[pricingSource] : 0))) * 100)}%</h2>

                          </div>

                        </Tooltip>
                        <Tooltip title="Chance for each unbox to be worth more than the cost of opening">
                          <div style={{ display: "flex", alignItems: "center", flex: '1', flexDirection: 'column', textAlign: 'center', fontSize: isMobileLayout ? '0.6rem' : '0.9rem', lineHeight: 0.9, height: '100%', justifyContent: 'space-between' }}>
                            Profit
                            <PriceCheckIcon className="iconSVG" sx={{ width: '32px', height: '32px', margin: '2px' }} fontSize='large' />
                            <h2 style={{ lineHeight: 0.5, margin: 0 }}>{formatNumber((pricingSource == "Steam" ? item.ProfitSteam : pricingSource == "CSFloat" ? item.ProfitCSFloat : pricingSource == "Tradeit" ? item.ProfitTradeit : pricingSource == "Buff" ? item.ProfitBuff : item.ProfitSkinport) * 100)}%</h2>
                          </div>

                        </Tooltip>

                      </div>
                      <h2 style={{ position: 'absolute', top: '0', right: '12px' }}>
                        #{itemIndex + 1}
                      </h2>
                      <h3 style={{ position: 'absolute', bottom: '0', left: '12px' }}>1 ★ / {formatNumber((pricingSource == "Steam" ? item.CollectionPriceSteam : pricingSource == "Buff" ? item.CollectionPriceBuff : pricingSource == "CSFloat" ? item.CollectionPriceCSFloat : pricingSource == "Skinport" ? item.CollectionPriceSkinport : pricingSource == "Tradeit" ? item.CollectionPriceTradeit : item.CollectionPriceSteam) / exchangeRate, true)} → {formatNumber(((pricingSource == "Steam" ? item.CollectionPriceSteam * item.SteamROI : pricingSource == "Buff" ? item.CollectionPriceBuff * item.BuffROI : pricingSource == "CSFloat" ? item.CollectionPriceCSFloat * item.CSFloatROI : pricingSource == "Skinport" ? item.CollectionPriceSkinport * item.SkinportROI : pricingSource == "Tradeit" ? item.CollectionPriceTradeit * item.TradeitROI : item.CollectionPriceSteam * item.SteamROI) * (1 - (showFees ? marketFees[pricingSource] : 0))) / exchangeRate, true)} AVG</h3>
                      <img src={item.Image} style={{ pointerEvents: 'none', position: 'absolute', right: '0', width: 'auto', height: '100%', maxWidth: '90%', padding: '16px', objectFit: 'contain' }} />
                      <div style={{ bottom: '0', position: 'absolute', width: '100%', margin: '5px', paddingRight: '10px' }}>
                        {
                          (item.RarityValuesSteam != null && item.RarityChances != null && Object.keys(item?.RarityChances)?.length > 1) ?
                            // Progress bar with multiple colors represented by the values within rarityvaluesteam
                            <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', marginLeft: '-5px', marginRight: '-5px', marginTop: '4px', marginBottom: '-6px' }}>
                              <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', width: '100%', height: '6px', borderRadius: '4px', overflow: 'hidden', backgroundColor: 'rgb(0,0,0,0.3)', padding: '0px', flexWrap: 'nowrap' }}>
                                {Object.entries(item.RarityChances).map(([rarity, value], index) => {
                                  const size = (value * item.RarityValuesSteam[rarity]) / (item.SteamROI * (item.CollectionPriceSteam + item.KeyCostSteam)) * 100;
                                  const rarityKey = Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity;
                                  return (
                                    <div key={index} style={{ backgroundColor: `RGB(${redRarityNums[rarityKey]}, ${greenRarityNums[rarityKey]}, ${blueRarityNums[rarityKey]})`, width: `${size}%`, height: '100%', borderTopLeftRadius: index == 0 ? '4px' : '0px', borderBottomLeftRadius: index == 0 ? '4px' : '0px', borderTopRightRadius: index == Object.keys(item.RarityChances).length - 1 ? '4px' : '0px', borderBottomRightRadius: index == Object.keys(item.RarityChances).length - 1 ? '4px' : '0px', overflow: 'hidden' }}></div>
                                  );
                                })}
                              </div>
                            </div> :
                            <div style={{ height: '4px' }}>
                            </div>
                        }
                      </div>
                    </Link>
                  );
                })()}
                {(() => {
                  const itemIndex = initialItems.findIndex(item => item.Name === "Character Craft Stickers Collection");
                  const item = initialItems[itemIndex];
                  let redNum;
                  let greenNum;
                  let blueNum;
                  const compareValue = (pricingSource == "Steam" ? item.SteamROI : pricingSource == "CSFloat" ? item.CSFloatROI : pricingSource == "Tradeit" ? item.TradeitROI : pricingSource == "Buff" ? item.BuffROI : item.SkinportROI)
                  if (compareValue > 1.35) {
                    redNum = 223;
                    greenNum = 199;
                    blueNum = 0;
                  } else if (compareValue > 1.25) {
                    redNum = 187 + Math.abs(compareValue - 1.25) / 0.1 * 36;
                    greenNum = 23 + Math.abs(compareValue - 1.25) / 0.1 * 176;
                    blueNum = 26 - Math.abs(compareValue - 1.25) / 0.1 * 26;
                  } else if (compareValue > 1.15) {
                    redNum = 158 + Math.abs(compareValue - 1.15) / 0.1 * 29;
                    greenNum = 0 + Math.abs(compareValue - 1.15) / 0.1 * 23;
                    blueNum = 197 - Math.abs(compareValue - 1.15) / 0.1 * 171;
                  } else if (compareValue > 1) {
                    redNum = 104 + Math.abs(compareValue - 1) / 0.15 * 54;
                    greenNum = 50 - Math.abs(compareValue - 1) / 0.15 * 50;
                    blueNum = 234 - Math.abs(compareValue - 1) / 0.15 * 37;
                  } else {
                    redNum = 2 + Math.abs(compareValue) / 1 * 102;
                    greenNum = 79 - Math.abs(compareValue) / 1 * 29;
                    blueNum = 228 + Math.abs(compareValue) / 1 * 6;
                  }
                  return (
                    <Link to={item?.UrlName !== null && `/item/${item.UrlName}`} className="hover-lift" style={{ minWidth: '300px', borderRadius: '4px', flex: '1', margin: '4px', position: 'relative', background: `linear-gradient(145deg, RGB(${redNum}, ${greenNum}, ${blueNum}) 0%, black 100%)`, textDecoration: 'none', color: 'inherit', display: 'flex' }}>
                      <h2 style={{ position: 'absolute', marginLeft: '12px' }}>CHARACTER CRAFT STICKERS</h2>
                      <div style={{ display: 'flex', flexDirection: 'row', position: 'absolute', bottom: '40%', left: '0', height: '60px', width: '40%', justifyContent: 'center' }}>
                        <Tooltip title="Return on Investment for opening this container now">
                          <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', fontSize: isMobileLayout ? '0.6rem' : '0.9rem', lineHeight: 0.9, height: '100%', justifyContent: 'space-between' }}>
                            Star ROI
                            <img src={OpenBox} className="iconSVGMob" style={{ fill: 'green', width: '32px', height: '32px', margin: '2px' }} />
                            <h2 style={{ lineHeight: 0.5, margin: 0 }}>{formatNumber(((pricingSource == "Steam" ? item.SteamROI : pricingSource == "CSFloat" ? item.CSFloatROI : pricingSource == "Tradeit" ? item.TradeitROI : pricingSource == "Buff" ? item.BuffROI : item.SkinportROI) * (1 - (showFees ? marketFees[pricingSource] : 0))) * 100)}%</h2>

                          </div>

                        </Tooltip>
                        <Tooltip title="Chance for each unbox to be worth more than the cost of opening">
                          <div style={{ display: "flex", alignItems: "center", flex: '1', flexDirection: 'column', textAlign: 'center', fontSize: isMobileLayout ? '0.6rem' : '0.9rem', lineHeight: 0.9, height: '100%', justifyContent: 'space-between' }}>
                            Profit
                            <PriceCheckIcon className="iconSVG" sx={{ width: '32px', height: '32px', margin: '2px' }} fontSize='large' />
                            <h2 style={{ lineHeight: 0.5, margin: 0 }}>{formatNumber((pricingSource == "Steam" ? item.ProfitSteam : pricingSource == "CSFloat" ? item.ProfitCSFloat : pricingSource == "Tradeit" ? item.ProfitTradeit : pricingSource == "Buff" ? item.ProfitBuff : item.ProfitSkinport) * 100)}%</h2>
                          </div>

                        </Tooltip>

                      </div>
                      <h2 style={{ position: 'absolute', top: '0', right: '12px' }}>
                        #{itemIndex + 1}
                      </h2>
                      <h3 style={{ position: 'absolute', bottom: '0', left: '12px' }}>1 ★ / {formatNumber((pricingSource == "Steam" ? item.CollectionPriceSteam : pricingSource == "Buff" ? item.CollectionPriceBuff : pricingSource == "CSFloat" ? item.CollectionPriceCSFloat : pricingSource == "Skinport" ? item.CollectionPriceSkinport : pricingSource == "Tradeit" ? item.CollectionPriceTradeit : item.CollectionPriceSteam) / exchangeRate, true)} → {formatNumber(((pricingSource == "Steam" ? item.CollectionPriceSteam * item.SteamROI : pricingSource == "Buff" ? item.CollectionPriceBuff * item.BuffROI : pricingSource == "CSFloat" ? item.CollectionPriceCSFloat * item.CSFloatROI : pricingSource == "Skinport" ? item.CollectionPriceSkinport * item.SkinportROI : pricingSource == "Tradeit" ? item.CollectionPriceTradeit * item.TradeitROI : item.CollectionPriceSteam * item.SteamROI) * (1 - (showFees ? marketFees[pricingSource] : 0))) / exchangeRate, true)} AVG</h3>
                      <img src={item.Image} style={{ pointerEvents: 'none', position: 'absolute', right: '0', width: 'auto', height: '100%', maxWidth: '90%', padding: '16px', objectFit: 'contain' }} />
                      <div style={{ bottom: '0', position: 'absolute', width: '100%', margin: '5px', paddingRight: '10px' }}>
                        {
                          (item.RarityValuesSteam != null && item.RarityChances != null && Object.keys(item?.RarityChances)?.length > 1) ?
                            // Progress bar with multiple colors represented by the values within rarityvaluesteam
                            <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', marginLeft: '-5px', marginRight: '-5px', marginTop: '4px', marginBottom: '-6px' }}>
                              <div style={{ display: "flex", alignItems: "center", flex: '1', justifyContent: 'center', width: '100%', height: '6px', borderRadius: '4px', overflow: 'hidden', backgroundColor: 'rgb(0,0,0,0.3)', padding: '0px', flexWrap: 'nowrap' }}>
                                {Object.entries(item.RarityChances).map(([rarity, value], index) => {
                                  const size = (value * item.RarityValuesSteam[rarity]) / (item.SteamROI * (item.CollectionPriceSteam + item.KeyCostSteam)) * 100;
                                  const rarityKey = Object.keys(otherRarityNames).includes(rarity) ? otherRarityNames[rarity] : rarity;
                                  return (
                                    <div key={index} style={{ backgroundColor: `RGB(${redRarityNums[rarityKey]}, ${greenRarityNums[rarityKey]}, ${blueRarityNums[rarityKey]})`, width: `${size}%`, height: '100%', borderTopLeftRadius: index == 0 ? '4px' : '0px', borderBottomLeftRadius: index == 0 ? '4px' : '0px', borderTopRightRadius: index == Object.keys(item.RarityChances).length - 1 ? '4px' : '0px', borderBottomRightRadius: index == Object.keys(item.RarityChances).length - 1 ? '4px' : '0px', overflow: 'hidden' }}></div>
                                  );
                                })}
                              </div>
                            </div> :
                            <div style={{ height: '4px' }}>
                            </div>
                        }
                      </div>
                    </Link>
                  );
                })()}


              </div>

            </div>

          }
          <p style={{ textAlign: 'center', lineHeight: '1', fontSize: '11px' }}>
            All containers, items and their corresponding images shown on this site are the property of Valve Corporation. CSROI.com does not guarantee that all probability calculations are correct as Valve Corporation controls all unboxing odds, this site serves merely to estimate the odds with community known data. Investing ROI serves only as an estimate based on historical data used on this site and should not be considered investing advice, past performance is not indicative of future performance. Some links on this website use affiliate systems that can earn a small affiliate commission for csroi.com.<br /><br />©2022 - 2025 CSROI.com<br /><br /><a href="http://www.onlinewebfonts.com">Unboxing Icon Provided by Online Web Fonts</a></p>

        </div>
      </div>
    </ThemeProvider>
  );
}

export default function Armory() {
  return <ArmoryPage />;
}