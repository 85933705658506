import * as React from 'react';
import CookieConsent from "react-cookie-consent";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import AutoSizer from 'react-virtualized-auto-sizer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
// import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Drawer from '@mui/material/Drawer';
import NotificationsIcon from '@mui/icons-material/Notifications';
// import { mainListItems, secondaryListItems } from './listItems';
import useMediaQuery from '@mui/material/useMediaQuery';
import { VariableSizeGrid as Grid } from 'react-window';
// import Chart from './Chart';
// import Deposits from './Deposits';
// import Orders from './Orders';
// import Case from './Case';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
// import FormControl from '@mui/material/FormControl';
// import CaseItems from './CaseItems';
import { Card, CardMedia, Chip, FormControl, Icon } from '@mui/material';
import { useState, useEffect } from 'react';
import { ToggleButtonGroup, ToggleButton, FormGroup, FormControlLabel, Checkbox, Switch } from '@mui/material';
import OpenBox from './OpenBox.svg';
import './Rankings.css'
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SettingsIcon from '@mui/icons-material/Settings';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import getSymbolFromCurrency from 'currency-symbol-map'
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import { useMemo } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { CaseGridItem } from './CaseGridItem';
import SortIcon from '@mui/icons-material/Sort';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import ReactGA from 'react-ga4';
import { FixedSizeGrid } from 'react-window';
import { AttachMoney, LocalShipping, PriceCheck } from '@mui/icons-material';
import { buffLink, csfloatLink, skinportLink, steamLink, tradeitLink } from './allLinks';

// ReactGA.pageview(window.location.pathname + window.location.search);


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const drawerWidth = 240;


const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const StyledToggleButton = styled(ToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: '#bb171a',
        marginLeft: '-1px',
    }
});

const CACHE_DURATION = 12 * 60 * 60 * 1000; // Cache duration in milliseconds (12 hours)

const wearCodeNames = {
    'FactoryNew': 'Factory New',
    'MinimalWear': 'Minimal Wear',
    'FieldTested': 'Field-Tested',
    'WellWorn': 'Well-Worn',
    'BattleScarred': 'Battle-Scarred',
}

const wearRanges = {
    0: 'Factory New',
    0.07: 'Minimal Wear',
    0.15: 'Field-Tested',
    0.38: 'Well-Worn',
    0.45: 'Battle-Scarred'
};

const calculatePrices = (inputType, allInputBuyorderCosts, exchangeRate, xnum, currBuyorders, selectedInputType) => {
    const num_items_resold = (1 / inputType.wear_chance) - 1;
    const base_price = allInputBuyorderCosts?.[inputType.item + " (" + wearCodeNames[selectedInputType.input_wear] + ")"] != null ? ((allInputBuyorderCosts?.[inputType.item + " (" + wearCodeNames[selectedInputType.input_wear] + ")"] * exchangeRate * 10)) : (currBuyorders?.[inputType.item + " (" + wearCodeNames[selectedInputType.input_wear] + ")"] != null ? (currBuyorders?.[inputType.item + " (" + wearCodeNames[selectedInputType.input_wear] + ")"]?.["price"] * exchangeRate * 10) : (inputType.cost));
    const resale_fee = (Math.max(Math.ceil(base_price * 0.13 * 100) / 100, 0.02)) * num_items_resold;
    const resale_price = base_price + resale_fee;
    const xtradeup_cost = (resale_price / 10) * xnum;
    return { base_price, resale_price, num_items_resold, xtradeup_cost };
};




function DashboardContent() {
    //   const data = await fetchData();
    const [open, setOpen] = React.useState(true);
    const [types, setTypes] = useState([
        "Case"
    ]);
    const [currencies, setCurrencies] = useState([]);
    const [openSettings, setOpenSettings] = useState(true);
    const storedTradeitHideUnavailable = (localStorage.getItem('tradeitHide') ?? "true") == "true";
    const [tradeitHideUnavailable, setTradeitHideUnavailable] = useState(storedTradeitHideUnavailable ?? true);
    const storedSortDirection = (localStorage.getItem('sortDirection') ?? "true") == "true";
    const [sortDirection, setSortDirection] = useState(storedSortDirection ?? true);
    const storedShowUnbox = (localStorage.getItem('showUnbox') ?? "true") == "true";
    const [showUnbox, setShowUnbox] = useState(storedShowUnbox ?? true);
    const storedShowProfit = (localStorage.getItem('showProfit') ?? "true") == "true";
    const [showProfit, setShowProfit] = useState(storedShowProfit ?? true);
    const storedShowInvest = (localStorage.getItem('showInvest') ?? "true") == "true";
    const [showInvest, setShowInvest] = useState(storedShowInvest ?? true);
    const storedShowListings = (localStorage.getItem('showListings') ?? "true") == "true";
    const [showListings, setShowListings] = useState(storedShowListings ?? true);
    var storedSort = localStorage.getItem('sortBy');
    storedSort = storedSort == "invest6m" ? "invest" : storedSort == "invest1m" ? "invest" : storedSort;
    const [sortBy, setSortBy] = useState(storedSort || "unbox");
    const [stattrak, setStattrak] = useState(false);
    const storedCurrency = localStorage.getItem('currency')
    // || new Intl.NumberFormat(navigator.language, {style: 'currency'}).resolvedOptions().currency;
    const [currency, setCurrency] = useState(storedCurrency || 'usd');
    const cachedUSDExchangeRate = localStorage.getItem('usdExchangeRate');
    const [exchangeRate, setExchangeRate] = useState(cachedUSDExchangeRate || 1);


    const cachedPricingSource = localStorage.getItem('pricingSource');
    const [pricingSource, setPricingSource] = useState(cachedPricingSource || 'Steam');
    const [currencySymbol, setCurrencySymbol] = useState('');
    const cachedSearch = localStorage.getItem('search');
    const [inputValue, setInputValue] = useState(cachedSearch || '');
    const [selectedValue, setSelectedValue] = useState(null);
    const [initialItems, setInitialItems] = useState([
    ]);
    const [chainTradeupsPossible, setChainTradeupsPossible] = useState(null);
    // const cachedAllCases = localStorage.getItem('allCases');
    // const [allCases, setAllCases] = useState(cachedAllCases || {});
    // const cachedAllLowCosts = localStorage.getItem('allLowCosts');
    // const [allLowCosts, setAllLowCosts] = useState(cachedAllLowCosts || {});
    const storedInvestType = localStorage.getItem('investType');
    const [investType, setInvestType] = useState(storedInvestType || "1y");
    // const theme = useTheme();
    const [openAdvanced, setOpenAdvanced] = React.useState(false);
    const [selectedCaseName, setSelectedCaseName] = React.useState(null);
    const [selectedCaseId, setSelectedCaseId] = React.useState(null);
    const [selectedRarity, setSelectedRarity] = React.useState(null);
    const [selectedTradeup, setSelectedTradeup] = React.useState(null);
    const [selectedTradeupFloat, setSelectedTradeupFloat] = React.useState(null);
    const [selectedTradeupOutput, setSelectedTradeupOutput] = React.useState(null);
    const [selectedChainFloat, setSelectedChainFloat] = React.useState(null);
    const [selectedChainFiller, setSelectedChainFiller] = React.useState(null);
    const [allInputBuyorderCosts, setAllInputBuyorderCosts] = React.useState({});
    const [selectedInput, setSelectedInput] = React.useState(null);
    const [tradeupInputName, setTradeupInputName] = React.useState(null);
    const [ratios, setRatios] = useState({});

    function formatNumber(number, isCurrency, maximumFractionDigits = 2) {
        // Use the user's locale
        const userLocale = navigator.language || 'en-US';

        // Create Intl.NumberFormat with user's locale
        if (isCurrency) {
            const formatter = new Intl.NumberFormat(userLocale, {
                style: "currency",
                currency: currency,
                maximumFractionDigits: number > 1000 ? 0 : number > 100 ? 1 : maximumFractionDigits,
            });
            return formatter.format(number);
        } else {
            const formatter = new Intl.NumberFormat(userLocale, {
                maximumFractionDigits: number > 1000 ? 0 : number > 100 ? 1 : maximumFractionDigits,
            });
            return formatter.format(number);
        }
    }


    const handleDrawerOpen = () => {
        setOpenAdvanced(true);
    };

    const toggleAdvanced = () => {
        setOpenAdvanced(!openAdvanced);
    };

    const handleCostChange = (e, inputType, input_wear) => {
        const newCost = parseFloat(e.target.value);
        setAllInputBuyorderCosts(prevCosts => ({
            ...prevCosts,
            [inputType.item + " (" + wearCodeNames[input_wear] + ")"]: newCost
        }));
    };

    const items = useMemo(() => {
        const searchTerms = inputValue.toLowerCase().split(' ').filter(term => term); // Split input into search terms

        const filteredItems = initialItems.filter(
            (x) => types.includes(x.CollectionType) &&
                searchTerms.every(term => x.Name.toLowerCase().includes(term)) && // Ensure all search terms are in the name
                ((x.TradeitEstimateCase !== true || tradeitHideUnavailable) || pricingSource !== "Tradeit")
        );

        const sortKeyMap = {
            unbox: pricingSource + 'ROI',
            cost: 'CollectionPrice' + pricingSource,
            profit: 'Profit' + pricingSource,
            invest: pricingSource + 'iROI',
            invest1M: pricingSource + '1MiROI',
            invest6M: pricingSource + '6MiROI',
            dropType: 'DropType',
            numListings: 'NumListings'
        };

        const sortKey = sortKeyMap[sortBy == "invest" ? investType == "1y" ? sortBy : investType == "6m" ? "invest6M" : "invest1M" : sortBy];
        const sortedItems = [...filteredItems].sort((a, b) => {
            let aValue = a[sortKey];
            let bValue = b[sortKey];

            if (sortBy === "unbox") {
                aValue *= a.CollectionType === "Collection" ? 0.001 : 1;
                bValue *= b.CollectionType === "Collection" ? 0.001 : 1;
            }

            if (sortBy === "dropType") {
                return aValue.localeCompare(bValue);
            }

            return bValue - aValue;
        });

        if (!sortDirection) {
            sortedItems.reverse();
        }

        return sortedItems;
    }, [initialItems, inputValue, sortBy, investType, pricingSource, sortDirection, types, tradeitHideUnavailable]);



    useEffect(() => {
        const fetchData = async () => {

            let url = '/pastData/allTrackedCases.json';

            if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
                url = 'http://localhost:8080/pastData/allTrackedCases.json';
            }

            const response = await fetch(url);
            const text = await response.text();
            const processedData = JSON.parse(text);

            setInitialItems(processedData);
        };
        fetchData();
    }, []);


    // useEffect(() => {
    //   const fetchData = async () => {

    //     let url = '/pastData/allTrackedCases.json';

    //     if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
    //       url = 'https://localhost:44396/jsondata';
    //     }

    //     const response = await fetch(url);
    //     const text = await response.text();
    //     // const processedText = `[${text.slice(0, -3)}]`;
    //     const processedData = JSON.parse(text);
    //     // console.log(processedData);




    //     // setItems(processedData.filter((x) => types.includes(x.CollectionType)));
    //     setInitialItems(processedData);

    //     //   setData(finalData)
    //   };
    //   fetchData();
    // }, []);

    const [isMobileLayout, setIsMobileLayout] = useState(false);

    useEffect(() => {
        // Initial calculation
        handleResize();

        // Add event listener for resize
        window.addEventListener("resize", handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);



    const handleResize = () => {
        // Calculate aspect ratio 
        const glanceInfoSize = window.innerWidth * 2 / 11

        // Define the threshold value for aspect ratio to differentiate between mobile and desktop
        const glanceInfoThreshold = 215; // Adjust this value based on your needs

        // Set the state based on the aspect ratio
        setIsMobileLayout(glanceInfoSize < glanceInfoThreshold);
    };
    // useEffect(() => {
    //   setItems(initialItems.filter((x) => types.includes(x.CollectionType)).filter((x) => x.Name.toLowerCase().includes(inputValue.toLowerCase())).sort((a,b) => sortBy === "unbox" ? (pricingSource === "Buff" ?  (b['BuffROI'] - a['BuffROI']) : (b['SteamROI'] - a['SteamROI'])): (pricingSource === "Buff" ?  (b['BuffiROI'] - a['BuffiROI']) : (b['SteamiROI'] - a['SteamiROI']))))
    // }, [types, inputValue, sortBy])
    const handleTradeitHide = (sortTest) => {
        setTradeitHideUnavailable(sortTest);
        localStorage.setItem('tradeitHide', sortTest.toString());
        // setSelectedItem(item);
        // setSelectedRarity(rarity)
    };

    const handleSort = (event, sort) => {
        setSortBy(sort.props.value);
        localStorage.setItem('sortBy', sort.props.value);
        // setSelectedItem(item);
        // setSelectedRarity(rarity)
    };

    const handleSortDirection = (sortTest) => {
        setSortDirection(sortTest);
        localStorage.setItem('sortDirection', sortTest.toString());
        // setSelectedItem(item);
        // setSelectedRarity(rarity)
    };

    const handleShowUnbox = (sortTest) => {
        setShowUnbox(sortTest);
        localStorage.setItem('showUnbox', sortTest.toString());
        // setSelectedItem(item);
        // setSelectedRarity(rarity)
    };

    const handleShowProfit = (sortTest) => {
        setShowProfit(sortTest);
        localStorage.setItem('showProfit', sortTest.toString());
        // setSelectedItem(item);
        // setSelectedRarity(rarity)
    };

    const handleShowInvest = (sortTest) => {
        setShowInvest(sortTest);
        localStorage.setItem('showInvest', sortTest.toString());
        // setSelectedItem(item);
        // setSelectedRarity(rarity)
    };


    const handleShowListings = (sortTest) => {
        setShowListings(sortTest);
        localStorage.setItem('showListings', sortTest.toString());
        // setSelectedItem(item);
        // setSelectedRarity(rarity)
    };

    const handleChange = (type) => {
        const typeValue = type.target.value;
        let newTypes;

        if (!types.includes(typeValue)) {
            newTypes = [...types, typeValue];
            if (typeValue === "Graffiti") {
                newTypes.push("Pins");
            }
            // if (typeValue === "Past Operation") {
            //   newTypes.push("Armory");
            // }
        } else {
            newTypes = types.filter(x => x !== typeValue);
            if (typeValue === "Graffiti") {
                newTypes = newTypes.filter(x => x !== "Pins");
            }
            // if (typeValue === "Past Operation") {
            //   newTypes = newTypes.filter(x => x !== "Armory");
            // }
        }

        localStorage.setItem('cachedTypes', JSON.stringify(newTypes));
        setTypes(newTypes);
    };
    // const handleChange = (type) => {

    //   if (!types.includes(type.target.value)) {
    //     localStorage.setItem('cachedTypes', JSON.stringify([...types, type.target.value, "Pins"]));
    //     setTypes([...types, type.target.value, "Pins"])
    //     // Find all elements with a certain class
    //     const elements = document.querySelectorAll(type.target.value);

    //     // Loop through each element and hide it
    //     elements.forEach((element) => {
    //       element.style.display = 'initial'; // or 'hidden' depending on your needs
    //     });
    //   } else {
    //     localStorage.setItem('cachedTypes', JSON.stringify(types.filter((x) => x !== type.target.value && x !== "Pins")));
    //     setTypes(types.filter((x) => x !== type.target.value && x !== "Pins"))
    //     // Find all elements with a certain class
    //     const elements = document.querySelectorAll(type.target.value);

    //     // Loop through each element and hide it
    //     elements.forEach((element) => {
    //       element.style.display = 'none'; // or 'hidden' depending on your needs
    //     });
    //   }

    //   // if (type.target.value == "Graffiti") {
    //   //   if (!types.includes(type.target.value)) {
    //   //     localStorage.setItem('cachedTypes', JSON.stringify([...types, type.target.value, "Pins"]));
    //   //     setTypes([...types, type.target.value, "Pins"])
    //   //   } else {
    //   //     localStorage.setItem('cachedTypes', JSON.stringify(types.filter((x) => x !== type.target.value && x !== "Pins")));
    //   //     setTypes(types.filter((x) => x !== type.target.value && x !== "Pins"))
    //   //   }
    //   // } else {
    //   //   if (!types.includes(type.target.value)) {
    //   //     localStorage.setItem('cachedTypes', JSON.stringify([...types, type.target.value]));
    //   //     setTypes([...types, type.target.value])
    //   //   } else {
    //   //     localStorage.setItem('cachedTypes', JSON.stringify(types.filter((x) => x !== type.target.value)));
    //   //     setTypes(types.filter((x) => x !== type.target.value))
    //   //   }
    //   // }
    useEffect(() => {
        const fetchData = async () => {
            var url = 'http://localhost:8080/chainTradeups/' + selectedCaseId + '/positive_chain_besttradeups_resale_filtered_results' + (stattrak ? '_stattrak' : '') + '.json';
            const response = await fetch(url);
            const text = await response.text();
            const processedData = JSON.parse(text);

            setChainTradeupsPossible(processedData);
        };
        if (selectedCaseId != null) {
            fetchData();
        }
    }, [selectedCaseId, stattrak]);

    useEffect(() => {
        const fetchRatios = async () => {
            try {
                let url = '/marketRatios.json';

                if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
                    url = 'http://localhost:8080/marketRatios.json';
                }

                const lastFetched = localStorage.getItem('lastFetched');
                // var oldRange = JSON.parse(localStorage.getItem('dealsRange'));
                // if (oldRange == null) {
                var oldRange = [0, 10000];
                // }
                // setRangeValue(oldRange);
                const currentTime = new Date().getTime();
                const fiveMinutes = 5 * 60 * 1000;

                if (!lastFetched || (currentTime - lastFetched) >= fiveMinutes) {
                    url += `?t=${new Date().getTime()}`;
                }
                const response = await fetch(url);
                const data = await response.json();
                setRatios(data);
            } catch (error) {
                console.error('Failed to fetch ratios:', error);
            }
        };

        fetchRatios();
    }, []);
    // };
    useEffect(() => {
        const fetchCurrencies = async () => {
            try {
                const cachedCurrencies = localStorage.getItem('currencies');
                const cachedTimestamp = localStorage.getItem('currenciesTimestamp');

                if (
                    cachedCurrencies &&
                    cachedTimestamp &&
                    Date.now() - Number(cachedTimestamp) < CACHE_DURATION
                ) {
                    setCurrencies(JSON.parse(cachedCurrencies));
                } else {
                    let url = '/currencies.json'

                    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
                        url = 'https://csroi.com/currencies.json';
                    }

                    const response = await fetch(url);
                    const data = await response.json();
                    const currencyList = Object.keys(data);
                    setCurrencies(currencyList);

                    localStorage.setItem('currencies', JSON.stringify(currencyList));
                    localStorage.setItem('currenciesTimestamp', Date.now().toString());
                }
            } catch (error) {
                console.error('Failed to fetch currencies:', error);
            }
        };



        const updateExchangeRateToUSD = async () => {
            try {
                const cachedCurrency = localStorage.getItem('currency');
                const cachedUSDExchangeRate = localStorage.getItem('usdExchangeRate');
                const cachedTimestamp = localStorage.getItem('usdExchangeRateTimestamp');
                // const cachedAllCases = localStorage.getItem('allCases');
                // const cachedAllLowCosts = localStorage.getItem('allLowCosts');

                if (
                    cachedCurrency &&
                    currency == cachedCurrency &&
                    cachedUSDExchangeRate &&
                    cachedTimestamp &&
                    Date.now() - Number(cachedTimestamp) < CACHE_DURATION
                ) {
                    const correspondingMarketRatio = pricingSource == "Steam" ? 1 : (ratios[pricingSource.toLowerCase() == "tradeit" ? "tradeit_withdraw" : pricingSource.toLowerCase()]["easily_achievable_case"]) * 0.87 * (pricingSource == "cstrade" ? 1.5 : pricingSource == "lootfarm" ? 1.03 : 1);
                    const usdMarketExchangeRate = cachedUSDExchangeRate / correspondingMarketRatio;
                    setCurrency(cachedCurrency);
                    // setPricingSource('USD');
                    setExchangeRate(usdMarketExchangeRate);
                    setSelectedInput(null);
                    setTradeupInputName(null);
                    setAllInputBuyorderCosts({});
                    // setAllCases(JSON.parse(cachedAllCases));
                    // setAllLowCosts(JSON.parse(cachedAllLowCosts));
                } else {
                    const response = await fetch(
                        `https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/${currency.toLowerCase()}.json`
                    );
                    const data = await response.json();
                    const usdExchangeRate = data[currency.toLowerCase()]['usd'];
                    const correspondingMarketRatio = pricingSource == "Steam" ? 1 : ((ratios[pricingSource.toLowerCase() == "tradeit" ? "tradeit_withdraw" : pricingSource.toLowerCase()]["easily_achievable_case"]) * 0.87 * (pricingSource == "cstrade" ? 1.5 : 1));
                    const usdMarketExchangeRate = usdExchangeRate / correspondingMarketRatio;
                    // const corresponding
                    // console.log(data);

                    console.log(currency);
                    console.log(usdExchangeRate);

                    setCurrency(currency);
                    // setPricingSource('USD');
                    setExchangeRate(usdMarketExchangeRate);
                    setSelectedInput(null);
                    setTradeupInputName(null);
                    setAllInputBuyorderCosts({});

                    localStorage.setItem('currency', currency);
                    localStorage.setItem('usdExchangeRate', usdExchangeRate);
                    localStorage.setItem('usdExchangeRateTimestamp', Date.now().toString());

                    //Piggybacking data for all cases to update daily:
                    // let caseurl = '/allCases.json'

                    // if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
                    //   caseurl = 'https://csroi.com/allCases.json';
                    // }

                    // const responseAllCases = await fetch(caseurl);
                    // const textAllCases = await responseAllCases.text();

                    // const processedText = `[${text.slice(0, -3)}]`;
                    // const processedDataAllCases = JSON.parse(textAllCases);

                    //Piggybacking data for all cases to update daily:
                    // let costUrl = '/allLowCosts.json'

                    // if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
                    //   costUrl = 'https://csroi.com/allLowCosts.json';
                    // }

                    // const responseLowCosts = await fetch(costUrl);
                    // const textLowCosts = await responseLowCosts.text();

                    // // const processedText = `[${text.slice(0, -3)}]`;
                    // const processedDataLowCosts = JSON.parse(textLowCosts);

                    // setAllCases(processedDataAllCases);
                    // setAllLowCosts(processedDataLowCosts);
                    // console.log(textAllCases);
                    // console.log(textLowCosts);
                    // localStorage.setItem('allCases', textAllCases);
                    // localStorage.setItem('allLowCosts', textLowCosts);
                }
            } catch (error) {
                console.error('Failed to update exchange rate to USD:', error);
            }
        };

        fetchCurrencies();
        updateExchangeRateToUSD();
    }, [currency, pricingSource]);

    useEffect(() => {
        setCurrencySymbol(getSymbolFromCurrency(currency));
    }, [currency]);

    useEffect(() => {
        if (!window.location.href.includes('localhost')) {
            ReactGA.send({ hitType: "pageview", page: "/", title: "Rankings" });
            // ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }, []);

    // const toggleDrawer = (open) => (event) => {
    //   setDrawerOpen(!drawerOpen);
    // };



    const theme = createTheme({
        palette: {
            mode: 'dark',
        },
        typography: {
            fontFamily: 'Barlow',
        }
    });

    const ColumnCountContext = React.createContext();

    const [drawerOpen, setDrawerOpen] = useState(false);



    const handleCurrencyChange = (event, newCurrency) => {
        setCurrency(newCurrency);
    };

    const handlePricingSourceChange = (event, newPricingSource) => {
        localStorage.setItem('pricingSource', newPricingSource.props.value)
        setPricingSource(newPricingSource.props.value);
    };


    const handleInputChange = (event, newInputValue) => {
        localStorage.setItem('search', newInputValue);
        setInputValue(newInputValue);
    };

    const handleSearchChange = (event, newInputValue, reason) => {

        if (reason == "selectOption") {
            window.open("/#/item/" + newInputValue.UrlName, "_self")

        }

    };

    const handleBlur = () => {
        if (inputValue.trim() !== '') {
            setSelectedValue(inputValue);
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'selectOption') {
            setSelectedValue(inputValue);
        }
    };

    const handleRarityClick = (rarity) => {
        setSelectedRarity(rarity);
        setSelectedTradeupFloat(null);
        setSelectedChainFloat(null);
        setSelectedTradeup(null);
        setSelectedInput(null);
        setTradeupInputName(null);
    };

    const handleTradeupFloatClick = (selectedFloat) => {
        setSelectedTradeupFloat(selectedFloat);
        setSelectedChainFloat(null);
        setSelectedTradeup(null);
        setSelectedInput(null);
        setTradeupInputName(null);
    };

    const handleTradeupOutputClick = (selectedOutput) => {
        setSelectedTradeupOutput(selectedOutput);
    };

    const MemoizedCaseGridItem = React.memo(CaseGridItem);

    function Cell({ columnIndex, rowIndex, style }) {
        const columnCount = React.useContext(ColumnCountContext);
        const index = rowIndex * (columnCount) + columnIndex;
        const item = items[index];

        const handleClick = (event) => {
            event.stopPropagation(); // Stop the event from propagating
            // Your custom onClick logic here
            setSelectedCaseId(item.CollectionId);
            setSelectedCaseName(item.Name);
            setSelectedRarity(null);
            setSelectedTradeupFloat(null);
            setSelectedChainFloat(null);
            setSelectedTradeup(null);
            setSelectedInput(null);
            setTradeupInputName(null);
        };

        return item ? (
            <div onClick={handleClick} style={style}>
                <div style={{ margin: '2px', flex: 6, flexWrap: 'wrap', justifyContent: 'center', padding: 0, maxWidth: '800px', pointerEvents: 'none' }}>
                    <MemoizedCaseGridItem
                        key={item.id}
                        item={item}
                        index={index}
                        pricingSource={pricingSource}
                        currency={currency}
                        exchangeRate={exchangeRate}
                        sortBy={sortBy}
                        isMobileLayout={isMobileLayout}
                        showUnbox={showUnbox}
                        showProfit={showProfit}
                        showInvest={showInvest}
                        numListings={item.NumListings}
                        showListings={showListings}
                        investType={investType}
                        isCaseEstimate={pricingSource == "Tradeit" ? item?.TradeitEstimateCase : false}
                    // Pass the custom onClick handler here
                    />
                </div>
            </div>
        ) : null;
    }

    // function Cell({ columnIndex, rowIndex, style }) {
    //   const columnCount = React.useContext(ColumnCountContext);

    //   const index = rowIndex * (columnCount) + columnIndex;
    //   const item = items[index];
    //   return item ? (
    //     <div style={style}>
    //       <div style={{ margin: '2px', flex: 6, flexWrap: 'wrap', justifyContent: 'center', padding: 0, maxWidth: '800px' }}>
    //         <CaseGridItem
    //           key={item.id}
    //           item={item}
    //           index={index}
    //           pricingSource={pricingSource}
    //           currency={currency}
    //           exchangeRate={exchangeRate}
    //           sortBy={sortBy}
    //           isMobileLayout={isMobileLayout}
    //           showUnbox={showUnbox}
    //           showProfit={showProfit}
    //           showInvest={showInvest}
    //           numListings={item.NumListings}
    //           showListings={showListings}
    //         />
    //       </div>
    //     </div>
    //   ) : null;
    // }

    const matchesXS = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const matchesSM = useMediaQuery(theme => theme.breakpoints.between('sm', 'md'));
    const matchesMD = useMediaQuery(theme => theme.breakpoints.between('md', 'lg'));
    const matchesLG = useMediaQuery(theme => theme.breakpoints.up('lg'));

    return (
        <ThemeProvider theme={theme}>
            <CookieConsent
                location="bottom"
                buttonText="I understand"
                cookieName="garequest"
                style={{ background: "#2B373B", zIndex: 99999 }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
            >
                This website uses cookies for anonymous Google Analytics. By continuing to use this website, you consent to the use of cookies.
            </CookieConsent>
            <title>CSROI.com | Find The Best Cases, Capsules, Armory Rewards and More to Invest and Unbox!</title>
            <meta name="description" content="Find the Unboxing Return, Chance to Profit, Investing Return and Market listings alongside in depth historical data to find YOUR favourite CS2 cases, armory rewards, capsules, souvenirs, patches, collections and more!" />
            <meta name="keywords" content="cs2, csgo, roi, case, capsule, souvenir, sticker, armory, stars, invest, pin, graffiti, autographs, listings, profit, unbox, simulator, history, music kit, collection, operation"></meta>
            <Box sx={{ display: 'flex', padding: 0, transition: "width 5s infinite alternate, height 5s infinite alternate", }}>
                <CssBaseline />
                <Drawer
                    sx={{
                        display: isMobileLayout ? 'none' : 'initial',
                        width: drawerWidth,
                        // flexShrink: 0,

                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="permanent"
                    anchor="left"
                // open={openDrawer}
                >
                    <Scrollbars
                        autoHide
                        // autoHideTimeout={1000}
                        // autoHideDuration={200}
                        style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
                        renderView={props => <div {...props} style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }} />}
                        renderThumbVertical={({ style }) => (
                            <div style={{ ...style, backgroundColor: 'lightgray', borderRadius: '4px' }} />
                        )}
                    >
                        <DrawerHeader sx={{ paddingTop: '12px', paddingBottom: '12px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: 'auto', marginBottom: '6px', marginTop: '6px', }}>
                                <img src="/csroi.png" class="p-2" style={{ maxWidth: '280px', width: '100%', height: "auto", objectFit: 'contain', margin: 'auto' }} />

                            </div>


                        </DrawerHeader>
                        <Divider style={{ borderWidth: '2px' }} />
                        {/* <h5 style={{ textAlign: 'center', maxHeight: '180px', padding: '4px' }}>
              Welcome to the all new CSROI.com!<br /><br />
              Featuring a new overhauled React UI, full currency conversion, a new Investing ROI, upgraded simulator, among many more!<br /><br />
              Any feedback is appreciated <a href="https://twitter.com/BrongoCS" target='_blank'>@BrongoCS</a>
            </h5> */}
                        {/* <Divider /> */}
                        {/* <iframe width={drawerWidth} height="180" src="https://www.youtube.com/embed/mMCqwpVC2Zc?si=1DY9BEJKcLe66qy3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;" allowFullScreen ></iframe> */}
                        <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.12)' }}>
                                <p>
                                    Rankings

                                </p>
                            </div>
                        </Link>
                        <Divider style={{ borderWidth: '2px' }} />
                        <Link to='/armory' style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer' }}>
                                <p style={{ lineHeight: '0.9' }}>
                                    Armory
                                    <br />
                                    <span style={{ fontSize: '0.7rem' }}>Find the best way to spend YOUR Armory Stars</span>
                                </p>
                                <Chip label="NEW" variant="outlined" size='small' sx={{ color: 'white', border: '1px white solid', fontSize: '0.8rem', height: '24px', width: '48px', padding: '0' }} />

                            </div>
                        </Link>
                        <Divider />
                        <Link to='/charmcolors' style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer', }}>
                                <p style={{ lineHeight: '0.9' }}>
                                    Charm Colors
                                    <br />
                                    <span style={{ fontSize: '0.7rem' }}>Find the color and pattern of charm to match your skin!</span>
                                </p>
                                <Chip label="NEW" variant="outlined" size='small' sx={{ color: 'white', border: '1px white solid', fontSize: '0.8rem', height: '24px', width: '48px', padding: '0' }} />

                            </div>
                        </Link>
                        <Divider />
                        <Link to='/deals' style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer' }}>
                                <p style={{ lineHeight: '0.9' }}>
                                    Deals
                                    <br />
                                    <span style={{ fontSize: '0.7rem' }}>Resell Skins to get up to 1.5x Steam Wallet Balance!</span>
                                </p>
                            </div>
                        </Link>
                        <Divider />
                        <Link to='/inventory' style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer' }}>
                                <p style={{ lineHeight: '0.9' }}>
                                    Inventory
                                    <br />
                                    <span style={{ fontSize: '0.7rem', }}>Find YOUR Unboxing and Investing ROI!</span>
                                </p>
                                {/* <Chip label="NEW" variant="outlined" size='small' sx={{ color: 'white', border: '1px white solid', fontSize: '0.8rem', height: '24px', width: '48px', padding: '0' }} /> */}
                            </div>
                        </Link>
                        <Divider />


                        {/* <p style={{textAlign: 'center', margin: '0'}}> */}
                        <div style={{ flex: '1' }}></div>


                        <Divider />

                        {/* {(!isMobileLayout || openSettings) && <div style={{ display: 'flex', marginTop: '8px', marginBottom: '8px' }}> */}

                        <FormControl sx={{ fontFamily: "'Barlow', 'Roboto', sans-serif", padding: '0 12px 8px 12px', marginTop: '12px', width: '100%' }}>
                            <InputLabel id="pricing-source-label" sx={{ paddingLeft: '14px' }}>Pricing Source</InputLabel>
                            <Select
                                //  renderInput={(params) => <TextField {...params} label="Pricing Source" />}

                                labelId="pricing-source-label"
                                id="pricing-source"
                                value={pricingSource}
                                label="Pricing Source"
                                onChange={handlePricingSourceChange}

                            >
                                <MenuItem value="Steam">Steam</MenuItem>
                                <MenuItem value="Buff">
                                    <div>
                                        Buff.163 / UUPin<Tooltip title="Buff.163 prices are preferred, but UUPin prices are used when Buff.163 prices are unavailable (e.g., containers) or when there is a significant price difference." sx={{ margin: 'auto' }}>

                                            <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '4px', marginBottom: '-4px' }} fontSize='large' />
                                        </Tooltip>
                                    </div>
                                </MenuItem>
                                <MenuItem value="CSFloat">CSFloat</MenuItem>
                                <MenuItem value="Skinport">Skinport</MenuItem>
                                <MenuItem value="cstrade">CSTrade</MenuItem>
                                <MenuItem value="swapgg">Swap.gg</MenuItem>
                                <MenuItem value="lootfarm">Loot.farm</MenuItem>
                                <MenuItem value="csmoney">CS.Money</MenuItem>
                                <MenuItem value="Tradeit">
                                    <div>
                                        Tradeit.gg
                                        <Tooltip title="Tradeit.gg pricing reflects the cost to withdraw containers and trade back unboxed items. Estimated prices, marked in grey, are used when specific item prices are unavailable." sx={{ margin: 'auto' }}>

                                            <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '4px', marginBottom: '-4px' }} fontSize='large' />
                                        </Tooltip></div>
                                </MenuItem>
                                {/* Add more pricing source options if needed */}
                            </Select>
                        </FormControl>
                        {
                            pricingSource === "Tradeit" &&
                            <FormGroup sx={{ padding: '0 8px 12px 16px', fontSize: '0.8rem' }}>
                                <FormControlLabel sx={{ fontSize: '0.8rem' }} control={<Checkbox checked={tradeitHideUnavailable} onClick={() => handleTradeitHide(!tradeitHideUnavailable)} />} label="Show Unavailable" />
                            </FormGroup>
                        }
                        <Autocomplete
                            value={currency}
                            onChange={(event, newValue) => setCurrency(newValue)}
                            disableClearable
                            options={currencies}
                            getOptionLabel={(option) => option.toUpperCase()}
                            sx={{ fontFamily: "'Barlow', 'Roboto', sans-serif", padding: '0 12px 12px 12px' }}
                            renderInput={(params) => <TextField {...params} label="Currency" />}
                        />

                        {/* </div>} */}
                        <Divider />
                        {!isMobileLayout && items.length > 0 &&
                            <p style={{
                                margin: '12px',
                                textAlign: 'center',
                                backgroundColor: "#121212",
                                border: 'rgba(255, 255, 255, 0.23) 1px solid',
                                color: 'white',
                                padding: '.25rem .75rem',
                                borderRadius: '0.25rem'
                            }} >
                                Updated<br />
                                {(() => {
                                    const updatedAtUTC = items[0]["UpdatedAt"] * 1000;
                                    const now = new Date();
                                    const timeDifference = now.getTime() - updatedAtUTC;
                                    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
                                    const daysDifference = Math.floor(hoursDifference / 24);

                                    let message;
                                    if (daysDifference > 0) {
                                        message = `${daysDifference} day${daysDifference !== 1 ? 's' : ''} ago`;
                                    } else {
                                        // if (hoursDifference > 0) {
                                        message = `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
                                        // } else {
                                        //   message = "Less than an hour ago";
                                        // }
                                    }

                                    return message;
                                })()}
                            </p>


                        }

                        <Divider />
                        <div style={{ margin: '12px', }}>
                            <p style={{

                                textAlign: 'center',
                                backgroundColor: "#121212",
                                border: 'rgba(255, 255, 255, 0.23) 1px solid',
                                color: 'white',
                                padding: '.25rem .75rem',
                                borderRadius: '0.25rem', textAlign: "center", margin: "auto", color: "white",
                            }}>A Project By Brongo: <br />
                                <div style={{ display: 'flex' }}>
                                    <a href="https://twitter.com/BrongoCS" target="_blank" style={{ flex: 1 }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 1200 1227" fill="none">
                                        <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" fill="white"></path>
                                    </svg></a>
                                    <a href="https://www.youtube.com/@BrongoCS" target="_blank" style={{ flex: 1 }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 576 512" fill="none"><path fill="white" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path></svg></a>
                                    <a href="https://steamcommunity.com/tradeoffer/new/?partner=83942777&amp;token=Z_ZUTuBY" style={{ flex: 1 }}><img src="/assets/steamlogo.png" style={{ width: '20px', height: '20px' }} /></a>
                                </div>
                            </p>
                        </div>
                        <Divider />
                        <Accordion sx={{ background: '#121212', padding: '4px 16px 4px 16px', margin: 0 }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                key="Float Drop Chances"
                                sx={{ padding: '0', backgroundColor: '#121212' }}
                            >
                                <Typography>Advanced Settings</Typography>

                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: '0', backgroundColor: '#121212' }}>
                                <FormControlLabel control={<Checkbox onClick={() => handleShowUnbox(!showUnbox)} checked={showUnbox} />} label="Show Unboxing ROI" />
                                <FormControlLabel control={<Checkbox onClick={() => handleShowProfit(!showProfit)} checked={showProfit} />} label="Show Profit Chance" />
                                <FormControlLabel control={<Checkbox onClick={() => handleShowInvest(!showInvest)} checked={showInvest} />} label="Show Investing ROI" />
                                <FormControlLabel control={<Checkbox onClick={() => handleShowListings(!showListings)} checked={showListings} />} label="Show Listings" />
                                {/* <FloatDropChances caseData={caseData} exchangeRate={exchangeRate} currency={currency} isMobile={isMobileLayout}/> */}
                            </AccordionDetails>


                        </Accordion>
                        <Divider />
                        <Divider />

                        <div style={{ margin: '12px', display: 'flex', flexDirection: 'row' }} >
                            <p onClick={() => window.location.href = '/#/faq'} style={{

                                textAlign: 'center',
                                backgroundColor: "#121212",
                                border: 'rgba(255, 255, 255, 0.23) 1px solid',
                                color: 'white',
                                padding: '.25rem .75rem',
                                borderRadius: '0.25rem', textAlign: "center", margin: "auto", color: "white", width: '70px', cursor: "pointer",
                            }}>FAQ
                            </p>
                            <p onClick={() => window.location.href = '/#/changelog'} style={{

                                textAlign: 'center',
                                backgroundColor: "#121212",
                                border: 'rgba(255, 255, 255, 0.23) 1px solid',
                                color: 'white',
                                padding: '.25rem .75rem',
                                borderRadius: '0.25rem', textAlign: "center", margin: "auto", color: "white", width: '120px', cursor: "pointer",
                            }}>Change Log
                            </p>
                        </div>


                        <Divider />
                        {/* <h5 style={{ textAlign: 'center', maxHeight: '180px', padding: '4px' }}>
              All Key Costs for third-party marketplaces are now automatically adjusted to reflect an easily achievable cost when converting to Steam Market balance.<br/><br/>
              <a href='/#/deals'>Find the best deals to get Steam Market balance here!</a>
            </h5> */}
                        {/* <h5 style={{ textAlign: 'center', maxHeight: '180px', padding: '4px' }}>
              Buff.163 has removed all containers from their site. They will continue to be tracked for historical purposes. All container prices using Buff.163 pricing from the 19th of May onwards are now instead using 80% of the Steam Market cost, as most users can convert Buff.163 balance to Steam Market balance at that rate or better.
            </h5> */}
                        {/* <Divider /> */}
                        {/* <iframe width={drawerWidth} height="180" src="https://www.youtube.com/embed/mMCqwpVC2Zc?si=1DY9BEJKcLe66qy3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;" allowFullScreen ></iframe> */}
                        <Divider />
                    </Scrollbars>
                </Drawer>
                <Box
                    component="main"
                    sx={{

                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    {/* <Toolbar /> */}
                    <Scrollbars
                        // autoHide
                        // autoHideTimeout={1000}
                        // autoHideDuration={200}
                        renderThumbVertical={({ style }) => (
                            <div style={{ ...style, backgroundColor: 'lightgray', borderRadius: '4px' }} />
                        )}
                    >



                        <Container maxWidth="false" sx={{ mb: 4, mt: "4px" }}>
                            <Container style={{ display: 'flex', flexDirection: 'row', padding: '0', margin: '0', maxWidth: 'none', flexWrap: 'wrap', justifyContent: 'center' }}>
                                {/* <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{ mr: 2 }}
                >
                  <MenuIcon />
                </IconButton> */}


                                <div style={{ display: isMobileLayout ? 'flex' : 'none', flexDirection: 'column', justifyContent: 'center', margin: 'auto', marginBottom: '6px', marginTop: '14px', }}>
                                    <img src="/csroi.png" class="p-2" style={{ maxWidth: '280px', width: '100%', height: "auto", objectFit: 'contain', margin: 'auto' }} />

                                </div>
                                <Autocomplete
                                    inputValue={inputValue}
                                    onInputChange={handleInputChange}
                                    onChange={handleSearchChange}
                                    // onBlur={handleBlur}
                                    // onClose={handleClose}
                                    freeSolo
                                    disablePortal
                                    id="combo-box-demo"
                                    options={items}
                                    filterOptions={(items, { inputValue }) => {
                                        const searchTerms = inputValue.toLowerCase().split(' ').filter(term => term);
                                        return items.filter((option) => {
                                            const normalizedOption = option?.Name.toLowerCase();
                                            return searchTerms.every(term => normalizedOption.includes(term));
                                        });
                                    }}
                                    getOptionLabel={(option) => option?.Name ?? option}
                                    sx={{ margin: 'auto', width: '220px', marginBottom: isMobileLayout ? 'auto' : 'auto' }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Search"
                                            InputProps={{
                                                ...params.InputProps,
                                                type: 'search',
                                            }}
                                        />
                                    )}
                                // renderInput={(params) => <TextField {...params} label="Search" />}
                                />
                                {
                                                  <FormControlLabel
                                                    style={{ marginTop: '-4px' }}
                                                    control={<Switch checked={stattrak} onChange={() => setStattrak(!stattrak)} />}
                                                    label="StatTrak"
                                                  />
                                                  }
                                <div style={{ margin: 'auto', textAlign: 'center', paddingBottom: '8px', display: 'flex', flexDirection: 'row' }}>

                                    <FormControl sx={{ flex: 1, minWidth: '120px', marginTop: '8px' }}>
                                        <InputLabel id="pricing-source-label" style={{ marginLeft: '-4px' }}>Sort By</InputLabel>
                                        <Select
                                            //  renderInput={(params) => <TextField {...params} label="Pricing Source" />}

                                            labelId="pricing-source-label"
                                            id="pricing-source"
                                            value={sortBy}
                                            style={{ minWidth: '120px', fontSize: '13px', height: '56px' }}
                                            label="Sort By  "
                                            onChange={handleSort}
                                        >
                                            <MenuItem value="unbox"><div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center', width: '100%' }}><img src={OpenBox} style={{ width: '28px', height: '28px' }} className="iconSVGMob" />UNBOX ROI

                                                <Tooltip title={
                                                    <Typography variant="body" component="div" style={{ textAlign: 'center' }}>
                                                        Return on Investment for opening this container now
                                                    </Typography>
                                                }>
                                                    <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '8px' }} fontSize='large' />
                                                </Tooltip></div></MenuItem>
                                            <MenuItem value="invest"><div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center', width: '100%' }}><TrendingUpIcon className="iconSVGMob" fontSize='small' sx={{ width: '28px', height: '28px' }} /> INVEST ROI
                                                <Tooltip title={
                                                    <Typography variant="body" component="div" style={{ textAlign: 'center' }}>
                                                        Return on Investment if you had purchased this container around {investType == "1y" ? "a year" : investType == "6m" ? "6 months" : "a month"} ago.
                                                    </Typography>
                                                }>
                                                    <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '8px' }} fontSize='large' />
                                                </Tooltip></div></MenuItem>
                                            {/* <MenuItem value="invest6M"><div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center', width: '100%' }}><TrendingUpIcon className="iconSVGMob" fontSize='small' sx={{ width: '28px', height: '28px' }} /> 6M INVEST ROI
                        <Tooltip title={
                          <Typography variant="body" component="div" style={{ textAlign: 'center' }}>
                            Return on Investment if you had purchased this container around 6 months ago.
                          </Typography>
                        }>
                          <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '8px' }} fontSize='large' />
                        </Tooltip></div></MenuItem>
                      <MenuItem value="invest1M"><div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center', width: '100%' }}><TrendingUpIcon className="iconSVGMob" fontSize='small' sx={{ width: '28px', height: '28px' }} /> 1M INVEST ROI
                        <Tooltip title={
                          <Typography variant="body" component="div" style={{ textAlign: 'center' }}>
                            Return on Investment if you had purchased this container around a month ago.
                          </Typography>
                        }>
                          <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '8px' }} fontSize='large' />
                        </Tooltip></div></MenuItem> */}
                                            <MenuItem value="cost"><div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center', width: '100%' }}><AttachMoney className="iconSVGMob" fontSize='small' sx={{ width: '28px', height: '28px' }} /> PRICE
                                                <Tooltip title={
                                                    <Typography variant="body" component="div" style={{ textAlign: 'center' }}>
                                                        Sort by the cost to unbox. duh.
                                                    </Typography>
                                                }>
                                                    <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '8px' }} fontSize='large' />
                                                </Tooltip></div></MenuItem>
                                            <MenuItem value="profit"><div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center', width: '100%' }}><PriceCheck className="iconSVGMob" fontSize='small' sx={{ width: '28px', height: '28px' }} /> PROFIT CHANCE
                                                <Tooltip title={
                                                    <Typography variant="body" component="div" style={{ textAlign: 'center' }}>
                                                        Chance for each unbox to be worth more than the cost of opening
                                                    </Typography>
                                                }>
                                                    <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '8px' }} fontSize='large' />
                                                </Tooltip></div></MenuItem>
                                            <MenuItem value="numListings"><div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center', width: '100%' }}><LocalShipping className="iconSVGMob" fontSize='small' sx={{ width: '28px', height: '28px' }} /> LISTINGS
                                                <Tooltip title={
                                                    <Typography variant="body" component="div" style={{ textAlign: 'center' }}>
                                                        Number of Listings on Steam
                                                    </Typography>
                                                }>
                                                    <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '8px' }} fontSize='large' />
                                                </Tooltip></div></MenuItem>
                                            <MenuItem value="dropType">
                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center', width: '100%' }}>
                                                    DROP TYPE
                                                    <Tooltip title={
                                                        <Typography variant="body" component="div" style={{ textAlign: 'center' }}>
                                                            Alphabetical sorting of drop types.<br />
                                                            "Active Drops" can be received in your weekly care package.<br />
                                                            "Discontinued" items are no longer obtainable and must be bought / traded for.<br />
                                                            "In Game Store" items are in infinite supply and are highly recommended NOT to invest in. Buy these items in the CS2 Store tab in game.<br />
                                                            "Rare Drops" are very rarely obtained from your weekly care package, it is estimated that any care package has a 1% chance of containing a random Rare Drop.
                                                        </Typography>
                                                    }>
                                                        <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '8px' }} fontSize='large' />
                                                    </Tooltip>
                                                </div>
                                            </MenuItem>
                                            {/* Add more pricing source options if needed */}
                                        </Select>
                                    </FormControl>
                                    <IconButton aria-label="sortDirection" style={{ marginTop: '8px', WebkitTransform: sortDirection ? "none" : "scaleY(-1)", transform: sortDirection ? "none" : "scaleY(-1)" }} onClick={() => handleSortDirection(!sortDirection)}>
                                        <SortIcon></SortIcon>
                                    </IconButton>
                                    <FormControl style={{ width: '120px', marginTop: '8px', marginRight: 'auto' }}>
                                        <InputLabel id="demo-simple-select-label">Invest Type</InputLabel>
                                        <Select
                                            value={investType}
                                            label="Investing ROI"
                                            style={{ textAlign: 'left', fontSize: '16px' }}
                                            onChange={(event) => {
                                                localStorage.setItem('investType', event.target.value);
                                                setInvestType(event.target.value);
                                            }}
                                        >
                                            <MenuItem value="1y">1 Year</MenuItem>
                                            <MenuItem value="6m">6 Months</MenuItem>
                                            <MenuItem value="1m">1 Month</MenuItem>
                                        </Select>
                                    </FormControl>

                                    {/* <FormGroup sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                  <FormControlLabel control={<Checkbox defaultChecked />} label="Unbox" labelPlacement="left" sx={{margin: 'auto'}} />
                  <FormControlLabel control={<Checkbox defaultChecked />} label="Invest" labelPlacement="left" sx={{margin: 'auto'}} />
                  </FormGroup> */}
                                </div>
                                <div style={{ marginTop: '8px', marginBottom: '8px', display: isMobileLayout ? 'flex' : 'none', flexDirection: 'row', marginLeft: 'auto', marginRight: 'auto' }}>
                                    <FormControl sx={{ fontFamily: "'Barlow', 'Roboto', sans-serif", margin: '0 4px 0px 0px', minWidth: '180px', flex: '1', display: 'inline-flex' }}>
                                        <InputLabel id="pricing-source-label" >Pricing Source</InputLabel>
                                        <Select
                                            //  renderInput={(params) => <TextField {...params} label="Pricing Source" />}

                                            labelId="pricing-source-label"
                                            id="pricing-source"
                                            value={pricingSource}
                                            label="Pricing Source"
                                            onChange={handlePricingSourceChange}

                                        >
                                            <MenuItem value="Steam">Steam</MenuItem>
                                            <MenuItem value="Buff">
                                                <div>Buff.163 / UUPin
                                                    <Tooltip title="Buff.163 prices are preferred, but UUPin prices are used when Buff.163 prices are unavailable (e.g., containers) or when there is a significant price difference." sx={{ margin: 'auto' }}>

                                                        <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '4px', marginBottom: '-4px' }} fontSize='large' />
                                                    </Tooltip>
                                                </div>
                                            </MenuItem>
                                            <MenuItem value="CSFloat">CSFloat</MenuItem>
                                            <MenuItem value="Skinport">Skinport</MenuItem>
                                            <MenuItem value="Tradeit">
                                                <div>
                                                    Tradeit.gg
                                                    <Tooltip title="Tradeit.gg pricing reflects the cost to withdraw containers and trade back unboxed items. Estimated prices, marked in grey, are used when specific item prices are unavailable." sx={{ margin: 'auto' }}>

                                                        <HelpOutlineIcon className="iconSVG" sx={{ width: '20px', height: '20px', margin: 'auto', marginLeft: '4px', marginBottom: '-4px' }} fontSize='large' />
                                                    </Tooltip></div>
                                            </MenuItem>
                                            {/* Add more pricing source options if needed */}
                                        </Select>
                                    </FormControl>

                                    <Autocomplete
                                        value={currency}
                                        onChange={(event, newValue) => setCurrency(newValue)}
                                        disableClearable
                                        options={currencies}
                                        getOptionLabel={(option) => option.toUpperCase()}
                                        sx={{ fontFamily: "'Barlow', 'Roboto', sans-serif", padding: '0 12px 4px 12px', flex: '1' }}
                                        renderInput={(params) => <TextField {...params} label="Currency" />}
                                    />
                                </div>
                                {
                                    pricingSource === "Tradeit" && isMobileLayout &&
                                    <FormGroup sx={{ fontSize: '0.8rem', marginTop: '-16px' }} >
                                        <FormControlLabel sx={{}} control={<Checkbox checked={tradeitHideUnavailable} onClick={() => handleTradeitHide(!tradeitHideUnavailable)} />} label="Show Unavailable Containers" />
                                    </FormGroup>
                                }
                                {
                                    isMobileLayout &&
                                    <div style={{ marginBottom: '12px', display: 'flex', border: '1px solid rgba(255, 255, 255, 0.23)', borderRadius: '4px', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer' }} onClick={() => window.location.href = '/#/deals'}>
                                        <p style={{ lineHeight: '0.9', marginRight: '4px' }}>
                                            Deals
                                            <br />
                                            <span style={{ fontSize: '0.7rem' }}>Resell Skins to get up to 1.5x Steam Wallet Balance!</span>
                                        </p>

                                    </div>
                                }
                                {
                                    isMobileLayout &&
                                    <div style={{ marginBottom: '4px', display: 'flex', border: '1px solid rgba(255, 255, 255, 0.23)', borderRadius: '4px', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer' }} onClick={() => window.location.href = '/#/charmcolors'}>
                                        <p style={{ lineHeight: '0.9', marginRight: '4px' }}>
                                            Charm Colors
                                            <br />
                                            <span style={{ fontSize: '0.7rem' }}>Find the color and pattern of charm to match your skin!</span>
                                        </p>
                                        <Chip label="NEW" variant="outlined" size='small' sx={{ color: 'white', border: '1px white solid', fontSize: '0.8rem', height: '24px', width: '48px', padding: '0', marginLeft: '4px' }} />
                                    </div>
                                }
                            </Container>


                            <div style={{ height: '95vh', paddingLeft: '4px', paddingRight: '4px', paddingBottom: '12px' }}>
                                {/* <Scrollbars
                  autoHide
                  autoHideTimeout={1000}
                  autoHideDuration={200}
                  renderThumbVertical={({ style }) => (
                    <div style={{ ...style, backgroundColor: 'lightgray', borderRadius: '4px' }} />
                  )}
                > */}



                                {/* <Grid container direction="row" spacing={{}} columns={{ xs: 20, sm: 20, md: 20, lg: 20 }} sx={{ flex: 6, flexWrap: 'wrap', justifyContent: 'center', padding: 0, maxWidth: '100%' }}> */}


                                {/* {items.map((item, index) => {
                      return <div style={{ margin: '2px', flex: 6, flexWrap: 'wrap', justifyContent: 'center', padding: 0, maxWidth: '800px' }}>{CaseGridItem(item, index, pricingSource, currency, exchangeRate, sortBy, isMobileLayout, showUnbox, showProfit, showInvest)}</div>
                      
                    })} */}
                                {/* {items.map((item, index) => (
                      <div style={{ margin: '2px', flex: 6, flexWrap: 'wrap', justifyContent: 'center', padding: 0, maxWidth: '800px' }}>
                        <CaseGridItem
                          key={item.id}
                          item={item}
                          index={index}
                          pricingSource={pricingSource}
                          currency={currency}
                          exchangeRate={exchangeRate}
                          sortBy={sortBy}
                          isMobileLayout={isMobileLayout}
                          showUnbox={showUnbox}
                          showProfit={showProfit}
                          showInvest={showInvest}
                        />
                      </div>
                    ))} */}

                                {/* <AutoSizer>
                  {({ height, width }) => {
                    let minColumnWidth = width < 380 ? 240 : 380;
                    let columnCount = Math.floor(width / minColumnWidth);
                    let columnWidth = (width - 10) / columnCount;

                    return (
                      <ColumnCountContext.Provider value={columnCount}>
                        <FixedSizeGrid
                          columnCount={columnCount}
                          columnWidth={columnWidth}
                          height={height}
                          rowCount={Math.ceil(items.length / columnCount)}
                          rowHeight={isMobileLayout ? 250: 240} // adjust this to your needs
                          width={width}
                          style={{ margin: 'auto' }}
                          align="center"
                        >
                          {Cell}
                        </FixedSizeGrid>
                      </ColumnCountContext.Provider>
                    );
                  }}
                </AutoSizer> */}
                                {/* </Grid> */}
                                {/* </Scrollbars> */}
                                <AutoSizer>
                                    {({ height, width }) => {
                                        let minColumnWidth = width < 380 ? 240 : 380;
                                        let columnCount = Math.floor(width / minColumnWidth);
                                        let columnWidth = index => (width - 10) / columnCount;
                                        let rowHeight = index => (isMobileLayout ? 254 : 244); // Set a larger height for the first row

                                        return (
                                            <ColumnCountContext.Provider value={columnCount}>
                                                <Grid
                                                    columnCount={columnCount}
                                                    columnWidth={columnWidth}
                                                    height={height}
                                                    rowCount={Math.ceil(items.length / columnCount)} // Add 1 to the row count for the div
                                                    rowHeight={rowHeight}
                                                    width={width}
                                                    style={{ margin: 'auto' }}
                                                >
                                                    {Cell}
                                                </Grid>
                                            </ColumnCountContext.Provider>
                                        );
                                    }}
                                </AutoSizer>

                            </div>
                            {isMobileLayout && items.length > 0 &&
                                <p style={{
                                    margin: 'auto',
                                    textAlign: 'center',
                                    backgroundColor: "#121212",
                                    border: 'rgba(255, 255, 255, 0.23) 1px solid',
                                    color: 'white',
                                    padding: '.25rem .75rem',
                                    borderRadius: '0.25rem',
                                    marginTop: '20px',
                                }} >
                                    Updated<br />
                                    {(() => {
                                        const updatedAtUTC = items[0]["UpdatedAt"] * 1000;
                                        const now = new Date();
                                        const timeDifference = now.getTime() - updatedAtUTC;
                                        const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
                                        const daysDifference = Math.floor(hoursDifference / 24);

                                        let message;
                                        if (daysDifference > 0) {
                                            message = `${daysDifference} day${daysDifference !== 1 ? 's' : ''} ago`;
                                        } else {
                                            if (hoursDifference > 0) {
                                                message = `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
                                            } else {
                                                message = "Less than an hour ago";
                                            }
                                        }

                                        return message;
                                    })()}
                                </p>
                            }
                            {/* <br/> */}
                            {
                                <h1 style={{ margin: 'auto', width: '300px', textAlign: 'center' }}>{selectedCaseName}</h1>
                            }

                            {chainTradeupsPossible &&
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: '4px 16px 4px 16px', marginTop: '8px', marginBottom: '8px' }}>
                                    {Object.keys(chainTradeupsPossible).map(rarity => (
                                        <div key={rarity} style={{ margin: '4px' }}>
                                            <Button
                                                variant="contained"
                                                style={{ backgroundColor: (rarity == selectedRarity ? 'green' : '#bb171a'), color: 'white' }}
                                                onClick={() => handleRarityClick(rarity)}
                                            >
                                                {rarity}
                                            </Button>
                                        </div>
                                    ))}
                                </div>
                            }
                            {selectedRarity &&
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: '4px 16px 4px 16px', marginTop: '8px', marginBottom: '8px' }}>
                                    {Object.keys(chainTradeupsPossible[selectedRarity]).map(tradeupFloat => (
                                        <div key={tradeupFloat}>
                                            <Button
                                                variant="contained"
                                                size='small'
                                                style={{ backgroundColor: (selectedTradeupFloat == tradeupFloat ? 'green' : '#bb171a'), color: 'white' }}
                                                onClick={() => handleTradeupFloatClick(tradeupFloat)}
                                            >
                                                {Number.parseFloat(tradeupFloat).toFixed(4)}
                                                <br />
                                                {((chainTradeupsPossible[selectedRarity][tradeupFloat]?.["chain_tradeup_return"] ?? chainTradeupsPossible[selectedRarity][tradeupFloat]?.["best_tradeup_return"]) * 100).toFixed(0)}%
                                            </Button>
                                        </div>
                                    ))}
                                </div>
                            }
                            {
                                selectedRarity &&
                                selectedTradeupFloat &&
                                Object.keys(chainTradeupsPossible[selectedRarity][selectedTradeupFloat]["best_tradeups"]).map(
                                    (tradeupInputNameChoice, index) => {
                                        return (
                                            <div key={tradeupInputNameChoice} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: '4px 16px 4px 16px', marginTop: '8px', marginBottom: '8px' }}>
                                                <Button
                                                    variant="contained"
                                                    size='small'
                                                    style={{ backgroundColor: (tradeupInputNameChoice == tradeupInputName ? 'green' : '#bb171a'), color: 'white' }}
                                                    onClick={() => setTradeupInputName(tradeupInputNameChoice)}
                                                >
                                                    {tradeupInputNameChoice}
                                                </Button>
                                            </div>
                                        );
                                    }

                                )
                            }
                            {selectedTradeupFloat &&
                                selectedTradeupFloat in chainTradeupsPossible[selectedRarity] &&
                                (() => {
                                    var possibleTradeups = chainTradeupsPossible[selectedRarity][selectedTradeupFloat]["best_tradeups"][tradeupInputName ?? Object.keys(chainTradeupsPossible[selectedRarity][selectedTradeupFloat]["best_tradeups"])[0]].map(tradeup => tradeup["yname"]);
                                    const initialTradeups = chainTradeupsPossible[selectedRarity][selectedTradeupFloat]["best_tradeups"][tradeupInputName ?? Object.keys(chainTradeupsPossible[selectedRarity][selectedTradeupFloat]["best_tradeups"])[0]];
                                    const initialFillerIndex = possibleTradeups.indexOf(selectedTradeup);
                                    const selected_tradeup = chainTradeupsPossible[selectedRarity][selectedTradeupFloat]["best_tradeups"][tradeupInputName ?? Object.keys(chainTradeupsPossible[selectedRarity][selectedTradeupFloat]["best_tradeups"])[0]][initialFillerIndex !== -1 ? initialFillerIndex : 0];
                                    const outputs = chainTradeupsPossible[selectedRarity][selectedTradeupFloat]["outputs"];
                                    const allPossibleInputTypes = chainTradeupsPossible[selectedRarity][selectedTradeupFloat]["inputs"]
                                    const selectedInputType = allPossibleInputTypes.find(input => wearCodeNames[input["input_wear"].replace("StatTrak™ ", "")] === wearRanges[selected_tradeup["xlow"]]);
                                    // console.log(selected_tradeup["xfloat"]);
                                    // console.log(selectedInputType?.["float_prices"]);
                                    // console.log(selected_tradeup["xfloat"]);
                                    console.log(allPossibleInputTypes);
                                    var possibleInputs = selectedInputType?.["float_prices"]?.[selected_tradeup["xfloat"]] ?? [];
                                    const nearbyPossibleInputs = selectedInputType?.["float_prices"]?.[(Math.floor(Number.parseFloat(selected_tradeup["xfloat"]) * 1000) / 1000).toFixed(3)] ?? [];
                                    const nearbyPossibleInputs2 = selectedInputType?.["float_prices"]?.[(Math.floor(Number.parseFloat(selected_tradeup["xfloat"]) * 100) / 100).toFixed(1)] ?? [];
                                    possibleInputs = possibleInputs.concat(
                                        nearbyPossibleInputs.filter(nearbyInput =>
                                            !possibleInputs.some(possibleInput => possibleInput.item === nearbyInput.item)
                                        )
                                        
                                    );
                                    possibleInputs = possibleInputs.concat(
                                        nearbyPossibleInputs2.filter(nearbyInput =>
                                            !possibleInputs.some(possibleInput => possibleInput.item === nearbyInput.item)
                                        )
                                        
                                    );
                                    var selectedOutput = null;
                                    var selectedOutputTradeup = null;
                                    var correspondingOutputs = null;
                                    var rarities = Object.keys(chainTradeupsPossible);
                                    var selectedIndex = rarities.indexOf(selectedRarity);
                                    var higherRarity = rarities[selectedIndex - 1];
                                    var possibleChainFloats = null;
                                    var possibleChainFillers = null;
                                    if (selectedTradeupOutput in outputs) {
                                        if ("best_tradeups" in outputs[selectedTradeupOutput]) {
                                            selectedOutput = outputs[selectedTradeupOutput];
                                            possibleChainFloats = Object.keys(selectedOutput["best_tradeups"]);
                                            possibleChainFillers = selectedOutput["best_tradeups"][selectedChainFloat ?? selectedOutput["best_tradeup"]]["tradeups"].map(tradeup => tradeup["yname"]);
                                            const tradeups = selectedOutput["best_tradeups"][selectedChainFloat ?? selectedOutput["best_tradeup"]]["tradeups"];
                                            const fillerIndex = possibleChainFillers.indexOf(selectedChainFiller);
                                            selectedOutputTradeup = tradeups[fillerIndex !== -1 ? fillerIndex : 0];
                                            correspondingOutputs = chainTradeupsPossible[higherRarity][selectedChainFloat ?? selectedOutput["best_tradeup"]]["outputs"];
                                        }
                                    }
                                    var stringBuyorders = localStorage.getItem("activeBuyorders");
                                    var currBuyorders = stringBuyorders ? JSON.parse(stringBuyorders) : {};

                                    return (
                                        <div>
                                            <div>
                                                <h2 style={{ textAlign: 'center' }}>Input Details</h2>

                                                {
                                                    possibleInputs &&
                                                    possibleInputs.map((inputType) => {
                                                        const { base_price, resale_price, num_items_resold, xtradeup_cost } = calculatePrices(inputType, allInputBuyorderCosts, exchangeRate, selected_tradeup["xnum"], currBuyorders, selectedInputType);
                                                        const inputRealName = (selectedInputType.input_wear.includes("StatTrak") ? "StatTrak™ " : "") + inputType.item + " (" + wearCodeNames[selectedInputType.input_wear.replace("StatTrak™ ", "")] + ")";
                                                        return (
                                                            <div key={inputType.item} onClick={() => {
                                                                var inputData = {
                                                                    "name": inputType.item,
                                                                    "base_price": base_price,
                                                                    "resale_price": resale_price,
                                                                    "chance": inputType.wear_chance,
                                                                    "xtradeup_cost": xtradeup_cost
                                                                }
                                                                setSelectedInput(inputData);
                                                            }}>
                                                                <h3 style={{ textAlign: 'center', color: selectedInput?.name == inputType.item ? 'green' : currBuyorders?.[inputType.item + " (" + wearCodeNames[selectedInputType.input_wear] + ")"] != null ? 'yellow' : 'white' }}>
                                                                    {inputType.item} - {(inputType.wear_chance * 100).toFixed(1)}% -
                                                                    <TextField
                                                                        type="number"
                                                                        value={allInputBuyorderCosts?.[inputType.item + " (" + wearCodeNames[selectedInputType.input_wear] + ")"] != null ? allInputBuyorderCosts[inputType.item + " (" + wearCodeNames[selectedInputType.input_wear] + ")"] : currBuyorders?.[inputType.item + " (" + wearCodeNames[selectedInputType.input_wear] + ")"]?.["price"] ?? ((inputType.cost / exchangeRate) / 10).toFixed(2)}
                                                                        onChange={(e) => {
                                                                            const { base_price, resale_price, num_items_resold, xtradeup_cost } = calculatePrices({ ...inputType, cost: parseFloat(e.target.value) }, allInputBuyorderCosts, exchangeRate, selected_tradeup["xnum"], currBuyorders, selectedInputType);
                                                                            var inputData = {
                                                                                "name": inputType.item,
                                                                                "base_price": base_price,
                                                                                "resale_price": resale_price,
                                                                                "chance": inputType.wear_chance,
                                                                                "xtradeup_cost": xtradeup_cost
                                                                            }
                                                                            setSelectedInput(inputData);
                                                                            handleCostChange(e, inputType, selectedInputType.input_wear);
                                                                        }}
                                                                        variant="outlined"
                                                                        size="small"
                                                                        style={{ width: '100px', marginRight: '10px' }}
                                                                    />
                                                                    {formatNumber(((resale_price / 10) / exchangeRate), true, 3)}                
                                                                    <a href={pricingSource.toLowerCase() == "steam" ? steamLink(inputRealName) : pricingSource.toLowerCase() == "skinport" ? skinportLink(inputRealName) : pricingSource.toLowerCase() == "buff163" ? buffLink(inputRealName) : pricingSource.toLowerCase() == "youpin" ? buffLink(inputRealName) : pricingSource.toLowerCase().includes("tradeit") ? tradeitLink(inputRealName) : csfloatLink(inputRealName)} target="_blank" rel="noreferrer"> Market</a>
                                                                    {currBuyorders?.[inputType.item + " (" + wearCodeNames[selectedInputType.input_wear] + ")"] != null ? formatNumber((inputType.cost / 10) / exchangeRate, true) : ""}
                                                                    <button onClick={() => {
                                                                        const price = allInputBuyorderCosts?.[inputType.item + " (" + wearCodeNames[selectedInputType.input_wear] + ")"] != null ? allInputBuyorderCosts[inputType.item + " (" + wearCodeNames[selectedInputType.input_wear] + ")"] : ((inputType.cost / exchangeRate) / 10).toFixed(2);
                                                                        const allData = {
                                                                            "case_id": selectedCaseId,
                                                                            "tradeup_rarity": selectedRarity,
                                                                            "tradeup_float": selectedTradeupFloat,
                                                                            "float": selected_tradeup["xfloat"],
                                                                            "float_chance": selected_tradeup["xchance"],
                                                                            "float_high": selected_tradeup["xhigh"],
                                                                            "float_low": selected_tradeup["xlow"],
                                                                            "price": price,
                                                                        }
                                                                        currBuyorders[inputType.item + " (" + wearCodeNames[selectedInputType.input_wear] + ")"] = allData;
                                                                        localStorage.setItem("activeBuyorders", JSON.stringify(currBuyorders));
                                                                    }} style={{ marginLeft: '10px' }}>Save</button>
                                                                    {currBuyorders?.[inputType.item + " (" + wearCodeNames[selectedInputType.input_wear] + ")"] != null && <button onClick={() => {
                                                                        delete currBuyorders[inputType.item + " (" + wearCodeNames[selectedInputType.input_wear] + ")"];
                                                                        localStorage.setItem("activeBuyorders", JSON.stringify(currBuyorders));
                                                                    }} style={{ marginLeft: '10px' }}>Delete</button>}
                                                                </h3>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>

                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: '4px 16px', marginTop: '8px', marginBottom: '8px' }}>
                                                {possibleTradeups &&
                                                    <FormControl variant="outlined" style={{ minWidth: 120 }}>
                                                        <InputLabel id="chain-filler-select-label">Filler</InputLabel>
                                                        <Select
                                                            labelId="chain-filler-select-label"
                                                            id="chain-filler-select"
                                                            value={selectedTradeup ?? possibleTradeups[0]}
                                                            onChange={(event) => setSelectedTradeup(event.target.value)}
                                                            label="Filler"
                                                        >
                                                            {possibleTradeups.map((floatValue) => (
                                                                <MenuItem key={floatValue} value={floatValue}>
                                                                    {floatValue}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                }
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Quantity</th>
                                                            <th>Name</th>
                                                            <th>Price</th>
                                                            <th>Float</th>
                                                            <th>Chance</th>
                                                            <th>Adjusted Price</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{selected_tradeup["xnum"]}</td>
                                                            <td>{selectedInput?.name ?? selected_tradeup["xname"]}</td>
                                                            <td>{formatNumber(((selectedInput?.["base_price"] ?? selected_tradeup["xprice"]) / 10) / exchangeRate, true)}</td>
                                                            <td>{(Number.parseFloat(selected_tradeup["xfloat"])).toFixed(4)}</td>
                                                            <td>{((selectedInput?.chance ?? selected_tradeup["xchance"]) * 100).toFixed(1)}%</td>
                                                            <td>{formatNumber(((selectedInput?.resale_price ?? selected_tradeup["xresaleprice"]) / 10) / exchangeRate, true, 3)}</td>
                                                        </tr>
                                                        {selected_tradeup["xnum"] < 10 && (
                                                            <tr>
                                                                <td>{selected_tradeup["ynum"]}</td>
                                                                <td>{selected_tradeup["yname"]}</td>
                                                                <td>{formatNumber((selected_tradeup["yprice"] / 10) / exchangeRate, true)}</td>
                                                                <td>{(Number.parseFloat(selected_tradeup["yfloat"])).toFixed(4)}</td>
                                                                <td>{(selected_tradeup["ychance"] * 100).toFixed(1)}%</td>
                                                                <td>{formatNumber((selected_tradeup["yresaleprice"] / 10) / exchangeRate, true, 3)}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                <h1>→</h1>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Wear</th>
                                                            <th>Cost</th>
                                                            <th>Chain Value</th>
                                                            <th>Float</th>
                                                            <th>Market Link</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Object.entries(outputs).map(([outputName, outputData], index) => (
                                                            <tr key={index} onClick={() => handleTradeupOutputClick(outputName)} style={{ color: outputName === selectedTradeupOutput ? 'green' : ("best_tradeups" in outputs[outputName]) ? 'yellow' : 'white' }}>
                                                                <td>{outputName}</td>
                                                                <td>{outputData.wear}</td>
                                                                <td>{formatNumber(outputData.cost / exchangeRate, true)}</td>
                                                                <td>{formatNumber((outputData?.chain_value ?? outputData.cost) / exchangeRate, true)}</td>
                                                                <td>{(Number.parseFloat(outputData.float)).toFixed(4)}</td>
                                                                <td><a href={pricingSource.toLowerCase() == "steam" ? steamLink(outputName + " (" + outputData.wear + ")", null, stattrak) : pricingSource.toLowerCase() == "skinport" ? skinportLink(outputName + " (" + outputData.wear + ")", null, stattrak) : pricingSource.toLowerCase() == "buff163" ? buffLink(outputName + " (" + outputData.wear + ")", null, stattrak) : pricingSource.toLowerCase() == "youpin" ? buffLink(outputName + " (" + outputData.wear + ")", null, stattrak) : pricingSource.toLowerCase().includes("tradeit") ? tradeitLink(outputName + " (" + outputData.wear + ")", null, stattrak) : csfloatLink(outputName + " (" + outputData.wear + ")", null, stattrak)} target="_blank" rel="noreferrer">Market</a></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                {
                                                    selected_tradeup &&
                                                    <h2 style={{ textAlign: 'center' }}>
                                                        Base Tradeup:<br />
                                                        = {formatNumber((selectedInput?.xtradeup_cost != null ? (selectedInput?.xtradeup_cost + (selected_tradeup?.ytradeup_cost ?? 0)) : selected_tradeup["tradeup_cost"]) / exchangeRate, true)}→{formatNumber((selected_tradeup["tradeup_value"]) / exchangeRate, true)} ({(((selectedInput?.xtradeup_cost != null ? (selected_tradeup["tradeup_value"]) / ((selectedInput?.xtradeup_cost + (selected_tradeup?.ytradeup_cost ?? 0))) : selected_tradeup["tradeup_return"]) * 100)).toFixed(1)}%)
                                                        <br />Chain Tradeup:<br />
                                                        = {formatNumber((selectedInput?.xtradeup_cost != null ? (selectedInput?.xtradeup_cost + (selected_tradeup?.ytradeup_cost ?? 0)) : selected_tradeup["tradeup_cost"]) / exchangeRate, true)} → {formatNumber(((selected_tradeup?.["ytradeup_value"] ?? 0) + (selected_tradeup["xnum"] * chainTradeupsPossible[selectedRarity][selectedTradeupFloat]["chain_tradeup_value_per_item"])) / exchangeRate, true)} ({(((selectedInput?.xtradeup_cost != null ? ((selected_tradeup?.["ytradeup_value"] ?? 0) + (selected_tradeup["xnum"] * chainTradeupsPossible[selectedRarity][selectedTradeupFloat]["chain_tradeup_value_per_item"])) / ((selectedInput?.xtradeup_cost + (selected_tradeup?.ytradeup_cost ?? 0))) : chainTradeupsPossible[selectedRarity][selectedTradeupFloat]["chain_tradeup_return"])) * 100).toFixed(1)}%)
                                                        <br />Value no Fillers:<br />
                                                        = {formatNumber((((chainTradeupsPossible[selectedRarity][selectedTradeupFloat]?.["chain_tradeup_value_per_item"]) ?? selected_tradeup?.xoutput / 10) / exchangeRate) * 10, true)}

                                                    </h2>
                                                }


                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: '4px 16px', marginTop: '8px', marginBottom: '8px' }}>
                                                {possibleChainFloats &&
                                                    <FormControl variant="outlined" style={{ minWidth: 120 }}>
                                                        <InputLabel id="chain-float-select-label">Chain Float</InputLabel>
                                                        <Select
                                                            labelId="chain-float-select-label"
                                                            id="chain-float-select"
                                                            value={selectedChainFloat ?? selectedOutput["best_tradeup"]}
                                                            onChange={(event) => setSelectedChainFloat(event.target.value)}
                                                            label="Chain Float"
                                                        >
                                                            {possibleChainFloats.map((floatValue) => (
                                                                <MenuItem key={floatValue} value={floatValue}>
                                                                    {floatValue}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                }
                                                {possibleChainFillers &&
                                                    <FormControl variant="outlined" style={{ minWidth: 120 }}>
                                                        <InputLabel id="chain-filler-select-label">Chain Filler</InputLabel>
                                                        <Select
                                                            labelId="chain-filler-select-label"
                                                            id="chain-filler-select"
                                                            value={selectedChainFiller ?? possibleChainFillers[0]}
                                                            onChange={(event) => setSelectedChainFiller(event.target.value)}
                                                            label="Chain Filler"
                                                        >
                                                            {possibleChainFillers.map((floatValue) => (
                                                                <MenuItem key={floatValue} value={floatValue}>
                                                                    {floatValue}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                }
                                                {selectedOutputTradeup &&
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>Quantity</th>
                                                                <th>Name</th>
                                                                <th>Price</th>
                                                                <th>Float</th>
                                                                <th>Chance</th>
                                                                <th>Adjusted Price</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>{selectedOutputTradeup["xnum"]}</td>
                                                                <td>{selectedOutputTradeup["xname"]}</td>
                                                                <td>{formatNumber((selectedOutputTradeup["xprice"] / 10) / exchangeRate, true)}</td>
                                                                <td>{(selectedOutputTradeup["xfloat"]).toFixed(4)}</td>
                                                                <td>{(selectedOutputTradeup["xchance"] * 100).toFixed(1)}%</td>
                                                                <td>{formatNumber((selectedOutputTradeup["xresaleprice"] / 10) / exchangeRate, true, 3)}</td>
                                                            </tr>
                                                            {selectedOutputTradeup["xnum"] < 10 && (
                                                                <tr>
                                                                    <td>{selectedOutputTradeup["ynum"]}</td>
                                                                    <td>{selectedOutputTradeup["yname"]}</td>
                                                                    <td>{formatNumber((selectedOutputTradeup["yprice"] / 10) / exchangeRate, true)}</td>
                                                                    <td>{(Number.parseFloat(selectedOutputTradeup["yfloat"])).toFixed(4)}</td>
                                                                    <td>{(selectedOutputTradeup["ychance"] * 100).toFixed(1)}%</td>
                                                                    <td>{formatNumber((selectedOutputTradeup["yresaleprice"] / 10) / exchangeRate, true, 3)}</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                }
                                                <h1>→</h1>
                                                {correspondingOutputs &&
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Wear</th>
                                                                <th>Cost</th>
                                                                <th>Chain Value</th>
                                                                <th>Float</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {Object.entries(correspondingOutputs).map(([outputName, outputData], index) => (
                                                                <tr key={index} onClick={() => handleTradeupOutputClick(outputName)}>
                                                                    <td>{outputName}</td>
                                                                    <td>{outputData.wear}</td>
                                                                    <td>{formatNumber(outputData.cost / exchangeRate, true)}</td>
                                                                    <td>{formatNumber((outputData?.chain_value ?? outputData.cost) / exchangeRate, true)}</td>
                                                                    <td>{(Number.parseFloat(outputData.float)).toFixed(4)}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                }
                                                {
                                                    selectedOutputTradeup &&
                                                    <h2 style={{ textAlign: 'center' }}>Chain Tradeup <br />
                                                        = {formatNumber((selectedOutputTradeup["tradeup_cost"]) / exchangeRate, true)}→{formatNumber((selectedOutputTradeup["tradeup_value"]) / exchangeRate, true)} {(selectedOutputTradeup["tradeup_return"] * 100).toFixed(1)}%</h2>
                                                }
                                            </div>
                                            {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', padding: '4px 16px', marginTop: '8px', marginBottom: '8px' }}>
                                                <h2>Base Tradeup: {formatNumber((selected_tradeup["tradeup_cost"]) / exchangeRate, true)} → {formatNumber((selected_tradeup["tradeup_value"]) / exchangeRate, true)} ({((selected_tradeup["tradeup_return"]) * 100).toFixed(1)}%)</h2>
                                                <h2>Chain Tradeup: {formatNumber((chainTradeupsPossible[selectedRarity][selectedTradeupFloat]["best_tradeup_cost"]) / exchangeRate, true)} → {formatNumber((chainTradeupsPossible[selectedRarity][selectedTradeupFloat]["chain_tradeup_value"]) / exchangeRate, true)} ({((chainTradeupsPossible[selectedRarity][selectedTradeupFloat]["chain_tradeup_return"]) * 100).toFixed(1)}%)</h2>
                                            </div> */}
                                        </div>
                                    );
                                })()
                            }
                        </Container>
                    </Scrollbars>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

function removeSlashSection(imageUrl) {
    return imageUrl.replace(/\/\d+fx\d+f$/, '/');
}

export default function ChainTradeups() {
    return <DashboardContent />;
}

